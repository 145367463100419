export const assetClasses = {
  ARTF: {
    displayName: 'Art',
    name: 'ARTF',
    subClasses: {
      BRLE: {
        assetClass: 'ARTF',
        displayName: 'Bridge Lending',
        name: 'BRLE',
      },
    },
  },
  COML: {
    displayName: 'Commercial',
    name: 'COML',
    subClasses: {
      EQFN: {
        assetClass: 'COML',
        displayName: 'Equipment Finance',
        name: 'EQFN',
      },
      MERC: {
        assetClass: 'COML',
        displayName: 'Merchant Cash Advance',
        name: 'MERC',
      },
      PURF: {
        assetClass: 'COML',
        displayName: 'Purchase Order Financing',
        name: 'PURF',
      },
      RECF: {
        assetClass: 'COML',
        displayName: 'Receivables Financing',
        name: 'RECF',
      },
      SMBL: {
        assetClass: 'COML',
        displayName: 'SMB Lending',
        name: 'SMBL',
      },
      WHLE: {
        assetClass: 'COML',
        displayName: 'Warehouse Lending',
        name: 'WHLE',
      },
    },
  },
  COND: {
    displayName: 'Consumer',
    name: 'COND',
    subClasses: {
      ATHL: {
        assetClass: 'COND',
        displayName: 'Professional Athlete Loans',
        name: 'ATHL',
      },
      CONC: {
        assetClass: 'COND',
        displayName: 'Consumer Credit Cards',
        name: 'CONC',
      },
      CONL: {
        assetClass: 'COND',
        displayName: 'Consumer Loans',
        name: 'CONL',
      },
    },
  },
  FUND: {
    displayName: 'Fund',
    name: 'FUND',
    subClasses: {
      MLTA: {
        assetClass: 'FUND',
        displayName: 'Multi-Asset',
        name: 'MLTA',
      },
    },
  },
  LEGL: {
    displayName: 'Legal',
    name: 'LEGL',
    subClasses: {
      CLAM: {
        assetClass: 'LEGL',
        displayName: 'Class Action Management',
        name: 'CLAM',
      },
      COML: {
        assetClass: 'LEGL',
        displayName: 'Commercial Litigation',
        name: 'COML',
      },
      LAWF: {
        assetClass: 'LEGL',
        displayName: 'Law Firm Loan',
        name: 'LAWF',
      },
      PRST: {
        assetClass: 'LEGL',
        displayName: 'Pre-Settlement Finance',
        name: 'PRST',
      },
      SETL: {
        assetClass: 'LEGL',
        displayName: 'Settled Case Funding',
        name: 'SETL',
      },
    },
  },
  MRIN: {
    displayName: 'Marine',
    name: 'MRIN',
    subClasses: {
      ACQU: {
        assetClass: 'MRIN',
        displayName: 'Vessel Acquisition',
        name: 'ACQU',
      },
      CONS: {
        assetClass: 'MRIN',
        displayName: 'Vessel Construction',
        name: 'CONS',
      },
      DECN: {
        assetClass: 'MRIN',
        displayName: 'Vessel Deconstruction',
        name: 'DECN',
      },
      VREF: {
        assetClass: 'MRIN',
        displayName: 'Vessel Refinance',
        name: 'VREF',
      },
    },
  },
  PVBC: {
    displayName: 'Private Business Credit',
    name: 'PVBC',
    subClasses: {
      COMM: {
        assetClass: 'PVBC',
        displayName: 'Commercial',
        name: 'COMM',
      },
      CORP: {
        assetClass: 'PVBC',
        displayName: 'Corporate',
        name: 'CORP',
      },
      COSM: {
        assetClass: 'PVBC',
        displayName: 'Consumer',
        name: 'COSM',
      },
    },
  },
  REAL: {
    displayName: 'Real Estate',
    name: 'REAL',
    subClasses: {
      BRIL: {
        assetClass: 'REAL',
        displayName: 'Bridge Lending',
        name: 'BRIL',
      },
      COLE: {
        assetClass: 'REAL',
        displayName: 'Construction Lending',
        name: 'COLE',
      },
      FNFP: {
        assetClass: 'REAL',
        displayName: 'Fix and Flip',
        name: 'FNFP',
      },
      PRED: {
        assetClass: 'REAL',
        displayName: 'Pre-Development Finance',
        name: 'PRED',
      },
      SFRL: {
        assetClass: 'REAL',
        displayName: 'Single-Family Rental',
        name: 'SFRL',
      },
    },
  },
};
