import React from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

export enum NotificationTypeEnum {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export const NotificationTypes = ['success', 'warning', 'error', 'info'];

export type NotificationType = (typeof NotificationTypes)[number];

export interface NotificationStyleProps {
  severity: NotificationType;
  inline?: boolean;
  noMargin?: boolean;
  width?: string;
  iconAlignment?: React.CSSProperties['alignItems'];
}

export interface NotificationProps extends NotificationStyleProps {
  title?: React.ReactElement | string;
  description?: React.ReactElement | string;
  link?: React.ReactElement | string;
  linkText?: string;
  onClick?: () => void;
  scroll?: boolean;
  scrollTo?: ScrollLogicalPosition;
  showIcon?: boolean;
}

export const { getTestIds, useTestIds } = genTestIds([
  'icon',
  'link',
  'title',
  'description',
  'close-button',
]);
