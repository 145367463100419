import React, { FunctionComponent, useEffect } from 'react';
import styled, { withTheme, ThemeProps } from 'styled-components';
import isArray from 'lodash/isArray';
import { setClientError } from '@yieldstreet/tool-kit';

import { BaseProps, Theme } from '../../types';

interface FormErrorProps {
  type?: string;
  error?: string | string[];
  name?: string;
}

const FormErrorInner: FunctionComponent<FormErrorProps & ThemeProps<Theme> & BaseProps> = ({
  type,
  error,
  name,
  testId,
}) => {
  useEffect(() => {
    const event = {
      errorType: type || '',
      errorMessage: isArray(error) ? error[0] : error || '',
      errorId: name || '',
    };
    setClientError(event, 'form-field-error');
  }, [error, type, name]);

  return error ? (
    <FormErrorContainer data-cy={testId || 'error'}>{error}</FormErrorContainer>
  ) : null;
};

export const FormErrorContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const FormError = withTheme(FormErrorInner);
