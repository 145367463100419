import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  Heading,
  ParagraphBase,
  ParagraphChildren,
  ParagraphRegularText,
  ParagraphProps,
} from '../../content/text';
import { Button } from '../../content/button';
import { Container } from '../container';
import { CSSTransition } from 'react-transition-group';

import { media } from '../../themes';

interface PageNotificationProps {
  title?: React.ReactNode;
  cta?: React.ReactNode;
  onCTAClick?: () => void;
  inverse?: boolean;
  animate?: boolean;
  dataCy?: string;
}

export const PageNotification: FunctionComponent<PageNotificationProps> = ({
  title,
  children,
  cta,
  onCTAClick,
  inverse,
  animate,
  dataCy,
}) => {
  return (
    <CSSTransition in={animate} classNames="fade" timeout={600} appear>
      <Background inverse={inverse}>
        <NotificationContainer data-cy={dataCy}>
          <Content>
            {title && (
              <Heading type={5} inverse={inverse}>
                {title}
              </Heading>
            )}
            <ChildrenContent inverse={inverse}>{children}</ChildrenContent>
          </Content>
          {cta && (
            <CTA>
              <Button buttonType="secondary" inverse={inverse} onClick={onCTAClick}>
                {cta}
              </Button>
            </CTA>
          )}
        </NotificationContainer>
      </Background>
    </CSSTransition>
  );
};

const NotificationContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  ${media.tablet`
    flex-direction: row;
  `};
`;

const Background = styled.div<ParagraphProps>`
  background: ${props =>
    props.inverse
      ? props.theme.colors.background_inverse_secondary
      : props.theme.colors.input_button_background};
  width: 100%;
  word-break: break-word;

  &.fade-appear {
    transition: all 300ms ease-in-out;
    transform: scaleY(0);
    transform-origin: top;

    > ${NotificationContainer} {
      transition: all 300ms ease-in-out;
      transition-delay: 300ms;
      opacity: 0;
    }
  }

  &.fade-appear-active {
    transform: scaleY(1);

    > ${NotificationContainer} {
      opacity: 1;
    }
  }
`;

const ChildrenContent = styled.div<ParagraphProps>`
  p {
    color: ${props =>
      props.inverse
        ? props.theme.colors.text_paragraph_inverse
        : props.theme.colors.text_paragraph};

    ${ParagraphBase}
    ${ParagraphChildren}
    ${ParagraphRegularText}
    &:not(:first-child) {
      padding-top: 10px;
    }
    &:first-child {
      padding-top: 5px;
    }
  }
`;

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  max-width: 700px;
`;

const CTA = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 20px;
  ${media.tablet`
    margin-left: 30px;
    margin-top: 0;
    justify-content: center;
  `};
`;
