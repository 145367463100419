import React, { FC } from 'react';
import { BaseProps } from '../../types';
import { LoaderContainer } from '../page-loader';
import { useTestIds } from './ModalLoader.model';
import styled from 'styled-components';
import { Spinner } from '../spinner';
import { friendlyMedia } from '../../themes';

type ModalLoaderProps = BaseProps;

export const ModalLoader: FC<ModalLoaderProps> = props => {
  const testIds = useTestIds(props);
  return (
    <ModalLoaderContainer center role="progressbar" {...testIds.base.attr}>
      <Spinner />
    </ModalLoaderContainer>
  );
};

const ModalLoaderContainer = styled(LoaderContainer)`
  min-height: calc(100vh - 60px);
  display: flex;

  ${friendlyMedia.tablet`
    min-height: 50vh;
  `}
`;
