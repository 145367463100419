import { sortBy } from 'lodash';
import { AssetClassType } from '@yieldstreet/platform-kit';

type DataItem = {
  assetClass: AssetClassType | string;
};

const MANAGED_ASSETS_ORDERING: Array<string> = [
  AssetClassType.RealEstate,
  AssetClassType.PrivateCredit,
  AssetClassType.PrivateEquity,
  AssetClassType.Cash,
];

export const sortManagedAssets = <T extends DataItem>(data: Array<T>) => {
  return sortBy(data, [
    item => {
      if (MANAGED_ASSETS_ORDERING.includes(item?.assetClass)) {
        return MANAGED_ASSETS_ORDERING.indexOf(item?.assetClass);
      }
      return null;
    },
  ]);
};
