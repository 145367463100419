import { PortfolioSummary } from '../models';
import { portfolioSummaryFunds } from './portfolio-summary-funds.mock';

export const portfolioSummary: PortfolioSummary = {
  accruedInvestmentInterest: 64083.55,
  accruedInvestmentInterestPostDefault: 18452.34,
  accruedInvestmentInterestPreDefault: 45631.21,
  availableBalance: -26031,
  balanceInDefault: 96739.99,
  balanceByPerformanceStatus: {
    PERFORMING: 100.99,
    DEFAULT: 129.99,
    MARINE_DEFAULT: 123.99,
    MODIFIED_OUTLOOK: 120.99,
    PARTIAL_DEFAULT: 110.99,
  },
  clearedBalance: 1511,
  funds: portfolioSummaryFunds,
  fundsTotal: 50300,
  interestEarnedLast30: 1207.28,
  investmentBalance: 467128.43,
  irr: -9.7,
  outstandingPrincipal: 352744.88,
  outstandingPrincipalPostDefault: 78287.65,
  outstandingPrincipalPreDefault: 274457.23,
  pendingDeposits: 226749,
  pendingInvestments: 1,
  reserveBalance: 20,
  total: 468640.43,
  weightedInvestmentRate: 9.08,
};
