const mocks = new Map();

// from https://github.com/jestjs/jest/issues/2227
// Allows easily mocking/unmocking properties without using jest.spyOn()

export function mockProperty<T extends {}, K extends keyof T>(object: T, property: K, value: T[K]) {
  const descriptor = Object.getOwnPropertyDescriptor(object, property);
  const mocksForThisObject = mocks.get(object) || {};
  mocksForThisObject[property] = descriptor;
  mocks.set(object, mocksForThisObject);
  Object.defineProperty(object, property, { get: () => value });
}

export function undoMockProperty<T extends {}, K extends keyof T>(object: T, property: K) {
  const descriptor = mocks.get(object)[property];
  if (descriptor) {
    Object.defineProperty(object, property, descriptor);
  }
}
