import styled from 'styled-components';
import { InputLabelStyles, FilledInputStyles } from '../text-input/styles';
import { TextField } from '@mui/material';

/**
 * All DatePickers implement Keyboard Accessibility
 * https://mui.com/material-ui/migration/pickers-migration/#imports
 */
export const SDatePicker = styled(TextField)`
  & .MuiFormLabel-root {
    ${InputLabelStyles}
  }

  & .MuiInputBase-root {
    ${FilledInputStyles}
  }
`;

export const PickerPopover = styled.div`
  &.MuiPaper-root {
    box-shadow: none;
    border-radius: 2px;
    border: 1px solid ${props => props.theme.colors.border_default};
    font-family: ${props => props.theme.typography.secondary};

    .MuiPickersDay-root.MuiPickersDay-today {
      background-color: ${props => props.theme.colors.background_page};
      border: none;
    }

    .MuiPickersDay-root.Mui-selected {
      background-color: ${props => props.theme.colors.background_inverse};
    }
  }
`;
