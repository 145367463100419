import { PaymentMethodType } from '../../enums';
import { AudienceRequest } from '../../types';

export enum PromotionType {
  FREE_INVESTMENT = 'FREE_INVESTMENT',
}

export type PromotionTypeMap<Value> = {
  [PromoType in PromotionType]?: Value;
};

export type FreeInvestmentPromotionConfig = {
  investmentAmountValue: number;
  paymentMethod: PaymentMethodType;
  sourceBankAccountId: string;
};

export type PromotionTypeConfig = {
  [PromotionType.FREE_INVESTMENT]: FreeInvestmentPromotionConfig;
};

export interface Promotion<PromoType extends PromotionType = PromotionType> {
  id: string;
  key: string;
  type: PromoType;
  config: PromotionTypeConfig[PromoType];
}

export enum OfferingPromotionGateCode {
  LIMIT_REACHED = 'LIMIT_REACHED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  PROMOTION_INACTIVE = 'PROMOTION_INACTIVE',
  PROMOTION_INVALID = 'PROMOTION_INVALID',
}

export type OfferingPromotionGateMap<Value> = {
  [PromoGate in OfferingPromotionGateCode]: Value;
};

export enum OfferingPromotionStatus {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
}

export type OfferingPromotion = {
  id: string;
  promotionId: string;
  noteUrlHash: string;
  audiences: AudienceRequest;
  status: OfferingPromotionStatus;
  maximumCountLimit: number;
  maximumInvestmentCount?: number;
};

export interface OfferingPromotionGate {
  code: OfferingPromotionGateCode;
}

export interface OfferingPromotionEligibilityRequest {
  promotionKey: string;
}

export interface BulkOfferingPromotionEligibilityRequest {
  [noteUrlHash: string]: OfferingPromotionEligibilityRequest;
}

export interface OfferingPromotionEligibilityResult {
  gate: OfferingPromotionGate | null;
  promotion: Promotion | null;
  offeringPromotion: OfferingPromotion | null;
}

export interface BulkOfferingPromotionEligibilityResult {
  [noteUrlHash: string]: OfferingPromotionEligibilityResult;
}

export interface BulkOfferingPromotionEligibilityResponse {
  success: boolean;
  response: BulkOfferingPromotionEligibilityResult;
}
