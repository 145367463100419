import { genTestIds } from '@yieldstreet/tool-kit';
import type { TooltipProps } from '@mui/material';

export interface TooltipV2Props extends TooltipProps {
  title: string;
  arrow?: boolean;
  placement: 'top' | 'right' | 'bottom' | 'left';
  inverse?: boolean;
}

const { getTestIds } = genTestIds();

export const testIds = getTestIds('tooltip');
