import React from 'react';
import Carousel from 'nuka-carousel';
import {
  Paragraph,
  TextBlock,
  MarketingPhotoCard,
  SectionContainer,
  useModalContext,
  NewModal,
  Heading,
} from '@yieldstreet/ui-kit';

import { leadershipData } from './FundLeadership.model';
import {
  LeadershipSection,
  PhotoCardsWrapper,
  CopyWraper,
  ModalBackground,
  ModalLeadWrapper,
} from './FundLeadership.style';

export const FundLeadership = () => {
  const { showModal } = useModalContext();

  return (
    <SectionContainer>
      <LeadershipSection>
        <CopyWraper>
          <TextBlock
            heading="Fund leadership"
            headingTagType={2}
            headingType={2}
            content={
              <Paragraph>
                The Fund is led by Ted Yarbrough, former Global Head of Securitized Products at
                Citi, and Sirisha Prasad — and supported by Yieldstreet’s 40+ investment
                professionals.
              </Paragraph>
            }
          />
        </CopyWraper>

        <PhotoCardsWrapper>
          <Carousel
            withoutControls
            slidesToScroll={1}
            slidesToShow={1}
            slideWidth="270px"
            cellSpacing={24}
            framePadding="12px 0"
            dragging={false}
          >
            {leadershipData.map(({ name, subtitle, description, imgUrl, modalId }) => (
              <React.Fragment key={name}>
                <MarketingPhotoCard
                  imgUrl={imgUrl}
                  title={name}
                  subTitle={subtitle}
                  onClick={() => showModal(modalId)}
                />

                <NewModal modalId={modalId}>
                  <ModalBackground>
                    <ModalLeadWrapper>
                      <img src={imgUrl} />
                      <Heading tagType={4} type={4} marginVertical="xxs">
                        {name}
                      </Heading>
                      <Paragraph size="small">{subtitle}</Paragraph>
                    </ModalLeadWrapper>
                    <Paragraph>{description}</Paragraph>
                  </ModalBackground>
                </NewModal>
              </React.Fragment>
            ))}
          </Carousel>
        </PhotoCardsWrapper>
      </LeadershipSection>
    </SectionContainer>
  );
};
