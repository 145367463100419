import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Currency, Heading, Paragraph } from '../..';

interface DetailsList {
  label: string | React.ReactNode;
  value: string;
  isCurrency?: boolean;
}

interface OfferingCardCustomDataProps {
  className?: string;
  image?: React.ReactNode;
  onClick?: () => void;
  title?: string;
  detailsList?: DetailsList[];
}

export const OfferingCardCustomData: FunctionComponent<OfferingCardCustomDataProps> = ({
  className,
  image,
  onClick,
  title,
  detailsList,
}) => (
  <FlexWrapper data-cy="offering-overview-wrapper" className={className} onClick={onClick}>
    <ImgWrapper className="OfferingOverview__ImgItem">
      <OfferingImageWrapper>{image}</OfferingImageWrapper>
    </ImgWrapper>

    <InfoWrapper>
      <Heading type={4}>{title}</Heading>
    </InfoWrapper>

    {detailsList && (
      <DetailsWrapper>
        {detailsList.map(item => (
          <DetailItem>
            <DetailLabel small>{item.label}</DetailLabel>
            <DetailDescription small semiBold>
              {item.isCurrency ? <Currency value={parseFloat(item.value)} /> : item.value}
            </DetailDescription>
          </DetailItem>
        ))}
      </DetailsWrapper>
    )}
  </FlexWrapper>
);

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  position: relative;
  z-index: 2;
  padding: 10px;
  background: ${props => props.theme.colors.background_card};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  transition: box-shadow 0.3s;
  :hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  min-height: 376px;
  height: 100%;
  max-width: 376px;
  width: 100%;
  margin: 0 auto;
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const OfferingImageWrapper = styled.div`
  position: relative;
  height: 120px;
`;

const InfoWrapper = styled.div`
  margin: 20px 0;
  padding: 0 10px;
  height: auto;
  display: flex;
  min-height: 64px;
`;

const DetailsWrapper = styled.div`
  max-width: 100%;
  padding: 0 10px;
  margin: auto 0;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DetailLabel = styled(Paragraph)``;

const DetailDescription = styled(Paragraph)`
  text-align: right;
`;
