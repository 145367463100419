import { useMemo } from 'react';
import {
  allToken,
  investmentApi,
  InvestorEntityTypeValue,
  ManagementStrategy,
  PortfolioStrategyValues,
} from '@yieldstreet/platform-kit';

import { getEligibilityGates } from './getEligibilityGates';
import { filter, find, forEach, isEmpty } from 'lodash';
import { AppAction, AppActionConfig, appActionManager } from '../app-actions';
import { useAllInvestorEntities } from '../hooks';
import { getEligibilityGateContent } from './getEligibilityGateContent';
import { GateContent } from './eligibilityGateContent.model';
import { EntityMetaData } from './useAllManagedPortfolioEligibilityContent.model';

export const useAllManagedPortfolioEligibilityContent = () => {
  const { data: { eligibility } = {}, isFetching: eligibilityIsFetching } =
    investmentApi.useManagedPortfolioEligibilityQuery(allToken);

  const { investorEntities, investorEntityStatuses, isLoading } = useAllInvestorEntities();

  const isFetching = useMemo(
    () => eligibilityIsFetching || isLoading,
    [eligibilityIsFetching, isLoading]
  );

  const managedMeta = useMemo(() => {
    let managedAction: AppActionConfig<AppAction> | undefined;
    let managedGate: GateContent | undefined;

    /* Get gates with onlyIfAllGates this will not return any gate
    if 1 account is fully functional and users should be allowed 
    to go to their portfolio */

    const gates =
      eligibility &&
      getEligibilityGates(eligibility, {
        onlyIfAllGated: true,
      });

    if (isFetching || isEmpty(gates)) {
      return managedAction;
    }
    const userGate =
      gates && getEligibilityGateContent(PortfolioStrategyValues.Discretionary, gates);

    /* If no investor entties early return to show Set up portfolio */

    if (!investorEntities?.length) {
      return {
        action: userGate?.action,
        gate: userGate?.blocking ? userGate : undefined,
      };
    }

    const hasIndividual = !!filter(investorEntities, {
      type: {
        value: InvestorEntityTypeValue.INDV,
      },
    }).length;

    /* If no individual account types set action to Setup Prtoflio create entity */

    if (!hasIndividual) {
      managedAction = appActionManager.getAction(AppAction.CREATE_INVESTOR_ENTITY, {});
    }

    let entityDictionary: Record<string, EntityMetaData> = {};

    /* Create an entity dictionary to base decisions on  */

    forEach(investorEntities, investorEntity => {
      const investorEntityStatus = find(investorEntityStatuses, {
        investorEntityId: investorEntity.id,
      });

      const gate =
        eligibility &&
        getEligibilityGates(eligibility, {
          investorEntityId: investorEntity.id.toString(),
        });

      const gateData = gate
        ? getEligibilityGateContent(
            PortfolioStrategyValues.Discretionary,
            gate,
            investorEntity,
            investorEntityStatus
          )
        : undefined;

      entityDictionary = Object.assign({
        ...entityDictionary,
        [`${investorEntity.id}`]: {
          type: investorEntity.type.value,
          hasManaged: !!filter(investorEntity.investorAccounts, {
            managementStrategy: ManagementStrategy.Discretionary,
          }).length,
          gateData,
          blockingGate: gateData?.blocking,
        },
      });
    });

    const individualAccounts = filter(
      entityDictionary,
      entity => entity.type === InvestorEntityTypeValue.INDV
    );

    const nonBlockingGate = find(individualAccounts, account => {
      if (account?.gateData && !account?.gateData?.blocking && !account?.gateData?.action?.cta) {
        return account;
      }
    });

    /* If 1 of the INDV entities has a gate that is not
    blocking no CTA should show and user should be allwoed to portfolio */

    if (nonBlockingGate) {
      return;
    }

    /* If only 1 individual entity show any gate action that is next */

    if (individualAccounts.length === 1) {
      managedAction = individualAccounts[0].gateData?.action;
      managedGate = individualAccounts[0].gateData;
    }

    const hasManaged = !!filter(
      entityDictionary,
      entity => entity.hasManaged && !entity.blockingGate
    ).length;

    /* If more than 1 individual entity show setup porfolio if none have a Discretionary account
    else show Complete setup both actions should take you to the account screen */

    if (individualAccounts.length > 1 && hasManaged) {
      managedAction = appActionManager.getAction(AppAction.COMPLETE_SETUP, {});
    } else if (individualAccounts.length > 1) {
      managedAction = appActionManager.getAction(AppAction.SETUP_PORTFOLIO, {});
    }

    return { action: managedAction, gate: managedGate };
  }, [eligibility, investorEntities, isFetching, investorEntityStatuses]);

  return { ...managedMeta, isFetching };
};
