import styled, { css } from 'styled-components';

import { media } from '../../themes';
import { MarketingPhotoCardProps } from './MarketingPhotoCard.model';

const photoCardSmall = css`
  flex-direction: row;
  height: 100px;
  width: 100%;

  ${media.desktop`
    width: 368px;
  `};
`;

const photoCardRegular = css`
  flex-direction: column;
  height: 330px;
  width: 270px;

  ${media.desktop`
    width: 270px;
  `};
`;

const plusButtonSmall = css`
  border-radius: 0 4px;
  width: 32px;
  min-height: 32px;
  max-height: 32px;
`;

const plusButtonRegular = css`
  border-radius: 4px 0;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  right: 0;
`;

export const PhotoCard = styled.div<Pick<MarketingPhotoCardProps, 'small' | 'onClick'>>`
  position: relative;
  display: flex;
  background-color: ${props => props.theme.colors.background_light};
  max-width: 368px;
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows.elevation_1};
  overflow: hidden;
  cursor: ${props => props.onClick && 'pointer'};
  ${({ small }) => (small ? photoCardSmall : photoCardRegular)};

  &:hover {
    cursor: pointer;
    > button {
      background-color: ${props => props.theme.colors.input_button_background_hover};
    }
  }
`;

export const Image = styled.img<Pick<MarketingPhotoCardProps, 'small'>>`
  width: ${props => (props.small ? '108px' : '100%')};
  min-height: ${props => (props.small ? '100%' : '250px')};
`;

export const TextContainer = styled.div<Pick<MarketingPhotoCardProps, 'small'>>`
  padding: 0 0 0 16px;
  margin: auto 0;
`;

export const PlusButton = styled.button<Pick<MarketingPhotoCardProps, 'small'>>`
  position: absolute;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-size: 22px;
  bottom: 0;
  cursor: pointer;
  background-color: ${props => props.theme.colors.input_button_background};
  color: ${props => props.theme.colors.input_button_text};
  ${({ small }) => (small ? plusButtonSmall : plusButtonRegular)}

  &:hover {
    background-color: ${props => props.theme.colors.input_button_background_hover};
  }

  &:active {
    background-color: ${props => props.theme.colors.input_button_background_active};
  }
`;
