import React, { useMemo, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { LinkStyles } from '../link';
import { media } from '../../themes';
import { TextBaseStyle, TextBaseStyleProps } from './TextBase';

export type LabelProps = TextBaseStyleProps & {
  htmlFor?: string;
  regular?: boolean;
  semiBold?: boolean;
  inverse?: boolean;
  small?: boolean;
  id?: string;
  onClick?: (event: React.MouseEvent) => void;
};

export const Label: FunctionComponent<LabelProps> = ({ children, small, htmlFor, ...knobs }) => {
  const tag = useMemo(() => {
    return htmlFor ? 'label' : 'span';
  }, [htmlFor]);

  if (small) {
    return (
      <LabelSmall htmlFor={htmlFor} as={tag} {...knobs}>
        {children}
      </LabelSmall>
    );
  }
  return (
    <LabelElement htmlFor={htmlFor} as={tag} {...knobs}>
      {children}
    </LabelElement>
  );
};

const normal = css`
  font-weight: normal;
`;

const semiBold = css`
  font-weight: 500;
`;

export const LabelBase = css<LabelProps>`
  margin: 0; // override default margin
  ${TextBaseStyle}; // contains spacing styles and should be defined after margin: 0

  font-family: ${props => props.theme.typography.primary};
  ${props => (props.semiBold ? semiBold : normal)}
  color: ${props =>
    props.inverse
      ? props.theme.colors.text_label_inverse
      : props.theme.colors.text_label} !important;
  fill: ${props =>
    props.inverse
      ? props.theme.colors.text_label_inverse
      : props.theme.colors.text_label} !important;

  a {
    ${LinkStyles}
  }
`;

export const LabelStyle = css`
  ${LabelBase};
  font-size: 14px;
  line-height: 16px;
`;

export const LabelStyleSmall = css`
  ${LabelBase};
  font-size: 10px !important;
  line-height: 12px;
  ${media.tablet`
    font-size: 12px !important;
    line-height: 14px;
  `}
`;

const LabelElement = styled.label<LabelProps>`
  ${LabelStyle}
`;

const LabelSmall = styled.label<LabelProps>`
  ${LabelStyleSmall}
`;
