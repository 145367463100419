import React, { FC } from 'react';

import { Container, ContainerProps } from '../container';

import { GridProvider } from './grid/Provider';
import { PartialGridConfig } from './grid/common';
import { RowProps } from './grid/Row';

interface PageGridProps extends ContainerProps {
  children: React.ReactElement<RowProps> | React.ReactElement<RowProps>[];
  config?: PartialGridConfig;
}

export const PageGrid: FC<PageGridProps> = ({ children, config, ...props }) => {
  return (
    <GridProvider config={config}>
      <Container {...props}>{children}</Container>
    </GridProvider>
  );
};
