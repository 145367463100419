import { PortfolioStrategyValues, UserReport, UserReportTypes } from '@yieldstreet/platform-kit';

export interface SortDataProps {
  data: UserReport[];
  orderKey: StatementsListOrderTypes;
  sortKey: StatementsListSortTypes;
}

export interface UseStatementsListProps {
  investorId?: number | string;
  managementStrategy: PortfolioStrategyValues;
  params: StatementsListQueryParamsValues;
  userId?: number;
}

export const enum StatementsListSortTypes {
  Date = 'date',
  Provider = 'provider',
  Report = 'name',
}

export const enum StatementsListSortTypesLabels {
  Date = 'Date',
  Provider = 'Provider',
  Report = 'Report',
}

export const enum StatementsListOrderTypes {
  Ascending = 'asc',
  Descending = 'desc',
}

export const enum StatementsListQueryParams {
  Date = 'date',
  Order = 'orderBy',
  Sort = 'sortBy',
  Providers = 'providers',
  Report = 'reportType',
  Year = 'year',
}

export interface StatementsListQueryParamsValues {
  date: string;
  orderBy: StatementsListOrderTypes;
  providers: string | 'ALL';
  reportType: UserReportTypes | 'ALL';
  sortBy: StatementsListSortTypes;
  year: string | 'ALL';
}

export const STATEMENTS_LIST_INIT_FILTER_OPTIONS = {
  [StatementsListQueryParams.Providers]: [],
  [StatementsListQueryParams.Report]: UserReportTypes.AllReports,
  [StatementsListQueryParams.Year]: [],
};

export const STATEMENTS_LIST_INIT_SORT_OPTIONS = {
  [StatementsListQueryParams.Order]: StatementsListOrderTypes.Descending,
  [StatementsListQueryParams.Sort]: StatementsListSortTypes.Date,
};

export const STATEMENTS_LIST_VALID_SORT_VALUES = {
  [StatementsListQueryParams.Order]: [
    StatementsListOrderTypes.Ascending,
    StatementsListOrderTypes.Descending,
  ],
  [StatementsListQueryParams.Sort]: [
    StatementsListSortTypes.Date,
    StatementsListSortTypes.Provider,
    StatementsListSortTypes.Report,
  ],
};
