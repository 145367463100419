import styled from 'styled-components';
import { media, MarketingContainer } from '@yieldstreet/ui-kit';

export const Background = styled.section`
  background: ${({ theme }) => theme.colors.background_page};
  margin-bottom: ${({ theme }) => theme.spacing.l};

  ${media.desktop`
    padding: ${({ theme }) => theme.spacing.l};
    padding-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing['3xl']};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.xl};

  ${media.large`
    margin-top: ${({ theme }) => theme.spacing['2xl']};
    flex-direction: row;
  `}
`;

export const AboutItem = styled.div`
  max-width: 320px;
`;

export const Icon = styled.img`
  width: 44px;
`;

export const ModalWrapper = styled(MarketingContainer)`
  background-color: ${({ theme }) => theme.colors.background_page};
  box-shadow: ${({ theme }) => theme.shadows.elevation_1};
  padding: ${({ theme }) => theme.spacing.ml};
  max-width: 700px;
`;

export const Divider = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.border_default}`};
  margin: ${({ theme }) => theme.spacing.m} 0;
`;
