import { PortfolioStatementProviderType } from '../../../enums';
import { UserReportsYearsApiResponse, UserReportsResponse } from './reports.model';

export const userReportsResponseMock: UserReportsResponse = {
  reports: [
    {
      date: 'Aug 31, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/8',
      name: 'Aug Monthly Statement',
      type: 'MONTHLY_STATEMENT',
      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Jul 31, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/7',
      name: 'Jul Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Jun 30, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/6',
      name: 'Jun Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Jun 30, 2022',
      link: '/portfolio/statements/v2/report/2022/QUARTERLY/2',
      name: 'Q2 Quarterly Statement',
      type: 'QUARTERLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'May 31, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/5',
      name: 'May Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Apr 30, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/4',
      name: 'Apr Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Mar 31, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/3',
      name: 'Mar Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Mar 31, 2022',
      link: '/portfolio/statements/v2/report/2022/QUARTERLY/1',
      name: 'Q1 Quarterly Statement',
      type: 'QUARTERLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Feb 28, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/2',
      name: 'Feb Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Jan 31, 2022',
      link: '/portfolio/statements/v2/report/2022/MONTHLY/1',
      name: 'Jan Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Dec 31, 2021',
      link: '/portfolio/statements/v2/report/2021/MONTHLY/12',
      name: 'Dec Monthly Statement',
      type: 'MONTHLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
    {
      date: 'Dec 31, 2021',
      link: '/portfolio/statements/v2/report/2021/QUARTERLY/4',
      name: 'Q4 Quarterly Statement',
      type: 'QUARTERLY_STATEMENT',

      provider: PortfolioStatementProviderType.PERSHING,
      rangeType: 'MONTHLY',
      year: '2024',
      rangeValue: 1,
      timestamp: 123,
    },
  ],
};

export const userReportsYearsResponseMock: UserReportsYearsApiResponse = {
  success: true,
  years: [2018, 2019, 2020, 2021, 2022],
};
