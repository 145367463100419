import { styled, alpha } from '@mui/material/styles';
import MuiDivider from '@mui/material/Divider';

import { Paragraph } from '../../content/text';

interface DisclosureProps {
  show: boolean;
}

export const FooterWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.NavigationFooter.background.default,
  padding: `${theme.spacing(10)} 0`,

  '@media print': {
    display: 'none !important',
  },
}));

export const Wrap = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  margin: `0 -${theme.spacing(3)}`,

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1.5fr 1fr 1fr',
  },
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.strong}`,
  margin: `${theme.spacing(6)} 0`,
  opacity: '0.5',
}));

export const LegalTextGraph = styled('div')(({ theme }) => ({
  p: {
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.text.paragraphSecondary.inverse,
  },
  a: {
    color: theme.palette.text.paragraphSecondary.inverse,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.text.paragraphSecondary.inverse,
    },
  },
  textAlign: 'justify',
  padding: `${theme.spacing(2)} 0`,
  [theme.breakpoints.up('lg')]: {
    gridColumn: '1/span 3',
  },
}));

export const DisclosureToggle = styled('div')<DisclosureProps>(({ theme, show }) => ({
  position: show ? 'relative' : 'absolute',
  background: `linear-gradient(
    180deg,
    ${alpha(theme.palette.NavigationFooter.background.default, 0.1)} 20%,
    ${alpha(theme.palette.NavigationFooter.background.default, 0.8)} 60%,
    ${alpha(theme.palette.NavigationFooter.background.default, 0.9)} 80%
  )`,
  width: '100%',
  height: '100%',
  zIndex: 2,
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
}));

export const DisclosureToggleText = styled('span')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  color: theme.palette.text.header.inverse,
  padding: theme.spacing(6),
  width: '100%',
  textAlign: 'center',
  cursor: 'pointer',
}));

export const LegalTextContainer = styled('div')<DisclosureProps>(({ theme, show }) => ({
  padding: `${theme.spacing(2)} 0`,
  overflow: 'hidden',
  maxHeight: show ? '2000px' : '150px',
  position: 'relative',
  a: {
    color: theme.palette.text.labelPrimary.inverse,
  },
  p: {
    textAlign: 'justify',
    lineHeight: '18px',
    fontSize: '12px',
    color: theme.palette.text.labelPrimary.inverse,
    padding: `0 0 ${theme.spacing(6)}`,
  },
  '#footnote-read-more-2': {
    paddingTop: `${theme.spacing(24)} !important`,
  },
  '#footnote-read-more-3': {
    paddingTop: theme.spacing(24),
  },
}));

export const BottomMenuWrapper = styled('div')(({ theme }) => ({
  'a,p,button': {
    marginRight: theme.spacing(6),
    padding: `${theme.spacing(3)} 0 !important`,
    display: 'inline-block',
    background: 'none !important',
  },
}));

export const Copyright = styled('div')(({ theme }) => ({
  display: 'block',
  textAlign: 'left',
  padding: `${theme.spacing(6)} 0`,
  [theme.breakpoints.up('lg')]: {
    gridColumn: '1/span 3',
  },

  'a, p': {
    color: theme.palette.text.labelPrimary.inverse,
    '&:hover': {
      color: theme.palette.text.labelPrimary.inverse,
    },
  },
}));

export const MenuContainer = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(3)} 0px`,
  display: 'flex',
  flexWrap: 'wrap',
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
  },
  [theme.breakpoints.up('lg')]: {
    gridColumn: '1/span 2',
  },
}));

export const MenuSection = styled('div')(({ theme }) => ({
  width: '50%',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    width: '25%',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: 0,
  },
  '& > ul > li': {
    lineHeight: '28px',
  },

  'a,  span ': {
    color: theme.palette.text.paragraphPrimary.inverse,
    '&:hover ': {
      color: theme.palette.text.labelPrimary.inverse,
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: 'inline-block',
    minWidth: '30%',
    fontSize: '16px',
    [theme.breakpoints.up('lg')]: {
      display: 'inline',
      paddingBottom: 0,
      paddingTop: 0,
      marginBottom: theme.spacing(6),
      fontSize: '14px',
    },
    backgroundImage: 'none',
  },
}));

export const MenuSectionTitle = styled(Paragraph)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const LogoHolder = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,
  img: {
    height: '38px',
  },
  textAlign: 'center',
  a: {
    background: 'none',
    margin: `0 ${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(3)}`,
    [theme.breakpoints.up('lg')]: {
      margin: `0 0 ${theme.spacing(6)} ${theme.spacing(3)}`,
      display: 'inline-block',
    },
    lineHeight: 0,
    padding: 0,
  },
  [theme.breakpoints.up('lg')]: {
    alignSelf: 'top',
    textAlign: 'right',
  },
}));

export const AddressBarHolder = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(6),
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

export const AddressContainer = styled('div')(({ theme }) => ({
  width: '100%',
  p: {
    margin: `0 0 ${theme.spacing(3)} 0`,
    color: theme.palette.text.labelPrimary.inverse,
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      display: 'inline-block',
    },
  },
  a: {
    color: theme.palette.text.labelPrimary.inverse,
    '&:hover': {
      color: theme.palette.text.labelPrimary.inverse,
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0 0 0 ${theme.spacing(6)}`,
      display: 'inline-block',
    },
  },
}));

export const ContentHolder = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),

  [theme.breakpoints.up('lg')]: {
    margin: 0,
    textAlign: 'right',
  },
}));

export const Email = styled('a')(({ theme }) => ({
  display: 'inline-block',
  marginBottom: theme.spacing(6),
  color: theme.palette.text.labelPrimary.inverse,

  '&:hover': {
    color: theme.palette.text.labelPrimary.inverse,
  },
  [theme.breakpoints.up('lg')]: {
    margin: 0,
  },
}));

export const SocialContainer = styled(Paragraph)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  a: {
    color: theme.palette.text.labelPrimary.inverse,
    background: 'none',
    backgroundImage: 'unset !important',
    display: 'inline-block',
    margin: `0 ${theme.spacing(3)}`,
    fontSize: '20px',

    svg: {
      color: 'white',
    },
  },
  [theme.breakpoints.up('lg')]: {
    display: 'inline-block',
    width: 'auto',
    justifyContent: 'space-between',
  },
}));
