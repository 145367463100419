import React, { ReactNode, FunctionComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
import { Container } from '../../layout/container/Container';

import { MarketingContainer } from '../../layout';
import { CardsCarouselProps } from '../../elements/cards-carousel';
import { theme2 } from '../../themes';
import { TextBlockProps } from '../../sections/text-block-component/TextBlock.model';

type Position = 'oversize' | 'expanded' | 'fit' | 'contained';
type Background = 'primary' | 'secondary';

const getBgType = (background?: Background) => {
  switch (background) {
    case 'primary':
      return theme2.gradients.radial_primary;
    case 'secondary':
      return theme2.gradients.radial_secondary;
    default:
      return 'transparent';
  }
};

interface MarketingContentBannerCarouselProps extends Omit<TextBlockProps, 'align'> {
  carousel: React.ReactElement<CardsCarouselProps>;
  image?: ReactNode;
  reverse?: boolean;
  inverse?: boolean;
  children?: ReactNode;
  imagePosition?: Position;
  background?: Background;
}

export const MarketingContentBannerCarousel: FunctionComponent<
  MarketingContentBannerCarouselProps
> = ({
  carousel,
  reverse,
  inverse,
  label,
  heading,
  content,
  button,
  mobileAlign = 'start',
  background,
  stats,
}) => {
  return (
    <MarketingContainer>
      <Wrapper>
        <Background background={background} reverse={reverse} inverse={inverse}>
          <CarouselContainer reverse={reverse}>{carousel}</CarouselContainer>
          <TextContainer>
            <TextBlock
              label={label}
              heading={heading}
              headingType={2}
              content={content}
              align="start"
              mobileAlign={mobileAlign}
              inverse={!inverse}
              button={button}
              stats={stats}
            />
          </TextContainer>
        </Background>
      </Wrapper>
    </MarketingContainer>
  );
};

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
`;

const Background = styled.div<
  Pick<MarketingContentBannerCarouselProps, 'inverse' | 'reverse' | 'background'>
>`
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.inverse ? props.theme.colors.background_light : getBgType(props.background)};
  padding: 20px;

  ${media.large<Pick<MarketingContentBannerCarouselProps, 'reverse'>>`
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
    border-radius: 8px;
    align-items: center;
    min-height: 370px;
    padding: 72px 64px;
  `}

  position: relative;
`;

const TextContainer = styled.div`
  padding: 0px 24px 0 24px;
  ${media.large`
    width: 375px;
    padding: 0px;
  `}
`;

const CarouselContainer = styled.div<{ reverse?: boolean }>`
  left: 0;
  position: relative;
  top: -40px;
  z-index: 100;
  ${media.large<{ reverse?: boolean }>`
    left: ${props => (props.reverse ? '12%' : '50%')};
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  `}
`;
