import React from 'react';
import { useRive } from 'rive-react';
import { genTestIds } from '@yieldstreet/tool-kit';

import { LoadingLogoProps, LoadingLogoType, logoVariations } from './LoadingLogo.model';
import { LogoWrapper } from './LoadingLogo.style';

const { useTestIds } = genTestIds();

export const LoadingLogo = ({ testId, size, type = LoadingLogoType.WHITE }: LoadingLogoProps) => {
  const testIds = useTestIds({ testId: testId || 'loadingLogo' });

  const { RiveComponent } = useRive({
    src: logoVariations[type],
    autoplay: true,
  });

  return (
    <LogoWrapper dimensions={size} {...testIds.base.attr}>
      <RiveComponent className="rive-canvas-size" />
    </LogoWrapper>
  );
};
