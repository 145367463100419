import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonContainer = styled('div')(() => ({
  flex: '1 0',
  marginLeft: 'auto',
  maxWidth: '284px',
  textAlign: 'center',
}));

export const DetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing(6),

  [theme.breakpoints.up('lg')]: {
    alignItems: 'center',
    padding: `0 ${theme.spacing(3)} ${theme.spacing(8)}`,
  },
}));

export const EarningsContainer = styled('div')(({ theme }) => ({
  flex: '1 0',
  maxWidth: '280px',

  [theme.breakpoints.up('lg')]: {
    borderRight: `1px solid ${theme.palette.border.default}`,
  },
}));

export const Label = styled(Typography)<{ inverse?: boolean; semiBold?: boolean }>(
  ({ inverse, semiBold, theme }) => ({
    color: inverse
      ? theme.palette.text.labelPrimary.inverse
      : theme.palette.text.labelPrimary.default,
    fontWeight: semiBold ? 500 : 400,
  })
);

export const MobileTargetContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(1),
}));

export const OuterContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8),
  },
}));

export const Paragraph = styled(Typography)<{ inverse?: boolean; semiBold?: boolean }>(
  ({ inverse, semiBold, theme }) => ({
    color: inverse
      ? theme.palette.text.paragraphPrimary.inverse
      : theme.palette.text.paragraphPrimary.default,
    fontWeight: semiBold ? 500 : 400,
  })
);

export const TargetContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(5)}`,
  },
}));

export const TotalInvestment = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: `-${theme.spacing(7.5)}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(5),
  width: '100%',
  boxShadow: '0px 8px 16px -8px rgba(0, 0, 0, 0.25), 0px 13px 27px -5px rgba(50, 50, 93, 0.2)',
  borderRadius: '4px',
  background: theme.palette.background.default,
}));
