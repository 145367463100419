import { css } from 'styled-components';

import { sizeCss } from '../../utils/sizes';
import {
  SpacingStyleProps,
  PaddingPropKeys,
  MarginPropKeys,
  PaddingKeyEnum,
  MarginKeyEnum,
  MarginKeys,
} from './Spacing.model';
import { sanitizeSpacingProps } from './Spacing.utils';
import { Spacing } from '../../themes';

export const getSizeVar = (key: Spacing = 'm') => `--y-spacing-${key}`;

const styleMapper = (props: SpacingStyleProps) => {
  const getStyleAttribute = (key: PaddingPropKeys | MarginPropKeys) => {
    return MarginKeys.includes(key as MarginPropKeys) ? 'margin' : 'padding';
  };

  return Object.entries(props).map(entry => {
    const [attribute, spacingKey] = entry;

    if (spacingKey && typeof spacingKey === 'boolean' && process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        'Boolean values for spacing are being deprecated. Please use a spacing token instead.'
      );
    }

    const sanitizedSpacingKey = spacingKey && typeof spacingKey === 'boolean' ? 'm' : spacingKey;

    if (!sanitizedSpacingKey) {
      return css``;
    }

    const cssSpacingVar = getSizeVar(sanitizedSpacingKey as Spacing);

    switch (attribute) {
      case PaddingKeyEnum.All:
      case MarginKeyEnum.All:
        return css`
          ${getStyleAttribute(attribute)}: var(${cssSpacingVar});
        `;
      case PaddingKeyEnum.Horizontal:
      case MarginKeyEnum.Horizontal:
        return css`
          ${getStyleAttribute(attribute)}-left: var(${cssSpacingVar});
          ${getStyleAttribute(attribute)}-right: var(${cssSpacingVar});
        `;
      case PaddingKeyEnum.Vertical:
      case MarginKeyEnum.Vertical:
        return css`
          ${getStyleAttribute(attribute)}-top: var(${cssSpacingVar});
          ${getStyleAttribute(attribute)}-bottom: var(${cssSpacingVar});
        `;
      case PaddingKeyEnum.Top:
      case MarginKeyEnum.Top:
        return css`
          ${getStyleAttribute(attribute)}-top: var(${cssSpacingVar});
        `;
      case PaddingKeyEnum.Right:
      case MarginKeyEnum.Right:
        return css`
          ${getStyleAttribute(attribute)}-right: var(${cssSpacingVar});
        `;
      case PaddingKeyEnum.Bottom:
      case MarginKeyEnum.Bottom:
        return css`
          ${getStyleAttribute(attribute)}-bottom: var(${cssSpacingVar});
        `;
      case PaddingKeyEnum.Left:
      case MarginKeyEnum.Left:
        return css`
          ${getStyleAttribute(attribute)}-left: var(${cssSpacingVar});
        `;
      default:
        return css``;
    }
  });
};

const getSpacingStyle = (props: SpacingStyleProps) => {
  const sanitizedProps = sanitizeSpacingProps(props);

  return sizeCss(styleMapper, sanitizedProps, {});
};

export const SpacingStyle = css<SpacingStyleProps>`
  ${props => getSpacingStyle(props)}
`;
