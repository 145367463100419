import { genTestIds } from '@yieldstreet/tool-kit';

import { RowData } from './components/progress-table-row';

export const { getTestIds, useTestIds } = genTestIds([
  'header',
  'header-left',
  'header-right',
  'row',
]);

export interface ProgressTableProps {
  leftHeading: string;
  rightHeading: string;
  rowData: RowData[];
}
