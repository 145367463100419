import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';

export const PartnersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const PartnersImage = styled.img`
  width: 100%;
  max-width: 150px;

  ${media.large`
    max-width: 250px;
  `}
`;
