import { genTestIds } from '@yieldstreet/tool-kit';

export const { useTestIds } = genTestIds([
  'title',
  'subtitle',
  'dropzone',
  'image-preview-accepted',
  'image-preview-rejected',
  'document-preview-accepted',
  'document-preview-rejected',
]);
