import type { BaseQueryFn } from '@reduxjs/toolkit/query';

import { BaseQueryConfig, BASE_CONFIG } from './base-config';
import { queryParser } from './query-parser';
import { ApiError } from './types';
import { httpRequest, HttpRequestParams } from './http-service';

type RestQueryProps = Omit<HttpRequestParams, 'paramsSerializer'>;

export const baseRestQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<RestQueryProps, unknown, ApiError> =>
  async ({ url, ...params }: RestQueryProps) => {
    return await httpRequest({
      ...params,
      url: baseUrl + url,
      paramsSerializer: (params: any) => queryParser(params),
    })
      .then(result => ({ data: result.data, meta: result.headers }))
      .catch((error: ApiError) => ({ error }));
  };

export const BASE_REST_CONFIG: BaseQueryConfig = {
  ...BASE_CONFIG,
  baseQuery: baseRestQuery(),
};
