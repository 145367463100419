import { AssetTypes } from '../../enums/asset-types';

export enum ManagedPortfolioStrategies {
  INCOME = 'INCOME',
  GROWTH = 'GROWTH',
  BALANCED = 'BALANCED',
}

export enum StrategySuggestionStatus {
  PENDING_QUIZ_ANSWERS = 'PENDING_QUIZ_ANSWERS',
  COMPLETED = 'COMPLETED',
}

export enum ManagedPortfolioRisks {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum ManagedPortfolioRisksMap {
  LOW = 'Lower',
  MEDIUM = 'Moderate',
  HIGH = 'Higher',
}

export enum ManagedPortfolioAssetClasses {
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
  PRIVATE_CREDIT = 'PRIVATE_CREDIT',
  REAL_ESTATE = 'REAL_ESTATE',
}

export enum ManagedPortfolioAssetTypes {
  CREDIT = 'CREDIT',
  EQUITY = 'EQUITY',
  CASH = 'CASH',
}

export enum ManagedPortfolioAssetTypeLabels {
  CASH = 'Cash Reserve',
  CREDIT = 'Credit',
  EQUITY = 'Equity',
}

export type AssetClassAlocation = {
  allocationPercentage: number;
  assetClass: ManagedPortfolioAssetClasses;
};

export type AssetTypeAllocation = {
  allocationPercentage: number;
  assetType: AssetTypes;
};

export type EligibleOfferings = {
  priority: number;
  noteUrlHash: string;
};

export type Estimates = {
  from: number;
  to: number;
  level: number;
};

export type AvailableStrategies = {
  strategyId: string;
  title: string;
  description: string;
  assetClassAllocations: AssetClassAlocation[];
  assetTypeAllocations: AssetTypeAllocation[];
  eligibleOfferings: EligibleOfferings[];
  investmentStrategyType: ManagedPortfolioStrategies;
  estimatedIncomeReturns: Estimates;
  estimatedAppreciations: Estimates;
  estimatedTotalReturns: Estimates;
  liquidityRisk: ManagedPortfolioRisks;
  risk: ManagedPortfolioRisks;
  reservePercentage: number;
  minimumDepositAmount: number;
};
