import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { LinkStyles } from '../link';
import { TextBaseStyle, TextBaseStyleProps } from './TextBase';

type TableProps = TextBaseStyleProps & {
  regular?: boolean;
  semiBold?: boolean;
  inverse?: boolean;
};

export const Table: FunctionComponent<TableProps> = ({ children, ...knobs }) => (
  <TableElement as="span" {...knobs}>
    {children}
  </TableElement>
);

const normal = css`
  font-weight: normal;
`;

const semiBold = css`
  font-weight: 500;
`;

export const TableBase = css<TableProps>`
  font-family: ${props => props.theme.typography.primary};
  ${props => (props.semiBold ? semiBold : normal)}
  color: ${props =>
    props.inverse ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_paragraph};
  margin: 0;

  a {
    ${LinkStyles}
  }
`;

export const TableStyle = css`
  font-size: 14px;
  line-height: 19px;
  ${TableBase}
`;

const TableElement = styled.span<TableProps>`
  ${TableStyle};
  ${TextBaseStyle};
`;
