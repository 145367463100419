import { Form, Formik } from 'formik';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Button, Heading, Label, Paragraph } from '../../content';
import { FormTextInput } from '../../form';
import { Spinner } from '../../layout/spinner';
import { media } from '../../themes';

interface EmailCaptureProps {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  buttonText: string;
  disclaimer?: string | ReactNode;
  onSubmit?: (x: any) => void;
  className?: string;
}

type FormikEmail = {
  email: string;
};

export const EmailCapture = ({
  title,
  subtitle,
  buttonText,
  disclaimer,
  onSubmit,
  className,
}: EmailCaptureProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values: FormikEmail) => {
    setIsSubmitting(true);
    onSubmit && (await onSubmit({ email: values.email }));
    setIsSubmitting(false);
    setSubmitted(true);
  };

  return (
    <Wrapper className={className}>
      <Content>
        <Heading type={3} tagType={4}>
          {title}
        </Heading>
        <Subtitle>{subtitle}</Subtitle>
        {submitted ? (
          <Paragraph small>
            Thanks for submitting your email! You’ll now receive the latest alternative investing
            insights straight to your inbox!
          </Paragraph>
        ) : (
          <>
            <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
              {formikProps => (
                <Form>
                  <SidedButton>
                    <FormTextInput
                      name="email"
                      type="email"
                      required
                      formikProps={formikProps}
                      label="Email address"
                      placeholder="Email address"
                      fullWidth
                    />
                    <SButton type="submit" reducedPadding buttonType="primary">
                      {isSubmitting ? <Spinner small /> : buttonText}
                    </SButton>
                  </SidedButton>
                </Form>
              )}
            </Formik>
            <Disclaimer>{disclaimer}</Disclaimer>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  ${media.desktop`
    max-width: 540px;
  `}
`;

const SidedButton = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    height: 60px;
    width: 540px;
    justify-content: center;
  `}
`;

const SButton = styled(Button)`
  width: 100%;
  ${media.desktop`
    margin-left: 20px;
    min-width: 169px;
    padding: 14px;
    width: unset;
  `}
`;

const Subtitle = styled(Paragraph)`
  margin: 10px 0 40px;
`;

const Disclaimer = styled(Label)`
  display: block;
  margin: 20px 0;
`;
