import { BankAccountsEntityGetApiResponse, BankAccountsGetApiResponse } from '../../apis';
import {
  BankAccountExternalPermission,
  BankAccountHolderType,
  BankAccountType,
  BankAccountUsageType,
  ExternalIdentityProvider,
  PaymentMethodType,
  PortfolioStrategyValues,
} from '../../enums';
import { BankAccount, BankAccountProvider, BankAccountProvisionState } from '../../types';

export const bankAccounts: Array<BankAccount> = [
  {
    id: 90774,
    userId: 4,
    holderId: 201509,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'SSN Test',
    nickname: 'SynapsePay Test Checking Account - 8901',
    accountType: BankAccountType.BUSI,
    accountBalance: 800,
    accruedInterest: 0,
    pendingMoneyIn: 0,
    pendingMoneyOut: 0,
    pendingBalance: null,
    availableBalance: null,
    totalBalance: null,
    availableWithdrawals: 0,
    nameOnAccount: 'SSN Test',
    bankName: 'CAPITAL ONE  N A ',
    bankRoutingNumber: 'XXXXX0407',
    maskedAccountNumber: 'XXXXX8901',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.ACH,
    externalId: '5eaaffc585e72e1377ca582a',
    lastPayInUsageDate: null,
    lastPayOutUsageDate: null,
    depositVerificationPending: false,
    removable: true,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: false,
    accountTypeDisplayName: 'Business Checking',
    paymentMethodDisplayName: 'ACH Bank Transfer',
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: 'CAPITAL ONE  N A  - 8901',
    provider: BankAccountProvider.SYNAPSE,
  },
  {
    id: 90775,
    userId: 4,
    holderId: 201509,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'SSN Test',
    nickname: 'SynapsePay Test Savings Account - 8902',
    accountType: BankAccountType.SAVE,
    accountBalance: 750,
    accruedInterest: 0,
    pendingMoneyIn: 0,
    pendingMoneyOut: 0,
    pendingBalance: null,
    availableBalance: null,
    totalBalance: null,
    availableWithdrawals: 0,
    nameOnAccount: 'SSN Test',
    bankName: 'CAPITAL ONE  N A ',
    bankRoutingNumber: 'XXXXX0407',
    maskedAccountNumber: 'XXXXX8902',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.ACH,
    externalId: '5eaaffc6e1a806306a5a90e4',
    lastPayInUsageDate: null,
    lastPayOutUsageDate: null,
    depositVerificationPending: false,
    removable: true,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: false,
    accountTypeDisplayName: 'Savings',
    paymentMethodDisplayName: 'ACH Bank Transfer',
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: 'CAPITAL ONE  N A  - 8902',
    provider: BankAccountProvider.SYNAPSE,
  },
  {
    id: 90985,
    userId: 4,
    holderId: 201509,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'SSN Test',
    nickname: 'YieldStreet',
    accountType: BankAccountType.YWLT,
    accountBalance: 59697.04,
    accruedInterest: 0.02,
    pendingMoneyIn: 0,
    pendingMoneyOut: 37034,
    pendingBalance: -37034,
    availableBalance: 22663.04,
    totalBalance: 22663.04,
    availableWithdrawals: 90,
    nameOnAccount: 'SSN Test',
    bankName: '',
    bankRoutingNumber: 'XXXXXXXXX',
    maskedAccountNumber: 'XXXXXXXXX',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.SYNP,
    externalId: '5ecbba3ac54d7b72468a64a1',
    lastPayInUsageDate: 1606999954000,
    lastPayOutUsageDate: 1606999954000,
    depositVerificationPending: false,
    removable: false,
    usages: [
      {
        investmentId: 88111,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 88573,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 90907,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91278,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91318,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91339,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91447,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91448,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91449,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91571,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 91575,
        usageType: BankAccountUsageType.PAYI,
      },
      {
        investmentId: 88111,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 88573,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 90907,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 91278,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 91339,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 91447,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 91448,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 91449,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 91571,
        usageType: BankAccountUsageType.PAYO,
      },
      {
        investmentId: 91575,
        usageType: BankAccountUsageType.PAYO,
      },
    ],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: true,
    accountTypeDisplayName: 'YieldStreet',
    paymentMethodDisplayName: 'Deposit Account',
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: ' - XXXX',
    minimumDeposit: 10000,
    minimumWithdrawal: 0.01,
    limitPerTransaction: 250000,
    investorAccountManagementStrategy: PortfolioStrategyValues.Self,
    provider: BankAccountProvider.SYNAPSE,
  },
];

export const plaidBankAccounts: Array<BankAccount> = [
  {
    minimumWithdrawal: 0.01,
    incrementalDeposit: null,
    numberOfDeposits: null,
    limitPerTransaction: 250000,
    id: 616610,
    userId: 538766,
    holderId: 4194122,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'Leslie Knope',
    nickname: 'Plaid Checking',
    accountType: BankAccountType.CHKG,
    accountBalance: 0,
    accruedInterest: 0,
    pendingMoneyIn: 0,
    pendingMoneyOut: 0,
    pendingBalance: null,
    availableBalance: null,
    totalBalance: null,
    availableWithdrawals: 0,
    nameOnAccount: 'Leslie Knope',
    bankName: 'Plaid Gold Standard 0% Interest Checking',
    bankRoutingNumber: 'XXXXXXXXX',
    maskedAccountNumber: 'XXXXX0000',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.ACH,
    externalId: '52671b1c-319d-4a11-824a-f4a99138ce73',
    lastPayInUsageDate: null,
    lastPayOutUsageDate: null,
    depositVerificationPending: false,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.UNIT,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: false,
    provider: BankAccountProvider.PLAID,
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: 'Plaid Gold Standard 0% Interest Checking - 0000',
    last4Digits: '0000',
    removable: true,
    paymentMethodDisplayName: 'ACH Bank Transfer',
    accountTypeDisplayName: 'Checking',
  },
  {
    minimumWithdrawal: 0.01,
    incrementalDeposit: null,
    numberOfDeposits: null,
    limitPerTransaction: 250000,
    id: 616615,
    userId: 538766,
    holderId: 4194122,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'Leslie Knope',
    nickname: 'Plaid Checking',
    accountType: BankAccountType.CHKG,
    accountBalance: 0,
    accruedInterest: 0,
    pendingMoneyIn: 0,
    pendingMoneyOut: 0,
    pendingBalance: null,
    availableBalance: null,
    totalBalance: null,
    availableWithdrawals: 0,
    nameOnAccount: 'Leslie Knope',
    bankName: 'Plaid Gold Standard 0% Interest Checking',
    bankRoutingNumber: 'XXXXXXXXX',
    maskedAccountNumber: 'XXXXX0000',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.ACH,
    externalId: '6c5cae5f-bd75-4625-a2a2-f520d9ee3304',
    lastPayInUsageDate: null,
    lastPayOutUsageDate: null,
    depositVerificationPending: false,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.PLAID,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: false,
    provider: BankAccountProvider.PLAID,
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: 'Plaid Gold Standard 0% Interest Checking - 0000',
    last4Digits: '0000',
    removable: true,
    paymentMethodDisplayName: 'ACH Bank Transfer',
    accountTypeDisplayName: 'Checking',
  },
];

export const bankAccountsWithLimits: Array<BankAccount> = [
  {
    id: 90800,
    userId: 4,
    holderId: 201924,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'SSN Test',
    nickname: 'SynapsePay Test Checking Account - 8901',
    accountType: BankAccountType.BUSI,
    accountBalance: 800,
    accruedInterest: 0,
    pendingMoneyIn: 0,
    pendingMoneyOut: 0,
    pendingBalance: null,
    availableBalance: null,
    totalBalance: null,
    availableWithdrawals: 0,
    nameOnAccount: 'SSN Test',
    bankName: 'CAPITAL ONE  N A ',
    bankRoutingNumber: 'XXXXX0407',
    maskedAccountNumber: 'XXXXX8901',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.ACH,
    externalId: '5eaaffc585e72e1377ca582a',
    lastPayInUsageDate: null,
    lastPayOutUsageDate: null,
    depositVerificationPending: false,
    removable: true,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: false,
    accountTypeDisplayName: 'Business Checking',
    paymentMethodDisplayName: 'ACH Bank Transfer',
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: 'CAPITAL ONE  N A  - 8901',
    provider: BankAccountProvider.SYNAPSE,
  },
  {
    id: 90801,
    userId: 4,
    holderId: 201924,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'SSN Test',
    nickname: 'SynapsePay Test Savings Account - 8902',
    accountType: BankAccountType.SAVE,
    accountBalance: 750,
    accruedInterest: 0,
    pendingMoneyIn: 0,
    pendingMoneyOut: 0,
    pendingBalance: null,
    availableBalance: null,
    totalBalance: null,
    availableWithdrawals: 0,
    nameOnAccount: 'SSN Test',
    bankName: 'CAPITAL ONE  N A ',
    bankRoutingNumber: 'XXXXX0407',
    maskedAccountNumber: 'XXXXX8902',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.ACH,
    externalId: '5eaaffc6e1a806306a5a90e4',
    lastPayInUsageDate: null,
    lastPayOutUsageDate: null,
    depositVerificationPending: false,
    removable: true,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: false,
    accountTypeDisplayName: 'Savings',
    paymentMethodDisplayName: 'ACH Bank Transfer',
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: 'CAPITAL ONE  N A  - 8902',
    provider: BankAccountProvider.SYNAPSE,
  },
  {
    id: 90982,
    userId: 4,
    holderId: 201924,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'SSN Test',
    nickname: 'YieldStreet',
    accountType: BankAccountType.YWLT,
    accountBalance: 59697.04,
    accruedInterest: 0.02,
    pendingMoneyIn: 0,
    pendingMoneyOut: 37034,
    pendingBalance: -37034,
    availableBalance: 22663.04,
    totalBalance: 22663.04,
    availableWithdrawals: 90,
    nameOnAccount: 'SSN Test',
    bankName: '',
    bankRoutingNumber: 'XXXXXXXXX',
    maskedAccountNumber: 'XXXXXXXXX',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.SYNP,
    externalId: '5ecbba3ac54d7b72468a64a1',
    lastPayInUsageDate: 1606999954000,
    lastPayOutUsageDate: 1606999954000,
    depositVerificationPending: false,
    removable: false,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: true,
    accountTypeDisplayName: 'YieldStreet',
    paymentMethodDisplayName: 'Deposit Account',
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: ' - XXXX',
    minimumDeposit: 10000,
    minimumWithdrawal: 0.01,
    incrementalDeposit: null,
    limitPerTransaction: 250000,
    investorAccountManagementStrategy: PortfolioStrategyValues.Self,
    provider: BankAccountProvider.SYNAPSE,
  },
  {
    id: 90983,
    userId: 4,
    holderId: 307123,
    provisionState: BankAccountProvisionState.CONFIRMED,
    holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
    holderName: 'SSN Test',
    nickname: 'YieldStreet',
    accountType: BankAccountType.YWLT,
    accountBalance: 59697.04,
    accruedInterest: 0.02,
    pendingMoneyIn: 0,
    pendingMoneyOut: 37034,
    pendingBalance: -37034,
    availableBalance: 22663.04,
    totalBalance: 22663.04,
    availableWithdrawals: 90,
    nameOnAccount: 'SSN Test',
    bankName: '',
    bankRoutingNumber: 'XXXXXXXXX',
    maskedAccountNumber: 'XXXXXXXXX',
    tokenizedAccountNumber: '1,SYNAPSEPAY,',
    specialInstructions: null,
    paymentMethod: PaymentMethodType.SYNP,
    externalId: '5ecbba3ac54d7b72468a64a1',
    lastPayInUsageDate: 1606999954000,
    lastPayOutUsageDate: 1606999954000,
    depositVerificationPending: false,
    removable: false,
    usages: [],
    externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
    externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
    microDepositsReinitiatedCount: 0,
    externalVerificationAttempts: 0,
    smallDepositsAllowed: true,
    accountTypeDisplayName: 'YieldStreet',
    paymentMethodDisplayName: 'Deposit Account',
    microDepositsReinitiateRemaining: 3,
    verificationAttemptsRemaining: 5,
    bankAccountNameWithLast4Digits: ' - XXXX',
    minimumDeposit: 50000,
    minimumWithdrawal: 0.01,
    incrementalDeposit: 25000,
    limitPerTransaction: 250000,
    investorAccountManagementStrategy: PortfolioStrategyValues.Discretionary,
    provider: BankAccountProvider.SYNAPSE,
  },
];

export const getBankAccountsResponse: BankAccountsGetApiResponse = {
  bankAccounts: bankAccounts,
  canAdd: true,
  investorEntities: {
    201509: {
      canAdd: true,
    },
  },
  success: true,
};

export const getEntityBankAccountsResponse: BankAccountsEntityGetApiResponse = {
  bankAccounts: bankAccounts,
  canAdd: true,
  success: true,
};
