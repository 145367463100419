import { styled } from '@mui/material/styles';

export const CloseIcon = styled('img')(() => ({
  verticalAlign: 'middle',
}));

export const Wrapper = styled('button')(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(6),
  right: theme.spacing(6),
  width: '36px',
  height: '36px',
  border: 'none',
  borderRadius: '8px',
  boxShadow: '0px 2px 5px -1px rgba(50, 50, 93, 0.2)',
  background: theme.palette.common.white,
  cursor: 'pointer',
  zIndex: 2000,

  [theme.breakpoints.up('sm')]: {
    top: 'unset',
    right: 'unset',
    margin: 'unset',
    marginRight: '-50px',
  },
}));
