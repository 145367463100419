import Big from 'big.js';
import { isNumber, isString } from 'lodash';

import { BASE_RADIUS } from './theme.model';

const multiplyBaseRadius = (multiplier: number) => `${new Big(multiplier).mul(BASE_RADIUS)}px`;

export const getBorderRadius = (multiplier: number | string) => {
  if (isNumber(multiplier)) {
    return multiplyBaseRadius(multiplier);
  }

  if (isString(multiplier)) {
    const asNumber = +multiplier;
    return isNumber(asNumber) ? multiplyBaseRadius(asNumber) : 0;
  }

  return 0;
};
