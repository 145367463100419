import styled from 'styled-components';

import { DropdownMenuListProps } from './DropdownMenu.model';

export const DropdownMenuWrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

export const DropdownMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const DropdownMenuIcon = styled.img`
  margin-left: ${({ theme }) => theme.spacing.xs};
`;

export const DropdownMenuList = styled.ul<DropdownMenuListProps>`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 240px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.background_card};
  border: 1px solid ${({ theme }) => theme.colors.border_default};
  box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.2);
  transition: all 300ms;
  opacity: ${({ open }) => (open ? '1' : '0')};
  transform: translateY(${({ open, theme }) => (open ? theme.spacing.sm : '0')});
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
`;

export const DropdownMenuItem = styled.li`
  white-space: nowrap;
  padding: 12px 20px;
  border-radius: 3px;
  cursor: pointer;
  color: rgb(26, 27, 29);
  transition: all 0.2s ease 0s;

  &:hover {
    background: ${({ theme }) => theme.colors.border_default};
  }
`;
