import styled from 'styled-components';

import { IconWrapperProps } from './Icon.model';

export const IconWrapper = styled.div<IconWrapperProps>`
  background-color: ${props => props.color || 'transparent'};
  height: 100%;
  mask-image: ${props => `url(${props.icon})`};
  mask-position: center;
  mask-repeat: no-repeat;
  width: 100%;
`;
