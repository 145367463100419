import type { Components, CssVarsTheme } from '@mui/material';

export const MuiTypography: Components<CssVarsTheme>['MuiTypography'] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      return {
        variants: [
          {
            props: { inverse: true },
            style: ({ theme }) => {
              return {
                //@ts-ignore
                color: theme.palette?.[ownerState?.color ?? 'primary']?.contrastText,
              };
            },
          },
        ],
      };
    },
  },
};
