import { genTestIds } from '@yieldstreet/tool-kit';

export type ProductTypeProps = {
  image: string;
  title: string;
  content: string | React.ReactNode;
  testId: string;
  onClick: () => void;
};

export const { useTestIds } = genTestIds(['image', 'title', 'content']);
