import styled from 'styled-components';

import { Label } from '../../content/text';
import { LinkStyles } from '../../content/link';
import { Surface } from '../surface';

export const Container = styled(Surface)`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

export const Content = styled.div`
  max-height: 600px;
  overflow-y: auto;
  a {
    ${LinkStyles}
  }
`;

export const SubHeadingWrapper = styled(Label)`
  margin: ${({ theme }) => `0 0 ${theme.spacing.s} ${theme.spacing.l}`};
`;

export const ExtraWrapper = styled.div``;

export const TitleWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
`;

export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.m};
`;
