import { PortfolioDocumentProvider } from '../../../enums';
import {
  PortfolioDocumentsYearsApiResponse,
  PortfolioDocumentsResponse,
  TaxDocument,
} from './documents.model';

export const taxDocumentMock: TaxDocument = {
  id: 984,
  investorAccountId: 1,
  loanId: 3080,
  noteUrlHash: '1099',
  year: 2016,
  type: 'K-1',
  url: 'https://ys-docs-production.s3',
  expectedDate: '2020-10-10',
  investmentName: 'Diversified Pre-settlement Portfolio VII',
  investorAccountName: 'Luz Schuster',
};

export const downloadTaxDocumentParamsMock = {
  userId: 1,
  taxDocumentIds: ['1', '2', '3'],
};

export const portfolioDocumentsResponseMock: PortfolioDocumentsResponse = {
  documents: {
    2016: [
      {
        expectedDate: '',
        id: 1287,
        investmentName: 'Diversified Pre-settlement Portfolio V',
        investorAccountId: 1547,
        investorAccountName: "Earlene O'Reilly",
        loanId: 3034,
        noteUrlHash: '1040',
        type: 'K-1',
        year: 2016,
        url: 'https://ys-docs-production.s3.amazonaws.com/prod/INVESTMENTS/359/pzSR9ICRLmJKeG5NeEsTYM8H.pdf?AWSAccessKeyId=AKIAINGKRR3JJLDHC5KQ&Expires=1567002306&Signature=03sku2GJeaIq7qkxn7Df3T3oO%2F4%3D',
        provider: PortfolioDocumentProvider.YS,
        publishedDate: '2022-05-12',
      },
      {
        expectedDate: '',
        id: 384,
        investmentName: 'Top 150 Law Firm - Settled Case Loan',
        investorAccountId: 1547,
        investorAccountName: "Earlene O'Reilly",
        loanId: 3848,
        noteUrlHash: '725',
        type: 'K-1',
        year: 2016,
        url: 'https://ys-docs-production.s3.amazonaws.com/prod/INVESTMENTS/319/Ud0nA9j-at0Bs8I7mXWCjJ3S.pdf?AWSAccessKeyId=AKIAINGKRR3JJLDHC5KQ&Expires=1567002306&Signature=UbCoBj%2BgcV5SB04RB%2BacKSVpwSI%3D',
        provider: PortfolioDocumentProvider.SYNAPSE,
        publishedDate: '2022-05-12',
      },
    ],
    2017: [
      {
        expectedDate: '',
        id: 65901,
        investmentName: 'Commercial Real Estate Portfolio I',
        investorAccountId: 1547,
        investorAccountName: "Earlene O'Reilly",
        loanId: 12225,
        noteUrlHash: '1476',
        type: 'K-1',
        year: 2017,
        url: 'https://ys-docs-production.s3.amazonaws.com/prod/INVESTMENTS/1623/zftY1t5WBMDUoF3EOuMtnXdP.pdf?AWSAccessKeyId=AKIAINGKRR3JJLDHC5KQ&Expires=1567002306&Signature=zY%2BxO6sIhXyYqs8sgRo%2BLRDYxRk%3D',
        provider: PortfolioDocumentProvider.YS,
        publishedDate: '2022-05-12',
      },
      {
        expectedDate: '',
        id: 59859,
        investmentName: 'Nationally Recognized Law Firm Financing',
        investorAccountId: 1547,
        investorAccountName: "Earlene O'Reilly",
        loanId: 4537,
        noteUrlHash: '721',
        type: 'K-1',
        year: 2017,
        url: 'https://ys-docs-production.s3.amazonaws.com/prod/INVESTMENTS/345/YgP7fDfj8uiFE5_JJfwh8SC1.pdf?AWSAccessKeyId=AKIAINGKRR3JJLDHC5KQ&Expires=1567002306&Signature=EwCMGjFUmLCNShrcu0KYmTYyxk0%3D',
        provider: PortfolioDocumentProvider.SYNAPSE,
        publishedDate: '2022-05-12',
      },
    ],
  },
};

export const portfolioDocumentsYearsResponseMock: PortfolioDocumentsYearsApiResponse = {
  success: true,
  years: [2018, 2019, 2020, 2021, 2022],
};
