import React, { FunctionComponent } from 'react';
import { BaseInputProps } from '@yieldstreet/tool-kit';
import {
  SFormControlLabel,
  SFormHelperText,
  SFormControl,
  withFormikWeb,
  FormError,
} from '../common';
import { SSwitch } from './styles';

export interface ToggleInputProps extends BaseInputProps<boolean> {}

export const ToggleInput: FunctionComponent<ToggleInputProps> = props => {
  const { label, value, error, onChange, caption, className, disabled, ...otherProps } = props;
  return (
    <SFormControl error={!!error} className={className} disabled={disabled}>
      <SFormControlLabel
        label={label}
        value={value}
        control={
          <SSwitch
            {...otherProps}
            data-cy={otherProps.testId || 'toggle-input'}
            color="primary"
            checked={value}
            onChange={onChange && (e => onChange(e.target.checked))}
          />
        }
      />
      {error || caption ? (
        <SFormHelperText margin="dense">
          {error ? (
            <FormError
              type="toggle-input"
              testId="error"
              name={props?.name || 'toggle'}
              error={error}
            />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </SFormControl>
  );
};

export const FormToggleInput = withFormikWeb(ToggleInput);
