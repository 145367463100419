import styled from 'styled-components';

import { Paragraph } from '../../content/text';
import { media } from '../../themes';

import { LoginProps } from './MediaCardsSection.model';

export const CardsWrapper = styled.div`
  ${media.tablet`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 -10px 20px;

    :before {
      bottom: 270px;
      top: 335px;
    }
  `};

  ${media.desktop`
    margin: 0 -10px;
    :before {
      left: calc(-60vw + 50%);
      height: 500px;
    }
  `};

  ${media.large`
    flex-direction: row;
    :before {
      bottom: 0;
      top: -35px;
      left: calc(-50vw + 50%);
    }
  `};
`;

export const InnerWrapper = styled.div`
  max-width: 1180px;
  margin: 0 20px;

  ${media.large`
    margin: 0 auto;
  `};
`;

export const Login = styled(Paragraph)<LoginProps>`
  width: 100%;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 20px;
`;
