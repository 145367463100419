import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes/media';

interface CardProps {
  className?: string;
}

export const CardContainer: FunctionComponent<CardProps> = props => {
  const { children, className } = props;
  return <OuterContainer className={className}>{children}</OuterContainer>;
};

const OuterContainer = styled.div`
  background: ${props => props.theme.colors.background_card};
  box-shadow: ${props => props.theme.shadows.shadow_default};
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0;
  text-align: left;
  margin: 0 0 20px 0;
  max-width: 475px;
  ${media.desktop`
    margin: 0 10px 0 10px;
  `}
`;
