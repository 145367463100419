import { css } from 'styled-components';
import { SpacingStyle, SpacingStyleProps, SpacingStoryArgs } from '../../base-styles/spacing';

export const TextSpacingArgs = SpacingStoryArgs;

export const TextElementTagOptions = [
  'span',
  'div',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'legend',
  'label',
] as const;

export type TextElementTags = (typeof TextElementTagOptions)[number];

export enum TextAlignEnum {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export const TextAlignOptions = Object.values(TextAlignEnum);

export type TextAlign = (typeof TextAlignOptions)[number];

export type TextBaseProps = {
  as?: TextElementTags;
};

export type TextLegacyStyleProps = {};

export type TextBaseStyleProps = SpacingStyleProps & TextLegacyStyleProps;

export const TextBaseStyle = css<TextBaseStyleProps>`
  ${SpacingStyle}
`;
