import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import trashIcon from './icons/trash.svg';

import { FilePreview } from './FileUpload';
import { Paragraph } from '../../content/text';
import { Spinner } from '../../layout/spinner';
import { Notification } from '../../elements/notification';
import { BaseProps } from '../..';
import { useTestIds } from './FilePreviewDocument.model';

type PreviewWrapperProps = {
  hideUploadzone?: boolean;
  error?: boolean;
};

type FilePreviewDocumentProps = {
  file: FilePreview;
  onRemoveClick?: () => void;
  hideUploadzone?: boolean;
} & BaseProps;

export const FilePreviewDocument: FunctionComponent<FilePreviewDocumentProps> = props => {
  const { file, onRemoveClick, hideUploadzone } = props;

  const testIds = useTestIds(props);

  return (
    <>
      <PreviewWrapper
        {...testIds.base.attr}
        key={file.id || file.name}
        error={file.error}
        hideUploadzone={hideUploadzone}
      >
        <FileName small>{file.name}</FileName>
        {file.sending ? (
          <div>
            <Spinner small testId={testIds.loading.id} />
          </div>
        ) : (
          <TrashIcon src={trashIcon} alt="" onClick={onRemoveClick} {...testIds.remove.attr} />
        )}
      </PreviewWrapper>
      {file.error && (
        <Notification
          testId={testIds.error.id}
          inline
          severity="error"
          title={file.errorTitle || ''}
          description={
            file.errorDescription ||
            'An error has occurred. Please try again, and if the problem persists, contact us.'
          }
        />
      )}
    </>
  );
};

const FileName = styled(Paragraph)`
  max-width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PreviewWrapper = styled.div<PreviewWrapperProps>`
  display: flex;
  justify-content: space-between;
  background: ${props => props.theme.colors.background_card};
  padding: 20px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
  ${props => props.error && `border: 2px solid ${props.theme.colors.notice_error};`}

  ${(props: PreviewWrapperProps) => !props.hideUploadzone && 'margin-top: 10px;'}
`;

const TrashIcon = styled.img`
  cursor: pointer;
  vertical-align: middle;
`;

export default FilePreviewDocument;
