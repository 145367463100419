import React, { FunctionComponent, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openConsentManager } from '@segment/consent-manager';
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Box, Link, Typography } from '@mui/material';

import play_store_logo from '../../assets/logos/google_play_logo.png';
import app_store_logo from '../../assets/logos/app_store_logo.png';
import { Container } from '../../layout';
import { NavigationLink, NavigationLinkProps } from '../common';
import { CollapsedNote } from './CollapsedNote';
import { FOOTER_NAVIGATION } from './menuConfig';
import {
  AddressBarHolder,
  AddressContainer,
  BottomMenuWrapper,
  FooterWrapper,
  ContentHolder,
  Copyright,
  DisclosureToggle,
  DisclosureToggleText,
  Email,
  LegalTextContainer,
  LegalTextGraph,
  LogoHolder,
  MenuContainer,
  MenuSection,
  MenuSectionTitle,
  SocialContainer,
  Divider,
  Wrap,
} from './Footer.style';

export interface FooterProps {
  condensed?: boolean;
  staticPage?: boolean;
  renderLink?: (props: NavigationLinkProps) => React.ReactElement | null;
}

const secondNote = (
  <p>
    <sup>2</sup> Represents an net annualized return, using an internal rate of return (IRR)
    methodology, with respect to the matured investments shown in your Portfolio experience,
    utilizing the effective dates and amounts of subscriptions and distributions to and from the
    investments, net of management fees and all other expenses charged to the investments. With
    respect to the “Portfolio Overview” view, a blended net annualized return is shown. All
    calculations are based on unaudited internal calculations and are subject to change. Numbers
    presented are rounded to the second decimal. Past performance is not a reliable indicator of
    future results and should not be relied upon as the basis for making an investment decision. All
    securities involve risk and may result in significant losses, including the loss of principal
    invested.{' '}
    <Link
      color="inherit"
      data-cy="footer-IRR-explanation-link"
      href="/resources/article/internal-rate-of-return-irr-vs-roi"
      target="_blank"
      rel="noopener noreferrer"
    >
      click here
    </Link>
    .
  </p>
);

export const Footer: FunctionComponent<FooterProps> = ({ condensed, ...otherProps }) => {
  const [showFullDisclosure, setShowFullDisclosure] = useState(false);

  return (
    <FooterWrapper>
      <Container>
        {!condensed && (
          <>
            <Wrap>
              <MenuContainer>
                {FOOTER_NAVIGATION.map((menu, i) => (
                  <MenuSection key={i}>
                    <MenuSectionTitle semiBold inverse secondary>
                      {menu.heading}
                    </MenuSectionTitle>
                    <ul>
                      {menu.menu.map(item => (
                        <li key={item.key} data-cy={item.key}>
                          {item.route ? (
                            <NavigationLink
                              inverse
                              navItem={{ ...item, id: item.key }}
                              {...otherProps}
                            />
                          ) : (
                            <span id={item.id}>{item.title}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </MenuSection>
                ))}
              </MenuContainer>

              <LogoHolder>
                <ContentHolder>
                  <SocialContainer small>
                    <a
                      data-cy="twitter-link"
                      href="https://twitter.com/YieldStreet"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="twitter"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a
                      data-cy="facebook-link"
                      href="https://www.facebook.com/YieldStreet"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="facebook"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a
                      data-cy="linkedin-link"
                      href="https://www.linkedin.com/company/9383317/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="linkedin"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a
                      data-cy="instagram-link"
                      href="https://www.instagram.com/yieldstreet/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="instagram"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a
                      data-cy="youtube-link"
                      href="https://www.youtube.com/c/YieldStreet"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="YouTube"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </SocialContainer>
                </ContentHolder>

                <Box>
                  <Link
                    id="app-store-link"
                    href="https://apps.apple.com/us/app/yieldstreet-alt-investments/id1437754463"
                    target="_blank"
                  >
                    <img src={app_store_logo} width="113" height="38" alt="App Store" />
                  </Link>
                  <Link
                    id="google-play-link"
                    href="https://play.google.com/store/apps/details?id=com.yieldstreet"
                    target="_blank"
                  >
                    <img src={play_store_logo} width="134" height="38" alt="Play Store" />
                  </Link>
                </Box>
              </LogoHolder>
            </Wrap>
            <Divider />
          </>
        )}

        <LegalTextGraph>
          <p>
            We believe our 10 alternative asset classes, track record across 470+ investments, third
            party reviews, and history of innovation makes Yieldstreet “The leading platform for
            private market investing,” as compared to other private market investment platforms.
          </p>
        </LegalTextGraph>

        <LegalTextGraph id="footnote--performance-disclaimer">
          <p>
            <sup id="sup1">1</sup> Past performance is no guarantee of future results. Any
            historical returns, expected returns, or probability projections may not reflect actual
            future performance. All securities involve risk and may result in significant losses.
          </p>
        </LegalTextGraph>
        <CollapsedNote fullNote={secondNote}>
          <sup id="sup2">2</sup> Represents an net annualized return, using an internal rate of
          return (IRR) methodology, with respect to the matured investments shown in your Portfolio
          experience, utilizing the effective dates and amounts of subscriptions and distributions
          to and from the investments, net of management fees and all other expenses charged to the
          investments.
        </CollapsedNote>
        <LegalTextGraph>
          <p>
            <sup id="sup3">3</sup> "Annual interest," "Annualized Return" or "Target Returns"
            represents a projected annual target rate of interest or annualized target return, and
            not returns or interest actually obtained by fund investors. “Term" represents the
            estimated term of the investment; the term of the fund is generally at the discretion of
            the fund’s manager, and may exceed the estimated term by a significant amount of time.
            Unless otherwise specified on the fund's offering page, target interest or returns are
            based on an analysis performed by Yieldstreet of the potential inflows and outflows
            related to the transactions in which the strategy or fund has engaged and/or is
            anticipated to engage in over the estimated term of the fund. There is no guarantee that
            targeted interest or returns will be realized or achieved or that an investment will be
            successful. Actual performance may deviate from these expectations materially, including
            due to market or economic factors, portfolio management decisions, modelling error, or
            other reasons.
          </p>
        </LegalTextGraph>
        <LegalTextGraph>
          <p>
            <sup id="sup4">4</sup> Reflects the annualized distribution rate that is calculated by
            taking the most recent quarterly distribution approved by the Fund's Board of Directors
            and dividing it by prior quarter-end NAV and annualizing it. The Fund’s distribution may
            exceed its earnings. Therefore, a portion of the Fund’s distribution may be a return of
            the money you originally invested and represent a return of capital to you for tax
            purposes.
          </p>
        </LegalTextGraph>
        <LegalTextGraph>
          <p>
            <sup id="sup5">5</sup> Represents the sum of the interest accrued in the statement
            period plus the interest paid in the statement period.
          </p>
        </LegalTextGraph>
        <LegalTextGraph>
          <p>
            <sup id="sup6">6</sup> The internal rate of return ("IRR") represents an average net
            realized IRR with respect to all matured investments, excluding our Short Term Notes and
            Structured Notes programs, weighted by the investment size of each individual
            investment, made by private investment vehicles managed by YieldStreet Management, LLC
            from July 1, 2015 through and including June 30, 2024, after deduction of management
            fees and all other expenses charged to investments.
          </p>
        </LegalTextGraph>
        <LegalTextGraph>
          <p>
            <sup id="sup7">7</sup> Investors should carefully consider the investment objectives,
            risks, charges and expenses of the Yieldstreet Alternative Income Fund before investing.
            The prospectus for the Yieldstreet Alternative Income Fund contains this and other
            information about the Fund and can be obtained by emailing{' '}
            <a href="mailto:investments@yieldstreet.com">investments@yieldstreet.com</a> or by
            referring to www.yieldstreetalternativeincomefund.com. The prospectus should be read
            carefully before investing in the Fund. Investments in the Fund are not bank deposits
            (and thus not insured by the FDIC or by any other federal governmental agency) and are
            not guaranteed by Yieldstreet or any other party.
          </p>
        </LegalTextGraph>
        <LegalTextGraph>
          <p>
            <sup id="sup8">8</sup> This tool is for informational purposes only. You should not
            construe any information provided here as investment advice or a recommendation,
            endorsement or solicitation to buy any securities offered on Yieldstreet. Yieldstreet is
            not a fiduciary by virtue of any person's use of or access to this tool. The information
            provided here is of a general nature and does not address the circumstances of any
            particular individual or entity. You alone assume the sole responsibility of evaluating
            the merits and risks associated with the use of this information before making any
            decisions based on such information.
          </p>
        </LegalTextGraph>
        <LegalTextGraph>
          <p>
            <sup id="sup9">9</sup> Statistics as of the most recent month end.
          </p>
        </LegalTextGraph>

        <Divider />

        {!condensed && (
          <AddressBarHolder>
            <AddressContainer>
              <p>300 Park Avenue 15th Floor, New York, NY 10022</p>{' '}
              <a href="tel:844-943-5378">844-943-5378</a>
            </AddressContainer>

            <ContentHolder>
              <Email href="mailto:investments@yieldstreet.com">investments@yieldstreet.com</Email>
            </ContentHolder>
          </AddressBarHolder>
        )}

        <LegalTextContainer show={showFullDisclosure}>
          <p>
            No communication by YieldStreet Inc. or any of its affiliates (collectively,
            “Yieldstreet™”), through this website or any other medium, should be construed or is
            intended to be a recommendation to purchase, sell or hold any security or otherwise to
            be investment, tax, financial, accounting, legal, regulatory or compliance advice,
            except for specific investment advice that may be provided by YieldStreet Management,
            LLC pursuant to a written advisory agreement between such entity and the recipient.
            Nothing on this website is intended as an offer to extend credit, an offer to purchase
            or sell securities or a solicitation of any securities transaction.
          </p>
          <p>
            Any financial projections or returns shown on the website are estimated predictions of
            performance only, are hypothetical, are not based on actual investment results and are
            not guarantees of future results. Estimated projections do not represent or guarantee
            the actual results of any transaction, and no representation is made that any
            transaction will, or is likely to, achieve results or profits similar to those shown. In
            addition, other financial metrics and calculations shown on the website (including
            amounts of principal and interest repaid) have not been independently verified or
            audited and may differ from the actual financial metrics and calculations for any
            investment, which are contained in the investors’ portfolios. Any investment information
            contained herein has been secured from sources that Yieldstreet believes are reliable,
            but we make no representations or warranties as to the accuracy of such information and
            accept no liability therefore.
          </p>
          <p>
            Private placement investments are NOT bank deposits (and thus NOT insured by the FDIC or
            by any other federal governmental agency), are NOT guaranteed by Yieldstreet or any
            other party, and MAY lose value. Neither the Securities and Exchange Commission nor any
            federal or state securities commission or regulatory authority has recommended or
            approved any investment or the accuracy or completeness of any of the information or
            materials provided by or through the website. Investors must be able to afford the loss
            of their entire investment.
          </p>

          <p>
            Investments in private placements are speculative and involve a high degree of risk and
            those investors who cannot afford to lose their entire investment should not invest.
            Additionally, investors may receive illiquid and/or restricted securities that may be
            subject to holding period requirements and/or liquidity concerns. Investments in private
            placements are highly illiquid and those investors who cannot hold an investment for the
            long term (at least 5-7 years) should not invest.
          </p>
          <p>
            Alternative investments should only be part of your overall investment portfolio.
            Further, the alternative investment portion of your portfolio should include a balanced
            portfolio of different alternative investments.
          </p>
          <p>
            Articles or information from third-party media outside of this domain may discuss
            Yieldstreet or relate to information contained herein, but Yieldstreet does not approve
            and is not responsible for such content. Hyperlinks to third-party sites, or
            reproduction of third-party articles, do not constitute an approval or endorsement by
            Yieldstreet of the linked or reproduced content.
          </p>
          <p>
            Investing in securities (the "Securities") listed on Yieldstreet™ pose risks, including
            but not limited to credit risk, interest rate risk, and the risk of losing some or all
            of the money you invest. Before investing you should: (1) conduct your own investigation
            and analysis; (2) carefully consider the investment and all related charges, expenses,
            uncertainties and risks, including all uncertainties and risks described in offering
            materials; and (3) consult with your own investment, tax, financial and legal advisors.
            Such Securities are only suitable for accredited investors who understand and are
            willing and able to accept the high risks associated with private investments.
          </p>

          <p>
            Investing in private placements requires long-term commitments, the ability to afford to
            lose the entire investment, and low liquidity needs. This website provides preliminary
            and general information about the Securities and is intended for initial reference
            purposes only. It does not summarize or compile all the applicable information. This
            website does not constitute an offer to sell or buy any securities. No offer or sale of
            any Securities will occur without the delivery of confidential offering materials and
            related documents. This information contained herein is qualified by and subject to more
            detailed information in the applicable offering materials. Yieldstreet™ is not
            registered as a broker-dealer. Yieldstreet™ does not make any representation or warranty
            to any prospective investor regarding the legality of an investment in any Yieldstreet
            Securities.
          </p>
          <p>
            YieldStreet Inc. is the direct owner of Yieldstreet Management, LLC, which is an
            SEC-registered investment adviser that manages the Yieldstreet funds and provides
            investment advice to the Yieldstreet funds, and in certain cases, to retail investors.
            RealCadre LLC is also indirectly owned by Yieldstreet Inc. RealCadre LLC is a
            broker-dealer registered with the Securities and Exchange Commission (
            <a href="https://www.sec.gov/" target="_blank" rel="noreferrer">
              “SEC”
            </a>
            ) and a member of the Financial Industry Regulatory Authority (
            <a
              href="https://www.finra.org/?utm_campaign=2023.04.28_Cadre-Annual-Disclosure-2023_All-Subscribers&utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-9z5b1hhEIHbX-GvT7Ch1LCdm1GjGvUMPy5OvbhbOp8EgnBDeUAG4l-OMETxCnUg-TUIbP_#/"
              target="_blank"
              rel="noreferrer"
            >
              “FINRA”
            </a>
            ) and the Securities Investor Protection Corporation (
            <a
              href="https://www.sipc.org/?utm_campaign=2023.04.28_Cadre-Annual-Disclosure-2023_All-Subscribers&utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-9z5b1hhEIHbX-GvT7Ch1LCdm1GjGvUMPy5OvbhbOp8EgnBDeUAG4l-OMETxCnUg-TUIbP"
              target="_blank"
              rel="noreferrer"
            >
              “SIPC”
            </a>
            ). Information on all FINRA registered broker-dealers can be found on FINRA’s{' '}
            <a
              href="https://brokercheck.finra.org/?utm_campaign=2023.04.28_cadre-annual-disclosure-2023_all-subscribers&utm_source=hs_email&utm_medium=email&_hsenc=p2anqtz-9z5b1hheihbx-gvt7ch1lcdm1gjgvumpy5ovbhbop8egnbdeuag4l-ometxcnug-tuibp_"
              target="_blank"
              rel="noreferrer"
            >
              BrokerCheck
            </a>
            . Despite its affiliation with Yieldstreet Management, LLC, RealCadre LLC has no role in
            the investment advisory services received by YieldStreet clients or the management or
            distribution of the Yieldstreet funds or other securities offered on our through
            Yieldstreet and its personnel. RealCadre LLC does not solicit, sell, recommend, or place
            interests in the Yieldstreet funds.
          </p>
          <p>
            Yieldstreet is not a bank. Certain services are offered through Plaid, Orum.io and
            Footprint and none of such entities is affiliated with Yieldstreet. By using the
            services offered by any of these entities you acknowledge and accept their respective
            disclosures and agreements, as applicable.
          </p>
          <p>
            Investment advisory services are only provided to clients of YieldStreet Management,
            LLC, an investment advisor registered with the Securities and Exchange Commission,
            pursuant to a written advisory agreement.
          </p>
          <p>
            Our site uses a third party service to match browser cookies to your mailing address. We
            then use another company to send special offers through the mail on our behalf. Our
            company never receives or stores any of this information and our third parties do not
            provide or sell this information to any other company or service.
          </p>
          <DisclosureToggle show={showFullDisclosure}>
            {!showFullDisclosure && (
              <DisclosureToggleText tabIndex={0} onClick={() => setShowFullDisclosure(true)}>
                Read full disclosure
                <br />
                <FontAwesomeIcon icon={faChevronDown} />
              </DisclosureToggleText>
            )}
            {showFullDisclosure && (
              <DisclosureToggleText tabIndex={0} onClick={() => setShowFullDisclosure(false)}>
                <FontAwesomeIcon icon={faChevronUp} />
                <br />
                Collapse disclosure
              </DisclosureToggleText>
            )}
          </DisclosureToggle>
        </LegalTextContainer>

        <Copyright>
          <Typography variant="caption2" color="textLabelSecondary">
            Copyright © {new Date().getFullYear()} YieldStreet, Inc.
            <br />
          </Typography>

          <BottomMenuWrapper>
            <span>
              <Link variant="caption2" href="/privacy/">
                Privacy Policy
              </Link>
            </span>
            <span>
              <Link variant="caption2" onClick={() => openConsentManager()}>
                Website Data Collection Preferences
              </Link>
            </span>
            <span>
              <Link variant="caption2" href="/security/">
                Data Security
              </Link>
            </span>
            <span>
              <Link variant="caption2" href="/responsible-disclosure-policy/">
                Responsible Disclosure Policy
              </Link>
            </span>
            <span>
              <Link variant="caption2" href="/accessibility/">
                Accessibility
              </Link>
            </span>
            <span>
              <Link variant="caption2" href="/terms/">
                Terms of Use
              </Link>
            </span>

            <span>
              <Link variant="caption2" href="/form-adv" target="_blank">
                Form ADV Disclosure Brochure
              </Link>
            </span>

            <span>
              <Link variant="caption2" href="/form-crs" target="_blank">
                Form CRS
              </Link>
            </span>
          </BottomMenuWrapper>
        </Copyright>
      </Container>
    </FooterWrapper>
  );
};
