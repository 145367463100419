import React, { FunctionComponent } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseComponentProps } from '@mui/material';
import { BaseInputProps, genTestIds } from '@yieldstreet/tool-kit';
import { SFormControl, SFormHelperText, FormError, withFormikWeb, useLabelId } from '../common';
import { SFilledInput, SInputLabel } from '../text-input/styles';

export interface TextInputProps
  extends Omit<
      React.AllHTMLAttributes<HTMLInputElement>,
      | 'onClick'
      | 'onChange'
      | 'onBlur'
      | 'value'
      | 'label'
      | 'rows'
      | 'as'
      | 'prefix'
      | 'color'
      | 'size'
    >,
    BaseInputProps<string> {
  placeholder?: string;
  hiddenLabel?: boolean;
  onKeyPress?: React.KeyboardEventHandler<any>;
  inputRef?: React.Ref<any>;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  inputProps?: InputBaseComponentProps;
  type?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  multiline?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  rowsMin?: string | number;
  fullWidth?: boolean;
  width?: number | string;
  maskClassName?: string;
  name?: string;
  children?: React.ReactNode;
}

const { useTestIds } = genTestIds();

export const TextInput: FunctionComponent<TextInputProps> = props => {
  const {
    prefix,
    suffix,
    inputRef,
    inputComponent,
    inputProps,
    label,
    placeholder,
    error,
    onChange,
    hiddenLabel,
    caption,
    className,
    fullWidth,
    width,
    maskClassName,
    name,
    disabled,
    testId,
    ...otherProps
  } = props;

  const labelId = useLabelId(name);

  const testIds = useTestIds({ testId: testId || name || 'text' });

  return (
    <SFormControl
      variant="filled"
      hiddenLabel={hiddenLabel}
      error={!!error}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      style={{ width }}
    >
      {label && !hiddenLabel && (
        <SInputLabel className={maskClassName} id={labelId}>
          {label}
        </SInputLabel>
      )}
      <SFilledInput
        {...otherProps}
        onChange={onChange && (e => onChange(e.target && e.target.value, e))}
        name={name}
        inputRef={inputRef}
        inputComponent={inputComponent}
        inputProps={{
          ...testIds.base.attr,
          'aria-labelledby': labelId,
          placeholder: hiddenLabel ? (label as string) : placeholder,
          ...otherProps,
          ...inputProps,
        }}
        startAdornment={prefix && <InputAdornment position="start">{prefix}</InputAdornment>}
        endAdornment={suffix && <InputAdornment position="end">{suffix}</InputAdornment>}
      />
      {error || caption ? (
        <SFormHelperText>
          {error ? (
            <FormError type="text-input" testId="error" name={name || 'text'} error={error} />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </SFormControl>
  );
};

export const FormTextInput = withFormikWeb(TextInput);
