import React, { useState } from 'react';
import { TextInput, TextInputProps } from './TextInput';
import { useMimicPassword } from 'react-mimic-password-hook';
import { withFormikWeb } from '../common';
import { ShowHideToggle } from './helpers';

export interface PasswordTextInputProps extends Omit<TextInputProps, 'type' | 'suffix'> {
  mimicPassword?: boolean;
}

export const PasswordTextInput: React.FunctionComponent<PasswordTextInputProps> = ({
  className,
  onChange,
  value,
  mimicPassword,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [mimicValue, mimicPresentation, onPasswordChange] = useMimicPassword({
    mode: 'persymbol',
    handleChange: onChange,
  });
  if (mimicPassword) {
    return (
      <TextInput
        {...props}
        className={`fs-mask ${className}`}
        type="text"
        onChange={(_value, e) => onPasswordChange(e)}
        value={showPassword ? mimicValue : mimicPresentation}
        suffix={<ShowHideToggle name="password" show={showPassword} onChange={setShowPassword} />}
      />
    );
  }
  return (
    <TextInput
      {...props}
      className={`fs-mask ${className}`}
      type={showPassword ? 'text' : 'password'}
      onChange={e => onChange && onChange(e)}
      value={value}
      suffix={<ShowHideToggle name="password" show={showPassword} onChange={setShowPassword} />}
    />
  );
};

export const FormPasswordTextInput = withFormikWeb(PasswordTextInput);
