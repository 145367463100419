import { MaybePartialSizeMap, sizeCssMui } from '../../utils/sizes';
import { CSS_THEME as theme } from '../../../theme';
import { css, styled } from '@mui/material/styles';

export type ContainerProps = {
  fluid?: MaybePartialSizeMap<boolean>;
  full?: MaybePartialSizeMap<boolean>;
};

export const ContainerPadding = css({
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 35,
    paddingRight: 35,
  },
  paddingLeft: 30,
  paddingRight: 30,
});

const fluidCss = (fluid: MaybePartialSizeMap<boolean>) =>
  sizeCssMui(
    ({ fluid }) =>
      fluid
        ? css``
        : css`
            max-width: 1250px;
          `,
    { fluid }
  );
const fullCss = (full: MaybePartialSizeMap<boolean>) =>
  sizeCssMui(({ full }) => (full ? css`` : ContainerPadding), { full });

const containerOutput = (props: ContainerProps) => css`
  ${fluidCss(props.fluid ?? false)}
  ${fullCss(props.full ?? false)}
`;

export const Container = styled('div')<ContainerProps>(
  {
    wordBreak: 'break-word',
    margin: '0 auto',
    width: '100%',
  },
  containerOutput
);
