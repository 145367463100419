import styled from 'styled-components';

import { media } from '../../themes/media';
import { Paragraph } from '../../content/text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    margin: 0;
    padding: 0 35px 13px;
    justify-content: flex-start;
    border-top: none;
  `};
`;

export const LeftHeaderElement = styled(Paragraph)`
  color: ${props => props.theme.colors.text_label};
  min-width: 45px;
`;

export const RightHeaderElement = styled(LeftHeaderElement)`
  min-width: unset;
`;
