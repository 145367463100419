import type { Components, CssVarsTheme } from '@mui/material';

export const MuiFilledInput: Components<CssVarsTheme>['MuiFilledInput'] = {
  styleOverrides: {
    root: {
      '&.Mui-disabled:before': {
        borderBottomStyle: 'solid',
      },
    },
  },
};
