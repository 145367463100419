export enum SpacingEnum {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  sm = 'sm',
  m = 'm',
  ml = 'ml',
  l = 'l',
  xl = 'xl',
  '2xl' = '2xl',
  '3xl' = '3xl',
  '4xl' = '4xl',
  '5xl' = '5xl',
}

export const SpacingOptions = [
  'xxs',
  'xs',
  's',
  'sm',
  'm',
  'ml',
  'l',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
] as const;

export type Spacing = `${SpacingEnum}`;

export const spacing = {
  xxs: '4px',
  xs: '8px',
  s: '12px',
  sm: '16px',
  m: '24px',
  ml: '32px',
  l: '40px',
  xl: '48px',
  '2xl': '64px',
  '3xl': '80px',
  '4xl': '96px',
  '5xl': '160px',
};
