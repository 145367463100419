import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { getConfig, PartialGridConfig } from './common';
import { sizeCss } from '../../../utils/sizes';
import { ColContainer, ColProps } from './Col';
import { GridProvider } from './Provider';

export type RowProps = {
  children: React.ReactElement<ColProps> | React.ReactElement<ColProps>[];
  config?: PartialGridConfig;
  reverse?: boolean;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
};

export const RowContainer = styled.div<RowProps>`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: ${props => props.justify || 'stretch'};

  ${props =>
    sizeCss(
      ({ columnGutter }) => css`
        margin-right: ${(columnGutter / 2) * -1}px;
        margin-left: ${(columnGutter / 2) * -1}px;

        > ${ColContainer} {
          padding-right: ${columnGutter / 2}px;
          padding-left: ${columnGutter / 2}px;
        }
      `,
      { columnGutter: getConfig(props.theme, 'gutter') }
    )}

  ${props =>
    sizeCss(
      ({ columnSpacing }) => css`
        &:first-child {
          margin-top: ${(columnSpacing / 2) * -1}px;
        }

        &:last-child {
          margin-bottom: ${(columnSpacing / 2) * -1}px;
        }

        > ${ColContainer} {
          padding-top: ${columnSpacing / 2}px;
          padding-bottom: ${columnSpacing / 2}px;
        }
      `,
      { columnSpacing: getConfig(props.theme, 'spacing') }
    )}
`;

export const Row: FunctionComponent<RowProps> = ({ children, config, reverse, justify }) => {
  return (
    <GridProvider config={config}>
      <RowContainer reverse={reverse} justify={justify}>
        {children}
      </RowContainer>
    </GridProvider>
  );
};
