import axios, { AxiosResponse } from 'axios';
import { WordpressArticle } from '@yieldstreet/platform-kit';

import { restApi, BASE_REST_CONFIG } from '../common';
import {
  GetWordpressArticlesRequest,
  GetWordpressArticlesResponse,
  WordpressSearchAPIResponse,
  GetWordpressSearchRequest,
  GetWordpressMenusRequest,
  WPOfferingArticlesRequest,
  WPOfferingArticlesResponse,
} from '../../types';

export const wordpressApi = restApi.injectEndpoints({
  ...BASE_REST_CONFIG,
  endpoints: builder => {
    const getWordpressArticles = builder.query<
      GetWordpressArticlesResponse,
      GetWordpressArticlesRequest
    >({
      query: ({ postType, ...params }) => ({
        url: `/wp-json/wp/v2/${postType}/?_embed`,
        method: 'GET',
        params,
      }),
      transformResponse: (data, headers) => {
        const wpHeaders = {
          total: headers?.['x-wp-total'],
          totalPages: headers?.['x-wp-totalpages'],
        };
        return {
          posts: data,
          headers: wpHeaders,
        };
      },
    });

    const wpOfferingArticles = builder.query<WPOfferingArticlesResponse, WPOfferingArticlesRequest>(
      {
        queryFn: async ({ postType, articleSlug }) => {
          const apis: Promise<AxiosResponse<WordpressArticle>>[] = [];

          if (Array.isArray(postType)) {
            postType.forEach(postTypeString => {
              apis.push(
                axios.get<WordpressArticle>(
                  `/wp-json/wp/v2/${postTypeString}/?_embed&slug=${articleSlug}`
                )
              );
            });
          } else {
            apis.push(
              axios.get<WordpressArticle>(`/wp-json/wp/v2/${postType}/?_embed&slug=${articleSlug}`)
            );
          }

          return await axios
            .all(apis)
            .then(
              axios.spread((...responses: AxiosResponse<WordpressArticle>[]) => {
                const validResults = responses.filter(response => response?.data);

                const wpArticle = validResults
                  .reduce(
                    (acc: WordpressArticle[], validResult) => acc.concat(validResult.data),
                    []
                  )
                  .find((article: WordpressArticle) => article.slug === articleSlug);

                return { data: { wpArticle } };
              })
            )
            .catch(errors => {
              return { error: errors };
            });
        },
      }
    );

    const getWordpressSearchResults = builder.query<
      WordpressSearchAPIResponse[],
      GetWordpressSearchRequest
    >({
      query: params => ({
        url: `/wp-json/wp/v2/search`,
        method: 'GET',
        params,
      }),
    });

    const getWordpressMenus = builder.query<Record<string, any>, GetWordpressMenusRequest>({
      query: ({ menuName, ...params }) => ({
        url: `/wp-json/menu/menu-items/${menuName}`,
        method: 'GET',
        params,
      }),
    });

    const getWordpressCategories = builder.query<Record<string, any>, GetWordpressMenusRequest>({
      query: params => ({
        url: `/wp-json/wp/v2/categories`,
        method: 'GET',
        params,
      }),
    });

    const getWordpressTags = builder.query<Record<string, any>, GetWordpressMenusRequest>({
      query: params => ({
        url: `/wp-json/wp/v2/tags`,
        method: 'GET',
        params,
      }),
    });

    return {
      getWordpressArticles,
      getWordpressSearchResults,
      getWordpressMenus,
      getWordpressCategories,
      getWordpressTags,
      wpOfferingArticles,
    };
  },
});
