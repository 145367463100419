import { DistributionOption } from '../../../../enums/investment/distribution-option';

export enum FundBalanceCalculationType {
  InvestedMinusCapital = 'INVESTED_MINUS_CAPITAL',
  CapitalBalance = 'CAPITAL_BALANCE',
  InvestedAmount = 'INVESTED_AMOUNT',
  NavOverride = 'NAV_OVERRIDE',
}

export enum FundCapitalBalanceReductionType {
  TotalDistributed = 'TOTAL_DISTRIBUTED',
  CapitalDistributed = 'CAPITAL_DISTRIBUTED',
  IncomeDistributed = 'INCOME_DISTRIBUTED',
}

export enum FeeBalanceProvider {
  STARTING_CAPITAL_BALANCE = 'STARTING_CAPITAL_BALANCE',
  ENDING_CAPITAL_BALANCE = 'ENDING_CAPITAL_BALANCE',
  TOTAL_INVESTED = 'TOTAL_INVESTED',
}

export interface FundConfig {
  balanceCalculationType: FundBalanceCalculationType | null;
  capitalBalanceReductionType: FundCapitalBalanceReductionType | null;
  fees?: Record<string, object>;
  feesBalanceProviders: {};
  positionsEffectiveDistributionImpact?: string | null;
  managementFeeBalanceProvider?: FeeBalanceProvider | null;
  memberExpenseBalanceProvider?: FeeBalanceProvider | null;
  managementFeeRate?: number | null;
}

export interface PortfolioSummaryFundBreakdown {
  funded: number;
  total: number;
  unfunded: number;
}

export interface PortfolioSummaryFund {
  canToggleDistributionOption: boolean;
  commitmentBreakdown: PortfolioSummaryFundBreakdown | null;
  dividendDistributionOption: DistributionOption;
  fundCode: string;
  fundConfig: FundConfig;
  fundId: string;
  fundInvestmentStatus?: string | null;
  investorAccountId: string;
  nav: number;
  navDate: string;
  noteUrlHash: string;
  sharesCount: number;
  total: number;
}
