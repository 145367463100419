import styled from 'styled-components';

import { Paragraph } from '../../content';

import { BadgeContainerProps } from './BadgeV2.model';

export const BadgeContainer = styled.div<BadgeContainerProps>`
  background-color: ${props => props.backgroundColor || props.theme.colors.background_card};
  border-radius: 20px;
  padding: 6px 16px 6px 8px;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  gap: 7px;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  min-width: 40px;
`;

export const SParagraph = styled(Paragraph)<{ textColor: string }>`
  color: ${props => props.textColor || props.theme.colors.text_header} !important;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export const IconContainer = styled.div`
  height: 20px;
  min-width: 20px;
  max-width: 30px;
`;
