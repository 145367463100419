export const resources = [
  {
    type: 'other',
    description:
      'For more information on this opportunity, please review the information provided in the Prospectus below.',
    name: 'Fund Prospectus',
    url: './yieldstreet-alternative-income-fund-inc-prospectus.pdf',
  },
  {
    type: 'other',
    description: 'Yieldstreet Alternative Income Fund FAQ',
    name: 'Yieldstreet Alternative Income Fund FAQ',
    url: './alternative-income-fund-FAQ.pdf',
  },
  {
    type: 'other',
    description: 'Semi Annual report as of June 30, 2024',
    name: 'Semi Annual report as of June 30, 2024',
    url: './Yieldstreet-Alternative-Income-Fund-June-30-2024-Semiannual-Report.pdf',
  },
  {
    type: 'other',
    description: 'Annual report as of December 31, 2023',
    name: 'Annual report as of December 31, 2023',
    url: './YieldStreet-Alternative-Income-Fund-Annual-Report-12-31-2023.pdf',
  },
];

export const documents = [
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 June 2024',
    name: 'Distribution Notice 19(a)1 September 2024',
    url: './Distribution-Notice-19(a)1-September-2024.pdf',
  },
  {
    type: 'other',
    description: 'Q3 2023 update',
    name: 'Q3 2023 update',
    url: './alternative-income-fund-Q3-2023-commentary.pdf',
  },
  {
    type: 'other',
    description: 'Semi Annual report as of June 30, 2023',
    name: 'Semi Annual report as of June 30, 2023',
    url: './Yieldstreet-Prism-Fund-Semi-Annual-Report-June-30-2023.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 June 2024',
    name: 'Distribution Notice 19(a)1 June 2024',
    url: './Distribution-Notice-19(a)1-June-2024.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a) March 2024 Payment',
    name: 'Distribution Notice 19(a) March 2024 Payment',
    url: './Distribution-Notice-19(a)1-March-2024.pdf',
  },
  {
    type: 'other',
    description: 'Annual report as of December 31, 2022',
    name: 'Annual report as of December 31, 2022',
    url: './Yieldstreet-Prism-Fund-Annual-Report-12-31-2022.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 December 2023 Payment',
    name: 'Distribution Notice 19(a)1 December 2023 Payment',
    url: './Distribution-Notice-19(a)1-December-2023-Payment.pdf',
  },
  {
    type: 'other',
    description: 'Q2 2023 update',
    name: 'Q2 2023 update',
    url: './Yieldstreet-Prism-Fund-Q2_2023_Update.pdf',
  },
  {
    type: 'other',
    description: 'Semi Annual report as of June 30, 2022',
    name: 'Semi Annual report as of June 30, 2022',
    url: './Yieldstreet-Prism-Fund-Semi-Annual-Report-June-30-2022.pdf',
  },
  {
    type: 'other',
    description: 'Q1 2023 update',
    name: 'Q1 2023 update',
    url: './Yieldstreet-Prism-Fund-Q1_2023_Update.pdf',
  },
  {
    type: 'other',
    description: 'Q4 2022 update',
    name: 'Q4 2022 update',
    url: './Yieldstreet-Prism-Fund-Q4_2022_Update.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 September 2023 Payment',
    name: 'Distribution Notice 19(a)1 September 2023 Payment',
    url: './YieldStreet-Prism-Fund-Distribution-Notice-19(a)1-September-2023.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 June 2023 Payment',
    name: 'Distribution Notice 19(a)1 June 2023 Payment',
    url: './Distribution Notice 19(a)1 June 2023 Payment.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 February 2023 Payment',
    name: 'Distribution Notice 19(a)1 February 2023 Payment',
    url: './Distribution Notice 19(a)1 February 2023 Payment.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for December 2022 Payment',
    name: 'Distribution Notice 19(a)1 for December 2022 Payment',
    url: './Distribution Notice 19(a)1 for December 2022 Payment.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for September 2022',
    name: 'Distribution Notice 19(a)1 for September 2022',
    url: './YieldStreet-Prism-Fund-Distribution-Notice-19(a)1-September-2022.pdf.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 June 2022',
    name: 'Distribution Notice 19(a)1 for June 2022',
    url: './YieldStreet-Prism-Fund-Distribution-Notice-19(a)1-June-2022.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 February 2022',
    name: 'Distribution Notice 19(a)1 February 2022',
    url: './Distribution_Notice_19_a_1_February_2022.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for December 2021',
    name: 'Distribution Notice 19(a)1 for December 2021',
    url: './Yieldstreet-Prism-Fund-Distribution-Notice-19(a)1-December-2021.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for September 2021',
    name: 'Distribution Notice 19(a)1 for September 2021',
    url: './Yieldstreet-Prism-Fund-Distribution-Notice-19(a)1-September-2021.pdf',
  },

  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for June 2021',
    name: 'Distribution Notice 19(a)1 for June 2021',
    url: './Yieldstreet-Prism-Fund-Distribution-Notice-19(a)1-June-2021.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for March 2021',
    name: 'Distribution Notice 19(a)1 for March 2021',
    url: './Yieldstreet-Prism-Fund-Distribution-Notice-19(a)1-March-2021.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for December 17, 2020 payment',
    name: 'Distribution Notice 19(a)1 for December 17, 2020 payment',
    url: './Yieldstreet-Prism-Fund-Distribution-Notice-19(a)1-December-2020-Payment.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for September 17, 2020 payment',
    name: 'Distribution Notice 19(a)1 for September 17, 2020 payment',
    url: './Yieldstreet-Prism-Fund-Distribution-Notice-19(a)1-September-2020-Payment.pdf',
  },
  {
    type: 'other',
    description: 'Distribution Notice 19(a)1 for June 12, 2020 Payment',
    name: 'Distribution Notice 19(a)1 for June 12, 2020 Payment',
    url: './Yieldstreet-Prism-Fund-Distribution-Notice-19(a)1-June-12-2020-Payment.pdf',
  },
  {
    type: 'other',
    description: 'Q3 2022 update',
    name: 'Q3 2022 update',
    url: './Yieldstreet-Prism-Fund-Q3_2022_Update.pdf',
  },
  {
    type: 'other',
    description: 'Q2 2022 update',
    name: 'Q2 2022 update',
    url: './Yieldstreet-Prism-Fund-Q2_2022_Update.pdf',
  },
  {
    type: 'other',
    description: 'Q1 2022 update',
    name: 'Q1 2022 update',
    url: './Yieldstreet-Prism-Fund-Q1_2022_Update.pdf',
  },
  {
    type: 'other',
    description: 'Yieldstreet Letters to Shareholders',
    name: 'Letter to Shareholders - Dec 21',
    url: './Yieldstreet-Prism-Fund-Letter-To-Shareholders.pdf',
  },
  {
    type: 'other',
    description: 'Annual report as of December 31, 2021',
    name: 'Annual report as of Dec 31, 2021',
    url: './YieldStreet-Prism-Fund-Annual-Report-12-31-2021.pdf',
  },
  {
    type: 'other',
    description: 'Q4 2021 update',
    name: 'Q4 2021 update',
    url: './Yieldstreet-Prism-Fund-Q4_2021_Update.pdf',
  },
  {
    type: 'other',
    description: 'Q3 2021 update',
    name: 'Q3 2021 update',
    url: './Yieldstreet-Prism-Fund-Q3_2021_Update.pdf',
  },
  {
    type: 'other',
    description: 'Holdings as of March 31, 2022',
    name: 'Holdings as of March 31, 2022',
    url: './Yieldstreet-Prism-Fund-Holdings-as-of-March-31-2022.pdf',
  },
  {
    type: 'other',
    description: 'Holdings as of September 30, 2021',
    name: 'Holdings as of September 30, 2021',
    url: './Yieldstreet-Prism-Fund-Holdings-as-of-Sept-30-2021.pdf',
  },
  {
    type: 'other',
    description: 'Holdings as of August 3, 2021',
    name: 'Holdings as of August 3, 2021',
    url: './Yieldstreet-Prism-Fund-Holdings-as-of-Aug-3-2021.pdf',
  },
  {
    type: 'other',
    description: 'Holdings as of April 30, 2021',
    name: 'Holdings as of April 30, 2021',
    url: './Yieldstreet-Prism-Fund-Holdings-as-of-April-30-2021.pdf',
  },
  {
    type: 'other',
    description: 'Holdings as of December 31, 2020',
    name: 'Holdings as of December 31, 2020',
    url: './YieldStreet-Prism-Fund-Holdings-as-of-Dec-31-2020.pdf',
  },
  {
    type: 'other',
    description: 'Form 8937 (2022)',
    name: 'Form 8937 (2022)',
    url: './Form-8937-(2022).pdf',
  },
  {
    type: 'other',
    description: 'Form 8937 (2021)',
    name: 'Form 8937 (2021)',
    url: './Form-8937.pdf',
  },
  {
    type: 'other',
    description: 'Semi-Annual report as of June 30, 2021',
    name: 'Semi-Annual report as of June 30, 2021',
    url: './Yieldstreet-Prism-Fund-Semi-Annual-Report- June-30-2021.pdf',
  },
  {
    type: 'other',
    description: 'Annual report as of December 31, 2020',
    name: 'Annual report as of December 31, 2020',
    url: './Yieldstreet-Prism-Fund-Annual-Report-Dec-31-2020.pdf',
  },
  {
    type: 'other',
    description: 'Semi-Annual report as of June 30, 2020',
    name: 'Semi-Annual report as of June 30, 2020',
    url: './Yieldstreet-Prism-Fund-Semi-Annual-Report- June-30-2020.pdf',
  },
];
