import styled, { css } from 'styled-components';

import { SpacingStyle, getSizeVar } from '../../base-styles';

import {
  SkeletonCommonProps,
  SkeletonTextProps,
  SkeletonThumbnailProps,
  SkeletonContainerProps,
  SkeletonTextAlign,
  SkeletonLineProps,
} from './Skeleton.model';

const skeletonTextCommon = css`
  > * {
    &:last-child {
      min-width: 80%;
    }

    &:only-child {
      min-width: 100%;
    }
  }
`;

const getAlignStyles = (align: SkeletonTextProps['align']) => {
  switch (align) {
    case SkeletonTextAlign.Left:
      return css`
        align-items: flex-start;
        ${skeletonTextCommon}
      `;
    case SkeletonTextAlign.Right:
      return css`
        align-items: flex-end;
        ${skeletonTextCommon}
      `;
    case SkeletonTextAlign.Center:
      return css`
        align-items: center;
        ${skeletonTextCommon}
      `;
    default:
      return css`
        align-items: stretch;
      `;
  }
};

const CommonStyles = css<SkeletonCommonProps>`
  ${props =>
    props.filled
      ? `
    background: ${props.theme.colors.background_page};
  `
      : `
    background: ${props.theme.colors.background_default};
    border: 1px solid ${props.theme.colors.border_default};
  `}
`;

const ActiveStyles = css<SkeletonCommonProps>`
  @keyframes active {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 1.5s ease-in-out 0.5s infinite normal none running active;
`;

export const StyledSkeletonThumbnail = styled.div<SkeletonThumbnailProps>`
  ${CommonStyles};
  ${props => props.active && ActiveStyles};
  ${props =>
    props.size
      ? `
    max-width: var(${getSizeVar(props?.size)});
  `
      : `
    width: 100%;
  `}
  border-radius: ${props => (props.rounded ? '50%' : '2px')};
  aspect-ratio: auto ${props => props.ratioX && props.ratioY && `${props.ratioX} / ${props.ratioY}`};
  ${SpacingStyle};
`;

export const StyledSkeletonLine = styled.span<SkeletonLineProps>`
  ${CommonStyles};
  ${props => props.active && ActiveStyles};
  display: inline-block;
  height: auto;
  min-width: ${props => (props?.spread ? `${(props.spread / 12) * 100}%` : '100%')};
  border-radius: 2px;

  &::before {
    // here we use the content property to add a space
    // this is to prevent the line from collapsing
    // adding a char in the element this will make the parent
    // element take the height of the child
    // thus the skeleton has dynamic height that is controlled by the parent
    content: '\\00a0';
  }
`;

export const StyledSkeletonText = styled.span<Pick<SkeletonTextProps, 'align'>>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  gap: 0.4rem;
  margin: ${props => props.theme.spacing.s} 0;

  ${props => getAlignStyles(props?.align)}
`;

export const StyledSkeletonContainer = styled.div<SkeletonContainerProps>`
  ${CommonStyles};
  display: inline-block;
  min-width: ${props => (props?.spread ? `${(props.spread / 12) * 100}%` : '100%')};
  border-radius: 2px;
  ${props => (props.filled && props.active ? ActiveStyles : '')}
  ${SpacingStyle};
`;
