import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

import { MuiTheme } from '../../../theme';

export interface ButtonContainerProps {
  alignment?: 'left' | 'center' | 'right';
}

const getCenterAlignStyles = (theme: MuiTheme) => {
  return {
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      'a:not(:last-child), button:not(:last-child)': {
        marginRight: theme.spacing(5),
      },
    },
  };
};

const getLeftAlignStyles = (theme: MuiTheme) => {
  return {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      'a:not(:last-child), button:not(:last-child)': {
        marginRight: theme.spacing(5),
      },
    },
  };
};

const getRightAlignStyles = (theme: MuiTheme) => {
  return {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',

    [theme.breakpoints.up('md')]: {
      'a:not(:last-child), button:not(:last-child)': {
        marginLeft: theme.spacing(5),
      },
    },
  };
};

const getAlignStyles = (alignment: string = '', theme: MuiTheme) => {
  switch (alignment) {
    case 'center':
      return getCenterAlignStyles(theme);

    case 'right':
      return getRightAlignStyles(theme);

    default:
      return getLeftAlignStyles(theme);
  }
};

export const ButtonContainer = styled(Stack)<ButtonContainerProps>(({ alignment, theme }) => ({
  flexDirection: 'column',
  margin: `${theme.spacing(10)} 0 ${theme.spacing(5)} 0`,

  a: {
    padding: 0,
    background: 'none !important',
  },

  'a, button': {
    width: '100%',

    '&:not(:last-child)': {
      margin: `0 0 ${theme.spacing(5)} 0`,
    },

    button: {
      margin: 0,
    },
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',

    'a, button': {
      margin: 0,
      width: 'auto',

      '&:not(:last-child)': {
        margin: 0,
      },
    },
  },

  ...getAlignStyles(alignment, theme),
}));
