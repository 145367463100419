import { PortfolioOverview } from '../models';
import { PerformanceStatus, DistributionOption } from '../../../../enums';
import {
  InvestmentEngine,
  FundProvider,
  gateType,
  TenderStatus,
  InvestmentTypes,
  InvestmentStatusTypes,
} from '../../../../types';

export const portfolioOverviewFundTheoremActive: PortfolioOverview = {
  userId: 109,
  accountSummary: {
    outstandingPrincipal: 0,
    outstandingPrincipalPreDefault: 0,
    outstandingPrincipalPostDefault: 0,
    accruedInvestmentInterest: 0,
    accruedInvestmentInterestPreDefault: 0,
    accruedInvestmentInterestPostDefault: 0,
    balanceInDefault: 0,
    balanceByPerformanceStatus: {
      PERFORMING: 20833.1,
      DEFAULT: 0.0,
      MODIFIED_OUTLOOK: 0.0,
      PARTIAL_DEFAULT: 0.0,
      MARINE_DEFAULT: 0.0,
    },
    investmentBalance: 20833.1,
    pendingInvestments: 0.0,
    reserveBalance: 0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    total: 20833.1,
    weightedInvestmentRate: 25.0,
    irr: 6.65,
    interestEarnedLast30: 0.0,
    fundsTotal: 20833.1,
    funds: [
      {
        investorAccountId: '57',
        noteUrlHash: 'qzqC4A',
        fundCode: 'qzqC4A',
        fundId: '60d2a3c0-c267-11ec-bba8-619a2280f6da',
        total: 20833.1,
        nav: 10.0,
        navDate: '2023-05-30',
        sharesCount: 2083.31,
        canToggleDistributionOption: false,
        dividendDistributionOption: DistributionOption.Cash,
        commitmentBreakdown: null,
        fundInvestmentStatus: 'CONFIRMED',
        fundConfig: {
          fees: {
            MEMBER_EXPENSE: {
              rate: 0.25,
            },
            MANAGEMENT_FEE: {
              rate: 1.5,
            },
          },
          feesBalanceProviders: {},
          balanceCalculationType: null,
          capitalBalanceReductionType: null,
          positionsEffectiveDistributionImpact: null,
        },
      },
    ],
  },
  capital: null,
  investorEntities: [],
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 0,
    compoundInterestEarned: 0,
    interestPaid: 0,
    fexpPaid: 0,
    fexpBalance: 0,
    netPaid: 0,
    walletInterestPaid: 0,
    compoundInterestPaid: 0,
    endingAccruedInterest: 0,
    interestEarnedPreDefault: 0,
    interestEarnedPostDefault: 0,
    earningsTimeline: [],
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: 0,
    repaidPrincipal: 0,
    endingOutstandingPrincipal: 0,
    endingOutstandingPrincipalPreDefault: 0,
    endingOutstandingPrincipalPostDefault: 0,
    liquidationPaid: 0,
    liquidationDiscount: 0,
    isInDefault: false,
    principalTimeline: [],
  },
  investmentDetail: {
    fundId: '60d2a3c0-c267-11ec-bba8-619a2280f6da',
    fundType: 'THIRD_PARTY_FUND',
    noteUrlHash: 'qzqC4A',
    title: 'Enhanced Crypto Fund ',
    distributionRate: 25.0,
    assetClass: 'CRYP',
    assetClassDescription: 'Crypto',
    paymentType: 'Event-Based',
    termPhrase: '-',
    advisor: 'YieldStreet Management LLC',
    subAdvisor: 'Osprey Funds',
    interestRate: 0,
    taxDocument: 'K-1',
    taxMonth: 'SEPTEMBER',
    firstYearExpense: 0,
    annualFlatExpense: 0,
    irr: 0.0,
    investedDate: '2022-04-27',
    activeDate: '2023-02-28',
    launchedDate: '2022-04-27',
    terminationDate: '',
    interestDefaultedDate: null,
    principalDefaultedDate: null,
    dateOfFirstDefault: null,
    originator: null,
    dividendDistributionOption: DistributionOption.Cash,
    canToggleDistributionOption: false,
    performanceStatus: PerformanceStatus.PERFORMING,
    performanceStatusNote: '',
    spvType: null,
    offeringData: {
      offeringHero: {
        customRateString: '25%+',
      },
    },
    investments: [
      {
        type: InvestmentTypes.Investment,
        dateInvested: '2023-02-02',
        dateActive: '2023-02-28',
        shareCount: 100.0,
        sharePrice: 10.0,
        status: InvestmentStatusTypes.Confirmed,
        amount: 1000.0,
      },
      {
        type: InvestmentTypes.Investment,
        dateInvested: '2022-05-06',
        dateActive: '2022-05-31',
        shareCount: 500.0,
        sharePrice: 10.0,
        status: InvestmentStatusTypes.Confirmed,
        amount: 5000.0,
      },
      {
        type: InvestmentTypes.Investment,
        dateInvested: '2022-04-27',
        dateActive: '2022-05-31',
        shareCount: 2500.0,
        sharePrice: 10.0,
        status: InvestmentStatusTypes.Confirmed,
        amount: 25000.0,
      },
    ],
    tenderOfferStatus: TenderStatus.UNAVAILABLE,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 31000.0,
    totalRepaid: 0,
    netTotalRepaid: 0,
    fundProvider: FundProvider.Theorem,
    engine: InvestmentEngine.Funds,
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: [
      {
        id: 'f6c7f293-a330-11ed-98d2-b534e0dceb47',
        irServiceInvestmentId: 'ea0a88b0-a330-11ed-8010-dbba60c8336e',
        externalInvestmentId: 215267,
        noteUrlHash: 'qzqC4A',
        investorAccountId: '57',
        adminUserId: '',
        visibility: 'ALL',
        priority: 51,
        timestamp: 1677621478,
        date: '2023-02-28',
        type: gateType.requestNotActive,
        status: 'CLEARED',
        clearReason: 'RESOLVED',
        owner: 'YS',
      },
    ],
  },
  wallets: [],
  investmentStrategies: [],
};
