import {
  FriendlySize as Size,
  SizeMap,
  friendlyMedia as media,
  friendlySizes as sizes,
} from '../../themes/media';
import { SimpleInterpolation, css } from 'styled-components';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import { css as muiCss } from '@mui/material/styles';
import { SerializedStyles } from '@emotion/utils';
import { CSS_THEME as muiTheme } from '../../../theme';

export type Prop = number | string | boolean | undefined;

export type MaybeSizeMap<T extends Prop> = T | SizeMap<Size, T>;
export type MaybePartialSizeMap<T> = T | Partial<SizeMap<Size, T>>;
export type SizeMapType<S> = S extends MaybeSizeMap<infer T> ? T : never;

export type PropMap<T extends Prop> = {
  [key: string]: T;
};

export type MaybeSizePropMap<C extends PropMap<any>> = {
  [P in keyof C]: MaybeSizeMap<C[P]> | MaybePartialSizeMap<C[P]>;
};

export type PartialMaybeSizePropMap<C extends PropMap<any>> =
  | MaybeSizePropMap<C>
  | Partial<MaybeSizePropMap<C>>;

export type PartialSizeMap<C extends PropMap<any>> = {
  [P in keyof C]?: C[P] extends Prop ? C[P] : MaybePartialSizeMap<SizeMapType<C[P]>>;
};

export const isValueMap = <T extends Prop>(
  value: MaybeSizeMap<T> | MaybePartialSizeMap<T>
): value is SizeMap<Size, T> | Partial<SizeMap<Size, T>> => isObject(value);

export const getValue = <T extends Prop>(value?: T, defaultValue?: T): T | undefined => {
  return isNil(value) ? defaultValue : value;
};

export const getSizeValue = <T extends Prop>(
  size: Size,
  value?: MaybeSizeMap<T> | MaybePartialSizeMap<T>,
  defaultValue?: MaybeSizeMap<T> | MaybePartialSizeMap<T>
): T | undefined => {
  defaultValue = !isNil(defaultValue) ? getSizeValue(size, defaultValue) : defaultValue;
  if (!isNil(value) && isValueMap(value)) {
    const sizeValue = value[size as Size];
    return getValue(sizeValue, defaultValue);
  }
  return getValue(value, defaultValue);
};

export const allValueProps = <T extends Prop, C extends Partial<PropMap<T>>>(
  props: PartialMaybeSizePropMap<C>
): props is C => !Object.values(props).filter(prop => isValueMap(prop)).length;

export const getProps = <T extends Prop, C extends Partial<PropMap<T>>>(
  props: C,
  defaultProps?: Partial<C>
): C =>
  mapValues(props, (value, key) => {
    return getValue(value, defaultProps && defaultProps[key]);
  }) as C;

export const getSizeProps = <T extends Prop, C extends Partial<PropMap<T>>>(
  size: Size,
  props: MaybeSizePropMap<C>,
  defaultProps?: PartialMaybeSizePropMap<C>
): C =>
  mapValues(props, (value, key) => {
    return getSizeValue(size, value, defaultProps && (defaultProps as Partial<C>)[key]);
  }) as C;

export const sizeMap = <T extends Prop>(sizeMapper: (props: Size) => T): SizeMap<Size, T> =>
  sizes.reduce((acc: any, size) => {
    acc[size] = sizeMapper(size);
    return acc;
  }, {});

export const sizeCss = <T extends Prop, C extends PropMap<T>>(
  styleMapper: (props: C) => SimpleInterpolation,
  props: MaybeSizePropMap<C>,
  defaultProps?: PartialMaybeSizePropMap<C>
): SimpleInterpolation =>
  allValueProps(props) && ((defaultProps && allValueProps(defaultProps)) || !defaultProps)
    ? styleMapper(getProps(props, defaultProps))
    : css`
        ${sizes.map(
          size => media[size]`
            ${styleMapper(getSizeProps(size, props, defaultProps))}
          `
        )}
      `;

/**
 * This replaces `sizeCss` for the MUI Styled Engine.
 */
export const sizeCssMui = <T extends Prop, C extends PropMap<T>>(
  styleMapper: (props: C) => SerializedStyles,
  props: MaybeSizePropMap<C>,
  defaultProps?: PartialMaybeSizePropMap<C>
): SerializedStyles =>
  allValueProps(props) && ((defaultProps && allValueProps(defaultProps)) || !defaultProps)
    ? styleMapper(getProps(props, defaultProps))
    : muiCss`
      ${muiTheme.breakpoints.keys.map(
        size => `${size}
          ${styleMapper(getSizeProps(size as Size, props, defaultProps))}
        `
      )}
    `;
