import {
  UnitBankLinkMigrationInvestorDetails,
  UnitKYCMigrationInvestorDetails,
} from '@yieldstreet/platform-kit';

export enum UnitMigrationStatus {
  KYC_REQUIRED = 'KYC_REQUIRED',
  KYC_INCOMPLETE = 'KYC_INCOMPLETE',
  KYC_PENDING = 'KYC_PENDING',
  KYC_FAILED = 'KYC_FAILED',
  KYC_ERROR = 'KYC_ERROR',
  BANK_LINK_REQUIRED = 'BANK_LINK_REQUIRED',
  COMPLETED = 'COMPLETED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  COMING_SOON = 'COMING_SOON',
}

export const ongoingStatuses = [
  UnitMigrationStatus.KYC_INCOMPLETE,
  UnitMigrationStatus.KYC_PENDING,
  UnitMigrationStatus.KYC_FAILED,
  UnitMigrationStatus.KYC_ERROR,
  UnitMigrationStatus.BANK_LINK_REQUIRED,
];

export const actionableStatuses = [
  UnitMigrationStatus.KYC_REQUIRED,
  UnitMigrationStatus.KYC_INCOMPLETE,
  UnitMigrationStatus.KYC_FAILED,
  UnitMigrationStatus.KYC_ERROR,
  UnitMigrationStatus.BANK_LINK_REQUIRED,
];

export const eligibleStatuses = [
  ...actionableStatuses,
  UnitMigrationStatus.KYC_PENDING,
  UnitMigrationStatus.COMPLETED,
];

export const finalStatuses = [
  UnitMigrationStatus.NOT_APPLICABLE,
  UnitMigrationStatus.COMING_SOON,
  UnitMigrationStatus.COMPLETED,
];

export const problemStatuses = [UnitMigrationStatus.KYC_FAILED, UnitMigrationStatus.KYC_ERROR];

export type UnitMigrationConfig = {
  id: string;
  name: string;
  type: string;
  status: UnitMigrationStatus;
  ongoing: boolean;
  actionable: boolean;
  eligible: boolean;
  final: boolean;
  problem: boolean;
  kycDetails?: UnitKYCMigrationInvestorDetails;
  bankLinkDetails?: UnitBankLinkMigrationInvestorDetails;
};
