import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
import { GetMetricNumberRequest } from '../../types/fomo-metrics';

export const fomoMetricsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.FomoMetrics],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getMetricNumber = builder.query<number, GetMetricNumberRequest>({
        query: ({ metricName, noteId, from, to }) => ({
          url: `/api/fomo/metric/${metricName}`,
          method: HttpMethods.Get,
          params: { noteId, from, to },
        }),
        providesTags: [Tags.FomoMetrics],
        transformResponse: (...data) => {
          // TODO: check linter config to ensure we are targeting modern
          // es versions and eventually allow `_` for unused params.
          // eslint-disable-next-line
          const [{ response }, _, { metricName }] = data;
          return response?.items?.[0]?.[metricName];
        },
      });

      return { getMetricNumber };
    },
  });
