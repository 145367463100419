import { InvestorEntityProvider, InvestorEntity } from '@yieldstreet/platform-kit';

type ProviderConfig = {
  company: string;
  platform: string;
};

export const PROVIDER_CONFIG: { [key in InvestorEntityProvider]?: ProviderConfig } = {
  [InvestorEntityProvider.ETC]: {
    company: 'Equity Trust',
    platform: 'myEQUITY',
  },
};

export const IRA_FUNDING_INSTRUCTIONS = `We will automatically request a wire transfer from your retirement account to fund your investment. This should take ~1-2 business days if the funds are available in your account and you have a verified accreditation.`;

export const IRA_PURCHASE_FAILED =
  'The purchase request we sent to {{iraprovider}} for your investment failed. Usually, this is due to insufficient funds. Please re-check your {{iraprovider}} balance and let us know when sufficient funds have arrived. We will retry the request at that time.';

export const getIRAFundingInstructions = () => {
  return IRA_FUNDING_INSTRUCTIONS;
};

export const getIRAPurchaseFailed = (investorEntity: InvestorEntity) => {
  const provider = investorEntity?.provider?.type;
  const providerCompany = PROVIDER_CONFIG[provider]?.company;
  if (providerCompany) {
    return IRA_PURCHASE_FAILED.replace(/{{iraprovider}}/g, providerCompany);
  }
  return;
};
