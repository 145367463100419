import { HttpMethods } from '../../../enums';
import {
  TwoFAStatusAPIPayload,
  TwoFALoginAPIRequest,
  TwoFALoginAPIPayload,
  TwoFARegisterAPIRequest,
  TwoFAConfirmAPIRequest,
  TwoFAFactorResponse,
} from './two-factor-auth.model';
import { Tags, restApi } from '../../common';

export const twoFactorAuthApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserTwoFactorAuthStatus, Tags.UserState],
  })
  .injectEndpoints({
    endpoints: builder => {
      const status = builder.query<TwoFAStatusAPIPayload, {}>({
        query: () => {
          return {
            url: '/a/api/user/twofa/status',
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.UserTwoFactorAuthStatus],
      });

      const login = builder.mutation<TwoFALoginAPIPayload, TwoFALoginAPIRequest>({
        query: ({ token }) => {
          return {
            url: '/a/api/user/twofa/login',
            method: HttpMethods.Post,
            data: { token },
          };
        },
        invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus],
      });

      const register = builder.mutation<TwoFALoginAPIPayload, TwoFARegisterAPIRequest>({
        query: ({ phone, method }) => {
          return {
            url: '/a/api/user/twofa/register',
            method: HttpMethods.Post,
            data: { phone, method },
          };
        },
        invalidatesTags: [Tags.UserTwoFactorAuthStatus],
      });

      const confirm = builder.mutation<TwoFALoginAPIPayload, TwoFAConfirmAPIRequest>({
        query: ({ token }) => {
          return {
            url: '/a/api/user/twofa/confirm',
            method: HttpMethods.Post,
            data: { token },
          };
        },
        invalidatesTags: [Tags.UserTwoFactorAuthStatus, Tags.UserState],
      });

      const resend = builder.mutation<TwoFALoginAPIPayload, {}>({
        query: () => {
          return {
            url: '/a/api/user/twofa/resend',
            method: HttpMethods.Post,
          };
        },
        invalidatesTags: [Tags.UserTwoFactorAuthStatus],
      });

      const confirmEmail = builder.mutation<TwoFALoginAPIPayload, TwoFAConfirmAPIRequest>({
        query: ({ token }) => {
          return {
            url: '/a/api/user/twofa/confirm-email',
            method: HttpMethods.Post,
            data: { token },
          };
        },
        invalidatesTags: [Tags.UserTwoFactorAuthStatus],
      });

      const createFactor = builder.mutation<TwoFAFactorResponse, {}>({
        query: () => {
          return {
            url: '/a/api/user/twofa/factor',
            method: HttpMethods.Post,
          };
        },
        invalidatesTags: [Tags.UserTwoFactorAuthStatus],
      });

      return {
        status,
        login,
        register,
        confirm,
        resend,
        confirmEmail,
        createFactor,
      };
    },
  });
