import { uniqBy, isEmpty } from 'lodash';
import { RecurringPayment, AudienceResolution } from '@yieldstreet/platform-kit';

export enum PersonalizedPortfolioModulesIcons {
  drip = 'DRIP',
  deposit = 'DEPOSIT',
  preferences = 'PREFERENCES',
}

export const dripModule = {
  title: 'Enable distribution reinvestment',
  subtitle: 'Invest income into the Fund',
  icon: PersonalizedPortfolioModulesIcons.drip,
};

export const monthlyDepositResponse = {
  title: 'Set up a monthly deposit',
  subtitle: 'Plan ahead for your next investment',
  linkTo: '/portfolio/transfer-funds?monthlyDeposit=true',
  icon: PersonalizedPortfolioModulesIcons.deposit,
};

export const preferencesResponse = {
  title: 'Share your preferences',
  subtitle: 'Receive custom investment alerts',
  linkTo: '/management/autoinvest',
  icon: PersonalizedPortfolioModulesIcons.preferences,
};

interface PersonalizedPortfolioModules {
  audiences: AudienceResolution;
  accountRecurringDeposits: Array<RecurringPayment>;
  accounts: {
    totalAccounts: number;
    funds: Array<{
      noteUrlHash: string;
      investorAccountId: string;
      canToggleDistributionOption: boolean;
      dividendDistributionOption: string;
    }>;
  };
}

export interface PersonalizedPortfolioModulesResponse {
  title: string;
  subtitle: string;
  linkTo: string;
  icon: PersonalizedPortfolioModulesIcons;
}

export const personalizedPortfolioModules = ({
  audiences,
  accountRecurringDeposits,
  accounts,
}: PersonalizedPortfolioModules): PersonalizedPortfolioModulesResponse | null => {
  if (isEmpty(audiences) || accounts.totalAccounts === 0) {
    return null;
  }

  const prismFundInvestments = accounts.funds.filter(
    fund => fund.canToggleDistributionOption && fund.noteUrlHash === 'LJouqg'
  );

  const investorsWithDripTurnedOff = prismFundInvestments.filter(
    prismAccount => prismAccount.dividendDistributionOption === 'CASH'
  ).length;

  const uniqInvestors = uniqBy(prismFundInvestments, 'investorAccountId');
  const investorsInPrism = uniqInvestors.length;

  if ((!audiences.altExperienced || audiences.allNAI) && investorsInPrism) {
    // multiple accounts
    if (accounts.totalAccounts > 1) {
      const isAllInvestorsWithDripTurnedOff = investorsWithDripTurnedOff === investorsInPrism;

      if (investorsInPrism === 1 && isAllInvestorsWithDripTurnedOff) {
        const investorAccountId = prismFundInvestments[0].investorAccountId;

        return {
          ...dripModule,
          linkTo: `/portfolio/investment-details-fund/LJouqg/${investorAccountId}?action=openDripSettings`, // to automatically open drip settings
        };
      }

      if (investorsInPrism > 1 && isAllInvestorsWithDripTurnedOff) {
        return {
          ...dripModule,
          linkTo: '/portfolio/investments',
        };
      }

      if (isEmpty(accountRecurringDeposits)) {
        return monthlyDepositResponse;
      }
    } else {
      if (investorsWithDripTurnedOff) {
        const investorAccountId = prismFundInvestments[0].investorAccountId;
        return {
          ...dripModule,
          linkTo: `/portfolio/investment-details-fund/LJouqg/${investorAccountId}?action=openDripSettings`, // to automatically open drip settings
        };
      }

      if (isEmpty(accountRecurringDeposits)) {
        return monthlyDepositResponse;
      }
    }
    return null;
  }

  if (audiences.altExperienced && audiences.noInvestmentPreferences && !audiences.allNAI) {
    return preferencesResponse;
  }

  return null;
};
