import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Heading, Paragraph } from '../..';
import { media } from '../../themes/media';
import { Label, LabelProps } from '../../content/text';
import CardHiglightBg from '../../assets/graphics/backgrounds/highlight-card-bg.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

interface HighlightedProps {
  highlighted?: boolean;
}

type CardItem = {
  img?: React.ReactNode;
  highlighted?: boolean;
  heading?: string;
  headingDescription?: string;
  secondLabel?: string;
  secondLabelDescription?: string;
  button?: React.ReactNode;
  node?: React.ReactNode;
};
interface CardListWithHighlightProps {
  cardsData: CardItem[];
  fixedHighlight?: boolean;
}

export const CardListWithHighlight: FunctionComponent<CardListWithHighlightProps> = ({
  cardsData,
  fixedHighlight = false,
}) => {
  const renderCard = (card?: CardItem) => {
    if (card) {
      return (
        <Card highlighted={card.highlighted}>
          <Logo highlighted={card.highlighted}>{card.img}</Logo>

          <Heading inverse={card.highlighted} type={card.highlighted ? 2 : 4}>
            {card.heading}
          </Heading>

          <TextBorder
            highlighted={card.highlighted}
            inverse={card.highlighted}
            secondary
            small={!card.highlighted}
          >
            {card.headingDescription}
          </TextBorder>

          {card.highlighted ? (
            <Heading inverse type={5}>
              {card.secondLabel}
            </Heading>
          ) : (
            <Paragraph small>{card.secondLabel}</Paragraph>
          )}

          <SecondLabelDescription inverse={card.highlighted} small>
            {card.secondLabelDescription}
          </SecondLabelDescription>

          {card.button && (
            <ButtonWrapper highlighted={card.highlighted}>{card.button}</ButtonWrapper>
          )}
        </Card>
      );
    }
    return null;
  };

  const getHighlitedCard = () => {
    const highlightedItem: CardItem | undefined = cardsData.find(item => item.highlighted);
    if (highlightedItem?.node) {
      return highlightedItem.node;
    }
    return renderCard(highlightedItem);
  };

  return (
    <Wrapper>
      {fixedHighlight && getHighlitedCard()}

      <SSwiper slidesPerView="auto" enabled={fixedHighlight}>
        {cardsData.map((card, index) => {
          if (card.highlighted && fixedHighlight) {
            return null;
          }

          return (
            <SwiperSlide key={`card-${index}`}>
              {card.node ? card.node : renderCard(card)}
            </SwiperSlide>
          );
        })}
      </SSwiper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  ${media.tablet`
    justify-content: center;
  `}

  > div.swiper-container {
    margin-left: 0;
    margin-right: 0;
  }
`;

const SSwiper = styled(Swiper)`
  margin-left: 0;
  margin-right: 0;

  .swiper-wrapper {
    align-items: center;
    display: flex;
  }

  .swiper-slide {
    width: auto;
  }

  ${media.tablet`
    .swiper-wrapper {
      justify-content: center;
    }
  `}

  ${media.large`
    .swiper-slide:first-child > div {
      margin-left: 0 !important;
    }
  `}
`;

export const Card = styled.div<HighlightedProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  position: relative;
  text-align: center;
  z-index: 2;

  ${media.desktop`
    cursor: default;
    margin-left: 20px;
  `}

  ${props =>
    props.highlighted
      ? css`
          background: url('${CardHiglightBg}') no-repeat 0px
            ${props.theme.colors.background_inverse_light};
          background-size: cover;
          color: ${props.theme.colors.white};
          height: 388px;
          width: 190px;
          margin-left: 0;

          ${media.large`
              margin-left: 20px;
              height: 438px;
              width: 372px;
          `}
        `
      : css`
          background: ${props.theme.colors.background_card};
          height: 263px;
          width: 105px;

          ${media.large`
              height: 263px;
              width: 177px;
          `}
        `}
`;

export const Logo = styled.div<HighlightedProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  ${props =>
    props.highlighted
      ? css`
          height: 114px;
          width: 190px;

          > img {
            max-width: 145px;
          }

          ${media.large`
        height: 152px;
        width: 372px;

        > img {
          max-width: 216px;
          width: 100%;
        }
      `}
        `
      : css`
          width: 105px;
          height: 77px;

          > img {
            max-width: 85px;
          }

          ${media.large`
        height: 97px;
        width: 177px;

        > img {
          max-width: 157px;
        }
      `}
        `}
`;

export const TextBorder = styled(Paragraph)<HighlightedProps>`
  border-bottom: 1px solid ${props => props.theme.colors.border_default};
  margin-bottom: 20px;
  padding-bottom: 20px;

  ${props =>
    props.highlighted
      ? css`
          margin: 0 37px 20px 37px;
          width: 117px;

          ${media.large`
        margin: 0 99px 20px 99px;
        width: 174px;
      `}
        `
      : css`
          margin: 0 10px 20px 10px;
          width: 85px;

          ${media.large`
        margin: 0 20px 20px 20px;
        width: 137px;
      `}
        `}
`;

export const SecondLabelDescription = styled(Label)<LabelProps>`
  padding: 0 20px;
  word-break: keep-all;
  ${media.large`
      padding: 0;
      word-break: inherit;
    `}
`;

export const ButtonWrapper = styled.div<HighlightedProps>`
  display: block;
  margin: 40px auto 0 auto;

  button {
    min-width: auto;
  }
`;
