export type eventProps = {
  errorType: string;
  errorMessage: string;
  errorId: string;
};

export const setClientError = (event: eventProps, eventName: string = 'client-error') => {
  const { errorMessage } = event;

  if (errorMessage && window && (window as any)?.analytics) {
    (window as any)?.analytics?.track(eventName, event);
  }
};
