import React, { ReactNode, FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
import { theme2 } from '../../themes';
import { SectionContainer } from '../../layout';
import { TextBlockProps } from '../../sections/text-block-component/TextBlock.model';
type Background = 'primary' | 'secondary';

const getBgType = (background?: Background) => {
  switch (background) {
    case 'primary':
      return theme2.gradients.radial_primary;
    case 'secondary':
      return theme2.gradients.radial_secondary;
    default:
      return 'transparent';
  }
};

interface MarketingReengagementBannerProps extends Omit<TextBlockProps, 'align'> {
  inverse?: boolean;
  background?: Background;
  children?: ReactNode;
  largeWidth?: boolean;
}

export const MarketingReengagementBanner: FunctionComponent<MarketingReengagementBannerProps> = ({
  inverse,
  background,
  label,
  heading,
  content,
  mobileAlign = 'center',
  button,
  largeWidth,
}) => {
  return (
    <SectionContainer>
      <Background inverse={inverse} background={background}>
        <TextBlockContainer largeWidth={largeWidth}>
          <TextBlock
            label={label}
            heading={heading}
            content={content}
            align="center"
            mobileAlign={mobileAlign}
            inverse={!inverse}
            button={button}
          />
        </TextBlockContainer>
      </Background>
    </SectionContainer>
  );
};

const Background = styled.div<Pick<MarketingReengagementBannerProps, 'inverse' | 'background'>>`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0 24px;
  background: ${props =>
    props.inverse ? props.theme.colors.background_light : getBgType(props.background)};
  border-radius: 8px;

  ${media.desktop`
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0px;
  `}
`;

const TextBlockContainer = styled.div<Pick<MarketingReengagementBannerProps, 'largeWidth'>>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 48px 0px 48px 0px;

  ${media.desktop<Pick<MarketingReengagementBannerProps, 'largeWidth'>>`
    width: ${props => (props.largeWidth ? '720px' : '490px')};
  `}
`;
