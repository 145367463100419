import { styled } from '@mui/material/styles';

export const ExternalProviderWrapper = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-bottom: 8px;
  }
`;

export const Arrow = styled('div')`
  border: solid ${props => props.theme.palette.border.strong};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
