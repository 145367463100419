import styled from 'styled-components';

import { Heading, Label } from '../../content';
import { Card } from '../card';

export const OfferingThumb = styled.img`
  border-radius: 6px;
  min-height: 140px;
  object-fit: cover;
  width: 100%;

  transition: all 0.4s;
`;

export const SCard = styled(Card)`
  transition: all 0.4s;

  &:hover {
    box-shadow: ${props => props.theme.shadows.shadow_hover_card};

    ${OfferingThumb} {
      scale: 1.1;
      opacity: 0.9;
    }
  }
`;

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing.xs};
  max-height: 30px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.xxs};
  width: 100%;
`;

export const MainContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 115px;
`;

export const AssetClass = styled(Label)`
  color: ${props => props.theme.colors.text_label_inverse} !important;
  font-size: 12px;
  letter-spacing: ${props => props.theme.spacing.xxs};
  margin-bottom: ${props => props.theme.spacing.xxs};
  text-transform: uppercase;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const OfferingTitle = styled(Heading)`
  max-width: 100%;
  line-height: 26px;
  height: 100%;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const OfferingTitleWrapper = styled.div`
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const MetricWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.border_default};
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0;
  width: 100%;

  &:last-child {
    border: 0;
  }

  &:first-child {
    padding-top: 0;
  }
`;
