import React, { FunctionComponent } from 'react';

import { MarketingCinematicProps, Container } from './MarketingCinematicContainer.model';

export const MarketingCinematicContainer: FunctionComponent<MarketingCinematicProps> = ({
  children,
  withBorder,
}) => {
  return <Container withBorder={withBorder}>{children}</Container>;
};
