import { useState, useMemo, useEffect, useCallback } from 'react';
import { debounce, filter, first, isEmpty } from 'lodash';
import {
  communicationApi,
  getCacheParams,
  CommunicationMessageType,
  FetchMessagesUpdateStrategy,
  FetchMessagesApiRequestParams,
  CommunicationMessageModified,
} from '@yieldstreet/platform-kit';

import { AttachAnchor, AttachAnchorOptions, UseMessagesProps } from './useMessages.model';

const {
  useDismissMessageMutation,
  useDismissMessagesMutation,
  useFetchMessagesQuery,
  useGetMessagesQuery,
  useReadMessageMutation,
  useReadMessagesMutation,
} = communicationApi;

const attachAnchor = (
  messages?: CommunicationMessageModified[],
  options?: AttachAnchorOptions
): AttachAnchor | undefined => {
  if (
    !isEmpty(messages) &&
    options &&
    options?.fetchingStrategy === FetchMessagesUpdateStrategy.AGGREGATE &&
    options?.page > 1
  ) {
    const { messageSpecId, sortDate } = first(messages) as CommunicationMessageModified;
    return {
      id: messageSpecId,
      timestamp: sortDate,
    };
  }
  return undefined;
};

export const useMessages = ({
  fetchConfig: initFetchConfig,
  hookConfig: initHookConfig,
}: UseMessagesProps) => {
  const [fetchConfig, setFetchConfig] = useState<FetchMessagesApiRequestParams>({
    page: 1,
    limit: 5,
    types: [CommunicationMessageType.Activity],
    updates: FetchMessagesUpdateStrategy.AGGREGATE,
    ...(initFetchConfig || {}),
  });

  const updateFetchConfig = useCallback(
    (updatedConfig: Partial<FetchMessagesApiRequestParams>) =>
      setFetchConfig({ ...fetchConfig, ...updatedConfig }),
    [fetchConfig, setFetchConfig]
  );

  const [hookParams, setHookParams] = useState({
    autoRead: false,
    autoReadDelay: 3000,
    ...initHookConfig,
  });

  const { data: messages, refetch: refetchMessages } = useGetMessagesQuery(
    getCacheParams(fetchConfig)
  );

  const {
    data: messagesQuery,
    isFetching,
    isLoading,
    isError,
  } = useFetchMessagesQuery(fetchConfig, {
    refetchOnMountOrArgChange: true,
    skip:
      (!fetchConfig?.authToken && fetchConfig.updates === FetchMessagesUpdateStrategy.AGGREGATE) ||
      fetchConfig.updates === FetchMessagesUpdateStrategy.LIVE_ONLY,
  });

  const [readMessage] = useReadMessageMutation();
  const [readMessages] = useReadMessagesMutation();
  const [dismissMessage] = useDismissMessageMutation();
  const [dismissMessages] = useDismissMessagesMutation();

  const isCountReached = useMemo(
    () => messagesQuery && messagesQuery?.currentPage >= messagesQuery?.totalPages,
    [messagesQuery]
  );

  const fetchMessages = (params?: FetchMessagesApiRequestParams) => {
    if (fetchConfig?.page) {
      const page = params?.page || fetchConfig?.page + 1;
      updateFetchConfig({
        ...params,
        page: page,
        anchor: attachAnchor(messages, {
          fetchingStrategy: fetchConfig?.updates,
          page,
        }),
      });
    }
  };

  const onReadMessage = useCallback(
    (messageSpecId: string, read: boolean, isGlobalCache: boolean = true) =>
      readMessage({
        messageSpecId,
        messageArgs: isGlobalCache ? undefined : fetchConfig,
        read,
      }),
    [fetchConfig, readMessage]
  );

  const onReadMessages = useCallback(
    (messageSpecIds: string[], read: boolean, isGlobalCache: boolean = true) =>
      readMessages({
        messageSpecIds,
        messageArgs: isGlobalCache ? undefined : fetchConfig,
        read,
      }),
    [fetchConfig, readMessages]
  );

  const onDismissMessage = useCallback(
    (messageSpecId, dismissed) =>
      dismissMessage({
        messageSpecId,
        messageArgs: fetchConfig,
        dismissed,
      }),
    [dismissMessage, fetchConfig]
  );

  const onDismissMessages = useCallback(
    (messageSpecIds, dismissed) =>
      dismissMessages({
        messageSpecIds,
        messageArgs: fetchConfig,
        dismissed,
      }),
    [dismissMessages, fetchConfig]
  );

  const unreadMessages = useMemo(() => filter(messages, ['read', false]), [messages]);
  const unseenMessages = useMemo(() => filter(messages, ['seen', false]), [messages]);
  const undismissedMessages = useMemo(() => filter(messages, ['dismissed', false]), [messages]);

  /**
   * @desc AutoRead
   * This useEffect handles that autoRead functionality. It will mark the messages as seen
   * (optimistically) whilst also dispatching an API request as to mark the message as read.
   * This functionality is debounced as not to pollute the browser with multiple API calls.
   */
  useEffect(() => {
    const { autoRead, autoReadDelay, autoReadGlobalCache = true } = hookParams;
    if (autoRead && !isEmpty(unreadMessages)) {
      const execAutoRead = debounce(() => {
        readMessages({
          messageSpecIds: unreadMessages.map(message => message.messageSpecId),
          messageArgs: autoReadGlobalCache ? undefined : fetchConfig,
          read: true,
        });
      }, autoReadDelay);
      execAutoRead();
    }
  }, [fetchConfig, hookParams, readMessages, unreadMessages]);

  return {
    dismissMessage: onDismissMessage,
    dismissMessages: onDismissMessages,
    fetchMessages,
    isCountReached,
    isError,
    isFetching,
    isLoading,
    messages,
    readMessage: onReadMessage,
    readMessages: onReadMessages,
    refetchMessages,
    setHookParams,
    undismissedMessages,
    unreadMessages,
    unseenMessages,
  };
};
