import React, { FunctionComponent } from 'react';
import styled, { ThemeProps, withTheme } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Heading, HeadingType } from '../../content/text';
import { Theme } from '../../types';
import { Container } from '../container';

interface PageTitleProps {
  backButton?: React.ReactNode;
  tagType?: HeadingType;
}

const PageTitleComponent: FunctionComponent<PageTitleProps & ThemeProps<Theme>> = ({
  backButton,
  tagType,
  children,
  theme,
}) => {
  return (
    <Background>
      <TitleContainer>
        {backButton && (
          <BackLink>
            <Arrow icon={faChevronLeft} color={theme.colors.text_paragraph_inverse} />
            {backButton}
          </BackLink>
        )}
        <Heading type={4} tagType={tagType || 1} inverse>
          {children}
        </Heading>
      </TitleContainer>
    </Background>
  );
};

const TitleContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Background = styled.div`
  border-top: 2px solid ${props => props.theme.colors.border_inverse};
  background: ${props => props.theme.colors.background_inverse};
  width: 100%;
  word-break: break-word;
`;

const BackLink = styled.div`
  margin-bottom: 10px;
`;

const Arrow = styled(FontAwesomeIcon)`
  margin: 0 10px 0 0;
`;

export const PageTitle = withTheme(PageTitleComponent);
