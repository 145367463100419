import { PdfLinkKeys, pdfLinks } from '@yieldstreet/platform-kit';

export const COMPLETE_INVEST_CHECKBOX_KEYS = {
  ADVISORY_RELATIONSHIP: 'advisoryRelationshipAcknowledged',
  ADVISORY_AGREEMENT: 'advisoryAgreement',
  BROCHURE_AND_CRS: 'brochureAndCRSForm',
};

export const COMPLETE_INVESTMENT_TYPE = {
  DISCRETIONARY: 'DISCRETIONARY',
  NON_DISCRETIONARY: 'NON_DISCRETIONARY',
};

export const COMPLETE_INVEST_CHECKBOXES = {
  [COMPLETE_INVEST_CHECKBOX_KEYS.ADVISORY_RELATIONSHIP]: {
    name: COMPLETE_INVEST_CHECKBOX_KEYS.ADVISORY_RELATIONSHIP,
    label: `I have received, carefully read and agree to the ::advisoryAgreement`,
    links: {
      advisoryAgreement: {
        url: pdfLinks[PdfLinkKeys.PCG_AGREEMENT],
        label: 'Advisory Agreement',
      },
    },
  },
  [COMPLETE_INVEST_CHECKBOX_KEYS.ADVISORY_AGREEMENT]: {
    name: COMPLETE_INVEST_CHECKBOX_KEYS.ADVISORY_AGREEMENT,
    label: `I have read and agree to the ::advisoryAgreement`,
    links: {
      advisoryAgreement: {
        url: pdfLinks[PdfLinkKeys.UNSGND_CLIENT_AGREEMENT],
        label: 'Advisory Agreement',
      },
    },
  },
  [COMPLETE_INVEST_CHECKBOX_KEYS.BROCHURE_AND_CRS]: {
    name: COMPLETE_INVEST_CHECKBOX_KEYS.BROCHURE_AND_CRS,
    label: `I have received YieldStreet Management, LLC's Form ADV Part 2A ::brochure and ::formCRS`,
    links: {
      brochure: {
        url: pdfLinks[PdfLinkKeys.FORM_ADV],
        label: 'Brochure',
      },
      formCRS: {
        url: pdfLinks[PdfLinkKeys.FORM_CRS],
        label: 'Form CRS',
      },
    },
  },
};

type CheckboxItemLink = {
  url: string;
  label: string;
};

type CheckboxItemLinks = {
  advisoryAgreement?: CheckboxItemLink;
  brochure?: CheckboxItemLink;
  formCRS?: CheckboxItemLink;
};

export type DocumentCheckboxList = {
  name: string;
  label: string;
  links: CheckboxItemLinks;
};
