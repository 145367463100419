import styled from 'styled-components';

export const TextContent = styled.div`
  text-align: left;
`;

export const HeaderContent = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;
