import React, { ComponentType, useState } from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { SFormControl, SFormHelperText, FormError, withFormikWeb, useLabelId } from '../common';
import { SFilledInput, SInputLabel } from '../text-input/styles';
import ArrowBlack from '../../assets/graphics/icons/arrow.svg';

import { SelectMenuStyles, Arrow } from './SelectInput.style';
import { SelectInputProps, useTestIds } from './SelectInput.model';
import { BaseProps } from '../../types';

export const SelectInput = styled<ComponentType<SelectInputProps & BaseProps>>(props => {
  const {
    label,
    value,
    onChange,
    open,
    error,
    options,
    allowNone,
    className,
    hiddenLabel,
    caption,
    fullWidth,
    width,
    hideIfSingleOption,
    name,
    disabled,
    testId,
    ...otherProps
  } = props;

  const [iconFacingUp, setIconFacinpUp] = useState(false);

  const handleChange = (value: string) => {
    const selectedOption = options.find(option => option.value === value)!;
    if (onChange) {
      onChange(value, selectedOption);
    }
  };

  const labelId = useLabelId(name);
  const showError = !!(error || caption);
  const valueIsDefined = value !== undefined && value !== null;

  if (hideIfSingleOption && options.length <= 1) {
    return null;
  }

  const testIds = useTestIds({
    testId: testId || name || 'select',
  });

  return (
    <SFormControl
      variant="filled"
      hiddenLabel={hiddenLabel}
      error={!!error}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      style={{ width }}
    >
      {label && !hiddenLabel && (
        <SInputLabel id={labelId} {...testIds.label.attr}>
          {label}
        </SInputLabel>
      )}
      <Select
        name={name}
        value={valueIsDefined ? value : ''}
        onChange={e => handleChange(e.target.value as string)}
        input={
          <SFilledInput
            inputProps={{
              'data-cy': testIds.input.id,
              'aria-labelledby': labelId,
            }}
          />
        }
        IconComponent={() => (
          <Arrow src={ArrowBlack} iconFacingUp={iconFacingUp} {...testIds.arrow.attr} />
        )}
        onOpen={() => setIconFacinpUp(true)}
        onClose={() => setIconFacinpUp(false)}
        MenuProps={{
          PopoverClasses: {
            paper: className,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          marginThreshold: 0,
          ...(open !== undefined ? { open } : {}),
        }}
        {...testIds.base.attr}
        {...otherProps}
      >
        {allowNone && (
          <MenuItem key="" value="" {...testIds.optionEmpty.attr}>
            <em>None</em>
          </MenuItem>
        )}
        {Array.isArray(options) &&
          options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              {...(option.disabled ? testIds.optionDisabled.attr : testIds.option.attr)}
            >
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {showError ? (
        <SFormHelperText>
          {error ? (
            <FormError
              type="select-input"
              name={name || 'select'}
              error={error}
              testId={testIds.error.id}
            />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </SFormControl>
  );
})`
  ${SelectMenuStyles}
`;

export const FormSelectInput = withFormikWeb(SelectInput);
