import styled from 'styled-components';

import { CardBaseWrapperProps } from './Card.model';

export const CardBaseContainer = styled.div<CardBaseWrapperProps>`
  border: 1px solid ${props => props.theme.colors.border_default};
  border-radius: 12px;
  height: 100%;
  display: inline-block;

  padding: ${props => props.theme.spacing.sm};
  width: 100%;

  background: ${props => props.bgColor || 'transparent'};
`;

export const CardBaseWrapper = styled(CardBaseContainer).attrs<CardBaseWrapperProps>(props => ({
  as: props.isLink ? 'a' : 'div',
}))`
  ${props =>
    props.isLink &&
    `
    color: inherit;
    text-decoration: none;
  `}

  ${props =>
    props.clickable &&
    `
   cursor: pointer;
  `}
`;
