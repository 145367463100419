import {
  UserGateCode,
  InvestorEntityGateCode,
  InvestorAccountGateCode,
  PortfolioStrategyValues,
  AllGateCodes,
  EligibilityGate,
  InvestorEntity,
  InvestorEntityStatus,
  InvestorEntityEligibilityGate,
  UserEligibilityGate,
  InvestorAccountEligibilityGate,
  InvestorEntityTypeName,
} from '@yieldstreet/platform-kit';
import { AppAction, AppActionConfig } from '../app-actions';
import { isFunction } from 'lodash';

export type GateContent = {
  title: string;
  description: string;
  action?: AppActionConfig<AppAction>;
  blocking?: boolean;
};

export type GateContentFunction<GateCode extends AllGateCodes> = (
  gateData: EligibilityGate<GateCode>
) => GateContent;

export type MaybeGateContent<GateCode extends AllGateCodes> =
  | GateContent
  | GateContentFunction<GateCode>;

export const isGateContent = <GateCode extends AllGateCodes>(
  gateContent: MaybeGateContent<GateCode>
): gateContent is GateContent => !isFunction(gateContent);

export const getGateContent = <GateCode extends AllGateCodes>(
  gateContent: MaybeGateContent<GateCode>,
  gateData: EligibilityGate<GateCode>
) => {
  if (!isGateContent(gateContent)) {
    return gateContent(gateData);
  }

  return gateContent;
};

export enum EligibilityGateLevel {
  USER = 'USER_GATES',
  INVESTOR_ENTITY = 'INVESTOR_ENTITY_GATES',
  INVESTOR_ACCOUNT = 'INVESTOR_ACCOUNT_GATES',
}

export type EligibilityGateCodeMap<Map extends Record<EligibilityGateLevel, string>> = Map;

export type EligibilityLevelGateContent<Level extends EligibilityGateLevel> =
  EligibilityGateContent<EligibilityLevelGateCodes[Level]>;

export type EligibilityLevelGateCodes = EligibilityGateCodeMap<{
  [EligibilityGateLevel.USER]: UserGateCode;
  [EligibilityGateLevel.INVESTOR_ENTITY]: InvestorEntityGateCode;
  [EligibilityGateLevel.INVESTOR_ACCOUNT]: InvestorAccountGateCode;
}>;

export type EligibilityGateContent<GateCode extends AllGateCodes> = {
  [Gate in GateCode]?: MaybeGateContent<Gate>;
};

export type EligibilityLevelGateContentMap = {
  [EligibilityGateLevel.USER]: (
    investorEntityGate?: UserEligibilityGate
  ) => EligibilityLevelGateContent<EligibilityGateLevel.USER>;
  [EligibilityGateLevel.INVESTOR_ENTITY]: (
    investorEntity: InvestorEntity,
    investorEntityStatus: InvestorEntityStatus,
    investorEntityGate: InvestorEntityEligibilityGate
  ) => EligibilityLevelGateContent<EligibilityGateLevel.INVESTOR_ENTITY>;
  [EligibilityGateLevel.INVESTOR_ACCOUNT]: (
    investorEntity: InvestorEntity,
    investorEntityStatus: InvestorEntityStatus,
    investorEntityGate?: InvestorAccountEligibilityGate
  ) => EligibilityLevelGateContent<EligibilityGateLevel.INVESTOR_ACCOUNT>;
};

export type PortfolioStrategyEligibilityGateContent = {
  [Key in PortfolioStrategyValues]: EligibilityLevelGateContentMap;
};

export interface GetEligibilityGatesConfig {
  onlyIfAllGated?: boolean;
  investorEntityId?: string;
}

export const EligibleEntityTypes = [InvestorEntityTypeName.INDIVIDUAL];
