import { Investor } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeInvestor: ObjectAnonymizer<Investor> = value =>
  anonymizeObject(value, {
    userFirstName: 'string',
    userLastName: 'string',
    userEmail: 'string',
    investorAccountName: 'string',
    userFullName: 'string',
  });
