/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Meta {
  name?: string;
  property?: string;
  content: string;
}

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title: string;
  showZendesk?: boolean;
}

interface SEOData {
  site: {
    siteMetadata: {
      description: string;
      author: string;
      keywords: string;
    };
  };
}

function SEO({ description, lang, meta, title, showZendesk }: SEOProps) {
  const { site } = useStaticQuery<SEOData>(
    graphql`
      query {
        site {
          siteMetadata {
            description
            author
            keywords
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://yieldstreetalternativeincomefund.com/`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...(meta || []),
      ]}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://api.segment.io" />
      <link rel="canonical" href={`https://yieldstreetalternativeincomefund.com/`} />

      <script src="//cdn.taboola.com/libtrc/yieldstreet-sc/tfa.js"></script>

      {showZendesk && (
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=c1287e98-175d-4d9a-a467-ff67493c6522"
        >
          {' '}
        </script>
      )}
    </Helmet>
  );
}

export default SEO;
