import React from 'react';
import styled from 'styled-components';

import Podcast from '../../assets/graphics/icons/podcast.svg';
import Cash from '../../assets/graphics/icons/commercial_finance.svg';
import Download from '../../assets/graphics/icons/file_download.svg';
import Risk from '../../assets/graphics/icons/risk.svg';
import Asset from '../../assets/graphics/icons/asset.svg';
import Structure from '../../assets/graphics/icons/structure.svg';
import Note from '../../assets/graphics/icons/note.svg';

interface IconPickerProps {
  iconName: string;
  className?: string;
}

const iconSelection = (name: string) => {
  switch (name) {
    case 'podcast':
      return Podcast;
    case 'cash':
      return Cash;
    case 'download':
      return Download;
    case 'risk':
      return Risk;
    case 'bank':
      return Asset;
    case 'structure':
      return Structure;
    case 'note':
      return Note;
    default:
      return Note;
  }
};

export const IconPicker = ({ iconName }: IconPickerProps) => (
  <IconContainer className="iconImage">
    <IconImage src={iconSelection(iconName.toLowerCase())} alt="yieldstreet icon"></IconImage>
  </IconContainer>
);

const IconContainer = styled.div``;

const IconImage = styled.img`
  max-width: 20px;
  max-height: 20px;
  height: 20px;
  width: 20px;
`;
