import { ThemeColor } from './palette.model';

type neutrals = 'white' | 'grey' | 'black';

export const NEUTRALS: Record<neutrals, ThemeColor> = {
  white: {
    '50': '#ffffff',
    '100': '#fefefe',
    '200': '#fdfdfd',
    '300': '#fcfcfc',
    '400': '',
    '500': '',
    '600': '',
    '700': '',
    '800': '',
    '900': '',
  },
  grey: {
    '50': '#f5f5f5',
    '100': '#dfdfdf',
    '200': '#d0d0d0',
    '300': '#bbbbbb',
    '400': '#adadad',
    '500': '#999999',
    '600': '#8b8b8b',
    '700': '#6d6d6d',
    '800': '#545454',
    '900': '#404040',
  },
  black: {
    '50': '',
    '100': '',
    '200': '',
    '300': '',
    '400': '',
    '500': '#080808',
    '600': '#070707',
    '700': '#060606',
    '800': '#040404',
    '900': '#030303',
  },
};
