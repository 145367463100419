import styled, { css } from 'styled-components';

import { SectionContainer } from '../../layout';
import { theme2 } from '../../themes';
import { media } from '../../themes/media';

import { MarketingHeroBannerProps, BackgroundType } from './MarketingHeroBanner.model';

const getBgType = (background?: BackgroundType) => {
  switch (background) {
    case 'primary':
      return theme2.gradients.radial_primary;
    case 'secondary':
      return theme2.gradients.radial_secondary;
    default:
      return 'transparent';
  }
};

export const Background = styled.div<Pick<MarketingHeroBannerProps, 'inverse' | 'background'>>`
  background: ${props =>
    props.inverse ? props.theme.colors.background_light : getBgType(props.background)};
`;

export const SContainer = styled(SectionContainer)<
  Pick<MarketingHeroBannerProps, 'reverse' | 'image' | 'mediaFramed'>
>`
  ${props => !props.mediaFramed && 'max-width: 1440px;'}

  display: flex;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};

  ${media.desktop<Pick<MarketingHeroBannerProps, 'image'>>`
    flex-direction: row;
     ${props => (props.image ? 'justify-content: space-between;' : 'justify-content: flex-start;')};
    align-items: center;
  `};
`;

export const TextBlockContainer = styled.div<Pick<MarketingHeroBannerProps, 'largeWidth'>>`
  width: 100%;
  margin: 24px 0;

  ${media.desktop<Pick<MarketingHeroBannerProps, 'largeWidth'>>`
    width: ${props => (props.largeWidth ? '635px;' : '464px;')}
    margin: ${theme2.spacing['2xl']} 0;
  `}
`;

export const ImageContainer = styled.div<
  Pick<MarketingHeroBannerProps, 'hideMediaOnMobile' | 'mediaFramed'>
>`
  display: flex;
  video {
    display: flex;
    width: 100%;
  }
  & > img {
    display: flex;
    width: 100%;
  }
  ${props => (props.hideMediaOnMobile ? 'display:none;' : '')}

  ${media.desktop`
    margin: 0;
    display: flex;
  `}


  ${({ mediaFramed }) =>
    mediaFramed &&
    css`
      position: relative;
      max-width: 420px;
      aspect-ratio: 16/9;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin: ${theme2.spacing.m} -${theme2.spacing.m} ${theme2.spacing.l} auto;
      display: flex;

      &::after,
      &::before {
        content: '';
        position: absolute;
        height: 24px;
        width: calc(100% - ${theme2.spacing.m});
        background-color: ${theme2.colors.background_hero_shadow};
        right: 0;
        opacity: 0.16;
      }

      &::before {
        top: -${theme2.spacing.m};
        border-top-left-radius: 4px;
      }

      &::after {
        bottom: -${theme2.spacing.m};
        border-bottom-left-radius: 4px;
      }

      ${media.tablet`
        max-width: 500px;
      `}

      ${media.desktop`
        width: 50%;
        max-width: 690px;
        position: absolute;
        right: 0;
        margin-right: 0;
      `}
        
      ${media.larger`
        width: 60%;
        max-width: 730px;
      `}
        
      ${media.fullscreen`
        position: sticky;
        width: 60%;
        margin-right: -${theme2.spacing['2xl']};
      `}
    `}
`;
