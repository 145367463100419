import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ExternalLink } from '../../content/link';
import { Heading, Paragraph } from '../../content/text';

export interface CardRowSingleProps {
  title: string;
  subTitle: string | string[];
  linkText: string;
  testId?: string;
  onClick: () => void;
}

export interface CardRowProps {
  options: CardRowSingleProps[];
}

export const CardRow: FunctionComponent<CardRowProps> = ({ options, ...otherProps }) => (
  <>
    {options?.map((option, idx) => (
      <CardRowWrapper {...otherProps} key={idx}>
        <CardRowHeader>
          <Heading type={5}>{option.title}</Heading>
          {typeof option.subTitle === 'string' ? (
            <Paragraph>{option.subTitle}</Paragraph>
          ) : (
            <>
              {option.subTitle.map((subTitle, idx) => (
                <Paragraph key={idx}>{subTitle}</Paragraph>
              ))}
            </>
          )}
        </CardRowHeader>
        <ExternalLink data-cy={option?.testId || 'card-row-link'} onClick={option.onClick}>
          {option.linkText}
        </ExternalLink>
      </CardRowWrapper>
    ))}
  </>
);

const CardRowWrapper = styled.div`
  background: ${props => props.theme.colors.background_default};
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0;
`;

const CardRowHeader = styled.div`
  flex: 1;
  overflow: hidden;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
`;
