import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { BaseInputProps } from '@yieldstreet/tool-kit';
import {
  withFormikWeb,
  withQuestion,
  SFormLabel,
  SFormGroup,
  SFormControl,
  SFormHelperText,
  FormError,
  SFormGroupProps,
} from '../common';
import { RadioInput } from '../radio-input';

export interface RadioGroupInputOption {
  value: string;
  label: string;
  description?: string | ReactElement;
  disabled?: boolean;
  image?: string | ReactNode;
  caption?: string;
  dataCy?: string;
}

export interface RadioGroupInputProps extends BaseInputProps<string>, SFormGroupProps {
  onChange?: (value: string, option: RadioGroupInputOption) => void;
  options: RadioGroupInputOption[];
  descriptionCheckedOnly?: boolean;
}

export const RadioGroupInput: FunctionComponent<RadioGroupInputProps> = props => {
  const {
    label,
    value,
    error,
    options,
    onChange,
    onClick,
    className,
    caption,
    disabled,
    descriptionCheckedOnly,
    justify,
    direction,
    testId,
    ...otherProps
  } = props;

  const handleChange = (option: RadioGroupInputOption) => (checked: boolean) => {
    onChange && checked && onChange(option.value, option);
  };

  return (
    <SFormControl data-radio-group error={!!error} className={className} disabled={disabled}>
      {label && <SFormLabel data-radio-group-label>{label}</SFormLabel>}
      <SFormGroup data-radio-group-options justify={justify} direction={direction} data-cy={testId}>
        {options.map(option => (
          <RadioInput
            {...otherProps}
            key={option.value}
            testId={`${testId}-option-${option.value}`}
            value={option.value === value}
            label={option.label}
            name={option.label}
            caption={option.caption}
            disabled={option.disabled || disabled}
            onChange={handleChange(option)}
            onClick={e => onClick && onClick(e, option)}
            description={option.description}
            descriptionCheckedOnly={descriptionCheckedOnly}
            image={option?.image}
            className="radio-input"
          />
        ))}
      </SFormGroup>
      {error || caption ? (
        <SFormHelperText margin="dense">
          {error ? (
            <FormError
              testId="radio-group-input-error"
              type="radio-group-input"
              name={props?.name || 'radio-group'}
              error={error}
            />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </SFormControl>
  );
};

export const FormRadioGroupInput = withFormikWeb(RadioGroupInput);

export const QuestionRadioGroupInput = withQuestion(RadioGroupInput, 'primary');
export const QuestionSecondaryRadioGroupInput = withQuestion(RadioGroupInput, 'secondary');
export const QuestionTertiaryRadioGroupInput = withQuestion(RadioGroupInput, 'tertiary');
export const FormikQuestionSecondaryRadioGroupInput = withFormikWeb(
  QuestionSecondaryRadioGroupInput
);

export const FormQuestionRadioGroupInput = withFormikWeb(QuestionRadioGroupInput);
