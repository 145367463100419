export const TabListTestIds = {
  container: 'tab-list-container',
};

export interface TabListProps {
  children?: React.ReactNode[];
  activeIndex?: number;
  onChange?: (event: React.SyntheticEvent, index: number) => void;
  testId?: string;
  vertical?: boolean;
}
