import { FundType, TenderOfferType, OfferingDocuments } from '@yieldstreet/platform-kit';

export enum TenderOfferPages {
  Splash = 'splash',
  Input = 'input',
  Confirmation = 'confirmation',
}

type TenderOfferPageSplashSectionType = {
  title: string;
  paragraph: string;
};

export type TenderOfferPageShape<T> = {
  default: T;
} & {
  [key in FundType]?: T;
} & {
  [TenderOfferType.ENHANCED_CRYPTO]: T;
};

export type TenderOfferPageSplashCopyShape = {
  title: string;
  subTitle: string;
  documentSectionText: string;
  sections: TenderOfferPageSplashSectionType[];
};

const TenderOfferPageSplashCopy: TenderOfferPageShape<TenderOfferPageSplashCopyShape> = {
  default: {
    title: 'What to know before you request to have your investment repurchased',
    subTitle:
      'Yieldtreet is conducting an offer to repurchase investments pursuant to a repurchase offer program to provide limited liquidity to our investors. If you elect to participate in the offer, you can submit your repurchase request electronically through the Yieldstreet platform. Please note that investors are not obligated to submit a repurchase request. Should you wish to maintain your current holding, no further action is required.',
    documentSectionText: `We highly recommend that you review the following documents to fully understand the terms and conditions of the tender offer process.`,
    sections: [
      {
        title: 'Repurchases are subject to taxation',
        paragraph:
          'Any repurchases of investments will be reported to the Internal Revenue Service and may be taxable. Neither we, nor YieldStreet Management, LLC, nor any of our affiliates provide tax advice; therefore, we highly recommend that you consult your financial representative or tax advisor for more information beforehand.',
      },
    ],
  },
  [FundType.RegAPlus]: {
    title: 'What to know before you request to have your shares redeemed',
    subTitle:
      'The Growth & Income REIT (“we,” “us” or the “Fund”) is conducting an offer pursuant to a redemption plan to provide limited liquidity to our investors. If you elect to redeem shares in the offer, you can submit your liquidation request electronically through the Yieldstreet platform. Please note that investors are not obligated to submit a redemption request. Should you wish to maintain your current holding, no further action is required.',
    documentSectionText: `We highly recommend that you review the following documents to fully understand the terms and conditions of the redemption process.`,
    sections: [
      {
        title: 'Limited liquidity options',
        paragraph:
          'To provide our stockholders with limited liquidity, we intend to, but are not obligated to, conduct quarterly redemption offers pursuant to the adopted redemption program. We may suspend or terminate the redemption program at any time. This share redemption program should not be relied upon as a method to liquidate your investment promptly or at a desired price.',
      },
      {
        title: 'Holding period requirements and early redemption penalty fees',
        paragraph:
          'Only shares held for at least one year since they were acquired are eligible for redemption. Additionally, early redemption of shares may be subject to penalty fees, as per the limitations set forth in the Redemption Offer.',
      },
      {
        title: 'Redemption requests are subject to review and are not guaranteed',
        paragraph:
          'We will limit the number of shares that we offer to redeem. As such, there is no guarantee that your liquidation request will be approved. Additionally, if the number of shares submitted for redemption by investors exceeds the number of aggregated shares limited by the plan, shares will be processed on a pro-rata basis, and not on a first-come, first-served basis.',
      },
      {
        title: 'Redemptions are subject to taxation',
        paragraph:
          'Any redemption of shares will be reported to the Internal Revenue Service and may be taxable. Neither we, nor YieldStreet Management, LLC, nor any of our affiliates provide tax advice; therefore, we highly recommend that you consult your financial representative or tax advisor for more information beforehand.',
      },
    ],
  },
  [FundType.FortyActFund]: {
    title: 'What to know before you request to have your shares repurchased',
    subTitle: `YieldStreet Prism Fund Inc. (the “Fund”) is conducting an offer to repurchase shares pursuant to a share repurchase program to provide limited liquidity to its investors. If you elect to sell shares in the offer, you can submit your repurchase request electronically through the Yieldstreet platform. Please note that investors are not obligated to submit a repurchase request. Should you wish to maintain your current holding, no further action is required.`,
    documentSectionText: `We highly recommend that you review the following documents to fully understand the terms and conditions of the tender offer process.`,
    sections: [
      {
        title: 'Limited liquidity options',
        paragraph: `To provide its stockholders with limited liquidity, the Fund intends to, but is not obligated to, conduct quarterly share repurchase offers pursuant to its share repurchase program. The Fund may suspend or terminate the share repurchase program at any time, subject to the discretion of its Board of Directors. This share repurchase program should not be relied upon as a method to sell shares promptly or at a desired price.`,
      },
      {
        title: 'Repurchase requests are subject to review and are not guaranteed',
        paragraph: `The Fund will limit the number of shares that it offers to repurchase. As such, there is no guarantee that your repurchase request will be approved. Additionally, if the number of shares submitted for repurchase by investors exceeds the number of shares the Fund seeks to repurchase, shares will be repurchased on a pro-rata basis, and not on a first-come, first-served basis.`,
      },
      {
        title: 'Purchase Price',
        paragraph: `The net asset value of any shares tendered in this offer will likely change between the most recent time net asset value was calculated and communicated to you and the net asset value of the shares as of the expiration date of this tender (the relevant date for determining the value of the shares tendered for purposes of calculating the purchase price of tendered shares) and such change could be material. The most recently calculated net asset value per share can be found in your portfolio.`,
      },
      {
        title: 'Repurchases are subject to taxation',
        paragraph: `Any repurchases of shares will be reported to the Internal Revenue Service and may be taxable. Neither the Fund, nor YieldStreet Management, LLC, nor any of their affiliates provide tax advice; therefore, we highly recommend that you consult your financial representative or tax advisor for more information beforehand.`,
      },
    ],
  },
  [TenderOfferType.ENHANCED_CRYPTO]: {
    title: 'What to know before you request to have your shares liquidated',
    subTitle:
      'The Enhanced Crypto Fund (“we,” “us” or the “Fund”) is facilitating the voluntary liquidation of investments to provide limited liquidity to our investors. If you elect to liquidate shares in the offer, you can submit your liquidation request electronically through the Yieldstreet platform. Please note that investors are not obligated to submit a liquidation request. Should you wish to maintain your current holding, no further action is required.',
    documentSectionText: `We highly recommend that you review the following documents to fully understand the terms and conditions of the voluntary withdrawal process.`,
    sections: [
      {
        title: 'Limited liquidity options',
        paragraph:
          'To provide our stockholders with limited liquidity we will conduct quarterly liquidity offers pursuant to the terms of the operating agreement. This share liquidity program should not be relied upon as a method to liquidate your investment promptly or at a desired price.',
      },
      {
        title: 'Holding period requirements',
        paragraph:
          'Only investors who made their initial investment request at least one year prior to the start of the liquidation offer period are eligible for liquidation.',
      },
      {
        title: 'Liquidation requests are subject to review and are not guaranteed',
        paragraph:
          'If investors request more than 95% of their capital account balance, they will be subject to a hold back of 5% of the requested amount until audits are complete. If investors request partial liquidation, their investment balance, after giving effect to the liquidation, cannot be less than $10k. See illustrative example below, and please refer to the Private Placement Memorandum and Operating Agreement for further detail.',
      },
      {
        title: 'Liquidations are subject to taxation',
        paragraph:
          'Any liquidation of shares will be reported to the Internal Revenue Service and may be taxable. Neither we, nor YieldStreet Management, LLC, nor any of our affiliates provide tax advice; therefore, we highly recommend that you consult your financial representative or tax advisor for more information beforehand.',
      },
    ],
  },
};

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

type TenderOfferCheckbox = {
  name: string;
  label: string;
  className?: string;
  values?: PartialRecord<`${OfferingDocuments}`, string>;
};

export type TenderOfferPageInputCopyShape = {
  title: string;
  formInputNote: string;
  formSellAll: string;
  submitCtaText: string;
  formTitle: string;
  investmentBalanceDisclaimer?: string;
  checkboxes?: TenderOfferCheckbox[];
  signatureDisclaimer: string;
};

const TenderOfferPageInputCopy: TenderOfferPageShape<TenderOfferPageInputCopyShape> = {
  default: {
    title: 'Request to have investment repurchased',
    investmentBalanceDisclaimer:
      '* Total investment balance is based on the outstanding principal balance.',
    formTitle: 'Enter you desired repurchase amount',
    formInputNote: '',
    formSellAll: `I’d like to sell my entire position`,
    submitCtaText: 'Submit',
    signatureDisclaimer:
      'By my signature below, I hereby execute the above agreements, certify that I have read and agree to the {{terms}} and {{privacy}} and that all foregoing information is accurate and truthful.',
    checkboxes: [
      {
        name: 'check1',
        label: `I have read and understood the {{${OfferingDocuments.OFTP}}}.`,
        className: 'check1',
        values: {
          [OfferingDocuments.OFTP]: 'Offer to Purchase',
        },
      },
      {
        name: 'check2',
        label:
          'I certify, under penalty of perjury, that (i) the social security number or taxpayer identification number previously setup on the Yieldstreet platform is correct (ii) that I am not subject to backup withholding, and (iii) I am a U.S. citizen or other U.S. person (including a U.S. resident alien).',
        className: 'check2',
      },
    ],
  },
  [FundType.RegAPlus]: {
    title: 'Request to have shares redeemed',
    investmentBalanceDisclaimer:
      '* Total investment balance shown is based on the NAV as of the date immediately prior to the commencement of this offer, which was {{amount}} per share, and may differ from the current balance of your active holdings visible in your Portfolio.',
    formTitle: 'Enter you desired repurchase amount',
    formInputNote: `Please note that your entered amount may be prorated due to a 5% max liquidation cap, as per the terms of the redemption plan, as well as any early redemption penalty fees based on the holding period of your shares.`,
    formSellAll: `I’d like to sell my entire position`,
    submitCtaText: 'Next',
    signatureDisclaimer:
      'By my signature below, I hereby execute the above agreements, certify that I have read and agree to the {{terms}} and {{privacy}} and that all foregoing information is accurate and truthful.',
    checkboxes: [
      {
        name: 'check1',
        label: `I understand that (1) the amount of my shares that are redeemed may be lower than my requested amount if the aggregate amount of redemption requests exceeds the number of shares the Fund plans to redeem, and (2) early redemption of my shares may be subject to penalty fees, as per the limitations set forth in the Redemption Offer by YS RE RAF I LLC.`,
        className: 'check1',
      },
      {
        name: 'check2',
        label: `I have read and understood the terms of the redemption as set forth in the offering circular, the {{${OfferingDocuments.OPER}}} and the {{${OfferingDocuments.TFAQ}}}.`,
        className: 'check2',
        values: {
          [OfferingDocuments.OPER]: 'operating agreement',
          [OfferingDocuments.TFAQ]: 'FAQ document',
        },
      },
      {
        name: 'check3',
        label:
          'I certify, under penalty of perjury, that (i) the social security number or taxpayer identification number previously setup on the Yieldstreet platform is correct (ii) that I am not subject to backup withholding, and (iii) I am a U.S. citizen or other U.S. person (including a U.S. resident alien).',
        className: 'check3',
      },
    ],
  },
  [FundType.FortyActFund]: {
    title: 'Request to have shares repurchased',
    formTitle: 'Enter you desired repurchase amount',
    investmentBalanceDisclaimer:
      '* Total investment balance shown is based on the NAV as of the date immediately prior to the commencement of this offer, which was {{amount}} per share, and may differ from the current balance of your active holdings visible in your Portfolio.',
    formInputNote: '',
    formSellAll: `I’d like to sell my entire position`,
    submitCtaText: 'Submit',
    signatureDisclaimer:
      'By my signature below, I hereby execute the above agreements, certify that I have read and agree to the {{terms}} and {{privacy}} and that all foregoing information is accurate and truthful.',
    checkboxes: [
      {
        name: 'check1',
        label: `I understand that repurchases will be made solely based on the number of shares requested to be repurchased and that the amount of my shares that are repurchased may be lower than my requested amount if the aggregate amount of repurchase requests exceeds the number of shares YieldStreet Prism Fund Inc. plans to repurchase, as per the limitations set forth in the {{${OfferingDocuments.OFTP}}} by Yieldstreet Prism Fund Inc.`,
        className: 'check1',
        values: {
          [OfferingDocuments.OFTP]: 'Offer to Purchase',
        },
      },
      {
        name: 'check2',
        label:
          'I recognize that the net asset value of the shares tendered in this offer will likely change between the most recent time net asset value was calculated and communicated to me and the net asset value of the shares as of the expiration date of this offer (the relevant date for determining the value of the shares tendered for purposes of calculating the purchase price of tendered shares) and such change could be material. I agree that repurchases will be made solely based on the number of shares requested to be repurchased.',
        className: 'check2',
      },
      {
        name: 'check3',
        label: `I have read and understood the {{${OfferingDocuments.OFTP}}} and {{${OfferingDocuments.LOTL}}}.`,
        className: 'check3',
        values: {
          [OfferingDocuments.OFTP]: 'Offer to Purchase',
          [OfferingDocuments.LOTL]: 'Letter of Transmittal',
        },
      },
      {
        name: 'check4',
        label:
          'I certify, under penalty of perjury, that (i) the social security number or taxpayer identification number previously setup on the Yieldstreet platform is correct (ii) that I am not subject to backup withholding, and (iii) I am a U.S. citizen or other U.S. person (including a U.S. resident alien).',
        className: 'check4',
      },
    ],
  },
  [TenderOfferType.ENHANCED_CRYPTO]: {
    title: 'Request to have shares liquidated',
    investmentBalanceDisclaimer:
      '* Total investment balance is based on the Purchase Price of {{amount}} per share, and may differ from the current balance of your active holdings visible in your Portfolio.',
    formTitle: 'Enter you desired liquidation amount',
    formInputNote: `There is no guarantee that your entered amount will be liquidated, as per the terms of the liquidation plan.`,
    formSellAll: `I’d like to sell my entire position`,
    submitCtaText: 'Submit',
    signatureDisclaimer:
      'By my signature below, I hereby execute the above agreements, certify that I have read and agree to the {{terms}} and {{privacy}} and that all foregoing information is accurate and truthful.',
    checkboxes: [
      {
        name: 'check1',
        label:
          'I understand that the amount of my shares that are liquidated may be lower than my requested amount, per the terms of the liquidation plan.',
        className: 'check1',
      },
      {
        name: 'check2',
        label: `I have read and understood the terms of the liquidation as set forth in the {{${OfferingDocuments.PRPM}}}, the {{${OfferingDocuments.OPER}}}, and the {{${OfferingDocuments.TFAQ}}}.`,
        className: 'check2',
        values: {
          [OfferingDocuments.PRPM]: 'Private Placement Memorandum',
          [OfferingDocuments.OPER]: 'Operating Agreement',
          [OfferingDocuments.TFAQ]: 'FAQ document',
        },
      },
      {
        name: 'check3',
        label:
          'I certify, under penalty of perjury, that (i) the social security number or taxpayer identification number previously setup on the Yieldstreet platform is correct (ii) that I am not subject to backup withholding, and (iii) I am a U.S. citizen or other U.S. person (including a U.S. resident alien).',
        className: 'check3',
      },
    ],
  },
};

export type TenderOfferPageConfirmationCopyShape = {
  title: string;
  tenderCopy: string;
  tenderCopyAcceptance: string;
  tenderCopyAcceptanceAdditional?: string;
  executionPaymentTitle: string;
  executionPaymentParagraphAdditional: string;
  executionPaymentParagraph: string;
  email: string;
  viewInvestment: string;
};

const TenderOfferPageConfirmationCopy: TenderOfferPageShape<TenderOfferPageConfirmationCopyShape> =
  {
    default: {
      title: 'Your repurchase request has been received',
      tenderCopy: 'Open Repurchase offer period',
      tenderCopyAcceptance:
        'We will continue to accept repurchase requests from {{openDtFrom}} to {{openDtTo}}. Your request will be processed only after the offer period has closed.',
      executionPaymentTitle: 'Repurchase Offer Execution and Payment',
      executionPaymentParagraphAdditional:
        'If you have any questions about this process, please refer to the {{faq}}. If you elect to cancel your liquidation request at any time prior to the expiration of the offer, you may do so by sending an email notice to {{email}}.',
      executionPaymentParagraph:
        'Given that we are limited in the amount that can be repurchased during each Repurchase offer period, your final amount repurchased may be less than your requested amount. Payment will be made to your Yieldstreet Wallet promptly after the close of the Repurchase offer period. We will send you an email with the executed amount repurchased following the close of the Repurchase offer period.',
      email: 'investments@yieldstreet.com',
      viewInvestment: 'View investment details',
    },
    [FundType.RegAPlus]: {
      title: 'Your redemption request has been received',
      tenderCopy: 'Open Redemption offer period',
      tenderCopyAcceptance:
        'We will continue to accept redemption requests from {{openDtFrom}} to {{openDtTo}}. Your request will be processed only after the offer period has closed.',
      executionPaymentTitle: 'Redemption Offer Execution and Payment',
      executionPaymentParagraphAdditional:
        'If you have any questions about this process, please refer to the {{faq}}. If you elect to cancel your liquidation request at any time prior to the expiration of the offer, you may do so by sending an email notice to {{email}}.',
      executionPaymentParagraph:
        'Given that we are limited in the amount that can be redeemed during each offer period, your final amount redeemed may be less than your requested amount. Payment will be made to your Yieldstreet Wallet between the close of the redemption offer period and the end of the respective quarter. We will send you an email with the executed price and total value redeemed following the close of the redemption offer period.',
      email: 'investments@yieldstreetprismfund.com',
      viewInvestment: 'View investment details',
    },
    [FundType.FortyActFund]: {
      title: 'Your repurchase request has been received',
      tenderCopy: 'Open Tender offer period',
      tenderCopyAcceptance:
        'We will continue to accept tender requests from {{openDtFrom}} to {{openDtTo}}. Your request will be processed only after the offer period has closed.',
      tenderCopyAcceptanceAdditional:
        'The net asset value of the shares tendered in this offer will likely change between the most recent time net asset value was calculated and communicated to you and the net asset value of the shares as of the expiration date of this tender (the relevant date for determining the value of the shares tendered for purposes of calculating the purchase price of tendered shares) and such change could be material. The most recently calculated net asset value per Share can be found in your portfolio.',
      executionPaymentTitle: 'Tender Offer Execution and Payment',
      executionPaymentParagraphAdditional:
        'If you have any questions about this process, please refer to the {{faq}}. If you elect to cancel your liquidation request at any time prior to the expiration of the offer, you may do so by sending an email notice to {{email}}.',
      executionPaymentParagraph: `Given that we are limited in the amount that can be repurchased during each tender offer period, your final amount repurchased may be less than your requested amount. Payment will be made to your Yieldstreet Wallet promptly after the close of the tender offer period. We will send you an email with the executed price and total number of shares  repurchased following the close of the tender offer period.`,
      email: 'investments@yieldstreetprismfund.com',
      viewInvestment: 'View investment details',
    },
    [TenderOfferType.ENHANCED_CRYPTO]: {
      title: 'Your liquidation request has been received',
      tenderCopy: 'Open Liquidation period',
      tenderCopyAcceptance:
        'We will continue to accept liquidation requests from {{openDtFrom}} to {{openDtTo}}. Your request will be processed only after the offer period has closed.',
      executionPaymentTitle: 'Liquidation Execution and Payment',
      executionPaymentParagraphAdditional:
        'If you have any questions about this process, please refer to the {{faq}}. If you elect to cancel your liquidation request at any time prior to the expiration of the offer, you may do so by sending an email notice to {{email}}.',
      executionPaymentParagraph:
        'Your final amount liquidated may be less than your requested amount. All valid liquidation requests are expected to be executed within 30 business days after the end of the current quarter. We will send you an email with the executed price and total value withdrawn following the close of the liquidation period.',
      email: 'investments@yieldstreet.com',
      viewInvestment: 'View investment details',
    },
  };

export const TenderOfferPagesCopy: {
  [TenderOfferPages.Splash]: TenderOfferPageShape<TenderOfferPageSplashCopyShape>;
  [TenderOfferPages.Input]: TenderOfferPageShape<TenderOfferPageInputCopyShape>;
  [TenderOfferPages.Confirmation]: TenderOfferPageShape<TenderOfferPageConfirmationCopyShape>;
} = {
  [TenderOfferPages.Splash]: TenderOfferPageSplashCopy,
  [TenderOfferPages.Input]: TenderOfferPageInputCopy,
  [TenderOfferPages.Confirmation]: TenderOfferPageConfirmationCopy,
};
