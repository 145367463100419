import styled from 'styled-components';
import { media, MarketingContainer } from '@yieldstreet/ui-kit';

export const FundPerformanceSection = styled.section`
  margin: ${({ theme }) => theme.spacing['2xl']} 0;

  ${media.desktop`
    margin: ${({ theme }) => theme.spacing['4xl']} 0;
  `}
`;

export const BannerWrapper = styled.div`
  overflow: auto;
  text-align: center;
`;

export const PerformanceBanner = styled.img`
  width: 250%;
  overflow: scroll;
  margin: ${({ theme }) => theme.spacing.m} auto 0 auto;
  display: block;

  ${media.large`
    margin-top: ${({ theme }) => theme.spacing.l};
    max-width: 100%;
  `}
`;

export const GrowthBanner = styled.img`
  margin-top: ${({ theme }) => theme.spacing.l};
  max-width: 100%;
`;

export const LegalTextWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.m};
  ${media.desktop`
    margin-top: ${({ theme }) => theme.spacing['3xl']};
    text-align: center;
  `}
`;

export const ModalWrapper = styled(MarketingContainer)`
  background-color: ${({ theme }) => theme.colors.background_page};
  box-shadow: ${({ theme }) => theme.shadows.elevation_1};
  padding: ${({ theme }) => theme.spacing.l};
  max-width: 700px;
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const TabVisible = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.l};
`;
