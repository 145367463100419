import React, { FunctionComponent } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material';
import { TextInput, TextInputProps } from './TextInput';
import { withFormikWeb } from '../common';

export const CurrencyFormatInput: FunctionComponent<InputBaseComponentProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, defaultValue, ...otherProps } = props;

  return (
    <NumericFormat
      {...otherProps}
      onValueChange={({ value }) => {
        onChange &&
          onChange({
            target: { value },
          } as unknown as React.FormEvent<HTMLInputElement>);
      }}
      thousandSeparator
      valueIsNumericString
      autoComplete="off"
    />
  );
};

export const CurrencyTextInput: React.FunctionComponent<
  NumericFormatProps & TextInputProps
> = props => {
  return <TextInput prefix="$" {...props} inputComponent={CurrencyFormatInput} />;
};

export const FormCurrencyTextInput = withFormikWeb(CurrencyTextInput);
