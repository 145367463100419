import styled from 'styled-components';

export const PageSheetWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background_card};
  overflow: hidden;
  z-index: 999;
`;

export const PageSheetHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme }) => theme.spacing['2xl']};
  padding: 0 ${({ theme }) => theme.spacing.m};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border_default};
  position: relative;
`;

export const PageSheetContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;
