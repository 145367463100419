import { HttpMethods } from '../../enums';
import { CustomSerializeQueryExtraOptions, restApi, RTKQAll, Tags } from '../common';

import {
  UserStateAPIResponse,
  GetUserWalletAPIResponse,
  GetUserContactInfoAPIResponse,
  GetUserIraAccountsAPIResponse,
} from '../../types';
import {
  UserAccreditationResponse,
  UserAccreditationPayload,
  SaveUserContactApiResponse,
  SaveUserContactApiRequest,
} from './user.model';

export const userApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserState, Tags.UserContact],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getUserState = builder.query<UserStateAPIResponse, RTKQAll>({
        extraOptions: (_props: string[]) => {
          const options: CustomSerializeQueryExtraOptions<string> = {
            cacheKey: '',
          };
          return options;
        },
        query: () => ({
          url: `/a/api/user/state`,
          method: HttpMethods.Get,
        }),
        keepUnusedDataFor: Infinity,
        providesTags: [Tags.UserState],
      });

      const submitAccreditationStatus = builder.mutation<
        UserAccreditationResponse,
        UserAccreditationPayload
      >({
        query: data => ({
          url: `/a/api/user/accreditation-status`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.UserState],
      });

      const getUserWallets = builder.query<GetUserWalletAPIResponse, { userId?: number }>({
        query: ({ userId }) => ({
          url: `/a/api/user/${userId}/wallets`,
          method: HttpMethods.Get,
        }),
      });

      const getUserIraSourceAccount = builder.query<
        GetUserIraAccountsAPIResponse,
        { userId: number }
      >({
        query: ({ userId }) => ({
          url: `/a/api/user/${userId}/ira-source-account`,
          method: HttpMethods.Get,
        }),
      });

      const getUserContactInfo = builder.query<GetUserContactInfoAPIResponse, RTKQAll>({
        query: () => ({
          url: `/a/api/user/contact/get`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.UserContact],
      });

      const saveUserContact = builder.mutation<
        SaveUserContactApiResponse,
        SaveUserContactApiRequest
      >({
        query: ({ ...data }) => {
          return {
            url: '/a/api/user/contact/save',
            method: HttpMethods.Post,
            data,
          };
        },
        invalidatesTags: [Tags.UserContact],
      });

      return {
        getUserState,
        getUserWallets,
        getUserContactInfo,
        getUserIraSourceAccount,
        saveUserContact,
        submitAccreditationStatus,
      };
    },
  });
