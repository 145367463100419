import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const OuterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  borderRadius: '4px',
  background: theme.palette.background.surface,
}));

export const Label = styled(Typography)<{ semiBold?: boolean }>(({ semiBold, theme }) => ({
  fontWeight: semiBold ? 500 : 400,
  wordBreak: 'normal',

  '&:not(:first-child)': {
    marginLeft: theme.spacing(3),
  },
}));

export const TabButton = styled('div')<{ active?: boolean }>(({ active, theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: '4px',
  textAlign: 'center',
  cursor: 'pointer',

  ...(active && {
    boxShadow: '0px 2px 5px -1px rgba(50, 50, 93, 0.2)',
    background: theme.palette.background.default,
  }),
}));
