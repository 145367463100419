import { InvestorLevelGateCode, UserLevelGateCode } from '@yieldstreet/platform-kit';

export type GateCopiesType = Record<string, GateCopy>;

export type GateCopy = {
  title: string;
  description: string;
};

export type GateObject = {
  gateCopy: GateCopy;
  gateAttributes: any;
  gateCode: InvestorLevelGateCode | UserLevelGateCode;
};

const GATE_COPIES: GateCopiesType = {
  [UserLevelGateCode.NEVER]: {
    title: 'Requires accreditation',
    description:
      'This offer is currently limited to accredited investors. If you qualify for {{accreditationStatusLink}}, you can {{contactSupport}} to change your status.',
  },
  [UserLevelGateCode.NO_INVESTOR_ACCOUNT]: {
    title: 'Finish account setup',
    description:
      'Before you can invest, you need to create an investor account. Create an {{createInvestorAccount}}.',
  },
  [UserLevelGateCode.ACCOUNT_GATE]: {
    title: 'Finish account setup',
    description:
      'Your investor account is not ready to invest. Before you can invest in an offering, you need to verify your identity. {{verifyIdentity}}.',
  },
  [InvestorLevelGateCode.ACCOUNT_TYPE]: {
    title: 'Account ineligibility',
    description:
      'Your investor account(s) are not eligible for this offering due to account type restrictions.',
  },
  [InvestorLevelGateCode.MISSING_BANK_ACCOUNTS]: {
    title: 'Finish account setup',
    description:
      'Your investor account is not ready to invest. Before you can invest in an offering, you need to link an external bank. Link a {{addBankAccount}}.',
  },
  [InvestorLevelGateCode.PENDING_MICRO_DEPOSITS]: {
    title: 'Finish account setup',
    description:
      'Your investor account is not ready to invest. Before you can invest in an offering, you need to complete your external bank account set-up. Verify {{verifyMicroDeposit}}.',
  },
  [InvestorLevelGateCode.PENDING_BANK_ACCOUNT_VERIFICATION]: {
    title: 'Finish account setup',
    description:
      'Your bank account is still being verified by the provider, please try again later.',
  },
  [InvestorLevelGateCode.OFFERING_STATUS]: {
    title: 'Offering unavailable',
    description: 'This offering is currently unavailable. Please {{contactIR}} with any questions.',
  },
  [UserLevelGateCode.SELF_QC]: {
    title: 'Open to qualified clients only',
    description:
      'This deal is open to qualified clients. If you are a qualified client, you can adjust this in {{investmentPreferencesLink}}.',
  },
  [UserLevelGateCode.SELF_QP]: {
    title: 'Open to qualified purchasers only',
    description:
      'This deal is open to qualified purchasers. If you are a qualified purchaser, you can adjust this in {{investmentPreferencesLink}}.',
  },
  [InvestorLevelGateCode.STATE_INELIGIBLE]: {
    title: 'Account ineligibility',
    description:
      'Unfortunately, investors from {{usStateName}} are not eligible to invest in this offering. Please {{contactIR}} with any questions.',
  },
  [InvestorLevelGateCode.INVESTOR_ENTITY_PROVIDER_ACTION]: {
    title: 'Finish Equity Trust set-up',
    description:
      'Your account set-up at Equity Trust isn’t complete. Please contact Equity Trust if you need any assistance.',
  },
  default: {
    title: 'Reserved',
    description:
      'This offer is currently reserved but may open up to additional segments in the future.',
  },
};

export const getGateCopy = (gateCode: string) => {
  return GATE_COPIES[gateCode] || GATE_COPIES.default;
};
