import {
  AssetClassType,
  AssetTypes,
  MarketplaceFilterQuerySortKey,
  MarketplaceFilterQuerySortDirection,
  MarketplaceFilterQueryParameters,
  DropDownType,
} from '@yieldstreet/platform-kit';

import { FilterAssetClassOptions, FilterType, FilterTypeEnum } from '../filters';

export type MarketplaceFilterAssetTypeOption = {
  id: AssetTypes;
  label: string;
  description: string;
};

export const MarketplaceFilterAssetTypeOptions: MarketplaceFilterAssetTypeOption[] = [
  {
    id: AssetTypes.Equity,
    label: 'Equity',
    description:
      'Returns are primarily from price appreciation or capital gains on the underlying asset. Relatively higher return and risk',
  },
  {
    id: AssetTypes.Credit,
    label: 'Credit',
    description:
      'Returns are primarily from income generated through a contractual stream of cashflows. Relatively lower return and risk',
  },
  {
    id: AssetTypes.Other,
    label: 'Other',
    description:
      'Returns are dictated by elements that contain both contractual income and price appreciation/capital gains.',
  },
];

export type MarketplaceFilterAssetClassOption = {
  id: AssetClassType;
  label: string;
};

export type MarketplaceRangeFilterConfig = {
  min: number;
  max: number;
  steps: number[];
  formatter: (v: string) => string;
};

export type MarketplaceBooleanFilterCopy = {
  title: string;
  content: string;
};

type DropdownConfig = {
  type: DropDownType;
};

export interface MarketplaceBaseFilter {
  type: FilterType;
  label: string;
  description: string;
  align?: string;
  dropdownConfig?: DropdownConfig;
}

export interface MarketplaceRangeFilter extends MarketplaceBaseFilter {
  key: MarketplaceFilterQueryParameters[];
  type: FilterTypeEnum.Range;
  config: MarketplaceRangeFilterConfig;
}

export interface MarketplaceMultiFilter extends MarketplaceBaseFilter {
  key: MarketplaceFilterQueryParameters;
  type: FilterTypeEnum.MultiSelect | FilterTypeEnum.SingleSelect;
  options: MarketplaceFilterAssetClassOption[] | MarketplaceFilterAssetTypeOption[];
}

export interface MarketplaceBooleanFilter extends MarketplaceBaseFilter {
  key: MarketplaceFilterQueryParameters;
  type: FilterTypeEnum.Boolean;
  copy: MarketplaceBooleanFilterCopy;
}

const MarketplaceDropdownConfig = {
  type: DropDownType.PILL,
};

export const MARKETPLACE_FILTERS_OPTIONS: Array<
  MarketplaceRangeFilter | MarketplaceMultiFilter | MarketplaceBooleanFilter
> = [
  {
    key: MarketplaceFilterQueryParameters.AssetType,
    type: FilterTypeEnum.SingleSelect,
    label: 'Asset Type',
    description: 'Each investment we offer has an asset type based on how its returns are derived.',
    options: MarketplaceFilterAssetTypeOptions,
    dropdownConfig: MarketplaceDropdownConfig,
  },
  {
    key: MarketplaceFilterQueryParameters.AssetClass,
    type: FilterTypeEnum.MultiSelect,
    label: 'Asset Class',
    description: 'Select the asset classes you would like to invest in.',
    options: FilterAssetClassOptions,
    dropdownConfig: MarketplaceDropdownConfig,
  },
  {
    key: [
      MarketplaceFilterQueryParameters.MinInvestmentLow,
      MarketplaceFilterQueryParameters.MinInvestmentHigh,
    ],
    type: FilterTypeEnum.Range,
    label: 'Minimum',
    description: 'Min. investment amount',
    config: {
      min: 100,
      max: 30000,
      steps: [100, 5000, 10000, 15000, 20000, 25000, 30000],
      formatter: v => {
        const n = parseInt(v);
        return n > 999 ? `$${n / 1000}K` : `$${n}`;
      },
    },
    dropdownConfig: MarketplaceDropdownConfig,
  },
  {
    key: [MarketplaceFilterQueryParameters.MinTerm, MarketplaceFilterQueryParameters.MaxTerm],
    type: FilterTypeEnum.Range,
    label: 'Term',
    description: 'Term duration',
    config: {
      min: 3,
      max: 72,
      steps: [3, 12, 24, 36, 48, 60, 72],
      formatter: v => `${v}M`,
    },
    dropdownConfig: MarketplaceDropdownConfig,
  },
  {
    key: MarketplaceFilterQueryParameters.IraEligible,
    type: FilterTypeEnum.Boolean,
    label: 'IRA Eligibility',
    description: 'Open to IRAs',
    copy: {
      title: '(retirement accounts)',
      content: `Retirement accounts include IRAs, pension plans, defined benefit plans, defined contribution plans, retirement plans, 401(k) and 403(b) plans, and funds comprised of these plans.`,
    },
    align: 'right',
    dropdownConfig: MarketplaceDropdownConfig,
  },
];

export type MarketplaceSortQueryOption = {
  label: string;
  key: MarketplaceFilterQuerySortKey;
  direction: MarketplaceFilterQuerySortDirection;
};

export const MARKETPLACE_SORT_OPTIONS: MarketplaceSortQueryOption[] = [
  {
    label: 'Newest',
    key: MarketplaceFilterQuerySortKey.Published,
    direction: MarketplaceFilterQuerySortDirection.Descending,
  },
  {
    label: 'Min. Investment low to high',
    key: MarketplaceFilterQuerySortKey.MinInvestment,
    direction: MarketplaceFilterQuerySortDirection.Ascending,
  },
  {
    label: 'Min. Investment high to low',
    key: MarketplaceFilterQuerySortKey.MinInvestment,
    direction: MarketplaceFilterQuerySortDirection.Descending,
  },
  {
    label: 'Term low to high',
    key: MarketplaceFilterQuerySortKey.Term,
    direction: MarketplaceFilterQuerySortDirection.Ascending,
  },
  {
    label: 'Term high to low',
    key: MarketplaceFilterQuerySortKey.Term,
    direction: MarketplaceFilterQuerySortDirection.Descending,
  },
];

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
const THEMATIC = {
  id: 'THEMATIC',
  title: 'Thematic funds',
  subTitle:
    'Select from a curated list of funds that can provide access to multiple holdings in a single investment.',
};

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
const SINGLE_OFFERING = {
  id: 'SINGLE_OFFERING',
  title: 'Single offerings',
  subTitle:
    'Select from a curated list of individual debt and equity offerings with varying return profiles.',
};

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
const MULTI_ASSET = {
  id: 'MULTI_ASSET',
  title: 'Multi-asset class fund',
  subTitle:
    'Build a fixed-income portfolio spread across multiple asset classes with a single investment.',
};

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
const STRUCTURED = {
  id: 'STRUCTURED',
  title: 'Structured notes',
  subTitle:
    'Invest in a diversified portfolio of notes tied to the performance of underlying securities with regular coupon payments and equity downside protection.',
};

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
const SHORT_TERM = {
  id: 'SHORT_TERM',
  title: 'Short term notes',
  subTitle:
    'Select from a range of offerings with interest rates typically higher than money market and CDs.',
};

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
export const productTypesAI = [THEMATIC, SINGLE_OFFERING, MULTI_ASSET, STRUCTURED, SHORT_TERM];

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
export const productTypes = [MULTI_ASSET, THEMATIC, SINGLE_OFFERING, STRUCTURED, SHORT_TERM];

/**
 * @deprecated Deprecated used for Education banner which will be removed when Marketplace filters are released */
export const MARKETPLACE_FILTERS = {
  All: 'All',
  Income: 'Income',
  Growth: 'Growth',
  Balanced: 'Balanced',
  LowMinimums: 'Low minimums',
  /**
   * @deprecated Deprecated ***ONLY*** on web, but still supported on Mobile.
   * On web, `Goals` has been replaced by Advice (i.e. `Recommended` filter) */
  MatchesGoals: 'Matches goals',
  New: 'New',
  Recommended: 'Recommended',
};
