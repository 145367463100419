import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { DropzoneProps } from 'react-dropzone';

import { Heading, Paragraph } from '../../content/text';
import FileDropzone from './FileDropzone';
import FilePreviewImage from './FilePreviewImage';
import FilePreviewDocument from './FilePreviewDocument';
import { BaseProps } from '../..';
import { useTestIds } from './FileUpload.model';

export interface FilePreview extends File {
  id?: number | string;
  preview: string;
  sending?: boolean;
  error?: boolean;
  errorDescription?: string;
  errorTitle?: string;
}

type FileUploadProps = {
  title: string | ReactElement;
  subtitle?: string;
  fileDescription?: string;
  acceptedFiles: FilePreview[];
  rejectedFiles: FilePreview[];
  showImagePreview?: boolean;
  onRemoveClick?: (file: File, fileStatus: string) => void;
  disableRemove?: boolean;
} & DropzoneProps &
  BaseProps;

export const FileUpload: FunctionComponent<FileUploadProps> = props => {
  const {
    title,
    subtitle,
    acceptedFiles,
    rejectedFiles,
    showImagePreview = true,
    onRemoveClick,
    disableRemove = false,
    ...otherProps
  } = props;

  const hideUploadzone = !props.multiple && (acceptedFiles.length > 0 || rejectedFiles.length > 0);

  const testIds = useTestIds(props);

  return (
    <div {...testIds.base.attr}>
      {title && (
        <TitleWrapper>
          <Heading type={5} {...testIds.title.attr}>
            {title}
          </Heading>
        </TitleWrapper>
      )}
      {subtitle && (
        <SubtitleWrapper>
          <Paragraph {...testIds.subtitle.attr}>{subtitle}</Paragraph>
        </SubtitleWrapper>
      )}
      {!hideUploadzone && <FileDropzone {...otherProps} testId={testIds.dropzone.id} />}
      {acceptedFiles.length > 0 &&
        acceptedFiles.map((file: FilePreview, idx) => {
          if (showImagePreview) {
            return (
              <FilePreviewImage
                key={idx}
                file={file}
                onRemoveClick={() =>
                  !disableRemove && onRemoveClick ? onRemoveClick(file, 'ACCEPTED') : null
                }
                testId={testIds.imagePreviewAccepted.id}
              />
            );
          }

          return (
            <FilePreviewDocument
              key={idx}
              file={file}
              hideUploadzone={hideUploadzone}
              onRemoveClick={() =>
                !disableRemove && onRemoveClick ? onRemoveClick(file, 'ACCEPTED') : null
              }
              testId={testIds.documentPreviewAccepted.id}
            />
          );
        })}
      {rejectedFiles.length > 0 &&
        rejectedFiles.map((file: FilePreview, idx) => {
          if (showImagePreview) {
            return (
              <FilePreviewImage
                key={idx}
                file={file}
                onRemoveClick={() =>
                  !disableRemove && onRemoveClick ? onRemoveClick(file, 'REJECTED') : null
                }
                testId={testIds.imagePreviewRejected.id}
              />
            );
          }

          return (
            <FilePreviewDocument
              key={idx}
              file={file}
              hideUploadzone={hideUploadzone}
              onRemoveClick={() =>
                !disableRemove && onRemoveClick ? onRemoveClick(file, 'REJECTED') : null
              }
              testId={testIds.documentPreviewRejected.id}
            />
          );
        })}
    </div>
  );
};

const TitleWrapper = styled.div`
  margin-bottom: 10px;
`;

const SubtitleWrapper = styled.div`
  margin-bottom: 20px;
`;
