import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { PageSection } from '../../layout/page-section';
import { Paragraph } from '../../content/text';
import { CallbackLink } from '../../content/link';
import { media } from '../../themes';

interface ContentProps {
  expanded: boolean;
}

interface Image {
  imageBorder?: boolean;
}

interface ManifestoProps extends Image {
  title?: string;
  subTitle?: string;
  image?: React.ReactNode;
  summary?: string;
}

export const Bio: FunctionComponent<ManifestoProps> = ({
  title,
  subTitle,
  image,
  imageBorder,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  const expandedClick = () => {
    setExpanded(!expanded);
  };

  return (
    <PageSection>
      <Container>
        {image && <ImageHolder imageBorder={imageBorder}>{image}</ImageHolder>}
        <ContentHolder>
          <Title small semiBold>
            {title}
          </Title>
          {subTitle && <SubTitle small>{subTitle}</SubTitle>}
          <Content expanded={expanded}>{children}</Content>
          {children && (
            <Paragraph>
              <Link callback={() => expandedClick()}>{expanded ? `Collapse` : `Read More`}</Link>
            </Paragraph>
          )}
        </ContentHolder>
      </Container>
    </PageSection>
  );
};

const Container = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    margin: 0 0 40px 0;
  `}
`;

const ImageHolder = styled.div<Image>`
  width: 100%;
  img {
    width: 100%;
  }
  img {
    ${props =>
      props.imageBorder ? `border-bottom: 10px solid ${props.theme.colors.accent_strong};` : ''};
  }
  ${media.desktop`
    width: 280px;
  `}
`;

const ContentHolder = styled.div`
  width: 100%;
  margin: 20px 0 0 0;
  ${media.desktop`
    margin: 0;
    width: 50%;
    padding: 0 40px 0 20px;
  `}
`;

const Title = styled(Paragraph)`
  width: 100%;
  ${media.desktop`
    width: 60%;
  `}
`;

const Content = styled.div<ContentProps>`
  margin-bottom: 20px;
  ${media.desktop<ContentProps>`
    p {    
      transition: max-height 0.5s ${props => (props.expanded ? `ease-in` : `ease-out`)};
      max-height: ${props => (props.expanded ? `2000px` : `90px`)};
      -webkit-line-clamp: ${props => (props.expanded ? `initial` : 3)};
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

const SubTitle = styled(Paragraph)`
  margin: 0 0 20px 0;
`;

const Link = styled(CallbackLink)`
  display: none;
  ${media.desktop`
    display: inline-block;
  `}
`;
