import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';

export const enum TaxListOrderTypes {
  Ascending = 'asc',
  Descending = 'desc',
}

export const enum TaxListSortTypes {
  InvestmentName = 'investmentName',
  InvestorAccountName = 'investorAccountName',
  PublishedDate = 'publishedDate',
  ExpectedDate = 'expectedDate',
  Type = 'type',
  Year = 'year',
}

export const enum TaxListLabelTypes {
  InvestmentName = 'Investment',
  InvestorAccountName = 'Investor',
  PublishedDate = 'Publication date',
  Type = 'Document type',
  Year = 'Year',
}

export interface TaxListQueryParamsValues {
  noteUrlHash?: Array<string>;
  year?: Array<number | string>;
  type?: Array<string>;
  sortBy: TaxListSortTypes;
  orderBy: TaxListOrderTypes;
}

export interface UseTaxListProps {
  params: TaxListQueryParamsValues;
  investorId?: string;
  managementStrategy?: PortfolioStrategyValues;
  userId?: number | string;
}

export const enum TaxListQueryParams {
  NoteUrlHash = 'noteUrlHash',
  Order = 'orderBy',
  Sort = 'sortBy',
  Type = 'type',
  Year = 'year',
}

export const TaxListQueryParamsData = {
  NoteUrlHash: 'noteUrlHash',
  Order: 'orderBy',
  Sort: 'sortBy',
  Type: 'type',
  Year: 'year',
};

export const TAX_LIST_INIT_SORT_OPTIONS = {
  [TaxListQueryParams.Order]: TaxListOrderTypes.Descending,
  [TaxListQueryParams.Sort]: TaxListSortTypes.PublishedDate,
};

export const TAX_LIST_VALID_SORT_VALUES = {
  [TaxListQueryParams.Order]: [TaxListOrderTypes.Ascending, TaxListOrderTypes.Descending],
  [TaxListQueryParams.Sort]: [
    TaxListSortTypes.InvestmentName,
    TaxListSortTypes.InvestorAccountName,
    TaxListSortTypes.PublishedDate,
    TaxListSortTypes.Type,
    TaxListSortTypes.Year,
  ],
};
