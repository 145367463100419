import React from 'react';
import styled from 'styled-components';

import { media } from '../../themes';
import { rgba } from 'polished';

interface OverlayProps {
  hideOnMobile?: boolean;
  onClick?: () => void;
  'data-cy'?: string;
}

export const Overlay = ({ hideOnMobile = true, onClick, 'data-cy': dataCy }: OverlayProps) => {
  return <Wrapper data-cy={dataCy} onClick={onClick} hideOnMobile={hideOnMobile} />;
};

const getBackground = (props: any) => rgba(props.theme.colors.background_inverse, 0.8);

const Wrapper = styled.div<Pick<OverlayProps, 'hideOnMobile'>>`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => (props.hideOnMobile ? 'none' : getBackground(props))};

  ${media.phoneLandscape`
    background: ${props => getBackground(props)};
  `}
`;
