import styled, { css } from 'styled-components';

import { Heading, Paragraph } from '../../content/text';
import { media, friendlyMedia } from '../../themes';
import { SectionContainer } from '../../layout';

import {
  BackgroundImageProps,
  ContainerProps,
  RightContainerProps,
  StatsViewProps,
} from './StatsView.model';

export const Container = styled.div<ContainerProps>`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `};
`;

export const BackgroundImage = styled.div<BackgroundImageProps>`
  background: url('${({ image }) => image}');
  background-position: center top;

  ${media.desktop`
    width: 384px;
  `}
`;

export const LeftContainer = styled.div`
  width: 100%;

  div {
    height: 300px;
    background-position: center;
    background-size: cover;
  }

  ${media.desktop`
    max-width: 384px;

    div {
      height: 100%;
    }
  `};
`;

export const RightContainer = styled.div<RightContainerProps>`
  padding: 52px 24px;
  background: ${({ inverse, theme }) =>
    inverse ? theme.gradients.radial_primary : theme.colors.background_light};

  ${media.desktop<RightContainerProps>`
    width: ${({ width }) => (width ? width : '100%')};
    padding: ${({ centerAlign }) => (centerAlign ? '64px 40px' : '95px 64px')};
    text-align: ${({ centerAlign }) => (centerAlign ? 'center' : 'left')};
  `};
`;

export const SHeading = styled(Heading)`
  margin-bottom: 8px;
`;

export const SParagraph = styled(Paragraph)<Pick<StatsViewProps, 'inverse'>>`
  color: ${({ inverse, theme }) =>
    inverse ? theme.colors.text_header_inverse : theme.colors.text_secondary_paragraph};
`;

export const SSectionContainer = styled(SectionContainer)`
  ${friendlyMedia.mobile<Pick<StatsViewProps, 'noMobilePadding'>>`
    ${({ noMobilePadding }) => noMobilePadding && 'padding: 0;'}
  `}
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop<{ hasImage: boolean }>`
    text-align: center;
    flex-basis: 200px;

    ${({ hasImage }) =>
      hasImage
        ? css`
            align-items: flex-start;
            &:first-child:nth-last-child(n + 4),
            &:first-child:nth-last-child(n + 4) ~ & {
              flex-basis: 300px;
            }
          `
        : ''}
  `}
`;

export const StatsWrapper = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 16px;
  margin: ${({ hasImage }) => (hasImage ? '32px 0 0 0' : '40px 0 0 0')};

  ${media.desktop`
    flex-direction: row;
    justify-content: space-evenly;
  `}
`;
