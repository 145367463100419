import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Heading, HeadingProps } from '../../content/text';

interface TitleProps extends Omit<HeadingProps, 'type'> {
  mainTitle?: boolean;
}

export const Title: FunctionComponent<TitleProps> = ({
  tagType,
  children,
  className,
  mainTitle,
  ...otherProps
}) => {
  const type = mainTitle ? 2 : 3;
  return (
    <TitleHeading
      mainTitle={mainTitle}
      type={type}
      tagType={tagType || type}
      className={className}
      {...otherProps}
    >
      {children}
    </TitleHeading>
  );
};

const TitleHeading = styled<FunctionComponent<TitleProps & HeadingProps>>(Heading)`
  margin-bottom: ${props => (props.mainTitle ? 35 : 20)}px;
  margin-right: auto;
`;
