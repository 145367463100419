import { AxiosResponse } from 'axios';
import {
  BankAccountType,
  BankAccountHolderType,
  BankAccountExternalPermission,
  ExternalIdentityProvider,
  PaymentMethodType,
  PortfolioStrategyValues,
} from '../../enums';
import { BankAccountUsage } from './bank-account-usage';

export interface BankAccountLimits {
  minimumDeposit?: number;
  minimumWithdrawal?: number;
  incrementalDeposit?: number | null;
  limitPerTransaction?: number;
  numberOfDeposits?: number | null;
}

export enum BankAccountProvider {
  SYNAPSE = 'SYNAPSE',
  PLAID = 'PLAID',
}

export enum BankAccountProvisionState {
  SHELL = 'SHELL',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DEGRADED = 'DEGRADED',
}

export enum VerificationOwner {
  YS = 'YS',
  USER = 'USER',
  PLAID = 'PLAID',
}

export interface BankAccount extends BankAccountLimits {
  accountBalance: number;
  accountType: BankAccountType;
  accountTypeDisplayName: string;
  accruedInterest: number;
  availableBalance: number | null;
  availableWithdrawals: number;
  bankAccountNameWithLast4Digits: string;
  bankName: string;
  bankRoutingNumber: string;
  depositVerificationPending: boolean;
  externalId: string;
  defaultAccount?: boolean;
  externalIdentityProvider: ExternalIdentityProvider;
  externalPermission: BankAccountExternalPermission;
  externalVerificationAttempts: number;
  holderId: number;
  holderName: string;
  holderType: BankAccountHolderType;
  id: number;
  investorAccountManagementStrategy?: PortfolioStrategyValues;
  last4Digits?: string;
  lastPayInUsageDate: number | null;
  lastPayOutUsageDate: number | null;
  maskedAccountNumber: string;
  microDepositsReinitiatedCount: number;
  microDepositsReinitiateRemaining: number;
  nameOnAccount: string;
  nickname: string;
  paymentMethod: PaymentMethodType;
  paymentMethodDisplayName: string;
  pendingBalance: number | null;
  pendingMoneyIn: number;
  pendingMoneyOut: number;
  provider: BankAccountProvider;
  provisionState: BankAccountProvisionState;
  removable: boolean;
  smallDepositsAllowed: boolean;
  specialInstructions: string | null;
  tokenizedAccountNumber: string;
  totalBalance: number | null;
  usages: BankAccountUsage[];
  userId: number;
  verificationAttemptsRemaining: number;
  verificationOwner?: VerificationOwner;
}

export type BankAccountAPIResponse = AxiosResponse<{
  success: boolean;
  bankAccounts: Array<BankAccount>;
}>;

export type BankAccountParams = {
  investorEntityId: number | string;
};
