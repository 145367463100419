import { genTestIds } from '@yieldstreet/tool-kit';
import { BaseProps } from '../../types';

export interface CardBaseContainerProps extends BaseProps {
  href?: string;
  newTab?: boolean;
  onClick?: () => void;
  bgColor?: string;
  children?: React.ReactNode;
  className?: string;
}

export interface CardBaseWrapperProps {
  href?: string;
  isLink?: boolean;
  target?: string;
  clickable?: boolean;
  bgColor?: string;
}

export const { getTestIds, useTestIds } = genTestIds(['card']);
