import { DisclaimerLegalEntity } from '../../../types';
import { LegalEntityType } from '../../../enums';

export const withLegalEntity: DisclaimerLegalEntity = {
  type: 'disclaimer',
  useLegalEntityDisclaimer: true,
  content: ['Additional copy 1', 'Additional copy 2'],
};

export const withoutLegalEntity: DisclaimerLegalEntity = {
  type: 'disclaimer',
  useLegalEntityDisclaimer: false,
  content: ['Additional copy 1', 'Additional copy 2'],
};

export const spvNames = ['AnotherSPV', 'YS ALTNOTES I LLC', 'YS ALTNOTES II LLC'];

export const DISCLAIMER_INFO = {
  ysAltnotesOne: 'YS ALTNOTES I LLC',
  ysAltnotesTwo: 'YS ALTNOTES II LLC',
  ysStNotes: 'YS ST NOTES LLC',
  disclaimerCopyDateBPDN: 'January 14, 2022',
  disclaimerCopyDateSHTN: 'March 8, 2024',
  disclaimerExtraBottomCopy: [
    'Investing in private markets and alternatives, such as this offering, is speculative and involves a risk of loss, and those investors who cannot afford to lose their entire investment should not invest. Returns are not guaranteed.',
    `"Annual interest," "Annualized Return" or "Target Returns" represents a projected annual target rate of interest or annualized target return, and not returns or interest actually obtained by fund investors. Unless otherwise specified on the fund's offering page, target interest or returns are based on an analysis performed by Yieldstreet of the potential inflows and outflows related to the transactions in which the strategy or fund has engaged and/or is anticipated to engage in over the estimated term of the fund. There is no guarantee that targeted interest or returns will be realized or achieved or that an investment will be successful. Actual performance may deviate from these expectations materially, including due to market or economic factors, portfolio management decisions, modeling error, or other reasons.`,
  ],
  disclaimer: {
    [LegalEntityType.SPVM]: `This offering page describes only certain aspects of the offering ("Offering") of the securities issued by {{spvName}} ("Issuer"). The Offering is made only by means of the Investment Memorandum relating to the Offering (the "Offering Document"). The information on this offering page is a summary of the Offering, does not purport to be complete and should not be considered a part of the Offering Document, or as incorporated in the Offering Document by reference or as forming the basis of the Offering. No person has been authorized to give any information or to make any representations other than those contained in the Offering Document or in any marketing or sales literature issued by the Issuer or Yieldstreet Management, LLC, as adviser thereto, and referred to in the Offering Document, and, if given or made, such information or representations must not be relied upon. All investors must read the Offering Document in its entirety prior to investing in the securities.`,
    [LegalEntityType.SHTN]: `This offering page describes only certain aspects of the offering ("Offering") of the securities issued by {{ysStNotes}} ("Issuer"). The Offering is made only by means of the Private Placement Memorandum dated {{disclaimerCopyDateSHTN}} and the Series Note Supplement relating to the Offering (collectively, the "Offering Documents"). The information on this offering page is a summary of the Offering, does not purport to be complete and should not be considered a part of the Offering Documents, or as incorporated in the Offering Documents by reference or as forming the basis of the Offering. No person has been authorized to give any information or to make any representations other than those contained in the Offering Documents or in any marketing or sales literature issued by the Issuer or Yieldstreet Management, LLC, as adviser thereto, and referred to in the Offering Documents, and, if given or made, such information or representations must not be relied upon. All investors must read the Offering Documents in their entirety prior to investing in the securities.`,
    [LegalEntityType.BPDN]: `This offering page describes only certain aspects of the offering ("Offering") of the securities issued by {{spvName}} ("Issuer"). The Offering is made only by means of the Private Placement Memorandum dated {{disclaimerCopyDateBPDN}} and the Series Note Supplement relating to the Offering (collectively, the "Offering Documents"). The information on this offering page is a summary of the Offering, does not purport to be complete and should not be considered a part of the Offering Documents, or as incorporated in the Offering Documents by reference or as forming the basis of the Offering. No person has been authorized to give any information or to make any representations other than those contained in the Offering Documents or in any marketing or sales literature issued by the Issuer or Yieldstreet Management, LLC, as adviser thereto, and referred to in the Offering Documents, and, if given or made, such information or representations must not be relied upon. All investors must read the Offering Documents in their entirety prior to investing in the securities.`,
  },
  specificExtraDisclaimer: {
    [LegalEntityType.SHTN]: [
      'Short Terms Notes involve different risk and liquidity profiles than money markets and CDs. Please review the Short Term Notes Offering Documents for complete details.',
    ],
  },
};
