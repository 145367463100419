import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { PageSection } from '../../layout/page-section';
import { media } from '../../themes/media';

export interface LogoBannerProps {
  image: string;
  href?: string;
  alt?: string;
  width?: number;
  height?: number;
}

interface ContainerProps {
  alignCenter?: boolean;
  reducedMargin?: boolean;
  miniMargin?: boolean;
  flexWrap?: boolean;
}
interface ImageWrapperProps {
  reducedMargin?: boolean;
  miniMargin?: boolean;
}

interface Logo {
  logos: LogoBannerProps[];
  alignCenter?: boolean;
  reducedMargin?: boolean;
  miniMargin?: boolean;
  flexWrap?: boolean;
}

export const LogoBanner: FunctionComponent<Logo> = ({
  logos,
  alignCenter = false,
  reducedMargin = false,
  miniMargin = false,
  flexWrap = true,
}) => (
  <PageSection>
    <Container
      className="logo-banner-container"
      alignCenter={alignCenter}
      reducedMargin={reducedMargin}
      miniMargin={miniMargin}
      flexWrap={flexWrap}
    >
      {logos.map((logo, i) =>
        logo.href ? (
          <ImageWrapper reducedMargin={reducedMargin} miniMargin={miniMargin}>
            <a key={i} href={logo.href} rel="noopener noreferrer" target="_blank">
              <img alt={logo.alt} src={logo.image} width={logo.width} height={logo.height} />
            </a>
          </ImageWrapper>
        ) : (
          <ImageWrapper
            className="logo-banner-image-wrapper"
            reducedMargin={reducedMargin}
            miniMargin={miniMargin}
          >
            <img key={i} alt={logo.alt} src={logo.image} width={logo.width} height={logo.height} />
          </ImageWrapper>
        )
      )}
    </Container>
  </PageSection>
);

const Container = styled.div<ContainerProps>`
  display: flex;
  margin: ${props => (props.reducedMargin ? `0` : `100px 0 40px 0`)};
  flex-wrap: ${props => (props.flexWrap ? 'wrap' : 'nowrap')};
  justify-content: center;
  ${props => props.alignCenter && `align-items: center;`}
`;

const ImageWrapper = styled.div<ImageWrapperProps>`
  min-width: 20%;
  text-align: center;
  margin: 10px;
  ${media.desktop<ImageWrapperProps>`
    min-width: auto;
    margin: 0 ${props => (props.reducedMargin ? `24px` : `50px`)};
    margin: 0 ${props => (props.miniMargin ? `16px` : `50px`)};
  `}
`;
