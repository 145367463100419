import React from 'react';
import styled from 'styled-components';

interface AnchorProps {
  anchorId: string;
  offset?: number;
}

interface WrapperProps {
  offset?: number;
}

const Anchor = ({ anchorId, offset = 60 }: AnchorProps) => (
  <Wrapper id={anchorId} offset={offset} />
);

const Wrapper = styled.div<WrapperProps>`
  display: block;
  position: relative;
  top: ${props => (props.offset ? `-${props.offset}px` : 0)};
  visibility: hidden;
`;

export default Anchor;
