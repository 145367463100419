import React from 'react';
import styled from 'styled-components';
import showIcon from '../../../assets/graphics/icons/show.svg';
import hideIcon from '../../../assets/graphics/icons/hide.svg';

export interface ShowHideToggleProps {
  show: boolean;
  onChange: (show: boolean) => void;
  name?: string;
}

export const ShowHideToggle: React.FunctionComponent<ShowHideToggleProps> = ({
  show,
  onChange,
  name,
}) => {
  return (
    <ShowHideLink data-js-show-hide-toggle onClick={() => onChange(!show)}>
      <img src={show ? hideIcon : showIcon} alt={show ? `Show ${name}` : `Hide ${name}`} />
    </ShowHideLink>
  );
};

const ShowHideLink = styled.a`
  display: inline-flex;
  cursor: pointer;
`;
