import { genTestIds } from '../../testing';

export enum ActionBadgeTypes {
  Default = 'default',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum InvestmentActionTypes {
  ActionRequired = 'action-required',
  Details = 'details',
  Rollover = 'rollover',
  RolloversScheduled = 'rollovers-scheduled',
}

export const { getTestIds } = genTestIds([
  InvestmentActionTypes.ActionRequired,
  InvestmentActionTypes.Details,
  InvestmentActionTypes.Rollover,
  InvestmentActionTypes.RolloversScheduled,
]);

export const testIds = getTestIds('investment-action');
