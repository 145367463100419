import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { CSSTransition } from 'react-transition-group';
import { media } from '../../themes';
import { MobileOnly, DesktopOnly } from '../../utils';

interface AnimatedBannerProps {
  image?: string;
  imageComponent?: React.ReactNode;
  containerBgColor?: string;
  trackRecordBgColor?: string;
  rightContainerWidth?: string;
}

interface BackgroundImageProps {
  image?: string;
}

interface ContainerProps {
  containerBgColor?: string;
  trackRecordBgColor?: string;
}

interface RightContainerProps {
  width?: string;
}

export const AnimatedBanner: FunctionComponent<AnimatedBannerProps> = ({
  image,
  imageComponent,
  children,
  containerBgColor,
  trackRecordBgColor,
  rightContainerWidth,
}) => {
  return (
    <>
      <DesktopOnly>
        <BarHolder trackRecordBgColor={trackRecordBgColor} containerBgColor={containerBgColor}>
          <LeftContainer />
          <RightContainer width={rightContainerWidth}>
            <VisibilitySensor>
              {({ isVisible }) => (
                <CSSTransition
                  className="CSSTransition"
                  in={isVisible}
                  timeout={100}
                  classNames="bars"
                >
                  <Bars>
                    <Bar className="first"></Bar>
                    <Bar className="second"></Bar>
                    <Bar className="third"></Bar>
                    <Bar containerBgColor={containerBgColor} className="forth"></Bar>
                  </Bars>
                </CSSTransition>
              )}
            </VisibilitySensor>
          </RightContainer>
        </BarHolder>
      </DesktopOnly>
      <Container trackRecordBgColor={trackRecordBgColor}>
        <LeftContainer>
          {imageComponent ? (
            imageComponent
          ) : (
            <BackgroundImage className="animated-banner-image" image={image} />
          )}
        </LeftContainer>
        <RightContainer width={rightContainerWidth}>
          <MobileOnly>
            <VisibilitySensor>
              {({ isVisible }) => (
                <CSSTransition in={isVisible} timeout={400} classNames="bars">
                  <Bars>
                    <Bar className="first"></Bar>
                    <Bar className="second"></Bar>
                    <Bar className="third"></Bar>
                    <Bar trackRecordBgColor={trackRecordBgColor} className="forth"></Bar>
                  </Bars>
                </CSSTransition>
              )}
            </VisibilitySensor>
          </MobileOnly>
          {children}
        </RightContainer>
      </Container>
    </>
  );
};

const Container = styled.div<ContainerProps>`
  background-color: ${props =>
    props.trackRecordBgColor
      ? props.theme.colors[props.trackRecordBgColor]
      : props.theme.colors.background_page};
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
  `};
`;

const LeftContainer = styled.div`
  width: 100%;
  div {
    height: 230px;
    background-position: center;
    background-size: cover;
  }
  ${media.desktop`
    width: 45%;
    div {
      height: 100%;
    }
  `};
`;

const BackgroundImage = styled.div<BackgroundImageProps>`
  background: url('${props => props.image}')};
  background-position: center top;
`;

const RightContainer = styled.div`
  padding: 50px 20px 40px;
  position: relative;
  ${media.desktop<RightContainerProps>`
    padding: 120px 20px 40px 80px;
    width: ${props => (props.width ? props.width : `45%`)};
  `}
`;

const BarHolder = styled(Container)`
  display: flex;
  height: 30px;
  ${LeftContainer} {
    background: ${props =>
      props.containerBgColor
        ? props.theme.colors[props.containerBgColor]
        : props.theme.colors.background_inverse_secondary};
  }
  background-color: ${props =>
    props.trackRecordBgColor
      ? props.theme.colors[props.trackRecordBgColor]
      : props.theme.colors.background_page};
`;

const Bars = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  position: absolute;
  left: 0;
  top: -60px;

  ${media.desktop`
    top: -30px;
  `};

  &.bars-enter-done {
    div {
      max-height: 30px;
      margin-top: 0;
    }
  }
`;

const Bar = styled.div<ContainerProps>`
  transition: all 0.5s ease;
  max-height: 0;
  margin-top: 30px;
  position: absolute;
  width: calc(100% / 3);
  height: 30px;
  &.first {
    bottom: 0;
    left: 0;
    background: ${props => props.theme.colors.accent_light};
  }
  &.second {
    transition-delay: 0.2s;
    top: 30px;
    left: calc(100% / 3);
    background: ${props => props.theme.colors.accent_medium};
  }
  &.third {
    transition-delay: 0.3s;
    top: 0;
    right: 0;
    background: ${props => props.theme.colors.accent_strong};
  }
  &.forth {
    max-height: 30px;
    margin-top: 0;
    top: 30px;
    right: 0;
    background-color: ${props =>
      props.trackRecordBgColor
        ? props.theme.colors[props.trackRecordBgColor]
        : props.theme.colors.background_card};
    ${media.desktop<ContainerProps>`
      left: 0;
      background: ${props =>
        props.containerBgColor
          ? props.theme.colors[props.containerBgColor]
          : props.theme.colors.background_inverse_secondary};
    `};
  }
`;
