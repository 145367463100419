import type { Components, CssVarsTheme } from '@mui/material';

export const MuiTextField: Components<CssVarsTheme>['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
  },
  styleOverrides: {
    root: () => ({
      variants: [
        {
          props: { variant: 'outlined', color: 'primary' },
          style: ({ theme }) => ({
            fieldset: {
              borderColor: theme.palette.border.default,
            },

            '&:hover, .Mui-focused': {
              fieldset: {
                border: `1px solid ${theme.palette.border.strong} !important`,
              },
            },
          }),
        },
      ],
    }),
  },
};
