import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { MuiTheme } from '../../../../../theme';

const getActiveStyles = (theme: MuiTheme) => ({
  backgroundColor: theme.palette.background.dark,

  p: {
    color: theme.palette.text.paragraphPrimary.inverse,
  },

  span: {
    color: theme.palette.text.labelPrimary.inverse,
  },
});

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginRight: theme.spacing(3),
  width: '280px',
}));

export const Label = styled(Typography)<{ inverse?: boolean; semiBold?: boolean }>(
  ({ inverse, semiBold, theme }) => ({
    color: inverse
      ? theme.palette.text.labelPrimary.inverse
      : theme.palette.text.labelPrimary.default,
    fontWeight: semiBold ? 500 : 400,
  })
);

export const OuterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '210px',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    height: '100%',
  },
}));

export const Paragraph = styled(Typography)<{ inverse?: boolean; semiBold?: boolean }>(
  ({ inverse, semiBold, theme }) => ({
    color: inverse
      ? theme.palette.text.paragraphPrimary.inverse
      : theme.palette.text.paragraphPrimary.default,
    fontWeight: semiBold ? 500 : 400,
  })
);

export const Strategy = styled('div')<{ active?: boolean }>(({ active, theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  borderRadius: '4px',
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',

  '&:hover': {
    ...getActiveStyles(theme),
  },

  ...(active && getActiveStyles(theme)),
}));
