import React, { FunctionComponent } from 'react';
import { media } from '../../themes/media';
import styled from 'styled-components';
import { Pagination, EffectCards, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';

type swiperEffects =
  | 'slide'
  | 'fade'
  | 'cube'
  | 'coverflow'
  | 'flip'
  | 'creative'
  | 'cards'
  | undefined;
const cardsEffect: swiperEffects = 'cards';

export type CardsCarouselProps = {
  className?: string;
  children: React.ReactChild[];
  slidesPerView?: number;
  initialSlide?: number;
  slideChange?: (slideIndex: number) => void;
  showMobileControls?: boolean;
};

export const CardsCarousel: FunctionComponent<CardsCarouselProps> = ({
  className,
  children,
  initialSlide,
  slideChange,
  showMobileControls = false,
}) => {
  const props = {
    navigation: true,
    effect: cardsEffect,
    grabCursor: true,
    modules: [EffectCards, Navigation, Pagination],
    className: 'swiper3d',
    cardsEffect: { rotate: false },
    slideToClickedSlide: false,
    initialSlide: initialSlide,
    preventClicks: true,
    pagination: showMobileControls,
    showMobileControls,
  };

  return (
    <CarouselWrapper className={className}>
      <SSwiper
        {...props}
        className="swiper"
        onActiveIndexChange={slide => slideChange && slideChange(slide.activeIndex)}
      >
        {React.Children.map(children, child => {
          return <SwiperSlide>{child}</SwiperSlide>;
        })}
      </SSwiper>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  min-height: 398px;
  margin: 0 auto;
  max-width: 100% !important;
  position: relative;
  ${media.desktop`
    width: 470px;
  `}
`;

const SSwiper = styled(Swiper)<Partial<Omit<CardsCarouselProps, 'children'>>>`
  position: relative;
  top: 0;
  margin-left: 0;
  overflow: visible !important;
  perspective: 2000px !important;
  width: 336px;
  ${media.desktop`
    width: 1600px !important;
  `}

  ${media.desktop`
    top: 0;
  `}

  .swiper-slide-shadow {
    background: none !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: ${props => (props.showMobileControls ? 'flex' : 'none')};
    background-color: ${props => props.theme.colors.background_card};
    border-radius: 5px;
    box-shadow: ${props => props.theme.shadows.elevation_2};
    height: 64px;
    top: 47%;
    width: 20px
    padding: ${props => (props.showMobileControls ? props.theme.spacing.s : '0')};

    &:hover {
      box-shadow: ${props => props.theme.shadows.shadow_hover};
    }
    &:after {
      color: ${props => props.theme.colors.background_inverse};
      font-weight: bold;
      font-size: 12px;
      position: absolute;
    }
  }

  .swiper-button-prev {
    left: ${props => (props.showMobileControls ? '-12px' : '3px')};
  }

  .swiper-button-next {
    right: ${props => (props.showMobileControls ? '-12px' : '3px')};
  }

  ${media.desktop`
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
  `}

  .swiper-slide {
    position: relative;
  }

  .swiper-slide:not(.swiper-slide-active) {
    ${media.desktop`
      filter: blur(2px);
    `}
  }

  .swiper-pagination {
    bottom: -20px !important;
    max-width: 100%;
  }

  .swiper-pagination-bullets {
    opacity: 1 !important;
    height: 10px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.colors.icon_default};
  }
`;
