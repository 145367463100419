import {
  Spacing as SpacingScale,
  FriendlySize as BreakpointsAlias,
  SpacingOptions,
} from '../../themes';

export enum PaddingKeyEnum {
  All = 'padding',
  Horizontal = 'paddingHorizontal',
  Vertical = 'paddingVertical',
  Top = 'paddingTop',
  Right = 'paddingRight',
  Bottom = 'paddingBottom',
  Left = 'paddingLeft',
}

export enum MarginKeyEnum {
  All = 'margin',
  Horizontal = 'marginHorizontal',
  Vertical = 'marginVertical',
  Top = 'marginTop',
  Right = 'marginRight',
  Bottom = 'marginBottom',
  Left = 'marginLeft',
}

export const PaddingKeys = Object.values(PaddingKeyEnum);

export type PaddingPropKeys = (typeof PaddingKeyEnum)[keyof typeof PaddingKeyEnum];

export const MarginKeys = Object.values(MarginKeyEnum);

export type MarginPropKeys = (typeof MarginKeyEnum)[keyof typeof MarginKeyEnum];

export type ResponsiveProp<T> =
  | T
  | {
      [Breakpoint in BreakpointsAlias]?: SpacingScale;
    };

type ResponsiveSpacing = ResponsiveProp<SpacingScale>;

export type PaddingProps = {
  /** Padding around children. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * padding={Spacing.xxs}
   * padding={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [PaddingKeyEnum.All]?: ResponsiveSpacing;
  /** Horizontal padding around children. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * paddingHorizontal={Spacing.xxs}
   * paddingHorizontal={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [PaddingKeyEnum.Horizontal]?: ResponsiveSpacing;
  /** Vertical padding around children. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * paddingVertical={Spacing.xxs}
   * paddingVertical={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [PaddingKeyEnum.Vertical]?: ResponsiveSpacing;
  /** Top padding. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * paddingTop={Spacing.xxs}
   * paddingTop={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [PaddingKeyEnum.Top]?: ResponsiveSpacing;
  /** Right padding. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * paddingRight={Spacing.xxs}
   * paddingRight={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [PaddingKeyEnum.Right]?: ResponsiveSpacing;
  /** Bottom padding. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * paddingBottom={Spacing.xxs}
   * paddingBottom={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [PaddingKeyEnum.Bottom]?: ResponsiveSpacing;
  /** Bottom padding. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * paddingLeft={Spacing.xxs}
   * paddingLeft={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [PaddingKeyEnum.Left]?: ResponsiveSpacing;
};

export type MarginProps = {
  /** Margin around children. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * margin={Spacing.xxs}
   * margin={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [MarginKeyEnum.All]?: ResponsiveSpacing;
  /** Horizontal margin around children. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * marginHorizontal={Spacing.xxs}
   * marginHorizontal={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [MarginKeyEnum.Horizontal]?: ResponsiveSpacing;
  /** Vertical margin around children. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * marginVertical={Spacing.xxs}
   * marginVertical={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [MarginKeyEnum.Vertical]?: ResponsiveSpacing;
  /** Top margin. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * marginTop={Spacing.xxs}
   * marginTop={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [MarginKeyEnum.Top]?: ResponsiveSpacing | boolean;
  /** Right margin. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * marginRight={Spacing.xxs}
   * marginRight={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [MarginKeyEnum.Right]?: ResponsiveSpacing;
  /** Bottom margin. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * marginBottom={Spacing.xxs}
   * marginBottom={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [MarginKeyEnum.Bottom]?: ResponsiveSpacing | boolean;
  /** Bottom margin. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * Spacing tokens xxs | xs | s | sm | m | ml | l | xl | 2xl | 3xl | 4xl | 5xl
   * @example
   * marginLeft={Spacing.xxs}
   * marginLeft={{ mobile: 'xxs', tablet: 'm', desktop: 'l' }}
   */
  [MarginKeyEnum.Left]?: ResponsiveSpacing;
};

export type SpacingStyleProps = PaddingProps & MarginProps;

const SpacingControls = {
  control: {
    type: 'select',
    options: SpacingOptions,
  },
  if: { arg: 'showSpacingProps' },
};

export const generateSpacingStoryArgs = (showByDefault = true) => ({
  showSpacingProps: {
    control: {
      type: 'boolean',
    },
    defaultValue: showByDefault,
  },
  ...SpacingStoryArgs,
});

export const SpacingStoryArgs = {
  [PaddingKeyEnum.All]: SpacingControls,
  [PaddingKeyEnum.Horizontal]: SpacingControls,
  [PaddingKeyEnum.Vertical]: SpacingControls,
  [PaddingKeyEnum.Top]: SpacingControls,
  [PaddingKeyEnum.Right]: SpacingControls,
  [PaddingKeyEnum.Bottom]: SpacingControls,
  [PaddingKeyEnum.Left]: SpacingControls,
  [MarginKeyEnum.All]: SpacingControls,
  [MarginKeyEnum.Horizontal]: SpacingControls,
  [MarginKeyEnum.Vertical]: SpacingControls,
  [MarginKeyEnum.Top]: SpacingControls,
  [MarginKeyEnum.Right]: SpacingControls,
  [MarginKeyEnum.Bottom]: SpacingControls,
  [MarginKeyEnum.Left]: SpacingControls,
};
