import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes';
import { Heading, HeadingType, Paragraph } from '../../../content/text';

interface ImageWithTitleProps {
  image?: string;
  imageAlt?: string;
  imageHeight?: number;
  imageWidth?: number;
  inverse?: boolean;
  peakItemOnMobile?: boolean;
  textWrap?: boolean;
  title?: string;
  heading?: string;
  headingType?: HeadingType;
  tagType?: HeadingType;
}

export const ImageWithTitle: FunctionComponent<ImageWithTitleProps> = ({
  image,
  imageAlt,
  imageHeight,
  imageWidth,
  inverse,
  peakItemOnMobile,
  textWrap,
  title,
  heading,
  tagType = 5,
  headingType = 5,
}) => {
  return (
    <OuterContainer peakItemOnMobile={peakItemOnMobile} image={image} title={title}>
      <ImageHolder>
        {image && (
          <ImageWrapper>
            <Image width={imageWidth} height={imageHeight} alt={imageAlt} src={image}></Image>
          </ImageWrapper>
        )}
      </ImageHolder>
      {heading && (
        <SHeading type={headingType} tagType={tagType} inverse={inverse}>
          {heading}
        </SHeading>
      )}
      {title && (
        <Headline inverse={inverse} semiBold small textWrap={textWrap}>
          {title}
        </Headline>
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled.div<ImageWithTitleProps>`
  text-align: left;
  flex: 0 0 calc(100% / ${props => (props.peakItemOnMobile ? 3.2 : 3)});
  padding: 0 20px 0 0;
  ${media.tablet`
    flex: 1 1 25%;
    text-align: center;

  `}
  ${media.desktop`
    padding: 0 20px;
    flex: none;
    flex: 1 0;
  `}
`;

const Headline = styled(Paragraph)<ImageWithTitleProps>`
  padding: 0;
  margin: 10px 0;
  white-space: ${props => (props.textWrap ? 'normal' : 'nowrap')};
`;

const SHeading = styled(Heading)<ImageWithTitleProps>`
  white-space: ${props => (props.textWrap ? 'normal' : 'nowrap')};
`;

const ImageHolder = styled.div`
  position: relative;
  height: 60px;
  margin: 20px 0;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const Image = styled.img`
  max-height: 60px;
`;
