export enum InvestmentType {
  MONTHLY,
  ENDOFTERM,
  MIXED,
  BIMONTHLY,
  QUARTERLY,
  SEMIANNUAL,
  ANNUAL,
}
export interface Investment {
  name: string;
  defaultAmount: number;
  term: number;
  termSuffix: string;
  rate: number;
  lowerRate?: number;
  type: InvestmentType;
  min?: number;
  max?: number;
}

export interface Portfolio {
  title: string;
  subtitle: string;
  investments: Investment[];
}
