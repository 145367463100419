import React from 'react';
import styled from 'styled-components';

// import { UserState } from '@yieldstreet/platform-kit';

import { Paragraph } from '../../content/text';

import Icon from './assets/download-icon.svg';

interface DownloadCardProps {
  title?: string;
  description?: string;
}

interface StyledComponentProps {
  className?: string;
}

interface TitleProps extends StyledComponentProps {
  icon?: string;
}

export const DownloadCard = ({ title, description }: DownloadCardProps) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <TextWrapper>
          {title ? (
            <TitleContainer>
              <Title semiBold className="media-card-title">
                {title}
              </Title>
            </TitleContainer>
          ) : null}
          {description ? (
            <Description className="media-card-description">{description}</Description>
          ) : null}
        </TextWrapper>
        <img src={Icon} />
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  overflow-wrap: break-word;
  border: 1px solid ${props => props.theme.colors.border_default};
  box-shadow: ${props => props.theme.shadows.shadow_default};
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: ${props => props.theme.shadows.shadow_hover};
  }
  margin: 10px 0;
  padding: 22px;
  flex-wrap: wrap;
  background-color: ${props => props.theme.colors.background_card};
  border-radius: 2px;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-wrap: anywhere;
  &:not(:only-child) {
    padding-bottom: 10px;
  }
  cursor: pointer;
`;

const Title = styled(Paragraph)<TitleProps>``;

const Description = styled(Paragraph)<StyledComponentProps>`
  cursor: pointer;
`;
