import { genTestIds } from '../testing';

export const requestAccountDeletionStrings = {
  initialCta: 'Delete my Yieldstreet account',
  requestHeader: 'Account deletion',
  requestCopy:
    'Are you sure you want to delete your account? This cannot be undone. If additional action is required before your account can be deleted, our Investor Relations team will reach out to you.',
  requestCta: 'Request account deletion',
  submittedHeader: 'Request sent',
  submittedCopy:
    'Our Investor Relations team has been notified of your request and will contact you shortly on next steps.',
  submittedCta: 'OK',
  errorMessage:
    'We encountered an error while submitting your request. Please reach out to us at investments@yieldstreet.com.',
};

export enum RequestAccountDeletionFormPage {
  PRE_SUBMIT = 'preSubmit',
  POST_SUBMIT = 'postSubmit',
}

const { getTestIds } = genTestIds([
  'initial-cta',
  'request-header',
  'request-cta',
  'submitted-cta',
  'submitted-header',
  'error-notification',
]);

export const requestAccountDeletionTestIds = getTestIds('request-account-deletion');
