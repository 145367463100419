import { restApi, BASE_REST_CONFIG } from '../../common';
import { InvestmentEligibility } from '../../../types';

const BASE_URL = '/a/api/investment-request';

export const legacyRolloverApi = restApi.injectEndpoints({
  ...BASE_REST_CONFIG,
  endpoints: builder => {
    const getEligibleRollover = builder.query<InvestmentEligibility, {}>({
      query: () => ({
        url: `${BASE_URL}/investments/eligible-rollover`,
        method: 'GET',
      }),
    });

    return {
      getEligibleRollover,
    };
  },
});
