// Keys for questions used in the Advice recommendations quiz and in Investment Preferences form

export enum AdviceQuestionKeys {
  CurrentIncome = 'current-income',
  Employer = 'employer-name',
  EmploymentStatus = 'employment-status',
  ExpectedYield = 'expected-yield',
  Industry = 'industry',
  InterestedAssetClasses = 'interested-asset-classes',
  InvestmentAmount = 'investment-amount',
  InvestmentStyle = 'investment-style',
  NetWorth = 'net-worth',
  Position = 'position',
  PreferredTimeHorizon = 'preferred-time-horizon',
  RiskTolerance = 'risk-tolerance',
  QualifiedPurchaser = 'qualified-purchaser',
  QualifiedClient = 'qualified-client',
}
