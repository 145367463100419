import styled from 'styled-components';
import { rgba } from 'polished';
import { Paragraph, SectionContainer, media, ExternalLink } from '@yieldstreet/ui-kit';

import { DisclosureProps } from './FundFooter.model';

export const Footer = styled.footer`
  background: ${({ theme }) => theme.colors.background_inverse};
`;

export const SSectionContainer = styled(SectionContainer)`
  ${media.large`
    padding-right: 0;
    padding-left: 0;
  `}
`;

export const SExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.text_label_inverse} !important;
  background: none !important;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const LegalParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text_label_inverse};
`;

export const AddressContainer = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.border_strong};
  p {
    color: ${({ theme }) => theme.colors.text_label_inverse};
  }
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  padding: ${({ theme }) => theme.spacing.ml} 0;
  ${media.desktop`
    flex-direction: row;
  `};
`;

export const Telephone = styled(Paragraph)`
  margin: ${({ theme }) => theme.spacing.s} 0 0 0;
  ${media.desktop`
    margin: 0 0 0 ${({ theme }) => theme.spacing.ml};
  `};
`;

export const Email = styled(Paragraph)`
  margin: ${({ theme }) => theme.spacing.s} 0 0 0;
  ${media.desktop`
    margin: 0;
    justify-self: end;
    flex: 1;
    text-align: right;
  `};
`;

export const LegalContainer = styled.div<DisclosureProps>`
  overflow: hidden;
  max-height: ${props => (props.show ? '2000px' : '130px')};
  position: relative;
`;

export const DisclosureToggle = styled.div<DisclosureProps>`
  position: ${props => (props.show ? 'relative' : 'absolute')};
  background: linear-gradient(
    180deg,
    ${({ theme }) => rgba(theme.colors.background_inverse, 0.1)} 20%,
    ${({ theme }) => rgba(theme.colors.background_inverse, 0.8)} 60%,
    ${({ theme }) => rgba(theme.colors.background_inverse, 0.9)} 80%
  );
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  ${media.desktop<DisclosureProps>`
    grid-column: ${props => props.show && `1 span / 3`};
  `};
`;

export const DisclosureToggleText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text_header_inverse};
  padding: ${({ theme }) => theme.spacing.m};
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

export const CopyrightLine = styled(Paragraph)`
  font-weight: 500;
  font-size: 12px;
  padding: ${({ theme }) => theme.spacing.l} 0 ${({ theme }) => theme.spacing.s};
`;
export const Copyright = styled.div`
  display: block;
  text-align: left;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.text_label_inverse};
`;

export const ChevronDown = styled.span`
  width: 12px;
  height: 12px;
  border-style: solid;
  border-width: 3px 3px 0 0;
  display: inline-block;
  position: relative;
  transform: rotate(135deg);
  vertical-align: top;
`;
export const ChevronUp = styled(ChevronDown)`
  top: 0;
  transform: rotate(-45deg) !important;
`;
