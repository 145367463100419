import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';

export const CardContainer = styled('div')<{ mini?: boolean }>(({ mini, theme }) => ({
  flex: 1,
  display: 'inline-flex',
  flexDirection: mini ? 'row' : 'column',
  alignItems: 'stretch',
  width: '100%',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
  background: theme.palette.background.default,
}));

export const CardContent = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(5),
}));

export const CardText = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const CTAHolder = styled('div')(({ theme }) => ({
  button: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));

export const Description = styled(Typography)<{ mini?: boolean }>(({ mini, theme }) => ({
  flexGrow: mini ? 0 : 1,
  marginBottom: mini ? theme.spacing(3) : theme.spacing(10),
}));

export const Image = styled('div')<{ image?: string; mini?: boolean }>(({ image }) => ({
  backgroundImage: `url(${image})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  borderRadius: '4px',
}));

export const ImageWrapper = styled('div')<{ mini?: boolean }>(({ mini, theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(5),
  overflow: 'hidden',

  ...(mini
    ? {
        paddingRight: 0,
        width: '140px',
        height: 'auto',
        minWidth: '140px',
        minHeight: 'none',
      }
    : {
        paddingBottom: 0,
        width: 'auto',
        height: '140px',
        minWidth: 'none',
        minHeight: '140px',
      }),
}));
