import { HttpMethods } from '../../../enums';
import { UserEmailVerifyApiRequest } from './email-verify.model';
import { Tags, restApi } from '../../common';

export const emailVerifyApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserState],
  })
  .injectEndpoints({
    endpoints: builder => {
      const emailVerify = builder.mutation<{}, UserEmailVerifyApiRequest>({
        query: ({ verification_code }) => {
          return {
            url: '/a/api/user/emailVerify',
            method: HttpMethods.Post,
            data: { verification_code },
          };
        },
        invalidatesTags: [Tags.UserState],
      });

      return {
        emailVerify,
      };
    },
  });
