import styled, { css } from 'styled-components';
import { media, spacing } from '../../themes';

import { TextBlockProps } from './TextBlock.model';

export const TextContainer = styled.div<Pick<TextBlockProps, 'align' | 'mobileAlign'>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.mobileAlign || props.align};
  text-align: ${props => props.mobileAlign || props.align};
  ${media.desktop<Pick<TextBlockProps, 'align'>>`
    align-items: ${props => props.align};
    text-align: ${props => props.align};
  `}
`;

export const ParagraphContainer = styled.div<{ inverse?: boolean }>`
  p {
    color: ${props =>
      props.inverse
        ? props.theme.colors.text_secondary_paragraph_inverse
        : props.theme.colors.text_secondary_paragraph};
    margin-bottom: ${spacing.sm};
  }
`;

export const HeadingContainer = styled.div<{ compact?: boolean }>`
  ${({ compact }) =>
    compact
      ? css`
          margin-bottom: ${spacing.s};
          margin-top: ${spacing.s};
        `
      : css`
          margin-bottom: ${spacing.sm};
          margin-top: ${spacing.sm};
        `}
`;

export const ButtonContainer = styled.div<{ compact?: boolean }>`
  margin-top: ${props => (props.compact ? spacing.xs : spacing.sm)};
`;

export const StatsContainer = styled.div`
  display: flex;
  column-gap: ${spacing.ml};
  justify-content: space-between;
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.s};
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;
