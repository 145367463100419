import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes';
import { Paragraph } from '../../../content/text';

interface Alignment {
  alignment?: 'left' | 'center' | 'right';
}

interface ContentAlignment {
  contentAlignment?: 'left' | 'center' | 'right';
}

interface ItemWithContentProps extends Alignment, ContentAlignment {
  title?: string;
  image?: React.ReactNode;
  inverse?: boolean;
}

export const PhotoWithContent: FunctionComponent<ItemWithContentProps> = ({
  title,
  image,
  alignment = 'left',
  contentAlignment = 'left',
  children,
  inverse,
}) => {
  return (
    <OuterContainer>
      <Title semiBold alignment={alignment} inverse={inverse}>
        {title}
      </Title>
      <ImageHolder alignment={alignment}>
        {image && <ImageWrapper>{image}</ImageWrapper>}
      </ImageHolder>
      <Content contentAlignment={contentAlignment} small={true} inverse={inverse}>
        {children}
      </Content>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  ${media.desktop`
    max-width: 280px;
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child){
      margin: 0 10px;
    }
  `}
`;

const Title = styled(Paragraph)<Alignment>`
  text-align: ${props => props.alignment};
  margin: 30px 0 10px 0;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const ImageHolder = styled.div<Alignment>`
  text-align: ${props => props.alignment};
`;

const Content = styled(Paragraph)<ContentAlignment>`
  margin: 10px 0 0 0;
  text-align: ${props => props.contentAlignment};
`;

const ImageWrapper = styled.div``;
