class EvalFlagComponents {
  flagComponentMap: Record<string, string[]> = {};

  getAllFlagComponents = (flag: string): string[] => this.flagComponentMap[flag] || [];

  clearAllFlagComponents = (flag: string) => {
    delete this.flagComponentMap[flag];
  };

  addFlagComponent = (flag: string, componentId: string) => {
    if (!this.flagHasEvalComponent(flag, componentId)) {
      this.flagComponentMap[flag] = [...this.getAllFlagComponents(flag), componentId];
    }
  };

  removeFlagComponent = (flag: string, componentId: string) => {
    if (this.flagHasEvalComponent(flag, componentId)) {
      this.flagComponentMap[flag]?.filter(id => componentId !== id);
    }
  };

  flagHasEval = (flag: string) => {
    const flagComponents = this.getAllFlagComponents(flag);
    return !!flagComponents.length;
  };

  flagHasEvalComponent = (flag: string, componentId: string) => {
    const flagComponents = this.getAllFlagComponents(flag);
    return !!flagComponents.length && flagComponents.find(id => componentId === id);
  };

  resetAllFlags = () => (this.flagComponentMap = {});
}

export const evalFlagComponents = new EvalFlagComponents();
