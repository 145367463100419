import React, { FunctionComponent, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { LinkStyles } from '../link';
import { TextBaseStyle, TextBaseStyleProps, TextBaseProps } from './TextBase';

enum ParagraphSizeEnum {
  Chart = 'chart',
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
}

export type ParagraphSize = `${ParagraphSizeEnum}`;

type LegacyTextSizes = {
  /**
   * @deprecated use `size` instead
   * */
  big?: boolean;
  /**
   * @deprecated use `size` instead
   * */
  chart?: boolean;
  /**
   * @deprecated use `size` instead
   * */
  small?: boolean;
};

export type ParagraphProps = TextBaseStyleProps &
  TextBaseProps &
  LegacyTextSizes & {
    inverse?: boolean;
    normal?: boolean;
    nowrap?: boolean;
    secondary?: boolean;
    semiBold?: boolean;
    className?: string;
    size?: ParagraphSize;
  };

const mapLegacySizeToNewSize = ({ big, chart, small }: LegacyTextSizes, size?: ParagraphSize) => {
  if (size) {
    return size;
  }

  if (chart) {
    return ParagraphSizeEnum.Chart;
  }

  if (small) {
    return ParagraphSizeEnum.Small;
  }

  if (big) {
    return ParagraphSizeEnum.Large;
  }

  return ParagraphSizeEnum.Regular;
};

export const Paragraph: FunctionComponent<PropsWithChildren<ParagraphProps>> = ({
  children,
  small = false,
  big = false,
  chart = false,
  as = 'p',
  size,
  ...knobs
}) => {
  const legacySize = mapLegacySizeToNewSize(
    {
      big,
      chart,
      small,
    },
    size
  );

  return (
    <ParagraphStyledElement as={as} size={legacySize} {...knobs}>
      {children}
    </ParagraphStyledElement>
  );
};

const normal = css`
  font-weight: normal;
`;

const semiBold = css`
  font-weight: 500;
`;

const regularColors = css<ParagraphProps>`
  color: ${props =>
    props.secondary
      ? props.theme.colors.text_secondary_paragraph
      : props.theme.colors.text_paragraph};
`;

const inverseColors = css<ParagraphProps>`
  color: ${props =>
    props.secondary
      ? props.theme.colors.text_paragraph_inverse
      : props.theme.colors.text_secondary_paragraph_inverse};
`;

export const ParagraphLink = css<ParagraphProps>`
  ${LinkStyles}
`;

export const ListStyle = css`
  padding-left: 20px;
  margin-top: 20px;
  li {
    list-style-type: disc;
    margin-bottom: 10px;
  }
`;

export const getParagraphSizeStyles = (size?: ParagraphSize) => {
  switch (size) {
    case ParagraphSizeEnum.Chart:
      return ParagraphChartText;
    case ParagraphSizeEnum.Small:
      return ParagraphSmallText;
    case ParagraphSizeEnum.Large:
      return ParagraphBigText;
    default:
      return ParagraphRegularText;
  }
};

export const ParagraphBase = css<ParagraphProps>`
  font-family: ${props => props.theme.typography.primary};
  ${props => (props.semiBold ? semiBold : normal)}
  ${props => (props.inverse ? inverseColors : regularColors)}
  ${props => props.nowrap && `white-space: nowrap;`}
  ${props => getParagraphSizeStyles(props.size)}
  ${TextBaseStyle}
`;

export const ParagraphChildren = css`
  a {
    ${LinkStyles}
  }

  ul {
    ${ListStyle}
  }
`;

export const ParagraphRegularText = css`
  font-size: 16px;
  line-height: 22px;
`;

export const ParagraphRegular = css`
  ${ParagraphBase}
  ${ParagraphChildren}
  ${ParagraphRegularText}
`;

const ParagraphStyledElement = styled.span<ParagraphProps>`
  ${ParagraphBase}
  ${ParagraphChildren}
`;

export const ParagraphSmallText = css`
  font-size: 14px;
  line-height: 20px;
`;

export const ParagraphBigText = css`
  font-size: 18px;
  line-height: 28px;
`;

export const ParagraphSmall = css`
  ${ParagraphBase}
  ${ParagraphChildren}
  ${ParagraphSmallText}
`;

export const ParagraphBig = css`
  ${ParagraphBase}
  ${ParagraphChildren}
  ${ParagraphBigText}
`;

export const ParagraphChartText = css`
  font-size: 12px;
  line-height: 18px;
`;

export const ParagraphChart = css`
  ${ParagraphBase}
  ${ParagraphChildren}
  ${ParagraphChartText}
`;
