import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Background = styled('div')<{ withPadding?: boolean }>(({ theme, withPadding }) => ({
  background: theme.palette.background.page.product,
  padding: withPadding ? `${theme.spacing(10)} ${theme.spacing(5)}` : '0',
}));

export const ChartContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  marginTop: 0,
  borderRadius: '8px',
  background: theme.palette.background.default,
  zIndex: '1000',

  [theme.breakpoints.up('lg')]: {
    position: 'static',
    marginTop: theme.spacing(8),
  },
}));

export const ControlsHolder = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: theme.spacing(5),

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    padding: 0,
  },
}));

export const Disclaimer = styled(Typography)(({ theme }) => ({
  display: 'block',
  padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(8)}`,
  background: theme.palette.background.page.product,

  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(5)} 0 0 0`,
  },
}));

export const StrategyDesc = styled(Typography)(({ theme }) => ({
  display: 'block',
  margin: `${theme.spacing(4)} 0`,
  textAlign: 'center',

  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));
