import React, { FunctionComponent } from 'react';

import { Paragraph } from '../../content/text';

import { Image, PhotoCard, PlusButton, TextContainer } from './MarketingPhotoCard.styles';

import { MarketingPhotoCardProps } from './MarketingPhotoCard.model';

export const MarketingPhotoCard: FunctionComponent<MarketingPhotoCardProps> = ({
  small,
  imgUrl,
  title,
  subTitle,
  onClick,
}) => {
  return (
    <PhotoCard small={small} onClick={onClick}>
      <Image src={imgUrl} small={small} />
      <TextContainer small={small}>
        <Paragraph semiBold>{title}</Paragraph>
        <Paragraph chart>{subTitle}</Paragraph>
      </TextContainer>
      {onClick && <PlusButton small={small}>+</PlusButton>}
    </PhotoCard>
  );
};
