import { styled } from '@mui/material/styles';

export const MobileOnly = styled('div')(({ theme }) => ({
  display: 'block',

  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const DesktopOnly = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}));
