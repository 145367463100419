import React from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds, useTestIds } = genTestIds(['icon', 'label']);

export type ValidationIndicatorConfig = {
  invalid: {
    color: string;
    icon: string;
  };
  valid: {
    color: string;
    icon: string;
  };
};

export type ValidationIndicatorIconProps = {
  touched: boolean;
  icon?: string;
  color?: string;
};

export type ValidationIndicatorProps = {
  touched: boolean;
  valid: boolean;
  config?: ValidationIndicatorConfig;
  children: React.ReactNode;
};
