import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Heading, Paragraph } from '../../content/text';
/* Assets */
import AvatarFinancialLogo from './assets/logo 1.svg';

/* ============================
 *    Main copy block module
 * ============================ */

interface CopyBlockProps {
  title: string;
  subtitle: string;
  description: string;
}

export const CopyBlock: FunctionComponent<CopyBlockProps> = ({ title, subtitle, description }) => {
  return (
    <MainCopySection>
      {/* heading */}
      <Headline type={3}>{title}</Headline>
      <SubTitle semiBold>{subtitle}</SubTitle>
      {/* Description */}
      <MainTextContainer>
        <MainBodyCopy normal>{description}</MainBodyCopy>
        <MainBodyImg src={AvatarFinancialLogo} />
      </MainTextContainer>
    </MainCopySection>
  );
};

/* ===== Layout UI elements ===== */

const MainTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  justify-content: space-between;
  ${media.desktop`
    align-items: flex-end;
  `}
`;

const MainCopySection = styled.section`
  box-sizing: border-box;
  ${media.desktop`
    flex-basis:50%;
    padding-right:50px;
  `}
`;

/* ===== Component UI elements ===== */

const MainBodyImg = styled.img`
  width: 100px;
`;

/* ===== Type styles ==== */

const Headline = styled(Heading)`
  margin-bottom: 20px;
`;

const SubTitle = styled(Paragraph)`
  margin-bottom: 20px;
  ${media.mobile`
    display:none;
  `}
  ${media.desktop`
    display:block;
  `}
`;

const MainBodyCopy = styled(Paragraph)`
  margin-right: 20px;
  ${media.tablet`
    max-width:569px;
  `}
`;
