import {
  IdentityDocumentType,
  InvestorEntityActionCode,
  InvestorEntityStepVerificationStatus,
  InvestorEntityTypeName,
  InvestorEntityTypeValue,
} from '../../enums';
import { InvestorEntityStepProvider } from '../../types';

import { InvestorEntityStatus } from './investor-entity-status.model';

const investorEntity1 = {
  id: 349913,
  name: '20193 Wealth Tr Cody Wisozk IRA account',
  type: 'TRADITIONAL_IRA',
  typeDescription: 'Traditional YSIRA',
  status: {
    userId: 4,
    id: 349913,
  },
};

const investorEntity2 = {
  id: 1,
  name: 'Delpha Towne',
  type: 'INDIVIDUAL',
  typeDescription: 'Individual',
  status: {
    userId: 4,
    id: 1,
  },
};

const investorEntity3 = {
  id: 346822,
  name: 'Entity Tester',
  type: 'LLC',
  typeDescription: 'Limited Liability Company',
  status: {
    userId: 4,
    id: 346822,
  },
};

export const investorEntityStatus: InvestorEntityStatus = {
  userId: 365647,
  investorEntityId: 303150,
  investorEntityStatusId: 1,
  readyToInvest: false,
  setupComplete: false,
  accreditedSignUp: true,
  type: {
    identityDocumentType: IdentityDocumentType.SSN,
    iraAccountType: null,
    entityType: null,
    name: InvestorEntityTypeName.INDIVIDUAL,
    value: InvestorEntityTypeValue.ENTT,
  },
  identity: {
    status: InvestorEntityStepVerificationStatus.CONFIRMED,
    statusDisplay: 'Confirmed',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.FOOTPRINT,
  },
  accreditation: {
    status: InvestorEntityStepVerificationStatus.MISSING,
    statusDisplay: 'Unverified',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.FOOTPRINT,
  },
  bankAccounts: {
    status: InvestorEntityStepVerificationStatus.MISSING,
    statusDisplay: 'None',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.PLAID,
  },
  investorEntityAction: {
    investorEntityId: null,
    code: InvestorEntityActionCode.LINK_BANK_ACCOUNT,
    status: null,
    nextStep: 'Link a Bank Account',
    priority: 100,
  },
  id: 303150,
  nextStep: 'Link a Bank Account',
  provider: {
    status: InvestorEntityStepVerificationStatus.CONFIRMED,
    statusDisplay: 'Confirmed',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.PLAID,
  },
  allPendingActions: [InvestorEntityActionCode.SET_KYC_1],
};

export const allInvestorEntityStatusApiResponse = {
  success: true,
  investorEntity: [investorEntity1, investorEntity2, investorEntity3],
};

export const allInvestorEntityStatusTransformResult = {
  [investorEntity1.id]: {
    ...investorEntity1.status,
    id: investorEntity1.id,
    type: investorEntity1.type,
    typeDescription: investorEntity1.typeDescription,
  },
  [investorEntity2.id]: {
    ...investorEntity2.status,
    id: investorEntity2.id,
    type: investorEntity2.type,
    typeDescription: investorEntity2.typeDescription,
  },
  [investorEntity3.id]: {
    ...investorEntity3.status,
    id: investorEntity3.id,
    type: investorEntity3.type,
    typeDescription: investorEntity3.typeDescription,
  },
};
