import styled from 'styled-components';
import { rgba } from 'polished';
import { media } from '../../themes';
import { ModalHeaderProps, ModalsProps } from './Modal.model';

export const ModalHeader = styled.div<ModalHeaderProps>`
  text-align: right;
  z-index: 2;
  position: relative;
  ${props => props.hideXMark && `display: none`};
`;

export const ModalBackground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: ${props => rgba(props.theme.colors.background_inverse, 0.8)};
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  ${media.tablet`
    padding: 1rem;
  `}
`;

export const ModalBody = styled.div<ModalsProps>`
  background: white;
  width: ${props => (props.width ? `${props.width} !important` : '100%')};
  height: ${props => (props.height ? `${props.height} !important` : 'auto')};
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  ${media.tablet<ModalsProps>`
    ${props => `max-width: ${props.width ? props.width : '750px'}`}
  `};
`;

export const ModalContent = styled.div`
  position: relative;
  flex: 1;
`;

export const Icon = styled.img`
  cursor: pointer;
`;
