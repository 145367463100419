import styled from 'styled-components';

import { media } from '../../themes/media';
import { Heading } from '../../content';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  width: 185px;
  height: 148px;

  ${media.desktop`
    width: 300px;
    height: 240px;
  `}
`;

export const SHeading = styled(Heading)`
  padding: ${({ theme }) => `${theme.spacing.m} 0 ${theme.spacing.sm}`};
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.spacing.sm};
  bottom: ${props => props.theme.spacing.m};
  width: 90%;

  ${media.desktop`
    position: initial;
    flex-direction: row;
    justify-content: center;
    margin-top: ${props => props.theme.spacing.xl};
    column-gap: ${props => props.theme.spacing.sm};
  `}
`;
