import styled from 'styled-components';
import { friendlyMedia } from '../../themes';

export const ModalContainer = styled.div`
  padding: ${props => props.theme.spacing.m};
  background: ${props => props.theme.colors.background_page};

  ${friendlyMedia.mobile`
    min-height: 100vh;
  `}

  ${friendlyMedia.tablet`
    width: 500px !important;
    padding: ${props => props.theme.spacing.xl};
  `}

  ${friendlyMedia.desktop`
    width: 750px !important;
    padding: ${props => props.theme.spacing.xl} ${props => props.theme.spacing['4xl']};
  `}
`;
