import styled from 'styled-components';

import { media } from '../../../../themes/media';
import { Paragraph } from '../../../../content/text';

import { LineProps } from '../../ProgressBar.model';

export const StatusContainer = styled.div<LineProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: unset;
  margin-bottom: ${props => (props.lastElement ? 0 : `24px;`)};
  height: 24px;
  &:after {
    content: '';
    display: ${props => (props.lastElement ? 'none' : 'block')};
    position: absolute;
    height: calc(24px * 2);
    left: calc(24px / 2);
    border-left: 2px solid ${props => props.theme.colors[props.color]};
    margin-top: 24px;
  }
  ${media.tablet<LineProps>`
    flex-direction: column;
    width: ${props => `calc(100% /${props.numOfElements})`};
    &:after {
        content: none;
    }
  `};
`;

export const CheckboxContainer = styled.div`
  &:after {
    content: none;
  }
  ${media.tablet<LineProps>`
    &:after {
        content: '';
        display: ${props => (props.lastElement ? 'none' : 'block')};
        position: absolute;
        width: ${props => `calc(100% /${props.numOfElements})`};
        top: calc(32px / 2);
        border-bottom: 2px solid ${props => props.theme.colors[props.color]};
    }
  `};
`;

export const Checkbox = styled.img`
  position: relative;
  z-index: 1;
  height: 24px;
  margin-right: 17px;
  ${media.tablet`
    height: unset;
    margin-right: 0;
  `};
`;

export const Status = styled(Paragraph)`
  margin-top: 0;
  max-width: unset;
  text-align: unset;
  ${media.tablet`
    margin-top: 10px;
    max-width: 184px;
    text-align: center;
  `};
`;
