import styled from 'styled-components';
import { sizeCss } from '../../utils/sizes';
import { getConfig } from './grid/common';

export const DemoBox = styled.div<{ height?: number | 'auto'; background?: string }>`
  width: 100%;
  position: relative;
  height: ${props => (props.height === 'auto' ? 'auto' : `${props.height || 100}px`)};
  background: ${props => props.theme.colors[`background_${props.background || 'default'}`]};
  padding: ${props => props.theme.spacing.s};

  ${props =>
    sizeCss(
      ({ columnSize }) => {
        return `
          &::after {
            content: "${columnSize}";
            position: absolute;
            background: white;
            box-shadow: 0 0 4px;
            border-radius: 50%;
            text-align: center;
            line-height: 21px;
            font-weight: bold;
            font-size: 12px;
            width: 20px;
            height: 20px;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
          }
        `;
      },
      { columnSize: getConfig(props.theme, 'size') }
    )}
`;
