import { HttpMethods } from '../../enums/http';
import { PostAnswersParams, PostAnswerData } from '../../types';
import { Tags, restApi } from '../common';
import {
  GetQuizAnswersByQuizIdRequest,
  GetQuizAnswersByUserIdRequest,
  GetQuizAnswersByInvestorEntityIdRequest,
  GetQuizAnswersResponse,
} from './quiz.model';
import { updateQuizAnswersQueries, updateQuizAnswerQueries } from './quiz.utils';

export const quizApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.Quiz, Tags.ManagedPortfolioEligibility],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getQuizAnswersByUserId = builder.query<
        GetQuizAnswersResponse,
        GetQuizAnswersByUserIdRequest
      >({
        query: params => {
          return {
            url: `/api/quiz/user/${params.userId}`,
            method: HttpMethods.Get,
            params: {
              quizKey: params?.quizKey,
              questionKeys: params?.questionKeys,
            },
          };
        },
        providesTags: [Tags.Quiz],
      });

      const getQuizAnswersByInvestorEntityId = builder.query<
        GetQuizAnswersResponse,
        GetQuizAnswersByInvestorEntityIdRequest
      >({
        query: params => {
          return {
            url: `/api/quiz/user/investor-entity/${params.investorEntityId}`,
            method: HttpMethods.Get,
            params: {
              quizKey: params?.quizKey,
              questionKeys: params?.questionKeys,
            },
          };
        },
        providesTags: [Tags.Quiz],
      });

      const getQuizAnswersByQuizId = builder.query<
        GetQuizAnswersResponse,
        GetQuizAnswersByQuizIdRequest
      >({
        query: params => {
          return {
            url: `/api/quiz/${params.quizId}`,
            method: HttpMethods.Get,
            params: {
              quizKey: params?.quizKey,
              questionKeys: params?.questionKeys,
            },
          };
        },
        providesTags: [Tags.Quiz],
      });

      const postAnswers = builder.mutation<{}, { answers: PostAnswersParams[] }>({
        query: ({ answers }) => {
          return {
            url: `/api/quiz/answers`,
            method: HttpMethods.Post,
            data: { answers },
          };
        },
        // Using onQueryStarted for optimistic cache updates to compensate for eventual consistency on the backend.
        // These definitions are verbose and not testable in this form, but I don't see
        // a way to simplify them or make them more generic.
        async onQueryStarted({ answers }, { dispatch, queryFulfilled }) {
          const patchResults = updateQuizAnswersQueries(answers, dispatch);

          try {
            await queryFulfilled;
          } catch {
            patchResults.forEach(patchResult => patchResult.undo());
          }
        },
        transformResponse: result => result.data,
        invalidatesTags: [Tags.ManagedPortfolioEligibility],
      });

      const postAnswer = builder.mutation<{}, PostAnswerData>({
        query: answer => {
          return {
            url: `/api/quiz/answer`,
            method: HttpMethods.Post,
            data: answer,
          };
        },
        async onQueryStarted(answer, { dispatch, queryFulfilled }) {
          const patchResults = updateQuizAnswerQueries(answer, dispatch);
          try {
            await queryFulfilled;
          } catch {
            patchResults.forEach(patchResult => patchResult.undo());
          }
        },
        invalidatesTags: [Tags.ManagedPortfolioEligibility],
      });

      return {
        getQuizAnswersByUserId,
        getQuizAnswersByInvestorEntityId,
        getQuizAnswersByQuizId,
        postAnswers,
        postAnswer,
      };
    },
  });
