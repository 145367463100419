import React, { FunctionComponent, useState, useMemo } from 'react';
import { useMimicPassword } from 'react-mimic-password-hook';
import { TextInput, TextInputProps } from './TextInput';
import { MaskTextInput } from './MaskTextInput';
import { ShowHideToggle } from './helpers';
import { withFormikWeb } from '../common';

export interface DocumentTextInputProps
  extends Omit<TextInputProps, 'type' | 'suffix' | 'defaultValue'> {
  defaultValue?: string | number;
  format: string;
}

export const DocumentTextInput: FunctionComponent<DocumentTextInputProps> = ({
  name,
  label,
  onChange,
  placeholder,
  format,
  ...props
}) => {
  const maxLength = useMemo(() => format.split('#').length - 1, [format]);
  const formatPlaceholder = useMemo(() => format.replace(/#/g, 'X'), [format]);
  const [showDocument, setShowDocument] = useState(false);

  const [value, presentation, onPasswordChange] = useMimicPassword({
    mode: 'persymbol',
    handleChange: onChange,
  });

  return !showDocument ? (
    <TextInput
      {...props}
      name={name}
      label={label}
      value={presentation}
      onChange={(_value, e) => onPasswordChange(e)}
      onKeyPress={e => {
        const isNumber = /^[0-9]$/i.test(e.key);
        if (!isNumber) {
          e.preventDefault();
        }
      }}
      autoComplete="new-password"
      type="text"
      inputMode="numeric"
      placeholder={placeholder || formatPlaceholder}
      maxLength={maxLength}
      suffix={
        <ShowHideToggle name={label?.toString()} show={showDocument} onChange={setShowDocument} />
      }
    />
  ) : (
    <MaskTextInput
      {...props}
      name={name}
      label={label}
      value={value}
      onChange={(_value, e) =>
        onPasswordChange({
          target: {
            ...e.target,
            setSelectionRange: () => null,
          },
        } as React.ChangeEvent<HTMLInputElement>)
      }
      autoComplete="new-password"
      type="text"
      mask="_"
      placeholder={placeholder || formatPlaceholder}
      format={format}
      suffix={
        <ShowHideToggle name={label?.toString()} show={showDocument} onChange={setShowDocument} />
      }
    />
  );
};

export const FormDocumentTextInput = withFormikWeb(DocumentTextInput);
