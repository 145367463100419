export interface TieredPricingContent {
  InvestmentAmount: string;
  FeeReduction: string;
  Title: string;
  DescriptionBefore: string;
  DescriptionAfter: string;
  LearnMore: string;
  LearnMoreLink: string;
  AndAbove: string;
  cardTitle: string;
  ModalTitle: string;
  ModalIntro: string;
  ModalInfo: string;
  ModalDisclaimer: string;
  AboutReducedFees: string;
}

export const tieredPricingContent: TieredPricingContent = {
  InvestmentAmount: 'Investment amount',
  FeeReduction: 'Fee reduction',
  Title: 'Tiered Pricing',
  DescriptionBefore:
    'Invest more to increase your target returns with lower management fees. Tiered Pricing applies to the sum of all active investments in this offering, including previous investments.',
  DescriptionAfter:
    'This amount is not eligible for a reduced management fee. Invest more to increase your target net returns with Tiered Pricing.',
  LearnMore: 'Learn more',
  LearnMoreLink: '/',
  AndAbove: '& Above',
  cardTitle: 'Tiered pricing available',
  ModalTitle: 'Tiered Pricing',
  ModalIntro: 'Invest more to increase your target returns with lower management fees.',
  ModalInfo:
    'By meeting certain investment thresholds, you can unlock a reduced management fee on this opportunity.',
  ModalDisclaimer:
    "The management fee discount will be to applied to each investor account that meets the threshold for any given discount tier for a particular investment offering. The discount will only be applied to investments in this particular investment offering at this time and may be discontinued in the future. The discount may not be offered for other investment offerings. For example, if the management fee were 1.50% on a given offering, the management fee reduction for Investor A's $250,000 investment in this hypothetical offering would be 0.25%, resulting in an effective management fee of 1.25% applied to Investor A's entire investment in this hypothetical offering. The discount will only be applied to investments in this particular investment offering at this time and may be discontinued in the future. The discount may not be offered for other investment offerings.",
  AboutReducedFees: 'about reduced management fees.',
};

export interface TierData {
  amount: [number, number];
  reduction: string;
  type: TieredPricingItemType;
}

export enum TieredPricingItemType {
  IDLE = 'idle',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const tierData: TierData[] = [
  {
    amount: [0, 99999],
    reduction: '--',
    type: TieredPricingItemType.IDLE,
  },
  {
    amount: [100000, 249999],
    reduction: '-0.10%',
    type: TieredPricingItemType.IDLE,
  },
  {
    amount: [250000, Infinity],
    reduction: '-0.25%',
    type: TieredPricingItemType.IDLE,
  },
];
