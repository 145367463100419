import styled from 'styled-components';

import { media } from '../../themes';
import { Container } from '../../layout';

import { MarketingSideCarouselContentProps } from './MarketingSideCarouselContent.model';

export const Wrapper = styled(Container)<Pick<MarketingSideCarouselContentProps, 'reverse'>>`
  display: flex;
  overflow: hidden;
  justify-content: space-evenly;
  flex-direction: column;

  ${media.desktop<Pick<MarketingSideCarouselContentProps, 'reverse'>>`
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  `}
`;

export const CarouselContainer = styled.div`
  width: 50%;
`;

export const TextWrapper = styled.div`
  margin-top: 24px;
  max-width: 440px;
  z-index: 5;

  ${media.desktop`
    margin-top: 0;
  `}
`;
