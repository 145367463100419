import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { DocumentNode } from 'graphql';
import { GraphQLClient, ClientError } from 'graphql-request';
import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

import { BASE_CONFIG, BaseQueryConfig } from './base-config';

type P = Parameters<GraphQLClient['request']>[0];
export type Document = P['document'];
export type Variables = P['variables'];
export type RequestHeaders = P['requestHeaders'];
export type Response = ClientError['response'];

export const client = new GraphQLClient('/api/api-aggregator/graphql', {
  fetch: buildAxiosFetch(axios),
});

type GraphQLParams = { document: string | DocumentNode; variables?: any };
type GraphQLErrors = Response['errors'];
type GraphQLClientError = Pick<ClientError, 'name' | 'message' | 'stack'>;
type GraphQLClientErrorMeta = Partial<Pick<ClientError, 'request' | 'response'>>;

type PendingRequestConfig = {
  params: GraphQLParams;
  resolve: (value: any) => void; // TODO: TYPE any
  reject: (reason?: any) => void;
};

export let pendingTimeout: number | null | any = null;
export let pendingRequests: PendingRequestConfig[] = [];

const processPendingRequests = async () => {
  const processingRequests = pendingRequests;
  pendingRequests = [];
  pendingTimeout = null;

  try {
    const responses = await client.batchRequests<Response[]>(
      processingRequests.map(({ params }) => params)
    );

    processingRequests.forEach(({ resolve }, index) => {
      const { data, errors } = responses[index];

      if (errors) {
        resolve({ error: errors });
      } else {
        resolve({ data });
      }
    });
  } catch (error) {
    if (error instanceof ClientError) {
      const { name, message, stack, request, response } = error;
      const payload = {
        error: { name, message, stack },
        meta: { request, response },
      };

      processingRequests.forEach(({ resolve }) => resolve(payload));
    }
    throw error;
  }
};

export const baseGraphQLQuery = (): BaseQueryFn<
  GraphQLParams,
  unknown,
  GraphQLErrors | GraphQLClientError,
  GraphQLClientErrorMeta
> => {
  return async params => {
    try {
      return new Promise((resolve, reject) => {
        pendingRequests.push({ params, resolve, reject });
        if (!pendingTimeout) {
          pendingTimeout = setTimeout(processPendingRequests);
        }
      });
    } catch (error) {
      throw error;
    }
  };
};

export const BASE_GRAPHQL_CONFIG: BaseQueryConfig = {
  ...BASE_CONFIG,
  baseQuery: baseGraphQLQuery(),
};
