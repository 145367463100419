import { InvestmentPerformanceStatus } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
import { useTheme } from 'styled-components';

import ArrowIcon from './assets/arrow_forward.svg';
import SuccessIcon from './assets/success.svg';
import { Theme } from '../../types';

export const { useTestIds } = genTestIds(['label', 'icon']);

export interface StatusTagProps {
  label: string;
  small?: boolean;
  showIcon?: boolean;
  status: InvestmentPerformanceStatus;
  testId: string;
}

export interface TagLabelProps extends Omit<StatusTagProps, 'status' | 'label' | 'testId'> {}

export const StatusTagInfo = (status: InvestmentPerformanceStatus) => {
  const theme = useTheme() as Theme;

  switch (status) {
    case InvestmentPerformanceStatus.Active:
      return {
        color: theme.colors.background_success,
        icon: SuccessIcon,
      };
    case InvestmentPerformanceStatus.Warning:
      return {
        color: theme.colors.background_warning,
        icon: ArrowIcon,
      };
    case InvestmentPerformanceStatus.Default:
      return {
        color: theme.colors.background_error,
        icon: ArrowIcon,
      };
    case InvestmentPerformanceStatus.Pending:
    default:
      return {
        color: theme.colors.input_disabled_background,
        icon: ArrowIcon,
      };
  }
};
