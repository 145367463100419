import React, { FunctionComponent } from 'react';
import { TabListContainer, TabListItem } from './TabList.style';
import { TabListProps, TabListTestIds as testIds } from './TabList.model';

export const TabList: FunctionComponent<TabListProps> = ({
  activeIndex,
  onChange,
  children,
  vertical,
  testId,
}) => {
  const handleTabChipClick = (event: React.SyntheticEvent, index: number) =>
    onChange && onChange(event, index);
  return (
    <TabListContainer vertical={vertical} data-cy={`${testId ? testId : testIds.container}`}>
      {children?.map((child, index) => {
        if (child) {
          return (
            <TabListItem vertical={vertical} key={index}>
              {React.cloneElement(child as React.ReactElement, {
                isActive: index === activeIndex,
                onClick: (event: React.SyntheticEvent) => handleTabChipClick(event, index),
              })}
            </TabListItem>
          );
        }
      })}
    </TabListContainer>
  );
};
