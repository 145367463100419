import type { Components, CssVarsTheme } from '@mui/material';

export const MuiButton: Components<CssVarsTheme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    size: 'large',
  },
  styleOverrides: {
    root: ({ theme, size }) => ({
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.Button?.contained.primary?.color,
            '&:hover': {
              backgroundColor: theme.palette.Button?.contained?.primary?.hover,
            },
            backgroundColor: theme.palette.Button?.contained?.primary?.background,
          }),
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.palette.Button?.contained.secondary?.color,
            '&:hover': {
              backgroundColor: theme.palette.Button?.contained?.secondary?.hover,
            },
            backgroundColor: theme.palette.Button?.contained?.secondary?.background,
          }),
        },
        {
          props: { variant: 'outlined', color: 'tertiary' },
          style: ({ theme }) => ({
            background: theme.palette.Button.outlined.tertiary?.background,
            border: `1px solid ${theme.palette.Button.outlined.tertiary?.border}`,
            color: theme.palette.Button.outlined.tertiary?.color,

            '&:hover': {
              background: theme.palette.Button.outlined.tertiary?.hover,
            },
          }),
        },
        {
          props: { variant: 'marketing' }, // FIXME this should be color brand / marketing. variant should remain as is.
          style: ({ theme }) => ({
            backgroundColor: theme.palette.Button?.marketing?.primary?.background,
          }),
        },
      ],

      ...(size === 'large' && {
        padding: theme.spacing(2, 4),
      }),

      textTransform: 'none',
      borderRadius: '15rem',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
  },
};
