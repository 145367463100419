import styled from 'styled-components';

export const OfferingCardBaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.sm};
  height: 100%;
  max-height: 385px;

  overflow: hidden;
  width: 100%;
`;

export const TopContainer = styled.div`
  max-height: 140px;
  overflow: hidden;
  width: 100%;
`;

export const MainContainer = styled.div`
  max-height: 115px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  max-height: 92px;
  width: 100%;
`;
