import { createGlobalStyle, ThemeProps } from 'styled-components';
import { Theme } from '../types';

export const SpacingTokens = createGlobalStyle`
  :root {
    ${({ theme }: ThemeProps<Theme>) => `
      --y-spacing-xxs: ${theme.spacing.xxs};
      --y-spacing-xs: ${theme.spacing.xs};
      --y-spacing-s: ${theme.spacing.s};
      --y-spacing-sm: ${theme.spacing.sm};
      --y-spacing-m: ${theme.spacing.m};
      --y-spacing-ml: ${theme.spacing.ml};
      --y-spacing-l: ${theme.spacing.l};
      --y-spacing-xl: ${theme.spacing.xl};
      --y-spacing-2xl: ${theme.spacing['2xl']};
      --y-spacing-3xl: ${theme.spacing['3xl']};
      --y-spacing-4xl: ${theme.spacing['4xl']};
      --y-spacing-5xl: ${theme.spacing['5xl']};
    `}
  }
`;
