import { User2FAMethod } from '../../enums';
import { User2FAStatus } from '../../types';

export const user2FAStatus: User2FAStatus = {
  available: true,
  enabled: true,
  method: User2FAMethod.TEXT_MESSAGE,
  phone: '+19172222222',
  emailAddress: 'user@domain.com',
  emailVerified: true,
};
