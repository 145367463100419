import assetsIcon from 'images/home/about-fund-assets-icon.svg';
import distributionIcon from 'images/home/about-fund-distribution-icon.svg';
import liquidityIcon from 'images/home/about-fund-liquidity-icon.svg';

interface aboutFundDataProps {
  img: string;
  title: string;
  description: string;
  modalId: string;
}

export const aboutFundData: aboutFundDataProps[] = [
  {
    img: assetsIcon,
    title: 'Broad range of assets',
    description:
      'The Fund includes 20+ investments spanning alternative asset classes such as real estate, private credit, and more.',
    modalId: '',
  },
  {
    img: distributionIcon,
    title: 'Quarterly distributions',
    description:
      'The Fund targets paying a quarterly distribution to investors. All targeted payments have been made to date.',
    modalId: 'net-annualized-yield-modal',
  },
  {
    img: liquidityIcon,
    title: 'Liquidity opportunities',
    description:
      'Investors can let their returns compound while taking advantage of expected quarterly liquidity windows as needed.',
    modalId: 'liquidity-modal',
  },
];
