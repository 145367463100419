import { styled } from '@mui/material/styles';

import { Container } from '../container';
import { PageContainerBackgrounds, PageContainerStyleProps } from './PageContainer.model';

const getBackgroundColor = ({ background, light, theme }: PageContainerStyleProps) => {
  /**
   * Remove line 10 and 22 (if condition)
   * when `light` prop is no longer used.
   */
  if (background) {
    switch (background) {
      case PageContainerBackgrounds.Dark:
        return theme?.palette.background.page.product;

      case PageContainerBackgrounds.Light:
        return theme?.palette.background.default;

      case PageContainerBackgrounds.None:
      default:
        return 'none';
    }
  }

  /**
   * Deprecated. Used for backward compatibility.
   * Remove when `light` prop is no longer used.
   */
  return light ? theme?.palette.background.default : theme?.palette.background.page.product;
};

export const PageContainerWrapper = styled('div')<PageContainerStyleProps>(
  ({ background, light, theme }) => ({
    background: getBackgroundColor({ background, light, theme }),
  })
);

export const PageContainerContent = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  paddingTop: theme.spacing(9),
  paddingBottom: theme.spacing(9),

  '&:last-child': {
    paddingBottom: theme.spacing(16),
  },
}));
