import React, { FunctionComponent } from 'react';

import { TextBlock } from '../../sections/text-block-component';

import { MarketingHeroBannerProps, useTestIds } from './MarketingHeroBanner.model';

import {
  Background,
  ImageContainer,
  SContainer,
  TextBlockContainer,
} from './MarketingHeroBanner.style';

export const MarketingHeroBanner: FunctionComponent<MarketingHeroBannerProps> = ({
  id,
  label,
  heading,
  align = 'start',
  image,
  content,
  hideMediaOnMobile,
  inverse,
  button,
  background,
  reverse,
  largeWidth,
  mediaFramed,
  stats,
  ...props
}) => {
  const testIds = useTestIds(props);

  return (
    <Background background={background} inverse={inverse} {...testIds.base.attr}>
      <SContainer reverse={reverse} image={image} mediaFramed={mediaFramed}>
        <TextBlockContainer largeWidth={largeWidth}>
          <TextBlock
            id={id}
            headingType={1}
            label={label}
            heading={heading}
            content={content}
            align={align}
            inverse={!inverse}
            button={button}
            stats={stats}
          />
        </TextBlockContainer>
        {image ? (
          <ImageContainer hideMediaOnMobile={hideMediaOnMobile} mediaFramed={mediaFramed}>
            {image}
          </ImageContainer>
        ) : null}
      </SContainer>
    </Background>
  );
};
