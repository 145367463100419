import React, { useState } from 'react';
import styled from 'styled-components';

import { ParagraphLink, Paragraph } from '../../content/text';
import { media } from '../../themes';
import axios from 'axios';

import { IconPicker } from '../../utils/icon-picker';
import { LightBox } from '../../utils/light-box';
import playbutton from '../../assets/graphics/case-specific/play_button.svg';

export interface ParagraphBoxSectionProps {
  section: Section;
}

type Section = {
  charLimit: number;
  content: string[];
  iconName?: string;
  interactiveMap?: string;
  imageCaption?: string;
  image?: string;
  subtitle?: string;
  title?: string;
  video?: string;
};

interface StyledComponentProps {
  className?: string;
}

interface TitleProps extends StyledComponentProps {
  icon?: string;
}

interface ParagraphBodyProps extends StyledComponentProps {
  fullWidth?: boolean;
}

interface ImageThumbnailProps {
  image?: string;
}

interface ReadMoreLinkProps extends StyledComponentProps {
  onClick: Function;
}

const characterLimitHit = (charLimit: number, arr?: string[]) => {
  if (arr && arr.join().length > charLimit) {
    return true;
  } else return false;
};

export const ParagraphBoxSection = ({ section }: ParagraphBoxSectionProps) => {
  const {
      charLimit,
      content,
      iconName,
      interactiveMap,
      imageCaption,
      image,
      subtitle,
      title,
      video,
    } = section,
    [showMore, setShowMore] = useState(false),
    [showVideoModal, setShowVideoModal] = useState(false),
    [showImageModal, setShowImageModal] = useState(false),
    [showMapModal, setShowMapModal] = useState(false),
    [videoThumbnail, setVideoThumbnail] = useState('');

  const getVideoThumbnail = (videoURL: string) => {
    const urlParams = videoURL.split('/'),
      videoId = urlParams[urlParams.length - 1];

    videoURL &&
      axios.get(`//vimeo.com/api/v2/video/${videoId}.json`).then(res => {
        setVideoThumbnail(res?.data[0]?.thumbnail_large);
      });
  };

  video && getVideoThumbnail(video);

  let truncatedContent: string[] = [];

  if (characterLimitHit(charLimit, content)) {
    let charLimitReached = 0;
    content.forEach(paragraph => {
      let truncatedContentLength = truncatedContent.join().length;
      if (truncatedContentLength >= charLimit) {
        return;
      } else if (truncatedContentLength + paragraph.length < charLimit) {
        truncatedContent.push(paragraph);
      } else if (charLimitReached < 1) {
        let remainingChar = charLimit - truncatedContentLength;
        truncatedContent.push(
          paragraph.substr(0, paragraph.lastIndexOf(' ', remainingChar - 3)) + '...'
        );
        charLimitReached = 1;
        return;
      }
    });
  } else {
    truncatedContent = content;
  }

  let fullWidth = false;

  if (!image && !interactiveMap && !video) {
    fullWidth = true;
  }

  return (
    <Wrapper>
      <InnerWrapper>
        <TitleContainer>
          {iconName && <IconPicker className="paragraph-box-icon" iconName={iconName} />}
          {title && (
            <Title className="paragraph-box-title" icon={iconName} semiBold>
              <span
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Title>
          )}
        </TitleContainer>
        {subtitle && (
          <SubTitle className="paragraph-box-subtitle" secondary>
            <span
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </SubTitle>
        )}
        <ContentWrapper>
          <TextWrapper>
            {!showMore &&
              truncatedContent &&
              truncatedContent.map((paragraph, idx) => (
                <ParagraphBody
                  className="paragraph-box-paragraph-body"
                  fullWidth={fullWidth}
                  small
                  key={`${title}-${idx}`}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: paragraph,
                    }}
                  />
                </ParagraphBody>
              ))}
            {characterLimitHit(charLimit, content) && !showMore && (
              <ReadMoreLink
                className="paragraph-box-read-more-link"
                small
                onClick={() => setShowMore(true)}
              >
                Read more
              </ReadMoreLink>
            )}
            {showMore &&
              content.map((paragraph, idx) => (
                <ParagraphBody
                  className="paragraph-box-read-more-paragraph-body"
                  fullWidth={fullWidth}
                  small
                  key={`${title}-${idx}`}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: paragraph,
                    }}
                  />
                </ParagraphBody>
              ))}
            {showMore && (
              <ReadMoreLink
                className="paragraph-box-read-less-link"
                small
                onClick={() => setShowMore(false)}
              >
                Show less
              </ReadMoreLink>
            )}
          </TextWrapper>
          {video && !image && !interactiveMap && (
            <VideoWrapper>
              <VideoOverlayTrigger
                className="paragraph-box-video-trigger"
                onClick={() => setShowVideoModal(true)}
              >
                <PlayButton src={playbutton} />
              </VideoOverlayTrigger>
              <VideoThumbnail className="paragraph-box-video-thumbnail" src={videoThumbnail} />
            </VideoWrapper>
          )}
          {image && !video && !interactiveMap && (
            <ImageThumbnail
              className="paragraph-box-image-thumbnail"
              src={image}
              onClick={() => setShowImageModal(true)}
            />
          )}
          {interactiveMap && !video && !image && (
            <MapWrapper className="paragraph-box-map-trigger" onClick={() => setShowMapModal(true)}>
              <MapThumbnail className="paragraph-box-map-thumbnail" src={interactiveMap} />
            </MapWrapper>
          )}
        </ContentWrapper>
      </InnerWrapper>

      {showVideoModal && (
        <StyledModal caption={imageCaption} clickClose={() => setShowVideoModal(false)}>
          <ModalVideoIframe
            className="paragraph-box-video-modal"
            src={`${video}?autoplay=1`}
            allow="autoplay"
            frameBorder="0"
          />
        </StyledModal>
      )}

      {showImageModal && (
        <StyledModal caption={imageCaption} clickClose={() => setShowImageModal(false)}>
          <ModalImage className="paragraph-box-image-modal" src={image} />
        </StyledModal>
      )}

      {showMapModal && (
        <StyledModal caption={imageCaption} clickClose={() => setShowMapModal(false)}>
          <ModalMapIframe className="paragraph-box-map-modal" src={interactiveMap} />
        </StyledModal>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const InnerWrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px 20px 40px;

  ${media.tablet`
    padding: 20px 35px 40px;
  `};

  ${media.large`
    padding: 20px 0 30px;
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `};
`;

const TextWrapper = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Paragraph)<TitleProps>`
  margin-bottom: 20px;
  margin-left: ${props => props.icon && `20px`};
`;

const SubTitle = styled(Paragraph)<StyledComponentProps>`
  margin: -10px 0 10px;
`;

const ParagraphBody = styled(Paragraph)<ParagraphBodyProps>`
  margin-bottom: 20px;

  ${media.desktop<ParagraphBodyProps>`
    max-width: ${props => !props.fullWidth && `580px`};
  `};
`;

const ReadMoreLink = styled(Paragraph)<ReadMoreLinkProps>`
  ${ParagraphLink};
  width: fit-content;
  margin: 0 0 20px;

  ${media.desktop`
    margin: 20px 0 0;
  `};
`;

const VideoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  height: 250px;
  width: 100%;
  object-fit: cover;
  max-width: 374px;

  ${media.desktop`
    margin-left: 180px;
    height: 250px;
    width: 380px;
  `};
`;

const VideoOverlayTrigger = styled.div`
  cursor: pointer;
  background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const VideoThumbnail = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const PlayButton = styled.img`
  width: 45px;

  ${media.desktop`
    width: 57px;
  `}
`;

const StyledModal = styled(LightBox)`
  > div {
    background: transparent;
  }
`;

const ModalVideoIframe = styled.iframe`
  border: 0;
  position: relative;
  width: 334px;
  height: 213px;

  ${media.tablet`
    width: 660px;
    height: 372px;
  `};
`;

const ImageThumbnail = styled.img<ImageThumbnailProps>`
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  height: 250px;
  width: 100%;
  max-width: 374px;
  border: 1px solid ${props => props.theme.colors.border_default};
  object-position: top;
  object-fit: cover;

  ${media.desktop`
    margin-left: 180px;
    height: 250px;
    width: 380px;
  `};
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const MapWrapper = styled(VideoWrapper)`
  cursor: pointer;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.17);
`;

const MapThumbnail = styled.iframe`
  pointer-events: none;
  border: none;
  height: 100%;
  width: 100%;
`;

const ModalMapIframe = styled(ModalVideoIframe)`
  ${media.tablet`
    width: 700px;
    height: 453px;
  `};
`;
