import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes';
import { Paragraph, Label } from '../../../content/text';
import { LinkStyles } from '../../../content/link/styles';

interface Alignment {
  alignment?: 'left' | 'center' | 'right';
}

interface ContentAlignment {
  contentAlignment?: 'left' | 'center' | 'right';
}

interface ItemWithContentProps extends Alignment, ContentAlignment {
  title?: string;
  image?: React.ReactNode;
  inverse?: boolean;
  category?: string;
}

interface AssetClassTotalListItemProps extends Alignment, ContentAlignment {
  title?: string;
}

export const AssetClassTotalListItem: FunctionComponent<AssetClassTotalListItemProps> = ({
  title,
  children,
}) => {
  return (
    <ItemHolder>
      <ListTitle>{title}</ListTitle>
      <ListContent>{children}</ListContent>
    </ItemHolder>
  );
};

const ItemHolder = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 5px;
`;

const ListTitle = styled(Label)``;

const ListContent = styled(Label)`
  flex-grow: 1;
  text-align: right;
`;

export const AssetClassTotalPhotoDetail: FunctionComponent<ItemWithContentProps> = ({
  title,
  image,
  alignment = 'left',
  contentAlignment = 'left',
  category,
  children,
  inverse,
}) => {
  return (
    <OuterContainer>
      <ImageHolder alignment={alignment}>
        {image && <ImageWrapper>{image}</ImageWrapper>}
      </ImageHolder>
      <ContentHolder>
        {category && <Category alignment={alignment}>{category}</Category>}
        <Title semiBold secondary alignment={alignment} inverse={inverse}>
          {title}
        </Title>
        <Content contentAlignment={contentAlignment}>{children}</Content>
      </ContentHolder>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background: ${props => props.theme.colors.background_default};
  flex-grow: 1;
  flex-basis: 0;
  ${media.desktop`
    display: flex;
    flex-direction: column;
    max-width: 379px;
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child) {
      margin: 0 10px;
    }
  `}
`;

const Title = styled(Paragraph)<Alignment>`
  text-align: ${props => props.alignment};
  margin: 30px 0 10px 0;
  width: 80%;
  flex-grow: 1;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const ContentHolder = styled.div`
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const ImageHolder = styled.div<Alignment>`
  text-align: ${props => props.alignment};
`;

const Content = styled.div<ContentAlignment>`
  margin: 10px 0 0 0;
  text-align: ${props => props.contentAlignment};
  button,
  a {
    ${LinkStyles}
    margin-top: 40px;
    display: inline-block;
  }
`;

const ImageWrapper = styled.div``;

const Category = styled(Label)<Alignment>`
  text-align: ${props => props.alignment};
  display: block;
  margin-bottom: 5px;
`;
