import React from 'react';
import styled from 'styled-components';
import logo from '@yieldstreet/ui-kit/assets/logos/white_ys_logo_mobile.svg';
import { media } from '@yieldstreet/ui-kit';

export const Logo = () => <LogoImage tabIndex={-1} />;

const LogoImage = styled.div`
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${logo});
  &:active,
  &:focus {
    outline: 0;
    border: none;
  }

  width: 145px;
  ${media.large`
    width: 185px;
  `};
`;
