import React, { FunctionComponent, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import isNumber from 'lodash/isNumber';

import { getConfig, GridTheme } from './common';
import {
  MaybeSizeMap,
  MaybePartialSizeMap,
  sizeCss,
  sizeMap,
  isValueMap,
} from '../../../utils/sizes';

import { GridProvider } from './Provider';

export type ColProps = {
  size?: MaybePartialSizeMap<boolean | number>;
  offset?: MaybePartialSizeMap<number>;
  display?: MaybePartialSizeMap<'flex' | 'block'>;
};

export const ColContainer = styled.div<ColProps>`
  box-sizing: border-box;
  flex: 0 0 auto;
  ${props =>
    sizeCss(
      ({ gridSize, columnSize, contentDisplay }) => {
        // eslint-disable-next-line no-nested-ternary
        const percentWidth = columnSize
          ? isNumber(columnSize)
            ? (100 / gridSize) * columnSize
            : 100
          : 0;
        return css`
          ${percentWidth
            ? // Number
              css`
                display: ${contentDisplay};
                flex-direction: column;
                flex-basis: ${percentWidth}%;
                max-width: ${percentWidth}%;
              `
            : // Hide element
              css`
                display: none;
              `}
        `;
      },
      {
        gridSize: getConfig(props.theme, 'size'),
        columnSize: props.size,
        contentDisplay: props.display,
      },
      {
        columnSize: true,
        contentDisplay: 'flex',
      }
    )}

  ${props =>
    props.offset &&
    sizeCss(
      ({ gridSize, columnOffset }) => css`
        margin-left: ${(100 / gridSize) * columnOffset}%;
      `,
      {
        gridSize: getConfig(props.theme, 'size'),
        columnOffset: props.offset,
      }
    )}
`;

const getColSize = (
  theme: GridTheme,
  size?: MaybePartialSizeMap<boolean | number>
): MaybeSizeMap<number> => {
  if (!size) {
    return 0;
  }
  if (isNumber(size)) {
    return size;
  }
  if (isValueMap(size)) {
    const gridSize = getConfig(theme, 'size');
    return sizeMap<number>(key => {
      const colSize = size[key];
      if (isNumber(colSize)) {
        return colSize;
      } else {
        return isValueMap(gridSize) ? gridSize[key] : gridSize;
      }
    });
  }
  return 0;
};

export const Col: FunctionComponent<ColProps> = ({ children, size, offset, display }) => {
  const theme = useTheme() as GridTheme;
  const config = useMemo(() => {
    const colSize = getColSize(theme, size);
    if (colSize) {
      return { size: colSize };
    }
  }, [size, theme]);

  return (
    <ColContainer display={display} size={size} offset={offset}>
      <GridProvider config={config}>{children}</GridProvider>
    </ColContainer>
  );
};
