import styled from 'styled-components';
import { media } from '../../themes';

export const ContentWrapepr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacing.sm};
  max-width: 375px;
  ${media.tablet`
    width: 550px;
    max-width: unset;
  `}
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm};
  min-width: 320px;
`;

export const HeadingContainer = styled.div``;

export const BarLabelWrapper = styled.div`
  text-align: center;
`;

export const ScrollContent = styled.div`
  overflow: auto;
  scrollbar-width: none;
  width: inherit;
`;

export const BarWrapper = styled.div`
  margin: ${({ theme }) => `188px ${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.m}`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ml};
  text-align: center;
  width: 500px;
  ${media.phoneLandscape`
    margin-right: auto;
    margin-left: auto;
  `}
  ${media.tablet`
    width: 648px;
    margin: ${({ theme }) => `240px auto ${theme.spacing.m} auto`};
  `}
`;

export const PageStepContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.ml};
  padding-bottom: ${({ theme }) => theme.spacing.ml};
  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing['2xl']};
    padding-bottom: ${({ theme }) => theme.spacing['2xl']};
  `}
`;

export const LogoContainer = styled.div`
  height: 30px;
  margin: auto auto 200px auto;
  ${media.tablet`
    margin: auto auto 270px auto;
  `}
  ${media.desktop`
    width: 500px;
  `};
`;

export const LogoImg = styled.img`
  cursor: pointer;
  display: block;
  max-width: 185px;
  height: auto;
  margin: auto;
`;
