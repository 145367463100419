import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const EmptyStateWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyItems: 'left',
  padding: theme.spacing(5),
  backgroundColor: theme.palette.background.default,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10),
  },
}));
