import { styled } from '@mui/material/styles';

import { Container } from '../container';

export const SectionContainer = styled(Container)(({ theme }) => ({
  paddingTop: `var(--sectionContainerPaddingVertical, ${theme.spacing(5)})`,
  paddingBottom: `var(--sectionContainerPaddingVertical, ${theme.spacing(5)})`,

  paddingLeft: `var(--sectionContainerPaddingHorizontal, ${theme.spacing(5)})`,
  paddingRight: `var(--sectionContainerPaddingHorizontal, ${theme.spacing(5)})`,

  [theme.breakpoints.up('md')]: {
    paddingTop: `var(--sectionContainerPaddingVertical, ${theme.spacing(10)})`,
    paddingBottom: `var(--sectionContainerPaddingVertical, ${theme.spacing(10)})`,

    paddingLeft: `var(--sectionContainerPaddingHorizontal, ${theme.spacing(10)})`,
    paddingRight: `var(--sectionContainerPaddingHorizontal, ${theme.spacing(10)})`,
  },
}));
