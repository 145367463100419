import { HttpMethods } from '../../enums/http';

import { Tags, restApi } from '../common';
import {
  PushRegisterAPIRequest,
  PushRegisterResponse,
  PushUnregisterAPIRequest,
  PushUnregisterResponse,
} from './push.model';

export const pushApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.Push],
  })
  .injectEndpoints({
    endpoints: builder => {
      const pushRegister = builder.mutation<PushRegisterResponse, PushRegisterAPIRequest>({
        query: params => {
          return {
            url: '/a/api/push/device',
            method: HttpMethods.Post,
            params,
          };
        },
        invalidatesTags: [Tags.Push],
      });

      const pushUnregister = builder.mutation<PushUnregisterResponse, PushUnregisterAPIRequest>({
        query: params => {
          return {
            url: '/a/api/push/device',
            method: HttpMethods.Delete,
            params,
          };
        },
        invalidatesTags: [Tags.Push],
      });

      return {
        pushRegister,
        pushUnregister,
      };
    },
  });
