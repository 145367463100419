export enum Audiences {
  adviceEligible = 'ADVICE_ELIGIBLE',
  adviceReady = 'ADVICE_READY',
  adviceClient = 'ADVICE_CLIENT',
  altExperienced = 'EXPERIENCED_WITH_ALTS',
  firstTimeInvestors = 'SAI_FIRST_TIME_INVESTORS',
  allNAI = 'ALL_NAI',
  noInvestmentPreferences = 'NO_INV_PREF',
  firstTimeInvestor = 'FTI_YS',
  tier = 'TIER',
  repeatInvestor = 'RPI_YS',
  portfolioSizeAbove1M = 'PORTFOLIO_SIZE_ABOVE_1M',
  hasWallet = 'HAS_WALLET',
  hasPortfolio = 'HAS_PORTFOLIO',
  ysUsers = 'YS_USERS',
  repeatInvestorCurrent = 'RPI_CUR',
  firstInvestorCurrent = 'FTI_CUR',
  diamondTier = 'DIAMOND_TIER',
  almostDiamondTier = 'ALMOST_DIAMOND_TIER',
  idleInvestors = 'IDLE_INVESTORS',
  churned = 'CHURNED',
  atRiskInvestors = 'AT_RISK_INVESTORS',
  gem = 'GEM',
  goldTier = 'GOLD_TIER',
  platinumTier = 'PLATINUM_TIER',
  etcUser = 'ETC_USER',
  userSince = 'USER_SINCE',
  hasRetirementAccount = 'HAS_RETIREMENT_ACCOUNT',
}

export enum TierLevels {
  /**
   * @deprecated - ALMOST_DIAMOND is deprecated, and will be removed in the next versions
   * */
  ALMOST_DIAMOND = 'ALMOST_DIAMOND',
  DEFAULT = 'DEFAULT',
  DIAMOND = 'DIAMOND',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
}

export enum AudiencePresetTypes {
  ANALYTICS = 'analytics',
  PORTFOLIO = 'portfolio',
  DASHBOARD = 'dashboard',
  LOGIN = 'login',
  ACCOUNT_SETUP = 'accountSetup',
}

export enum VisibilityTypes {
  PUBLIC = 'PBLC',
  LIMITED = 'LMTD',
  PRIVATE = 'PRVT',
}

export enum Note506Types {
  SAI = 'R_506B',
  NAI = 'R_506C',
}

export enum OfferingAudienceVisibilityTypes {
  PUBLIC = 'PUBLIC',
  DEFAULT = 'DEFAULT',
  PRIVATE = 'PRIVATE',
  NON_DISCRETIONARY_ONLY = 'NON_DISCRETIONARY_ONLY',
}

export enum OfferingAudienceEligibilityTypes {
  DEFAULT = 'DEFAULT',
  ELIGIBLE = 'ELIGIBLE',
  RESERVED = 'RESERVED',
}
