import React from 'react';

import { BaseProps } from '../../../../types/components/base';

import checkboxActive from '../../assets/checkbox_active.svg';
import checkboxCompleted from '../../assets/checkbox_completed.svg';
import checkboxError from '../../assets/checkbox_error.svg';
import checkboxIdle from '../../assets/checkbox_idle.svg';

import {
  ProgressCheckboxProps,
  CheckboxStatus,
  CheckboxStatuses,
  useTestIds,
} from '../../ProgressBar.model';

import { StatusContainer, CheckboxContainer, Status, Checkbox } from './ProgressBarCheckbox.style';

const getCheckbox = (status: CheckboxStatus) => {
  switch (status) {
    case CheckboxStatuses.ACTIVE:
      return checkboxActive;
    case CheckboxStatuses.CLEARED:
      return checkboxCompleted;
    case CheckboxStatuses.FAILED:
      return checkboxError;
    case CheckboxStatuses.IDLE:
      return checkboxIdle;
    default:
      return checkboxActive;
  }
};

const getColor = (status: CheckboxStatus) => {
  switch (status) {
    case CheckboxStatuses.CLEARED:
      return 'border_outline';
    default:
      return 'border_default';
  }
};

export const ProgressBarCheckbox = (props: ProgressCheckboxProps & BaseProps) => {
  const { copy, status, lastElement, numOfElements } = props;

  const testIds = useTestIds(props);

  const lineProps = {
    color: getColor(status),
    lastElement,
    numOfElements,
  };

  return (
    <StatusContainer {...lineProps} {...testIds.item.attr}>
      <CheckboxContainer {...lineProps}>
        <Checkbox src={getCheckbox(status)} {...testIds.checkbox.attr} />
      </CheckboxContainer>
      <Status small semiBold={status === CheckboxStatuses.CLEARED} {...testIds.copy.attr}>
        {copy}
      </Status>
    </StatusContainer>
  );
};
