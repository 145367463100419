import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TimerState {
  expirationTime: number | null;
}

const initialState: TimerState = {
  expirationTime: null,
};

const investmentRequestTimerSlice = createSlice({
  name: 'investmentRequestTimer',
  initialState,
  reducers: {
    setInvestmentRequestExpirationTime: (state, action: PayloadAction<number>) => {
      state.expirationTime = Date.now() + action.payload;
    },
    resetInvestmentRequestExpirationTime: state => {
      state.expirationTime = null;
    },
  },
});

export const { setInvestmentRequestExpirationTime, resetInvestmentRequestExpirationTime } =
  investmentRequestTimerSlice.actions;
export const investmentRequestTimerReducer = investmentRequestTimerSlice.reducer;
