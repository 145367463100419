import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { offeringPromotionApi } from '@yieldstreet/platform-kit';
import { getOfferingPromotionState } from './useOfferingPromotion.utils';
import isArray from 'lodash/isArray';

export const useOfferingPromotion = (urlHash: string | string[], promoKey?: string) => {
  const urlHashes = isArray(urlHash) ? urlHash : [urlHash];

  const eligibilityParams = useMemo(() => {
    if (urlHashes && promoKey) {
      let promos = {};
      urlHashes.forEach(urlHash => {
        promos = Object.assign(promos, {
          [urlHash]: promoKey,
        });
      }, {});
      return promos;
    }
    return null;
  }, [urlHash, promoKey]);

  const { data: eligibilityResult, error: eligibilityError } =
    offeringPromotionApi.usePromotionEligibilityQuery(eligibilityParams || skipToken);

  return useMemo(
    () => getOfferingPromotionState(promoKey, eligibilityResult, eligibilityError),
    [promoKey, eligibilityResult, eligibilityError]
  );
};
