import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { theme2, media } from '../../../themes';
import { Heading } from '../../../content/text';

interface AssetClassTotalProps {
  title?: string;
  inverse?: boolean;
}

export const AssetClassContent: FunctionComponent<AssetClassTotalProps> = ({
  title,
  children,
  inverse,
}) => {
  return (
    <OuterContainer>
      <Headline type={4} inverse={inverse}>
        {title}
      </Headline>
      <ContentHolder>{children}</ContentHolder>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background: ${props => props.theme.colors.background_default};
  position: relative;
  text-align: left;
  min-height: 392px;
  margin: 20px 0 0 0;
  padding: 0 20px 20px 20px;
  flex-grow: 1;
  flex-basis: 0;
  ${media.desktop`
    margin: 0;
    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
    max-width: 221px;
  `}
`;

const Headline = styled(Heading)`
  padding: 0 0 20px 0;
  margin: 30px 0 20px 0;
  min-height: 68px;
  box-sizing: content-box;
  border-bottom: 1px solid ${theme2.colors.border_default};
`;

const ContentHolder = styled.div`
  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;
