import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

import { MuiTheme } from '../../../theme';
import { OfferingVisualProps } from './OfferingAnnouncement.model';

const getBackgroundColor = (backgroundColor: string, theme: MuiTheme) => {
  switch (backgroundColor) {
    case 'blue':
      return theme.palette.background.darkGreen;

    case 'black':
      return theme.palette.background.dark;

    default:
      return theme.palette.background.default;
  }
};

export const Wrapper = styled('div')<OfferingVisualProps>(({ backgroundColor, theme }) => ({
  background: getBackgroundColor(backgroundColor || '', theme),
}));

export const Container = styled(Stack)<OfferingVisualProps>(({ orientation, small, theme }) => ({
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: 'auto',
  padding: theme.spacing(5),
  maxWidth: small ? '1150px' : '1180px',

  [theme.breakpoints.up('md')]: {
    flexDirection: orientation,
    padding: small ? theme.spacing(5) : theme.spacing(8),
  },
}));

export const ButtonContainer = styled('div')<OfferingVisualProps>(({ orientation, theme }) => ({
  marginTop: theme.spacing(5),

  [theme.breakpoints.up('md')]: {
    margin: orientation === 'column' ? `${theme.spacing(5)} 0 0 0` : `auto 0`,
    minWidth: '20%',
  },
}));

export const Close = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  height: '16px',
  cursor: 'pointer',
}));

export const OfferingDetail = styled(Stack)<OfferingVisualProps>(({ small, theme }) => ({
  flexDirection: small ? 'row' : 'column',
  marginTop: theme.spacing(5),
  marginRight: theme.spacing(8),
}));

export const Heading = styled(Typography)<{ inverse?: boolean }>(({ inverse, theme }) => ({
  color: inverse ? theme.palette.text.header.inverse : theme.palette.text.header.default,
}));

export const Paragraph = styled(Typography)<{ inverse?: boolean }>(({ inverse, theme }) => ({
  color: inverse
    ? theme.palette.text.paragraphPrimary.inverse
    : theme.palette.text.paragraphPrimary.default,
}));
