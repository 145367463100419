import { PaymentMethodType } from '../../enums';
import { gateType } from '../../types';

const INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK = {
  ACTIVE: {
    investments: [
      {
        currentValue: 15000.0,
        fundType: 'THIRD_PARTY_FUND',
        investmentAmount: 15000.0,
        investorAccountId: '394626',
        noteTitle: 'Enhanced Crypto Fund',
        noteUrlHash: 'qzqC4A',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 0.0,
        termPercentage: 0.0,
        termPhrase: 'N/A',
        termRemaining: 998.0,
      },
      {
        currentValue: 507.0,
        fundType: 'INTERNAL_FUND',
        investmentAmount: 507.0,
        investorAccountId: '394626',
        noteTitle: "Vitor's Test",
        noteUrlHash: 'lrgZSw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 0.0,
        termPercentage: 8.47457627118644,
        termPhrase: '54 Mo.',
        termRemaining: 54.0,
      },
      {
        currentValue: 2000.0,
        fundType: 'THIRD_PARTY_FUND',
        investmentAmount: 2000.0,
        investorAccountId: '394817',
        noteTitle: 'FUNDS Test Offering',
        noteUrlHash: 'Z4hj1Q',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 0.0,
        termPercentage: 100.0,
        termPhrase: '0 Mo.',
        termRemaining: 0.0,
      },
      {
        currentValue: 14197.39,
        fundType: 'THIRD_PARTY_FUND',
        investmentAmount: 15000.0,
        investorAccountId: '394626',
        noteTitle: 'FUNDS Test Offering',
        noteUrlHash: 'Z4hj1Q',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 0.0,
        termPercentage: 100.0,
        termPhrase: '0 Mo.',
        termRemaining: 0.0,
      },
      {
        currentValue: 2.02,
        fundType: null,
        investmentAmount: 2.0,
        investorAccountId: '394626',
        noteTitle: 'Pflex Schedule Debug',
        noteUrlHash: 'Y5wcqg',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 12.0,
        termPercentage: 25.0,
        termPhrase: '9 Mo.',
        termRemaining: 9.0,
      },
      {
        currentValue: 1004.38,
        fundType: null,
        investmentAmount: 1000.0,
        investorAccountId: '394626',
        noteTitle: 'Blue Origin',
        noteUrlHash: 'DPiCSg',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 12.0,
        termPercentage: 33.33333333333333,
        termPhrase: '6 Mo.',
        termRemaining: 6.0,
        rolloversScheduled: [
          {
            rolloverAmount: 1000,
            to: {
              noteUrlHash: 'abc',
              investmentId: '123',
            },
          },
        ],
      },
      {
        currentValue: 100000.0,
        fundType: null,
        investmentAmount: 100000.0,
        investorAccountId: '394626',
        noteTitle: 'Bayan - Portland Multi-Family',
        allowRollover: true,
        noteUrlHash: 'wXGNxQ',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 36.0,
        termPercentage: 20.8955223880597,
        termPhrase: '53 Mo.',
        termRemaining: 53.0,
      },
      {
        currentValue: 10.33,
        fundType: null,
        investmentAmount: 10.0,
        investorAccountId: '394817',
        noteTitle: 'Pestoflex dist break',
        noteUrlHash: 'uqWmfw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 60.0,
        termPercentage: 8.51063829787234,
        termPhrase: '43 Mo.',
        termRemaining: 43.0,
      },
      {
        currentValue: 1013.19,
        fundType: null,
        investmentAmount: 1000.0,
        investorAccountId: '394817',
        noteTitle: 'PFLEX Test Offering',
        noteUrlHash: 'epu_Hg',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 18.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
      {
        currentValue: 200.0,
        fundType: null,
        investmentAmount: 200.0,
        investorAccountId: '394626',
        noteTitle: 'BPDN 1',
        noteUrlHash: 'B-WQlg',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 30.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
      {
        currentValue: 1026.7,
        fundType: null,
        investmentAmount: 1000.0,
        investorAccountId: '394626',
        noteTitle: 'Short term notes 1',
        noteUrlHash: '--DfDw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 20.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
    ],
    totals: {
      currentValue: 134961.01,
      investmentAmount: 135719.0,
    },
  },
  PENDING: {
    investments: [
      {
        currentValue: 0.0,
        fundType: 'REG_A_PLUS',
        investmentAmount: 6000.0,
        investorAccountId: '394817',
        noteTitle: 'Growth & Income REIT',
        noteUrlHash: 'e5jyfw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 0.0,
        termPercentage: 0.0,
        termPhrase: 'N/A',
        termRemaining: 998.0,
      },
      {
        currentValue: 0.0,
        fundType: 'REG_A_PLUS',
        investmentAmount: 4000.0,
        investorAccountId: '394626',
        noteTitle: 'Growth & Income REIT',
        noteUrlHash: 'e5jyfw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 0.0,
        termPercentage: 0.0,
        termPhrase: 'N/A',
        termRemaining: 998.0,
      },
      {
        currentValue: 0.0,
        fundType: 'THIRD_PARTY_FUND',
        investmentAmount: 2000.0,
        investorAccountId: '394626',
        noteTitle: 'Enhanced Crypto Fund',
        noteUrlHash: 'qzqC4A',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 0.0,
        termPercentage: 0.0,
        termPhrase: 'N/A',
        termRemaining: 998.0,
      },
      {
        currentValue: 0.0,
        fundType: 'INTERNAL_FUND',
        investmentAmount: 2.0,
        investorAccountId: '394626',
        noteTitle: 'Test Rebuild',
        noteUrlHash: 'oTXk7g',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 0.0,
        termPercentage: 16.666666666666664,
        termPhrase: '10 Mo.',
        termRemaining: 10.0,
      },
      {
        currentValue: 0.0,
        fundType: 'INTERNAL_FUND',
        investmentAmount: 1000.0,
        investorAccountId: '394817',
        noteTitle: 'Test Rebuild',
        noteUrlHash: 'oTXk7g',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 0.0,
        termPercentage: 16.666666666666664,
        termPhrase: '10 Mo.',
        termRemaining: 10.0,
      },
      {
        currentValue: 0.0,
        fundType: 'INTERNAL_FUND',
        investmentAmount: 10.0,
        investorAccountId: '394817',
        noteTitle: "Vitor's Test",
        noteUrlHash: 'lrgZSw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 0.0,
        termPercentage: 8.47457627118644,
        termPhrase: '54 Mo.',
        termRemaining: 54.0,
      },
      {
        currentValue: 0.0,
        fundType: 'INTERNAL_FUND',
        investmentAmount: 100.0,
        investorAccountId: '394626',
        noteTitle: 'testAuto Internal Fund flare',
        noteUrlHash: 'BLN_retrospectivity_84957',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        allowRollover: true,
        termCurrent: 0.0,
        termPercentage: -9.090909090909092,
        termPhrase: '12 Mo.',
        termRemaining: 12.0,
      },
      {
        currentValue: 0.0,
        fundType: 'THIRD_PARTY_FUND',
        investmentAmount: 2000.0,
        investorAccountId: '394626',
        noteTitle: 'SFQP Fund',
        noteUrlHash: 'b2R0nw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 0.0,
        termPercentage: 100.0,
        termPhrase: '5 Mo.',
        termRemaining: 5.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 1.0,
        investorAccountId: '394626',
        noteTitle: 'YS TBG MULTIREP XI LLC CL A',
        noteUrlHash: 'HfGfrg',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 12.0,
        termPercentage: 33.33333333333333,
        termPhrase: '8 Mo.',
        termRemaining: 8.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 6003.0,
        investorAccountId: '394626',
        noteTitle: "Eugene's Tooltip test",
        noteUrlHash: 'dX0-EA',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 60.0,
        termPercentage: 3.571428571428571,
        termPhrase: '81 Mo.',
        termRemaining: 81.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 110.0,
        investorAccountId: '394626',
        noteTitle: 'YS TBG MULTIPROP I LLC CLASS A',
        noteUrlHash: 'R83YiQ',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 12.0,
        termPercentage: 33.33333333333333,
        termPhrase: '8 Mo.',
        termRemaining: 8.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 10.0,
        investorAccountId: '394626',
        noteTitle: 'YS ALTNOTES I LLC',
        noteUrlHash: '5bqIvg',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 12.0,
        termPercentage: 33.33333333333333,
        termPhrase: '8 Mo.',
        termRemaining: 8.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 1000.0,
        investorAccountId: '394626',
        noteTitle: 'SFQC Cool Offering',
        noteUrlHash: 'bDdjog',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 11.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 300.0,
        investorAccountId: '394817',
        noteTitle: 'SFQP Offering test',
        noteUrlHash: 'Wz8GVw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 1.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 300.0,
        investorAccountId: '394626',
        noteTitle: 'SFQP Offering test',
        noteUrlHash: 'Wz8GVw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 1.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 1.0,
        investorAccountId: '394817',
        noteTitle: "Eugene's Tooltip test",
        noteUrlHash: 'dX0-EA',
        performanceStatus: 'PERFORMING',
        requiresUserAction: true,
        termCurrent: 60.0,
        termPercentage: 3.571428571428571,
        termPhrase: '81 Mo.',
        termRemaining: 81.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 4000.0,
        investorAccountId: '394817',
        noteTitle: 'PFLEX Test Offering',
        noteUrlHash: 'epu_Hg',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 18.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 80000.0,
        investorAccountId: '394626',
        noteTitle: 'Pricing Test Offering',
        noteUrlHash: 'w2nNhQ',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 12.0,
        termPercentage: 30.0,
        termPhrase: '7 Mo.',
        termRemaining: 7.0,
      },
      {
        currentValue: 0.0,
        fundType: null,
        investmentAmount: 112.0,
        investorAccountId: '394626',
        noteTitle: 'Short term notes 1',
        noteUrlHash: '--DfDw',
        performanceStatus: 'PERFORMING',
        requiresUserAction: false,
        termCurrent: 20.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
    ],
    totals: {
      currentValue: 0.0,
      investmentAmount: 106949.0,
    },
  },
  MATURED: {
    investments: [
      {
        fundType: null,
        investmentAmount: 1000.0,
        investorAccountId: '394626',
        irr: -100.0,
        netTotalRepaid: 123.88,
        noteTitle: 'SPV Deal Update',
        noteUrlHash: 'DclE4A',
        termCurrent: 32.0,
        termPercentage: 100.0,
        termPhrase: '6 Mo.',
        termRemaining: 6.0,
      },
      {
        fundType: null,
        investmentAmount: 1000.0,
        investorAccountId: '394626',
        irr: -100.0,
        netTotalRepaid: 123.88,
        noteTitle: 'Drake Debt Deal',
        noteUrlHash: 'nhaw7y',
        termCurrent: 32.0,
        termPercentage: 100.0,
        termPhrase: '6 Mo.',
        termRemaining: 6.0,
      },
      {
        fundType: 'INTERNAL_FUND',
        investmentAmount: 200.0,
        investorAccountId: '394626',
        irr: 423.19,
        netTotalRepaid: 123.88,
        noteTitle: 'YS fund test',
        noteUrlHash: 'stfIIg',
        termCurrent: 0.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
      {
        fundType: 'INTERNAL_FUND',
        investmentAmount: 200.0,
        investorAccountId: '394626',
        irr: 423.19,
        netTotalRepaid: 123.88,
        noteTitle: 'Dake Fund Deal',
        noteUrlHash: 'ksjdh2',
        termCurrent: 0.0,
        termPercentage: 100.0,
        termPhrase: '<1 Mo.',
        termRemaining: 1.0,
      },
    ],
    totals: {
      investmentAmount: 1200.0,
      irr: 0.0,
      netTotalRepaid: 800.0,
    },
  },
};

export const INVESTMENTS_BY_STATUS_MOCK = {
  investmentByStatus: {
    ACTIVE: {
      gates: {},
      ...INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.ACTIVE,
    },
    PENDING: {
      gates: {
        '394817': {
          oTXk7g: {
            attributes: {
              paymentMethod: PaymentMethodType.WIRE,
            },
            adminUserId: '',
            clearReason: 'NONE',
            date: '2023-04-14',
            externalInvestmentId: 154727,
            id: '317f6c10-da95-11ed-86ff-7fb3e1ed4a4b',
            irServiceInvestmentId: '2ba06e70-da95-11ed-86ff-7fb3e1ed4a4b',
            owner: 'USER',
            priority: 30,
            status: 'ACTIVE',
            timestamp: 1681456983,
            type: gateType.allocationPaymentPending,
            visibility: 'ALL',
          },
        },
        '394626': {
          'dX0-EA': {
            attributes: {
              paymentMethod: PaymentMethodType.WIRE,
            },
            adminUserId: '',
            clearReason: 'NONE',
            date: '2023-05-03',
            externalInvestmentId: 154805,
            id: '23511400-e9b2-11ed-87f2-69a1b46d632a',
            irServiceInvestmentId: 'd99e2350-3148-11ee-9d57-8144b4c76421',
            owner: 'USER',
            priority: 30,
            status: 'ACTIVE',
            timestamp: 1683118682,
            type: gateType.allocationPaymentPending,
            visibility: 'ALL',
          },
        },
      },
      ...INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.PENDING,
    },
    MATURED: INVESTMENTS_BY_STATUS_INVESTMENTS_MOCK.MATURED,
  },
  lastCommentary: {
    offerings: {
      Z4hj1Q: {
        id: '689ce9d0-902f-11ed-a7c0-656ecf8e2dcd',
        read: true,
        sortDate: '2023-01-09',
      },
      Y5wcqg: {
        id: '6ff47301-d3a1-11ed-8ac5-6f88c6cae517',
        read: true,
        sortDate: '2023-04-05',
      },
    },
  },
};

export const INVESTMENTS_BY_STATUS_EMPTY_MOCK = {
  investmentByStatus: {
    ...INVESTMENTS_BY_STATUS_MOCK.investmentByStatus,
    ACTIVE: {
      ...INVESTMENTS_BY_STATUS_MOCK.investmentByStatus.ACTIVE,
      investments: [],
      totals: {
        currentValue: 0,
        investmentAmount: 0,
      },
    },
    PENDING: {
      ...INVESTMENTS_BY_STATUS_MOCK.investmentByStatus.PENDING,
      investments: [],
      totals: {
        currentValue: 0,
        investmentAmount: 0,
      },
    },
  },
  lastCommentary: INVESTMENTS_BY_STATUS_MOCK.lastCommentary,
};

export const MAX_ROLLOVER_AMOUNT_MOCK = {
  maxRolloverAmount: {
    userId: '493207',
    maturityDate: '2024-03-03',
    rolloverAmountPerInvestment: [
      {
        investmentId: 'b6f0a22b-3e14-4aa5-9cd4-e7db93eb3621',
        rolloverAmount: 25000,
      },
      {
        investmentId: '7c8b5ed9-6f1a-4c9c-a6ef-42a207b91c12',
        rolloverAmount: 30000,
      },
      {
        investmentId: 'd2f3cfe6-91c9-4a42-9c47-55159e44a6db',
        rolloverAmount: 20000,
      },
      {
        investmentId: 'e4a91ed5-0c2b-49a2-a799-9ce7d14c3b7a',
        rolloverAmount: 10000,
      },
      {
        investmentId: 'f83a9c6e-2df9-4d31-9e57-efaecf6db64f',
        rolloverAmount: 5000,
      },
    ],
    totals: {
      investmentIds: [
        'b6f0a22b-3e14-4aa5-9cd4-e7db93eb3621',
        '7c8b5ed9-6f1a-4c9c-a6ef-42a207b91c12',
        'd2f3cfe6-91c9-4a42-9c47-55159e44a6db',
        'e4a91ed5-0c2b-49a2-a799-9ce7d14c3b7a',
        'f83a9c6e-2df9-4d31-9e57-efaecf6db64f',
      ],
      maxRolloverAmount: 90000,
    },
  },
};
