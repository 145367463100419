import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import Radio from '@mui/material/Radio';
import { BaseInputProps } from '@yieldstreet/tool-kit';

import {
  SFormHelperText,
  FormError,
  OptionControl,
  OptionLabel,
  OptionDescription,
  OptionControlLabel,
  withFormikWeb,
  useLabelId,
} from '../common';

export interface RadioInputProps extends BaseInputProps<boolean> {
  description?: string | ReactElement;
  descriptionCheckedOnly?: boolean;
  image?: string | ReactNode;
}

export const RadioInput: FunctionComponent<RadioInputProps> = props => {
  const {
    testId,
    label,
    value,
    error,
    onChange,
    onClick,
    className,
    caption,
    disabled,
    description,
    descriptionCheckedOnly,
    image,
    name,
    ...otherProps
  } = props;

  const labelId = useLabelId(name);

  return (
    <OptionControl
      error={!!error}
      className={className}
      data-radio
      data-checked={value}
      data-disabled={disabled}
      id={labelId}
    >
      <OptionControlLabel
        className="option-control-label"
        disabled={disabled}
        data-cy={`option-control-${testId}` || 'option-control-label-testId'}
        label={
          <>
            <OptionLabel data-radio-label className="option-label">
              {image && typeof image === 'string' ? <img src={image} /> : image}
              {label}
            </OptionLabel>
            {(!descriptionCheckedOnly || value) && description && (
              <OptionDescription small data-radio-description>
                {description}
              </OptionDescription>
            )}
          </>
        }
        value={value}
        onChange={onChange && ((_e, checked) => onChange(checked))}
        control={
          <Radio
            onClick={e => onClick && onClick(e)}
            data-radio-input
            data-cy={testId || 'radio'}
            name={name}
            color="primary"
            checked={value}
            {...otherProps}
          />
        }
      />
      {error || caption ? (
        <SFormHelperText margin="dense">
          {error ? (
            <FormError
              testId="error"
              type="radio-input"
              name={props?.name || 'radio'}
              error={error}
            />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </OptionControl>
  );
};

export const FormRadioInput = withFormikWeb(RadioInput);
