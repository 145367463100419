import { useMemo } from 'react';
import fedHolidays from '@18f/us-federal-holidays';
import dayjsBusinessTime from 'dayjs-business-time';
import dayjs, { BusinessHoursMap } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const holidays = [
  ...fedHolidays.allForYear(),
  ...fedHolidays.allForYear(dayjs(new Date()).add(1, 'year').year()),
];

dayjs.extend(utc);
dayjs.extend(timezone);

const defaultOpeningHours = [{ start: '09:00:00', end: ' 15:00:00' }];
const businessTime: BusinessHoursMap = {
  saturday: null,
  sunday: null,
  monday: defaultOpeningHours,
  tuesday: defaultOpeningHours,
  wednesday: defaultOpeningHours,
  thursday: defaultOpeningHours,
  friday: defaultOpeningHours,
};

dayjs.extend(dayjsBusinessTime);
dayjs.setHolidays(holidays?.map(x => x.dateString));
dayjs.setBusinessTime(businessTime);

/**
 * Hook based on US Holidays
 * If not a US holiday + during the week... it's a business day.
 *
 * EffectiveBusiness Day means that the day may be the same start date
 * if date is a business day and before 3pm ET.
 */
export const useGetACHBusinessDay = (startDate: Date, addBusinessDays: number = 0) => {
  return useMemo(() => {
    let sanitizedDate = dayjs(startDate).tz('America/New_York');

    let effectiveBusinessDay = dayjs(sanitizedDate);
    const isBusinessDay = effectiveBusinessDay.isBusinessDay();
    if (!effectiveBusinessDay.isBusinessDay()) {
      effectiveBusinessDay = effectiveBusinessDay.nextBusinessDay();
    }
    if (addBusinessDays) {
      effectiveBusinessDay = effectiveBusinessDay.addBusinessDays(addBusinessDays);
    }

    return {
      isBusinessDay,
      effectiveBusinessDay,
      formattedBusinessDay: effectiveBusinessDay.format('MM/DD/YYYY'),
    };
  }, [startDate, addBusinessDays]);
};
