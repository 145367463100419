import { HttpMethods } from '../../enums/http';
import {
  BankAccountLoginApiRequest,
  BankAccountLoginApiResponse,
  BankAccountMfaApiRequest,
  BankAccountMicroDepositVerifyApiRequest,
  BankAccountMicroDepositVerifyApiResponse,
  DictionaryBankApiResponse,
  Bank,
  AddManualBankAccountApiResponse,
  AddManualBankAccountApiRequest,
} from '../../types';
import { CustomSerializeQueryExtraOptions, restApi, RTKQAll, Tags } from '../common';
import {
  BankAccountsEntityGetApiRequest,
  BankAccountsEntityGetApiResponse,
  BankAccountsGetApiRequest,
  BankAccountsGetApiResponse,
  PlaidCreateMicrodepositTokenApiRequest,
  PlaidCreateTokenApiRequest,
  PlaidCreateTokenApiResponse,
  PlaidLinkBankApiRequest,
  PlaidLinkBankApiResponse,
  PlaidMicroDepositVerificationApiRequest,
  RemoveBankAccountRequest,
  RemoveBankAccountResponse,
  UpdateBankNicknameRequest,
  UpdateBankNicknameResponse,
  SetDefaultBankAccountParams,
} from './bank-account.model';

export const bankAccountApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.BankAccount,
      Tags.PlaidLinkBank,
      Tags.InvestorEntityStatus,
      Tags.ProviderBankLinkMigrationStatus,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getEntityBankAccounts = builder.query<
        BankAccountsEntityGetApiResponse,
        BankAccountsEntityGetApiRequest
      >({
        query: ({ investorEntityId }) => {
          return {
            url: `/a/api/investor-entity/${investorEntityId}/bank-account`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.BankAccount],
      });

      const bankAccountLogin = builder.mutation<
        BankAccountLoginApiResponse,
        BankAccountLoginApiRequest
      >({
        query: ({ request, investorEntityId }) => {
          return {
            url: `/a/api/investor-entity/${investorEntityId}/bank-account/login`,
            method: HttpMethods.Post,
            data: request,
          };
        },
        invalidatesTags: [Tags.BankAccount],
        transformResponse: (response: BankAccountLoginApiResponse) => {
          return response;
        },
      });

      const getBankAccounts = builder.query<
        BankAccountsGetApiResponse,
        BankAccountsGetApiRequest | RTKQAll
      >({
        extraOptions: (_props: string[]) => {
          const options: CustomSerializeQueryExtraOptions<string> = {
            cacheKey: '',
          };
          return options;
        },
        query: ({ userId }: BankAccountsGetApiRequest) => {
          return {
            url: `/a/api/user/${userId}/bank-accounts`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.BankAccount],
      });

      const setDefaultBankAccount = builder.mutation<{}, SetDefaultBankAccountParams>({
        query: ({ bankAccountId, investorEntityId }) => {
          return {
            url: `/a/api/investor-entity/${investorEntityId}/bank-account/${bankAccountId}/default`,
            method: HttpMethods.Post,
          };
        },
        invalidatesTags: [Tags.BankAccount],
      });

      const bankAccountMfa = builder.mutation<
        BankAccountLoginApiResponse,
        BankAccountMfaApiRequest
      >({
        query: ({ request, investorEntityId }) => {
          return {
            url: `/a/api/investor-entity/${investorEntityId}/bank-account/mfa`,
            method: HttpMethods.Post,
            data: request,
          };
        },
        invalidatesTags: [Tags.BankAccount],
      });

      const verifyMicroDeposits = builder.mutation<
        BankAccountMicroDepositVerifyApiResponse,
        BankAccountMicroDepositVerifyApiRequest
      >({
        query: ({ amounts, holderId, bankAccountId }) => {
          return {
            url: `/a/api/investor-entity/${holderId}/bank-account/${bankAccountId}/verify-micro-deposits`,
            method: HttpMethods.Post,
            data: { amounts },
          };
        },
      });

      const getExternalBanks = builder.query<DictionaryBankApiResponse, {}>({
        query: () => {
          return {
            url: `/a/api/dic/banks`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.BankAccount],
        transformResponse: (response: DictionaryBankApiResponse) => {
          const { banks } = response;
          const filteredBanks = banks
            .filter((bank: Bank) => bank.code !== 'hsbc')
            .sort((bank1: any, bank2: any) =>
              (bank1?.name?.toLowerCase() || '') > (bank2?.name?.toLowerCase() || '') ? 1 : -1
            );

          return { ...response, banks: filteredBanks };
        },
      });

      const addManualBankAccount = builder.mutation<
        AddManualBankAccountApiResponse,
        AddManualBankAccountApiRequest
      >({
        query: ({ investorEntityId, ...payload }) => {
          return {
            url: `/a/api/investor-entity/${investorEntityId}/bank-account/manual`,
            method: HttpMethods.Post,
            data: payload,
          };
        },
        invalidatesTags: [Tags.BankAccount],
      });

      const createPlaidToken = builder.query<
        PlaidCreateTokenApiResponse,
        PlaidCreateTokenApiRequest | PlaidCreateMicrodepositTokenApiRequest
      >({
        query: data => ({
          url: `/a/investor-entity/bank-accounts/linked/plaid/create-token`,
          method: HttpMethods.Post,
          data,
        }),
      });

      const addPlaidLinkBank = builder.mutation<
        PlaidLinkBankApiResponse,
        PlaidLinkBankApiRequest | PlaidMicroDepositVerificationApiRequest
      >({
        query: data => ({
          url: `/a/investor-entity/bank-accounts/linked/plaid/on-link-completed`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [
          Tags.BankAccount,
          Tags.PlaidLinkBank,
          Tags.InvestorEntityStatus,
          Tags.ProviderBankLinkMigrationStatus,
        ],
      });

      const updateBankNickname = builder.mutation<
        UpdateBankNicknameResponse,
        UpdateBankNicknameRequest
      >({
        query: ({ bankAccount, nickname }) => {
          return {
            url: `/a/api/user/ba/${bankAccount.id}`,
            method: HttpMethods.Post,
            data: {
              ...bankAccount,
              nickname,
            },
          };
        },
        invalidatesTags: [Tags.BankAccount],
      });

      const removeBankAccount = builder.mutation<
        RemoveBankAccountResponse,
        RemoveBankAccountRequest
      >({
        query: ({ bankAccountId }) => {
          return {
            url: `/a/investor/bankAccount/${bankAccountId}`,
            method: HttpMethods.Delete,
          };
        },
        invalidatesTags: [Tags.BankAccount],
      });

      return {
        getEntityBankAccounts,
        setDefaultBankAccount,
        bankAccountLogin,
        bankAccountMfa,
        getBankAccounts,
        verifyMicroDeposits,
        getExternalBanks,
        addManualBankAccount,
        createPlaidToken,
        addPlaidLinkBank,
        updateBankNickname,
        removeBankAccount,
      };
    },
  });
