import React, { FunctionComponent } from 'react';
import { getLabelTagConfig, LabelTagConfig } from '@yieldstreet/tool-kit';
import styled from 'styled-components';
import { Badge, Button, Currency, Heading, Paragraph } from '../..';
import BackgroundViewAll from '../../assets/graphics/backgrounds/offering-all-card-bg.jpg';
import { TagsList } from './TagsList';

interface DetaillItemProps {
  lastItem?: boolean;
}

interface OfferingCardProps {
  offering?: any;
  onClick?: () => void;
  imageUrl?: string;
  viewAllCard?: boolean;
  className?: string;
  showSummary?: boolean;
}
interface ViewAllCardProps {
  onClick?: () => void;
}

const Card = ({ offering, onClick, imageUrl, className, showSummary }: OfferingCardProps) => {
  if (offering === undefined) {
    return null;
  }
  const badges = getLabelTagConfig(offering);
  const offeringTags = offering?.v5Data?.offeringTags;
  const hasLegacyOfferingTags = offeringTags && typeof offeringTags[0] === 'string';

  const renderBadges = (badges: LabelTagConfig[]) => (
    <BadgesWrapper>
      {badges.map(badge => {
        const { text, icon, testingID } = badge;
        return (
          <BadgeWrapper key={`${testingID || text} badge`}>
            <Badge
              data-cy={`${testingID || text} badge`}
              config={{
                text,
                icon,
              }}
            />
          </BadgeWrapper>
        );
      })}
    </BadgesWrapper>
  );

  return (
    <FlexWrapper data-cy="offering-overview-wrapper" className={className} onClick={onClick}>
      <ImgWrapper className="OfferingOverview__ImgItem">
        {renderBadges(badges)}
        <OfferingImage loading="lazy" src={imageUrl} />
      </ImgWrapper>
      <InfoWrapper>
        <Heading type={4}>{offering.title}</Heading>
        {showSummary && offering.summary ? (
          <OfferingSummary>{offering.summary}</OfferingSummary>
        ) : null}
      </InfoWrapper>
      <DetailsWrapper>
        <DetailItem>
          <DetailLabel small>Min. investment</DetailLabel>
          <DetailDescription small semiBold>
            <Currency value={parseFloat(offering.minimumPurchase)} />
          </DetailDescription>
        </DetailItem>
        <DetailItem lastItem={!offering.originator ? true : false}>
          <DetailLabel small>Asset class</DetailLabel>
          <DetailDescription small semiBold>
            {offering.assetClassDisplayName}
          </DetailDescription>
        </DetailItem>
        {offering.originatorName && (
          <DetailItem lastItem>
            <DetailLabel small>Originator</DetailLabel>
            <DetailDescription small semiBold>
              {offering.originatorName}
            </DetailDescription>
          </DetailItem>
        )}
        {offeringTags && (
          <DetailTags>
            <TagsList tags={offeringTags} hasLegacyOfferingTags={hasLegacyOfferingTags} />
          </DetailTags>
        )}
      </DetailsWrapper>
    </FlexWrapper>
  );
};

const ViewAllCard = ({ onClick }: ViewAllCardProps) => (
  <FlexWrapper data-cy="offering-overview-wrapper">
    <SbuttonWrapper>
      <SButton onClick={onClick} buttonType="secondary">
        View all offerings
      </SButton>
    </SbuttonWrapper>
    <BlurWrapper>
      <ImgWrapper className="OfferingOverview__ImgItem">
        <OfferingImage loading="lazy" src={BackgroundViewAll} />
      </ImgWrapper>
      <InfoWrapper>
        <Heading type={4}>Offering Title</Heading>
      </InfoWrapper>
      <DetailsWrapper>
        <DetailItem>
          <DetailLabel small>Min. investment</DetailLabel>
          <DetailDescription small>$10k</DetailDescription>
        </DetailItem>
        <DetailItem>
          <DetailLabel small>Asset class</DetailLabel>
          <DetailDescription small>Asset class</DetailDescription>
        </DetailItem>
        <DetailItem lastItem>
          <DetailLabel small>Originator</DetailLabel>
          <DetailDescription small>originatorName</DetailDescription>
        </DetailItem>
        <DetailTags>
          <TagWrapper>
            <Badge
              isTag
              config={{
                text: 'offeringTag',
              }}
            />
          </TagWrapper>
        </DetailTags>
      </DetailsWrapper>
    </BlurWrapper>
  </FlexWrapper>
);

export const OfferingCard: FunctionComponent<OfferingCardProps> = ({
  offering,
  onClick,
  imageUrl,
  viewAllCard,
  className,
  showSummary,
}) => {
  if (viewAllCard) {
    return <ViewAllCard onClick={onClick} />;
  }

  return (
    <Card
      offering={offering}
      onClick={onClick}
      imageUrl={imageUrl}
      className={className}
      showSummary={showSummary}
    />
  );
};

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  position: relative;
  z-index: 2;
  padding: 10px;
  background: ${props => props.theme.colors.background_card};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  transition: box-shadow 0.3s;
  min-height: 376px;
  height: 100%;
  max-width: 376px;
  width: 100%;
  margin: 0 auto;

  :hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    ${props => (props.onClick ? 'cursor: pointer;' : '')}
  }
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const BlurWrapper = styled.div`
  filter: blur(6px);
  opacity: 0.5;
`;

const SbuttonWrapper = styled.div`
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
`;

const SButton = styled(Button)`
  max-width: 195px;
`;

const OfferingImage = styled.img`
  position: relative;
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 120px;
`;

const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
  margin: 0 -5px 10px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  margin: 0 5px 5px;
`;

const InfoWrapper = styled.div`
  margin: 20px 0;
  padding: 0 10px;
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 64px;
`;

const OfferingSummary = styled(Paragraph)`
  margin-top: 5px;
`;

const DetailsWrapper = styled.div`
  max-width: 100%;
  padding: 0 10px;
  margin: auto 0;
`;

const DetailItem = styled.div<DetaillItemProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DetailLabel = styled(Paragraph)``;

const DetailDescription = styled(Paragraph)`
  text-align: right;
`;

const TagWrapper = styled.div`
  display: flex;
`;

const DetailTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;
