import React from 'react';

import {
  ContentContainer,
  MainContainer,
  OfferingCardBaseContainer,
  TopContainer,
} from './OfferingCardBase.style';
import { OfferingCardBaseProps } from './OfferingCardBase.model';
import { theme2 } from '../../../themes';
import { SCard } from '../OfferingCardV2.style';

export const OfferingCardBase: React.FC<OfferingCardBaseProps> = props => {
  const { topComponent, mainComponent, contentComponent } = props;

  return (
    <SCard bgColor={theme2.colors.background_card} {...props}>
      <OfferingCardBaseContainer>
        <TopContainer>{topComponent ? topComponent : null}</TopContainer>
        <MainContainer>{mainComponent ? mainComponent : null}</MainContainer>
        <ContentContainer>{contentComponent ? contentComponent : null}</ContentContainer>
      </OfferingCardBaseContainer>
    </SCard>
  );
};
