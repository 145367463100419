import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
import {
  PortfolioDocumentsApiResponse,
  PortfolioDocumentsYearsRequest,
  PortfolioDocumentsRequest,
  PortfolioDocumentsResponse,
  PortfolioDocumentsYearsApiResponse,
  PortfolioDocumentsYearsResponse,
  PortfolioDownloadDocumentRequest,
  PortfolioDownloadDocumentResponse,
  PortfolioDocumentsListRequest,
} from './documents.model';

export const documentsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioDocuments, Tags.PortfolioDocumentsYears],
  })
  .injectEndpoints({
    endpoints: build => {
      const getDocumentsYears = build.query<
        PortfolioDocumentsYearsResponse,
        PortfolioDocumentsYearsRequest
      >({
        query: ({
          investorId,
          noteUrlHash,
          userId,
          managementStrategy,
        }: PortfolioDocumentsYearsRequest) => {
          return {
            url: `/a/api/portfolio/documents/available-years`,
            method: HttpMethods.Get,
            params: {
              account: investorId,
              urlHash: noteUrlHash,
              user: userId,
              managementStrategy,
            },
          };
        },
        providesTags: [Tags.PortfolioDocumentsYears],
        transformResponse: ({ years }: PortfolioDocumentsYearsApiResponse) => ({
          years,
        }),
      });

      const getTaxDocuments = build.query<PortfolioDocumentsResponse, PortfolioDocumentsRequest>({
        query: ({
          investorId,
          noteUrlHash,
          userId,
          year,
          managementStrategy,
        }: PortfolioDocumentsRequest) => {
          return {
            url: `/a/api/portfolio/documents`,
            method: HttpMethods.Get,
            params: {
              account: investorId,
              urlHash: noteUrlHash,
              user: userId,
              year,
              managementStrategy,
            },
          };
        },
        providesTags: [Tags.PortfolioDocuments],
        transformResponse: ({ documents }: PortfolioDocumentsApiResponse) => ({
          documents,
        }),
      });

      const downloadTaxDocuments = build.mutation<
        PortfolioDownloadDocumentResponse,
        PortfolioDownloadDocumentRequest
      >({
        query: ({ userId, taxDocumentIds }) => ({
          url: '/a/api/portfolio/documents/download-list',
          method: HttpMethods.Post,
          data: { user: userId, taxDocumentIds },
          responseType: 'blob',
        }),
        transformResponse: (response, headers) => {
          return {
            file: response,
            headers,
          };
        },
      });

      const getTaxDocumentsList = build.query<
        PortfolioDocumentsResponse,
        PortfolioDocumentsListRequest
      >({
        query: ({
          account,
          urlHashes,
          user,
          years,
          managementStrategy,
          taxDocumentTypes,
        }: PortfolioDocumentsListRequest) => {
          return {
            url: `/a/api/portfolio/documents/list`,
            method: HttpMethods.Get,
            params: {
              account,
              urlHashes,
              user,
              years,
              managementStrategy,
              taxDocumentTypes,
            },
          };
        },
        providesTags: [Tags.PortfolioDocuments],
        transformResponse: ({ documents }: PortfolioDocumentsApiResponse) => ({
          documents,
        }),
      });

      return {
        getDocumentsYears,
        getTaxDocuments,
        downloadTaxDocuments,
        getTaxDocumentsList,
      };
    },
  });
