import React from 'react';
import { ThemeProps, withTheme } from 'styled-components';

import CheckIcon from '../../assets/graphics/icons/checkmark.svg';
import CloseIcon from '../../assets/graphics/icons/close-icon-small.svg';

import { BaseProps, Theme } from '../../types';

import {
  ValidationIndicatorConfig,
  ValidationIndicatorProps,
  useTestIds,
} from './ValidationIndicator.model';

import { Icon, Indicator, IndicatorLabel } from './ValidationIndicator.style';

export const ValidationIndicatorComponent: React.FC<
  ValidationIndicatorProps & BaseProps & ThemeProps<Theme>
> = ({ touched, valid, children, config, theme, ...props }) => {
  const testIds = useTestIds(props);
  const defaultConfig: ValidationIndicatorConfig = {
    invalid: {
      icon: CloseIcon,
      color: theme.colors.notice_error,
      ...config?.invalid,
    },
    valid: {
      icon: CheckIcon,
      color: theme.colors.notice_success,
      ...config?.valid,
    },
  };

  return (
    <Indicator {...testIds.base.attr}>
      <Icon
        touched={touched}
        color={valid ? defaultConfig.valid.color : defaultConfig.invalid.color}
        icon={valid ? defaultConfig.valid.icon : defaultConfig.invalid.icon}
        {...testIds.icon.attr}
      ></Icon>
      <IndicatorLabel small {...testIds.label.attr}>
        {children}
      </IndicatorLabel>
    </Indicator>
  );
};

export const ValidationIndicator = withTheme(ValidationIndicatorComponent);
