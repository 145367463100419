import { HttpMethods } from '../../enums/http';

import { restApi, RTKQAll, Tags } from '../common';
import {
  GetKYCProviderMigrationStatusResponse,
  GetBankLinkProviderMigrationStatusResponse,
  StartProviderMigrationParams,
  BankLinkProviderMigrationStatus,
} from './investor-entity-provider-migration.model';

const BASE_URL = '/a/api';

export const investorEntityProviderMigrationApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.ProviderKYCMigrationStatus,
      Tags.ProviderBankLinkMigrationStatus,
      Tags.InvestorEntityStatus,
      Tags.BankAccount,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      const startProviderMigration = builder.mutation<{}, StartProviderMigrationParams>({
        query: ({ investorEntityId }) => ({
          url: `${BASE_URL}/kyc/investor-entity/${investorEntityId}/migrate`,
          method: HttpMethods.Post,
        }),
        invalidatesTags: [
          Tags.ProviderKYCMigrationStatus,
          Tags.ProviderBankLinkMigrationStatus,
          Tags.InvestorEntityStatus,
          Tags.BankAccount,
        ],
      });

      const getKYCProviderMigrationStatus = builder.query<
        GetKYCProviderMigrationStatusResponse,
        RTKQAll
      >({
        query: () => ({
          url: `${BASE_URL}/kyc/migration/eligibility`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.ProviderKYCMigrationStatus],
      });

      const getBankLinkProviderMigrationStatus = builder.query<
        BankLinkProviderMigrationStatus,
        RTKQAll
      >({
        query: () => ({
          url: `${BASE_URL}/linked-bank-accounts/migration`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.ProviderBankLinkMigrationStatus],
        transformResponse: (response: GetBankLinkProviderMigrationStatusResponse) => response.value,
      });

      return {
        startProviderMigration,
        getKYCProviderMigrationStatus,
        getBankLinkProviderMigrationStatus,
      };
    },
  });
