import { MARKETPLACE_FILTERS } from '../marketplace';

export const EDUCATION_MODULES = {
  [MARKETPLACE_FILTERS.Income]: {
    title: 'What is an income offering?',
    description:
      'These offerings are expected to generate income through recurring distribution payments.',
  },
  [MARKETPLACE_FILTERS.Growth]: {
    title: 'What is a growth offering?',
    description: 'These offerings are expected to appreciate during the target term.',
  },
  [MARKETPLACE_FILTERS.Balanced]: {
    title: 'What is a balanced offering?',
    description:
      'These offerings are expected to appreciate during the target term, while making some recurring distribution payments.',
  },
  [MARKETPLACE_FILTERS.LowMinimums]: {
    title: 'Which offerings provide low minimums?',
    description:
      'These offerings only require minimum investments of $5k or less. Additionally, certain offerings have lower minimums after your initial investment.',
  },
  [MARKETPLACE_FILTERS.New]: {
    title: 'Take a look at some of our new offerings.',
    description:
      'These offerings recently launched within the last couple of weeks or are expected to open soon.',
  },
};
