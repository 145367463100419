import { InvestorEntityAllStatusResponse } from './investor-entity-status.model';

export const transformAllInvestorEntityStatusResponse = (
  response: InvestorEntityAllStatusResponse
) =>
  response.investorEntities?.reduce((accountStatus, { id, status, type, typeDescription }) => {
    return {
      ...accountStatus,
      [id]: {
        ...status,
        id,
        type,
        typeDescription,
      },
    };
  }, {});
