export interface SocketManagerAddConnection {
  url: string;
  params?: any[];
  reconnectionStrategy?: SocketManagerReconnectionStrategy;
}

export interface SocketManagerConnection {
  sockets: Record<string, WebSocket>;
  config: SocketManagerAddConnection;
  events: Partial<SocketManagerEvents>[];
}

export interface SocketManagerEvents {
  onMessage: (socket: WebSocket, ev: MessageEvent) => any;
  onOpen: (socket: WebSocket, ev: Event) => any;
  onError: (socket: WebSocket, ev: Event) => any;
  onClose: (socket: WebSocket, ev: CloseEvent) => any;
}

export enum SocketManagerReconnectionStrategy {
  ignore = 'IGNORE',
  retry = 'RETRY',
}
