import React from 'react';
import {
  TextBlock,
  MarketingCinematicContainer,
  DesktopOnly,
  MobileOnly,
  Paragraph,
} from '@yieldstreet/ui-kit';

import assetClassesBanner from 'images/home/asset-classes-banner-desktop.png';
import assetClassesBannerMobile from 'images/home/asset-classes-banner-mobile.png';
import { AssetClassesImage, AssetClassesWrapper } from './AssetClasses.style';

export const AssetClasses = () => (
  <MarketingCinematicContainer withBorder>
    <AssetClassesWrapper>
      <TextBlock
        heading="Portfolio composition"
        align="center"
        mobileAlign="start"
        content={
          <>
            <DesktopOnly>
              <AssetClassesImage src={assetClassesBanner} />
            </DesktopOnly>
            <MobileOnly>
              <AssetClassesImage src={assetClassesBannerMobile} />
            </MobileOnly>
            <Paragraph marginTop="xl">
              As of 9/30/2024. Based on total invested and subject to change.
            </Paragraph>
          </>
        }
      />
    </AssetClassesWrapper>
  </MarketingCinematicContainer>
);
