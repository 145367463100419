import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { Heading, Paragraph } from '../../content/text';
import { Modal } from '../../elements/modal';
import { PageContainer } from '../../layout/page-container';
import { PageSection } from '../../layout';
import { media } from '../../themes/media';

import PlusIcon from './assets/plus.svg';
import QuotationIcon from './assets/icon.svg';

interface BioItem {
  name: string;
  title: string | React.ReactNode;
  image: React.ReactNode;
  bio: React.ReactNode;
  linkedIn?: string;
}

interface BioContainerProps {
  quote: string;
  quoteHeadingType?: 1 | 2 | 3 | 4 | 5;
  quoteBy: string;
  bios: BioItem[];
}

export const BioModalContainerV2: FunctionComponent<BioContainerProps> = ({
  quote,
  quoteHeadingType = 4,
  quoteBy,
  bios,
}) => {
  const [showBio, setShowBio] = useState<number | undefined>();

  return (
    <PageSection>
      <PageContainer>
        <OuterContainer>
          <Container>
            <Bios>
              {bios.map((bio, index) => (
                <>
                  <BioHolder key={`d-${index}`}>
                    <ImageHolder>{bio.image}</ImageHolder>
                    <InfoHolder>
                      <Paragraph small semiBold>
                        {bio.name}
                      </Paragraph>
                      <Paragraph small>{bio.title}</Paragraph>
                    </InfoHolder>
                    <Link onClick={() => setShowBio(index)}>
                      <img src={PlusIcon} width="10" height="10" alt="Read Bio" />
                    </Link>
                  </BioHolder>
                  {showBio === index && <MobileContent key={`m-${index}`}>{bio.bio}</MobileContent>}
                </>
              ))}
            </Bios>
            <ContentHolder>
              <Icon>
                <img src={QuotationIcon} alt="Quotation Marks" width="55" height="48" />
              </Icon>
              <SHeading type={quoteHeadingType}>{quote}</SHeading>
              <Paragraph>{quoteBy}</Paragraph>
            </ContentHolder>
          </Container>
          {bios.map(
            (bio, index) =>
              showBio === index && (
                <Modal
                  clickClose={() => setShowBio(undefined)}
                  className="modal"
                  key={`modal-bio-${index}`}
                >
                  <ModalHolder>
                    <ModalLeftContainer>
                      <ModalBioHolder>
                        <ModalImageHolder>{bio.image}</ModalImageHolder>
                        <Paragraph small semiBold>
                          {bio.name}
                        </Paragraph>
                        <Paragraph small>{bio.title}</Paragraph>
                      </ModalBioHolder>
                    </ModalLeftContainer>
                    <ModalRightContainer>{bio.bio}</ModalRightContainer>
                  </ModalHolder>
                </Modal>
              )
          )}
        </OuterContainer>
      </PageContainer>
    </PageSection>
  );
};

const OuterContainer = styled.div`
  .modal {
    display: none;
    ${media.desktop`
      display: -webkit-box;
    `}
    > div {
      border: none;
      max-width: 900px;
    }
  }
`;

const ModalHolder = styled.div`
  padding: 20px 20px 20px 0;
  display: flex;
`;

const ModalLeftContainer = styled.div`
  width: 25%;
`;

const ModalRightContainer = styled.div`
  width: 75%;
  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Container = styled.div``;

const InfoHolder = styled.div`
  padding: 0 20px;
`;

const ContentHolder = styled.div`
  width: 100%;
  margin: 60px auto;
  text-align: center;
  ${media.desktop`
    width: 40%;
  `}
`;

const Icon = styled.div``;

const Bios = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
  justify-content: center;
`;

const SHeading = styled(Heading)`
  margin-bottom: 20px;
`;

const Link = styled.div`
  position: absolute;
  top: 10px;
  cursor: pointer;
  right: 10px;
`;

const MobileContent = styled.div`
  display: block;
  text-align: left;
  margin-top: 20px;
  p:not(:last-child) {
    margin-bottom: 20px;
  }

  ${media.desktop`
    display: none;
  `}
`;

const BioHolder = styled.div`
  position: relative;
  background: ${props => props.theme.colors.background_card};
  border: 1px solid ${props => props.theme.colors.border_default};
  width: 100%;
  display: flex;
  padding: 20px;
  margin: 10px 0;
  ${media.desktop`
    width: 375px;
    margin: 0 20px;
  `}
`;

const ImageHolder = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
  img {
    display: block;
    margin: 0 auto;
    max-height: 60px;
  }
  text-align: center;
`;

const ModalImageHolder = styled.div`
  position: relative;
  width: 175px;
  margin: 0 auto 20px auto;
  img {
    max-width: 175px;
  }
  text-align: center;
`;

const ModalBioHolder = styled.div`
  text-align: center;
  ${media.desktop`
    padding: 0 20px 0 0;
  `}
`;
