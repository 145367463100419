import { ReactNode } from 'react';

import { genTestIds } from '@yieldstreet/tool-kit';

import { BaseProps } from '../../types';
import { TextBlockProps } from '../../sections/text-block-component/TextBlock.model';

export type BackgroundType = 'primary' | 'secondary';

export interface MarketingHeroBannerProps extends TextBlockProps, BaseProps {
  hideMediaOnMobile?: boolean;
  image?: ReactNode;
  background?: BackgroundType;
  reverse?: boolean;
  largeWidth?: boolean;
  mediaFramed?: boolean;
}

export const { getTestIds, useTestIds } = genTestIds(['marketing-hero-banner-test-id']);
