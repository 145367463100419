import { FormikInputComponent, BaseInputComponentType, withFormik } from '@yieldstreet/tool-kit';

import { scrollToQuery } from '../../utils/scroll';

export const withFormikWeb = <Value, CustomInputProps>(
  inputComponent: BaseInputComponentType<Value, CustomInputProps>
): FormikInputComponent<Value, CustomInputProps> => {
  // This HOC is used to add web specific callbacks to the
  // generic `withFormik` HOC from toolkit

  return withFormik(inputComponent, {
    onSubmitError: ({ name }) => scrollToQuery(`[data-cy='${name}']`, 'center'),
  });
};
