import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { Heading, Paragraph } from '../../../content/text';

interface TitleWithSubtitleProps {
  title?: ReactNode;
  headingType?: 1 | 2 | 3 | 4 | 5;
  inverse?: boolean;
  seperator?: boolean;
}

export const TitleWithSubtitle: FunctionComponent<TitleWithSubtitleProps> = ({
  title,
  headingType = 3,
  children,
  seperator,
  inverse,
}) => {
  return (
    <OuterContainer seperator={seperator}>
      <ContentHolder>
        <Title type={headingType} inverse={inverse}>
          {title}
        </Title>
        <Paragraph secondary inverse={inverse}>
          {children}
        </Paragraph>
      </ContentHolder>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<Partial<TitleWithSubtitleProps>>`
  display: flex;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &::after {
    ${props => (props.seperator ? `background: ${props.theme.colors.border_default}` : '')};
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 2px;
  }
`;

const ContentHolder = styled.div``;

const Title = styled(Heading)`
  margin-bottom: 20px;
`;
