import React from 'react';
import styled from 'styled-components';

export default () => {
  return (
    <Wrapper>
      <Title>CCPA Privacy Notice</Title>
      <SubTitle>PRIVACY STATEMENT-CALIFORNIA</SubTitle>
      <p>
        This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the information contained in the
        Privacy Statement of YieldStreet Inc. and its subsidiaries (collectively, “we,” “us,” or
        “our”) and applies solely to our visitors, users, and others who reside in the State of
        California (“consumers” or “you”). We adopt this notice to comply with the California
        Consumer Privacy Act of 2018 (“CCPA”). Any terms defined in the CCPA have the same meaning
        when used in this notice.
      </p>
      <p>
        Under the CCPA, this CCPA Privacy Notice and the privacy practices and rights it describes
        do not apply to the information we collect, use or disclose about consumers when they
        initiate the process of applying for our financial products and services, or receive our
        financial products and services. This is because this information is subject to the federal
        Gramm-Leach-Bliley Act (“GLBA”), and implementing regulations, or the California Financial
        Information Privacy Act (“FIPA”). The GLBA and/or FIPA governs the information of consumers
        who initiated the process of applying for our financial products or services, or receive our
        financial products and services. This CCPA Privacy Notice and the privacy practices and
        rights it describes also do not apply to representatives of businesses that seek to obtain
        our products or services, or to provide products or services to us.
      </p>
      <SectionTitle>Information We Collect</SectionTitle>
      <p>
        We collect information that identifies, relates to, describes, references, is capable of
        being associated with, or could reasonably be linked, directly or indirectly, with a
        particular consumer or device (“personal information”). In particular, we have collected the
        following categories of personal information from consumers within the last twelve (12)
        months:
      </p>
      <table>
        <tbody>
          <tr>
            <th>CCPA Category</th>
            <th>CCPA Examples</th>
          </tr>
          <tr>
            <td>A. Identifiers.</td>
            <td>
              A real name, alias, postal address, unique personal identifier, online identifier,
              Internet Protocol (IP) address, email address, account name, social security number,
              driver’s license number, passport number, or other similar identifiers.
            </td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California Customer Records statute
              (Cal. Civ. Code § 1798.80(e)).
            </td>
            <td>
              A name, signature, Social Security number, physical characteristics or description,
              address, telephone number, passport number, driver's license or state identification
              card number, insurance policy number, education, employment, employment history, bank
              account number, credit card number, debit card number, or any other financial
              information, medical information, or health insurance information. Some personal
              information included in this category may overlap with other categories.
            </td>
          </tr>
          <tr>
            <td>C. Protected classification characteristics under California or federal law.</td>
            <td>
              Age (40 years or older), race, color, ancestry, national origin, citizenship, religion
              or creed, marital status, medical condition, physical or mental disability, sex
              (including gender, gender identity, gender expression, pregnancy or childbirth and
              related medical conditions), sexual orientation, veteran or military status, genetic
              information (including familial genetic information).
            </td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity.</td>
            <td>
              Browsing history, search history, information on a consumer's interaction with a
              website, application, or advertisement.
            </td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information.</td>
            <td>
              Profile reflecting a person's preferences, characteristics, psychological trends,
              predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Please note that <i>personal information</i> excludes:
      </p>
      <ul>
        <li>Publicly available information from government records.</li>
        <li>De-identified or aggregated consumer information.</li>
        <li>
          Personal information covered by certain sector-specific privacy laws, including the Fair
          Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial
          Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
        </li>
      </ul>
      <p>
        We obtain the categories of personal information listed above from the following categories
        of sources:
      </p>
      <ul>
        <li>
          Directly from our clients. For example, from documents that our clients provide to us
          related to the services for which they engage us.
        </li>
        <li>
          From our clients’ agents. For example, through information we collect in the course of
          providing services to our clients.
        </li>
        <li>
          From activity on our website (www.yieldstreet.com). For example, from submissions through
          our website portal or website usage details collected automatically.
        </li>
        <li>From third parties that provide data to us.</li>
      </ul>
      <SectionTitle>Use of Personal Information</SectionTitle>
      <p>
        We may use or disclose the personal information we collect for one or more of the following
        business purposes:
      </p>
      <ul>
        <li>To provide the products or services you request.</li>
        <li>
          To provide you with topical and broad communications via SMS, email and push
          notifications, as part of your engagement with the YieldStreet platform (for instance,
          informing you of a new offering, or letting you know that you have received a distribution
          payment on an investment)
        </li>
        <li>
          To carry out our obligations and enforce our rights arising from any contracts entered
          into between you and us, including for billing and collections.
        </li>
        <li>To improve our website and present its contents to you.</li>
        <li>For testing, research, analysis and product development.</li>
        <li>
          As necessary or appropriate to protect the rights, property or safety of us, our clients
          or others.
        </li>
        <li>
          To respond to law enforcement requests and as required by applicable law, court order, or
          governmental regulations.
        </li>
        <li>
          As described to you when collecting your personal information or as otherwise set forth in
          the CCPA.
        </li>
        <li>
          To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution,
          or other sale or transfer of some or all of our assets, whether as a going concern or as
          part of bankruptcy, liquidation, or similar proceeding, in which personal information held
          by us is among the assets transferred.
        </li>
      </ul>
      <SectionTitle>Sharing Personal Information</SectionTitle>
      <p>We may disclose your personal information to a third party for a business purpose.</p>
      <p>
        In the preceding twelve (12) months, we have disclosed the following categories of personal
        information for a business purpose:
      </p>
      <p>Category A: &emsp;Identifiers.</p>
      <p>Category B: &emsp;California Customer Records personal information categories.</p>
      <p>Category C: &emsp;classification characteristics under California or federal law.</p>
      <p>Category F: &emsp;Internet or other similar network activity information.</p>
      <p>
        We disclose your personal information for a business purpose to the following categories of
        third parties:
      </p>
      <ul>
        <li>
          Service providers. These include (but are not limited to) providers such as our banking
          and payment partners, our customer relationship management partners communication
          providers (email, SMS, etc.)
        </li>
        <li>
          Third parties to whom you or your agents direct us to disclose your personal information
          in connection with products or services we provide to you (e.g. your accountant or CPA to
          verify your accreditation status to us)
        </li>
      </ul>
      <p>
        YieldStreet <b>does not sell personal information</b>. Like many companies online, we use
        cookies and other tracking technologies to analyze website traffic and facilitate
        advertising. If you would like to learn how you may opt out of our (and our third party
        advertising partners’) use of cookies and other tracking technologies, see the “Online
        Tracking” section of “Your Rights and Choices,” below.
      </p>
      <SectionTitle>Your Rights and Choices</SectionTitle>
      <p>
        The CCPA provides consumers (California residents) whose information is subject to the
        CCPAwith specific rights regarding their personal information. This section describes your
        CCPA rights and explains how to exercise those rights.
      </p>
      <p>
        <i>Information and Access Requests</i>
      </p>
      <p>
        You have the right to request that we disclose certain information to you about our
        collection and use of your personal information over the past 12 months. Once we receive and
        confirm your verifiable consumer request, we will disclose to you:
      </p>
      <ul>
        <li>The categories of personal information we collected about you.</li>
        <li>The categories of sources for the personal information we collected about you.</li>
        <li>
          Our business or commercial purpose for collecting or selling that personal information.
        </li>
        <li>The categories of third parties with whom we share that personal information.</li>
        <li>The specific pieces of personal information we collected about you.</li>
        <li>
          If we disclosed your personal information for a business purpose, a separate list of the
          disclosures for a business purpose, identifying the personal information categories that
          each category of recipient obtained.
        </li>
      </ul>
      <p>
        <i>Deletion Request Rights</i>
      </p>
      <p>
        You have the right to request that we delete any of your personal information that we
        collected from you and retained, subject to certain exceptions. Once we receive and confirm
        your verifiable consumer request, we will delete your personal information from our records,
        unless an exception applies.
      </p>
      <p>We may deny your deletion request where required or permitted by applicable law.</p>
      <p>
        <i>Exercising Information Access and Deletion Rights</i>
      </p>
      <p>
        To exercise the access and deletion rights described above, please submit a verifiable
        consumer request to us by either:
      </p>
      <ul>
        <li>Calling us at 844.943.5378</li>
        <li>
          Emailing us at{' '}
          <i>
            <a href="mailto:privacy@yieldstreet.com">privacy@yieldstreet.com</a>
          </i>{' '}
          (with “<b>Request to access or delete my data under CCPA</b>” in the subject line)
        </li>
      </ul>
      <p>
        Only you or a person registered with the California Secretary of State that you authorize to
        act on your behalf, may make a verifiable consumer request related to your personal
        information.
      </p>
      <p>
        You may only make a verifiable consumer request for access twice within a 12-month period.
        The verifiable consumer request must:
      </p>
      <ul>
        <li>
          Provide sufficient information that allows us to reasonably verify you are the person
          about whom we collected personal information or an authorized representative.
        </li>
        <li>
          Describe your request with sufficient detail that allows us to properly understand,
          evaluate, and respond to it.
        </li>
      </ul>
      <p>
        We cannot respond to your request or provide you with personal information if we cannot
        verify your identity or authority to make the request and confirm the personal information
        relates to you. Making a verifiable consumer request does not require you to create an
        account with us. We will only use personal information provided in a verifiable consumer
        request to verify the requestor's identity or authority to make the request.
      </p>
      <p>
        <i>Response Timing and Format</i>
      </p>
      <p>
        We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we
        require more time (up to 90 days), we will inform you of the reason and extension period in
        writing. If you have an account with us, we will deliver our written response via a secure
        link sent to the registered email address on the account. Any disclosures we provide will
        only cover the 12-month period preceding the verifiable consumer request's receipt. The
        response we provide will also explain the reasons we cannot comply with a request, if
        applicable.
      </p>
      <p>
        <i>Online Tracking Opt-out</i>
      </p>
      <p>
        We use on our websites services provided by Google, Facebook and other companies that use
        tracking technology. These services rely on tracking technologies – such as cookies and web
        beacons – to collect directly from your device information about your browsing activities,
        your interactions with websites, and the device you are using to connect to the Internet.
        There are a number of ways to opt out of having your online activity and device data
        collected through these services, which we have summarized below:
      </p>
      <ol>
        <li>
          <b>Blocking cookies in your browser.</b> Most browsers let you remove or reject cookies,
          including cookies used for interest-based advertising. To do this, follow the instructions
          in your browser settings. Many browsers accept cookies by default until you change your
          settings. For more information about cookies, including how to see what cookies have been
          set on your device and how to manage and delete them, visit{' '}
          <a href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Furldefense.proofpoint.com%2Fv2%2Furl%3Fu%3Dhttps-3A__nam04.safelinks.protection.outlook.com_-3Furl-3Dhttp-253A-252F-252Fwww.allaboutcookies.org-26data-3D01-257C01-257Cmsher-2540tegna.com-257C0b9591b3164a4223a43a08d76c83aa09-257Cccd8a79b7268413b878971f8b6f4c0df-257C0-26sdata-3D3xLYXSY5MjqtQeA0dtIlCumR6FW6C567mxVQoDVamtY-253D-26reserved-3D0%26d%3DDwMGaQ%26c%3D5vOBDmAB7iEjDDOZLSiuMw%26r%3D_YfAy831FkbZfTnxNug9lr52Tp3YXZ2rXWQiKuJkKFI%26m%3DFEz0wMvPsct8JxsR6hb29zWVG-8y5VDXFe9FQksk7T4%26s%3DW7Wplj06dIYdI6sxd6ob-ZV1U-iWjJiEZr1WSSFlzDI%26e%3D&data=01%7C01%7Cmsher%40tegna.com%7C7ea8404e46834aead0b508d76c9655ae%7Cccd8a79b7268413b878971f8b6f4c0df%7C0&sdata=r30F%2FwpcDJ3ylZQOlCvshAQ1c6D0BqYsDuHplcUR%2BmY%3D&reserved=0">
            www.allaboutcookies.org
          </a>
          .
        </li>
        <li>
          <b>Blocking advertising ID use in your mobile settings.</b> Your mobile device settings
          may provide functionality to limit use of the advertising ID associated with your mobile
          device for interest-based advertising purposes.
        </li>
        <li>
          <b>Using privacy plug-ins or browsers.</b> You can block our websites from setting cookies
          used for interest-based ads by using a browser with privacy features, like{' '}
          <a href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Furldefense.proofpoint.com%2Fv2%2Furl%3Fu%3Dhttps-3A__nam04.safelinks.protection.outlook.com_-3Furl-3Dhttps-253A-252F-252Fbrave.com-252F-26data-3D01-257C01-257Cmsher-2540tegna.com-257C0b9591b3164a4223a43a08d76c83aa09-257Cccd8a79b7268413b878971f8b6f4c0df-257C0-26sdata-3DUUzohOD2NP0YJpbbfwso2QTS2i8pq0kXH06ykyfiIq8-253D-26reserved-3D0%26d%3DDwMGaQ%26c%3D5vOBDmAB7iEjDDOZLSiuMw%26r%3D_YfAy831FkbZfTnxNug9lr52Tp3YXZ2rXWQiKuJkKFI%26m%3DFEz0wMvPsct8JxsR6hb29zWVG-8y5VDXFe9FQksk7T4%26s%3Dysxlhh4JTpssHkwb8Mu8h5PxQLll4Mov41Sw_8Qp76I%26e%3D&data=01%7C01%7Cmsher%40tegna.com%7C7ea8404e46834aead0b508d76c9655ae%7Cccd8a79b7268413b878971f8b6f4c0df%7C0&sdata=AKSjw7Jg1wvYbiEUlNhbmc%2Bqra3R%2B2tZhtWt67RbbS0%3D&reserved=0">
            Brave
          </a>
          , or installing browser plugins like{' '}
          <a href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Furldefense.proofpoint.com%2Fv2%2Furl%3Fu%3Dhttps-3A__nam04.safelinks.protection.outlook.com_-3Furl-3Dhttps-253A-252F-252Fwww.eff.org-252Fprivacybadger-26data-3D01-257C01-257Cmsher-2540tegna.com-257C0b9591b3164a4223a43a08d76c83aa09-257Cccd8a79b7268413b878971f8b6f4c0df-257C0-26sdata-3DiJtqQjcTdglt-252FrPTx8o6YMItndgN6VPZa1ATsvgv5Nw-253D-26reserved-3D0%26d%3DDwMGaQ%26c%3D5vOBDmAB7iEjDDOZLSiuMw%26r%3D_YfAy831FkbZfTnxNug9lr52Tp3YXZ2rXWQiKuJkKFI%26m%3DFEz0wMvPsct8JxsR6hb29zWVG-8y5VDXFe9FQksk7T4%26s%3D_S7MveGOoRQjo7McNLNB0H3rHpH8eaM_Yt6Hzh58uPk%26e%3D&data=01%7C01%7Cmsher%40tegna.com%7C7ea8404e46834aead0b508d76c9655ae%7Cccd8a79b7268413b878971f8b6f4c0df%7C0&sdata=MzS5aNpiW6nJlOPssJDZUh%2BYJV1wuTTbxDtMIZXlF1c%3D&reserved=0">
            Privacy Badger
          </a>
          ,{' '}
          <a href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Furldefense.proofpoint.com%2Fv2%2Furl%3Fu%3Dhttps-3A__nam04.safelinks.protection.outlook.com_-3Furl-3Dhttps-253A-252F-252Fwww.ghostery.com-252F-26data-3D01-257C01-257Cmsher-2540tegna.com-257C0b9591b3164a4223a43a08d76c83aa09-257Cccd8a79b7268413b878971f8b6f4c0df-257C0-26sdata-3D7bLkXwlBB4RlOOrai33RDGlywD9D3Uz3W7dbyvE3w6o-253D-26reserved-3D0%26d%3DDwMGaQ%26c%3D5vOBDmAB7iEjDDOZLSiuMw%26r%3D_YfAy831FkbZfTnxNug9lr52Tp3YXZ2rXWQiKuJkKFI%26m%3DFEz0wMvPsct8JxsR6hb29zWVG-8y5VDXFe9FQksk7T4%26s%3Di3OHw0osHTWUVDetkFWDjpGzPg8q4fGocVN5sCp3U1g%26e%3D&data=01%7C01%7Cmsher%40tegna.com%7C7ea8404e46834aead0b508d76c9655ae%7Cccd8a79b7268413b878971f8b6f4c0df%7C0&sdata=I9hXONIe0LOL0SJZGBSsJ2qIgbLBr0XwmId%2BKl%2BPsYM%3D&reserved=0">
            Ghostery
          </a>{' '}
          or
          <a href="https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Furldefense.proofpoint.com%2Fv2%2Furl%3Fu%3Dhttps-3A__nam04.safelinks.protection.outlook.com_-3Furl-3Dhttps-253A-252F-252Fublock.org-252F-26data-3D01-257C01-257Cmsher-2540tegna.com-257C0b9591b3164a4223a43a08d76c83aa09-257Cccd8a79b7268413b878971f8b6f4c0df-257C0-26sdata-3DI05aiOXYuD-252Fs20Ki89cnG6Toznv9352OsTLBOQ01kXI-253D-26reserved-3D0%26d%3DDwMGaQ%26c%3D5vOBDmAB7iEjDDOZLSiuMw%26r%3D_YfAy831FkbZfTnxNug9lr52Tp3YXZ2rXWQiKuJkKFI%26m%3DFEz0wMvPsct8JxsR6hb29zWVG-8y5VDXFe9FQksk7T4%26s%3DZ8WU2G-MdK8muHPPXCH-2NYAwWN_9R4yUoxENaRFe0Y%26e%3D&data=01%7C01%7Cmsher%40tegna.com%7C7ea8404e46834aead0b508d76c9655ae%7Cccd8a79b7268413b878971f8b6f4c0df%7C0&sdata=h7Wtr1uQNS9PrvWN5Bc1215bBl2iMR5jrJmykVcx5Qo%3D&reserved=0">
            {' '}
            uBlock Origin
          </a>
          , and configuring them to block third party cookies/trackers.
        </li>
        <li>
          <b>Platform opt-outs.</b> Some of our advertising partners offer opt-out features that let
          you opt-out of use of your information for interest-based advertising. For example, you
          may opt out of interest-based advertising by some of our partners by visiting the
          following links:
          <ul>
            <li>
              Google: <a href="https://adssettings.google.com">https://adssettings.google.com</a>
            </li>
            <li>
              Microsoft:{' '}
              <a href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads">
                https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads
              </a>
            </li>
            <li>
              Facebook:{' '}
              <a href="https://www.facebook.com/about/ads">https://www.facebook.com/about/ads</a>
            </li>
            <li>
              LinkedIn:{' '}
              <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
                https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
              </a>
            </li>
            <li>
              Outbrain:{' '}
              <a href="https://my.outbrain.com/recommendations-settings/home">
                https://my.outbrain.com/recommendations-settings/home
              </a>
            </li>
            <li>
              Taboola:{' '}
              <a href="https://www.taboola.com/privacy-policy#optout">
                https://www.taboola.com/privacy-policy#optout
              </a>
            </li>
          </ul>
        </li>
        <li>
          <b>Advertising industry opt-out tools.</b> You can also use these opt-out options to limit
          use of your information for interest-based advertising by participating companies:
          <ul>
            <li>
              Digital advertising Alliance:{' '}
              <a href="http://optout.aboutads.info">http://optout.aboutads.info</a>
            </li>
            <li>
              Network Advertising Initiative:{' '}
              <a href="http://optout.networkadvertising.org/?c=1">
                http://optout.networkadvertising.org/?c=1
              </a>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Note that because these opt-out mechanisms are specific to the device or browser on which
        they are exercised, you will need to opt-out on every browser and device that you use.
      </p>
      <SectionTitle>Non-Discrimination</SectionTitle>
      <p>We will not discriminate against you for exercising any of your CCPA rights.</p>
      <SectionTitle>Changes to Our Privacy Notice</SectionTitle>
      <p>
        We reserve the right to amend this privacy notice at our discretion and at any time. When we
        make changes to this privacy notice, we will notify you by email or through a notice on our
        website homepage.
      </p>
      <SectionTitle>Contact Information</SectionTitle>
      <p>
        If you have any questions or comments about this notice, our Privacy Statement, the ways in
        which we collect and use your personal information, your choices and rights regarding such
        use, or wish to exercise your rights under California law, please do not hesitate to contact
        us at:
      </p>
      <p>Phone: 844.943.5378</p>
      <p>
        Email: <a href="mailto:privacy@yieldstreet.com">privacy@yieldstreet.com</a>
      </p>
      <div>Postal Address:</div>
      <div>YieldStreet Inc.</div>
      <div>Attn: Rebecca Fine </div>
      <div>300 Park Ave, Floor 15</div>
      <div>New York NY 10022</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.gray2};
  p {
    margin: 12px 0;
  }
  li {
    list-style-type: disc;
    margin-left: 50px;
  }
  table {
    margin: 25px 0;
    border-collapse: collapse;
  }
  tr,
  td,
  th {
    border: 1px solid;
    border-color: ${props => props.theme.colors.gray1};
  }
  td,
  th {
    padding: 10px;
  }
`;

const Title = styled.div`
  margin-top: 15px;
  font-size: 33px;
  line-height: 54px;
  font-weight: 300;
`;

const SubTitle = styled.div`
  margin-top: 15px;
  font-size: 27px;
  line-height: 54px;
  font-weight: 300;
`;

const SectionTitle = styled.div`
  line-height: 26px;
  font-size: 18px;
  margin: 30px 0 0;
`;
