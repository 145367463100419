import { CreateApiOptions } from '@reduxjs/toolkit/query';
import { ReducerPaths } from './reducer-path';

type FullConfig = CreateApiOptions<any, any, ReducerPaths>;

export type BaseQueryConfig = Pick<FullConfig, 'keepUnusedDataFor' | 'baseQuery'>;

type BaseConfig = Omit<BaseQueryConfig, 'baseQuery'>;

export const BASE_CONFIG: BaseConfig = {
  keepUnusedDataFor: 50,
};
