import React, { useState, useEffect } from 'react';
import { media, Modal, LinkStyles } from '@yieldstreet/ui-kit';
import styled from 'styled-components';

import CCPA from './PrivacyContent/CCPA';
import privacyPolicyData from './PrivacyContent/privacyPolicyData';
import termsData from './PrivacyContent/termsData';

interface PrivacyFrameModalProps {
  hideModal: () => void;
}

interface StyledModalProps {
  id?: string;
}

const PrivacyFrameModal = ({ hideModal }: PrivacyFrameModalProps) => {
  const [articleHtml, setArticleHtml] = useState('');
  const [articleTitle, setArticleTitle] = useState('');
  const [isCCPA, setCCPA] = useState(false);

  useEffect(() => {
    refetchContent('privacy');
    window.addEventListener('hashchange', hashDetector, false);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('hashchange', hashDetector, false);

      if (window.history.pushState) {
        window.history.pushState('', '/', window.location.pathname);
      } else {
        window.location.hash = '';
      }
    };
  }, []);

  const refetchContent = (slug: string) => {
    if (slug === 'privacy') {
      setArticleTitle(privacyPolicyData.title);
      setArticleHtml(privacyPolicyData.content);
    } else if (slug === 'terms') {
      setArticleTitle(termsData.title);
      setArticleHtml(termsData.content);
    }
  };

  const parseAnchors = (rawContent: string) => {
    let content = rawContent;
    const anchors = [...new Set(content.match(/<a\s(href.*?)\/a>/gm))];

    if (!!anchors) {
      anchors.forEach(anchor => {
        const isTarget = anchor.match(/"(\/privacy)|"\/terms|\/pages\/ccpa-privacy/gm);

        if (isTarget) {
          const target = anchor
            .match(/"(\/privacy)|"\/terms|\/pages\/ccpa-privacy/gm)![0]
            .replace(/"|\//gi, '');
          const text = anchor.match(/>(.*?)</gi)![0].replace(/>|</gi, '');

          content = content.replace(new RegExp(anchor, 'gi'), `<a href="#${target}">${text}</a>`);
        }
      });

      return <span dangerouslySetInnerHTML={{ __html: content }} />;
    }
  };

  const hashDetector = () => {
    const hash = window.location.hash.replace('#', '');

    if (hash === 'pagesccpa-privacy') {
      setCCPA(true);
    } else {
      setCCPA(false);
      refetchContent(hash);
    }

    window.document
      .querySelector('#privacyFrameModal > div > div')!
      .scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Wrapper id="privacyFrameModal">
      <StyledModal clickClose={hideModal}>
        <InnerWrapper>
          {!isCCPA ? (
            <>
              <ContentTitle>{articleTitle}</ContentTitle>
              {articleHtml && <Content>{parseAnchors(articleHtml)}</Content>}
            </>
          ) : (
            <Content>
              <CCPA />
            </Content>
          )}
        </InnerWrapper>
      </StyledModal>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyledModal = styled(Modal)<StyledModalProps>`
  background: rgba(0, 0, 0, 0.8);
  max-width: 100% !important;
  padding: 0 !important;
  z-index: 9999;
  text-align: left;
  > div {
    background: ${props => props.theme.colors.gray5};
    max-width: 1180px;
    width: 90%;
    margin: 0 auto;
  }
`;

const InnerWrapper = styled.div`
  color: ${props => props.theme.colors.gray1};

  ${media.tablet`
    padding: 0 30px;
  `};
`;

const ContentTitle = styled.h1`
  margin-top: 15px;
  font-size: 33px;
  font-weight: 300;
  color: ${props => props.theme.colors.gray2};
  font-family: ${props => props.theme.typography.secondary};
`;

const Content = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.gray2};
  padding-bottom: 20px;
  h4 {
    font-style: normal;
    font-weight: 400;
    margin: 30px 0 5px;
    font-family: ${props => props.theme.typography.secondary};
  }
  a {
    ${LinkStyles}
  }
  p {
    margin: 0 0 10px;
  }
  ul {
    padding-inline-start: 40px;
  }
  li {
    list-style: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  .highlight {
    font-style: normal;
    font-weight: 500;
  }
  .last-update {
    font-size: 12px;
    color: #96989a;
    padding-bottom: 63px;
  }
`;

export default PrivacyFrameModal;
