import { genTestIds } from '@yieldstreet/tool-kit';

import { ButtonType } from '../../content';

const { getTestIds } = genTestIds([
  'container',
  'logo',
  'title',
  'description',
  'cta',
  'step',
  'step-text',
]);

export const pageStepTestIds = getTestIds('page-step');

export interface PageStepContentProps {
  title: string;
  description: string;
  cta?: {
    type?: ButtonType;
    text?: string;
    callback?: () => void;
  };
}

export interface PageStepScrollProps {
  step?: {
    current?: number;
    items?: string[];
    text?: string;
  };
}

export interface PageStepProps extends PageStepContentProps, PageStepScrollProps {}
