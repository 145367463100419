import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { PageSection } from '../../layout/page-section';
import { Heading, Paragraph } from '../../content/text';
import { CallbackLink } from '../../content/link';
import { media } from '../../themes';

interface ContentProps {
  expanded: boolean;
}

interface ManifestoProps {
  title?: string;
  summaryTitle?: string;
  summary?: string;
}

export const Manifesto: FunctionComponent<ManifestoProps> = ({
  title,
  summaryTitle,
  summary,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  const expandedClick = () => {
    setExpanded(!expanded);
  };

  return (
    <PageSection>
      <Container>
        <TitleHolder>
          <Title type={3}>{title}</Title>
        </TitleHolder>
        <ContentHolder>
          <SummaryHolder>
            {summaryTitle && <SubTitle semiBold>{summaryTitle}</SubTitle>}
            {summary && <Text>{summary}</Text>}
          </SummaryHolder>
          <Content expanded={expanded}>{children}</Content>
          <CallbackLink callback={() => expandedClick()}>
            {expanded ? `Collapse` : `Expand`}
          </CallbackLink>
        </ContentHolder>
      </Container>
    </PageSection>
  );
};

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: 'column';
  ${media.desktop`
    flex-direction: 'row';
    padding: 0 130px 100px 130px;
  `}
`;

const TitleHolder = styled.div`
  width: 100%;
  ${media.desktop`
    margin: 60px 0 0 0;
    width: 50%;
  `}
`;

const ContentHolder = styled.div`
  width: 100%;
  margin: 20px 0 0 0;
  ${media.desktop`
    width: 50%;
    margin: 75px 0 0 0;
  `}
  p {
    margin: 0 0 20px 0;
  }
`;

const Title = styled(Heading)`
  width: 100%;
  ${media.desktop`
    width: 60%;
  `}
`;

const Content = styled.div<ContentProps>`
  max-height: ${props => (props.expanded ? `2000px` : 0)};
  transition: max-height 0.5s ${props => (props.expanded ? `ease-in` : `ease-out`)};
  overflow: hidden;
  height: auto;
`;

const SummaryHolder = styled.div``;

const SubTitle = styled(Paragraph)``;

const Text = styled(Paragraph)``;
