import React from 'react';
import { TextBlock, SectionContainer } from '@yieldstreet/ui-kit';

import stradley from 'images/home/stradley-logo.png';
import deloitte from 'images/home/deloitte-logo.png';
import prytania from 'images/home/prytania-logo.png';
import sseclogo from 'images/home/SS&C-logo.png';
import { PartnersWrapper, PartnersImage } from './Partners.style';

export const Partners = () => (
  <SectionContainer>
    <TextBlock
      heading="Management partners"
      headingTagType={3}
      headingType={3}
      align="center"
      content={
        <PartnersWrapper>
          <PartnersImage src={stradley} alt="Stradely Sr Ronon - Legal counsel" />
          <PartnersImage src={deloitte} alt="Deloitte - Auditor" />
          <PartnersImage src={prytania} alt="Prytania - Sub Adviser" />
          <PartnersImage src={sseclogo} alt="SS&C - Administrator" />
        </PartnersWrapper>
      }
    />
  </SectionContainer>
);
