import styled from 'styled-components';

import { Label } from '../../content/text';

import { ValidationIndicatorIconProps } from './ValidationIndicator.model';

export const Indicator = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

export const Icon = styled.div<ValidationIndicatorIconProps>`
  position: relative;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  transition: 0.5s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 75% 75%;
    mask-size: 75% 75%;
  }

  box-shadow: inset 0 0 0 1px white;
  border: 1px solid
    ${props => {
      if (!props.touched) return props.theme.colors.border_default;
      return props.color;
    }};

  background-color: ${props => {
    if (!props.touched) return props.theme.colors.border_default;
    return props.color;
  }};
`;

export const IndicatorLabel = styled(Label)`
  flex: 1 1 auto;
`;
