import {
  GetUserProfileResponse,
  UserProfileUpdatePhoneApiResponse,
  UserProfileUpdatePhoneRequest,
  UpdateUserProfileParams,
  UpdateUserProfileResponse,
} from './user-profile.model';
import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums/http';

export const userProfileApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserProfile, Tags.UserContact],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getUserProfile = builder.query<GetUserProfileResponse, {}>({
        query: () => ({
          url: `/a/api/investor/profile`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.UserProfile],
      });

      const updateUserProfile = builder.mutation<
        UpdateUserProfileResponse,
        UpdateUserProfileParams
      >({
        query: ({ userId, data }) => ({
          url: `/a/api/user/${userId}/investor-profile`,
          method: HttpMethods.Patch,
          data,
        }),
        invalidatesTags: [Tags.UserProfile],
      });

      const updateUserProfilePhone = builder.mutation<
        UserProfileUpdatePhoneApiResponse,
        UserProfileUpdatePhoneRequest
      >({
        query: ({ ...data }) => {
          return {
            url: `/a/api/investor/profile/phone`,
            method: HttpMethods.Put,
            data,
          };
        },
        invalidatesTags: [Tags.UserProfile, Tags.UserContact],
      });

      return {
        getUserProfile,
        updateUserProfile,
        updateUserProfilePhone,
      };
    },
  });
