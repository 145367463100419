import { OfferingPromotion, Promotion } from '../../apis';
import { PaymentMethodType, Cadence } from '../../enums';

export enum InvestmentGateType {
  NO_SOURCE_BANK_ACCOUNT = 'NO_SOURCE_BANK_ACCOUNT',
  INVESTMENT_REQUEST_NOT_ALLOCATED = 'INVESTMENT_REQUEST_NOT_ALLOCATED',
  INVESTMENT_REQUEST_NOT_ACTIVE = 'INVESTMENT_REQUEST_NOT_ACTIVE',
  DEPOSIT_PAYMENT_PENDING = 'DEPOSIT_PAYMENT_PENDING',
  ALLOCATION_PAYMENT_PENDING = 'ALLOCATION_PAYMENT_PENDING',
  INVESTOR_ACCOUNT_AIQ = 'INVESTOR_ACCOUNT_AIQ',
  BANK_ACCOUNT_PENDING = 'BANK_ACCOUNT_PENDING',
  PAYMENT_NOT_INITIALIZED = 'PAYMENT_NOT_INITIALIZED',
  CRYPTO_PAYMENT_PENDING = 'CRYPTO_PAYMENT_PENDING',
  IDENTITY_NOT_VERIFIED = 'IDENTITY_NOT_VERIFIED',
  FUND_ACCOUNT_NOT_CONFIRMED = 'FUND_ACCOUNT_NOT_CONFIRMED',
  INVESTMENT_REQUEST_HELD = 'INVESTMENT_REQUEST_HELD',
  INVESTMENT_REQUEST_WAITLISTED = 'INVESTMENT_REQUEST_WAITLISTED',
  INSUFFICIENT_WALLET_BALANCE = 'INSUFFICIENT_WALLET_BALANCE',
  ROLLOVER_PENDING = 'ROLLOVER_PENDING',
}

export enum GateOwnerEnum {
  user = 'USER',
  ys = 'YS',
}

export type GateOwnerType = `${GateOwnerEnum}`;

export const gateOwner = {
  ...GateOwnerEnum,
};

export enum GateStatusEnum {
  cleared = 'CLEARED',
  active = 'ACTIVE',
  failed = 'FAILED',
}

export type GateStatusType = `${GateStatusEnum}`;

export const gateStatus = {
  ...GateStatusEnum,
};

export type GateAttributes = {
  paymentMethod: PaymentMethodType;
  aiqGateType?: string;
  bankStep?: BankStepType;
};

export interface Gates {
  id: string;
  irServiceInvestmentId: string;
  externalInvestmentId: number;
  noteUrlHash: string;
  investorAccountId: string;
  adminUserId?: string;
  priority: number;
  timestamp: number;
  date: string;
  type: GateTypeType;
  status: GateStatusType;
  clearReason: string;
  owner: GateOwnerType;
  attributes?: GateAttributes;
  visibility?: string;
}
export interface InvestmentGatesDetail {
  gates: Array<Gates>;
  requiresUserAttention: boolean;
}

// the following can be replaced with InvestmentGateType
export enum gateType {
  noSourceBankAccount = 'NO_SOURCE_BANK_ACCOUNT',
  depositPaymentPending = 'DEPOSIT_PAYMENT_PENDING',
  bankAccountPending = 'BANK_ACCOUNT_PENDING',
  // @deprecated: PAYMENT_NOT_INITIALIZED is being phased out
  paymentNotInitialized = 'PAYMENT_NOT_INITIALIZED',
  insufficientwalletbalance = 'INSUFFICIENT_WALLET_BALANCE',

  requestNotAllocated = 'INVESTMENT_REQUEST_NOT_ALLOCATED',
  rolloverPending = 'ROLLOVER_PENDING',
  requestNotActive = 'INVESTMENT_REQUEST_NOT_ACTIVE',
  allocationPaymentPending = 'ALLOCATION_PAYMENT_PENDING',

  aiq = 'INVESTOR_ACCOUNT_AIQ',
}

export type GateTypeType = `${InvestmentGateType}`;

export const GateType = {
  ...gateType,
};

export enum GateActions {
  FUND_INVESTMENT = 'FUND_INVESTMENT',
  NO_SOURCE_BANK_ACCOUNT = 'NO_SOURCE_BANK_ACCOUNT',
  INSUFFICIENT_WALLET_BALANCE = 'INSUFFICIENT_WALLET_BALANCE',
  DEPOSIT_PAYMENT_PENDING = 'DEPOSIT_PAYMENT_PENDING',
  BANK_ACCOUNT_PENDING = 'BANK_ACCOUNT_PENDING',
  // @deprecated: PAYMENT_NOT_INITIALIZED is being phased out
  PAYMENT_NOT_INITIALIZED = 'PAYMENT_NOT_INITIALIZED',

  WIRE_TRANSFER = 'WIRE_TRANSFER',
  ACH_PAYMENT = 'ACH_PAYMENT',

  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  AIQ_FAILED = 'AIQ_FAILED',
  AIQ_MISSING = 'AIQ_MISSING',

  // ETC Purchase request - this is not a gate
  ETC_PURCHASE_REQUEST_FAILED = 'ETC_PURCHASE_REQUEST_FAILED',
}

export enum BankStepEnum {
  createAccount = 'CREATE_BANK_ACCOUNT',
  verifyDeposits = 'VERIFY_MICRO_DEPOSITS',
}

export type BankStepType = `${BankStepEnum}`;

export const bankAccountGateActions = {
  ...BankStepEnum,
};

export type GateObject = {
  type: GateTypeType;
  status: GateStatusType;
  priority: number;
  owner: GateOwnerType;
  timestamp: number;
  attributes?: GateAttributes;
};

export type SocketOffering = {
  noteUrlHash: string;
  cadence?: Cadence | null;
  shortName: string;
  spvBankAccountName: string;
  spvBankAccountNumber: string;
  title: string;
};

export type SocketData = {
  investment?: {
    amount: number;
    bankAccountId?: string;
    dateSubmitted?: string;
    fundingAmount?: string;
    id: string;
    investorAccountId: string;
    noteUrlHash: string;
    payInBankAccountId?: string;
    payInPaymentMethod?: string;
    paymentMethod?: string;
    rollover?: boolean;
    shortId?: string;
    status: string;
    timestamp?: number;
    userId?: string;
    waitlisted?: boolean;

    dateConfirmed?: string;
    dateActive?: string;
    processOn?: 0 | 2;
    expectedDpstPaymentSettlementDate?: number;
    committedAmount?: number;
    promotionKey?: string;
  };
  promotion?: Promotion;
  offeringPromotion?: OfferingPromotion;
  paymentMethod?: PaymentMethodType;
  investmentGates?: Array<GateObject>;
  walletFundingRequest?: {
    amount: string;
    bank_account_id: string;
    transferDate: string;
  };
  investmentFundingSource?: {
    amount: string;
    bank_account_id: string;
    transferDate?: string;
  };
  offering?: SocketOffering;
  code?: string;
  success?: boolean;
  message?: string;
};
