import React, { ReactNode, FunctionComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
import { SectionContainer } from '../../layout';
import { TextBlockProps } from '../../sections/text-block-component/TextBlock.model';

type Position = 'oversize' | 'expanded' | 'fit' | 'contained';

const handleImagePosition = (position?: Position) => {
  switch (position) {
    case 'oversize':
      return '-80px -49px';
    case 'expanded':
      return '-40px 0';
    case 'fit':
      return '0 0';
    case 'contained':
      return '64px';
    default:
      return '64px';
  }
};

interface MarketingContentBannerProps extends Omit<TextBlockProps, 'align'> {
  image?: ReactNode;
  reverse?: boolean;
  inverse?: boolean;
  children?: ReactNode;
  imagePosition?: Position;
  transparent?: boolean;
}

export const MarketingContentBanner: FunctionComponent<MarketingContentBannerProps> = ({
  image,
  reverse,
  inverse,
  label,
  heading,
  content,
  button,
  mobileAlign = 'start',
  imagePosition = 'contained',
  transparent,
}) => {
  return (
    <SectionContainer>
      <Background reverse={reverse} inverse={inverse} transparent={transparent}>
        <TextContainer>
          <TextBlock
            label={label}
            heading={heading}
            content={content}
            align="start"
            mobileAlign={mobileAlign}
            inverse={inverse}
            button={button}
          />
        </TextContainer>
        {image ? (
          <ImageContainer imagePosition={imagePosition}>
            <MainImage>{image}</MainImage>
          </ImageContainer>
        ) : null}
      </Background>
    </SectionContainer>
  );
};

const Background = styled.div<
  Pick<MarketingContentBannerProps, 'inverse' | 'reverse' | 'transparent'>
>`
  display: flex;
  flex-direction: column-reverse;
  background: ${props =>
    props.inverse ? props.theme.gradients.radial_secondary : props.theme.colors.background_light};

  ${props => props.transparent && 'background: transparent;'}
  padding: 20px 0px 40px 0px;

  ${media.desktop<Pick<MarketingContentBannerProps, 'reverse'>>`
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: space-evenly;;
    border-radius: 8px;
    align-items: center;
    padding: 0;
  `}
`;

const TextContainer = styled.div`
  margin: 24px 0 0 0;
  padding: 0px 24px 0 24px;
  ${media.desktop`
    margin: 64px;
    width: 410px;
    padding: 0px;
  `}
`;

const ImageContainer = styled.div<Pick<MarketingContentBannerProps, 'imagePosition'>>`
  padding: 0 24px;
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  ${media.desktop<Pick<MarketingContentBannerProps, 'imagePosition'>>`
    margin: ${({ imagePosition }) => handleImagePosition(imagePosition)}
  `}
`;

const MainImage = styled.div`
  video {
    width: 100%;
  }
  img {
    width: 100%;
  }
  ${media.desktop`
    img{
      width: auto;
    }
    video {
      width: auto;
    }
  `}
`;
