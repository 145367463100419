import styled from 'styled-components';

export const PageSheetBackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${({ theme }) => theme.spacing.l};
  height: ${({ theme }) => theme.spacing.l};
  cursor: pointer;
`;
