import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { TextBaseStyle, TextBaseStyleProps } from './TextBase';

type SubTitleProps = TextBaseStyleProps & {
  inverse?: boolean;
};

export const SubTitle: FunctionComponent<SubTitleProps> = ({ children, ...knobs }) => (
  <SubTitleElement as="span" {...knobs}>
    {children}
  </SubTitleElement>
);

export const SubTitleBase = css<SubTitleProps>`
  font-family: ${props => props.theme.typography.primary};
  font-weight: 500;
  color: ${props =>
    props.inverse ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_paragraph};
`;

export const SubTitleStyle = css`
  font-size: 16px;
  line-height: 22px;
  ${SubTitleBase}
`;

const SubTitleElement = styled.span<SubTitleProps>`
  ${SubTitleStyle};
  ${TextBaseStyle};
`;
