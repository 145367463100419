import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes';
import { Paragraph } from '../../../content/text';

interface Alignment {
  alignment?: 'left' | 'center' | 'right';
}

interface ItemWithContentProps extends Alignment {
  title?: string;
  image?: React.ReactNode;
  cta?: React.ReactNode;
  inverse?: boolean;
}

export const PhotoTiles: FunctionComponent<ItemWithContentProps> = ({
  title,
  image,
  cta,
  alignment = 'left',
  inverse,
}) => {
  return (
    <OuterContainer>
      <ContentHolder alignment={alignment}>
        <Title semiBold alignment={alignment} inverse={inverse}>
          {title}
        </Title>
        {cta}
      </ContentHolder>
      <ImageHolder alignment={alignment}>
        {image && <ImageWrapper>{image}</ImageWrapper>}
      </ImageHolder>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  ${media.desktop`
    max-width: 280px;
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child){
      margin: 0 10px;
    }
  `}
`;

const ContentHolder = styled.div<Alignment>`
  text-align: ${props => props.alignment};
  background: ${props => props.theme.colors.table_row_background_alt};
  padding: 20px 10px;
  margin-top: 20px;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const Title = styled(Paragraph)<Alignment>`
  text-align: ${props => props.alignment};
  margin: 0 0 5px 0;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const ImageHolder = styled.div<Alignment>`
  text-align: ${props => props.alignment};
  img {
    width: 100%;
    ${media.desktop`
      width: auto;
    `}
  }
`;

const ImageWrapper = styled.div``;
