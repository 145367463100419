import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { BadgeIcons, BadgeConfig } from './badge.model';
import { Paragraph, Label } from '../../content';
import flameIcon from '../../assets/graphics/icons/flame.svg';
import updateIcon from '../../assets/graphics/icons/update.svg';
import newIcon from '../../assets/graphics/icons/new_badge.svg';
import calendarIcon from '../../assets/graphics/icons/calendar_badge.svg';
import hourglassIcon from '../../assets/graphics/icons/hourglass.svg';

type Props = {
  isTag?: boolean;
  config: BadgeConfig;
  className?: string;
  dataCy?: string;
};

const getIcon = (iconName: string) => {
  switch (iconName) {
    case BadgeIcons.FLAME:
      return flameIcon;
    case BadgeIcons.UPDATE:
      return updateIcon;
    case BadgeIcons.NEW:
      return newIcon;
    case BadgeIcons.ALWAYS:
      return calendarIcon;
    case BadgeIcons.LAUNCHING:
      return hourglassIcon;
  }
};

export const Badge: FunctionComponent<Props> = ({
  isTag = false,
  config,
  className,
  dataCy = 'badge',
}) => {
  const { text, icon } = config;
  if (!text) return null;

  if (isTag) {
    dataCy = dataCy == 'badge' ? 'tag' : dataCy;
    return (
      <TagContainer className={className} data-cy={dataCy}>
        <Label data-cy={`${dataCy}-text`}>{text}</Label>
      </TagContainer>
    );
  }

  return (
    <BadgeContainer data-cy={dataCy}>
      {icon && <Icon data-cy={`${dataCy}-icon`} src={getIcon(icon)}></Icon>}
      <SParagraph data-cy={`${dataCy}-text`} small semiBold>
        {text}
      </SParagraph>
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  background: ${props => props.theme.colors.background_card};
  border: 1px solid ${props => props.theme.colors.border_default};
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 26px;
`;

const TagContainer = styled.div`
  background: ${props => props.theme.colors.background_page};
  border-radius: 1px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 30px;
`;

const SParagraph = styled(Paragraph)`
  color: ${props => props.theme.colors.text_header};
  font-size: 12px;
`;

const Icon = styled.img`
  margin-right: 5px;
  flex: 1;
`;
