import styled from 'styled-components';
import { Paragraph } from '../../content';

export const RateWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const RateAmount = styled(Paragraph)`
  display: inline;
  color: ${props => props.theme.colors.input_disabled_text};
`;

export const Rate = styled.div`
  display: flex;
  column-gap: ${props => props.theme.spacing.xs};
  margin-right: ${props => props.theme.spacing.sm};
`;

export const AwardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: ${props => props.theme.spacing.ml};
  width: 270px;
  min-height: 227px;
  align-items: center;
  padding: ${props => props.theme.spacing.sm};
  border: solid 1px ${props => props.theme.colors.border_default};
  border-radius: ${props => props.theme.spacing.xs};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.spacing.xxs};
  align-items: center;
`;
