import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
import {
  UserReportsApiResponse,
  UserReportsYearsApiResponse,
  UserReportsYearsRequest,
  UserReportsYearsResponse,
  UserReportsRequest,
  UserReportsResponse,
} from './reports.model';

export const reportsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserReports, Tags.UserReportsYears],
  })
  .injectEndpoints({
    endpoints: build => {
      const getReports = build.query<UserReportsResponse, UserReportsRequest>({
        query: ({
          investorId,
          userId,
          reportType,
          year,
          managementStrategy,
          providers,
        }: UserReportsRequest) => {
          return {
            url: `/a/api/user/${userId}/reports`,
            method: HttpMethods.Get,
            params: {
              investorAccountId: investorId,
              type: reportType,
              year,
              managementStrategy,
              providers,
            },
          };
        },
        providesTags: [Tags.UserReports],
        transformResponse: ({ reports }: UserReportsApiResponse) => ({
          reports,
        }),
      });

      const getReportsYears = build.query<UserReportsYearsResponse, UserReportsYearsRequest>({
        query: ({
          investorId,
          reportType,
          userId,
          managementStrategy,
        }: UserReportsYearsRequest) => {
          return {
            url: `/a/api/user/${userId}/reports/available-years`,
            method: HttpMethods.Get,
            params: {
              investorAccountId: investorId,
              type: reportType,
              managementStrategy,
            },
          };
        },
        providesTags: [Tags.UserReportsYears],
        transformResponse: ({ years }: UserReportsYearsApiResponse) => ({
          years,
        }),
      });

      return {
        getReports,
        getReportsYears,
      };
    },
  });
