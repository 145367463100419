import styled from 'styled-components';
import { Heading } from '../../content';

export const TextBoxWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background_light};
  border: 1px solid ${({ theme }) => theme.colors.border_default};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing.ml};
`;

export const SHeading = styled(Heading)`
  margin: ${({ theme }) => theme.spacing.sm} 0 ${({ theme }) => theme.spacing.xs};
`;
