export enum PaymentStatus {
  Approved = 'APPR',
  Cleared = 'CLEA',
  Hold = 'HOLD',
  Pending = 'PEND',
  Processing = 'PROC',
  Qued = 'QUED',
}

export const ProcessingStatusTypes = [
  PaymentStatus.Approved,
  PaymentStatus.Hold,
  PaymentStatus.Pending,
  PaymentStatus.Processing,
  PaymentStatus.Qued,
];
