import { useMemo } from 'react';
import { camelCase } from 'lodash';

export const TEST_ID_ATTR = 'data-cy';

export type TestPropsWithLegacy =
  | {
      testId?: never;
      /** @deprecated use testId field instead */
      [TEST_ID_ATTR]: string;
      /** @deprecated use testId field instead */
      dataCy?: never;
    }
  | {
      testId?: never;
      /** @deprecated use testId field instead */
      [TEST_ID_ATTR]?: never;
      /** @deprecated use testId field instead */
      dataCy: string;
    }
  | {
      testId: string;
      /** @deprecated use testId field instead */
      [TEST_ID_ATTR]?: never;
      /** @deprecated use testId field instead */
      dataCy?: never;
    };

export type TestProps = {
  testId: string;

  /** @deprecated use testId field instead */
  [TEST_ID_ATTR]?: never;

  /** @deprecated use testId field instead */
  dataCy?: never;
};

/**
 * Converts a hyphenated string to camelCase format with a maximum length constraint.
 *
 * @template S The input string to be converted to camelCase.
 * @template MaxLength The maximum length of the output string. Defaults to 50. This is to prevent `Type instantiation is excessively deep and possibly infinite.`
 *
 * @param S The input string to be converted to camelCase.
 *
 * @returns The input string converted to camelCase format with a maximum length constraint.
 *
 * @example TestKeyCamelCase<'header-left-icon'>; // "headerLeftIcon"
 */
type TestKeyCamelCase<
  S extends string,
  MaxLength extends number = 50
> = S extends `${infer P}-${infer T}`
  ? `${Lowercase<P>}${Capitalize<TestKeyCamelCase<T, MaxLength>>}`
  : S;

/**
 * ConcatenatedTestId is a string that is either a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // 'test-key'
 */
type ConcatenatedTestId<TestKey extends string, TestID extends string> = `${TestID}-${TestKey}`;

/**
 * MaybeConcatenatedTestId is a string that is either a concatenation of the testId and the key or just the testId
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // 'test-key'
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // 'test'
 */
type MaybeConcatenatedTestId<
  TestKey extends string | undefined,
  TestID extends string
> = TestKey extends string ? ConcatenatedTestId<TestKey, TestID> : TestID;

/**
 * TestIDValue is a string that is either a concatenation of the testId and the key or just the testId or empty string
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // 'test-key'
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // 'test'
 *
 * @example - or just empty string if the testId is not defined
 * TestID = undefined and TestKey = undefined // ''
 */
type TestIDValue<
  TestKey extends string | undefined,
  TestID extends string | undefined
> = TestID extends string ? MaybeConcatenatedTestId<TestKey, TestID> : ``;

/**
 * TestIdAttrType defines an object that has a key that is a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the testId Object
 * @typeParam TestID - testId to construct the testId Object
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // { 'data-cy': 'test-key' }
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // { 'data-cy': 'test' }
 *
 * @example - or just empty string if the testId is not defined
 * TestID = testId = undefined and key = 'key' // attr: { 'data-cy': '' }
 */
type TestIdAttrType<TestKey extends string | undefined, TestID extends string | undefined> = {
  [TEST_ID_ATTR]: TestIDValue<TestKey, TestID>;
};

/**
 * TestIDSelector is a string that can be used with querySelector to find the testId element
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // '[data-cy="test-key"]'
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // '[data-cy="test"]'
 *
 * @example - or just empty string if the testId is not defined
 * TestID = undefined and TestKey = undefined // '[data-cy=""]'
 */
type TestIDSelector<
  TestKey extends string | undefined,
  TestID extends string | undefined
> = `[${typeof TEST_ID_ATTR}="${TestIDValue<TestKey, TestID>}"]`;

/**
 * TestIdObjType defines an object that has 2 keys that are a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the testId Object
 * @typeParam TestID - testId to construct the testId Object
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // { id: 'test-key', attr: { 'data-cy': 'test-key' } }
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // { id: 'test', attr: { 'data-cy': 'test' } }
 *
 * @example - or just empty string if the testId is not defined
 * TestID = testId = undefined and key = 'key' // { id: '', attr: { 'data-cy': '' } }
 */
type TestIdObjType<TestKey extends string, TestID extends string | undefined> = {
  id: TestIDValue<TestKey, TestID>;
  attr: TestIdAttrType<TestKey, TestID>;
  selector: TestIDSelector<TestKey, TestID>;
};

/**
 * TestIdObjKeyPairType defines an object that has a key that is a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the testId Object
 * @typeParam TestID - testId to construct the testId Object
 *
 * @example
 * TestID = 'test' and TestKey = 'key' => { key: { id: 'test-key', attr: { 'data-cy': 'test-key' } } }
 *
 * @example - or just empty string if the testId is not defined
 * TestID = undefined and TestKey = 'key' => { key: { id: '', attr: { 'data-cy': '' } } }
 */
type TestIdObjKeyPairType<TestID extends string | undefined, TestKey extends string> = {
  [key in TestKey as TestKeyCamelCase<key>]: TestIdObjType<key, TestID>;
};

/**
 * getTestIds is a function that generates a memoized list of TestIdObjKeyPairType objects
 *
 * @warning Do not use this function directly, the hook generated by genTestIds instead
 *
 * @internal
 * @param {array} keys - an array of keys
 * @param {string} testId - string used to generate the id
 *
 * @returns {TestIdObjKeyPairType} - an array of TestIdObjKeyPairType objects
 *
 * @example
 * const { getTestIds } genTestIds(['key1', 'key2']);
 * const testIds = getTestIds('test'); // { base: {...}, key1: {...}, key2: {...} }
 * testIds.base.id // 'test'
 * testIds.base.attr // { 'data-cy': 'test' }
 */
const getTestIds = <TestKey extends string, TestID extends string | undefined>(
  keys: TestKey[],
  testId?: TestID
) => {
  /**
   * getTestIdObj is a function that returns an object that has a key object pair
   *
   * @internal
   *
   * @param {string | undefined} key - a key
   * @param {string | undefined} id - string used to generate the id
   *
   * @returns {TestIdObjType} - an object that has a key object pair
   *
   * @example
   * getTestIdObj() // { id: '', attr: { 'data-cy': '' } }
   * getTestIdObj('test') // { id: 'test', attr: { 'data-cy': 'test' } }
   * getTestIdObj('test', 'key') // { id: 'test-key', attr: { 'data-cy': 'test-key' } }
   */
  const getTestIdObj = (id?: TestID, key?: TestKey) => {
    let testId = ``;
    if (id) {
      testId = id;
      if (key) {
        testId = `${id}-${key}`;
      }
    } else if (key) {
      testId = `${key}`;
    }

    return {
      id: testId,
      attr: { [TEST_ID_ATTR]: testId },
      selector: `[${TEST_ID_ATTR}="${testId}"]`,
    };
  };

  const keyValues = keys.reduce((acc, key) => {
    const csKey = camelCase(key) as TestKeyCamelCase<TestKey>;
    (acc as any)[csKey] = getTestIdObj(testId, key) as TestIdObjType<
      TestKeyCamelCase<TestKey>,
      TestID
    >;
    return acc;
  }, {} as TestIdObjKeyPairType<TestID, TestKey>);

  return { base: getTestIdObj(testId), ...keyValues };
};

/**
 * useTestIds is a hook that extracts the testId for the component props
 * and generates a memoized list of TestIdObjKeyPairType objects
 * @warning Do not use this function directly, the hook generated by genTestIds instead
 *
 * @internal
 * @param {array} keys - an array of keys
 * @param {TestProps} props - components prop that must contain testId
 *
 * @returns {TestIdObjKeyPairType} - an array of TestIdObjKeyPairType objects
 *
 * @example
 * const { useTestIds } genTestIds(['key1', 'key2']);
 * const testIds = useTestIds(props); // { base: {...}, key1: {...}, key2: {...} }
 * testIds.base.id // 'test'
 * testIds.base.attr // { 'data-cy': 'test' }
 **/
const useTestIds = <TestKey extends string>(keys: TestKey[], props: TestProps) => {
  const { testId } = props;

  return useMemo(() => getTestIds(keys, testId), [testId]);
};

/**
 * genTestIds is a generator function that return a get function and a hook function
 * @param {array} keys - an array of keys that will be used to create the returns a
 * function that returns an array of object that has a key object pair
 *
 * @example
 * const testId = 'test';
 * const { getTestIds, useTestIds } genTestIds(['key1', 'key2']);
 * getTestIds(testId).base // { id: 'test', attr: { 'data-cy': 'test' } }
 * getTestIds(testId).key1 // { id: 'test-key1', attr: { 'data-cy': 'test-key1' } }
 * getTestIds(testId).key2 // { id: 'test-key2', attr: { 'data-cy': 'test-key2' } }
 */
export const genTestIds = <TestKey extends string>(keys: TestKey[] = []) => {
  return {
    getTestIds: <TestID extends string>(testId: TestID) => getTestIds(keys, testId),

    useTestIds: (props: TestPropsWithLegacy) => {
      return useTestIds(keys, {
        testId: props?.testId || props?.[TEST_ID_ATTR] || props?.dataCy || '',
      });
    },
  };
};
