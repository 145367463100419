import React, { useEffect, FunctionComponent } from 'react';
import CloseIcon from './close-icon.svg';
import InverseCloseIcon from './close-icon-inverse.svg';
import { ModalsProps } from './Modal.model';
import { ModalBackground, ModalBody, ModalHeader, ModalContent, Icon } from './Modal.style';

/**
 * @deprecated Please do not use for new projects. Use newest-modal/Modal.tsx instead. 10/20/2022
 */
export const Modal: FunctionComponent<ModalsProps> = props => {
  const {
    className,
    disableCloseOnClickOutside = false,
    children,
    hideXMark,
    clickClose,
    inverseXMark = false,
    height,
    width,
  } = props;

  const handleClickOutside = (event: MouseEvent) => {
    if ((event.target as HTMLElement).getAttribute('data-click-id') === 'modal-bg' && clickClose) {
      clickClose();
    }
  };

  useEffect(() => {
    if (!disableCloseOnClickOutside) {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  });

  return (
    <ModalBackground data-click-id="modal-bg" className={className}>
      <ModalBody
        data-cy="modal-body-wrapper"
        height={height}
        width={width}
        className="modal-body-wrapper"
      >
        <ModalHeader hideXMark={hideXMark}>
          <Icon
            tabIndex={0}
            src={inverseXMark ? InverseCloseIcon : CloseIcon}
            onClick={clickClose}
          />
        </ModalHeader>
        <ModalContent className="modal-content">{children}</ModalContent>
      </ModalBody>
    </ModalBackground>
  );
};
