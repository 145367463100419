import { HttpMethods } from '../../enums';
import { restApi, Tags } from '../common';
import {
  PenscoServiceFundsRequest,
  PenscoServiceFundsResponse,
  PenscoServiceFundsApiResponse,
} from './pensco-service.model';

export const penscoServiceApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PenscoServiceFunds],
  })
  .injectEndpoints({
    endpoints: build => {
      const getPenscoServiceFunds = build.query<
        PenscoServiceFundsResponse,
        PenscoServiceFundsRequest
      >({
        query: ({ page, pageSize }: PenscoServiceFundsRequest) => {
          return {
            url: `/pensco-service/account/funding`,
            method: HttpMethods.Get,
            params: { page, pageSize },
          };
        },
        providesTags: [Tags.PenscoServiceFunds],
        transformResponse: ({ result }: PenscoServiceFundsApiResponse) => ({
          ...result,
        }),
      });

      return {
        getPenscoServiceFunds,
      };
    },
  });
