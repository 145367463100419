import { map, mapValues, identity } from 'lodash';

import { AccountSummary } from '../../account-summary';
import {
  InvestmentBalanceBreakdown,
  InvestmentBalanceBreakdownItems,
} from './useBalanceBreakdown.model';

export const mapToBalanceBreakdown = (
  accountSummary: AccountSummary = {},
  config: InvestmentBalanceBreakdown,
  filterEmpty: boolean = true
) => {
  return mapValues(config, section => {
    const mappedItems = map(section?.items, item => {
      return { ...item, value: (accountSummary as any)[item.key] };
    }).filter(identity) as InvestmentBalanceBreakdownItems[];

    return {
      ...section,
      // remove items that are configured to hide if empty and have 0/empty value
      items: filterEmpty
        ? mappedItems.filter(item => !item.hideWhenEmpty || item.value)
        : mappedItems,
    };
  });
};
