import { restApi, Tags } from '../../common';
import { HttpMethods } from '../../../enums';

import { AllocationTimelineApiResponse, AllocationTimelineApiRequest } from './allocation.model';

export const allocationApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioAllocationTimeline],
  })
  .injectEndpoints({
    endpoints: build => {
      const getAllocationTimeline = build.query<
        AllocationTimelineApiResponse,
        AllocationTimelineApiRequest
      >({
        query: ({
          end,
          start,
          userId,
          investorAccountId,
          managementStrategy,
        }: AllocationTimelineApiRequest) => ({
          url: `/api/portfolio/${userId}/allocation-time-line`,
          method: HttpMethods.Get,
          params: {
            end,
            investorAccountId,
            start,
            managementStrategy,
          },
        }),
        providesTags: [Tags.PortfolioAllocationTimeline],
      });

      return {
        getAllocationTimeline,
      };
    },
  });
