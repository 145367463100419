import { useState, useLayoutEffect } from 'react';

const createPortalElementAndAppendToBody = (portalId: string) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', portalId);
  wrapperElement.setAttribute('data-cy', portalId);
  document.body.appendChild(wrapperElement);

  return wrapperElement;
};

export const usePortal = (id: string) => {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let systemCreated = false;
    let element = document.getElementById(id);

    if (!element) {
      systemCreated = true;
      element = createPortalElementAndAppendToBody(id);
    }

    setPortalElement(element);

    return () => {
      setPortalElement(null);
      if (systemCreated && element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [id]);

  return portalElement;
};
