import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes';
import { Paragraph } from '../../../content/text';

import VeryGoodIcon from '../../../assets/graphics/icons/very-good.svg';

interface TitleAlignment {
  titleAlignment?: 'left' | 'center' | 'right';
  iconPosition?: 'top' | 'inline';
}

interface ContentAlignment {
  contentAlignment?: 'left' | 'center' | 'right';
}

interface ItemWithContentProps extends TitleAlignment, ContentAlignment {
  title?: string;
  image?: React.ReactNode;
  inverse?: boolean;
  className?: string;
}

export const TitleWithContent: FunctionComponent<ItemWithContentProps> = ({
  title,
  image,
  titleAlignment = 'left',
  contentAlignment = 'left',
  iconPosition = 'inline',
  children,
  inverse,
  className,
}) => {
  return (
    <OuterContainer className={className}>
      <TitleHolder titleAlignment={titleAlignment} iconPosition={iconPosition}>
        {image ? image : <Icon src={VeryGoodIcon} alt="check" />}{' '}
        <Title semiBold inverse={inverse}>
          {title}
        </Title>
      </TitleHolder>
      <Content contentAlignment={contentAlignment} small inverse={inverse}>
        {children}
      </Content>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  ${media.desktop`
    max-width: '280px';
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child){
      margin: 0 10px;
    }
  `}
`;

const Title = styled(Paragraph)``;

const TitleHolder = styled.div<TitleAlignment>`
  text-align: ${props => props.titleAlignment};
  margin: 30px 0 10px 0;
  display: flex;
  flex-direction: ${props => (props.iconPosition === 'inline' ? 'row' : 'column')};
  align-items: ${props => (props.iconPosition === 'inline' ? 'center' : 'flex-start')};
  justify-content: flex-start;
  img {
    margin: ${props => (props.iconPosition === 'inline' ? '0 10px 0 0' : '0 0 10px 0')};
  }
  ${media.desktop`
    margin-top: 0;
  `}
`;

const Content = styled(Paragraph)<ContentAlignment>`
  margin: 10px 0 0 0;
  text-align: ${props => props.contentAlignment};
`;

const Icon = styled.img``;
