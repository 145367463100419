import { formatISODate } from '../formatters/date';
import isNumber from 'lodash/isNumber';
import sum from 'lodash/sum';
import { Investment, InvestmentType } from './types';

export const getYearsForChartLabels = (date: Date = new Date()) => {
  var aYearFromNow = new Date(date);
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
  const years = [aYearFromNow.getFullYear()];
  for (let i = 1; i < 5; i++) {
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 2);
    years.push(aYearFromNow.getFullYear());
  }
  return years;
};

export const getSimulatedPortfolioEarnings = (
  annualInterest: number,
  period: number,
  annualAmount: number,
  years: number = 10
) => {
  if (period % 6 !== 0 || period > 120 || period < 6) {
    return {};
  }

  if (annualAmount < 10000 || annualAmount > 250000) {
    return {};
  }

  if (annualInterest < 3 || annualInterest > 15) {
    return {};
  }
  const yearsForChartLabels = getYearsForChartLabels();
  const datesWithChartLabels: any[] = [];
  const paymentsPerYear = 4;
  const monthsPerPaymentInterval = 12 / paymentsPerYear;
  const numberOfPayments = years * paymentsPerYear;
  let date = new Date();
  const interestRatePerPayment = annualInterest / paymentsPerYear / 100;

  const paymentsBeforeFullReturn = period / monthsPerPaymentInterval;

  let principalValue = annualAmount;
  let totalInterestValue = 0;
  let earningsTimeline: any[] = [];
  let helper = 1;

  for (let i = 1; i <= numberOfPayments; i++) {
    const value = principalValue * interestRatePerPayment;
    const earningsDate = date.setMonth(date.getMonth() + monthsPerPaymentInterval);
    totalInterestValue = totalInterestValue + value;
    const earningsDateYear = new Date(earningsDate).getFullYear();
    if (yearsForChartLabels.includes(earningsDateYear)) {
      datesWithChartLabels.push(formatISODate(earningsDate));
      yearsForChartLabels.shift();
    }
    earningsTimeline.push({
      date: formatISODate(earningsDate),
      value,
    });

    if (helper === paymentsBeforeFullReturn) {
      principalValue = principalValue ? principalValue - annualAmount : principalValue;
      helper = 1;
    } else {
      helper = helper + 1;
    }

    if (i % 4 === 0) {
      principalValue = principalValue + annualAmount;
    }
  }

  return {
    totalInterestValue,
    earningsTimeline,
    datesWithChartLabels,
  };
};

/*
 CODE BELOW IS USED BY THE NEW PORTFOLIO SIMULATOR
 THIS FILE WILL BE REFACTORED AFTER GATSBY/CHIMI GO TO PROD WITH THE NEW SIMULATOR VERSION
*/
interface ChartData {
  totalInterest: number;
  payments: number[];
  earnings: number[];
  name: string;
}

export const investmentEarnings = (payments: number[]) => {
  const earnings: number[] = [];
  payments.reduce((a, b, i) => {
    return (earnings[i] = a + b);
  }, 0);

  return earnings;
};

export const totalAnnualizedReturn = (investments: Investment[], amounts: number[]) => {
  const totalYearlyEarnings = sum(
    investments.map((investment, index) => (amounts[index] * investment.rate) / 100)
  );

  return parseFloat(`${(totalYearlyEarnings / sum(amounts)) * 100}`).toFixed(2);
};

export const investmentCalculator = (investment: Investment, amount: number): ChartData => {
  const yearlyInterest = amount * (investment.rate / 100);
  const totalInterest = (yearlyInterest / 12) * investment.term;

  let payments: number[] = [];
  switch (investment.type) {
    case InvestmentType.MONTHLY:
      payments = Array(investment.term).fill(yearlyInterest / 12);
      break;
    case InvestmentType.BIMONTHLY:
      const biMonthlyPayment = yearlyInterest / 6;
      for (let i = 0; i < investment.term; i++) {
        payments.push(i % 2 ? biMonthlyPayment : 0);
      }
      break;
    case InvestmentType.QUARTERLY:
      const quarterlyPayment = yearlyInterest / 4;
      for (let i = 1; i <= investment.term; i++) {
        payments.push(i % 3 === 0 ? quarterlyPayment : 0);
      }
      break;
    case InvestmentType.SEMIANNUAL:
      const semiAnnualPayment = yearlyInterest / 6;
      for (let i = 1; i <= investment.term; i++) {
        payments.push(i % 6 === 0 ? semiAnnualPayment : 0);
      }
      break;
    case InvestmentType.ANNUAL:
      for (let i = 1; i <= investment.term; i++) {
        payments.push(i % 12 === 0 ? yearlyInterest : 0);
      }
      break;
    case InvestmentType.ENDOFTERM:
      payments = [...Array(investment.term - 1).fill(0), totalInterest];
      break;
    case InvestmentType.MIXED:
      const lowerRate = investment.lowerRate || 0;
      const yearlyLowerRate = amount * (lowerRate / 100);
      const monthlyPayment = yearlyLowerRate / 12;

      const eotPayment = ((amount * ((investment.rate - lowerRate) / 100)) / 12) * investment.term;

      payments = [...Array(investment.term - 1).fill(monthlyPayment), eotPayment + monthlyPayment];
      break;
  }

  const result = {
    name: investment.name,
    totalInterest,
    payments,
    earnings: investmentEarnings(payments),
  };

  return result;
};

export const portfolioCalculator = (investments: Investment[], amounts: number[]) => {
  const investmentsData = investments.map((investment, index) =>
    investmentCalculator(investment, amounts[index])
  );

  const globalEarnings: number[] = [];

  investmentsData.forEach(investment => {
    investment.payments.forEach((earning, index) => {
      if (isNumber(globalEarnings[index])) {
        globalEarnings[index] = globalEarnings[index] + earning;
      } else {
        globalEarnings.push(earning);
      }
    });
  });

  return {
    investmentsData,
    globalEarnings: investmentEarnings(globalEarnings),
  };
};
