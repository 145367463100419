import { PortfolioInterest } from '../models';

export const portfolioInterest: PortfolioInterest = {
  beginningAccruedInterest: 0,
  compoundInterestEarned: 0,
  compoundInterestPaid: 0,
  endingAccruedInterest: 7730.75,
  fexpBalance: 0,
  fexpPaid: 1816.15,
  interestEarned: 44052.53,
  interestEarnedPostDefault: 7717.76,
  interestEarnedPreDefault: 36334.77,
  interestPaid: 36321.78,
  netPaid: 34505.63,
  walletInterestPaid: 107.49,
  earningsTimeline: [
    {
      accruedPostDefault: 0,
      accruedPreDefault: 0,
      date: '2016-06-30',
      fexpPaid: 0,
      netPaid: 0,
      totalPaid: 0,
      value: 0,
      walletInterestPaid: 0,
    },
    {
      accruedPostDefault: 0,
      accruedPreDefault: 21.62,
      date: '2016-07-31',
      fexpPaid: 0,
      netPaid: 24.02,
      totalPaid: 24.02,
      value: 45.64,
      walletInterestPaid: 0,
    },
    {
      accruedPostDefault: 0,
      accruedPreDefault: 32.86,
      date: '2016-08-31',
      fexpPaid: 0,
      netPaid: 141.98,
      totalPaid: 141.98,
      value: 174.84,
      walletInterestPaid: 0,
    },
  ],
};
