import { css, CSSObject, ThemedCssFunction, DefaultTheme } from 'styled-components';
import isArray from 'lodash/isArray';

export const SizeOptions = [
  'reallySmall',
  'mobile',
  'phoneLandscape',
  'tablet',
  'desktop',
  'large',
  'larger',
  'fullscreen',
  'fullHd',
  'ultrawide',
] as const;

export const FriendlySizeOptions = ['mobile', 'tablet', 'desktop'] as const;

export type Size = (typeof SizeOptions)[number];

export type FriendlySize = (typeof FriendlySizeOptions)[number];

export type Other = 'handheld' | 'print';

export type SizeMap<K extends string, T> = { [SizeKey in K]: T };

type SizeProps<K extends string> = SizeMap<K, [string, number] | string>;

export const otherProps: SizeProps<Other> = {
  handheld: '(pointer:none), (pointer:coarse)',
  print: 'print',
};

export const sizeProps: SizeProps<Size> = {
  reallySmall: ['max', 380],
  mobile: ['min', 480],
  phoneLandscape: ['min', 576],
  tablet: ['min', 768],
  desktop: ['min', 992],
  large: ['min', 1200],
  larger: ['min', 1440],
  fullscreen: ['min', 1600],
  fullHd: ['min', 1920],
  ultrawide: ['min', 2560],
};

export const friendlySizeProps: SizeProps<FriendlySize> = {
  mobile: ['max', 576],
  tablet: ['min', 576],
  desktop: ['min', 1200],
};

export const sizes: Size[] = Object.keys(sizeProps) as Size[];
export const friendlySizes: FriendlySize[] = Object.keys(friendlySizeProps) as FriendlySize[];

type SizeQueries<K extends string> = SizeMap<K, string>;

const generateQueries = <K extends string>(propsMap: SizeProps<K>): SizeQueries<K> =>
  Object.keys(propsMap).reduce((acc: any, size) => {
    const props = propsMap[size as K];
    const query = isArray(props) ? `(${props[0]}-width: ${props[1]}px)` : props;
    acc[size] = query;
    return acc;
  }, {});

export const queries = generateQueries({ ...sizeProps, ...otherProps });
export const friendlyQueries = generateQueries({
  ...friendlySizeProps,
  ...otherProps,
});

type SizeMedia<K extends string> = SizeMap<K, ThemedCssFunction<DefaultTheme>>;

export const generateMedia = <K extends string>(queryMap: SizeQueries<K>): SizeMedia<K> =>
  Object.keys(queryMap).reduce((acc: any, size) => {
    const query = queryMap[size as K];
    acc[size] = (first: TemplateStringsArray | CSSObject, ...args: any) => css`
      @media ${query} {
        ${css(first, ...args)};
      }
    `;
    return acc;
  }, {});

export const media = generateMedia(queries);
export const friendlyMedia = generateMedia(friendlyQueries);
