import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../themes/media';
import { Heading, Paragraph } from '../../content';
import InverseCloseIcon from '../../assets/graphics/icons/close-icon-inverse.svg';
export interface PromoBannerProps {
  testId?: string | number;
  image?: string;
  imageFull?: boolean;
  imageFullBasis?: number;
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  cta?: React.ReactNode;
  inverse?: boolean;
  onCloseClick?: () => void;
}

export const PromoBanner: FunctionComponent<PromoBannerProps> = ({
  testId,
  image,
  imageFull,
  imageFullBasis,
  title,
  content,
  cta,
  inverse,
  onCloseClick,
}) => {
  return (
    <BannerContainer inverse={inverse} data-cy={`${testId ? testId : 'banner'}`}>
      <CloseButton src={InverseCloseIcon} onClick={onCloseClick} />
      <BannerContent
        imageFull={imageFull}
        bannerBasis={imageFullBasis ? `${100 - imageFullBasis}%` : 'unset'}
      >
        <BannerText>
          <Title inverse={inverse} type={4}>
            {title}
          </Title>
          <Description inverse={inverse}>{content}</Description>
          {cta && <CTAHolder>{cta}</CTAHolder>}
        </BannerText>
      </BannerContent>
      {image && (
        <ImageWrapper imageFull={imageFull}>
          <Image imageFull={imageFull} src={image} />
        </ImageWrapper>
      )}
    </BannerContainer>
  );
};

const BannerContainer = styled.div<{ inverse?: boolean }>`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column-reverse;
  ${media.desktop`
    flex-direction: row;
  `}
  background: ${props =>
    props.inverse ? props.theme.colors.background_inverse : props.theme.colors.background_card};
  flex: 1;
  align-items: stretch;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  ${media.desktop<{ bannerBasis: string; imageFull?: boolean }>`
    ${props => props.imageFull && `flex-basis: ${props.bannerBasis};`}
    padding: 40px;
  `}
`;

const BannerText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Heading)`
  margin-bottom: 20px;
`;

const Description = styled(Paragraph)`
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 30px;
  ${media.desktop`
    flex-grow: 0;
  `}
`;

const CTAHolder = styled.div`
  button {
    width: 100%;
    ${media.desktop`
      width: auto;
    `};
  }
`;

const ImageWrapperFull = css<{ imageFull?: boolean }>`
  ${props =>
    props.imageFull &&
    css`
      padding: 0;
      flex: 1;
    `};
`;

const ImageFull = css<{ imageFull?: boolean }>`
  ${props =>
    props.imageFull &&
    css`
      object-fit: cover;
    `};
`;

const ImageWrapper = styled.div<{ imageFull?: boolean }>`
  display: inline-flex;
  padding: 30px 20px;
  padding-bottom: 0;
  ${ImageWrapperFull}

  ${media.desktop`
    padding: 40px;
    padding-left: 0;
    ${ImageWrapperFull}
  `}
`;

const Image = styled.img`
  width: 100%;
  ${ImageFull}
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  width: 15px;
  top: 15px;
  right: 15px;
`;
