import { UserProfile } from '../../apis';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeUserProfile: ObjectAnonymizer<UserProfile> = value =>
  anonymizeObject(value, {
    phone: 'string',
    phoneRaw: 'string',
    addresses: ['array', 'address'],
  });
