import { ReactElement } from 'react';

export enum ModalAlignment {
  centre = 'CENTRE',
}

export interface ModalProps {
  align?: ModalAlignment;
  children: ReactElement;
  dataCy?: string;
  disableCloseOnClickOutside?: boolean;
  hideCloseButton?: boolean;
  modalId: string;
  onClose?: () => void;
}

export interface ModalWrapperyStyleProps {
  align?: ModalAlignment;
  visible: boolean;
}

export interface ModalInterface {
  currentModalId: string;
  hideModal: (modalId?: string) => void;
  isModalVisible: boolean;
  passProps: any;
  showModal: (modalId: string, passProps?: any) => void;
}

export interface PassProps {
  [key: string]: unknown;
}
