import { BadgeType } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';

import { BaseProps } from '../../types';

import { theme2 } from '../../themes';
import { Icons } from '../icon/Icon.model';

export const { getTestIds, useTestIds } = genTestIds(['badge', 'icon']);

export interface BadgeProps extends Partial<BaseProps> {
  text: string;
  icon?: keyof typeof Icons;
  testId: string;
  iconColor?: string;
  type?: keyof typeof BadgeType;
}

export interface BadgeContainerProps {
  backgroundColor?: string;
}

export const BadgeConfiguration = {
  [BadgeType.PERSONAL]: {
    backgroundColor: theme2.colors.badge_personal,
    textColor: theme2.colors.badge_personal_text,
  },
  [BadgeType.DEFAULT]: {
    textColor: 'default',
    backgroundColor: theme2.colors.badge_regular,
  },
};
