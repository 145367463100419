import { FunctionComponent } from 'react';
import { BaseInputProps } from '@yieldstreet/tool-kit';
import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { SelectInput, SelectInputProps } from '../select-input';

export interface SelectGroupInputProps extends BaseInputProps<string>, SelectGroupInputStyling {
  options: Array<SelectInputProps>;
}

interface SelectGroupInputStyling {
  styling?: CSSProperties;
}

interface WrapperProps extends SelectGroupInputStyling {
  fullWidth?: boolean;
  children: React.ReactElement;
}

const SelectFilterContainer = styled.div<SelectGroupInputStyling>`
  .MuiFormControl-root {
    ${({ styling }: SelectGroupInputStyling) => styling as string}
  }
`;

const Wrapper = ({ fullWidth, children, styling }: WrapperProps) =>
  fullWidth ? (
    children
  ) : (
    <SelectFilterContainer styling={styling}>{children}</SelectFilterContainer>
  );

export const SelectGroupInput: FunctionComponent<SelectGroupInputProps> = props => {
  const { options, styling } = props;

  return (
    <>
      {options?.map((option, index) => (
        <Wrapper fullWidth={option.fullWidth} key={`${option.label}${index}`} styling={styling}>
          <SelectInput
            testId={option?.name || 'select'}
            key={`${option.label}-${index}`}
            {...option}
          />
        </Wrapper>
      ))}
    </>
  );
};
