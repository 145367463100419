import React, { FunctionComponent, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BaseInputProps } from '@yieldstreet/tool-kit';
import { SFormControl, SFormHelperText, FormError, withFormikWeb } from '../common';
import { PickerPopover, SDatePicker } from './styles';
import { CallbackLink } from '../../content';
import { TextFieldProps } from '@mui/material';

export interface DatePickerProps extends BaseInputProps<Date> {
  hiddenLabel?: boolean;
  fullWidth?: boolean;
  width?: number | string;
  inline?: boolean;
  helperText?: string;
  minDate?: Date;
  name?: string;
}

export const DatePickerInput: FunctionComponent<DatePickerProps> = props => {
  const {
    value,
    label,
    error,
    caption,
    className,
    width,
    inline,
    // disabled,
    // helperText,
    minDate,
    onChange,
    name,
    // testId,
  } = props;

  const inputRef = useRef(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);

  const defaultOnChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  return (
    <SFormControl
      ref={inputRef}
      variant="filled"
      error={!!error}
      className={className}
      style={{ width }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {inline ? (
          <DatePicker
            label={label}
            renderInput={(props: TextFieldProps) => (
              <CallbackLink callback={() => setPickerOpen(!pickerOpen)}>
                {props.inputProps?.value as string}
              </CallbackLink>
            )}
            PopperProps={{ anchorEl: inputRef.current }}
            PaperProps={{ component: PickerPopover }}
            value={selectedDate}
            open={pickerOpen}
            inputFormat="MM/dd/yyyy"
            onOpen={() => setPickerOpen(true)}
            onClose={() => setPickerOpen(false)}
            minDate={minDate ? minDate : new Date()}
            onChange={onChange ? value => onChange(value as Date) : defaultOnChange}
          />
        ) : (
          <DatePicker
            label={label}
            renderInput={(props: TextFieldProps) => {
              return <SDatePicker variant="standard" {...props} />;
            }}
            components={{
              OpenPickerIcon: props => (
                <FontAwesomeIcon icon={faCalendarAlt} size="sm" {...props} />
              ),
            }}
            PopperProps={{ anchorEl: inputRef.current }}
            PaperProps={{ component: PickerPopover }}
            value={selectedDate}
            open={pickerOpen}
            inputFormat="MM/dd/yyyy"
            onOpen={() => setPickerOpen(true)}
            onClose={() => setPickerOpen(false)}
            minDate={minDate ? minDate : new Date()}
            onChange={onChange ? value => onChange(value as Date) : defaultOnChange}
          />
        )}
      </LocalizationProvider>
      {error || caption ? (
        <SFormHelperText>
          {error ? (
            <FormError testId="error" type="date-input" name={name || 'date'} error={error} />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </SFormControl>
  );
};

export const FormDatePickerInput = withFormikWeb(DatePickerInput);
