import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { ContextMenuAlignment } from './ContextMenu.model';

interface ContextWindowProps {
  align: `${ContextMenuAlignment}`;
  maxHeight?: number;
}

export const ContextContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const ContextAction = styled.div``;

export const ContextWindow = styled(motion.div)<ContextWindowProps>`
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow-y: auto;
    `}
  position: absolute;
  top: 100%;
  margin-top: ${({ theme }) => theme.spacing.xs};
  padding: 20px;
  background: #ffffff;
  border: 1px solid ${props => props.theme.colors.border_default};
  box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.2);
  ${props =>
    props.align === ContextMenuAlignment.RIGHT
      ? `
    right: 0;
  `
      : `
    left: 0;
  `};
`;

export const ContextWindowAnimation = {
  initial: 'out',
  animate: 'in',
  exit: 'out',
  variants: {
    in: {
      opacity: 1,
      transform: `translateY(0px)`,
    },
    out: {
      opacity: 0,
      transform: `translateY(-36px)`,
      transition: {
        opacity: {
          duration: 0.3,
        },
        transform: {
          duration: 0.4,
        },
      },
    },
  },
};
