import styled, { css } from 'styled-components';

import { LoadingLogoSize } from './LoadingLogo.model';

export const LogoWrapper = styled.div<{ dimensions: LoadingLogoSize }>`
  ${({ dimensions }) => css`
    width: ${dimensions.width};
    margin: 0 auto;

    .rive-canvas-size {
      height: ${dimensions.height};
    }
  `};
`;
