import { SignupInterestedValues } from '@yieldstreet/platform-kit';

export const SignupInterestedOptions = [
  {
    value: SignupInterestedValues.ART,
    label: 'Art',
  },
  {
    value: SignupInterestedValues.CRYPTO,
    label: 'Crypto',
  },
  {
    value: SignupInterestedValues.LEGAL,
    label: 'Legal',
  },
  {
    value: SignupInterestedValues.MULTI_ASSET_CLASS_FUND,
    label: 'Multi-asset class fund',
  },
  {
    value: SignupInterestedValues.PRIVATE_CREDIT,
    label: 'Private credit',
  },
  {
    value: SignupInterestedValues.PRIVATE_EQUITY,
    label: 'Private equity',
  },
  {
    value: SignupInterestedValues.REAL_ESTATE,
    label: 'Real estate',
  },
  {
    value: SignupInterestedValues.SHORT_TERM_NOTES,
    label: 'Short term notes',
  },
  {
    value: SignupInterestedValues.TRANSPORTATION,
    label: 'Transportation',
  },
  {
    value: SignupInterestedValues.VENTURE_CAPITAL,
    label: 'Venture Capital',
  },
];

export const SignupInterestedLabels = {
  title: 'Which asset classes interest you the most?',
};
