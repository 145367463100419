import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import {
  AutoInvestPreferencesApiResponse,
  SetAutoInvestPreferencesResponse,
  SetAutoInvestPreferencesRequest,
} from '../../types';

export const autoInvestPreferencesApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.AutoInvestPreferences],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getAutoInvestPreferences = builder.query<AutoInvestPreferencesApiResponse, void>({
        query: () => ({
          url: `/a/api/auto-invest/preferences`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.AutoInvestPreferences],
      });

      const setAutoInvestPreferences = builder.mutation<
        SetAutoInvestPreferencesResponse,
        SetAutoInvestPreferencesRequest
      >({
        query: ({ ...data }) => {
          return {
            url: `/a/api/auto-invest/register`,
            method: HttpMethods.Put,
            data,
          };
        },
        invalidatesTags: [Tags.AutoInvestPreferences],
      });

      return {
        getAutoInvestPreferences,
        setAutoInvestPreferences,
      };
    },
  });
