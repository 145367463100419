import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';

export const AssetClassesImage = styled.img`
  width: 100%;
  max-width: 330px;
  margin: ${({ theme }) => theme.spacing.m} auto 0;
  display: flex;

  ${media.desktop`
    margin: ${({ theme }) => theme.spacing['2xl']} auto 0;
  `}

  ${media.large`
    max-width: 90%;
  `}
`;

export const AssetClassesWrapper = styled.section`
  padding: ${({ theme }) => theme.spacing.m};

  ${media.desktop`
    padding: ${({ theme }) => theme.spacing['2xl']};
  `}
`;
