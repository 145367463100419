import { UserState } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeUserState: ObjectAnonymizer<UserState> = value =>
  anonymizeObject(value, {
    authToken: 'string',
    nickname: 'string',
    firstName: 'string',
    lastName: 'string',
    displayName: 'string',
    emailAddress: 'string',
  });
