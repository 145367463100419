import { Components, CssVarsTheme } from '@mui/material';

export const MuiAccordion: Components<CssVarsTheme>['MuiAccordion'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '1rem',
      marginBottom: theme.spacing(4),
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:before': {
        display: 'none',
      },
      '&:first-of-type': { borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' },
      '&:last-of-type': { borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem' },
    }),
  },
};
