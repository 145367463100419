import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import BulletImage from '../../assets/graphics/icons/checkmark.svg';
import { Heading, HeadingType, Paragraph } from '../../content/text';
import { media } from '../../themes/media';

interface BackgroundProps {
  backgroundImg?: string;
  fixedWidth?: boolean;
}

interface BulletsWrapperProps {
  direction?: 'row' | 'column';
  fixedWidth?: boolean;
}

interface BulletIconHolder {
  color?: 'primary' | 'secondary';
  size?: string;
  fixedWidth?: boolean;
}

interface BulletProps {
  title?: string;
  text: string;
  icon?: React.ReactNode;
}

interface BulletBannerProps extends BulletsWrapperProps, BackgroundProps {
  title: string;
  bullets: BulletProps[];
  footerContent?: React.ReactNode;
  bulletColor?: 'primary' | 'secondary';
  backgroundImg?: string;
  className?: string;
  bulletSize?: 'small' | 'big';
  headingTagType?: HeadingType;
  headingType?: HeadingType;
  fixedWidth?: boolean;
}

export const BulletBanner: FunctionComponent<BulletBannerProps> = ({
  title,
  bullets,
  direction = 'row',
  footerContent,
  bulletColor = 'primary',
  bulletSize = 'small',
  backgroundImg,
  headingTagType = 2,
  headingType = 2,
  className,
  fixedWidth = false,
}) => (
  <Wrapper fixedWidth={fixedWidth} backgroundImg={backgroundImg} className={className}>
    <SHeading
      inverse
      type={headingType}
      tagType={headingTagType}
      className="title"
      fixedWidth={fixedWidth}
    >
      {title}
    </SHeading>
    <BulletsWrapper className="bulletsWrapper" direction={direction} fixedWidth={fixedWidth}>
      {bullets.map((bullet, index) => (
        <Bullet className="bullet" fixedWidth={fixedWidth} key={`${bullet.title}-${index}`}>
          {bullet.icon ? (
            bullet.icon
          ) : (
            <BulletIconHolder
              color={bulletColor}
              fixedWidth={fixedWidth}
              size={bulletSize}
              className="bulletIconHolder"
            >
              <BulletIcon src={BulletImage} size={bulletSize} />
            </BulletIconHolder>
          )}
          <BulletContent fixedWidth={fixedWidth} className="bulletContent">
            {bullet.title && (
              <SHeadingBullet type={5} inverse>
                {bullet.title}
              </SHeadingBullet>
            )}
            <Paragraph inverse>{bullet.text}</Paragraph>
          </BulletContent>
        </Bullet>
      ))}
    </BulletsWrapper>
    {footerContent && <FooterWrapper>{footerContent}</FooterWrapper>}
  </Wrapper>
);

const Wrapper = styled.div<BackgroundProps>`
  background-color: ${props => props.theme.colors.background_inverse};

  padding: ${props => (props.fixedWidth ? '40px 35px' : '20px')};

  ${media.desktop`
    ${props =>
      props.fixedWidth &&
      `
      padding: 80px 35px;
    `}

    background-repeat: no-repeat;
    background-position: -20% 175%, 120% 175%;
    background-image: ${(props: BackgroundProps) =>
      `url(${props.backgroundImg}), url(${props.backgroundImg})`};
  `}
`;

const SHeading = styled(Heading)<{ fixedWidth: boolean }>`
  ${media.desktop`
      text-align: center;
    `}
`;

const BulletsWrapper = styled.div<BulletsWrapperProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.fixedWidth ? '60px' : '40px')};
  justify-content: center;

  ${media.tablet`
    align-items: flex-start;
  `}

  ${media.desktop`
    ${(props: BulletsWrapperProps) => props.direction && `flex-direction: ${props.direction}`}
  `}
`;

const Bullet = styled.div<{ fixedWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: ${props => (props.fixedWidth ? '0 30px 30px' : '30px')};
  width: 100%;
  ${media.tablet`
      width: auto;
  `}
`;

const BulletContent = styled.div`
  text-align: left;
  width: 100%;

  ${media.desktop<{ fixedWidth: boolean }>`
    ${props =>
      props.fixedWidth &&
      `
      width: 200px
    `};
  `}
`;

const SHeadingBullet = styled(Heading)`
  font-weight: 500;
  margin-bottom: 5px;
`;

const BulletIconHolder = styled.div<BulletIconHolder>`
  background: ${props =>
    props.color === 'primary'
      ? props.theme.colors.input_button_background
      : props.theme.colors.icon_inverse};
  border-radius: 50%;
  width: ${props => (props.size === 'small' ? '27px' : '40px')};
  height: ${props => (props.size === 'small' ? '27px' : '40px')};
  flex-shrink: 0;
  margin-right: ${props => (props.fixedWidth ? '20px' : '10px')};
  display: flex;
  justify-content: center;
  align-content: center;
`;

const BulletIcon = styled.img<{ size?: string }>`
  width: ${props => (props.size === 'small' ? '15px' : '20px')};
`;

const FooterWrapper = styled.div`
  text-align: left;
  width: 100%;

  ${media.tablet`
    margin: auto;
    text-align: center;
  `}
`;
