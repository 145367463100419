export enum ReducerPaths {
  Accreditation = 'accreditationApi',
  AutoInvestPreferences = 'autoInvestPreferencesApi',
  BankAccount = 'bankAccountApi',
  Communication = 'communicationApi',
  Campaign = 'campaignApi',
  Config = 'configApi',
  DeleteAccountRequest = 'deleteAccountRequestApi',
  DictionaryAssetClasses = 'dictionaryAssetClassesApi',
  FomoMetrics = 'fomoMetricsApi',
  Investment = 'investmentApi',
  InvestmentRequestOffering = 'investmentRequestOfferingApi',
  InvestorAccounts = 'investorAccountsApi',
  InvestorAccountsStatus = 'investorAccountStatusApi',
  Login = 'loginApi',
  Marketplace = 'marketplaceApi',
  Offering = 'offeringApi',
  OfferingEligibility = 'offeringEligibilityApi',
  PaymentMethods = 'paymentMethodsApi',
  PortfolioGetDocuments = 'portfolioGetDocumentsApi',
  PortfolioGraphQLApi = 'portfolioGraphQLApi',
  PortfolioInvestments = 'portfolioInvestmentsApi',
  PortfolioNavChart = 'portfolioNavChartApi',
  PortfolioPayments = 'portfolioPaymentsApi',
  PortfolioTiers = 'portfolioTiersApi',
  Quiz = 'quizApi',
  RecurringPayments = 'recurringPaymentsApi',
  Representative = 'representativeApi',
  User = 'userApi',
  UserProfile = 'userProfileApi',
  Users = 'usersApi',
  Wordpress = 'wordpressApi',
}
