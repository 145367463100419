import React, { forwardRef } from 'react';
import { TabComponentProps, TabTestIds as testIds } from './Tab.model';
import {
  Icon,
  TabContainer,
  TabContent,
  TextContent,
  TabIcon,
  TabNumericBadge,
  TabTitle,
} from './Tab.style';
import ChevronLeft from '../../assets/graphics/icons/chevron-left.svg';
import { useResponsiveFriendly } from '../../utils';

export const Tab = forwardRef<HTMLDivElement, TabComponentProps>(
  (
    {
      title,
      icon,
      numericBadge,
      onClick,
      isActive,
      disableIconInverse,
      vertical,
      textWrap,
      testId,
    },
    ref
  ) => {
    const handleOnClick = (event: React.SyntheticEvent) => onClick && onClick(event);

    const isLargeBadge = !!(numericBadge && numericBadge > 99);
    const badgeCounter = numericBadge && `${isLargeBadge ? '99+' : numericBadge}`;

    const isDesktop = useResponsiveFriendly() === 'desktop';

    return (
      <TabContainer
        data-cy={`${testId ? testId : testIds.container}`}
        disableIconInverse={disableIconInverse}
        isActive={isActive}
        onClick={handleOnClick}
        ref={ref}
        vertical={vertical}
      >
        <TabContent>
          <TextContent>
            {icon && <TabIcon data-cy={testIds.icon}>{icon}</TabIcon>}
            <TabTitle vertical={vertical} textWrap={textWrap}>
              {title}
            </TabTitle>
            {badgeCounter && (
              <TabNumericBadge isActive={isActive} isLarge={isLargeBadge}>
                {badgeCounter}
              </TabNumericBadge>
            )}
          </TextContent>
          {!isDesktop && vertical && <Icon src={ChevronLeft} />}
        </TabContent>
      </TabContainer>
    );
  }
);
