import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Label, Paragraph } from '../../content/text';
import { Button } from '../../content/button';
import { ExternalLink, LinkTextHoverStyles, LinkTextActiveStyles } from '../../content/link';
import { useResponsiveFriendly } from '../../utils/responsive';

import arrow from '../../assets/graphics/icons/arrow.svg';
import { Tooltip } from '../tooltip';

export interface CardProps {
  testId?: string | number;
  image?: string;
  icon?: string;
  fullImageMobile?: boolean;
  title: string | React.ReactNode;
  subtitle: string;
  description: string | React.ReactNode;
  cta?: string;
  buttonCTA?: boolean;
  tagTitle?: string;
  tooltipDesc?: string;
  tooltipKey?: string;
  upperTitle?: string;
  mobileDescription?: boolean;
  titleDecoration?: string;
  onClick?: () => void;
}

export const Card: FunctionComponent<CardProps> = ({
  testId,
  image,
  icon,
  fullImageMobile,
  title,
  subtitle,
  description,
  cta,
  buttonCTA,
  tagTitle,
  tooltipDesc,
  tooltipKey,
  upperTitle,
  mobileDescription,
  titleDecoration,
  onClick,
}) => {
  const viewPort = useResponsiveFriendly();
  const isMobile = viewPort === 'mobile';

  return (
    <>
      {image && (fullImageMobile || !isMobile) && (
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
      )}
      <CardContent onClick={onClick} data-cy={`${testId ? testId : 'card'}`}>
        {icon && (
          <IconWrapper>
            <Icon src={icon} />
          </IconWrapper>
        )}
        <CardText>
          <Label>{upperTitle}</Label>
          <Title titleDecoration={titleDecoration} type={5}>
            {title}
          </Title>
          <TagWrapper>
            {tagTitle && (
              <Tag>
                <Label semiBold inverse small>
                  {tagTitle}
                </Label>
              </Tag>
            )}
            {tooltipDesc && (
              <Tooltip
                testId="card-tooltip"
                children={tooltipDesc}
                place="top"
                tooltipKey={tooltipKey}
              />
            )}
          </TagWrapper>
          <Subtitle>{subtitle}</Subtitle>
          <Description
            mobileDescription={mobileDescription}
            small
            fullImageMobile={!!fullImageMobile}
          >
            {description}
          </Description>
          {cta && (
            <CTA fullImageMobile={!!fullImageMobile}>
              {buttonCTA ? <Button>{cta}</Button> : <ExternalLink>{cta}</ExternalLink>}
            </CTA>
          )}
        </CardText>
        {!fullImageMobile && <Arrow src={arrow} />}
      </CardContent>
    </>
  );
};

interface CardSelectionProps {
  options: CardProps[];
  buttonCTAs?: boolean;
  cardBorder?: boolean;
  fullImageMobile?: boolean;
  tabletColumns?: number;
  desktopColumns?: number;
  mobileDescription?: boolean;
}

export const CardSelection: FunctionComponent<CardSelectionProps> = ({
  options,
  buttonCTAs,
  cardBorder,
  fullImageMobile = false,
  tabletColumns = 2,
  desktopColumns = 3,
  mobileDescription = false,
}) => (
  <CardsContainer fullImageMobile={fullImageMobile}>
    {options.map((option, index) => (
      <CardWrapper
        key={index}
        tabletColumns={tabletColumns}
        desktopColumns={desktopColumns}
        cardBorder={cardBorder}
        fullImageMobile={fullImageMobile}
      >
        <Card
          {...option}
          buttonCTA={buttonCTAs}
          fullImageMobile={fullImageMobile}
          mobileDescription={mobileDescription}
        />
      </CardWrapper>
    ))}
  </CardsContainer>
);

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -20px;

  ${media.phoneLandscape`
      ${(props: FullImageProps) => props.fullImageMobile && 'flex-direction: row;'}
  `}
  ${media.tablet`
    ${(props: FullImageProps) => !props.fullImageMobile && 'flex-direction: row;'}
    flex-wrap: wrap;
    padding: 0 10px;
  `}
`;

interface CardWrapperProps extends FullImageProps {
  cardBorder?: boolean;
  tabletColumns: number;
  desktopColumns: number;
}

const CardWrapper = styled.div<CardWrapperProps>`
  background: ${props => props.theme.colors.background_card};
  margin: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.m}`};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: ${props => props.theme.shadows.shadow_default};
  flex-basis: 100%;

  ${props => props.cardBorder && `border: 1px solid ${props.theme.colors.border_default};`}

  ${media.phoneLandscape`
    ${(props: CardWrapperProps) => props.fullImageMobile && 'margin: 10px;'}
  `}
  ${media.tablet`
    &:hover {
      transform: translateY(-2px);
      box-shadow: ${props => props.theme.shadows.shadow_hover};

      a {
        ${LinkTextHoverStyles}
      }

      button {
        & .text {
          ${LinkTextHoverStyles}
        }
      }
    }
    &:active {
      transform: translateY(2px);
      box-shadow: ${props => props.theme.shadows.shadow_active};

      a {
        ${LinkTextActiveStyles}
      }
    }
  `}
  ${media.tablet`
    ${(props: CardWrapperProps) => !props.fullImageMobile && 'margin: 10px;'}
    flex-basis: calc((100% / ${(props: CardWrapperProps) => props.tabletColumns}) - 20px);
  `}
  ${media.desktop`
    flex-basis: calc((100% / ${(props: CardWrapperProps) => props.desktopColumns}) - 20px);
  `}
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  width: 100%;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
`;

interface TitleProps {
  titleDecoration?: string;
}

const Title = styled(Heading)`
  text-decoration: ${(props: TitleProps) => props.titleDecoration || 'none'};
  ${media.tablet`
    margin-bottom: 5px;
  `};
`;

const Subtitle = styled(Label)`
  cursor: pointer;

  ${media.tablet`
    margin-bottom: 20px;
  `}
`;

interface FullImageProps {
  fullImageMobile: boolean;
  mobileDescription?: boolean;
}

const Description = styled(Paragraph)`
  flex-grow: 1;
  margin-bottom: 20px;
  ${(props: FullImageProps) => props.mobileDescription && 'margin-top: 10px;'}
  ${(props: FullImageProps) =>
    props.fullImageMobile || props.mobileDescription ? 'display: block;' : 'display: none;'}

  ${media.tablet`
    margin-bottom: 40px;
    margin-top: 0px;
    display: block;
  `}
`;

const CTA = styled.div`
  width: fit-content;
  ${(props: FullImageProps) => !props.fullImageMobile && 'display: none;'}

  ${media.tablet`
    display: block;
  `}
`;

const Arrow = styled.img`
  height: 10px;
  transform: rotate(-90deg);
  align-self: center;

  ${media.tablet`
    display: none;
  `}
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Tag = styled.div`
  background: ${props => props.theme.colors.background_inverse};
  padding: 0 6px 2px;
  margin-right: 5px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: block;
  max-height: 140px;
  overflow: hidden;

  ${media.tablet`
    max-height: unset;
    overflow: unset;
  `};
`;

const Image = styled.img`
  width: 100%;
`;

const IconWrapper = styled.div`
  height: 120px;
  margin-right: auto;
  display: block;
`;

const Icon = styled.img`
  margin-bottom: 20px;
  margin-right: auto;
`;
