import React, { ReactNode, FunctionComponent } from 'react';
import styled from 'styled-components';

import { SectionContainer } from '../../layout/section-container';
import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
import { TextBlockProps } from '../../sections/text-block-component/TextBlock.model';

interface MarketingSidePhotoContentProps extends Omit<TextBlockProps, 'align'> {
  reverse?: boolean;
  inverse?: boolean;
  image?: ReactNode;
}

export const MarketingSidePhotoContent: FunctionComponent<MarketingSidePhotoContentProps> = ({
  reverse,
  inverse,
  label,
  heading,
  content,
  image,
  button,
  ...props
}) => {
  return (
    <SectionContainer>
      <Background reverse={reverse} inverse={inverse}>
        <TextBlockContainer>
          <TextBlock
            label={label}
            heading={heading}
            content={content}
            inverse={inverse}
            button={button}
            {...props}
          />
        </TextBlockContainer>
        <ImageContainer>
          <MainImage>{image}</MainImage>
        </ImageContainer>
      </Background>
    </SectionContainer>
  );
};

const Background = styled.div<Partial<MarketingSidePhotoContentProps>>`
  display: flex;
  flex-direction: column-reverse;

  ${media.desktop<Pick<MarketingSidePhotoContentProps, 'reverse'>>`
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-content: center;
  `}
`;

const ImageContainer = styled.div`
  margin-bottom: 40px;

  ${media.desktop`
    margin-bottom: 0px;
  `}
`;

const MainImage = styled.div`
  text-align: center;
  video {
    width: 100%;
  }
  img {
    width: 100%;
  }
  ${media.desktop`
    img{
      width: auto;
    }
    video {
      width: auto;
    }
  `}
`;

const TextBlockContainer = styled.div`
  display: flex;
  width: 100%;
  ${media.desktop`
    width: 410px;
  `}
`;
