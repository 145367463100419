import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Container = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  maxWidth: '335px',
  padding: `0 ${theme.spacing(5)}`,

  [theme.breakpoints.up('sm')]: {
    maxWidth: '790px',
  },
}));

export const Icon = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(8),
  maxWidth: '110px',
}));
