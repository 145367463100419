import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
import {
  PortfolioStatementsRequest,
  PortfolioStatementsResponse,
  PortfolioStatementsApiResponse,
  PortfolioStatementsProviderApiRequest,
  PortfolioStatementsProviderApiResponse,
  PortfolioSynapseStatementApiResponse,
  PortfolioSynapseStatementApiRequest,
} from './statements.model';

export const statementsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioStatements, Tags.PortfolioStatementsProviders],
  })
  .injectEndpoints({
    endpoints: build => {
      const getStatement = build.query<PortfolioStatementsResponse, PortfolioStatementsRequest>({
        query: ({
          rangeType,
          rangeValue,
          userId,
          year,
          provider,
          investorAccountId,
        }: PortfolioStatementsRequest) => {
          return {
            url: `/a/api/portfolios/${userId}/statements`,
            method: HttpMethods.Get,
            params: {
              rangeType,
              rangeValue,
              year,
              provider,
              investorAccountId,
            },
          };
        },
        providesTags: [Tags.PortfolioStatements],
        transformResponse: ({ statement }: PortfolioStatementsApiResponse) => ({
          ...statement,
        }),
      });

      const getStatementProviders = build.query<
        PortfolioStatementsProviderApiResponse,
        PortfolioStatementsProviderApiRequest
      >({
        query: ({
          userId,
          managementStrategy,
          investorAccountId,
        }: PortfolioStatementsProviderApiRequest) => {
          return {
            url: `/a/api/user/${userId}/reports/provider-list`,
            method: HttpMethods.Get,
            params: {
              investorAccountId,
              managementStrategy,
            },
          };
        },
        providesTags: [Tags.PortfolioStatementsProviders],
      });

      const getSynapseStatement = build.query<
        PortfolioSynapseStatementApiResponse,
        PortfolioSynapseStatementApiRequest
      >({
        query: ({ investorAccountId, yearMonth }: PortfolioSynapseStatementApiRequest) => {
          return {
            url: `/a/api/managed/portfolio/statement/synapse/${investorAccountId}/${yearMonth}`,
            method: HttpMethods.Get,
            responseType: 'blob',
          };
        },
      });

      return {
        getStatement,
        getStatementProviders,
        getSynapseStatement,
      };
    },
  });
