import { ReactNode } from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

import { ContainerProps } from '../container';
import { MuiTheme } from '../../../theme';

export const { useTestIds } = genTestIds(['page-container']);

export const enum PageContainerBackgrounds {
  Dark = 'dark',
  Light = 'light',
  None = 'none',
}

export interface PageContainerProps extends ContainerProps {
  background?: `${PageContainerBackgrounds}`;
  children: ReactNode;
  testId?: string;
  /**
   * @deprecated use `testId` instead.
   * */
  id?: string;
  /**
   * @deprecated use `background` instead.
   * */
  light?: boolean;
}

export interface PageContainerStyleProps extends Pick<PageContainerProps, 'background' | 'light'> {
  theme?: MuiTheme;
}
