import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';

import closeIcon from '../../assets/graphics/icons/close-icon-small.svg';
import { Tags, TagProps } from './Tags';

interface Props {
  tags: TagProps[];
  isHidden: boolean;
  onClose: (e: React.MouseEvent) => void;
  hasLegacyOfferingTags: boolean;
}

export const ExtraTags: FunctionComponent<Props> = ({
  tags = [],
  isHidden = true,
  onClose,
  hasLegacyOfferingTags,
}) => (
  <Wrapper>
    <Collapse in={!isHidden}>
      <TagsWrapper>
        <AllTagsCloseButton data-cy="past-offerings-card-close-all-tags" onClick={onClose}>
          <Icon src={closeIcon} alt="Close all tags list" />
        </AllTagsCloseButton>
        <Tags
          data-cy="past-offerings-card-all-tags"
          tags={tags}
          hasCloseButton={true}
          hasLegacyOfferingTags={hasLegacyOfferingTags}
        />
      </TagsWrapper>
    </Collapse>
  </Wrapper>
);

const Wrapper = styled.div`
  background: ${props => props.theme.colors.background_card};
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
`;

const TagsWrapper = styled.div`
  position: relative;
`;

const Icon = styled.img`
  padding: 10px;
`;

export const AllTagsCloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 0px;
  margin: 0px;
  padding: 0px;
  border: none;
  background: transparent;
`;
