import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { PageSection } from '../../layout/page-section';
import { Paragraph } from '../../content/text';
import { media } from '../../themes/media';

interface ArticleHeaderProps {
  title?: string;
  subTitle?: string;
  image?: React.ReactNode;
  sponsored?: React.ReactNode;
}

export const ArticleHeader: FunctionComponent<ArticleHeaderProps> = ({
  title,
  subTitle,
  image,
  sponsored,
  children,
}) => (
  <PageSection>
    <OuterContainer>
      {sponsored}
      <ContentHolder>
        <BioHolder>
          <BioImage>{image}</BioImage>
          <BioContent>
            <Paragraph small>
              <strong>{title}</strong>
            </Paragraph>
            <Paragraph small>{subTitle}</Paragraph>
          </BioContent>
        </BioHolder>
        <Paragraph>{children}</Paragraph>
      </ContentHolder>
    </OuterContainer>
  </PageSection>
);

const OuterContainer = styled.div`
  position: relative;
  padding: 0 20px 0 20px;
  ${media.desktop`
    padding: 0 130px 0 130px;
  `};
`;

const ContentHolder = styled.div`
  &:only-child {
    padding: 80px 0 0 0;
  }
  padding: 60px 0 0 0;
`;

const BioHolder = styled.div`
  background: ${props => props.theme.colors.background_inverse_secondary};
  width: calc(100% - 40px);
  display: flex;
  position: absolute;
  align-items: center;
  top: -50px;
  left: 20px;
  ${media.tablet`
    left: auto;
    width: auto;
    padding-right: 20px;
  `}
`;

const BioImage = styled.div`
  margin: 20px;
  padding: 7px;
  background: ${props => props.theme.colors.background_default};
  display: inherit;
  img {
    display: block;
    width: 44px;
  }
`;

const BioContent = styled.div`
  flex-wrap: wrap;
  p {
    color: ${props => props.theme.colors.text_background_inverse};
  }
`;
