import React from 'react';
import {
  Heading,
  MarketingCinematicContainer,
  NewModal,
  Paragraph,
  SectionContainer,
  TextBlock,
  useModalContext,
} from '@yieldstreet/ui-kit';

import { InvestButton } from 'components/ui/InvestButton/InvestButton';
import { stats } from './Stats.model';
import {
  Background,
  StatsWrapper,
  StatItem,
  ModalWrapper,
  ModalList,
  Divider,
} from './Stats.style';

export const Stats = () => {
  const { showModal } = useModalContext();
  return (
    <MarketingCinematicContainer>
      <Background>
        <SectionContainer>
          <TextBlock
            align="center"
            heading="Supplement your portfolio’s income"
            headingTagType={2}
            headingType={2}
            inverse
            content={
              <>
                <Paragraph inverse paddingHorizontal={{ desktop: '5xl' }}>
                  Consider diversifying your traditional fixed income allocation with a Fund that
                  seeks to generate quarterly income from a range of private market assets.
                  <br />
                  More than 50% of Yieldstreet’s accredited investors have allocated to the Fund.
                </Paragraph>
                <StatsWrapper>
                  {stats.map(({ label, value, modalId, footNote }) => (
                    <StatItem
                      key={value}
                      onClick={() => showModal(modalId)}
                      tabIndex={-1}
                      hasModal={modalId !== ''}
                    >
                      <Paragraph inverse>{label}</Paragraph>
                      <Heading type={3} tagType={3} inverse>
                        {value}
                      </Heading>
                      {footNote && (
                        <Paragraph size="chart" inverse secondary marginTop="xs">
                          {footNote}
                        </Paragraph>
                      )}
                    </StatItem>
                  ))}
                </StatsWrapper>
              </>
            }
            button={<InvestButton />}
          />
          <Paragraph size="small" inverse marginTop="l">
            Open to all investors, regardless of accreditation status.{' '}
          </Paragraph>
          <Paragraph size="small" inverse>
            Individual, retirement, and entity Yieldstreet accounts are all eligible to invest.
          </Paragraph>
          <Paragraph size="small" inverse>
            No offers will be made to or accepted from investors residing in or located in Nebraska
            or North Dakota.
          </Paragraph>
        </SectionContainer>
      </Background>

      <NewModal modalId="fees-modal">
        <ModalWrapper>
          <TextBlock
            heading="Fees"
            headingTagType={3}
            headingType={3}
            content={
              <>
                <Paragraph>
                  The Fund may incur additional investment-related expenses, such as leverage
                  expense and current and deferred income taxes. There are also no up-front load or
                  redemption fees.
                </Paragraph>
                <Divider />
              </>
            }
          />
          <TextBlock
            heading="Total annual expense ratio: 3.98%"
            headingTagType={4}
            headingType={4}
            content={
              <ModalList>
                <li>
                  <Paragraph>Annual management fee: 1.00%</Paragraph>
                </li>
                <li>
                  <Paragraph>Interest payments on borrowed funds: 1.18%</Paragraph>
                </li>
                <li>
                  <Paragraph>Other expenses: 1.70%</Paragraph>
                </li>
                <li>
                  <Paragraph>Acquired Fund Fees and Expenses: 0.10%</Paragraph>
                </li>
              </ModalList>
            }
          />
          <TextBlock
            heading="Adjusted annual expense ratio: 2.70%"
            headingTagType={4}
            headingType={4}
            content={
              <Paragraph size="small">
                Adjusted Expense Ratio excludes certain investment-related expenses borne by the
                Fund, such as the Fund's estimated annual leverage expense and current and deferred
                income taxes from the Fund's consolidated subsidiaries incurred by the Fund’s
                wholly-owned subsidiaries, none of which are paid to Yieldstreet Management.
              </Paragraph>
            }
          />
        </ModalWrapper>
      </NewModal>
    </MarketingCinematicContainer>
  );
};
