export enum InvestorEntityStepVerificationStatus {
  MISSING = 'MISSING',
  PENDING = 'PENDING',
  ACTION_NEEDED = 'ACTION_NEEDED',
  FAILED = 'FAILED',
  ATTENTION = 'ATTENTION',
  CONFIRMED = 'CONFIRMED',
  UNKNOWN = 'UNKNOWN',
  INVALID = 'INVALID',
}
