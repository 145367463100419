import styled, { css } from 'styled-components';

export interface LinkStyleProps {
  isBreadcrumb?: boolean;
  inverse?: boolean;
}

const selectColor = ({ inverse }: LinkStyleProps) => {
  if (inverse) {
    return 'text_link_inverse';
  }
  return 'text_link';
};

const DefaultLinkBackgroundStyle = css<LinkStyleProps>`
  background-image: linear-gradient(
    to right,
    ${props => props.theme.colors[selectColor(props)]} 45%,
    transparent 45%,
    transparent 55%,
    ${props => props.theme.colors[selectColor(props)]} 55%
  );
`;

export const LinkTextStyles = css<LinkStyleProps>`
  ${props => (props.isBreadcrumb ? '' : DefaultLinkBackgroundStyle)}
  background-repeat: no-repeat;
  background-size: 225% 0.1em;
  background-position: 100% 100%;
  padding-bottom: 1px;
  text-decoration: none;
  color: ${props => props.theme.colors[selectColor(props)]};
`;

export const LinkTextHoverStyles = css<LinkStyleProps>`
  background-position: 0 100%;
  transition-property: background-position;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  color: ${props => props.theme.colors[selectColor(props)]};
`;

export const LinkTextActiveStyles = css<LinkStyleProps>`
  background-image: linear-gradient(
    to right,
    ${props => props.theme.colors.active} 0%,
    ${props => props.theme.colors.active} 100%
  );
`;

export const BreadcrumbStyle = css<LinkStyleProps>`
  &:hover {
    text-decoration: underline;
    text-underline-position: from-font;
  }
`;

export const RegularLinkStyle = css<LinkStyleProps>`
  ${css<LinkStyleProps>`
    &:hover {
      ${LinkTextHoverStyles}
    }
    &:active {
      ${LinkTextActiveStyles}
    }
  `}
`;

export const LinkStyles = css<LinkStyleProps>`
  cursor: pointer;
  font-family: ${props => props.theme.typography.secondary};

  ${css<LinkStyleProps>`
    ${LinkTextStyles}
    ${props => (props.isBreadcrumb ? BreadcrumbStyle : RegularLinkStyle)}
  `}
`;

export const Link = styled.a<LinkStyleProps>`
  ${LinkStyles}
`;
