import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { SliderInput } from '../../../../form';
import Arrow from '../../assets/arrow-round.svg';

export const AmountSelectorHolder = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
  padding: `${theme.spacing(4)} ${theme.spacing(4)} 0`,
  borderRadius: '4px',
  backgroundColor: theme.palette.background.default,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 0,
    padding: theme.spacing(4),
  },
}));

export const AmountSliderHolder = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4),
  borderRadius: '4px',
  background: theme.palette.background.page.product,
}));

export const AmountTitle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: '25%',
  textAlign: 'left',
}));

export const Label = styled(Typography)<{ inverse?: boolean; semiBold?: boolean }>(
  ({ inverse, semiBold, theme }) => ({
    color: inverse
      ? theme.palette.text.labelPrimary.inverse
      : theme.palette.text.labelPrimary.default,
    fontWeight: semiBold ? 500 : 400,
  })
);

export const LineValue = styled('div')(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: `${theme.spacing(1)} 0`,
}));

export const Paragraph = styled(Typography)<{ inverse?: boolean; semiBold?: boolean }>(
  ({ inverse, semiBold, theme }) => ({
    color: inverse
      ? theme.palette.text.paragraphPrimary.inverse
      : theme.palette.text.paragraphPrimary.default,
    fontWeight: semiBold ? 500 : 400,
  })
);

export const SliderContainer = styled('div')(() => ({
  display: 'flex',
  minWidth: '250px',
}));

export const SSliderInput = styled(SliderInput)<{ trackColor?: string }>(
  ({ theme, trackColor }) => ({
    marginBottom: '0 !important',

    [theme.breakpoints.up('md')]: {
      marginTop: 'auto !important',
    },

    '> span': {
      display: 'none',
    },

    span: {
      color: trackColor ? trackColor : '#0F477F', // FIX ME: replace with new chart color
    },

    p: {
      backgroundColor: theme.palette.background.page.product,
      borderRadius: '4px',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },

    '.MuiSlider-root': {
      marginBottom: 0,

      '.MuiSlider-track': {
        transition: 'unset',
      },

      '.MuiSlider-thumb': {
        position: 'relative',
        width: '34px',
        height: '18px',
        background: trackColor ? trackColor : '#0F477F', // FIX ME: replace with new chart color
        borderRadius: '4px',
        transitionProperty: 'height, width, box-shadow',
        transitionDuration: '0.3s',

        '&:hover': {
          height: '23px',
          width: '39px',
        },

        '&:before, &:after': {
          position: 'absolute',
          bottom: 'auto',
          width: '5px',
          height: '8px',
          backgroundImage: `url(${Arrow})`,
          backgroundSize: 'cover',
          content: '""',
        },

        '&:before': {
          left: theme.spacing(1),
          transform: 'scaleX(-1)',
        },

        '&:after': {
          left: 'auto',
          right: theme.spacing(1),
        },
      },
    },
  })
);
