import { styled } from '@mui/material/styles';

export const ExternalProviderIcon = styled('img')<{ backgroundColor?: string }>`
  padding: 10px;
  width: 80px;
  height: 80px;
  box-shadow: ${props => props.theme.shadows[3]};
  border-radius: 10px;
  background-color: ${props => props.backgroundColor || '#1A1B1D'};
`;
