export const getSnackbarTestIds = (testId: string) => ({
  actionClose: `${testId}-snackbar-close`,
  title: `${testId}-snackbar-title`,
  description: `${testId}-snackbar-description`,
  link: `${testId}-snackbar-link`,
  container: `${testId}-snackbar-container`,
});

export type SnackbarStateType = {
  snackbarQueue: SnackbarProps[];
  enqueueSnackbar: (_snackbarObj: SnackbarProps) => void;
  closeSnackbar: (_snackbarKey?: string) => void;
};

export const snackbarInitialState: SnackbarStateType = {
  snackbarQueue: [] as SnackbarProps[],
  enqueueSnackbar: (_snackbarObj: SnackbarProps) => {},
  closeSnackbar: (_snackbarKey?: string) => {},
};

export enum SnackbarTypes {
  Info = 'INFO',
  Success = 'SUCCESS',
  Error = 'ERROR',
  Warning = 'WARNING',
}

export interface SnackbarProps {
  key: string | number;
  isVisible?: boolean;
  type: SnackbarTypes;
  title?: string;
  description?: string;
  /** @deprecated use testId field instead */
  dataCy?: string;
  testId?: string;
  linkText?: string;
  handleLink?: () => void;
  closeSnackbar?: () => void;
  autoHideDuration?: number;
}
