import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Paragraph } from '../../content/text';

/* ============================
 *    Callout module
 * ============================ */

interface CalloutProps {
  copy: string;
}

export const Callout: FunctionComponent<CalloutProps> = ({ copy }) => {
  return (
    <CalloutUI>
      <CalloutText>{copy}</CalloutText>
    </CalloutUI>
  );
};

/* ===== Component UI elements ===== */

const CalloutUI = styled.div`
  box-sizing: border-box;
  padding: 20px 0px;
  background-color: ${props => props.theme.colors.background_card};
  margin: 0 auto;
  position: relative;
  top: 60px;
  width: 100%;
  text-align: center;
  ${media.tablet`
    width:80%;
  `}
  ${media.desktop`
    text-align:left;
    height:220px;
    width:200px;
    position:absolute;
    top:auto;
    right:0;
    bottom:120px;
    padding:40px 15px;
  `}
`;

/* ===== Type styles ==== */

const CalloutText = styled(Paragraph)`
  width: 80%;
  margin: 0 auto;
  max-width: 420px;
`;
