import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import { Paragraph, Heading } from '../../content/text';
import { media } from '../../themes';
import { useResponsiveFriendly } from '../../utils/responsive';

export interface CarouselSectionProps {
  section: Section;
}

type Section = {
  type: string;
  title?: string;
  autoplayInterval?: number;
  content: Content[];
};

type Content = {
  slideTitle: string;
  slideText: string;
};

interface SlideControlProps {
  isActive: boolean;
  onClick: Function;
}

export const CarouselSection: React.FunctionComponent<CarouselSectionProps> = ({ section }) => {
  const { title, autoplayInterval, content } = section;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [displayCarousel, setDisplayCarousel] = useState(false);

  useEffect(() => {
    setDisplayCarousel(true);
  }, [setDisplayCarousel]);

  const viewPort = useResponsiveFriendly();

  return (
    <Wrapper data-cy="offering-v5-section-carousel-wrapper">
      <InnerWrapper>
        {viewPort !== 'mobile' && (
          <ControlWrapper data-cy="offering-v5-section-carousel-control-wrapper">
            <WhiteHeading type={3} data-cy="offering-v5-section-carousel-control-title">
              {title}
            </WhiteHeading>
            {content.map((slide, idx) => {
              const { slideTitle } = slide;
              return (
                <SlideControl
                  data-cy="slide-control"
                  type={4}
                  key={`slide-control-${slideTitle}-${idx}`}
                  onClick={() => setCurrentSlide(idx)}
                  isActive={currentSlide === idx}
                >
                  {slideTitle}
                </SlideControl>
              );
            })}
          </ControlWrapper>
        )}

        {displayCarousel && (
          <SCarousel
            afterSlide={(slideIndex: number) => setCurrentSlide(slideIndex)}
            autoplay
            autoplayInterval={autoplayInterval ? autoplayInterval : 5000}
            cellAlign="center"
            cellSpacing={400}
            data-cy="offering-v5-section-carousel-slide-wrapper"
            dragging
            framePadding="10px"
            heightMode="current"
            initialSlideHeight={400}
            slideIndex={currentSlide}
            slidesToShow={1}
            vertical={viewPort !== 'mobile'}
            wrapAround
          >
            {content.map((slide, idx) => {
              const { slideTitle, slideText } = slide;
              return (
                <Fragment key={`slide-content-${slideTitle}-${idx}`}>
                  {viewPort !== 'mobile' && (
                    <DesktopContentWrapper data-cy="offering-v5-section-carousel-slide-content-desktop">
                      <Paragraph small>
                        <span dangerouslySetInnerHTML={{ __html: slideText }} />
                      </Paragraph>
                    </DesktopContentWrapper>
                  )}
                  {viewPort === 'mobile' && (
                    <MobileContentWrapper data-cy="offering-v5-section-carousel-slide-content-mobile">
                      <MobileCarouselHeadingWrapper>
                        <MobileCarouselHeading type={4}>{slideTitle}</MobileCarouselHeading>
                      </MobileCarouselHeadingWrapper>
                      <MobileCarouselContent>
                        <span dangerouslySetInnerHTML={{ __html: slideText }} />
                      </MobileCarouselContent>
                    </MobileContentWrapper>
                  )}
                </Fragment>
              );
            })}
          </SCarousel>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto 30px;
  position: relative;

  ${media.tablet`
    margin: 0 auto 35px;
    background: ${props => props.theme.colors.chart_8};
  `};
`;

const InnerWrapper = styled.div`
  display: flex;
  margin: 0 20px;
  max-width: 1180px;

  ${media.tablet`
    margin: 0 auto;
    padding: 0 35px;
    min-height: 400px;
  `};

  ${media.large`
    padding: 0;
  `};
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  margin: 20px 10%;
  color: ${props => props.theme.colors.text_background_inverse};

  ${media.tablet`
    margin: 20px 0;
  `};
`;

const WhiteHeading = styled(Heading)`
  color: ${props => props.theme.colors.text_background_inverse};
  margin-bottom: 30px;
`;

const SlideControl = styled(WhiteHeading)<SlideControlProps>`
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: ${props => (props.isActive ? 500 : 'normal')};
`;

const DesktopContentWrapper = styled.div`
  display: block;
`;

const MobileContentWrapper = styled.div`
  background: ${props => props.theme.colors.background_card};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 6px;
  min-height: 251px;
`;

const MobileCarouselHeadingWrapper = styled.div`
  padding: 20px;
  height: 68px;
  background: ${props => props.theme.colors.chart_8};
`;

const MobileCarouselHeading = styled(WhiteHeading)`
  margin-bottom: 0;
`;

const MobileCarouselContent = styled(Paragraph)`
  padding: 22px 20px 51px;
`;

const SCarousel = styled(Carousel)<any>`
  display: block;
  background: ${props => props.theme.colors.background_default};
  margin: 0;

  ${media.tablet`
    margin: 10px 0;
    overflow: hidden !important;
    z-index: 2;
  `};

  .slider-control-centerright,
  .slider-control-centerleft {
    display: none;
  }

  .slider-control-bottomcenter {
    width: 100% !important;
    text-align: center;
    bottom: -25px !important;

    ${media.tablet`
      display: none;
    `};

    ul {
      justify-content: center !important;
    }
  }

  .paging-item {
    button {
      padding: 0 4px !important;
      opacity: unset !important;
      fill: transparent !important;
    }

    &.active {
      .paging-dot {
        background: ${props => props.theme.colors.chart_1} !important;
      }
    }

    .paging-dot {
      width: 10px !important;
      height: 10px !important;
      background: ${props => props.theme.colors.border_strong} !important;
      border-radius: 10px !important;
    }
  }

  .slider-list {
    width: 100% !important;
    margin-bottom: 40px !important;
  }

  .slider-slide {
    vertical-align: middle !important;
  }

  .slider-frame {
    margin: 0 !important;

    ${media.tablet`
      max-width: 70% !important;
      margin: auto !important;
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: visible !important;
    `};
  }
`;
