import styled from 'styled-components';
import { media, SectionContainer } from '@yieldstreet/ui-kit';

export const Background = styled.section`
  background: radial-gradient(110.49% 110.5% at 47.4% 104.74%, #a7c1d9 0%, #556c9a 100%);
  text-align: center;
  ${media.desktop`
    white-space: pre-line;
    padding: ${({ theme }) => theme.spacing.ml};
  `}
`;

export const Image = styled.img`
  max-width: 600px;
`;

export const SContainer = styled(SectionContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${media.desktop`
    flex-direction: row;
  `}
`;

export const TextBlockContainer = styled.div`
  text-align: left;
  & > h4 {
    color: ${({ theme }) => theme.colors.background_page};
  }
  width: 100%;
  margin: ${({ theme }) => theme.spacing.m} 0;
  ${media.desktop`
    width: 635px;
    margin: ${({ theme }) => theme.spacing['2xl']} 0;
  `}
`;

export const ImageContainer = styled.div`
  display: flex;
  & > img {
    display: flex;
    width: 100%;
  }
  ${media.desktop`
    margin: 0;
    display: flex;
  `}
`;

export const StatItem = styled.div<{ hasModal: boolean }>`
  text-align: left;
  cursor: ${({ hasModal }) => hasModal && 'pointer'};
  & > p {
    text-decoration: ${({ hasModal }) => hasModal && 'underline dotted'};
  }
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.s};
  margin: ${({ theme }) => theme.spacing.ml} 0;
`;
