export enum FormatCurrencyDecimals {
  ALWAYS = 'always',
  NEVER = 'never',
  AUTO = 'auto',
}
interface FormatOptions {
  locale: string;
  currency: string;
  style: string;
  decimals: FormatCurrencyDecimals;
  abbreviate: boolean;
  decimalSeparator: string;
  digitGroupSeparator: string;
}

const defaultFormatOptions: FormatOptions = {
  locale: 'en-EN',
  currency: 'USD',
  style: 'currency',
  decimals: FormatCurrencyDecimals.AUTO,
  abbreviate: false,
  decimalSeparator: '.',
  digitGroupSeparator: ',',
};

const getDecimalSeparator = (locale: string) => {
  const input = 1.1;
  if (!Intl) {
    return '.';
  }
  const separator = Intl.NumberFormat(locale)
    .formatToParts(input)
    .find(item => item.type === 'decimal');
  return separator ? separator.value : '.';
};

const formatAbbreviate = (value: string, options: Partial<FormatOptions>): string => {
  const opts = {
    ...defaultFormatOptions,
    ...options,
  };

  const hasDecimals =
    opts.decimals !== FormatCurrencyDecimals.NEVER
      ? false
      : value.indexOf(opts.decimalSeparator) > -1;
  const digitGroups = value.split(opts.digitGroupSeparator);
  const lastDigitGroup = digitGroups[digitGroups.length - 1];

  if (hasDecimals) return value;

  if (lastDigitGroup === '000') {
    value = value.replace(`${opts.digitGroupSeparator}${lastDigitGroup}`, 'K');
  } else if (lastDigitGroup.endsWith('00')) {
    value = value.replace(
      `${opts.digitGroupSeparator}${lastDigitGroup}`,
      `.${lastDigitGroup.charAt(0)}K`
    );
  }

  return value;
};

const formatDecimals = (value: string, options: FormatOptions) => {
  const opts = {
    ...defaultFormatOptions,
    ...options,
  };
  switch (opts.decimals) {
    case FormatCurrencyDecimals.NEVER:
      return (value = value.split(opts.decimalSeparator)[0]);

    case FormatCurrencyDecimals.AUTO:
      if (parseInt(value.split(opts.decimalSeparator)[1]) === 0) {
        return (value = value.split(opts.decimalSeparator)[0]);
      }
      return value;

    default:
      return value;
  }
};
export const formatCurrency = (number: number, options: Partial<FormatOptions> = {}) => {
  const opts: FormatOptions = {
    ...defaultFormatOptions,
    ...options,
  };

  opts.decimalSeparator = getDecimalSeparator(opts.locale);
  opts.digitGroupSeparator = opts.decimalSeparator === '.' ? ',' : '.';

  let value = '';

  if (Intl) {
    value = new Intl.NumberFormat(opts.locale, {
      style: opts.style,
      currency: opts.currency,
    }).format(number);
  }

  if (opts.decimals !== FormatCurrencyDecimals.ALWAYS) {
    value = formatDecimals(value, opts);
  }

  if (opts.abbreviate) {
    value = formatAbbreviate(value, opts);
  }

  return value;
};

export const isCorrectIncrement = (value: number, increment: number): boolean => {
  return value > 0 ? value / increment === Math.round(value / increment) : false;
};

export const isCorrectRange = (value: number, minimum: number, maximum: number) => {
  return value && value >= minimum && value <= maximum;
};
