import { genTestIds } from '@yieldstreet/tool-kit';

const { getTestIds } = genTestIds(['button', 'description', 'title']);
export const testIds = getTestIds('promo-pop-over');

export interface PromoPopOverProps {
  buttonText: string;
  description: string;
  icon: string;
  onClick: Function;
  onClose: () => void;
  storageId: string;
  testId?: string | number;
  title: string;
}
