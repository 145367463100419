import styled, { css } from 'styled-components';
import { ButtonContentProps, ButtonProps } from './Button.model';

export const ButtonDisabledStyles = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.input_disabled_background};
  border-color: ${({ theme }) => theme.colors.border_default};
  color: ${({ theme }) => theme.colors.input_disabled_text};
  text-decoration: none;
  cursor: not-allowed;
`;

export const ButtonBasicStyles = css<ButtonProps>`
  background-color: ${({ inverse, theme }) =>
    inverse
      ? theme.colors.input_button_basic_inverse_background
      : theme.colors.input_button_basic_background};

  border-color: ${({ inverse, theme }) =>
    inverse ? theme.colors.border_inverse : theme.colors.border_outline};

  color: ${({ inverse, theme }) =>
    inverse
      ? theme.colors.input_button_basic_inverse_text
      : theme.colors.input_button_basic_text} !important;

  &:hover {
    background-color: ${({ inverse, theme }) =>
      inverse
        ? theme.colors.input_button_basic_inverse_background_hover
        : theme.colors.input_button_basic_background_hover};

    border-color: ${({ inverse, theme }) =>
      inverse
        ? theme.colors.input_button_basic_inverse_background_hover
        : theme.colors.input_button_basic_background_hover};
  }

  &:active {
    background-color: ${({ inverse, theme }) =>
      inverse
        ? theme.colors.input_button_basic_inverse_background_active
        : theme.colors.input_button_basic_background_active};

    border-color: ${({ inverse, theme }) =>
      inverse
        ? theme.colors.input_button_basic_inverse_background_active
        : theme.colors.input_button_basic_background_active};
  }
`;

export const ButtonSecondaryStyles = css<ButtonProps>`
  background-color: ${({ inverse, theme }) =>
    inverse
      ? theme.colors.input_button_secondary_background
      : theme.colors.input_button_secondary_background};

  border-color: ${({ inverse, theme }) =>
    inverse ? theme.colors.border_inverse : theme.colors.border_outline};

  color: ${({ inverse, theme }) =>
    inverse
      ? theme.colors.input_button_secondary_inverse_text
      : theme.colors.input_button_secondary_text} !important;

  &:hover {
    background-color: ${({ inverse, theme }) =>
      inverse
        ? theme.colors.input_button_secondary_inverse_background_hover
        : theme.colors.input_button_secondary_background_hover};

    border-color: ${({ inverse, theme }) =>
      inverse ? theme.colors.border_inverse : theme.colors.border_outline};
  }

  &:active {
    background-color: ${({ inverse, theme }) =>
      inverse
        ? theme.colors.input_button_secondary_inverse_background_active
        : theme.colors.input_button_secondary_background_active};

    color: ${({ inverse, theme }) =>
      inverse
        ? theme.colors.input_button_secondary_text
        : theme.colors.input_button_secondary_inverse_text} !important;
  }
`;

export const ButtonPrimaryStyles = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.input_button_background};
  border-color: ${({ theme }) => theme.colors.input_button_background};
  color: ${({ theme }) => theme.colors.input_button_text};

  &:hover {
    background-color: ${({ theme }) => theme.colors.input_button_background_hover};
    border-color: ${({ theme }) => theme.colors.input_button_background_hover};
    color: ${({ theme }) => theme.colors.input_button_text};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.input_button_background_active};
    border-color: ${({ theme }) => theme.colors.input_button_background_active};
    color: ${({ theme }) => theme.colors.input_button_text};
  }
`;

export const ButtonContainer = styled.button<ButtonProps>`
  position: relative;
  min-width: ${({ small }) => (small ? '95px' : '180px')};
  max-width: ${({ mobileLimit }) => mobileLimit && '340px'};
  width: ${({ fullWidth }) => fullWidth && '100%'};
  padding: ${({ reducedPadding, theme }) =>
    reducedPadding
      ? `${theme.spacing.xs} ${theme.spacing.sm}`
      : `${theme.spacing.s} ${theme.spacing.m}`};
  border: solid 1px transparent;
  border-radius: 15rem;
  font-family: ${({ theme }) => theme.typography.primary};
  font-size: ${({ small }) => (small ? '14px' : '16px')};
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${props => {
    if (props.disabled) {
      return ButtonDisabledStyles;
    }
    if (props.buttonType === 'secondary') {
      return ButtonSecondaryStyles;
    }
    if (props.buttonType === 'basic') {
      return ButtonBasicStyles;
    } else {
      return ButtonPrimaryStyles;
    }
  }}
`;

export const ButtonContent = styled.span<ButtonContentProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  visibility: ${({ buttonLoading }) => (buttonLoading ? 'hidden' : 'visible')};
`;

export const ButtonText = styled.span``;

export const ButtonIcon = styled.div<{
  iconSide: 'left' | 'right';
  disabled?: boolean;
}>`
  position: absolute;
  top: 50%;
  ${props =>
    props.iconSide === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
  transform: translateY(-50%);
  margin: 0 20px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  display: flex;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
