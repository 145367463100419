import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Label } from '../../content/text';
import closeIcon from '../../assets/graphics/icons/close_white.svg';

interface LightBoxProps {
  className?: string;
  caption?: string;
  hideXMark?: boolean;
  clickClose?: () => void;
  children: React.ReactNode;
}

interface ModalHeaderProps {
  hideXMark?: boolean;
}

interface IconProps {
  onClick?: () => void;
}

export class LightBox extends Component<LightBoxProps> {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (event.target && this.props.clickClose) {
      this.props.clickClose();
    }
  };

  render() {
    return (
      <ModalBackground className={this.props.className}>
        <ModalContainer>
          <ModalHeader hideXMark={this.props.hideXMark}>
            <Icon src={closeIcon} onClick={this.props.clickClose} className="modal-close-icon" />
          </ModalHeader>
          <ModalContent>{this.props.children}</ModalContent>
          <ModalCaption>{this.props.caption}</ModalCaption>
        </ModalContainer>
      </ModalBackground>
    );
  }
}

const ModalBackground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background: white;
  height: 100%;
  width: 100%;
  padding: 15px;
  overflow-y: auto;
  max-height: 98vh;
  width: auto;
  height: auto;
  ${media.tablet`
    max-width: 750px;
  `};
`;

const ModalHeader = styled.div<ModalHeaderProps>`
  text-align: right;
  z-index: 2;
  position: relative;
  ${props => props.hideXMark && `display: none`};
`;

const ModalContent = styled.div`
  position: relative;
`;

const ModalCaption = styled(Label)`
  color: ${props => props.theme.colors.background_page} !important;
`;

const Icon = styled.img<IconProps>`
  cursor: pointer;
  margin-bottom: 20px;
`;
