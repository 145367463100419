import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { media } from '../../themes/media';

import { ReactTooltipProps } from './Tooltip.model';

export const TooltipDiv = styled.button`
  cursor: pointer;
  width: fit-content;
  background: none;
  border: none;
  align-self: center;
  display: inline-flex;
  padding: 0;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const CloseIcon = styled.img`
  height: 14px;
  margin-bottom: 5px;

  ${media.tablet`
    display: none;
  `};
`;

export const StyledReactTooltip = styled(ReactTooltip)<ReactTooltipProps>`
  border: 2px solid ${({ theme }) => theme.colors.border_outline} !important;
  max-width: 250px !important;
  padding: 10px 12px !important;
  border-radius: 0 !important;
  opacity: 0;
  box-shadow: ${({ theme }) => theme.shadows.shadow_default};
  background-color: ${({ inverse, theme }) =>
    inverse ? theme.colors.background_inverse : theme.colors.background_card} !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 20px !important;
  color: ${({ theme }) => theme.colors.text_paragraph} !important;
  &.place-top {
    &:after {
      bottom: -5px !important;
      border-left-color: ${({ theme }) => theme.colors.border_outline};
    }
  }
  &.place-right {
    &:after {
      left: -5px !important;
      border-left-color: ${({ inverse, theme }) =>
        inverse ? theme.colors.border_outline : theme.colors.background_card} !important;
    }
  }
  &.place-bottom {
    &:after {
      bottom: -18px !important;
    }
    &:before {
      border-bottom: 8px solid ${({ theme }) => theme.colors.background_inverse} !important;
    }
  }
  &.place-left {
    &:after {
      right: -5px !important;
      border-left-color: ${({ inverse, theme }) =>
        inverse ? theme.colors.border_outline : theme.colors.background_card} !important;
    }
  }
  &.show {
    opacity: 1 !important;
  }
  white-space: initial;
`;
