import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
import { NoteApiResponse, NoteApiRequest, TenderOfferConfigApiResponse } from './note.model';

const BASE_URL = '/a/api/note';

export const noteApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.NoteDocuments, Tags.NoteTenderOfferConfig],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getNoteDocuments = builder.query<NoteApiResponse, NoteApiRequest>({
        query: ({ urlHash }) => ({
          url: `${BASE_URL}/documents/${urlHash}`,
          method: HttpMethods.Get,
        }),
        transformResponse: ({ documents }) => ({ documents }),
        providesTags: [Tags.NoteDocuments],
      });

      const getTenderOfferConfig = builder.query<TenderOfferConfigApiResponse, NoteApiRequest>({
        query: ({ urlHash }) => ({
          url: `${BASE_URL}/${urlHash}/tender-offer-config`,
          method: HttpMethods.Get,
        }),
        transformResponse: res => res?.config,
        providesTags: [Tags.NoteTenderOfferConfig],
      });

      return {
        getNoteDocuments,
        getTenderOfferConfig,
      };
    },
  });
