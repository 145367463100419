export enum IraAccountType {
  TRAD = 'TRAD',
  ROTH = 'ROTH',
}

export enum ETCIraAccountType {
  INDIVIDUAL = 'individual',
  ROTH_IRA = 'roth-ira',
  SEP_IRA = 'sep-ira',
  SIMPLE_IRA = 'simple-ira',
  TRADITIONAL_IRA = 'traditional-ira',
}

export enum ETCIraAccountTypeName {
  INDIVIDUAL = 'Individual',
  ROTH_IRA = 'Roth IRA',
  SEP_IRA = 'SEP IRA',
  SIMPLE_IRA = 'Simple IRA',
  TRADITIONAL_IRA = 'Traditional IRA',
}

export enum MappedETCIraAccountType {
  'individual' = 'Individual',
  'roth-ira' = 'Roth IRA',
  'sep-ira' = 'SEP IRA',
  'simple-ira' = 'Simple IRA',
  'traditional-ira' = 'Traditional IRA',
}
