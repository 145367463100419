import React, { FunctionComponent } from 'react';
import { ExternalLink } from './ExternalLink';
import { LinkStyleProps } from './styles';

interface CallbackLinkProps extends LinkStyleProps {
  id?: string;
  callback?: () => void;
  className?: string;
  isBreadcrumb?: boolean;
}

export const CallbackLink: FunctionComponent<CallbackLinkProps> = ({
  id,
  children,
  inverse,
  isBreadcrumb,
  callback,
  className,
}) => {
  return (
    <ExternalLink
      id={id}
      data-cy={id || 'link'}
      inverse={inverse}
      isBreadcrumb={isBreadcrumb}
      className={className}
      onClick={e => {
        e.preventDefault();
        callback && callback();
      }}
    >
      {children}
    </ExternalLink>
  );
};
