export enum PortfolioStrategyLabels {
  SelfDirected = 'Direct',
  Managed = 'Managed',
}

export enum PortfolioStrategy {
  SelfDirected = 'self-directed',
  Managed = 'managed',
}

export enum PortfolioStrategyValues {
  Self = 'SELF',
  Discretionary = 'DISCRETIONARY',
}
