import { portfolioOverview } from '@yieldstreet/platform-kit';

import { AccountSummary } from '../useAccountSummary.model';

export const ACCOUNT_SUMMARY_INTEREST_RATE = 2.3;

export const ACCOUNT_SUMMARY_MOCK: AccountSummary = {
  ...portfolioOverview.accountSummary,
  hasWallets: true,
  interestRate: ACCOUNT_SUMMARY_INTEREST_RATE,
  balanceByPerformanceStatus: 999,
};
