import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { media } from '../../themes/media';
import { Modal } from '../modal';
import PlayButtonImage from '../../assets/graphics/case-specific/play_button.svg';

enum Providers {
  Vimeo = 'vimeo',
  Youtube = 'youtube',
}

interface VideoProps {
  id: string;
  provider?: string;
  thumbnail?: string;
  inverse?: boolean;
  className?: string;
  hasOverlayBackground?: boolean;
}

export const Video: FC<VideoProps> = ({
  id,
  provider = 'vimeo',
  thumbnail,
  inverse,
  className,
  hasOverlayBackground = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const [providerThumbnail, setProviderThumbnail] = useState('');

  const getVimeoThumbnail = useCallback(async () => {
    const response = await axios.get(`//vimeo.com/api/v2/video/${id}.json`);
    const { data = [] } = response;
    setProviderThumbnail(data[0]?.thumbnail_large);
  }, [id]);

  const handleVimeoVideo = useCallback(() => {
    setIframeSrc(
      `https://player.vimeo.com/video/${id}?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1`
    );

    if (!thumbnail) {
      getVimeoThumbnail();
    }
  }, [getVimeoThumbnail, id, thumbnail]);

  const handleYoutubeVideo = useCallback(() => {
    setIframeSrc(`https://www.youtube.com/embed/${id}?autoplay=1&rel=0`);

    if (!thumbnail) {
      setProviderThumbnail(`//img.youtube.com/vi/${id}/hqdefault.jpg`);
    }
  }, [id, thumbnail]);

  useEffect(() => {
    if (id) {
      switch (provider) {
        case Providers.Vimeo:
          handleVimeoVideo();
          break;

        case Providers.Youtube:
          handleYoutubeVideo();
          break;

        default:
          break;
      }
    }
  }, [handleVimeoVideo, handleYoutubeVideo, id, provider]);

  return (
    <>
      <VideoWrapper className={className}>
        <VideoOverlayTrigger
          inverse={inverse}
          onClick={() => setShowModal(true)}
          hasOverlayBackground={hasOverlayBackground}
        >
          <PlayButton src={PlayButtonImage} />
        </VideoOverlayTrigger>

        <VideoThumbnail src={thumbnail || providerThumbnail} />
      </VideoWrapper>

      {showModal && (
        <StyledModal clickClose={() => setShowModal(false)} inverseXMark>
          <ModalVideoIframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            src={iframeSrc}
            title={id}
          />
        </StyledModal>
      )}
    </>
  );
};

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  object-fit: cover;
`;

const VideoOverlayTrigger = styled.div<Partial<VideoProps>>`
  cursor: pointer;
  ${props =>
    props.inverse
      ? css`
          -webkit-filter: invert(1);
          filter: invert(1);
        `
      : ''}
  background: ${props =>
    props.hasOverlayBackground
      ? 'linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%)'
      : 'none'};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PlayButton = styled.img`
  width: 45px;

  ${media.desktop`
    width: 57px;
  `}
`;

const VideoThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ModalVideoIframe = styled.iframe`
  border: 0;
  position: relative;
  width: 334px;
  height: 213px;

  ${media.tablet`
    width: 660px;
    height: 372px;
  `};
`;

const StyledModal = styled(Modal)`
  > div {
    background: transparent;
  }
`;
