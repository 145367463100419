import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

interface ScrollRevealProps {
  delay?: number;
  forceRender?: boolean;
}

export const ScrollReveal: React.FunctionComponent<ScrollRevealProps> = props => {
  const { children, delay = 100, forceRender = false } = props;

  const [childrenVisible, setChildrenVisible] = useState(forceRender);
  const detectorVisible = useRef(false);
  const delayPassed = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      delayPassed.current = true;
      setChildrenVisible(detectorVisible.current);
    }, delay);
  }, [delay]);

  return (
    <VisibilitySensor
      partialVisibility
      delayedCall
      onChange={isVisible => {
        if (!childrenVisible) {
          detectorVisible.current = isVisible;
          if (delayPassed.current) {
            setChildrenVisible(isVisible);
          }
        }
      }}
    >
      <RevealDetector visible={childrenVisible}>
        <RevealContainer visible={childrenVisible}>{childrenVisible && children}</RevealContainer>
      </RevealDetector>
    </VisibilitySensor>
  );
};

const RevealDetector = styled.div<{ visible: boolean }>`
  padding-bottom: ${props => (props.visible ? `0` : `10px`)};
`;

const RevealContainer = styled.div<{ visible: boolean }>`
  overflow: hidden;
  transition: transform 1s ease-in-out;
  transition: opacity 1s ease-out;
  height: auto;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top;
  ${props =>
    props.visible
      ? `
    transform: scaleY(1);
    opacity: 1;
  `
      : ``}
`;

export default ScrollReveal;
