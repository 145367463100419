import { Store } from '@reduxjs/toolkit';

import { OfferingV5, FundType, FeeStructureEnum, OfferingDocuments } from '../../types';
import {
  AdviceRiskTolerance,
  AdviceInvestmentStyle,
  AccreditationEligibility,
  LegalEntityType,
  OfferingStatus,
} from '../../enums';
import { wordpressArticle } from '../../mocks';
import { getMockEndpoint, configureRTKMockStore, MockRtkQuery } from '../../testing';
import { restApi } from '../../apis/common';
import { wordpressApi } from '../../apis';

import { offeringV5Containers } from '../offering-v5-containers';
import { offeringV5Tags } from '../offering-v5-tags';

const offeringV5DefaultEndpoints = [
  getMockEndpoint(wordpressApi, 'wpOfferingArticles', { wpArticle: wordpressArticle }),
];

export const offeringV5EmptyEndpoints = [
  getMockEndpoint(wordpressApi, 'wpOfferingArticles', { wpArticle: null }),
];

export const offeringV5NoImageEndpoints = [
  getMockEndpoint(wordpressApi, 'wpOfferingArticles', {
    wpArticle: {
      ...wordpressArticle,
      _embedded: {
        ...wordpressArticle._embedded,
        'wp:featuredmedia': [
          {
            media_details: { sizes: {} },
          },
        ],
      },
    },
  }),
];

export const offeringV5getMockStore = (
  mockEndpoints: MockRtkQuery[] = offeringV5DefaultEndpoints
) => {
  const setup = configureRTKMockStore(
    mockEndpoints,
    {
      configApi: () => ({
        httpsHost: '',
        wssHost: 'wss://127.0.0.1',
      }),
    },
    restApi.middleware
  );

  return setup.store as Store;
};

export const offeringV5Mock: OfferingV5 = {
  version: 5,
  originator: {
    id: '205061',
    title: 'Yieldstreet',
    description: '',
    slug: '',
    profileImageUrl: '',
  },
  messages: {
    isActive: false,
    comingSoon: '',
    open: '',
    closed: '',
    matured: '',
  },
  offeringHero: {
    customTermLabel: '',
    customTerm: '',
    customTermSuffix: '',
    customRateLabel: '',
    customRateString: '',
    showSimulator: false,
  },
  offeringCardOverrides: {
    minInvestmentLabel: '',
    minInvestmentValue: '',
    assetClassLabel: '',
    assetClassValue: '',
    originatorLabel: '',
    originatorValue: '',
  },
  tieredRateTree: {
    label1: '[label1]',
    value1: '[value1]',
    label2: '[label2]',
    value2: '[value2]',
    tooltipTitle: '[tooltipTitle]',
    tooltipSubtitle: '[tooltipSubtitle]',
    tooltipImage: '',
    tooltipDescription: '[tooltipDescription]',
    description: '[description]',
  },
  tieredTermTree: {
    label1: '',
    value1: '',
    label2: '',
    value2: '',
    tooltipTitle: '',
    tooltipSubtitle: '',
    tooltipImage: '',
    tooltipDescription: '',
    description: '',
  },
  liquidityDescription: {
    tooltipTitle: '[LD tooltipTitle]',
    tooltipSubtitle: '[LD tooltipSubtitle]',
    tooltipImage: '',
    tooltipDescription: '[LD tooltipDescription]',
    description: '[LD description]',
  },
  updatedDate: {
    date: '',
    dateEpoch: '',
  },
  markdown: {
    isMarkdown: true,
  },
  urlHash: 'testUrlHash',
  title: 'Theorem Internal Fund 2',
  summary: 'aaaaaaaa',
  bannerText: null,
  bannerDetailText: null,
  underlyingSecurity: '',
  status: OfferingStatus.open,
  legalEntityType: LegalEntityType.FUND,
  assetClass: 'LEGL',
  assetSubClass: 'LAWF',
  paymentFrequency: 'BIWK',
  regulatoryType: 'PBLC',
  interestRate: 10,
  distributionRate: 10,
  noteAmount: 1000000,
  minimumPurchase: 1,
  maximumPurchase: 1000000,
  purchaseIncrement: 1,
  autoInvestMinimumPurchase: null,
  autoInvestMaximumPurchase: null,
  hasAutoInvestAllocation: false,
  computedTerm: 11,
  term: 10,
  actualTerm: null,
  termsRemaining: '10 Mo.',
  progressMessage: 'aaaaaa',
  originatorId: 0,
  originatorName: 'Originator',
  originatorNickname: 'originator',
  prefunded: false,
  newInvestorsOnly: false,
  segmented: false,
  segment: null,
  partnerName: null,
  launchedDateTime: 1686700800000,
  fundingProgress: 1,
  remainingInvestment: 988199,
  heroImageUrl: null,
  summaryTitle: null,
  description: null,
  descriptionFields: [],
  spvName: 'theorem liq test',
  spvBankAccountName: 'theorem liq test',
  spvBankAccountNumber: '123456789',
  counterParty: null,
  originatorTypeDescription: null,
  borrowerName: null,
  borrowerTypeDescription: null,
  accreditationEligibility: AccreditationEligibility.ACCR,
  note506RuleType: null,
  facebookShare: null,
  linkedinShare: null,
  twitterShare: null,
  emailShare: null,
  documents: [
    {
      type: 'other',
      docType: OfferingDocuments.SUBS,
      description:
        'For more information on this opportunity, please review the information provided in the Subscription Agreement below.',
      name: 'Subscription Agreement',
      url: 'https://qa.yieldstreet.net/a/api/note/documents/IC8-WQ/891148',
    },
    {
      type: 'other',
      docType: OfferingDocuments.OFTP,
      description:
        'For more information on this opportunity, please review the information provided in the offer to purchase below.',
      name: 'offer to purchase',
      url: 'https://qa.yieldstreet.net/a/api/note/documents/IC8-WQ/891155',
    },
    {
      type: 'other',
      docType: OfferingDocuments.TFAQ,
      description:
        'For more information on this opportunity, please review the information provided in the tender offer faq below.',
      name: 'tender offer faq',
      url: 'https://qa.yieldstreet.net/a/api/note/documents/IC8-WQ/891156',
    },
  ],
  acceptRollover: false,
  capitalCallEnabled: false,
  capitalDrawnPercentage: 0,
  capitalDrawnStrategy: null,
  risk: AdviceRiskTolerance.Conservative,
  investmentStyle: AdviceInvestmentStyle.Income,
  preferredAssetClass: false,
  legalAcknowledgementConfigContent: '',
  fundType: FundType.InternalFund,
  distributionReinvestment: false,
  taxDocument: 'TEN_99_DIV',
  feeStructure: FeeStructureEnum.DEFAULT,
  investable: true,
  assetClassDisplayName: 'Legal',
  statusDisplayName: 'Open',
  launchedDateTimeString: '2023-06-13 20:00:00',
  launchedDateTimeFormatted: 'Jun 13 2023, 08:00 PM EDT',
  paymentFrequencyDisplayName: 'Bi-Weekly',
  SEOTitle: 'theorem-internal-fund-2',
  reserved: false,
  v5Data: {
    containers: offeringV5Containers,
    offeringTags: offeringV5Tags,
  },
};
