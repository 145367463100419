import map from 'lodash/map';

import { HttpMethods } from '../../enums';
import {
  OfferingV5,
  OfferingProgress,
  ResolveAudiences,
  AudiencePreset,
  SaveRecommendationOffering,
  SaveRecommendationsAPIResponse,
} from '../../types';
import { SocketManagerReconnectionStrategy, websocketManager } from '../../websocket';
import { restApi, Tags } from '../common';
import { CustomSerializeQueryExtraOptions } from '../common/custom-serialize-query';
import { selectConfig } from '../config';
import {
  GetOfferingsApiRequest,
  GetProgressApiRequest,
  GetRecommendededOffersRequest,
  GetRecommendededOffersSourceType,
  GetRecommendedOffersApiResponse,
  GetPaginatedOfferingsApiRequest,
  GetPaginatedOfferingsApiResponse,
} from './marketplace.model';
import { getAudiencePresetRequest } from './helper';

export const marketplaceApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.Marketplace,
      Tags.MarketplacePaginatedOfferings,
      Tags.MarketplaceRecommendations,
      Tags.MarketplaceProgress,
      Tags.MarketplaceAudiences,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getOfferings = builder.query<GetPaginatedOfferingsApiResponse, GetOfferingsApiRequest>({
        query: ({ accreditation, offeringType, noteUrlHash }) => ({
          url: `/a/api/marketplace/${offeringType}`,
          method: HttpMethods.Get,
          params: {
            accreditation,
            noteUrlHash,
          },
        }),
        providesTags: [Tags.Marketplace],
      });

      const getPaginatedOfferings = builder.query<
        GetPaginatedOfferingsApiResponse,
        GetPaginatedOfferingsApiRequest
      >({
        query: data => ({
          url: `/a/api/marketplace/offerings`,
          method: HttpMethods.Post,
          data,
        }),
        providesTags: [Tags.MarketplacePaginatedOfferings],
      });

      const getRecommendations = builder.query<OfferingV5[], GetRecommendededOffersRequest>({
        query: ({ userId, source = GetRecommendededOffersSourceType.Goals, limit }) => ({
          url: `/a/api/marketplace/recommendations/${userId}`,
          method: HttpMethods.Get,
          params: { source, limit },
        }),
        providesTags: [Tags.MarketplaceRecommendations],
        transformResponse: (response: GetRecommendedOffersApiResponse) => response.offerings,
      });

      const getProgress = builder.query<Record<string, OfferingProgress>, GetProgressApiRequest>({
        queryFn: () => ({ data: {} }),
        extraOptions: (props: GetProgressApiRequest) => {
          const options: CustomSerializeQueryExtraOptions<typeof props> = {
            cacheKey: props.key,
          };
          return options;
        },
        providesTags: [Tags.MarketplaceProgress],
        async onCacheEntryAdded(
          request,
          { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }
        ) {
          const { wssHost } = selectConfig(getState() as any);

          const onMessage = (_: WebSocket, ev: MessageEvent) => {
            const data = JSON.parse(ev.data);
            updateCachedData(draft => Object.assign(draft, { [data.noteUrlHash]: data.progress }));
          };

          const key = request.key;
          try {
            await cacheDataLoaded;
            const params = map(request.noteUrlHashes, urlHash => ({ urlHash }));

            websocketManager.remove(key);

            websocketManager.add(
              key,
              {
                url: `${wssHost}/a/api/ws/marketplace/progress/:urlHash`,
                params,
                reconnectionStrategy: SocketManagerReconnectionStrategy.retry,
              },
              { onMessage }
            );

            await cacheEntryRemoved;
          } finally {
            websocketManager.unsubscribe(key);
          }
        },
      });

      /**
       * This needs to be a query as it's get functionality wrapped around post due to data property.
       */
      const getResolveAudiences = builder.query<ResolveAudiences, AudiencePreset>({
        query: preset => {
          const payload = getAudiencePresetRequest(preset);

          return {
            url: '/a/api/marketplace/audiences/resolve',
            method: HttpMethods.Post,
            data: payload,
          };
        },
      });

      const saveRecommendedOfferings = builder.mutation<
        SaveRecommendationsAPIResponse,
        SaveRecommendationOffering
      >({
        query: ({ ...data }) => {
          return {
            url: `/a/api/marketplace/recommendations`,
            method: HttpMethods.Put,
            data,
          };
        },
        invalidatesTags: () => [{ type: Tags.MarketplaceRecommendations }],
      });

      return {
        getOfferings,
        getRecommendations,
        getProgress,
        getResolveAudiences,
        getPaginatedOfferings,
        saveRecommendedOfferings,
      };
    },
  });
