import styled from 'styled-components';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { LinkStyles } from '../../content/link';

export const StyledReactMarkdown = styled(MarkdownPreview)`
  font-family: ${props => props.theme.typography.primary} !important;
  color: inherit !important;
  background-color: inherit !important;

  &.wmde-markdown {
    font-size: inherit !important;
  }

  * {
    color: inherit !important;
    background-color: inherit !important;
  }

  a {
    ${LinkStyles}
    color: ${props => props.theme.colors.text_link} !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  ul {
    li {
      list-style: initial;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  hr {
    border-top: 2px dotted #eee !important;
    border-bottom: 0 !important;
  }
`;
