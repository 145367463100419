import React, { FunctionComponent } from 'react';

import { TitleTag, Heading, Label } from '../../content/text';

import { TextBlockProps } from './TextBlock.model';
import {
  TextContainer,
  HeadingContainer,
  ParagraphContainer,
  StatsContainer,
  ButtonContainer,
  StatItem,
} from './TextBlock.styles';

export const TextBlock: FunctionComponent<TextBlockProps> = ({
  id,
  label,
  heading,
  content,
  compact,
  align = 'start',
  mobileAlign,
  inverse,
  button,
  children,
  headingTagType,
  headingType = compact ? 3 : 2,
  stats,
}) => {
  return (
    <TextContainer mobileAlign={mobileAlign} align={align}>
      {label ? (
        <TitleTag inverse={inverse} data-cy={`text-block-label-${id}`}>
          {label}
        </TitleTag>
      ) : null}

      {heading ? (
        <HeadingContainer compact={compact}>
          <Heading
            data-cy={`text-block-heading-${id}`}
            type={headingType}
            tagType={headingTagType}
            inverse={inverse}
          >
            {heading}
          </Heading>
        </HeadingContainer>
      ) : null}

      {content ? <ParagraphContainer inverse={inverse}>{content}</ParagraphContainer> : null}

      {stats && (
        <StatsContainer>
          {stats.map(stat => (
            <StatItem key={stat.label}>
              <Label inverse={inverse}>{stat.label}</Label>
              <Heading type={3} tagType={3} inverse={inverse}>
                {stat.value}
              </Heading>
            </StatItem>
          ))}
        </StatsContainer>
      )}

      {button ? <ButtonContainer compact={compact}>{button}</ButtonContainer> : null}

      {children}
    </TextContainer>
  );
};
