import tedPhoto from 'images/home/ted-yarbrough.jpg';
import sirishaPhoto from 'images/home/sirisha-prasad.jpg';

interface FundLeadershipProps {
  name: string;
  subtitle: string;
  description: string;
  imgUrl: string;
  modalId: string;
}

export const leadershipData: FundLeadershipProps[] = [
  {
    name: 'Ted Yarbrough',
    subtitle: `Chief Investment Officer & ${'\n'}Co-Portfolio Manager`,
    description: `Ted Yarbrough leads investment strategy across Yieldstreet’s wide range of asset classes as Chief Investment Officer. Under his leadership, the investment team is tasked with driving ambitious asset growth, expanding Yieldstreet’s origination network, and developing innovative private market products of the future.${'\n'}
    Ted joined Yieldstreet following more than 28 years at Citigroup and its predecessor companies, where he held various senior roles across the firm’s banking, markets, and lending platforms. Most notably, Ted served as Chief Investment Officer for Citi’s Spread Products business as well as Global Head of Structured Finance, and he has extensive experience across multiple asset classes.`,
    imgUrl: tedPhoto,
    modalId: 'ted-modal',
  },
  {
    name: 'Sirisha Prasad',
    subtitle: 'Co-Portfolio Manager',
    description:
      'Sirisha Prasad joined Yieldstreet in 2022, where she has been managing the day-to-day management of the registered funds at the firm. Most recently she worked at ICONIQ Capital, in their Investment Management group in San Francisco, CA. Prior to joining ICONIQ, Sirisha worked at Morgan Stanley since 2014, where she spent most of her career working in the Outsourced Chief Investment Office managing assets for Endowments and Foundations.',
    imgUrl: sirishaPhoto,
    modalId: 'sirisha-modal',
  },
];
