import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes';
import { Heading, Paragraph } from '../../../content/text';

interface ItemWithContentProps {
  title?: string;
  image?: string;
  imageAlt?: string;
  inverse?: boolean;
  alignment?: 'left' | 'center' | 'right';
  className?: string;
  imagePosition?: 'top' | 'left';
  hideImageMobile?: boolean;
  imageComponent?: React.ReactNode | Element;
}

export const ImageWithContent: FunctionComponent<ItemWithContentProps> = ({
  title,
  image,
  imageAlt,
  alignment = 'left',
  className,
  children,
  inverse,
  imagePosition = 'top',
  hideImageMobile,
  imageComponent,
}) => {
  return (
    <OuterContainer
      className={className}
      alignment={alignment}
      image={image}
      title={title}
      imagePosition={imagePosition}
    >
      {imageComponent && <div>{imageComponent}</div>}
      {image && (
        <ImageHolder hideImageMobile={hideImageMobile} className="image-with-content-image-holder">
          <ImageWrapper>
            <Image alt={imageAlt} src={image} />
          </ImageWrapper>
        </ImageHolder>
      )}
      <div>
        <Headline type={4} inverse={inverse} className="image-with-content-title">
          {title}
        </Headline>
        <Content size="small" inverse={inverse} className="image-with-content-children">
          {children}
        </Content>
      </div>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<ItemWithContentProps>`
  text-align: ${props => props.alignment};
  ${media.desktop`
    padding: 0 20px;
  `}
  position: relative;
  display: flex;
  flex-direction: ${props => (props.imagePosition === 'top' ? 'column' : 'row')};
  flex-grow: 1;
  flex-basis: 0;
`;

const Headline = styled(Heading)`
  padding: 0;
  margin: 10px 0;
`;

const Content = styled(Paragraph)``;

const ImageHolder = styled.div<ItemWithContentProps>`
  ${props => props.hideImageMobile && 'display: none;'}
  position: relative;
  height: 120px;
  margin: 30px 0;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const Image = styled.img`
  max-height: 120px;
`;
