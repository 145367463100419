import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../elements/modal';
import { media } from '../../themes/media';

export interface VideoModalProps {
  url: string;
  isVisible?: boolean;
  onClickClose: () => void;
}

export const VideoModal = ({ url, isVisible, onClickClose }: VideoModalProps) =>
  isVisible ? (
    <Wrapper>
      <SModal clickClose={onClickClose} inverseXMark>
        <Iframe src={`${url}?autoplay=1`} allow="autoplay" frameBorder="0" />
      </SModal>
    </Wrapper>
  ) : null;

const Wrapper = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;

  ${media.desktop`
    max-width: 668px;
  `};
`;

const SModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.3);
  > div {
    background: transparent;
    padding: 0;
  }

  ${media.desktop`
    > div {
      height: auto;
    }
  `};
`;

const Iframe = styled.iframe`
  width: 335px;
  height: 193px;
  border: 0;
  position: relative;
  ${media.tablet`
    width: 660px;
    height: 372px;
  `};
`;
