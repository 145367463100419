import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Title } from '../../layout/title/Title';
import { Paragraph } from '../../content/text/Paragraph';
import { Label } from '../../content/text/Label';

import CheckIcon from '../../assets/graphics/icons/blue-circle-blue-check.png';
import DashedIcon from '../../assets/graphics/icons/checkmark_dashed.svg';

interface PageCompleteSetupProps {
  title: string | React.ReactNode;
  subtitle?: string;
  description?: string;
  cta?: React.ReactNode;
  showIcon?: boolean;
  fullSizeChildren?: boolean;
  inverse: boolean;
  altIcon: boolean;
  textCenter: boolean;
}

export const PageCompleteSetup: FunctionComponent<PageCompleteSetupProps> = ({
  title,
  subtitle,
  description,
  cta,
  children,
  fullSizeChildren = false,
  showIcon = true,
  inverse = true,
  altIcon,
  textCenter,
}) => (
  <Container>
    <Background inverse={inverse}>
      <ContentWrapper textCenter={textCenter}>
        <TitleWrapper textCenter={textCenter}>
          {showIcon && altIcon ? (
            <AltIcon src={DashedIcon} alt="check" data-cy="page-complete-setup-alt-icon" />
          ) : (
            <Icon src={CheckIcon} alt="check" data-cy="page-complete-setup-icon" />
          )}
          <Title tagType={3} inverse={inverse}>
            {title}
          </Title>
        </TitleWrapper>
        {subtitle && (
          <SubtitleWrapper>
            <Paragraph inverse={inverse}>{subtitle}</Paragraph>
          </SubtitleWrapper>
        )}
        {description && <Label inverse={inverse}>{description}</Label>}
        {cta}
        {!fullSizeChildren && children}
      </ContentWrapper>
      {fullSizeChildren && children}
    </Background>
  </Container>
);

const Icon = styled.img`
  margin-bottom: 20px;

  ${media.desktop`
    position: absolute;
    margin-left: -80px;
    margin-top: 7px;
    margin-bottom: 0;
  `}
`;

const AltIcon = styled.img`
  margin-bottom: 40px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  max-width: 490px;
  margin: 60px auto auto;
  padding: 0 20px;

  ${media.tablet<Partial<PageCompleteSetupProps>>`
    margin: 100px auto auto;
    ${props => props.textCenter && 'text-align: center; max-width: 650px;'}
  `}
`;

const TitleWrapper = styled.div<Partial<PageCompleteSetupProps>>`
  ${props => props.textCenter && 'text-align: center;'}
`;

const SubtitleWrapper = styled.div`
  margin-bottom: 10px;
`;

const Background = styled.div<Partial<PageCompleteSetupProps>>`
  background: ${props =>
    props.theme.colors[props.inverse ? 'background_inverse' : 'background_page']};
  width: 100%;
`;
