import { BaseEventProps } from './events';
import { BaseEventCall, EventCall, EventFailureCall } from './eventManager.model';

const logEvent = '☰ TRACK EVENT';
const logEventFailure = `${logEvent} FAILURE`;

export type GlobalEventProps = {
  app: 'web' | 'native';
  mobile: boolean;
} & BaseEventProps;
class EventManager {
  private debugMode = false;
  private globalProps?: GlobalEventProps;
  private onTrack?: BaseEventCall;

  init = (onTrack: BaseEventCall, globalProps: GlobalEventProps, debugMode?: boolean) => {
    this.onTrack = onTrack;
    this.debugMode = debugMode || false;
    this.globalProps = globalProps;
  };

  log = (event: string, props: BaseEventProps, failure?: boolean) => {
    const log = failure ? logEventFailure : logEvent;
    // eslint-disable-next-line no-console
    this.debugMode && console.info(log, event, props);
  };

  track: EventCall = (event, props) => {
    const eventProps = { ...(this.globalProps || {}), ...props };
    this.log(event, eventProps);
    this.onTrack && this.onTrack(event, eventProps);
  };

  trackFailure: EventFailureCall = (event, props) => {
    const eventName = `${event}.failed`;
    const eventProps = { ...(this.globalProps || {}), ...props };
    this.log(eventName, eventProps, true);
    this.onTrack && this.onTrack(eventName, eventProps);
  };
}

export const eventManager = new EventManager();
