import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { theme2, media } from '../../../themes';
import { Heading, ParagraphSmall, Paragraph } from '../../../content/text';
import { Currency, CurrencyProps } from './common/currency';

interface AssetClassTotalCompactProps {
  title?: string;
  image?: string;
  imageAlt?: string;
  amount: CurrencyProps;
  label?: string;
  inverse?: boolean;
  tag?: React.ReactNode;
}

export const AssetClassTotalCompact: FunctionComponent<AssetClassTotalCompactProps> = ({
  title,
  image,
  imageAlt,
  amount,
  label = 'Funded to date:',
  inverse,
  tag,
}) => {
  return (
    <OuterContainer>
      <HeadingHolder>
        <ImageHolder>{image && <Image alt={imageAlt} src={image}></Image>}</ImageHolder>
        <Headline type={5} inverse={inverse}>
          {title}
        </Headline>
      </HeadingHolder>
      <Title small inverse={inverse}>
        {label}
      </Title>
      <Number inverse={inverse}>
        <Currency {...amount}></Currency>
      </Number>
      {tag && <Tag>{tag}</Tag>}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background: ${props => props.theme.colors.background_default};
  border-top: 2px solid ${theme2.colors.accent_strong};
  position: relative;
  text-align: left;
  margin: 20px 0 0 0;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  flex-basis: 100%;

  ${media.desktop`
    flex-basis: 30%;
    margin: 0 10px 20px 10px;
  `}
`;

const HeadingHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Headline = styled(Heading)`
  padding: 5px 10px;
  box-sizing: content-box;
`;

const Title = styled(Paragraph)`
  color: ${theme2.colors.text_label};
`;

const Number = styled(Paragraph)``;

const ImageHolder = styled.div`
  width: 34px;
`;

const Image = styled.img`
  width: 34px;
`;

const Tag = styled.span`
  ${ParagraphSmall}
  background: ${props => props.theme.colors.accent_strong_background};
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px 10px;
  font-size: 12px;
`;
