import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds } = genTestIds(['container', 'action', 'window']);

export const mapToTestId = (testId = 'context-menu') => getTestIds(`${testId}`);

export enum ContextMenuAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export type ContextMenuType = `${ContextMenuAlignment}`;

export interface ContextMenuProps {
  open: boolean;
  renderTrigger: JSX.Element;
  onChange: Function;
  onClose?: Function;
  onOpen?: Function;
  align: `${ContextMenuAlignment}`;
  testId?: string;
  maxHeight?: number;
}
