import styled, { css } from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import { LabelStyles } from '../common';

export const InputLabelStyles = css`
  ${LabelStyles}
  &.MuiInputLabel-filled {
    color: ${props => props.theme.colors.text_label};
  }
`;

export const SInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    ${InputLabelStyles}
  }
`;

export const FilledInputStyles = css`
  font-family: ${props => props.theme.typography.secondary};
  border: 1px solid ${props => props.theme.colors.background_page};
  border-bottom: 0;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.input_background};
  &:hover {
    background-color: ${props => props.theme.colors.input_background};
  }
  &.Mui-focused {
    background-color: ${props => props.theme.colors.input_background};
  }
  &.Mui-disabled {
    background-color: ${props => props.theme.colors.input_disabled_background};
    &:before {
      border-bottom-style: solid;
    }
    & .MuiFilledInput-input {
      color: ${props => props.theme.colors.input_disabled_text};
      -webkit-text-fill-color: ${props => props.theme.colors.input_disabled_text};
    }
  }

  &.MuiFilledInput-root {
    overflow: hidden;
  }

  &.MuiInputBase-multiline {
    padding: 28px 12px 10px;
  }

  & .MuiInputBase-input {
    color: ${props => props.theme.colors.text_paragraph};
    &:not(.MuiInputBase-inputMultiline) {
      padding: 25px 12px 10px;
    }
    &.MuiInputBase-inputHiddenLabel {
      padding: 19px 12px 19px;
      border: 0;
    }
    &.MuiInputBase-inputAdornedStart {
      padding-left: 6px;
    }
    &.MuiInputBase-inputAdornedEnd {
      padding-right: 6px;
    }
  }

  &.MuiFilledInput-underline {
    min-height: 60px;
    &.Mui-disabled {
      &:hover:before,
      &:before {
        border-bottom: 1px solid ${props => props.theme.colors.border_default};
      }
    }
    &:before {
      border-bottom: 1px solid ${props => props.theme.colors.border_default};
    }
    &:after {
      border-bottom: 2px solid ${props => props.theme.colors.border_outline};
    }
    &.Mui-error {
      &:hover:after,
      &:after {
        border-bottom: 2px solid ${props => props.theme.colors.notice_error};
      }
    }
  }

  & .MuiInputAdornment-filled {
    &.MuiInputAdornment-positionStart {
      margin-right: 0;
    }
    &.MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
  }
  &.MuiFilledInput-adornedStart {
    padding-left: 10px;
  }
  &.MuiFilledInput-adornedEnd {
    padding-right: 10px;
  }
`;

export const SFilledInput = styled(FilledInput)`
  &.MuiInputBase-root {
    ${FilledInputStyles}
  }
`;
