import type { AnonymizerMap } from './types';
import type { TaxDocument, UserProfile } from '../apis';
import type {
  Address,
  BankAccount,
  InvestorAccount,
  InvestorAccountAiq,
  InvestorAccountAiqDocument,
  User2FAStatus,
  UserContact,
  UserState,
  RecurringPayment,
  BeneficialOwner,
  BeneficialOwnerSummary,
  Payment,
  Investor,
} from '../types';
import { anonymizeAddress } from './common/address';
import {
  anonymizeUserState,
  anonymizeUserProfile,
  anonymizeUserContact,
  anonymizeUser2FAStatus,
} from './user';
import {
  anonymizeInvestorAccount,
  anonymizeInvestorAccountAiq,
  anonymizeInvestorAccountAiqDocument,
} from './investor-account';
import { anonymizeBankAccount } from './bank-account';
import { anonymizeRecurringPayment, anonymizePayment } from './payment';
import { anonymizeBeneficialOwner } from './beneficial-owner';
import { anonymizeBeneficialOwnerSummary } from './beneficial-owner/beneficial-owner-summary';
import { anonymizeTaxDocument } from './tax-document';
import { anonymizeInvestor } from './investor';

export type ObjectAnonymizeRegister = {
  address: Address;
  userState: UserState;
  userProfile: UserProfile;
  userContact: UserContact;
  user2FAStatus: User2FAStatus;
  bankAccount: BankAccount;
  investorAccount: InvestorAccount;
  investorAccountAiq: InvestorAccountAiq;
  investorAccountAiqDocument: InvestorAccountAiqDocument;
  recurringPayment: RecurringPayment;
  beneficialOwner: BeneficialOwner;
  beneficialOwnerSummary: BeneficialOwnerSummary;
  taxDocument: TaxDocument;
  payment: Payment;
  investor: Investor;
};

export const anonymizerRegister: AnonymizerMap<ObjectAnonymizeRegister> = {
  address: anonymizeAddress,
  userState: anonymizeUserState,
  userProfile: anonymizeUserProfile,
  userContact: anonymizeUserContact,
  user2FAStatus: anonymizeUser2FAStatus,
  bankAccount: anonymizeBankAccount,
  investorAccount: anonymizeInvestorAccount,
  investorAccountAiq: anonymizeInvestorAccountAiq,
  investorAccountAiqDocument: anonymizeInvestorAccountAiqDocument,
  recurringPayment: anonymizeRecurringPayment,
  beneficialOwner: anonymizeBeneficialOwner,
  beneficialOwnerSummary: anonymizeBeneficialOwnerSummary,
  taxDocument: anonymizeTaxDocument,
  payment: anonymizePayment,
  investor: anonymizeInvestor,
};
