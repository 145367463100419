import { DropDownType } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds } = genTestIds(['value', 'label', 'icon']);

export const mapToTestId = (testId = 'dropdown') => getTestIds(`${testId}`);

export interface DropDownProps {
  value: string;
  label: string;
  active: boolean;
  withBorder: boolean;
  inline: boolean;
  fullWidth: boolean;
  maxWidth?: number;
  testId?: string;
  responsive: boolean;
  type?: DropDownType;
}
