import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import {
  TextBaseStyle,
  TextBaseStyleProps,
  TextBaseProps,
  TextAlign,
  TextAlignEnum,
} from './TextBase';

type LegalTextProps = TextBaseStyleProps &
  TextBaseProps & {
    nowrap?: boolean;
    textAlign?: Pick<TextAlign, 'left' & 'center'>;
    className?: string;
  };

export const LegalText: FunctionComponent<LegalTextProps> = ({
  children,
  className,
  textAlign = TextAlignEnum.Center,
  as = 'p',
  ...props
}) => (
  <SmallLegalElement as={as} className={className} textAlign={textAlign} {...props}>
    {children}
  </SmallLegalElement>
);

const SmallLegalElement = styled.p<LegalTextProps>`
  ${TextBaseStyle};
  font-family: ${props => props.theme.typography.primary};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: ${props => props.textAlign};
  color: #9098a2; //TODO: Change to var talk to design
  ${props => props.nowrap && `white-space: nowrap;`}
`;
