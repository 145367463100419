import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
import { Heading, Paragraph } from '../../content/text';
import { Button, ButtonContainer } from '../../content/button';
import { PageSection } from '../../layout';

import CommercialImage from '../../assets/graphics/asset-classes/commerical_inverse.svg';
import RealEstateImage from '../../assets/graphics/asset-classes/real_estate_inverse.svg';
import DataScienceImage from '../../assets/graphics/case-specific/data_science_inverse.svg';
import IRAImage from '../../assets/graphics/case-specific/ira_inverse.svg';
import YSLogoImage from './assets/ys_logo_white.svg';

interface ListItemProps {
  active?: boolean;
}

interface AssetImagesProps {
  active?: boolean;
}

interface SubContentHolderProps {
  active?: boolean;
}

export const HowItWorks: FunctionComponent = () => {
  const [section, setSection] = useState(3);

  const sectionChange = (section: number) => {
    setSection(section);
  };

  return (
    <Background>
      <PageSection>
        <Container>
          <ContentHolder>
            <Headline type={3} inverse>
              Discover how far your money will take you
            </Headline>
            <Content inverse={true}>
              Regardless of where you start, we provide you with the tools needed to generate
              financial momentum—helping you reach your next level faster.
            </Content>
            <List>
              <ListItem onClick={() => sectionChange(3)} active={section === 3}>
                <Paragraph>Yieldstreet funds</Paragraph>
              </ListItem>
              <ListItem onClick={() => sectionChange(2)} active={section === 2}>
                <Paragraph>Individual offerings</Paragraph>
              </ListItem>
              <ListItem onClick={() => sectionChange(1)} active={section === 1}>
                <Paragraph>Yieldstreet IRA</Paragraph>
              </ListItem>
              <ListItem onClick={() => sectionChange(0)} active={section === 0}>
                <Paragraph>Yieldstreet Wallet</Paragraph>
              </ListItem>
              <ListItem onClick={() => sectionChange(4)} active={section === 4}>
                <Paragraph>Reach your next level</Paragraph>
              </ListItem>
            </List>
          </ContentHolder>
          <RightHolder>
            <Circle>
              <Commercial onClick={() => sectionChange(0)} active={section === 0}>
                <Image alt="Yieldstreet Wallet" src={CommercialImage} />
              </Commercial>
              <IRA onClick={() => sectionChange(1)} active={section === 1}>
                <Image alt="Yieldstreet IRA" src={IRAImage} />
              </IRA>
              <RealEstate onClick={() => sectionChange(2)} active={section === 2}>
                <Image alt="investing in individual offerings" src={RealEstateImage} />
              </RealEstate>
              <DataScience onClick={() => sectionChange(3)} active={section === 3}>
                <Image alt="investing in Yieldstreet funds" src={DataScienceImage} />
              </DataScience>
              <YSLogo onClick={() => sectionChange(4)} active={section === 4}>
                <Image alt="invest to realize your next level" src={YSLogoImage} />
              </YSLogo>

              <SubContentHolder active={section === 3}>
                <Image src={DataScienceImage} />
                <Title>YieldStreet funds</Title>
                <Paragraph inverse small>
                  Make one allocation spread across multiple classes and sectors, allowing you to
                  immediately diversify your portfolio.
                  <ul>
                    <li>Reinvest your dividends</li>
                    <li>Immediate portfolio acceleration</li>
                  </ul>
                  <ButtonContainer>
                    <a href="/offerings">
                      <Button small>Invest</Button>
                    </a>
                  </ButtonContainer>
                </Paragraph>
              </SubContentHolder>

              <SubContentHolder active={section === 2}>
                <Image src={RealEstateImage} />
                <Title>Individual offerings</Title>
                <Paragraph inverse small>
                  Invest across a variety of institutional quality assets like Art, Legal, Marine,
                  Private Business Credit and Real Estate.
                  <ul>
                    <li>Rolling maturities</li>
                    <li>Low stock market correlation</li>
                  </ul>
                  <ButtonContainer>
                    <a href="/offerings">
                      <Button small>Invest</Button>
                    </a>
                  </ButtonContainer>
                </Paragraph>
              </SubContentHolder>

              <SubContentHolder active={section === 1}>
                <Image src={IRAImage} />
                <Title>Yieldstreet IRA</Title>
                <Paragraph inverse>
                  As an investor-first company, we ensure that you’re leveraging all of the tools
                  available to you.
                  <ul>
                    <li>Tax-efficient</li>
                    <li>Optimize your retirement accounts</li>
                  </ul>
                  <ButtonContainer>
                    <a href="/ira">
                      <Button small>Get started</Button>
                    </a>
                  </ButtonContainer>
                </Paragraph>
              </SubContentHolder>

              <SubContentHolder active={section === 0}>
                <Image src={CommercialImage} />
                <Title>Yieldstreet Wallet</Title>
                <Paragraph inverse small>
                  Your Wallet acts as a flexible savings account and allows you to invest more
                  quickly.
                  <ul>
                    <li>FDIC insured</li>
                  </ul>
                  <ButtonContainer>
                    <a href="/signup-quiz">
                      <Button small>Sign up</Button>
                    </a>
                  </ButtonContainer>
                </Paragraph>
              </SubContentHolder>

              <SubContentHolder active={section === 4}>
                <Image src={YSLogoImage} />
                <Title>Reach your next level</Title>
                <Paragraph inverse small>
                  With Yieldstreet as your partner, you’re generating passive income to help fund
                  your dreams. Whether your ambitions are large or small, we accelerate your
                  timeline.
                  <ButtonContainer>
                    <a href="/signup-quiz">
                      <Button small>Sign up</Button>
                    </a>
                  </ButtonContainer>
                </Paragraph>
              </SubContentHolder>
            </Circle>
          </RightHolder>
        </Container>
      </PageSection>
    </Background>
  );
};

const Background = styled.div`
  background: ${props => props.theme.colors.background_inverse};
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  ${media.desktop`
    padding: 0 0 40px 110px;
    flex-wrap: nowrap;
  `}
`;

const ContentHolder = styled.div`
  padding: 20px 0 20px;
  width: 100%;
  ${media.desktop`
    padding: 60px 0 60px;
    width: 40%;
  `}
`;

const Headline = styled(Heading)`
  margin: 20px 0 20px 20px;
`;

const Content = styled(Paragraph)`
  margin: 0 0 20px 20px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0px 20px;
  margin-top: 40px;
  display: none;
  ${media.desktop`
    display: block;
  `}
`;

const ListItem = styled.li<ListItemProps>`
  p {
    color: ${props => (props.active ? props.theme.colors.active : props.theme.colors.text_label)};
    &:hover {
      color: ${props => props.theme.colors.active};
    }
  }

  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  cursor: pointer;
  border-top: 1px solid ${props => props.theme.colors.border_default};
  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.border_default};
  }
`;

const RightHolder = styled.div`
  width: 100%;
  margin: 20px;
  ${media.desktop`
    width: 60%;
    margin: 70px 40px 0 120px;
  `}
`;

const Circle = styled.div`
  position: relative;
  ${media.desktop`
    border-radius: 50%;
    border: 2px solid white;
    width: 535px;
    height: 535px;
    box-sizing: border-box;
    padding: 93px 111px 0px 156px;
    margin-top: 20px;
  `}
`;

const Image = styled.img`
  width: 80px;
`;

const AssetImages = styled.div<AssetImagesProps>`
  width: 120px;
  position: absolute;
  border: 20px solid ${props => props.theme.colors.background_inverse};
  background: ${props => props.theme.colors.background_inverse};
  cursor: pointer;
  ${Image} {
    opacity: ${props => (props.active ? `100%` : `30%`)};
    transition: opacity 0.5s linear;
  }
  display: none;
  ${media.desktop`
    display: block;
  `}
`;

const Commercial = styled(AssetImages)`
  left: -35px;
  top: 340px;
`;

const YSLogo = styled(AssetImages)`
  left: -28px;
  top: 84px;
`;

const IRA = styled(AssetImages)`
  right: -21px;
  top: 340px;
`;

const RealEstate = styled(AssetImages)`
  right: -32px;
  top: 89px;
`;

const DataScience = styled(AssetImages)`
  left: 207px;
  top: -54px;
`;

const SubContentHolder = styled.div<SubContentHolderProps>`
  ${Image} {
    margin: 0 0 20px;
    ${media.desktop`
      display: none;
      margin: 0;
    `}
  }
  display: block;
  ${media.desktop<SubContentHolderProps>`
    display: ${props => (props.active ? 'block' : 'none')};
  `}
`;

const Title = styled(Paragraph)`
  color: ${props => props.theme.colors.active};
  margin-bottom: 10px;
`;
