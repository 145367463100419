import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { map, omit, isMatch, uniqBy } from 'lodash';
import { websocketManager, SocketAuthenticate } from '../../websocket';
import { communicationApi } from './communication';
import {
  CommunicationMessage,
  CommunicationMessageModified,
  FetchMessagesApiRequestParams,
  FetchMessagesApiRequestParamsKey,
} from './communication.model';

/**
 * @const getQueryParams
 * @desc Modifies the query parameters as to construct the
 * correct anchor tags. Anchor tags are required due to the use of websockets
 * in conjuction to Rest API calls for fetching messages.
 */
export const getQueryParams = (params: FetchMessagesApiRequestParams) => {
  if (params.anchor) {
    const { id, timestamp } = params.anchor;
    return {
      ...omit(params, ['anchor', 'authToken', 'refreshTimestamp']),
      ...{
        'anchor.id': id,
        'anchor.timestamp': timestamp,
      },
    };
  }
  return omit(params, ['authToken', 'refreshTimestamp']);
};

/**
 * @const getCacheParams
 * @desc Omits page, limit and anchor parameters due to the messages being
 * aggregated as one list as part of the getMessages RTK query. The latter does
 * not dispatch any API calls whatsoever.
 */
export const getCacheParams = (
  params?: FetchMessagesApiRequestParams
): FetchMessagesApiRequestParams =>
  omit(params, ['page', 'limit', 'anchor'] as FetchMessagesApiRequestParamsKey[]);

/**
 * @const updateMessage
 * @desc updates the message properties in the getMessages RTK query. This is namely
 * used when a message is either being marked as read or dismissed. Messages here are
 * optimisically being updated whilst an async call is performed on the backend.
 */
export const updateMessage = (
  messages: CommunicationMessageModified[],
  messageSpecId: string,
  delta: Partial<CommunicationMessageModified>
): CommunicationMessageModified[] =>
  map(messages, (message: CommunicationMessageModified) =>
    message.messageSpecId === messageSpecId ? { ...message, ...delta } : message
  );

export const updateMessages = (
  messages: CommunicationMessageModified[],
  messageSpecIds: string[],
  delta: Partial<CommunicationMessageModified>
) =>
  map(messages, (message: CommunicationMessageModified) =>
    messageSpecIds.includes(message.messageSpecId) ? { ...message, ...delta } : message
  );

/**
 * @const updateCache
 * @desc Aggregates the incoming messages and existing messages in cache,
 * also safeguards for any potential messages with the same spec ID.
 */
export const updateCache = (
  draft: CommunicationMessageModified[],
  delta: CommunicationMessageModified[],
  tail: boolean = true
) => {
  const uniqByKey: keyof CommunicationMessage = 'messageSpecId';
  if (!delta?.length) {
    return draft;
  }

  return tail ? uniqBy([...draft, ...delta], uniqByKey) : uniqBy([...delta, ...draft], uniqByKey);
};

/**
 * @const updateMessageArgs
 * @desc Maps through the messages and adds the request
 * message arguments to each message.
 */
export const updateMessageArgs = (
  messages: CommunicationMessage[] = [],
  args: FetchMessagesApiRequestParams
): CommunicationMessageModified[] =>
  map(messages, message => ({
    ...message,
    messageArgs: args,
    seen: message.read,
  }));

/**
 * @const constructTagId
 * @desc Constructs a tagID based on FetchMessagesApiRequestParams
 */
export const constructTagId = (args: FetchMessagesApiRequestParams): string => JSON.stringify(args);

/**
 * @const onSocketOpen
 * @desc Autheticates the user.
 */
export const onSocketOpen = (key: string, authToken: string) =>
  websocketManager.send(key, SocketAuthenticate(authToken));

/**
 * @const onSocketMessage
 * @desc Updates the getMessages cache upon receiving a message via the
 * web socket. Message cache is only updated if the message params matches
 * the current cache.
 */
export const onSocketMessage = (
  ev: MessageEvent,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  params: FetchMessagesApiRequestParams
): void => {
  const message: CommunicationMessage = JSON.parse(ev.data);
  const messageParams = extractMessageParams(message);

  if (isMatch(messageParams, sanitiseFetchParams(params))) {
    const messageWithArgs = updateMessageArgs([message], messageParams);
    dispatch(
      communicationApi.util.updateQueryData('getMessages', getCacheParams(params), draft =>
        updateCache(draft, messageWithArgs, false)
      )
    );
  }
};

const extractMessageParams = (message: CommunicationMessage): FetchMessagesApiRequestParams => {
  const { configuration } = message;
  return {
    types: [message.messageType],
    investorAccountId: configuration.investorAccountId || undefined,
    noteUrlHashes: configuration.noteUrlHash ? [configuration.noteUrlHash] : undefined,
    category: configuration.category,
    read: message.read,
    dismissed: message.dismissed,
  };
};

/**
 * @const sanitiseFetchParams
 * @desc Sanitises FetchMessagesApiRequestParams params as to be able
 * to match extracted message params. This is namely as we have some
 * fetch parameters which aren't available within the recieved messages.
 */
const sanitiseFetchParams = (
  params: FetchMessagesApiRequestParams
): FetchMessagesApiRequestParams => omit(params, ['authToken', 'updates']);
