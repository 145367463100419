import { genTestIds } from '@yieldstreet/tool-kit';

const { getTestIds } = genTestIds([
  'asset-class',
  'button',
  'description',
  'interest-small',
  'interest',
  'link',
  'offering-title',
  'terms',
  'terms-small',
  'title',
]);
export const testIds = getTestIds('offering-announcement');

export interface OfferingAnnouncementProps {
  assetClass?: string;
  backgroundColor?: string;
  buttonText?: boolean;
  dismiss?: () => {};
  dismissible?: boolean;
  interest?: string;
  inverse?: boolean;
  offeringTitle?: string;
  onClick?: () => {};
  orientation?: 'row' | 'column';
  path?: string;
  small?: boolean;
  terms?: string;
  title?: string;
}

export interface OfferingVisualProps {
  backgroundColor?: string;
  inverse?: boolean;
  orientation?: 'row' | 'column';
  small?: boolean;
}
