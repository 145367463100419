import React, { FunctionComponent, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TagProps, Tags } from './Tags';
import { ExtraTags } from './ExtraTags';

interface Props {
  tags: TagProps[];
  hasLegacyOfferingTags: boolean;
}

export const TagsList: FunctionComponent<Props> = ({ tags = [], hasLegacyOfferingTags }) => {
  const [extraTagsHidden, setExtraTagsHidden] = useState(true);

  const onShowMore = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setExtraTagsHidden(!extraTagsHidden);
  };

  let visibleTags: any = [];
  let hiddenTags: TagProps[] = [];
  const maxVisibleTags = 2;
  const maxVisibleCharacters = 26;

  let totalCharacters = hasLegacyOfferingTags
    ? tags.reduce((acc, { length }) => acc + length, 0)
    : tags.reduce((acc, obj) => acc + obj.name.length, 0);

  if (maxVisibleCharacters >= totalCharacters) {
    visibleTags = [...tags];
  } else if (hasLegacyOfferingTags) {
    let amountOfVisibleCharacters = 0;
    const [visTags, hidTags] = tags.reduce(
      (acc: any, tag) => {
        const [visible] = acc;
        if (
          visible.length < maxVisibleTags &&
          amountOfVisibleCharacters + tag.length < maxVisibleCharacters
        ) {
          amountOfVisibleCharacters += tag.length;
          acc[0].push(tag);
        } else {
          acc[1].push(tag);
        }
        return acc;
      },
      [[], []]
    );
    visibleTags = [...visTags];
    hiddenTags = [...hidTags];
  } else {
    let amountOfVisibleCharacters = 0;
    const [visTags, hidTags] = tags.reduce(
      (acc: any, tag) => {
        const [visible] = acc;
        if (
          visible.length < maxVisibleTags &&
          amountOfVisibleCharacters + tag.name.length < maxVisibleCharacters
        ) {
          amountOfVisibleCharacters += tag.name.length;
          acc[0].push(tag);
        } else {
          acc[1].push(tag);
        }
        return acc;
      },
      [[], []]
    );
    visibleTags = [...visTags];
    hiddenTags = [...hidTags];
  }

  const hasMoreTags = !isEmpty(hiddenTags);

  return (
    <>
      <Tags
        data-cy="past-offerings-card-summary-tags"
        tags={visibleTags}
        hasMoreTags={hasMoreTags}
        onShowMore={onShowMore}
        hasLegacyOfferingTags={hasLegacyOfferingTags}
      />
      <ExtraTags
        data-cy="past-offerings-card-extra-tags"
        tags={[...visibleTags, ...hiddenTags]}
        isHidden={extraTagsHidden}
        onClose={onShowMore}
        hasLegacyOfferingTags={hasLegacyOfferingTags}
      />
    </>
  );
};
