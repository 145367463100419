import blackLogo from './assets/loading_black.riv';
import whiteLogo from './assets/loading_white.riv';
import noLogoLoad from './assets/loading_animation.riv';

export interface LoadingLogoSize {
  width: string;
  height: string;
}

export enum LoadingLogoType {
  DARK,
  WHITE,
  NO_LOGO,
}

export interface LoadingLogoProps {
  size: LoadingLogoSize;
  testId?: string;
  type?: LoadingLogoType;
}

export const logoVariations = {
  [LoadingLogoType.DARK]: blackLogo,
  [LoadingLogoType.WHITE]: whiteLogo,
  [LoadingLogoType.NO_LOGO]: noLogoLoad,
};
