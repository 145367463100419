import { HttpMethods } from '../../enums/http';

import { restApi, RTKQAll, Tags } from '../common';
import {
  GetUnitApplicationFormParams,
  GetUnitApplicationFormResponse,
  UnitApplicationForm,
  GetUnitKYCMigrationStatusResponse,
  GetUnitBankLinkMigrationStatusResponse,
  StartUnitKYCMigrationParams,
  UnitKYCMigrationStatus,
  UnitBankLinkMigrationStatus,
} from './unit.model';

const BASE_URL = '/api/unit';

export const unitApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.UnitApplication,
      Tags.UnitKYCMigrationStatus,
      Tags.UnitBankLinkMigrationStatus,
      Tags.InvestorEntityStatus,
      Tags.BankAccount,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getUnitApplication = builder.query<UnitApplicationForm, GetUnitApplicationFormParams>({
        query: ({ investorEntityId }) => ({
          url: `${BASE_URL}/kyc/investor-entity/${investorEntityId}/application-form`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.UnitApplication],
        transformResponse: (response: GetUnitApplicationFormResponse) => response.value,
      });

      const startUnitKYCMigration = builder.mutation<{}, StartUnitKYCMigrationParams>({
        query: ({ investorEntityId }) => ({
          url: `/a/api/kyc/investor-entity/${investorEntityId}/migrate`,
          method: HttpMethods.Post,
        }),
        invalidatesTags: [
          Tags.UnitKYCMigrationStatus,
          Tags.UnitBankLinkMigrationStatus,
          Tags.InvestorEntityStatus,
          Tags.BankAccount,
        ],
      });

      const getUnitKYCMigrationStatus = builder.query<UnitKYCMigrationStatus, RTKQAll>({
        query: () => ({
          url: `${BASE_URL}/kyc/migration`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.UnitKYCMigrationStatus],
        transformResponse: (response: GetUnitKYCMigrationStatusResponse) => response.value,
      });

      const getUnitBankLinkMigrationStatus = builder.query<UnitBankLinkMigrationStatus, RTKQAll>({
        query: () => ({
          url: `/a/api/linked-bank-accounts/migration`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.UnitBankLinkMigrationStatus],
        transformResponse: (response: GetUnitBankLinkMigrationStatusResponse) => response.value,
      });

      return {
        getUnitApplication,
        startUnitKYCMigration,
        getUnitKYCMigrationStatus,
        getUnitBankLinkMigrationStatus,
      };
    },
  });
