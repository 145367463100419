import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
import {
  Heading,
  Paragraph,
  ParagraphProps,
  ParagraphBase,
  ParagraphChildren,
} from '../../content/text';
import { Button, ButtonContainer } from '../../content/button';
import { PageSection } from '../../layout/page-section';
import PlusSign from './assets/plus-sign.svg';

import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PlusTextProps {
  active?: boolean;
}

interface SectionHolderProps {
  active?: boolean;
}

export const OurOriginationApproach: FunctionComponent = () => {
  const [section, setSection] = useState<number | undefined>(0);

  const sectionChange = (newSelection: number, isMobile = false) => {
    if (isMobile && section === newSelection) {
      setSection(undefined);
      return;
    }
    setSection(newSelection);
  };

  return (
    <Background>
      <PageSection>
        <Container>
          <LeftContent>
            <MainHeadline type={3} inverse>
              Our origination approach
            </MainHeadline>
            <Content inverse>
              Evaluating opportunities from a diverse pool of originators allows us to offer
              investors opportunities from numerous asset classes, spread across the yield spectrum.
            </Content>
            <ContentList inverse small>
              <ul>
                <li>Asset-based collateral</li>
                <li>Low stock market correlation</li>
                <li>Experienced management</li>
                <li>Short duration (less than 5 years)</li>
                <li>Risk appropriate returns</li>
              </ul>
            </ContentList>
          </LeftContent>
          <CenterContent>
            <PlusHolder>
              <StrategicPartnerships onClick={() => sectionChange(0)} active={section === 0}>
                <Paragraph inverse small>
                  Strategic partnerships
                </Paragraph>
              </StrategicPartnerships>
              <JointVentures onClick={() => sectionChange(1)} active={section === 1}>
                <Paragraph inverse small>
                  Joint ventures
                </Paragraph>
              </JointVentures>
              <MandA onClick={() => sectionChange(2)} active={section === 2}>
                <Paragraph inverse small>
                  M&amp;A
                </Paragraph>
              </MandA>
              <YieldStreetOriginated onClick={() => sectionChange(3)} active={section === 3}>
                <Paragraph inverse small>
                  Yieldstreet originated
                </Paragraph>
              </YieldStreetOriginated>
              <Image
                alt="four elements of Yieldstreet origination approach to alternative investing"
                src={PlusSign}
              />
            </PlusHolder>
          </CenterContent>
          <RightContent>
            <SectionHolder active={section === 0}>
              <HeadingWrapper>
                <Headline type={3} inverse>
                  Strategic partnerships <ArrowIcon icon={faChevronUp}></ArrowIcon>
                </Headline>
              </HeadingWrapper>
              <MobileHeadingWrapper onClick={() => sectionChange(0, true)}>
                <Headline type={3} inverse>
                  Strategic partnerships <ArrowIcon icon={faChevronUp}></ArrowIcon>
                </Headline>
              </MobileHeadingWrapper>

              <SectionContentHolder>
                <Content inverse semiBold small>
                  Strategic forward flow, Simple forward flow
                </Content>
                <Content inverse small>
                  Agreements with select originators where Yieldstreet provides a strategic flow
                  agreement that gives originators support to grow their business. In turn,
                  Yieldstreet gets a minor interest in the originator and origination ROFR.
                </Content>
                <Content inverse semiBold small>
                  Simple Forward Flow
                </Content>
                <Content inverse small>
                  Agreements with select originators where Yieldstreet provides a non-binding flow
                  agreement, but the agreement highlights a desire to memorialize the relationship.
                  The desire is to move this from simple to strategic over time.
                </Content>
              </SectionContentHolder>
            </SectionHolder>

            <SectionHolder active={section === 1}>
              <HeadingWrapper>
                <Headline type={3} inverse>
                  Joint ventures <ArrowIcon icon={faChevronUp}></ArrowIcon>
                </Headline>
              </HeadingWrapper>
              <MobileHeadingWrapper onClick={() => sectionChange(1, true)}>
                <Headline type={3} inverse>
                  Joint ventures <ArrowIcon icon={faChevronUp}></ArrowIcon>
                </Headline>
              </MobileHeadingWrapper>

              <SectionContentHolder>
                <Content inverse small>
                  Partnerships with originators to explore new opportunities together.
                </Content>
                <Content inverse small>
                  There are strategic flow terms for Yieldstreet and equity benefits from the JV
                  ownership.
                </Content>
              </SectionContentHolder>
            </SectionHolder>

            <SectionHolder active={section === 2}>
              <HeadingWrapper>
                <Headline type={3} inverse>
                  M&amp;A <ArrowIcon icon={faChevronUp}></ArrowIcon>
                </Headline>
              </HeadingWrapper>
              <MobileHeadingWrapper onClick={() => sectionChange(2, true)}>
                <Headline type={3} inverse>
                  M&amp;A <ArrowIcon icon={faChevronUp}></ArrowIcon>
                </Headline>
              </MobileHeadingWrapper>

              <SectionContentHolder>
                <Content inverse small>
                  In order to continue to bring new asset classes to our investors, we leverage our
                  technology, proprietary origination channels and efficient direct-to-consumer
                  distribution, to take a traditional capital-intensive business and make it capital
                  efficient.
                </Content>
              </SectionContentHolder>
            </SectionHolder>

            <SectionHolder active={section === 3}>
              <HeadingWrapper>
                <Headline type={3} inverse>
                  Yieldstreet originated{' '}
                </Headline>
              </HeadingWrapper>
              <MobileHeadingWrapper onClick={() => sectionChange(3, true)}>
                <Headline type={3} inverse>
                  Yieldstreet originated <ArrowIcon icon={faChevronUp}></ArrowIcon>
                </Headline>
              </MobileHeadingWrapper>
              <SectionContentHolder>
                <Content inverse small>
                  Deals are shown on a one-off basis from the originator.
                </Content>
              </SectionContentHolder>
            </SectionHolder>

            <ButtonHolder>
              <a href="/originator-application/contact">
                <Button>Start application</Button>
              </a>
            </ButtonHolder>
          </RightContent>
        </Container>
      </PageSection>
    </Background>
  );
};

const Background = styled.div`
  background: ${props => props.theme.colors.background_inverse};
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 40px 0 0 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    padding: 40px 100px 20px 100px;
  `}
`;

const LeftContent = styled.div`
  padding: 0 20px;
  flex-grow: 1;
  flex-basis: 0;
`;

const CenterContent = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const RightContent = styled.div`
  padding: 40px 0 0 0;
  flex-grow: 1;
  flex-basis: 0;
  ${media.desktop<SectionHolderProps>`
    padding: 40px 20px 0 20px;
  `}
`;

const Headline = styled(Heading)`
  padding: 20px 0;
  ${media.desktop`
    color: ${props => `${props.theme.colors.text_header_inverse}`};
    border: none;
    padding: 0 0 20px 0;
  `}
`;

const HeadingWrapper = styled.div`
  display: none;
  ${media.desktop`
    display: block;
  `}
`;

const MobileHeadingWrapper = styled.div`
  display: block;
  ${media.desktop`
    display: none;
  `}
`;

const Content = styled(Paragraph)`
  margin: 0 0 10px 0;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  float: right;
  padding: 5px;
  margin-top: 5px;
  width: 10px;
  transition: all 0.2s ease-in;
  transform: rotate(180deg);
  ${media.desktop`
    display: none;
  `}
`;

const SectionHolder = styled.div<SectionHolderProps>`
  display: block;
  padding: 0 20px;
  ${props => {
    if (props.active) {
      return `
        ${SectionContentHolder} {
          max-height: 800px;
          transition: all 0.5s ease-in;
        }
        ${Headline} {
          color: ${props.theme.colors.text_header};
        }
        ${ArrowIcon} {
          transform: rotate(-0deg);
        }
        padding: 0 20px 20px 20px;
        ${Content} {
          display: block;
          color: ${props.theme.colors.text_paragraph};
        }
        background-color: ${props.theme.colors.background_card};
      `;
    } else {
      return `
        ${Headline} {
          border-bottom: 1px solid ${props.theme.colors.background_card};
        }
      `;
    }
  }}
  ${media.desktop<SectionHolderProps>`
    padding: 0;
    ${Headline} {
      color: ${props => `${props.theme.colors.text_header_inverse}`};
    }
    ${Content} {
      color: ${props => `${props.theme.colors.text_paragraph_inverse}`};
    }
    background: none;
    display: ${props => (props.active ? 'block' : 'none')};
  `}
`;

const MainHeadline = styled(Heading)`
  padding: 0 0 20px 0;
`;

const SectionContentHolder = styled.div`
  max-height: 0;
  overflow: hidden;
  height: auto;
  ${media.desktop`
    display: block;
  `}
`;

const ButtonHolder = styled(ButtonContainer)`
  padding: 0 20px;
  ${media.desktop`
    padding: 0;
  `}
`;

const ContentList = styled.div<ParagraphProps>`
  ${ParagraphBase}
  ${ParagraphChildren}
`;

const Image = styled.img`
  width: 100%;
  ${media.desktop`
    width: auto;
  `}
`;

const PlusHolder = styled.div`
  display: none;
  ${media.desktop`
    padding: 65px;
    position: relative;
    margin: 0 auto;
    max-width: 592px;
    display: block;
  `}
`;

const PlusText = styled.div<PlusTextProps>`
  p {
    color: ${props => (props.active ? props.theme.colors.active : props.theme.colors.text_label)};
  }
  cursor: pointer;
  position: absolute;
  width: 80px;
  margin: auto;

  ${media.desktop`
    width: 100px;
    p {
      text-align: left;
      font-size: 16px;
    }
`}
  ${media.desktop`
  width: 90px;
  p {
    text-align: left;
    font-size: 16px;
    }
  `}
`;

const StrategicPartnerships = styled(PlusText)`
  ${media.desktop`
    top: 95px; 
    left: 0; 
    right: 0;
  `}
`;

const YieldStreetOriginated = styled(PlusText)`
  ${media.desktop`
    top: 0; 
    left: 90px; 
    bottom: 0; 
  `}
  height: 45px;
`;

const JointVentures = styled(PlusText)`
  ${media.desktop`
    top: 0; 
    bottom: 0; 
    right: 80px;
    width: 80px;
  `}
  height: 45px;
`;

const MandA = styled(PlusText)`
  ${media.desktop`
    left: 0; 
    bottom: 95px; 
    right: 0;
    p {
     text-align: center;
    }
  `}
`;
