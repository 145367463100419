import styled from 'styled-components';
import { media } from '../../themes';

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  background: ${props => props.theme.gradients.radial_primary};
  box-shadow: ${props => props.theme.shadows.elevation_2};

  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  justify-content: flex-end;

  ${media.desktop`
    max-width: 285px;
    padding: 20px;
    padding-bottom: 0px;
    border-radius: 8px;

    flex-direction: column;
    gap: 30px;
    justify-content: center;
  `}
`;

export const Title = styled.div`
  max-width: 50%;
  text-align: initial;
  margin: auto 0;
  ${media.desktop`
    max-width: 85%;
    margin: none;
  `}
`;

export const Arrow = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
`;
export const Image = styled.img`
  max-height: 105px;
  ${media.desktop`
    margin: 0 25px;
    max-height: 170px;
  `}
`;
