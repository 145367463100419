import { InvestorEntityProvider } from '../../enums';

export const managedPortfolioEligibility = {
  eligibility: {
    userGate: null,
    investorEntities: [
      {
        investorEntityId: 201924,
        name: 'Test User',
        ysIra: false,
        retirementAccount: false,
        provider: {
          type: InvestorEntityProvider.YS,
          data: null,
        },
        investorAccounts: [],
        advisoryRelationship: 'NO_ADVISORY',
        gateData: null,
      },
    ],
  },
};

export const managedPortfolioEligibilityWithAccounts = {
  success: true,
  eligibility: {
    userGate: null,
    investorEntities: [
      {
        investorEntityId: '201924',
        name: 'Test User',
        ysIra: false,
        retirementAccount: false,
        provider: {
          type: InvestorEntityProvider.YS,
          data: null,
        },
        investorAccounts: [
          {
            investorAccountId: 201924,
            gateData: null,
          },
        ],
        advisoryRelationship: 'NO_ADVISORY',
        gateData: null,
      },
    ],
  },
};

export const managedPortfolioIneligible = {
  eligibility: {
    investorData: null,
    investorEntities: [
      {
        advisoryRelationship: 'NO_ADVISORY',
        gateData: {
          actionable: false,
          attributes: {
            action: {
              code: 'VERIFY_ACCREDITATION',
              investorEntityId: null,
              nextStep: 'Verify Accreditation',
              priority: 5,
              status: null,
            },
            reason: 'EXCHANGE_AFFILIATED',
          },
          blocking: true,
          gateCode: 'KYC_INELIGIBLE',
          gateText: 'Entity is ineligible for managed flow',
        },
        investorAccounts: [
          {
            investorAccountId: 4186128,
            gateData: null,
          },
        ],
        investorEntityId: '4186128',
        name: 'Alfie Byrne',
        provider: { data: null, type: 'ys' },
        retirementAccount: false,
        ysIra: false,
      },
    ],
    userGate: {
      actionable: false,
      attributes: null,
      blocking: true,
      gateCode: 'INVESTOR_ENTITY_GATE',
      gateText: 'Investor entity require some action to complete setup',
    },
  },
  success: true,
};
