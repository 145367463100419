import { combineReducers, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';

import { graphqlApi, restApi } from '../apis';

import {
  MockRtkExtraReducers,
  MockRtkQuery,
  MockRtkApi,
  MockAxiosConfig,
  MockRtkQueryEndpoints,
  QueryFnMock,
} from './mockRtkApiStore.model';

export const getRTKMockStoreExtraMiddlewares = () => {
  return [restApi.middleware, graphqlApi.middleware];
};

export const getMockEndpoint = (api: any, endpoint: string, data: any) => {
  return {
    api,
    endpoints: [
      {
        endpoint,
        queryFn: () => ({ data }),
      },
    ],
  };
};

export const getMockErrorEndpoint = (api: any, endpoint: string, errorData: any) => {
  return {
    api,
    endpoints: [
      {
        endpoint,
        queryFn: () => ({
          error: {
            response: {
              data: typeof errorData === 'string' ? { message: errorData } : errorData,
            },
          },
        }),
      },
    ],
  };
};

export const getMockLoadingEndpoint = (api: any, endpoint: string) => {
  return {
    api,
    endpoints: [
      {
        endpoint,
        queryFn: () => new Promise(() => {}),
      },
    ],
  };
};

export function mockApi(queries: MockRtkQuery[]) {
  if (!queries?.length) {
    return;
  }

  return map(queries, query => {
    let enhancedEndpoints = {};

    const { api, endpoints } = query;
    forEach(endpoints, e => {
      Object.assign(enhancedEndpoints, {
        [e.endpoint]: {
          query: null,
          queryFn: e.queryFn,
        },
      });
    });

    const extendedApi = api.enhanceEndpoints({
      endpoints: enhancedEndpoints,
    });
    return extendedApi;
  });
}

export function setupApiStore(
  apis?: MockRtkApi[],
  extraReducers?: MockRtkExtraReducers,
  extraMiddlewares?: any | any[],
  mockQueries?: MockRtkQuery[]
): { apis?: MockRtkApi[] | any[]; store: EnhancedStore } {
  /*
   * Modified version of RTK Query's helper function:
   * https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/tests/helpers.tsx
   */

  let extendedApis = mockApi(mockQueries || []);

  let apiReducers = {};
  forEach([...(extendedApis || []), ...(apis || [])], api => {
    Object.assign(apiReducers, { [api.reducerPath]: api.reducer });
  });

  let apiMiddlewares: any = [];

  if (apis) {
    apiMiddlewares = apis?.map(query => query.middleware);
  } else {
    apiMiddlewares = mockQueries?.map((query: any) => query.api?.middleware);
  }

  const getStore = (): EnhancedStore =>
    configureStore({
      reducer: combineReducers({
        ...(apiReducers || {}),
        ...(extraReducers || {}),
      }),
      middleware: gdm =>
        gdm({ serializableCheck: false, immutableCheck: false }).concat(
          ...(apiMiddlewares || []),
          ...(extraMiddlewares || [])
        ),
    });

  const initialStore = getStore();

  const refObj = {
    apis: apis || extendedApis,
    store: initialStore,
  };

  return refObj;
}

export function configureRTKMockStore(
  apis: MockRtkQuery[] | any[],
  extraReducers: MockRtkExtraReducers,
  extraMiddlewares: any,
  axiosConfig?: MockAxiosConfig[],
  isMockingQueryFn?: boolean
): {
  apis?: MockRtkApi[] | any[];
  store: EnhancedStore;
  mockAxios: AxiosMockAdapter;
  queryFnMocks: QueryFnMock;
} {
  const mockAxios = new AxiosMockAdapter(axios);

  /*
    DO NOT TOUCH THE FOR LOOP
    THE FOR IS USED ON PURPOSE FOR API BLOCKING - SYNCHRONOUS QUEUED
   */
  if (isArray(axiosConfig)) {
    for (const config of axiosConfig) {
      if (config.type === 'GET') {
        mockAxios.onGet(config.url, { params: config?.params }).reply(() => {
          return config.data;
        });
      }

      if (config.type === 'POST') {
        mockAxios.onPost(config.url).reply(() => {
          return config.data;
        });
      }
    }
  }

  let queryFnMocks: QueryFnMock = {}; // for cases where we want to verify if the endpoint was called with a certain params or not
  let apisWithQueryFnMock;

  if (isMockingQueryFn) {
    apisWithQueryFnMock = apis.map((api: MockRtkQuery, idx) => {
      return {
        ...api,
        endpoints: apis[idx].endpoints.map((endpointObj: MockRtkQueryEndpoints) => {
          queryFnMocks = {
            ...queryFnMocks,
            [endpointObj.endpoint]: jest.fn(),
          };
          return {
            ...endpointObj,
            queryFn: (params: any) => {
              queryFnMocks[endpointObj.endpoint](params);
              return endpointObj.queryFn(params);
            },
          };
        }),
      };
    });
  }

  const setup = setupApiStore(
    null as any,
    extraReducers,
    isArray(extraMiddlewares) ? extraMiddlewares : [extraMiddlewares],
    isMockingQueryFn ? apisWithQueryFnMock : apis
  );

  return { mockAxios, queryFnMocks, ...setup };
}
