import styled from 'styled-components';

export interface MarketingCinematicProps {
  withBorder?: boolean;
}

export const Container = styled.div<MarketingCinematicProps>`
  max-width: 1250px;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
  ${props => props.withBorder && `border: solid 1px ${props.theme.colors.border_default};`}
`;
