export enum InvestorAccountType {
  INDIVIDUAL = 'Individual',
  SOLE_PROPRIETOR = 'Sole Proprietor',
  S_CORPORATION = 'S Corporation',
  C_CORPORATION = 'C Corporation',
  PARTNERSHIP = 'Partnership',
  LLC = 'Limited Liability Company',
  TRUST_EIN = 'Trust',
  TRUST_SSN = 'Trust',
  TRADITIONAL_IRA = 'Traditional YSIRA',
  ROTH_IRA = 'Roth YSIRA',
}

export enum InvestmentAccountType {
  INDIVIDUAL = 'INDIVIDUAL',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  S_CORPORATION = 'S_CORPORATION',
  C_CORPORATION = 'C_CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  LLC = 'LLC',
  TRUST_EIN = 'TRUST_EIN',
  TRUST_SSN = 'TRUST_SSN',
  TRADITIONAL_IRA = 'TRADITIONAL_IRA',
  ROTH_IRA = 'ROTH_IRA',
}

export enum InvestorType {
  INDIVIDUAL = 'individual',
  TRUST = 'trust',
  TRUST_SSN = 'trust-with-ssn',
  TRUST_EIN = 'trust-with-ein',
  ENTITY = 'entity',
  S_DIRA = 'sdira',
  IRA = 'ira',
}

export enum CreateInvestorAccountType {
  ANOTHER_INDIVIDUAL = 'ANOTHER_INDIVIDUAL',
  ITS_IRA = 'ITS_IRA',
  ETC_IRA = 'ETC_IRA',
  ENTITY = 'ENTITY',
  ENTITY_ITS_401K = 'ITS_401K',
  ENTITY_ANOTHER_RETIREMENT_TYPE = 'ANOTHER_TYPE',
  ENTITY_NOT_RETIREMENT = 'NOT_RETIREMENT',
}
