import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Paragraph } from '../../content/text';

/* assets */
import studentHouse from './assets/office.png';
import industrial from './assets/plant.png';
import multifamily from './assets/house.png';
import hotel from './assets/pool.png';

/* Shares value properties */
const imageAndSpaceWidth = '182px';

/* =========================================
 *    Desktop industry sector tiles module
 * ========================================= */

export const IndustrySectorsDesktop = () => {
  return (
    <ParentContainer>
      <SectorSectionDesktop>
        <SectorColumn>
          <SectorCallout image={hotel} imageAlt="hotel real estate investing" text="Hotel" />
          <SectorCallout
            image={studentHouse}
            imageAlt="student housing real estate investing"
            text="Student Housing"
          />
        </SectorColumn>
        <SectorColumn>
          <SectorCallout
            image={multifamily}
            imageAlt="multifamily real estate investing"
            text="Multifamily"
            contrast
          />
          <SectorCallout
            image={industrial}
            imageAlt="industrial real estate investing, avatar originator yielstreet real estate investing"
            text="Industrial"
            contrast
          />
        </SectorColumn>
      </SectorSectionDesktop>
    </ParentContainer>
  );
};

/* ===================================
 *    Mobile industry sector tiles
 * =================================== */

export const IndustrySectorsMobile = () => {
  return (
    <SectorSectionMobile>
      <SectorCallout image={hotel} imageAlt="hotel real estate investing" text="Hotel" />
      <SectorCallout
        image={multifamily}
        imageAlt="multifamily real estate investing"
        text="Multifamily"
        orientation="rtl"
      />
      <SectorCallout
        image={studentHouse}
        imageAlt="student housing real estate investing"
        text="Student Housing"
        contrast
      />
      <SectorCallout
        image={industrial}
        imageAlt="industrial real estate investing, avatar originator yielstreet real estate investing"
        text="Industrial"
        orientation="rtl"
        contrast
      />
    </SectorSectionMobile>
  );
};

/* ===================================
 *    Sector callout UI element
 * =================================== */

/* shared types */
type orientationTypes = 'ltr' | 'rtl';

interface SectorCalloutProps {
  image: string;
  imageAlt?: string;
  text: string;
  orientation?: orientationTypes;
  contrast?: boolean;
}

export const SectorCallout: FunctionComponent<SectorCalloutProps> = ({
  image,
  text,
  imageAlt = '',
  orientation = 'ltr',
  contrast = false,
}) => {
  return (
    <CalloutContainer flexPosition={orientation}>
      <CalloutImage src={image} alt={imageAlt} />
      <CalloutText inverse={contrast}>{text}</CalloutText>
    </CalloutContainer>
  );
};

/* ===== Layout UI elements ===== */

const ParentContainer = styled.div`
  display: none;
  ${media.desktop`
    display:flex;
    justify-content:stretch;
    margin-right: 180px;
    width: calc(100% - 180px);
    position: absolute;
    right: 0;
    bottom: 120px;
  `}
`;

const SectorSectionDesktop = styled.div`
  display: flex;
`;

const SectorSectionMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  ${media.tablet`
    margin-bottom:20px;
  `}
  ${media.desktop`
    display:none;
  `}
`;

const SectorColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  &:last-child {
    transform: translateX(-20%);
  }
`;

/* ----------------- */

interface CalloutContainerProps {
  flexPosition: orientationTypes;
}

const CalloutContainer = styled.div<CalloutContainerProps>`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: ${props => (props.flexPosition === 'ltr' ? 'row' : 'row-reverse')};
  &:last-child {
    margin-bottom: 0;
  }
  ${media.desktop`
    margin-bottom:0px;
    &:last-child {
      margin-left: ${imageAndSpaceWidth}
    }
  `}
`;

/* ===== Component UI elements ===== */

const CalloutImage = styled.img`
  width: 55%;
  max-width: 375px;
  ${media.desktop`
    width:${imageAndSpaceWidth};
  `}
`;

/* ===== Type styles ==== */

const CalloutText = styled(Paragraph)`
  padding: 0px 20px;
`;
