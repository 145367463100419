import styled from 'styled-components';
import {
  StepItemContainerProps,
  StepProgressBarProps,
  StepProgressDotProps,
} from './StepProgressBar.model';
import { Label } from '../../content';
import { media } from '../../themes';

// temp color
export const StepProgressBarContainer = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.l};
  width: auto;
  background-color: #b0bebf;
  border-radius: ${({ theme }) => theme.radius.md};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet`
    margin: 0 ${({ theme }) => theme.spacing.xl};
  `}
`;

export const ProgressLine = styled.div`
  height: 6px;
  display: flex;
  align-items: center;
  width: 100%;
`;

// temp color
export const ProgressBar = styled.div<StepProgressBarProps>`
  height: 100%;
  background-color: #b2e061;
  transition: width 0.3s ease-in-out;
  border-radius: ${({ theme }) => theme.radius.md};
  width: ${({ width }) => width}%;
`;

// temp color
export const ProgressDot = styled.div<StepProgressDotProps>`
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #b2e061;
  border-radius: 50%;
  left: ${({ left }) => left}%;
  margin-left: -${({ theme }) => theme.spacing.xs};
`;

export const ItemTitle = styled(Label)<{ id: string }>`
  white-space: nowrap;
  position: absolute;
  top: -${({ theme }) => theme.spacing.l};
`;

export const ItemContainer = styled.div<StepItemContainerProps>`
  position: absolute;
  left: ${({ left }) => left}%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
