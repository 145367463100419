import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';

import { Audiences } from '../../enums/audiences/audiences';
import {
  AudienceRequest,
  AudienceResponse,
  AudienceResolution,
  AudiencePreset,
  AudienceKeyMap,
  AudiencePresets,
} from '../../types';

export const mapAudienceResponse = (response: AudienceResponse): AudienceResolution =>
  mapKeys(response, (_value, key) => AudienceKeyMap[key]);

export const getAudiencePresetRequest = (preset: AudiencePreset): AudienceRequest =>
  AudiencePresets[preset].reduce<AudienceRequest>((payload, key) => {
    payload[Audiences[key]] = {};
    return payload;
  }, {});

export const getAudiencePresetValues = (preset: AudiencePreset, audiences: AudienceResolution) =>
  pick(audiences, AudiencePresets[preset]);
