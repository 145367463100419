import { InvestorAccountProvider } from '../../enums';
import { InvestorAccountProviderDetails } from '../../types';

export const investorAccountProvider: InvestorAccountProviderDetails<InvestorAccountProvider.YS> = {
  type: InvestorAccountProvider.YS,
  data: null,
};

export const investorAccountProviderETC: InvestorAccountProviderDetails<InvestorAccountProvider.ETC> =
  {
    type: InvestorAccountProvider.ETC,
    data: {
      etc_owner_contact_id: '2200044',
      etc_account_number: '205350023',
    },
  };
