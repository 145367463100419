import React, { Component, FunctionComponent } from 'react';
import styled from 'styled-components';
import ArrowIcon from '../../assets/graphics/icons/arrow.svg';
import { CallbackLink } from '../../content/link';

interface BreadcrumbProps {
  breadcrumbText?: string;
  onClickLinkFn: () => void;
}

export class Breadcrumb extends Component<BreadcrumbProps> {
  render = () => {
    const { breadcrumbText, onClickLinkFn } = this.props;
    return <BreadcrumbComponent breadcrumbText={breadcrumbText} onClickLinkFn={onClickLinkFn} />;
  };
}

const BreadcrumbComponent: FunctionComponent<BreadcrumbProps> = ({
  onClickLinkFn,
  breadcrumbText,
}) => (
  <BreadcrumbWrapper>
    <Arrow
      alt="Arrow to return to investment page"
      src={ArrowIcon}
      onClick={e => {
        e.preventDefault();
        onClickLinkFn && onClickLinkFn();
      }}
    />
    <CallbackLink callback={onClickLinkFn} isBreadcrumb>
      <span>{breadcrumbText && breadcrumbText}</span>
    </CallbackLink>
  </BreadcrumbWrapper>
);

const BreadcrumbWrapper = styled.div`
  display: flex;
`;

const Arrow = styled.img`
  transform: rotate(90deg);
  margin-right: 8px;
  cursor: pointer;
`;
