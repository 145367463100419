import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import { FeatureEnvironmentsApiResponse } from '../../types';

export const environmentsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.FeatureEnvironments],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getFeatureEnvs = builder.query<FeatureEnvironmentsApiResponse, {}>({
        query: () => ({
          url: 'https://api.yieldstreet.cloud/v1/feature-environments',
          method: HttpMethods.Get,
        }),
      });

      return {
        getFeatureEnvs,
      };
    },
  });
