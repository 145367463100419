import { useApiQueryPollCheck } from '../../../async-effects';
import { unitApi } from '@yieldstreet/platform-kit';
import {
  UNIT_KYC_COMPLETE,
  UnitWindowStatus,
  isApplicationUserInputReady,
  isApplicationUserInputDone,
} from './useUnitApplicationForm.model';
import { useState, useEffect, useCallback, useMemo } from 'react';

export const useUnitApplicationForm = (investorEntityId: string) => {
  const unitKYCApplicationResult = unitApi.useGetUnitApplicationQuery({
    investorEntityId,
  });

  const [windowStatus, setWindowStatus] = useState<UnitWindowStatus>();
  const [windowURL, setWindowURL] = useState<string>();

  const { data: { status, applicationUrl } = {} } = unitKYCApplicationResult;

  useEffect(() => {
    // storing initial application url in a fixed state to prevent window refreshes
    // since Unit application url changes on every query refetch in case of poll check
    if (!windowURL) {
      setWindowURL(applicationUrl);
    }
  }, [applicationUrl, windowURL, setWindowURL]);

  const inputState = useMemo(() => {
    const isReady = isApplicationUserInputReady(status);
    const isDone = isApplicationUserInputDone(status) || windowStatus === UnitWindowStatus.DONE;
    const isLoading = !isReady || !windowStatus;

    return { isLoading, isReady, isDone };
  }, [status, windowStatus]);

  // poll until application form is ready for user input
  useApiQueryPollCheck(unitKYCApplicationResult, ({ status }) =>
    isApplicationUserInputReady(status)
  );

  // when window has been redirected poll until application form user input is done
  useApiQueryPollCheck(
    unitKYCApplicationResult,
    ({ status }) => isApplicationUserInputDone(status),
    windowStatus !== UnitWindowStatus.REDIRECTED
  );

  // first window load means the unit application form will be loaded
  // second window load means the redirect to YS callback url loaded
  const onWindowLoad = useCallback(
    () => setWindowStatus(!windowStatus ? UnitWindowStatus.LOADED : UnitWindowStatus.REDIRECTED),
    [windowStatus, setWindowStatus]
  );

  const onWindowMessage = useCallback(
    (data?: any) => data?.action === UNIT_KYC_COMPLETE && setWindowStatus(UnitWindowStatus.DONE),
    [setWindowStatus]
  );

  return {
    ...inputState,
    windowURL,
    windowStatus,
    onWindowLoad,
    onWindowMessage,
  };
};
