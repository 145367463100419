export enum OfferingStatus {
  closed = 'CLSD',
  deleted = 'DELE',
  failed = 'FAIL',
  fundedPending = 'CLNG',
  open = 'OPEN',
  payout = 'PAYO',
  pending = 'PEND',
  pendingFullyCommitted = 'PNFL',
  preoffering = 'PREO',
  repaid = 'MATD',
  unfinished = 'UNFN',
}
