import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { SectionContainer } from '../../layout/section-container/SectionContainer';
import { media } from '../../themes/media';
import { PageSection } from '../../layout/page-section';
import { Heading } from '../../content/text';

interface Alignment {
  contentAlignment?: 'left' | 'center';
  mobileContentAlignment?: 'left' | 'center';
}
interface CtaBannerProps extends Alignment {
  title?: string;
  headingTagType?: 1 | 2 | 3 | 4 | 5;
  headingType?: 1 | 2 | 3 | 4 | 5;
  inverse: boolean;
  className?: string;
}

export const CtaBanner: FunctionComponent<CtaBannerProps> = ({
  title,
  headingTagType = 3,
  headingType = 3,
  children,
  inverse,
  contentAlignment,
  mobileContentAlignment,
  className,
}) => {
  return (
    <OuterContainer>
      <PageSection>
        <InnerContainer>
          <Container
            className={className}
            mobileContentAlignment={mobileContentAlignment}
            contentAlignment={contentAlignment}
          >
            <Headline tagType={headingTagType} type={headingType} inverse={inverse}>
              {title}
            </Headline>
            <Content>{children}</Content>
          </Container>
        </InnerContainer>
      </PageSection>
    </OuterContainer>
  );
};

const OuterContainer = styled.div``;

const InnerContainer = styled(SectionContainer)`
  ${media.tablet`
    padding: 0 35px;
  `};
  padding: 0;
`;

const Headline = styled(Heading)``;

const Container = styled.div<Alignment>`
  background: ${props => props.theme.colors.background_inverse_secondary};
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  text-align: ${props => (props.mobileContentAlignment === 'center' ? 'center' : 'left')};
  flex-direction: column;
  ${media.desktop<Alignment>`
    padding: 60px 40px;
    align-items: center;
    justify-content: space-between;
    text-align: ${props => (props.contentAlignment === 'center' ? 'center' : 'left')};
    flex-direction:  ${props => (props.contentAlignment === 'center' ? 'column' : 'row')};

    ${Headline} {
      width: ${props => (props.contentAlignment === 'center' ? '80%' : '50%')};
    }
  `}
`;

const Content = styled.div``;
