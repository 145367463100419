import React, { FunctionComponent } from 'react';
import { Link, LinkStyleProps } from './styles';

export interface ExternalLinkProps extends LinkStyleProps {
  id?: string;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isBreadcrumb?: boolean;
  title?: string;
}

export const ExternalLink: FunctionComponent<ExternalLinkProps> = ({
  children,
  rel = 'noopener noreferrer',
  id,
  ...otherProps
}) => (
  <Link {...otherProps} rel={rel} tabIndex={0} data-cy={id}>
    {children}
  </Link>
);
