import styled from 'styled-components';

import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepConnector from '@material-ui/core/StepConnector';

export const CustomConnector = styled(StepConnector)`
  .MuiStepConnector-lineVertical {
    border: 1px solid;
    width: 1px;
  }

  .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.colors.border_default};
    border-top-width: 3px;
    border-radius: 1px;
    margin-top: -2px;
    //offsets and align the borders MUI used as lines on horizontal

    &.MuiStepConnector-lineVertical {
      border-radius: 0px;
    }
  }

  &.MuiStepConnector-active .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.colors.progress_bar_primary};
  }

  &.MuiStepConnector-completed .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.colors.progress_bar_primary};
  }
`;

export const StepIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const CustomStepLabel = styled(StepLabel)`
  .MuiStepLabel-labelContainer > .MuiStepLabel-alternativeLabel {
    margin-top: ${({ theme }) => theme.spacing.m};
  }

  &.MuiStepLabel-vertical {
    max-width: unset;
    margin-left: 1px;
    //offsets and align the borders MUI used as lines on vertical/mobile

    .MuiStepLabel-labelContainer {
      margin-left: ${({ theme }) => theme.spacing.sm};
    }
  }

  max-width: 180px;
  margin: auto;
`;

export const CustomStepper = styled(Stepper)`
  background: transparent !important;
  padding: 0 !important;
`;

export const CustomStepContent = styled(StepContent)<{ completed?: boolean; last?: boolean }>`
  &.MuiStepContent-root {
    ${({ completed, last, theme }) => `
      border-left: ${last ? '0px' : '2px'} solid ${
      completed ? theme.colors.progress_bar_primary : theme.colors.border_default
    };
    `}

    padding-left: calc(20px + ${({ theme }) => theme.spacing.sm});
    // since this is the "line" between vertical steps it already had a 20px default offset
    // that should be kept for alignment
  }

  .MuiCollapse-wrapperInner {
    margin-top: -${({ theme }) => theme.spacing.xs};
  }
`;
