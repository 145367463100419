import { ReactNode } from 'react';

import { HeadingType } from '../../content/text/Heading';
import { genTestIds } from '@yieldstreet/tool-kit';

export interface ContentProps {
  show: boolean;
}

export interface DescriptionProps {
  addParagraphMargin?: boolean;
}

export interface AccordionProps {
  addParagraphMargin?: boolean;
  animated?: boolean;
  background?: Background;
  headingIcon?: string;
  headingType?: HeadingType;
  showIcon?: boolean;
  startOpen?: boolean;
  subHeading?: ReactNode;
  tagType?: HeadingType;
  title?: string;
  toggleVisibilityCallback?: () => void;
  testId?: string;
}

export interface ContainerProps {
  background: Background;
}

export type Background = 'primary' | 'secondary';

export const backgroundVariant = {
  primary: 'background_card',
  secondary: 'background_light',
};

export const { getTestIds } = genTestIds([]);

export const mapToTestIds = (testId = '') => getTestIds(`${testId}-accordion`);
