import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Snackbar, Slide, SlideProps, SnackbarCloseReason } from '@mui/material';
import { Paragraph } from '../../content/text';

interface ToastNotificationProps {
  autoHideDuration?: number | null;
  icon?: React.ReactNode;
  text: string | React.ReactElement;
  title?: string;
  className?: string;
  transitionDuration?: number;
}

export const ToastNotification: FunctionComponent<ToastNotificationProps> = ({
  autoHideDuration = null,
  icon,
  text,
  title,
  className,
  transitionDuration,
}) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = (_event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    return setOpen(!open);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={toggleOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={(props: SlideProps) => (
        <Slide {...props} direction="up" timeout={transitionDuration} />
      )}
      className={className}
    >
      <Wrapper>
        <IconWrapper>{icon}</IconWrapper>
        <TextWrapper>
          <LabelText small>{title}</LabelText>
          <Paragraph semiBold small>
            {text}
          </Paragraph>
        </TextWrapper>
      </Wrapper>
    </Snackbar>
  );
};

const Wrapper = styled.div`
  background: ${props => props.theme.colors.background_card};
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12), 0px 8px 24px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  min-width: 335px;
`;

const IconWrapper = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.background_page};
  display: flex;
  justify-content: center;
  margin: 8px;
  height: 48px;
  width: 48px;
`;

const TextWrapper = styled.div`
  margin: 10px 10px 10px 0;
`;

const LabelText = styled(Paragraph)`
  color: ${props => props.theme.colors.text_label};
`;
