import { HttpMethods } from '../../../enums';
import { restApi, Tags, RTKQAll } from '../../common';
import {
  PortfolioGetDocumentsApiResponse,
  PortfolioGetDocumentsRequest,
  GetDocumentsRequest,
  GetInvestorEntityDocumentsRequest,
  GetInvestorEntityDocumentsResponse,
} from './get-documents.model';

export const getDocumentsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioGetDocuments],
  })
  .injectEndpoints({
    endpoints: build => {
      const getInvestmentDocuments = build.query<
        PortfolioGetDocumentsApiResponse,
        PortfolioGetDocumentsRequest | RTKQAll
      >({
        query: ({ investorAccountId, noteUrlHash, userId }: PortfolioGetDocumentsRequest) => {
          return {
            url: `/a/api/portfolio/get-documents/${noteUrlHash}/${userId}`,
            method: HttpMethods.Get,
            params: {
              investorAccountId,
            },
          };
        },
        providesTags: [Tags.PortfolioGetDocuments],
      });

      const getDocuments = build.query<GetDocumentsRequest, GetDocumentsRequest>({
        query: ({ userId, type }: GetDocumentsRequest) => {
          return {
            url: `/a/api/portfolio/get-documents/${userId}`,
            method: HttpMethods.Get,
            params: {
              type,
            },
          };
        },
        providesTags: [Tags.PortfolioGetDocuments],
      });

      const getInvestorEntityDocuments = build.query<
        GetInvestorEntityDocumentsResponse,
        GetInvestorEntityDocumentsRequest
      >({
        query: ({ investorEntityId, type }: GetInvestorEntityDocumentsRequest) => {
          return {
            url: `/a/api/portfolio/get-documents/user/investor-entity/${investorEntityId}`,
            method: HttpMethods.Get,
            params: {
              type,
            },
          };
        },
        providesTags: [Tags.PortfolioGetDocuments],
      });

      return {
        getDocuments,
        getInvestmentDocuments,
        getInvestorEntityDocuments,
      };
    },
  });
