import {
  VoteValue,
  CampaignApiPutRequest,
  CampaignEligibilityApiGetRequest,
  CampaignEligibilityApiGetResponse,
  CampaignStatus,
  CampaignApiPutResponse,
} from './campaign.model';

export const CampaignApiPutRequestMock: CampaignApiPutRequest = {
  fundId: 'abc123',
  userId: 1,
  investorAccountId: 1,
  votes: [{ campaignId: 'abc123', value: VoteValue.Yes }],
};

export const CampaignEligibilityApiGetRequestMock: CampaignEligibilityApiGetRequest = {
  fundId: 'abc123',
  campaignId: 'abc123',
  userId: 1,
  investorAccountId: 1,
};

export const EligibleCampaignMock: CampaignEligibilityApiGetResponse = {
  success: true,
  eligible: true,
  message: 'User is eligible to vote on this campaign.',
  votes: [
    {
      id: '0000aa80-e1a1-11eb-8521-0006c5b0',
      campaignAggregateId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      campaignId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      description: '',
      noteTitle: 'asd',
      noteUrlHash: 'jb123',
      userId: '1234',
      investorAccountId: '1',
      value: null,
      status: CampaignStatus.Pending,
    },
    {
      id: '7b829da0-af55-11ea-8b88-259b8f74d735',
      campaignAggregateId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      campaignId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      description: '',
      noteTitle: 'asd',
      noteUrlHash: 'jb123',
      userId: '1234',
      investorAccountId: '2567',
      value: null,
      status: CampaignStatus.Pending,
    },
  ],
};

export const NotEligibleCampaignVotedMock: CampaignEligibilityApiGetResponse = {
  success: true,
  eligible: false,
  message: 'User is NOT eligible to vote on this campaign.',
  votes: [
    {
      id: '0000aa80-e1a1-11eb-8521-0006c5b0',
      userId: '1234',
      investorAccountId: '1',
      value: VoteValue.Yes,
      status: CampaignStatus.Computed,
      campaignAggregateId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      campaignId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      description: '',
      noteTitle: 'asd',
      noteUrlHash: 'jb123',
    },
    {
      id: '7b829da0-af55-11ea-8b88-259b8f74d735',
      userId: '1234',
      investorAccountId: '2567',
      value: VoteValue.Yes,
      status: CampaignStatus.Computed,
      campaignAggregateId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      campaignId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      description: '',
      noteTitle: 'asd',
      noteUrlHash: 'jb123',
    },
  ],
};

export const NotEligibleCampaignMock: CampaignEligibilityApiGetResponse = {
  success: true,
  eligible: false,
  message: 'User is NOT eligible to vote on this campaign.',
  votes: [],
};

export const CampaignApiPutResponseMock: CampaignApiPutResponse = {
  userId: '1234',
  fundId: '7b829da0-af55-11ea-8b88-25d823f227d56',
  votes: [
    {
      id: '0000aa80-e1a1-11eb-8521-0006c5b0',
      accountId: '7b829da0-af55-11ea-8b88-25d83ff2893e',
      investorAccountId: '1',
      value: VoteValue.Yes,
      status: CampaignStatus.Computed,
      campaignAggregateId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      campaignId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      description: '',
      noteTitle: 'asd',
      noteUrlHash: 'jb123',
    },
    {
      id: '7b829da0-af55-11ea-8b88-259b8f74d735',
      accountId: '7b829da0-af55-11ea-8b88-25d823f74d988',
      investorAccountId: '2567',
      value: VoteValue.Yes,
      status: CampaignStatus.Computed,
      campaignAggregateId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      campaignId: '0000aa80-e1a1-11eb-8521-0006c5b0',
      description: '',
      noteTitle: 'asd',
      noteUrlHash: 'jb123',
    },
  ],
};
