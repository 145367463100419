import React, { FC } from 'react';
import styled from 'styled-components';

import ActionItemsIndicatorMark from '../../assets/graphics/case-specific/action_items_indicator_mark.svg';
import { Paragraph } from '../../content';
import { media } from '../../themes/media';

type ActionItemsIndicatorPropsType = {
  iconOnly?: boolean;
  largeIcon?: boolean;
  onClick?: () => void;
  text?: string;
};

export const ActionItemsIndicator: FC<ActionItemsIndicatorPropsType> = ({
  iconOnly,
  largeIcon,
  onClick,
  text = 'Action items',
}) => {
  const handleOnClick = () => {
    typeof onClick === 'function' && onClick();
  };

  return (
    <IndicatorContainer
      data-testid="action-items-indicator"
      iconOnly={iconOnly}
      onClick={handleOnClick}
    >
      <IndicatorIcon alt="Exclamation mark" largeIcon={largeIcon} src={ActionItemsIndicatorMark} />

      {!iconOnly && (
        <IndicatorText semiBold small>
          {text}
        </IndicatorText>
      )}
    </IndicatorContainer>
  );
};

const IndicatorContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0 10px;
  padding: 5px 0;
  ${media.desktop<{ iconOnly?: boolean }>`
    ${props =>
      !props.iconOnly &&
      `
        background: ${props.theme.colors.action_indicator_background};
        padding-left: 10px;
        padding-right: 10px;
      `}
  `}
`;

const IndicatorIcon = styled.img<{ largeIcon?: boolean }>`
  height: ${props => (props.largeIcon ? '23px' : '16px')};
  width: ${props => (props.largeIcon ? '23px' : '16px')};
`;

const IndicatorText = styled(Paragraph)`
  color: ${props => props.theme.colors.action_indicator_color};
  display: none;
  margin-left: 8px;
  ${media.desktop`
    display: block;
  `}
`;
