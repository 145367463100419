import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { paymentMethodsApi, PaymentMethodType } from '@yieldstreet/platform-kit';
import { forEach } from 'lodash';

import {
  useInvestmentPaymentMethodsParams,
  paymentMethodOptionsParams,
  PaymentMethods,
} from './useInvestmentPaymentMethods.model';

const paymentMethodOptions = ({
  paymentInfo,
  supportAdditionalPaymentMethods,
}: paymentMethodOptionsParams) => {
  let paymentMethods: PaymentMethods[] = [];
  let primaryPaymentMethods: PaymentMethods[] = [];
  let additionalFundsPaymentMethods: PaymentMethods[] = [];

  if (paymentInfo) {
    paymentInfo.paymentOptions.forEach(paymentOption => {
      if (paymentOption.bankAccounts.length) {
        paymentOption.bankAccounts.forEach(bankAcct => {
          paymentMethods.push({
            label: bankAcct.displayName,
            paymentMethod: paymentOption.type as keyof typeof PaymentMethodType,
            bankAccountId: bankAcct.id,
            processOn: paymentOption.processOn,
            value: paymentOption.type + bankAcct.id,
            additionalFunds: paymentOption.additionalFunds,
          });
        });
      } else if (!paymentOption.additionalFunds) {
        paymentMethods.push({
          label: paymentOption.description,
          paymentMethod: paymentOption.type as keyof typeof PaymentMethodType,
          bankAccountId: null,
          processOn: paymentOption.processOn,
          value: paymentOption.type,
          additionalFunds: paymentOption.additionalFunds,
        });
      }
    });
  }

  forEach(paymentMethods, method => {
    if (method.additionalFunds) {
      if (supportAdditionalPaymentMethods) {
        additionalFundsPaymentMethods.push(method);
      }
    } else {
      primaryPaymentMethods.push(method);
    }
  });

  // helper consts
  const hasPaymentMethodChoice = primaryPaymentMethods.length > 1;
  const firstPaymentMethod = primaryPaymentMethods[0];

  const hasAdditionalFundsPaymentMethodChoice = additionalFundsPaymentMethods.length > 1;
  const firstAdditionalFundsPaymentMethod = additionalFundsPaymentMethods[0];

  return {
    paymentMethods,
    hasPaymentMethodChoice,
    firstPaymentMethod,
    hasAdditionalFundsPaymentMethodChoice,
    firstAdditionalFundsPaymentMethod,
    primaryPaymentMethods,
    additionalFundsPaymentMethods,
  };
};

export const useInvestmentPaymentMethods = ({
  investorAccountId,
  investmentAmount,
  noteUrlHashes,
  supportAdditionalPaymentMethods,
  rolloverConfig,
}: useInvestmentPaymentMethodsParams) => {
  const paymentMethodQuery = useMemo(() => {
    if (!investorAccountId || !investmentAmount || !noteUrlHashes) {
      return skipToken;
    }

    return {
      investorAccountId,
      investmentAmount,
      urlHashes: noteUrlHashes,
      ...rolloverConfig,
    };
  }, [investorAccountId, investmentAmount, noteUrlHashes, rolloverConfig]);

  const {
    currentData: paymentInfo,
    isFetching: paymentInfoLoading,
    error: paymentInfoError,
    refetch,
  } = paymentMethodsApi.usePaymentMethodsQuery(paymentMethodQuery, {
    refetchOnMountOrArgChange: true,
  });

  const {
    paymentMethods,
    hasPaymentMethodChoice,
    firstPaymentMethod,
    hasAdditionalFundsPaymentMethodChoice,
    firstAdditionalFundsPaymentMethod,
    primaryPaymentMethods,
    additionalFundsPaymentMethods,
  } = useMemo(
    () => paymentMethodOptions({ paymentInfo, supportAdditionalPaymentMethods }),
    [paymentInfo, supportAdditionalPaymentMethods]
  );

  return {
    // payment info data
    paymentInfo,
    paymentInfoLoading,
    paymentInfoError,

    // payment methods
    paymentMethods,
    primaryPaymentMethods,
    additionalFundsPaymentMethods,

    // helper consts
    hasPaymentMethodChoice,
    firstPaymentMethod,
    hasAdditionalFundsPaymentMethodChoice,
    firstAdditionalFundsPaymentMethod,

    refetchPaymentMethods: refetch,
  };
};
