export const bulkInvestmentEligibilityMock = {
  success: true,
  eligibility: {
    userGate: null,
    investorData: [
      {
        investorEntityId: '395238',
        investorAccountId: 395238,
        name: 'SAI User',
        gateData: null,
        action: null,
        readyToInvest: true,
        ysIra: false,
        retirementAccount: false,
        urlHashReason: {},
        advisoryRelationship: 'NO_ADVISORY',
        provider: {
          type: 'ys',
          data: null,
        },
      },
    ],
    investorEntities: null,
  },
};

export const bulkInvestmentEligibilityGatedMock = {
  success: true,
  eligibility: {
    userGate: {
      gateCode: 'NO_INVESTOR_ACCOUNT',
      gateText: 'gated!',
    },
    investorData: [
      {
        investorEntityId: '395238',
        investorAccountId: 395238,
        name: 'SAI User',
        gateData: null,
        action: null,
        readyToInvest: true,
        ysIra: false,
        retirementAccount: false,
        urlHashReason: {},
        advisoryRelationship: 'NO_ADVISORY',
        provider: {
          type: 'ys',
          data: null,
        },
      },
    ],
    investorEntities: null,
  },
};
