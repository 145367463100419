import React, { forwardRef } from 'react';
import { TextInput, TextInputProps } from './TextInput';

import PhoneInput from 'react-phone-number-input';
import { withFormikWeb } from '../common';
import 'react-phone-number-input/style.css';
import { InputBaseComponentProps } from '@mui/material';
import styled, { createGlobalStyle, ThemeProps } from 'styled-components';
import { Theme } from '../../types';

const PhoneFormatInput: React.ForwardRefExoticComponent<InputBaseComponentProps> = forwardRef(
  (props, ref) => {
    const { value, onBlur, onChange, numberInputProps, ...otherProps } = props;

    return (
      <STextInput
        {...numberInputProps}
        {...otherProps}
        value={value}
        prefix={<div></div>}
        inputProps={otherProps}
        onBlur={() =>
          onBlur &&
          onBlur({
            currentTarget: { value },
          } as React.FocusEvent<HTMLInputElement>)
        }
        onChange={value =>
          onChange &&
          onChange({
            currentTarget: { value },
          } as React.FormEvent<HTMLInputElement>)
        }
        inputRef={ref}
      />
    );
  }
);

export const PhoneTextInput: React.FunctionComponent<TextInputProps> = props => {
  const { onChange, value, name, disabled, fullWidth, ...otherProps } = props;

  let valueWithCountryCode = value;
  const numberHasNoCountryCode = value?.length && value[0] !== '+';
  if (numberHasNoCountryCode) {
    valueWithCountryCode = `+1${value}`;
  }

  return (
    <>
      <PhoneInputGlobalStyle />
      <SPhoneInput
        value={valueWithCountryCode}
        name={name}
        limitMaxLength
        useNationalFormatForDefaultCountryValue
        addInternationalOption={false}
        defaultCountry={'US'}
        disabled={disabled}
        inputComponent={PhoneFormatInput}
        numberInputProps={otherProps}
        onChange={(value: any) => (onChange ? onChange(value) : null)}
        fullWidth={fullWidth}
      />
    </>
  );
};

const STextInput = styled(TextInput)`
  & .MuiFilledInput-adornedStart {
    padding-left: 0 !important;
  }
  & .MuiInputBase-root .MuiInputBase-input.MuiInputBase-inputAdornedStart {
    padding-left: 55px;
  }
`;

const PhoneInputGlobalStyle = createGlobalStyle`
  :root {
    ${({ theme }: ThemeProps<Theme>) => `
    --PhoneInput-color--focus: ${theme.colors.text_label} !important;
    --PhoneInputCountryFlag-borderColor: ${theme.colors.text_label} !important;
    --PhoneInputCountryFlag-borderColor--focus: ${theme.colors.text_label_inverse} !important;
    --PhoneInputCountrySelectArrow-color: ${theme.colors.text_label} !important;
    --PhoneInputCountrySelectArrow-color--focus: ${theme.colors.text_label_inverse} !important;
    --PhoneInputCountrySelectArrow-opacity: 1 !important;
    `}
  }
`;

const SPhoneInput = styled(PhoneInput)`
  &.PhoneInput {
    display: inline-block;
    position: relative;
    ${props => (props.fullWidth ? 'width: 100%;' : '')}

    & .PhoneInputCountry {
      position: absolute;
      z-index: 10;
      top: 31px;
      left: 14px;
    }
  }
`;

export const FormPhoneTextInput = withFormikWeb(PhoneTextInput);
