import { UserContact } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeUserContact: ObjectAnonymizer<UserContact> = value =>
  anonymizeObject(value, {
    firstName: 'string',
    lastName: 'string',
    phone: 'string',
    phoneRaw: 'string',
    address: 'address',
  });
