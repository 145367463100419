import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Dropzone, { DropzoneProps } from 'react-dropzone';

import fileIcon from './icons/upload.svg';

import { Paragraph, ParagraphLink, Label } from '../../content/text';
import { media } from '../../themes/media';
import { BaseProps } from '../..';
import { useTestIds } from './FileDropzone.model';

type FileDropzoneProps = DropzoneProps &
  BaseProps & {
    fileDescription?: string;
  };

const FileDropzone: FunctionComponent<FileDropzoneProps> = props => {
  const { fileDescription, ...dropzoneProps } = props;

  const testIds = useTestIds(props);

  return (
    <Dropzone {...dropzoneProps}>
      {({ getRootProps, getInputProps }) => (
        <SectionWrapper {...testIds.base.attr}>
          <Content {...getRootProps()} {...testIds.root.attr}>
            <input {...getInputProps()} {...testIds.input.attr} />
            <FileIcon src={fileIcon} alt="" />
            <MobileParagraph small>Upload {fileDescription || 'file'}</MobileParagraph>
            <DesktopParagraph small>Drag & Drop</DesktopParagraph>
            <DesktopLabel>
              Drag your {fileDescription || 'file'} here or{' '}
              <Underline>browse for a {fileDescription || 'file'}</Underline> to upload
            </DesktopLabel>
          </Content>
        </SectionWrapper>
      )}
    </Dropzone>
  );
};

const MobileParagraph = styled.span`
  ${ParagraphLink};
  display: block;
  width: fit-content;
  margin: auto;
  ${media.tablet`
    display: none;
  `}
`;

const DesktopParagraph = styled(Paragraph)`
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

const DesktopLabel = styled(Label)`
  display: none;
  ${media.tablet`
    display: block;
    padding: 0 10px;
  `}
`;

const FileIcon = styled.img`
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  ${media.tablet`
    margin-bottom: 20px;
  `}
`;

const SectionWrapper = styled.section`
  background: ${props => props.theme.colors.background_card};
  text-align: center;
`;

const Content = styled.div`
  border: none;
  padding: 40px 0;
  outline: none;
  text-decoration: none;

  ${media.tablet`
    border: 1px dashed ${props => props.theme.colors.border_default};
    padding: 20px 0;
  `}
`;

const Underline = styled.span`
  text-decoration: underline;
`;

export default FileDropzone;
