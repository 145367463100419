import oldStyled from 'styled-components';
import { ActionType, ButtonProps } from './ActionCell.model';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

const handleColor = (props: ButtonProps) => {
  switch (props.type) {
    case ActionType.ActionRequired:
      return `
        background: ${props.theme.colors.background_error};
        color: ${props.theme.colors.text_error_main};
      `;
    case ActionType.ActionNavigate:
      return `
        background: ${props.theme.colors.background_card};
        color: ${props.theme.colors.text_paragraph};
        border: ${props.theme.colors.border_default};
      `;
    case ActionType.ActionSuccess:
      return `
          background: ${props.theme.colors.background_success};
          color: ${props.theme.colors.text_paragraph};
          border: ${props.theme.colors.border_default};
        `;
    case ActionType.ActionAvailable:
    default:
      return `
        background: ${props.theme.colors.input_button_basic_inverse_background_active};
        color: ${props.theme.colors.text_paragraph};
      `;
  }
};

export const ActionButton = oldStyled.div<ButtonProps>`
  ${props => handleColor(props)};
  font-size: 12px;
  font-weight: 500;
  padding: ${({ theme }) => theme.spacing.s};
  border-radius: 30px;
  width: fit-content;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-color: ${props => props.theme.colors.background_inverse};
    display: none;
    opacity: 0.08;
  }

  ${props =>
    props.onClick &&
    `&:hover::before {
        display: block;
      }`}
`;

export const ActionContent = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: theme.spacing(2),
}));

export const ActionCellIcon = styled('svg')({
  minWidth: '20px',
});
