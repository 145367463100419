import styled from 'styled-components';

import { Heading, Label } from '../../content/text';

import { media } from '../../themes';
import { OfferingTitleIcon } from './OfferingTitle.model';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.sm};
  flex: 1 1 auto;

  ${media.tablet`
    gap: ${props => props.theme.spacing.m};
  `};
`;

export const Copy = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  gap: ${props => props.theme.spacing.xs};
`;

export const IconWrapper = styled.div``;

export const Icon = styled.div<OfferingTitleIcon>`
  border-radius: 2px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  ${props => props?.src && `background-image: url(${props.src});`}
  ${props =>
    props?.compact
      ? `
    width: ${props.theme.spacing['xl']};
    height: ${props.theme.spacing['xl']};
  `
      : `
    width: ${props.theme.spacing['2xl']};
    height: ${props.theme.spacing['2xl']};
  `}
`;

export const Title = styled(Heading)``;

export const Subtitle = styled(Label)``;
