import { ReactChild } from 'react';
import {
  GateObject,
  GateActions,
  SocketData,
  Promotion,
  PaymentMethodType,
} from '@yieldstreet/platform-kit';

export type GateOptions = {
  onCTAClick?: Function;
  children?: ReactChild;
  buttonLoading?: boolean;
  buttonDisabled?: boolean;
  errorMessage?: string;
};

export type InvestmentUpdateProps = {
  socketData?: SocketData;
  signedSuccessfully?: boolean;
  history?: {
    push: Function;
  };
  isConfirmInvestmentScreen?: boolean;
  isPortfolioScreen?: boolean;
  options?: {
    [key in GateActions]?: GateOptions;
  };
  iraFundingInstructions?: string;
  iraPurchaseFailedInstructions?: string;
  shouldShowAlternativeCopy?: boolean;
};

export type InvestmentUpdateData = {
  date: string;
  title: string;
  description: string;
  expandable: boolean;
  button?: boolean;
  buttonCTA?: string;
  buttonLoading?: boolean;
  buttonDisabled?: boolean;
  onCTAClick?: Function;
  mobileNavigation?: string;
  error?: boolean;
  children?: null | ReactChild;
  startOpen?: boolean;
  passProps?: object;
  forcedOpen?: boolean;
  errorMessage?: string;
};

export type RequestReceivedProps = {
  socketDataPaymentMethod?: string;
  depositInitiated?: GateObject;
  wireTransfer: boolean;
  ACHPayment: boolean;
  rolloverUsed?: boolean;
  anticipatedSettlementDate?: string;
  promotion?: Promotion;
  shortName?: string;
};

export const allocationConfirmedAlternativeMonthlyCadenceCopy: Record<
  PaymentMethodType.WIRE | PaymentMethodType.ACH,
  { pizzaTracker: string }
> = {
  [PaymentMethodType.ACH]: {
    pizzaTracker:
      'We’ve initiated a withdrawal of {{amount}} from your linked bank account. Please note that if we do not receive your funds by the 15th of this month, your investment request will be canceled. If we receive your funds on time, your investment will be activated and reflected in your portfolio shortly thereafter. The effective date of your investment will be the 1st of the month, regardless of when funds were received.',
  },
  [PaymentMethodType.WIRE]: {
    pizzaTracker:
      'If you have not done so already, please wire {{amount}} to the instructions provided prior to the 15th of this month. Wires received after the 15th of this month will be returned, and your investment request will be canceled. If we receive your funds on time, your investment will be activated and reflected in your portfolio shortly thereafter. The effective date of your investment will be the 1st of the month, regardless of when funds were received.',
  },
};
