import { useUnitMigration } from './useUnitMigration';
import { useAuth } from '../../../../auth';
import { useEffect, useState } from 'react';

export const useUnitMigrationLaunch = (onLaunch: () => void) => {
  const { isJustAuthenticated, isAuthenticated, isLoginSession, isAuthReady } = useAuth();
  const { isMigrationActionable } = useUnitMigration();
  const [launched, setLaunched] = useState(false);

  const launchMigration =
    isJustAuthenticated && isLoginSession && isAuthReady && isMigrationActionable && !launched;

  useEffect(() => {
    if (launchMigration) {
      onLaunch();
      setLaunched(true);
    }
  }, [launchMigration, onLaunch, setLaunched]);

  useEffect(() => {
    if (!isAuthenticated && launched) {
      setLaunched(false);
    }
  }, [isAuthenticated, launched]);

  return launchMigration;
};
