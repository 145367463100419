import { styled } from '@mui/material/styles';

import { ModalAlignment, ModalWrapperyStyleProps } from './Modal.model';

export const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: 'auto',
  width: '100%',
  minHeight: '20vh',
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: '8px',
    width: 'fit-content',
  },

  [theme.breakpoints.up('md')]: {
    minWidth: '375px',
  },
}));

export const Modal = styled('div')(({ theme }) => ({
  zIndex: 1000,

  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(12),
  },
}));

export const ModalWrapper = styled('div')<ModalWrapperyStyleProps>(({ align, theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'auto',
  zIndex: '999',
  transition: 'all 0.2s ease',

  ...(align === ModalAlignment.centre
    ? {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }
    : {
        [theme.breakpoints.up('sm')]: {
          padding: `${theme.spacing(26)} 0`,
        },
      }),
}));
