import { ReactNode } from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

const { getTestIds } = genTestIds(['content', 'main-image', 'splash-image', 'title']);
export const testIds = getTestIds('side-photo-content');

export interface Image {
  imageBorder?: boolean;
}

export interface ImagePosition {
  imagePosition?: 'left' | 'right';
  removeImageMargin?: boolean;
  removePaddingSide?: boolean;
  reverseMobile?: boolean;
  small?: boolean;
}

export interface SidePhotoContentProps extends Image, ImagePosition {
  backgroundImage?: string | boolean;
  dismiss?: () => void;
  inverse?: boolean;
  mainImage?: ReactNode;
  removeFooterSpacing?: boolean;
  removeHeaderSpacing?: boolean;
  removeImageMargin?: boolean;
  removePaddingSide?: boolean;
  small?: boolean;
  splashImage?: ReactNode;
  title?: string;
}
