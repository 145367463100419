import { isNil } from 'lodash';
import { isPlainObject } from '@reduxjs/toolkit';
import { CreateApiOptions } from '@reduxjs/toolkit/query/react';

type CustomSerializeQuery = Parameters<
  Exclude<CreateApiOptions<any, any>['serializeQueryArgs'], undefined>
>[0];

export type CustomSerializeQueryExtraOptions<T> = {
  cacheKey?: (keyof T)[] | string;
};

export const customSerializeQuery = ({
  endpointName,
  queryArgs,
  endpointDefinition,
}: CustomSerializeQuery) => {
  const extraOptions: CustomSerializeQueryExtraOptions<any> =
    endpointDefinition?.extraOptions && (endpointDefinition.extraOptions as any)(queryArgs);

  let cacheKey = extraOptions ? extraOptions?.cacheKey : queryArgs;
  if (typeof cacheKey === 'symbol' || isNil(queryArgs)) {
    cacheKey = '';
  }

  // https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/defaultSerializeQueryArgs.ts
  return `${endpointName}(${JSON.stringify(cacheKey, (_key, value) =>
    isPlainObject(value)
      ? Object.keys(value)
          .sort()
          .reduce<any>((acc, key) => {
            acc[key] = (value as any)[key];
            return acc;
          }, {})
      : value
  )})`;
};
