import { InvestorAccountAiq } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeInvestorAccountAiq: ObjectAnonymizer<InvestorAccountAiq> = value =>
  anonymizeObject(value, {
    investorAccountName: 'string',
    investorFirstName: 'string',
    userDisplayName: 'string',
    documents: ['array', 'investorAccountAiqDocument'],
  });
