import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import 'react-tippy/dist/tippy.css';
import { LabelStyle } from '../../content/text/Label';
import { Tooltip } from 'react-tippy';
import { theme2 } from '../../themes';

export interface TagProps {
  description: string;
  name: string;
  length: number;
}

interface Props {
  tags: TagProps[];
  hasMoreTags?: boolean;
  hasCloseButton?: boolean;
  onShowMore?: (e: React.MouseEvent) => void;
  hasLegacyOfferingTags: boolean;
}

export const preventClick = (event: React.MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

export const Tags: FunctionComponent<Props> = ({
  tags = [],
  hasMoreTags = false,
  hasCloseButton = false,
  onShowMore = () => null,
  hasLegacyOfferingTags,
}) => {
  return (
    <>
      <TagsWrapper
        hasCloseButton={hasCloseButton}
        onClick={hasCloseButton ? preventClick : undefined}
      >
        {hasLegacyOfferingTags && tags.length > 0 ? (
          <>
            {tags.map(tag => (
              <BaseTag
                key={`tags-${hasMoreTags ? 'all' : 'summary'}-${tag}`}
                onClick={!hasCloseButton ? preventClick : undefined}
              >
                {tag}
              </BaseTag>
            ))}
          </>
        ) : (
          tags.length > 0 && (
            <>
              {tags.map((tag, index) => (
                <span key={index}>
                  {tag.description ? (
                    <BaseTag onClick={preventClick}>
                      <Tooltip
                        position="top"
                        trigger="mouseenter"
                        arrow
                        html={
                          <TooltipContent style={{ fontFamily: theme2.typography.primary }}>
                            {tag.description}
                          </TooltipContent>
                        }
                      >
                        {tag.name}
                      </Tooltip>
                    </BaseTag>
                  ) : (
                    <BaseTag>{tag.name}</BaseTag>
                  )}
                </span>
              ))}
            </>
          )
        )}
        {hasMoreTags && (
          <EllipsisTag
            data-cy="past-offerings-card-more-tags-ellipsis"
            onClick={onShowMore}
            title="See more tags"
          >
            ...
          </EllipsisTag>
        )}
      </TagsWrapper>
    </>
  );
};

const TooltipContent = styled.div`
  font-size: 13px;
  max-width: 200px;
`;

const TagsWrapper = styled.div<{ hasCloseButton: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: ${props => (props.hasCloseButton ? '20px 35px 20px 20px' : '0')};
`;

const EllipsisTag = styled.button`
  ${LabelStyle};
  background: ${props => props.theme.colors.background_page};
  cursor: pointer;
  padding: 5px 20px;
  word-break: initial;
  border: unset;
  text-align: center;
  overflow: hidden;
`;

export const BaseTag = styled.span`
  ${LabelStyle};
  background: ${props => props.theme.colors.background_page};
  padding: 5px 10px;
  word-break: initial;
  text-align: center;
  display: inline-block;
`;
