import { SnackbarProps, SnackbarTypes } from './Snackbar.model';

export const snackbarInfoMock: SnackbarProps = {
  key: 'info',
  title: '1 New Investment',
  description: 'Diversified Art Debt Portfolio II investment is now active.',
  testId: 'story-book-info',
  linkText: 'test',
  type: SnackbarTypes.Info,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {},
};

export const snackbarSuccessMock: SnackbarProps = {
  key: 'success',
  title: 'Success',
  description: 'Success.',
  testId: 'story-book-success',
  linkText: 'test',
  type: SnackbarTypes.Success,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {},
};

export const snackbarErrorMock: SnackbarProps = {
  key: 'error',
  title: 'Error',
  description: 'Error.',
  testId: 'story-book-error',
  linkText: 'test',
  type: SnackbarTypes.Error,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {},
};

export const snackbarWarningMock: SnackbarProps = {
  key: 'warning',
  title: 'Warning',
  description: 'Warning.',
  testId: 'story-book-warning',
  linkText: 'test',
  type: SnackbarTypes.Warning,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {},
};

export const snackbarListMock: SnackbarProps[] = [
  snackbarInfoMock,
  snackbarSuccessMock,
  snackbarErrorMock,
  snackbarWarningMock,
];
