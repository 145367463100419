import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
import { Heading, Paragraph } from '../../content/text';
import { PageSection } from '../../layout/page-section';

interface TableRow {
  description: string;
  value: string;
}

interface SideTablePosition {
  tablePosition?: 'left' | 'right';
}

interface SideTableContentProps extends SideTablePosition {
  title?: string;
  inverse?: boolean;
  tableRows: TableRow[];
  disclaimer?: string;
}

export const SideTableContent: FunctionComponent<SideTableContentProps> = props => {
  const { title, inverse, children, tablePosition, tableRows, disclaimer } = props;

  return (
    <PageSection>
      <Container tablePosition={tablePosition}>
        <HeadingContainer>
          <Title type={3} inverse={inverse}>
            {title}
          </Title>
          <Content>{children}</Content>
        </HeadingContainer>
        <TableContainer>
          {tableRows.map(tableRow => (
            <TableRow key={tableRow.value}>
              <Description type={5}>{tableRow.description}</Description>
              <Value>{tableRow.value}</Value>
            </TableRow>
          ))}
          {disclaimer && <Disclaimer small>{disclaimer}</Disclaimer>}
        </TableContainer>
      </Container>
    </PageSection>
  );
};

const Container = styled.div<SideTablePosition>`
  display: flex;
  padding: 30px 20px 40px;
  flex-direction: column;
  justify-content: space-between;
  ${media.tablet`
    padding: 30px 20px 40px;
  `}
  ${media.desktop<SideTablePosition>`
  flex-direction: ${props => (props.tablePosition === 'right' ? 'row' : 'row-reverse')};
    padding: 30px 110px 40px;
  `}
`;

const HeadingContainer = styled.div`
  margin-top: 10px;
  ${media.desktop`
    margin-left: 20px;
  `}
`;

const Title = styled(Heading)`
  margin-bottom: 40px;
  ${media.desktop`
    width: 75%;
  `}
`;

const Content = styled.div`
  ${media.desktop`
    width: 80%;
  `}
`;

const TableContainer = styled.div`
  ${media.desktop`
    flex-basis: 60%;
    margin-right: 20px;
  `}
`;

const TableRow = styled.div`
  background: ${props => props.theme.colors.table_row_background};
  :nth-child(even) {
    background: ${props => props.theme.colors.table_row_background_alt};
    border-top: 1px solid ${props => props.theme.colors.border_default};
    border-bottom: 1px solid ${props => props.theme.colors.border_default};
  }
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.tablet`
    padding: 25px;
  `}
`;

const Description = styled(Heading)`
  flex-basis: 60%;
  ${media.tablet`
    flex-basis: unset;
  `}
`;

const Value = styled(Paragraph)`
  flex-basis: 20%;
  text-align: right;
  align-self: center;
  ${media.tablet`
    flex-basis: unset;
  `}
`;

const Disclaimer = styled(Paragraph)`
  margin-top: 40px;
`;
