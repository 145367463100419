import { Address } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeAddress: ObjectAnonymizer<Address> = value =>
  anonymizeObject(value, {
    addressLine1: 'string',
    addressLine2: 'string',
    city: 'string',
    state: 'string',
    postalCode: 'string',
  });
