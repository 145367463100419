import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums';
import { DeleteUserBeneficiaryParams } from './user-beneficiaries.model';

export const userBeneficiariesApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserBeneficiaries],
  })
  .injectEndpoints({
    endpoints: builder => {
      const deleteUserBeneficiary = builder.mutation<{}, DeleteUserBeneficiaryParams>({
        query: ({ userId }) => ({
          url: `/a/api/user/${userId}/beneficiary`,
          method: HttpMethods.Delete,
        }),
        invalidatesTags: [Tags.UserBeneficiaries],
      });

      return {
        deleteUserBeneficiary,
      };
    },
  });
