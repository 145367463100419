import { MaybeSizeMap, PartialSizeMap } from '../../../utils/sizes';
import isNil from 'lodash/isNil';

export type GridConfig = {
  size: MaybeSizeMap<number>;
  gutter: MaybeSizeMap<number>;
  spacing: MaybeSizeMap<number>;
};

export type PartialGridConfig = PartialSizeMap<GridConfig>;

export type GridTheme = {
  grid: PartialGridConfig;
};

const defaultConfig: GridConfig = {
  size: 12,
  gutter: 20,
  spacing: 0,
};

export const getConfig = <K extends keyof GridConfig>(
  theme: Partial<GridTheme> = {},
  key: K
): GridConfig[K] => {
  if (theme.grid && !isNil(theme.grid[key])) {
    return theme.grid[key]! as GridConfig[K];
  }
  return defaultConfig[key];
};
