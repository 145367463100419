import React from 'react';
import { TextInput, TextInputProps } from './TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

interface SearchTextInputProps extends TextInputProps {
  showClearIcon?: boolean;
  onClearSearch?: () => void;
}

export const SearchTextInput: React.FunctionComponent<SearchTextInputProps> = props => {
  const { showClearIcon, onClearSearch } = props;
  return (
    <TextInput
      {...props}
      hiddenLabel
      prefix={<SFontAwesomeIcon icon={faSearch} size="sm" title="search icon magnifying glass" />}
      suffix={
        showClearIcon && (
          <ClearIcon icon={faTimes} size="sm" title="clear search icon" onClick={onClearSearch} />
        )
      }
    />
  );
};

const SFontAwesomeIcon = styled(FontAwesomeIcon)`
  max-height: 14px;
`;

const ClearIcon = styled(SFontAwesomeIcon)`
  cursor: pointer;
`;
