import styled, { css } from 'styled-components';

import { media } from '../../themes';
import { Heading, Paragraph } from '../../content';
import { SectionContainer } from '../../layout';

import { AdvantagesSectionProps } from './AdvantagesSection.model';

export const SSectionContainer = styled(SectionContainer)`
  padding: 0;

  ${media.desktop`
    padding: 0 var(--sectionContainerPaddingHorizontal);
  `}
`;

const responsivePadding = css<{ hasSmallItems?: boolean }>`
  ${({ hasSmallItems }) => {
    const mediaSize = hasSmallItems ? 'large' : 'desktop';
    return css`
      ${media[mediaSize]`
          ${props => `padding: 0 ${props.theme.spacing['2xl']}`}
        `}
    `;
  }}
`;

export const Container = styled.div<{
  hasPadding?: boolean;
  hasSmallItems?: boolean;
}>`
  ${props => props.hasPadding && responsivePadding}
`;

export const TextWrapper = styled.div<Pick<AdvantagesSectionProps, 'align'>>`
  max-width: 720px;
  ${props => props.align === 'center' && 'margin: 0 auto'}
`;

export const SParagraph = styled(Paragraph)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.xs};
    margin-bottom: ${theme.spacing.sm};

    ${media.desktop`
      margin-bottom: ${theme.spacing.m};
    `}
  `}
`;

export const SHeading = styled(Heading)`
  color: ${props => props.theme.colors.text_label};
`;

export const AdvantagesContainer = styled.div<{ hasSmallItems: boolean }>`
  ${({ theme, hasSmallItems }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing.l};
    margin-top: ${theme.spacing.l};

    ${media.desktop`
      display: grid;
      grid-template-columns: ${hasSmallItems ? 'repeat(4, 232px)' : 'repeat(3, 270px)'};
      margin-top: ${theme.spacing.ml};
      justify-content: space-between;
      row-gap: ${theme.spacing.xl};
    `}

    ${media.large`
      column-gap: ${theme.spacing.xl};
    `}
  `}
`;

export const Advantage = styled.div`
  ${media.desktop`
    display: flex;
    flex-direction: column;
  `}
`;

export const Icon = styled.img<Pick<AdvantagesSectionProps, 'iconsSize'>>`
  margin-bottom: ${props => props.theme.spacing.sm};
  width: ${props => props.iconsSize};
`;

export const Stat = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: ${theme.spacing.sm};
    border-top: 1px solid ${theme.colors.border_default};
    padding-top: ${theme.spacing.sm};
    align-items: center;
    word-break: normal;

    ${media.desktop`
      padding-top: ${theme.spacing.m};
      margin-top: auto;
    `}
  `}
`;
