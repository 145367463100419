import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import { MarketplaceMetricsResponse } from './mareketplace-metrics.model';

export const marketplaceMetricsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.MarketplaceMetrics],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getSingleOfferingMetrics = builder.query<MarketplaceMetricsResponse, string>({
        query: noteUrlHash => ({
          url: `/a/api/marketplace/metrics/${noteUrlHash}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.MarketplaceMetrics],
      });

      return {
        getSingleOfferingMetrics,
      };
    },
  });
