import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import trashIcon from './icons/trash.svg';

import { FilePreview } from './FileUpload';
import { Spinner } from '../../layout/spinner';
import { ParagraphLink } from '../../content/text';
import { Notification } from '../../elements/notification';
import { useTestIds } from './FilePreviewDocument.model';
import { BaseProps } from '../..';

type PreviewImgProps = {
  error?: boolean;
};

type FilePreviewImageProps = {
  file: FilePreview;
  onRemoveClick?: () => void;
} & BaseProps;

export const FilePreviewImage: FunctionComponent<FilePreviewImageProps> = props => {
  const { file, onRemoveClick } = props;

  const testIds = useTestIds(props);

  return (
    <>
      <PreviewWrapper key={file.id || file.name} {...testIds.base.attr}>
        <PreviewImg src={file.preview} error={file.error} />
        {file.sending ? (
          <Spinner testId={testIds.loading.id} />
        ) : (
          <DeleteWrapper>
            <TrashIcon src={trashIcon} alt="" />
            <DeleteLink onClick={onRemoveClick} {...testIds.remove.attr}>
              Upload new photo
            </DeleteLink>
          </DeleteWrapper>
        )}
      </PreviewWrapper>
      {file.error && file.errorDescription && (
        <Notification
          testId={testIds.error.id}
          inline
          severity="error"
          title={file.errorTitle || ''}
          description={
            file.errorDescription ||
            'An error has occurred. Please try again, and if the problem persists, contact us.'
          }
        />
      )}
    </>
  );
};

const PreviewWrapper = styled.div`
  margin: 20px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DeleteWrapper = styled.div`
  margin-top: 20px;
`;

const DeleteLink = styled.span`
  ${ParagraphLink};

  margin-left: 10px;
`;

const PreviewImg = styled.img<PreviewImgProps>`
  display: block;
  max-width: 400px;
  object-fit: contain;
  width: 100%;

  ${props => props.error && `border: 2px solid ${props.theme.colors.notice_error};`}
`;

const TrashIcon = styled.img`
  cursor: pointer;
  vertical-align: middle;
`;

export default FilePreviewImage;
