import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { kebabCase, isEmpty } from 'lodash';

import { MODAL_CONTEXT_INITIAL_STATE } from './Modal.const';
import { ModalContext } from './Modal.context';
import { PassProps } from './Modal.model';

export const ModalProvider = ({ history, children }: { history: any; children: ReactNode }) => {
  const [currentModalId, setCurrentModalId] = useState(MODAL_CONTEXT_INITIAL_STATE.currentModalId);
  const [passProps, setPassProps] = useState<PassProps>(MODAL_CONTEXT_INITIAL_STATE.passProps);

  useEffect(() => {
    const handleLocation = () => {
      const { hash } = history?.location || {};
      if (hash) {
        const modalIdFormatted = kebabCase(hash.substring(1));
        setCurrentModalId(modalIdFormatted);
      } else if (!hash && currentModalId) {
        setCurrentModalId(MODAL_CONTEXT_INITIAL_STATE.currentModalId);
      }
    };

    handleLocation();

    const unsubNavListener = history?.listen(handleLocation);

    return () => {
      unsubNavListener();
    };
  }, [history, currentModalId]);

  const showModal = useCallback(
    (modalId: string, modalProps: PassProps = {}) => {
      const { pathname, search } = history.location;
      const modalIdFormatted = kebabCase(modalId);
      const url = `${pathname}${search}#${modalIdFormatted}`;

      history.navigate ? history.navigate(url) : history.push(url);

      if (!isEmpty(modalProps)) {
        setPassProps(modalProps);
      }
    },
    [history]
  );

  const hideModal = useCallback(
    (modalId?: string) => {
      if (!modalId || (modalId && modalId === currentModalId)) {
        const url = history.location.pathname + history.location.search;
        history.navigate ? history.navigate(url) : history.push(url);

        setPassProps(MODAL_CONTEXT_INITIAL_STATE.passProps);
      }
    },
    [history, currentModalId]
  );

  const value = useMemo(
    () => ({
      currentModalId,
      hideModal,
      isModalVisible: !!currentModalId,
      passProps,
      showModal,
    }),
    [currentModalId, hideModal, passProps, showModal]
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
