import React from 'react';
import { Heading, Paragraph, TextBlock, useModalContext } from '@yieldstreet/ui-kit';

import heroImage from 'images/home/home-hero.png';
import { InvestButton } from 'components/ui/InvestButton/InvestButton';
import { heroStats } from './Hero.model';
import {
  Background,
  Image,
  SContainer,
  TextBlockContainer,
  ImageContainer,
  StatItem,
  StatsWrapper,
} from './Hero.style';

export const Hero = () => {
  const { showModal } = useModalContext();
  return (
    <Background>
      <SContainer>
        <TextBlockContainer>
          <Heading type={4} tagType={4}>
            Yieldstreet Alternative Income Fund
          </Heading>
          <TextBlock
            inverse
            headingTagType={1}
            headingType={1}
            heading={`A private market ${'\n'}fund built for income`}
            content={
              <Paragraph inverse secondary>
                Gain exposure to a wide array of private market investments
                <br /> with a Fund designed to distribute quarterly income.
              </Paragraph>
            }
          />
          <StatsWrapper>
            {heroStats.map(({ label, value, modalId, footNote }) => (
              <StatItem
                key={value}
                onClick={() => showModal(modalId)}
                tabIndex={-1}
                hasModal={modalId !== ''}
              >
                <Paragraph inverse secondary marginBottom="xs" size="small">
                  {label}
                </Paragraph>
                <Heading type={3} tagType={3} inverse>
                  {value}
                </Heading>
                {footNote && (
                  <Paragraph size="small" inverse secondary marginTop="xs">
                    {footNote}
                  </Paragraph>
                )}
              </StatItem>
            ))}
          </StatsWrapper>

          <InvestButton />
        </TextBlockContainer>
        <ImageContainer>
          <Image src={heroImage} />
        </ImageContainer>
      </SContainer>
      <Paragraph
        size="small"
        inverse
        secondary
        padding={{ mobile: 'sm', tablet: 's', desktop: 'xxs' }}
      >
        Fund formerly known as the Yieldstreet Prism Fund. <br />
        No offers will be made to or accepted from investors residing in or located in Nebraska or
        North Dakota.
      </Paragraph>
    </Background>
  );
};
