import styled from 'styled-components';
import { media } from '../../themes';

export const TabListContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  ${props =>
    props.vertical &&
    `
    row-gap: ${props.theme.spacing.xs};
    width: 100%;
  `}
  ${media.large<{ vertical?: boolean }>`
    ${props =>
      props.vertical &&
      `
      row-gap: ${props.theme.spacing.m};
      width: unset;
    `}
  `}
  overflow-y: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  a {
    background-image: none;
  }

  a:active {
    background-image: none;
  }
`;

export const TabListItem = styled.div<{ vertical?: boolean }>`
  ${props => props.vertical && `border-bottom: 1px solid ${props.theme.colors.border_default};`}

  ${media.large<{ vertical?: boolean }>`
    border-bottom: unset;
  `}

  flex-shrink: 0;

  &:not(:last-child) {
    margin: 0 8px 0 0;
    display: flex;
  }

  ${props =>
    props.vertical
      ? `
      &:last-child {
        margin: 0 8px 0 0;
        display: flex;
      }
      `
      : `
      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }
  `}

  ${media.large`
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  `}
`;
