import React from 'react';
import { BaseInputProps, genTestIds } from '@yieldstreet/tool-kit';

export interface SelectInputOption {
  value: string;
  label: React.ReactNode;
  disabled?: boolean;
}

export interface SelectInputProps extends BaseInputProps<string> {
  onChange?: (value: string, option: SelectInputOption) => void;
  open?: boolean;
  options: SelectInputOption[];
  allowNone?: boolean;
  hiddenLabel?: boolean;
  fullWidth?: boolean;
  width?: number | string;
  hideIfSingleOption?: boolean;
  name?: string;
}

export interface ArrowProps {
  iconFacingUp?: boolean;
}

export const { getTestIds, useTestIds } = genTestIds([
  'label',
  'input',
  'arrow',
  'option',
  'option-empty',
  'option-disabled',
  'error',
]);
