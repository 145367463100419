import { InvestorEntityStepVerificationStatus } from '../../enums';

export enum InvestorEntityStepProvider {
  YS = 'YS',
  UNIT = 'UNIT',
  PLAID = 'PLAID',
  ETC = 'ETC',
  FOOTPRINT = 'FOOTPRINT',
}

export type InvestorEntityIdentityProvider =
  | InvestorEntityStepProvider.YS
  | InvestorEntityStepProvider.ETC
  | InvestorEntityStepProvider.UNIT
  | InvestorEntityStepProvider.FOOTPRINT
  | InvestorEntityStepProvider.PLAID;

export type InvestorEntityBankLinkingProvider =
  | InvestorEntityStepProvider.YS
  | InvestorEntityStepProvider.PLAID;

export type InvestorEntityAccreditationProvider =
  | InvestorEntityStepProvider.YS
  | InvestorEntityStepProvider.FOOTPRINT;

export type InvestorEntityStatusProvider =
  | InvestorEntityStepProvider.YS
  | InvestorEntityStepProvider.PLAID;

export type InvestorEntityStepVerificationDetails<Provider extends InvestorEntityStepProvider> = {
  status: InvestorEntityStepVerificationStatus;
  statusDisplay: string;
  pendingActions: string[];
  message: string | null;
  entityType: string | null;
  provider: Provider;
};
