import {
  BankLinkProviderMigrationInvestorDetails,
  KYCProviderMigrationInvestorDetails,
} from '@yieldstreet/platform-kit';

export enum ProviderMigrationStatus {
  KYC_REQUIRED = 'KYC_REQUIRED',
  KYC_INCOMPLETE = 'KYC_INCOMPLETE',
  KYC_PENDING = 'KYC_PENDING',
  KYC_FAILED = 'KYC_FAILED',
  KYC_ERROR = 'KYC_ERROR',
  BANK_LINK_REQUIRED = 'BANK_LINK_REQUIRED',
  DEFAULT_BANK_REQUIRED = 'DEFAULT_BANK_REQUIRED',
  COMPLETED = 'COMPLETED',
  PENDING_MICRO_DEPOSIT = 'PENDING_MICRO_DEPOSIT',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  COMING_SOON = 'COMING_SOON',
}

const ongoingStatuses = [
  ProviderMigrationStatus.KYC_INCOMPLETE,
  ProviderMigrationStatus.KYC_PENDING,
  ProviderMigrationStatus.KYC_FAILED,
  ProviderMigrationStatus.KYC_ERROR,
  ProviderMigrationStatus.BANK_LINK_REQUIRED,
  ProviderMigrationStatus.DEFAULT_BANK_REQUIRED,
];

const actionableStatuses = [
  ProviderMigrationStatus.KYC_REQUIRED,
  ProviderMigrationStatus.KYC_INCOMPLETE,
  ProviderMigrationStatus.KYC_FAILED,
  ProviderMigrationStatus.KYC_ERROR,
  ProviderMigrationStatus.BANK_LINK_REQUIRED,
  ProviderMigrationStatus.DEFAULT_BANK_REQUIRED,
  ProviderMigrationStatus.PENDING_MICRO_DEPOSIT,
];

const eligibleStatuses = [
  ...actionableStatuses,
  ProviderMigrationStatus.PENDING_MICRO_DEPOSIT,
  ProviderMigrationStatus.KYC_PENDING,
  ProviderMigrationStatus.COMPLETED,
];

const pendingStatuses = [ProviderMigrationStatus.PENDING_MICRO_DEPOSIT];

const finalStatuses = [
  ProviderMigrationStatus.NOT_APPLICABLE,
  ProviderMigrationStatus.COMING_SOON,
  ProviderMigrationStatus.COMPLETED,
];

const problemStatuses = [ProviderMigrationStatus.KYC_FAILED, ProviderMigrationStatus.KYC_ERROR];

export const statusTypes = {
  ongoing: ongoingStatuses,
  actionable: actionableStatuses,
  eligible: eligibleStatuses,
  final: finalStatuses,
  problem: problemStatuses,
  pending: pendingStatuses,
};

export type ProviderMigrationConfig = {
  id: string;
  name: string;
  type: string;
  status: ProviderMigrationStatus;
  ongoing: boolean;
  actionable: boolean;
  eligible: boolean;
  final: boolean;
  problem: boolean;
  pending: boolean;
  kycDetails?: KYCProviderMigrationInvestorDetails;
  bankLinkDetails?: BankLinkProviderMigrationInvestorDetails;
};
