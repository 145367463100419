import { MuiAlert } from './muiAlert';
import { MuiButton } from './muiButton';
import { MuiCssBaseline } from './muiCssBaseline';
import { MuiFilledInput } from './muiFilledInput';
import { MuiLink } from './muiLink';
import { MuiLoadingButton } from './muiLoadingButton';
import { MuiSelect } from './muiSelect';
import { MuiTextField } from './muiTextField';
import { MuiTypography } from './muiTypography';
import { MuiAccordion } from './muiAccordion';

export const components = {
  MuiAlert,
  MuiButton,
  MuiCssBaseline,
  MuiFilledInput,
  MuiLink,
  MuiLoadingButton,
  MuiSelect,
  MuiTextField,
  MuiTypography,
  MuiAccordion,
};
