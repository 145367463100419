export const MODAL_BODY = 'modal-body';
export const MODAL_CLOSE_BUTTON = 'modal-close-button';
export const MODAL_CONTENT = 'modal-content';
export const MODAL_OVERLAY = 'modal-overlay';
export const MODAL_PORTAL = 'modal-portal';

export const MODAL_CONTEXT_INITIAL_STATE = {
  currentModalId: '',
  hideModal: () => {},
  isModalVisible: false,
  passProps: {},
  showModal: () => {},
};
