import React, { FC } from 'react';

import { Spinner } from '../../layout/spinner';

import { ButtonProps } from './Button.model';
import {
  ButtonContainer,
  ButtonContent,
  ButtonIcon,
  ButtonText,
  SpinnerWrapper,
} from './Button.style';

export const Button: FC<ButtonProps> = props => {
  const {
    trackingId,
    buttonType = 'primary',
    loading,
    icon,
    iconSide = 'left',
    ...allProps
  } = props;

  return (
    <ButtonContainer
      data-cy={`ui-kit-button-container${trackingId ? `-${trackingId}` : ''}`}
      id={trackingId}
      buttonType={buttonType}
      {...allProps}
    >
      <ButtonContent
        data-cy="ui-kit-button-content"
        className="ui-kit-button-content"
        buttonLoading={loading}
      >
        {icon && (
          <ButtonIcon iconSide={iconSide} disabled={props.disabled}>
            {icon}
          </ButtonIcon>
        )}
        <ButtonText tabIndex={-1} className="ui-kit-button-text">
          {props.children}
        </ButtonText>
      </ButtonContent>
      {loading && (
        <SpinnerWrapper>
          <Spinner
            className="spinner"
            small
            inverse={buttonType === 'basic' ? !props.inverse : props.inverse}
            disabled={props.disabled}
          />
        </SpinnerWrapper>
      )}
    </ButtonContainer>
  );
};
