import { SignupUsualInvestingWayValues } from '@yieldstreet/platform-kit';

export const SignupUsualInvestingWayOptions = [
  {
    value: SignupUsualInvestingWayValues.ACTIVELY_MANAGE_MY_PORTFOLIO,
    label: 'Actively manage my portfolio',
    description: 'Example: Buy individual stocks and bonds',
  },
  {
    value: SignupUsualInvestingWayValues.PASSIVELY_MANAGE_MY_PORTFOLIO,
    label: 'Passively manage my portfolio',
    description: 'Example: Invest in ETFs or mutual funds',
  },
  {
    value: SignupUsualInvestingWayValues.PARTNER_WITH_A_FINANCIAL_ADVISOR,
    label: 'Partner with a financial advisor',
  },
];

export const SignupUsualInvestingWayLabels = {
  title: 'How do you typically invest?',
};
