import styled, { css } from 'styled-components';
import { media } from '../../themes/media';
import { NotificationStyleProps, NotificationTypeEnum } from './Notification.model';

const getSeverityCss = (severity: NotificationStyleProps['severity']) => {
  switch (severity) {
    case NotificationTypeEnum.SUCCESS:
      return css`
        background-color: ${props => props.theme.colors.notice_success};
      `;
    case NotificationTypeEnum.WARNING:
      return css`
        background-color: ${props => props.theme.colors.notice_warning_background};
      `;
    case NotificationTypeEnum.ERROR:
      return css`
        background-color: ${props => props.theme.colors.notice_error};
      `;
    case 'info':
    default:
      return css`
        background-color: ${props => props.theme.colors.border_default};
      `;
  }
};

export const NotificationIcon = styled.img`
  height: 20px;
`;

export const NotificationAction = styled.div``;

export const NotificationCloseButton = styled.div``;

export const NotificationText = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  color: ${props => props.theme.colors.text_header};
  font-family: ${props => props.theme.typography.primary};
  font-size: 16px;

  span {
    padding-right: 5px;
  }

  #notification-external-link {
    width: fit-content;
  }
`;

export const NotificationTextFlex = styled.div`
  display: inline-flex;
  flex-flow: row wrap;
  font-size: 14px;
  word-break: break-word;

  ${media.desktop`
    display: inline;
    font-size: 16px;
    padding-top: 0;
  `}
`;

export const NotificationTitle = styled.span`
  font-weight: 500;
`;

export const NotificationDescription = styled.span``;

export const NotificationInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 10px;
`;

export const NotificationWrapper = styled.div<NotificationStyleProps>`
  border-radius: 0;
  padding: ${props => props.theme.spacing.m};
  margin: ${props => (props.noMargin ? 0 : '0 auto 20px')};

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  ${props =>
    props?.inline
      ? `
    justify-content: center;
    align-content: center;
    align-items: center;
    `
      : `
    justify-content: space-between;
    align-content: center;
    align-items: center;
  `}

  ${props => (props?.severity ? getSeverityCss(props.severity) : '')}
  ${props =>
    props.width ? `width: ${props.width}` : `max-width: ${props.inline ? '1440px' : '1180px'}`};

  ${NotificationIcon} {
    align-items: ${props => props.iconAlignment};
    ${props => (props.inline ? media.desktop` margin-left: auto;` : '')}
  }

  ${NotificationAction} {
    align-self: ${props => props.iconAlignment};
    ${props =>
      props.iconAlignment !== 'center' &&
      `
        margin: 0;
        padding-left: 5px;
    `}
  }

  ${NotificationCloseButton} {
    display: flex;
    border: none;
    cursor: pointer;
  }
`;
