import { BeneficialOwner } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeBeneficialOwner: ObjectAnonymizer<BeneficialOwner> = value =>
  anonymizeObject(value, {
    firstName: 'string',
    lastName: 'string',
    email: 'string',
  });
