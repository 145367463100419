import styled, { css } from 'styled-components';

import { ArrowProps } from './SelectInput.model';

export const SelectMenuStyles = css`
  &.MuiPaper-root {
    box-shadow: none;
    border-radius: ${({ theme }) => `0 0 ${theme.radius.md} ${theme.radius.md}`};
    border: 1px solid ${props => props.theme.colors.border_default};
    border-top: 0;

    & .MuiMenu-list {
      padding: 0;

      & .MuiMenuItem-root {
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: ${props => props.theme.typography.secondary};
        background-color: ${props => props.theme.colors.background_card};

        &:not(:last-child) {
          border-bottom: 1px solid ${props => props.theme.colors.border_default};
        }

        &:hover {
          border-left: ${props => props.theme.colors.border_outline};
          border-left-style: solid;
          border-left-width: 2px;
        }

        &.Mui-selected {
          border-left: ${props => props.theme.colors.active};
          border-left-style: solid;
          border-left-width: 2px;
        }
      }
    }
  }
`;

export const Arrow = styled.img<ArrowProps>`
  color: ${props => props.theme.colors.text_paragraph};
  right: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  ${props => (props.iconFacingUp ? 'transform: rotate(180deg);' : '')}
`;
