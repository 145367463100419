import React from 'react';
import { ExternalLink } from '@yieldstreet/ui-kit';

import { Logo } from './Logo';
import { InvestButton } from 'components/ui/InvestButton/InvestButton';
import { Wrapper, Container, Flex, LogoWrap, RightButtonWrapper } from './Header.style';

export const SimpleHeader: React.FunctionComponent = () => {
  return (
    <Wrapper data-cy="simple-header">
      <Container>
        <Flex>
          <LogoWrap>
            <ExternalLink href="/">
              <Logo />
            </ExternalLink>
          </LogoWrap>
        </Flex>
        <RightButtonWrapper>
          <InvestButton buttonType="basic" inverse small />
        </RightButtonWrapper>
      </Container>
    </Wrapper>
  );
};
