import React from 'react';
import styled from 'styled-components';

import { media } from '../../themes';

import closeIcon from './closeIcon.svg';

interface CloseButtonProps {
  onClick: () => void;
  'data-cy'?: string;
  className?: string;
}

export const CloseButton = ({ onClick, 'data-cy': dataCy, className }: CloseButtonProps) => {
  return (
    <Wrapper data-cy={dataCy} onClick={onClick} className={className}>
      <CloseIcon src={closeIcon} alt="close-icon" />
    </Wrapper>
  );
};

const Wrapper = styled.button`
  background: ${({ theme }) => theme.colors.input_background};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 36px;
  width: 36px;
  position: fixed;
  top: ${({ theme }) => theme.spacing.m};
  right: ${({ theme }) => theme.spacing.m};
  box-shadow: ${props => props.theme.shadows.elevation_1};
  z-index: 2000;

  ${media.phoneLandscape`
    margin: unset;
    margin-right: -50px;
    top: unset;
    right: unset;
  `}
`;

const CloseIcon = styled.img`
  vertical-align: middle;
`;
