import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Background = styled('div')<{ withPadding?: boolean }>(({ theme }) => ({
  background: theme.palette.background.page.product,
  margin: `-${theme.spacing(4)}`,
  marginTop: 0,
  padding: theme.spacing(4),
}));

export const ChartContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  marginTop: 0,
  borderRadius: '8px',
  background: theme.palette.background.default,
  zIndex: '1000',

  [theme.breakpoints.up('lg')]: {
    position: 'static',
  },
}));

export const ControlsHolder = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  background: theme.palette.background.page.product,

  [theme.breakpoints.up('lg')]: {
    padding: 0,
  },
}));

export const Disclaimer = styled(Typography)(({ theme }) => ({
  display: 'block',
  padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(8)}`,
  background: theme.palette.background.page.product,

  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(5)} 0 0 0`,
  },
}));
