import React, { createContext } from 'react';

import { MODAL_CONTEXT_INITIAL_STATE } from './Modal.const';
import { ModalInterface } from './Modal.model';
import { useModalContext } from './hooks/useModalContext';

export const ModalContext = createContext<ModalInterface>({ ...MODAL_CONTEXT_INITIAL_STATE });

/**
 * @deprecated should not encourage using class components
 */
export const withModalContext = <P extends object>(Component: React.ComponentType<P>) => {
  const WrappedComponent = (props: any) => {
    const modalContext = useModalContext();
    return <Component {...props} {...modalContext} />;
  };

  return WrappedComponent;
};
