import { useMemo } from 'react';
import { InvestorEntity, EntityType, IdentityDocumentType } from '@yieldstreet/platform-kit';

export const getInvestorEntityType = (investorEntity: InvestorEntity) => {
  const { type } = investorEntity;
  const { entityType, identityDocumentType, iraAccountType, value } = type;

  if (['INDV'].includes(value)) {
    return 'individual';
  } else if (entityType === EntityType.IRA) {
    return 'sdira';
  } else if (
    entityType &&
    [
      EntityType.LLC,
      EntityType.SOLE_PROPRIETOR,
      EntityType.PARTNERSHIP,
      EntityType.S_CORPORATION,
      EntityType.C_CORPORATION,
    ].includes(entityType)
  ) {
    return 'entity';
  } else if (identityDocumentType === IdentityDocumentType.SSN && entityType === EntityType.TRUST) {
    return 'trust-with-ssn';
  } else if (iraAccountType) {
    return 'ira';
  } else if (
    !iraAccountType &&
    identityDocumentType === IdentityDocumentType.EIN &&
    entityType === EntityType.TRUST
  ) {
    return 'trust-with-ein';
  }

  return 'individual';
};

export const useEntityType = (investorEntity: InvestorEntity) => {
  return useMemo(() => getInvestorEntityType(investorEntity), [investorEntity]);
};
