import { restApi, Tags } from '../../common';
import { HttpMethods } from '../../../enums';

import {
  PortfolioSelfAudienceApiRequest,
  PortfolioSelfAudienceApiResponse,
} from './audience.model';

export const audienceApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioSelfAudience],
  })
  .injectEndpoints({
    endpoints: build => {
      const getPortfolioSelfAudience = build.query<
        PortfolioSelfAudienceApiResponse,
        PortfolioSelfAudienceApiRequest
      >({
        query: ({ userId }: PortfolioSelfAudienceApiRequest) => ({
          url: `/api/portfolio/self/${userId}/hasSelfPortfolioAudience`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.PortfolioSelfAudience],
      });

      return {
        getPortfolioSelfAudience,
      };
    },
  });
