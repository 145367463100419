import React from 'react';

import { SurfaceProps, SurfaceType } from './Surface.model';
import { SurfaceWrapper } from './Surface.style';

// FIX ME: convert to mui paper and move to core
export const Surface = ({
  children,
  className,
  small = false,
  type = SurfaceType.Default,
  testId,
  withPadding = true,
}: SurfaceProps) => {
  return (
    <SurfaceWrapper
      className={className}
      data-cy={testId}
      small={small}
      type={type}
      withPadding={withPadding}
    >
      {children}
    </SurfaceWrapper>
  );
};
