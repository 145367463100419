import isEmpty from 'lodash/isEmpty';
import numeral from 'numeral';

const valueRegex = /[^0-9,.][,\s]+|[,\s]+/g;
const digitRegex = /\D+/g;
const numberRegex = /[0-9]+/g;

const defaultRequiredText = 'This field is required.';
const digitOnlyText = 'This field should have digits only.';
const numberOnlyText = 'This field should have numbers only.';
const stringOnlyText = 'This field does not support numbers.';

const getMinCharText = (min: number) => `This field has a minimum length of ${min} characters.`;
const getMaxCharText = (max: number) => `This field has a maximum length of ${max} characters.`;
const getMinDigitText = (min: number) => `This field has a minimum length of ${min} digits.`;
const getMaxDigitText = (max: number) => `This field has a maximum length of ${max} digits.`;

const getExactCharText = (length: number) => `This field should have ${length} characters.`;
const getExactDigitText = (length: number) => `This field should have ${length} digits.`;
const getAmountLimitText = (amount: number) =>
  `This field must be less than ${numeral(amount).format('$0,0.00')}`;
const getMinAmountText = (amount: number) => `This field value should be greater than ${amount}.`;

const getInputNumber = (value: string) => parseFloat(value.replace(valueRegex, ''));

const rules = {
  accountNumber: { max: 32, min: 4 },
  address: {
    max: 32,
  },
  amount: {
    min: 0,
    regex: valueRegex,
    validation: (val: string = '') => {
      if (val) {
        return getInputNumber(val) > 0;
      }
      return true;
    },
  },
  amount1T: {
    max: 1000000000000,
    regex: valueRegex,
    validation: (val: string = '') => {
      if (val) {
        return getInputNumber(val) < 1000000000000;
      }
      return true;
    },
  },
  bankRoutingNumber: { length: 9 },
  country: {
    length: 2,
    validation: (val: string = '') => val.toUpperCase() === 'US',
  },
  crdDocument: {
    min: 6,
    max: 7,
  },
  dateEntity: {
    min: 100,
    max: 1,
  },
  dateOfRecord: {
    min: 100,
    max: 18,
  },
  idDocument: { length: 9 },
  email: {},
  entityType: { min: 2, max: 30 },
  extendedName: {
    min: 2,
    max: 200,
    regex: digitRegex,
  },
  initials: { min: 2, max: 8 },
  name: {
    min: 2,
    max: 30,
    regex: numberRegex,
    validation: (val: string = '') => {
      if (!isEmpty(val) && val.match(numberRegex)) {
        return false;
      }
      return true;
    },
  },
  password: {
    min: 8,
    validation: (val: string = '') => {
      if (!val.match(/[a-z]/)) {
        return 'This field must have a lowercase letter.';
      } else if (!val.match(/[A-Z]/)) {
        return 'This field must have an uppercase letter.';
      }
      return null;
    },
  },
  phone: {
    length: 10,
    regex: digitRegex,
    validation: (val: string = '') => {
      if (val) {
        const phone = val.replace(digitRegex, '');

        return phone.length >= 10;
      }
      return false;
    },
  },
  state: {
    length: 2,
  },
  textArea: { min: 2, max: 300 },
  zipCode: { min: 4, max: 9 },
};

export const formRules = {
  defaultRequiredText,
  digitOnlyText,
  numberOnlyText,
  stringOnlyText,
  getMinCharText,
  getMaxCharText,
  getMinDigitText,
  getMaxDigitText,
  getExactCharText,
  getExactDigitText,
  getAmountLimitText,
  getMinAmountText,
  getInputNumber,
  rules,
};
