export enum AssetClassLabel {
  RealEstate = 'Real Estate',
  VentureCapital = 'Venture Capital',
  PrivateEquity = 'Private Equity',
  PrivateCredit = 'Private Credit',
  Crypto = 'Crypto',
  ShortTermNotes = 'Short Term Notes',
  StructuredNotes = 'Structured Notes',
  DiversifiedFunds = 'Diversified Funds',
  Transportation = 'Transportation',
  Art = 'Art',
  LegalFinance = 'Legal Finance',
  Cash = 'Cash',
  RealAssets = 'Real Assets',
}
