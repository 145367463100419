import {
  AppAction,
  AppActionCTA,
  AppActionCTAText,
  AppActionCallbackMap,
  AppActionConfig,
  AppActionParamsMap,
} from './appActions.model';

class AppActionManager<GlobalActionParams extends Record<string, any> = {}> {
  private debugMode = false;
  private globalParams?: GlobalActionParams;
  private actionCallbacks?: AppActionCallbackMap<GlobalActionParams>;

  init = (
    actionCallbacks: AppActionCallbackMap<GlobalActionParams>,
    globalParams: GlobalActionParams,
    debugMode?: boolean
  ) => {
    this.actionCallbacks = actionCallbacks;
    this.debugMode = debugMode || false;
    this.globalParams = globalParams;
  };

  log = <Action extends AppAction>(action: Action, params: AppActionParamsMap[Action]) => {
    // eslint-disable-next-line no-console
    this.debugMode && console.info(action, params);
  };

  callAction = <Action extends AppAction>(action: Action, params: AppActionParamsMap[Action]) => {
    if (!this.actionCallbacks) {
      throw new Error(
        'Make sure you initialize app action callbacks before triggering any action in the application.'
      );
    }

    this.log(action, params);
    this.actionCallbacks[action](
      params || ({} as AppActionParamsMap[Action]),
      this.globalParams || ({} as GlobalActionParams)
    );
  };

  getAction = <Action extends AppAction>(
    action: Action,
    params: AppActionParamsMap[Action]
  ): AppActionConfig<Action> => ({
    action,
    params,
    cta: this.getActionCTA(action, params),
  });

  getActionCTA = <Action extends AppAction>(
    action: Action,
    params: AppActionParamsMap[Action]
  ): AppActionCTA<Action> | undefined => {
    if (!AppActionCTAText[action]) {
      return;
    }
    return {
      text: AppActionCTAText[action],
      callback: () => this.callAction(action, params),
    };
  };
}

export const appActionManager = new AppActionManager();
