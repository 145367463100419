import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
import { Heading, Paragraph, Table } from '../../content/text';
import { ButtonContainer } from '../../content/button';
import { PageSection } from '../../layout';

interface ItemContainerProps {
  itemAlignment?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  mobileHorizontalScroll?: boolean;
  itemOrientation?: 'row' | 'column';
}

interface Alignment {
  mobileAlignment?: 'left' | 'center';
  desktopAlignment?: 'left' | 'center';
}

interface SubTitleProps extends Alignment {
  className?: string;
}

export interface MultiItemBannerProps extends ItemContainerProps, Alignment {
  title?: string | React.ReactNode;
  headingType?: 1 | 2 | 3 | 4 | 5;
  tagType?: 1 | 2 | 3 | 4 | 5;
  inverse?: boolean;
  subtitle?: string;
  content?: string;
  endtitle?: string;
  cta?: React.ReactNode;
  bottomExtraSpacing?: boolean;
  className?: string;
}

export const MultiItemBanner: FunctionComponent<MultiItemBannerProps> = props => {
  const {
    title,
    headingType = 3,
    tagType,
    children,
    inverse,
    subtitle,
    content,
    endtitle,
    cta,
    bottomExtraSpacing,
    itemAlignment = 'space-evenly',
    wrap = 'nowrap',
    mobileHorizontalScroll = false,
    mobileAlignment = 'left',
    desktopAlignment = 'center',
    itemOrientation,
    className,
  } = props;
  return (
    <OuterContainer>
      <Container bottomExtraSpacing={bottomExtraSpacing} className={className}>
        <ContentHolder>
          {title && (
            <Headline
              mobileAlignment={mobileAlignment}
              desktopAlignment={desktopAlignment}
              tagType={tagType || headingType}
              type={headingType}
              inverse={inverse}
              className="multi-item-banner-title"
            >
              {title}
            </Headline>
          )}
          {subtitle && (
            <SubTitle
              mobileAlignment={mobileAlignment}
              desktopAlignment={desktopAlignment}
              inverse={inverse}
              className="multi-item-banner-subtitle"
            >
              {subtitle}
            </SubTitle>
          )}
          <ItemContainer
            mobileHorizontalScroll={mobileHorizontalScroll}
            wrap={wrap}
            itemAlignment={itemAlignment}
            className="multi-item-banner-children"
            itemOrientation={itemOrientation}
          >
            {children}
          </ItemContainer>
          {content && <Content desktopAlignment={desktopAlignment}>{content}</Content>}
          {endtitle && (
            <EndTitle desktopAlignment={desktopAlignment} regular inverse={inverse}>
              {endtitle}
            </EndTitle>
          )}
          {cta && (
            <CtaHolder>
              <ButtonContainer alignment="center">{cta}</ButtonContainer>
            </CtaHolder>
          )}
        </ContentHolder>
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled(PageSection)``;

const Container = styled.div<MultiItemBannerProps>`
  margin: 0 auto;
  padding-bottom: ${props => (props.bottomExtraSpacing ? `70px` : `20px`)};
  width: 100%;
  ${media.desktop`
    padding-left: 110px;
    padding-right: 110px;
  `}
`;

const ContentHolder = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Headline = styled(Heading)<Alignment>`
  padding: 40px 20px 15px 20px;
  width: 100%;
  text-align: ${props => props.mobileAlignment};
  ${media.desktop<Alignment>`
    text-align: ${props => props.desktopAlignment};
    padding: ${props =>
      props.desktopAlignment === 'center' ? '40px 20px 15px 20px' : '40px 0 15px 0'};
    margin: ${props => (props.desktopAlignment === 'center' ? '0 auto' : '0')};
  `}
`;

const SubTitle = styled(Paragraph)<SubTitleProps>`
  padding: 20px;
  width: 100%;
  text-align: ${props => props.mobileAlignment};
  ${media.desktop<Alignment>`
    text-align: ${props => props.desktopAlignment};
    width: 50%;
    padding: ${props => (props.desktopAlignment === 'center' ? '20px' : '20px 0')};
    margin: ${props => (props.desktopAlignment === 'center' ? '0 auto' : '0')};
  `}
`;

const EndTitle = styled(Table)<Alignment>`
  padding: 20px;
  color: ${props => props.theme.colors.text_label};
  width: 100%;
  text-align: left;
  display: inline-block;
  ${media.desktop<Alignment>`
    text-align: ${props => props.desktopAlignment};
    width: 50%;
    padding: ${props => (props.desktopAlignment === 'center' ? '20px' : '20px 0')};
    margin: ${props => (props.desktopAlignment === 'center' ? '0 auto' : '0')};
  `}
`;

const Content = styled(Paragraph)<Alignment>`
  padding: 20px;
  width: 100%;
  text-align: left;
  display: inline-block;
  ${media.desktop<Alignment>`
    text-align: ${props => props.desktopAlignment};
    width: 60%;
    padding: ${props => (props.desktopAlignment === 'center' ? '20px' : '20px 0')};
    margin: ${props => (props.desktopAlignment === 'center' ? '0 auto' : '0')};
  `}
`;

const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  flex-direction: ${props =>
    props.itemOrientation || (props.wrap === 'nowrap' && !props.mobileHorizontalScroll)
      ? `column`
      : `row`};
  flex-wrap: ${props => props.wrap};
  justify-content: ${props => props.itemAlignment};
  margin: 0 auto;
  padding: 20px;

  ${props =>
    props.mobileHorizontalScroll
      ? `
  overflow-y: auto;    
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }`
      : ''};

  ${media.desktop<ItemContainerProps>`
    flex-direction: ${props => props.itemOrientation || 'row'};
    padding: 20px 0;
  `}
`;

const CtaHolder = styled.div`
  padding: 0 20px;
  ${media.desktop`
    padding: 0;
  `}
`;
