import React from 'react';
import { Button, ButtonLink } from '@yieldstreet/ui-kit';
import { FortyActFund } from '@yieldstreet/platform-kit';

import { InvestButtonProps } from './InvestButton.model';

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const isBrowser = typeof window !== 'undefined' && window.document && window.document.createElement;
const webView = isBrowser && window.ReactNativeWebView;
const isComingFromRollover = isBrowser && window?.document?.URL?.includes('rollover');

export const InvestButton: React.FunctionComponent<InvestButtonProps> = ({
  buttonType,
  inverse,
  small,
}) => {
  return webView ? (
    <Button
      tabIndex={-1}
      onClick={() => webView.postMessage(JSON.stringify({ type: FortyActFund.WEBVIEW_MESSAGE }))}
    >
      {isComingFromRollover ? 'Select investment' : 'Invest now'}
    </Button>
  ) : (
    <ButtonLink
      buttonProps={{
        buttonType,
        inverse,
        small,
      }}
      linkProps={{
        isBreadcrumb: true,
        href: 'https://www.yieldstreet.com/signup?redirect=/input-investment/LJouqg',
        target: '_blank',
      }}
    >
      Invest now
    </ButtonLink>
  );
};
