import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Slider from '@mui/material/Slider';
import { BaseInputProps } from '@yieldstreet/tool-kit';
import { Paragraph, Label } from '../../content/text';
import isArray from 'lodash/isArray';

import {
  withFormikWeb,
  SFormGroup,
  SFormControl,
  SFormHelperText,
  FormError,
  useLabelId,
} from '../common';

interface SSliderProps {
  inverse?: boolean;
}

interface SliderInputProps extends BaseInputProps<number | number[]> {
  width?: number | string;
  fullWidth?: boolean;
  formatValue?: (value?: number | number[]) => string;
  step?: number;
  min?: number;
  max?: number;
}

export const SliderInput: FunctionComponent<SliderInputProps> = props => {
  const {
    label,
    value,
    error,
    onChange,
    className,
    caption,
    disabled,
    inverse,
    width,
    fullWidth,
    formatValue,
    step,
    min,
    max,
    name,
    testId,
    ...otherProps
  } = props;

  // eslint-disable-next-line no-nested-ternary
  const displayValue: string | undefined = formatValue
    ? formatValue(value)
    : isArray(value)
    ? `${value[0]} - ${value[1]}`
    : value?.toString();

  const labelId = useLabelId(name);

  return (
    <SFormControl
      data-slider
      error={!!error}
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      style={{ width: width || (fullWidth ? undefined : 200) }}
    >
      {label && (
        <Label inverse={inverse} data-slider-label id={labelId}>
          {label}
        </Label>
      )}
      <SFormGroup data-slider-input>
        <Flex>
          {displayValue && displayValue.length ? (
            <Paragraph semiBold inverse={inverse} data-cy={`${label}-slider-input-paragraph`}>
              {displayValue}
            </Paragraph>
          ) : null}
          <SSlider
            data-cy={testId || `${label}-slider-input`}
            inverse={inverse}
            value={value}
            disabled={disabled}
            step={step}
            min={min}
            max={max}
            name={name}
            onChange={onChange && ((_e, value) => onChange(value))}
            aria-labelledby={labelId}
            {...otherProps}
          />
        </Flex>
      </SFormGroup>
      {error || caption ? (
        <SFormHelperText margin="dense">
          {error ? (
            <FormError
              type="slider-input"
              testId="error"
              name={props?.name || 'slider'}
              error={error}
            />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </SFormControl>
  );
};

export const FormSliderInput = withFormikWeb(SliderInput);

const Flex = styled.div<SSliderProps>`
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
`;

const SSlider = styled(Slider)<SSliderProps>`
  &.MuiSlider-root {
    color: ${props => props.theme.colors.border_default};
    height: 4px;
    .MuiSlider-thumb {
      background: ${props =>
        props.inverse
          ? props.theme.colors.text_paragraph_inverse
          : props.theme.colors.text_paragraph};
    }
  }

  .MuiSlider-rail {
    height: 4px;
  }

  .MuiSlider-track {
    height: 4px;
  }

  .MuiSlider-thumb {
    background-color: ${props => props.theme.colors.border_outline};
    width: 20px;
    height: 20px;
  }

  .MuiSlider-thumb.Mui-disabled {
    background-color: ${props => props.theme.colors.border_default};
    width: 20px;
    height: 20px;
  }
`;
