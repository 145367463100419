import styled from 'styled-components';

import { TagContainerProps, TagContentProps } from './Tag.model';

export const TagContainer = styled.div<TagContainerProps>`
  align-items: center;
  border: 1px solid ${props => props.borderColor};
  border-radius: 20px;
  background-color: ${props => props.backgroundColor};
  display: inline-flex;
  font-size: 12px;
  flex-direction: row;
  gap: ${props => props.theme.spacing.xs};
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.s};
`;

export const TagTooltip = styled.div`
  align-self: center;
  display: flex;
`;

export const TagContent = styled.p<TagContentProps>`
  color: ${props => props.textColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;
