import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds, useTestIds } = genTestIds([
  'title',
  'subtitle',
  'item',
  'checkbox',
  'copy',
]);

export enum CheckboxStatuses {
  ACTIVE = 'ACTIVE',
  CLEARED = 'CLEARED',
  FAILED = 'FAILED',
  IDLE = 'IDLE',
}

export type CheckboxStatus = `${CheckboxStatuses}`;

export type CheckboxObject = {
  status: CheckboxStatus;
  copy?: string;
};

export type ProgressCheckboxProps = CheckboxObject & {
  lastElement: boolean;
  numOfElements: number;
};

export interface ProgressBarProps {
  checkboxArr: CheckboxObject[];
}

export type LineProps = {
  color: string;
  lastElement: boolean;
  numOfElements: number;
};
