import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { PageSection } from '../../layout/page-section';
import { Heading, Paragraph, HeadingType } from '../../content/text';

interface ArticleContentProps {
  title?: string;
  tagType?: HeadingType;
}

export const ArticleContent: FunctionComponent<ArticleContentProps> = ({
  title,
  tagType = 3,
  children,
}) => (
  <PageSection>
    <Container>
      <Title type={3} tagType={tagType}>
        {title}
      </Title>
      <Content>{children}</Content>
    </Container>
  </PageSection>
);

const Container = styled.div`
  padding-top: 40px;
`;

const Title = styled(Heading)`
  padding: 20px 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 2px;
    background: ${props => `${props.theme.colors.accent_strong}`};
  }
`;

const Content = styled(Paragraph)`
  p {
    padding-top: 20px;
  }
`;
