import React, { FunctionComponent } from 'react';
import { BaseInputProps, withFormik } from '@yieldstreet/tool-kit';
import {
  withQuestion,
  SFormLabel,
  SFormGroup,
  SFormControl,
  SFormHelperText,
  FormError,
  SFormGroupProps,
  useLabelId,
} from '../common';
import { CheckboxInput } from '../checkbox-input';

export interface CheckboxGroupInputOption {
  testId?: string;
  value: string;
  label: string;
  description?: string;
  disabled?: boolean;
}

export interface CheckboxGroupInputProps extends BaseInputProps<string[]>, SFormGroupProps {
  onChange?: (value: string[], options: CheckboxGroupInputOption[]) => void;
  options: CheckboxGroupInputOption[];
  descriptionCheckedOnly?: boolean;
}

export const CheckboxGroupInput: FunctionComponent<CheckboxGroupInputProps> = props => {
  const {
    label,
    value,
    options,
    error,
    name,
    onChange,
    className,
    caption,
    disabled,
    descriptionCheckedOnly,
    justify,
    direction,
    testId,
    ...otherProps
  } = props;

  const labelId = useLabelId(name);

  const handleChange = (option: CheckboxGroupInputOption) => (checked: boolean) => {
    let selectedOptions: CheckboxGroupInputOption[] = options.filter(
      option => value && value.includes(option.value)
    );

    if (checked) {
      selectedOptions = selectedOptions.concat(option);
    } else if (value) {
      selectedOptions = selectedOptions.filter(
        selectedOption => selectedOption.value !== option.value
      );
    }

    const selectedValues = selectedOptions.map(selectedOption => selectedOption.value);

    onChange && onChange(selectedValues, selectedOptions);
  };

  return (
    <SFormControl
      data-cy="checkbox-group-input"
      data-checkbox-group
      error={!!error}
      className={className}
      disabled={disabled}
      aria-labelledby={labelId}
    >
      {!!label && (
        <SFormLabel data-checkbox-group-label id={labelId}>
          {label}
        </SFormLabel>
      )}
      <SFormGroup data-checkbox-group-options justify={justify} direction={direction}>
        {options.map(option => (
          <CheckboxInput
            {...otherProps}
            key={option.value}
            testId={option?.testId || testId || option.value}
            value={value && value.includes(option.value)}
            label={option.label}
            name={`${name}-checkbox-${option.label}`}
            disabled={option.disabled || disabled}
            onChange={handleChange(option)}
            description={option.description}
            descriptionCheckedOnly={descriptionCheckedOnly}
          />
        ))}
      </SFormGroup>
      {error || caption ? (
        <SFormHelperText margin="dense">
          {error ? (
            <FormError
              testId="error"
              type="checkbox-group-input"
              name={props?.name || 'checkbox-group'}
              error={error}
            />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </SFormControl>
  );
};

export const FormCheckboxGroupInput = withFormik(CheckboxGroupInput);

export const QuestionCheckboxGroupInput = withQuestion(CheckboxGroupInput, 'primary');
export const QuestionSecondaryCheckboxGroupInput = withQuestion(CheckboxGroupInput, 'secondary');

export const TagsCheckboxGroupInput = withQuestion(CheckboxGroupInput, 'tags');

export const FormTagsCheckboxGroupInput = withFormik(TagsCheckboxGroupInput);

export const FormQuestionCheckboxGroupInput = withFormik(QuestionCheckboxGroupInput);
export const FormQuestionSecondaryCheckboxGroupInput = withFormik(
  QuestionSecondaryCheckboxGroupInput
);
