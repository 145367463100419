import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content';
import TipSvg from './icon.svg';
import WarningSvg from './warningIcon.svg';
import InfoSvg from './trustmarkInfo.svg';
import SecuritySvg from './trustmarkSecurity.svg';
interface OwnProps {
  alt?: string;
  className?: string;
  type?: string;
  center?: boolean;
}
interface TrustmarkProps {
  center?: boolean;
}

const getIcon = (type: string) => {
  switch (type) {
    case 'warning':
      return WarningSvg;
    case 'info':
      return InfoSvg;
    case 'security':
      return SecuritySvg;
    case 'tip':
    default:
      return TipSvg;
  }
};

export const InfoFact: FunctionComponent<OwnProps> = ({
  className,
  alt,
  children,
  type = 'tip',
  center,
}) => (
  <Container className={className}>
    <Icon center={center}>
      <img alt={alt || 'icon'} src={getIcon(type)} width="45" height="45" />
    </Icon>
    <Content small>{children}</Content>
  </Container>
);

const Container = styled.div`
  display: flex;
`;

const Icon = styled.div<TrustmarkProps>`
  align-self: ${props => (props.center ? 'center' : 'flex-start')};
  img {
    display: block;
  }
`;

const Content = styled(Paragraph)`
  padding: 0 0 0 15px;
  flex: 1;
`;
