import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { media } from '../../../themes';
import { Heading, Paragraph } from '../../../content/text';

interface RateProps {
  rate: number;
  rateStyle?: string;
  small?: boolean;
}

type RateAmountProps = Pick<RateProps, 'rateStyle'>;

interface ItemWithContentProps extends RateProps {
  title?: string;
  image?: string;
  imageAlt?: string;
  imageWidth?: number;
  imageHeight?: number;
  inverse?: boolean;
  alignment?: 'left' | 'center' | 'right';
  rateStyle?: string;
  fontBig?: boolean;
  isLabel?: boolean;
  rateAlignment?: string;
}

export const Rating: FunctionComponent<ItemWithContentProps> = ({
  title,
  image,
  imageAlt,
  imageWidth,
  imageHeight,
  alignment = 'left',
  rate,
  children,
  inverse,
  rateStyle,
  fontBig = false,
  isLabel,
  rateAlignment,
  small = false,
  ...otherProps
}) => {
  return (
    <OuterContainer {...otherProps} alignment={alignment}>
      <Content semiBold inverse={inverse} fontBig={fontBig} isLabel={isLabel}>
        {children}
      </Content>
      {title && (
        <Headline type={5} inverse={inverse}>
          {title}
        </Headline>
      )}
      <ImageHolder>
        {image && (
          <ImageWrapper>
            <Image alt={imageAlt} src={image} width={imageWidth} height={imageHeight}></Image>
          </ImageWrapper>
        )}
      </ImageHolder>
      <RateWrapper rateAlignment={rateAlignment}>
        <RateAmount semiBold rateStyle={rateStyle} small={small}>
          {small ? `${rate} / 5` : rate}
        </RateAmount>
        <Rate rate={rate} rateStyle={rateStyle} small={small} />
      </RateWrapper>
    </OuterContainer>
  );
};

const getRateStyle = (rateStyle: string) => {
  switch (rateStyle) {
    case 'signup':
      return {
        gradient1: 'accent_medium',
        gradient2: 'border_strong',
        gradientAplha: 1,
        letterSpacing: '3px',
        marginLeft: '6px',
        amountColor: 'accent_medium',
      };
    case 'gray':
      return {
        gradient1: 'text_label',
        gradient2: 'text_label_inverse',
        gradientAplha: 1,
        letterSpacing: '3px',
        marginLeft: '6px',
        amountColor: 'text_label',
      };
    case '':
    default:
      return {
        gradient1: 'accent_medium',
        gradient2: 'accent_medium',
        gradientAplha: 0.3,
        letterSpacing: '1px',
        marginLeft: '0',
        amountColor: 'accent_medium',
      };
  }
};

const OuterContainer = styled.div<Partial<ItemWithContentProps>>`
  display: flex;
  text-align: ${props => props.alignment};
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  &:not(:last-child) {
    border-right: 1px solid ${props => rgba(props.theme.colors.accent_medium, 0.2)};
  }
  ${media.desktop`
    padding: 0 20px;
  `}
`;

const Headline = styled(Heading)`
  padding: 0;
  margin: 10px 0;
`;

const Content = styled(Paragraph)<{ fontBig?: boolean; isLabel?: boolean }>`
  ${props =>
    props.isLabel &&
    `
      color: ${props.theme.colors.text_label};
      font-size: 14px;
      font-weight: 400;
      `}

  ${props =>
    props.fontBig
      ? `
      font-size: 26px;
      line-height: 32px;
      margin: 15px 0 50px;
  `
      : `margin: 25px 0`}
`;

const ImageHolder = styled.div`
  margin-bottom: 10px;
`;

const ImageWrapper = styled.div``;

const Image = styled.img``;

const RateWrapper = styled.div<{ rateAlignment?: string }>`
  align-items: center;
  display: flex;
  justify-content: ${props => props.rateAlignment};
`;

const RateAmount = styled(Paragraph)<RateAmountProps>`
  margin-right: 4px;
  display: inline;
  color: ${props => props.theme.colors[getRateStyle(props.rateStyle || '').amountColor]};
  font-size: ${props => props.small && '10px'};
`;

const Rate = styled.div<RateProps>`
  display: inline-block;
  font-size: ${props => (props.small ? '12px' : '20px')};
  font-family: Times;
  line-height: 1;

  &::before {
    will-change: auto;
    content: '★★★★★';
    letter-spacing: ${props => getRateStyle(props.rateStyle || '').letterSpacing};
    margin-left: ${props => getRateStyle(props.rateStyle || '').marginLeft};

    background: linear-gradient(
      90deg,
      ${props => props.theme.colors[getRateStyle(props.rateStyle || '').gradient1]}
        calc(${props => props.rate} / 5 * 100%),
      ${props =>
          rgba(
            props.theme.colors[getRateStyle(props.rateStyle || '').gradient2],
            getRateStyle(props.rateStyle || '').gradientAplha
          )}
        0
    );

    background-size: 20% 50%;

    animation: Animate 1s ease forwards;
    animation-iteration-count: 1;

    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  @keyframes Animate {
    to {
      background-size: 100% 100%;
    }
  }
`;
