import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { ButtonContainer } from '../../../../content/button/ButtonContainer';
import { ProductCardProps } from './ProductCard.model';

export const ContentHolder = styled('div')<Pick<ProductCardProps, 'sideCTA'>>(
  ({ sideCTA, theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: sideCTA ? 'row' : 'column',
    justifyContent: 'space-between',
    color: theme.palette.text.paragraphPrimary.default,
  })
);

export const CTAHolder = styled(ButtonContainer)<Pick<ProductCardProps, 'sideCTA' | 'sideImage'>>(
  ({ sideCTA, sideImage, theme }) => ({
    alignItems: sideCTA ? 'center' : 'flex-start',
    margin: 0,
    marginTop: sideCTA ? 0 : theme.spacing(10),
    marginLeft: sideCTA ? theme.spacing(11) : 0,

    'a, button': {
      width: sideImage || sideCTA ? 'auto' : '100%',
      whiteSpace: 'nowrap',
    },
  })
);

export const Headline = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up('md')]: {
    minHeight: '50px',
  },
}));

export const Image = styled('img')(() => ({
  height: '60px',
}));

export const ImageHolder = styled('div')<Pick<ProductCardProps, 'sideImage'>>(
  ({ sideImage, theme }) => ({
    marginBottom: sideImage ? 0 : theme.spacing(5),
    marginRight: sideImage ? theme.spacing(11) : 0,
  })
);

export const InfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

export const OuterContainer = styled('div')<
  Pick<ProductCardProps, 'noMargin' | 'sideCTA' | 'sideImage'>
>(({ noMargin, sideCTA, sideImage, theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: sideImage ? 'row' : 'column',
  padding: sideImage || sideCTA ? theme.spacing(11) : theme.spacing(8),
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.06)',
  background: theme.palette.background.default,

  ...(!noMargin && {
    margin: `${theme.spacing(3)} 0`,

    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(3)}`,
    },
  }),
}));

export const Paragraph = styled(Typography)<{ inverse?: boolean; semiBold?: boolean }>(
  ({ inverse, semiBold, theme }) => ({
    color: inverse
      ? theme.palette.text.paragraphPrimary.inverse
      : theme.palette.text.paragraphPrimary.default,
    fontWeight: semiBold ? 500 : 400,
  })
);
