import { AssetClassType } from '@yieldstreet/platform-kit';

export enum FilterTypeEnum {
  Boolean = 'boolean',
  MultiSelect = 'multi-select',
  SingleSelect = 'single-select',
  Range = 'range',
}

export type FilterType = `${FilterTypeEnum}`;

export type FilterAssetClassOption = {
  id: AssetClassType;
  label: string;
};

export const FilterAssetClassOptions: FilterAssetClassOption[] = [
  {
    id: AssetClassType.Art,
    label: 'Art',
  },
  {
    id: AssetClassType.Crypto,
    label: 'Crypto',
  },
  {
    id: AssetClassType.Legal,
    label: 'Legal',
  },
  {
    id: AssetClassType.MultiAssetClassFund,
    label: 'Multi-Asset Class Fund',
  },
  {
    id: AssetClassType.Other,
    label: 'Other',
  },
  {
    id: AssetClassType.PrivateCredit,
    label: 'Private Credit',
  },
  {
    id: AssetClassType.PrivateEquity,
    label: 'Private Equity',
  },
  {
    id: AssetClassType.RealEstate,
    label: 'Real Estate',
  },
  {
    id: AssetClassType.ShortTermNotes,
    label: 'Short Term Notes',
  },
  {
    id: AssetClassType.Transportation,
    label: 'Transportation',
  },
  {
    id: AssetClassType.VentureCapital,
    label: 'Venture Capital',
  },
];
