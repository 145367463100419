import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content';

export interface ListItemProps {
  label: string;
  value: string;
  borderBottom?: boolean;
}

export const ListItem: FunctionComponent<ListItemProps> = ({ label, value, borderBottom }) => {
  return (
    <ListItemContainer borderBottom={borderBottom}>
      <Paragraph>{label}</Paragraph>
      <Paragraph semiBold>{value}</Paragraph>
    </ListItemContainer>
  );
};

const ListItemContainer = styled.div<{ borderBottom?: boolean }>`
  ${props => props.borderBottom && `border-bottom: solid 1px ${props.theme.colors.border_default};`}
  display: flex;
  width: 100%;
  flex: 1;
  align-items: stretch;
  justify-content: space-between;
  padding: 4px 0;
`;
