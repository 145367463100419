import styled from 'styled-components';
import { media } from '../../themes/media';

export const Block = styled.div`
  border-radius: ${({ theme }) => theme.radius.lg};
  border: 1px solid ${props => props.theme.colors.border_default};
  ${media.desktop`
    width: 300px;
    &:hover{
      cursor: pointer;
      box-shadow: ${props => props.theme.shadows.shadow_hover};
    }
  `};
`;

export const ImageContent = styled.div<{ bgImg: string }>`
  height: 120px;
  background: url(${props => props.bgImg}) no-repeat;
  background-size: cover;
  background-position: center center;
  border-top-left-radius: ${({ theme }) => theme.radius.lg};
  border-top-right-radius: ${({ theme }) => theme.radius.lg};
  ${media.desktop`
    height: 160px;
  `};
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.m};
  row-gap ${props => props.theme.spacing.s};
  text-align: left;
`;
