export enum PerformanceStatus {
  PERFORMING = 'PERFORMING',
  MODIFIED_OUTLOOK = 'MODIFIED_OUTLOOK',
  PARTIAL_DEFAULT = 'PARTIAL_DEFAULT',
  DEFAULT = 'DEFAULT',
  NONE = 'NONE',
  MARINE_DEFAULT = 'MARINE_DEFAULT',
}

export enum InvestmentPerformanceStatus {
  Active = 'Active',
  Pending = 'Pending',
  Warning = 'Warning',
  Default = 'Default',
}
