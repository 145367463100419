import React, { useState, useCallback, useContext } from 'react';

import { snackbarInitialState, SnackbarStateType, SnackbarProps } from './Snackbar.model';

export const SnackbarContext = React.createContext<SnackbarStateType>({
  ...snackbarInitialState,
});

export const useSnackbarContext = (): SnackbarStateType => {
  const [snackbarQueue, setSnackbarQueue] = useState(snackbarInitialState.snackbarQueue);

  const enqueueSnackbar = useCallback(
    (snack: SnackbarProps) => {
      setSnackbarQueue([snack, ...snackbarQueue]);
    },
    [snackbarQueue]
  );

  const closeSnackbar = useCallback(
    (snackbarKey?: string) => {
      if (snackbarKey) {
        const filteredQueue = snackbarQueue.filter(
          snackbar => snackbar.key.toString() !== snackbarKey
        );
        setSnackbarQueue(filteredQueue);
      } else {
        setSnackbarQueue([]);
      }
    },
    [snackbarQueue]
  );

  return {
    snackbarQueue,
    enqueueSnackbar,
    closeSnackbar,
  };
};

export const useSnackbar = (): SnackbarStateType => {
  const { snackbarQueue, enqueueSnackbar, closeSnackbar } = useContext(SnackbarContext);

  return {
    snackbarQueue,
    enqueueSnackbar,
    closeSnackbar,
  };
};
