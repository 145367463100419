import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { TitleHeading, Paragraph, HeadingType, TagType } from '../../../content/text';
import { CurrencyProps, Currency } from './common/currency';
import { media } from '../../../themes/media';

interface Alignment {
  mobileAlignment?: 'left' | 'center';
  desktopAlignment?: 'left' | 'center';
  horizontalLayout?: boolean;
}

interface ContentProps extends Alignment {
  className?: string;
}

interface StatsProps extends Alignment {
  amount?: CurrencyProps;
  plainAmount?: string;
  headingSuperscript?: string;
  subtitle?: string | React.ReactNode;
  inverse?: boolean;
  seperator?: boolean;
  headingType?: HeadingType;
  tagType?: TagType;
  className?: string;
}

export const Stats: FunctionComponent<StatsProps> = ({
  amount,
  subtitle,
  plainAmount,
  inverse,
  seperator = false,
  desktopAlignment = 'center',
  mobileAlignment = 'left',
  headingSuperscript,
  headingType = 2,
  tagType,
  className,
  horizontalLayout,
}) => {
  return (
    <OuterContainer
      mobileAlignment={mobileAlignment}
      desktopAlignment={desktopAlignment}
      amount={amount}
      subtitle={subtitle}
      inverse={inverse}
      className={className}
      horizontalLayout={horizontalLayout}
    >
      <HeadlineHolder horizontalLayout={horizontalLayout}>
        <Headline type={headingType} tagType={tagType} inverse={inverse}>
          {amount && <Currency {...amount}></Currency>}
          {plainAmount}
        </Headline>
        {headingSuperscript && (
          <Superscript semiBold inverse={inverse}>
            {headingSuperscript}
          </Superscript>
        )}
      </HeadlineHolder>
      {seperator && <Separator desktopAlignment={desktopAlignment} />}
      <Content
        className="stats-subtitle"
        desktopAlignment={desktopAlignment}
        inverse={inverse}
        horizontalLayout={horizontalLayout}
      >
        {subtitle}
      </Content>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<StatsProps>`
  text-align: ${props => props.mobileAlignment};
  margin: 0 0 20px 0;
  flex-grow: 1;
  flex-basis: 0;
  flex: ${props => (props.horizontalLayout ? '50%' : '1 0 0%')};
  ${media.desktop<StatsProps>`
    margin: 0;
    text-align: ${props => props.desktopAlignment};
    display: ${props => (props.horizontalLayout ? 'flex' : 'block')};
    flex-direction: ${props => (props.horizontalLayout ? 'row' : 'none')};
  `}
`;

const HeadlineHolder = styled.div<StatsProps>`
  position: relative;
  margin: ${props => (props.horizontalLayout ? '0 0 4px' : '0px')};

  ${media.desktop`
    margin: 0px;
  `}
`;

const Headline = styled(TitleHeading)`
  padding: 0;
  margin: 10px 0 0 0;
  display: inline-block;
  ${media.desktop`
    margin: 10px 0;
  `}
`;

const Superscript = styled(Paragraph)`
  display: inline;
  font-family: ${props => props.theme.typography.primary};
  top: 10px;
  position: absolute;
  font-size: smaller;
  width: auto !important;
`;

const Separator = styled.div<Alignment>`
  width: 30px;
  height: 1px;
  margin: 20px 0;
  background: ${props => props.theme.colors.accent_strong};
  ${media.desktop<Alignment>`
    margin: ${props => (props.desktopAlignment === 'center' ? '20px auto' : '20px 0')};
  `}
`;

const Content = styled(Paragraph)<ContentProps>`
  font-size: ${props => (props.horizontalLayout ? '12px' : '16px')};
  ${media.desktop<Alignment>`
    margin: ${props => (props.desktopAlignment === 'center' ? '0 auto' : '0')};

    line-height: ${props => (props.horizontalLayout ? '13px' : '22px')};
    text-align: ${props => (props.horizontalLayout ? 'left' : 'center')};
    width: ${props => (props.horizontalLayout ? '100px;' : '100%')};
    padding-left: ${props => (props.horizontalLayout ? '10px;' : '0px')};
    align-self: ${props => (props.horizontalLayout ? 'center' : 'none')};
  `}
`;
