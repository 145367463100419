import { palette, theme2 } from './theme2';

const cadrePalette = {
  ...palette,
  'cadre-500': '#7861ED',
  'cadre-800': '#423582',
};

export const themeCadre = {
  ...theme2,
  name: 'Theme Cadre',
  colors: {
    ...theme2.colors,
    input_button_background: cadrePalette['cadre-500'],
    input_button_background_hover: cadrePalette['cadre-800'],
    input_button_background_active: cadrePalette['cadre-500'],
    input_button_text: palette.white,
  },
};
