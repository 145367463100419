import styled, { css, CSSProperties } from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText, { FormHelperTextTypeMap } from '@mui/material/FormHelperText';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const SFormHelperText = styled<OverridableComponent<FormHelperTextTypeMap<{}>>>(
  FormHelperText
)`
  &.MuiFormHelperText-root {
    font-family: ${props => props.theme.typography.secondary};
    font-size: 12px;
    line-height: 14px;
    &.Mui-error {
      color: ${({ theme }) => theme.colors.notice_error};
    }

    &.MuiFormHelperText-contained {
      margin-top: 3px;
      margin-left: 12px;
      margin-right: 0;
    }
  }
`;

export const LabelStyles = css`
  color: ${({ theme }) => theme.colors.text_label};

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.text_label} !important;
  }
  &.Mui-error {
    color: ${({ theme }) => theme.colors.notice_error} !important;
  }
  &.Mui-disabled {
    color: ${props => props.theme.colors.input_disabled_text};
  }
  &.Mui-focused {
    color: ${props => props.theme.colors.text_label};
  }
  font-family: ${props => props.theme.typography.secondary} !important;
  line-height: 1.8;
`;

export const SFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    margin-bottom: 10px;
    text-align: left !important;
  }
`;

export const SFormControlLabel = styled(FormControlLabel)`
  & .MuiTypography-root {
    ${LabelStyles}
  }
`;

export const SFormControlLabelRadio = styled(FormControlLabel)`
  & .MuiTypography-root {
    ${LabelStyles}
    color: ${({ theme }) => theme.colors.text_paragraph};
  }
`;
export const SFormLabel = styled(FormLabel)`
  &.MuiFormLabel-root {
    ${LabelStyles}
  }
`;

export type SFormGroupProps = {
  justify?: CSSProperties['justifyContent'];
  direction?: CSSProperties['flexDirection'];
};

export const SFormGroup = styled(FormGroup)<SFormGroupProps>`
  &[data-checkbox-group-options='true'],
  &[data-radio-group-options='true'] {
    ${({ justify }) => (justify ? `justify-content: ${justify};` : '')}
    ${({ direction }) => (direction ? `flex-direction: ${direction};` : '')}
  }
  &.MuiFormGroup-root {
    padding-top: 5px;
    & .MuiFormControl-root {
      margin-bottom: 0;
    }
  }
`;
