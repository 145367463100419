import { groupBy } from 'lodash';
import { QuizAnswer } from '../../types';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { quizApi } from './quiz';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';

// makes a copy of answers and returns it with the updated answer
export const updateQuizAnswer = (answers: QuizAnswer[], newAnswer: QuizAnswer): QuizAnswer[] => {
  const answerIndex = answers.findIndex(({ questionKey, investorEntityId }) => {
    if (newAnswer.investorEntityId) {
      return (
        questionKey === newAnswer.questionKey && investorEntityId === newAnswer.investorEntityId
      );
    }
    return questionKey === newAnswer.questionKey;
  });
  if (answerIndex > -1) {
    const updatedAnswers = [...answers];
    updatedAnswers[answerIndex] = newAnswer;
    return updatedAnswers;
  } else {
    return [...answers, newAnswer];
  }
};

export const updateMultipleAnswers = (
  answers: QuizAnswer[],
  newAnswers: QuizAnswer[]
): QuizAnswer[] => {
  let updatedAnswers = [...answers];
  newAnswers.forEach(newAnswer => {
    updatedAnswers = updateQuizAnswer(updatedAnswers, newAnswer);
  });
  return updatedAnswers;
};

// updateQuizAnswersQueries and updateQuizAnswerQueries are used for optimistically updating
// the RTK cache for the Quiz API because of eventual consistency in the quiz endpoints.
// Each of these functions will update the getQuizAnswersByInvestorEntityId, getQuizAnswersByUserId and getQuizAnswersByQuizId
// query caches for any investorEntityIds, userIds or quizIds found in the answers provided to the query to the
// put endpoint.

export const updateQuizAnswersQueries = (
  newAnswers: QuizAnswer[],
  dispatch: ThunkDispatch<any, any, AnyAction>
): PatchCollection[] => {
  const patchResults: PatchCollection[] = [];

  const groupedNewAnswersByInvestorEntityId = groupBy(newAnswers, 'investorEntityId');
  Object.entries(groupedNewAnswersByInvestorEntityId).forEach(
    ([investorEntityId, newAnswersByInvestorEntityId]) => {
      const patchResult = dispatch(
        quizApi.util.updateQueryData(
          'getQuizAnswersByInvestorEntityId',
          { investorEntityId },
          draft => {
            Object.assign(draft, {
              answers: updateMultipleAnswers(draft.answers, newAnswersByInvestorEntityId),
            });
          }
        )
      );
      patchResults.push(patchResult);
    }
  );

  const groupedNewAnswersByUserId = groupBy(newAnswers, 'userId');
  Object.entries(groupedNewAnswersByUserId).forEach(([userId, newAnswersByUserId]) => {
    const patchResult = dispatch(
      quizApi.util.updateQueryData('getQuizAnswersByUserId', { userId }, draft => {
        Object.assign(draft, { answers: updateMultipleAnswers(draft.answers, newAnswersByUserId) });
      })
    );
    patchResults.push(patchResult);
  });

  const groupedNewAnswersByQuizId = groupBy(newAnswers, 'quizId');
  Object.entries(groupedNewAnswersByQuizId).forEach(([quizId, newAnswersByQuizId]) => {
    const patchResult = dispatch(
      quizApi.util.updateQueryData('getQuizAnswersByQuizId', { quizId }, draft => {
        Object.assign(draft, { answers: updateMultipleAnswers(draft.answers, newAnswersByQuizId) });
      })
    );
    patchResults.push(patchResult);
  });

  return patchResults;
};

export const updateQuizAnswerQueries = (
  newAnswer: QuizAnswer,
  dispatch: ThunkDispatch<any, any, AnyAction>
): PatchCollection[] => {
  const patchResults: PatchCollection[] = [];
  if (newAnswer.investorEntityId) {
    const patchResult = dispatch(
      quizApi.util.updateQueryData(
        'getQuizAnswersByInvestorEntityId',
        { investorEntityId: newAnswer.investorEntityId },
        draft => {
          if (draft) {
            Object.assign(draft, { answers: updateQuizAnswer(draft.answers, newAnswer) });
          }
        }
      )
    );
    patchResults.push(patchResult);
  }

  if (newAnswer.userId) {
    const patchResult = dispatch(
      quizApi.util.updateQueryData(
        'getQuizAnswersByUserId',
        { userId: newAnswer.userId },
        draft => {
          if (draft) {
            Object.assign(draft, { answers: updateQuizAnswer(draft.answers, newAnswer) });
          }
        }
      )
    );
    patchResults.push(patchResult);
  }

  if (newAnswer.quizId) {
    const patchResult = dispatch(
      quizApi.util.updateQueryData(
        'getQuizAnswersByQuizId',
        { quizId: newAnswer.quizId },
        draft => {
          if (draft) {
            Object.assign(draft, {
              answers: updateQuizAnswer(draft.answers, newAnswer),
            });
          }
        }
      )
    );
    patchResults.push(patchResult);
  }
  return patchResults;
};
