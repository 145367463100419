import React from 'react';

import { CardBaseContainerProps, useTestIds } from './Card.model';
import { CardBaseWrapper } from './Card.style';

export const Card: React.FC<CardBaseContainerProps> = ({
  onClick,
  children,
  href,
  newTab,
  bgColor,
  className,
  ...props
}) => {
  const isLink = !!href;

  const testIds = useTestIds(props);
  return (
    <CardBaseWrapper
      className={className}
      {...testIds.card.attr}
      isLink={isLink}
      href={href}
      clickable={isLink || !!onClick}
      target={newTab ? '_blank' : '_self'}
      onClick={() => {
        onClick && onClick();
      }}
      bgColor={bgColor}
    >
      {children}
    </CardBaseWrapper>
  );
};
