import styled from 'styled-components';

import { media } from '../../themes/media';

// Height/width calculations are based on the checkbox sizes of 32px (desktop)
// and 24px (mobile), respectively
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${media.tablet`
    flex-direction: row;
    justify-content: space-evenly;
  `};
`;
