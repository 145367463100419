import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../../themes';
import { Heading, Paragraph } from '../../../content/text';

interface ItemWithContentProps {
  title?: string;
  image?: string;
  imageAlt?: string;
  inverse?: boolean;
  alignment?: 'left' | 'center' | 'right';
}

export const ImageWithContentWithSep: FunctionComponent<ItemWithContentProps> = ({
  title,
  image,
  imageAlt,
  alignment = 'left',
  children,
  inverse,
}) => {
  return (
    <OuterContainer alignment={alignment} image={image} title={title}>
      <ImageHolder>
        {image && (
          <ImageWrapper>
            <Image alt={imageAlt} src={image}></Image>
          </ImageWrapper>
        )}
      </ImageHolder>
      <Headline type={4} inverse={inverse}>
        {title}
      </Headline>
      <Content small={true} inverse={inverse}>
        {children}
      </Content>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<ItemWithContentProps>`
  text-align: ${props => props.alignment};
  flex-grow: 1;
  flex-basis: 0;
  ${media.desktop`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  `}
`;

const Headline = styled(Heading)`
  padding: 0 0 20px;
  margin: 10px 0 20px 0;
  position: relative;
  &::after {
    bottom: 0;
    left: 0;
    height: 1px;
    width: 30px;
    content: '';
    position: absolute;
    background: ${props => props.theme.colors.accent_strong};
  }
`;

const Content = styled(Paragraph)`
  flex: 1 0 auto;
  position: relative;
  padding-bottom: 50px;
  a {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const ImageHolder = styled.div`
  position: relative;
  height: 70px;
  margin: 20px 0;
  ${media.desktop`
    margin-top: 0;
  `}
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const Image = styled.img`
  max-height: 70px;
`;
