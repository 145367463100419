import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Paragraph } from '../../content/text';
import { SectionContainer } from '../../layout/section-container';
import { PageSection } from '../../layout/page-section';
import { media } from '../../themes/media';

interface TextBannerProps {
  title?: string;
  inverse?: boolean;
}

export const TextBanner: FunctionComponent<TextBannerProps> = ({ title, children, inverse }) => {
  return (
    <OuterContainer>
      <PageSection>
        <Container>
          <InnerContainer>
            <Title inverse={inverse} small semiBold>
              {title}
            </Title>
            <Paragraph inverse={inverse} small>
              {children}
            </Paragraph>
          </InnerContainer>
        </Container>
      </PageSection>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<TextBannerProps>`
  background: ${props => props.theme.colors.background_default};
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  text-align: left;
  padding: 20px 0;
  box-sizing: border-box;
  ${media.desktop`
    text-align: center;
  `};
`;

const Title = styled(Paragraph)`
  margin-bottom: 10px;
`;

const Container = styled(SectionContainer)`
  padding-top: 0;
  padding-bottom: 0;
  ${media.tablet`
    padding-top: 0;
    padding-bottom: 0;
  `};
`;
