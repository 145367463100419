import { genTestIds } from '@yieldstreet/tool-kit';

export interface EmptyStateProps {
  title: string;
  subtitle?: string;
  buttonText?: string;
  onClick?: () => void;
  trackingId?: string;
  /** @deprecated use testId property instead */
  dataCy?: string;
  testId?: string;
}

export const { getTestIds } = genTestIds(['title', 'subtitle']);
export const mapToTestId = (testId = 'empty-state') =>
  getTestIds(`empty-state${testId ? '-' + testId : ''}`);
