import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';

import {
  CreateIraAccountResponse,
  CreateIraAccountRequest,
  IraGeneralResponse,
  CopyIraAccountRequest,
  PostIraPricingRequest,
} from './ira.model';

export const iraApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.Ira],
  })
  .injectEndpoints({
    endpoints: builder => {
      const createIraAccount = builder.mutation<CreateIraAccountResponse, CreateIraAccountRequest>({
        query: data => ({
          url: `/a/api/ira/account`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.Ira],
      });

      const copyIraAccount = builder.mutation<IraGeneralResponse, CopyIraAccountRequest>({
        query: ({ investorId, ...data }) => ({
          url: `/a/api/ira/account/${investorId}/copy-account`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.Ira],
      });

      const postIraPricing = builder.mutation<IraGeneralResponse, PostIraPricingRequest>({
        query: ({ investorId, tier }) => ({
          url: `/a/api/ira/account/${investorId}/pricing`,
          method: HttpMethods.Post,
          data: { tier },
        }),
        invalidatesTags: [Tags.Ira],
      });

      return {
        createIraAccount,
        copyIraAccount,
        postIraPricing,
      };
    },
  });
