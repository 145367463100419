import {
  SpacingStyleProps,
  PaddingKeys,
  PaddingPropKeys,
  MarginKeys,
  MarginPropKeys,
} from './Spacing.model';

/*
 * remove any props that are not spacing related and
 * reorder props keys to ensure correct css order
 * (padding, paddingHorizontal, paddingVertical)
 * */
export const sanitizeSpacingProps = (props: Partial<SpacingStyleProps>) => {
  const sanitizedProps: SpacingStyleProps = {};

  [...PaddingKeys, ...MarginKeys].forEach((key: PaddingPropKeys | MarginPropKeys) => {
    if (props[key]) {
      sanitizedProps[key] = props[key];
    }
  });

  return sanitizedProps;
};
