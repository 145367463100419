import { genTestIds } from '@yieldstreet/tool-kit';
import { Place } from 'react-tooltip';

export interface TooltipProps {
  buttonType?: 'button' | 'submit' | 'reset';
  children?: React.ReactNode;
  closeIcon?: boolean;
  customIcon?: string;
  customIconHeight?: number;
  customIconWidth?: number;
  dataCy?: string;
  inverse?: boolean;
  clickable?: boolean;
  place?: Place;
  tooltipKey?: string;
}

export interface ReactTooltipProps {
  inverse?: boolean;
}

export const { getTestIds, useTestIds } = genTestIds(['content']);
