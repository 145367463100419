import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds, useTestIds } = genTestIds([
  'content-icon',
  'content-subtitle',
  'content-title',
]);

export type OfferingTitleIcon = {
  src: string;
  compact?: boolean;
};

export type OfferingTitleProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  icon?: OfferingTitleIcon;
  loading?: boolean;
  inverse?: boolean;
  compact?: boolean;
};
