import React, { useCallback } from 'react';
import styled from 'styled-components';
import ArrowBlack from '../../assets/graphics/icons/arrow.svg';
import ArrowGrey from '../../assets/graphics/icons/arrowGrey.svg';

interface PaginationProps {
  total?: number;
  current?: number;
  onChange?: (currentState: number) => void | undefined;
  linkComponent?: (number?: number | undefined, customElement?: React.ReactNode) => React.ReactNode;
}

export const Pagination = ({ total, current, onChange, linkComponent }: PaginationProps) => {
  let pageItems: Array<React.ReactNode> = [];
  const step = 3;
  const renderNumber = useCallback((number?: number, customElement?: React.ReactNode) => {
    if (linkComponent) {
      return linkComponent(number, customElement || number);
    }
    return customElement || number;
  }, []);

  if (current && total) {
    const addPagination = (start: number, end: number) => {
      for (let idx = start; idx <= end && end; idx++) {
        pageItems &&
          pageItems.push(
            <PaginationItem
              onClick={() => (idx === current ? null : onChange && onChange(idx))}
              key={idx}
              selected={idx === current}
              className={'paginationitem paginationitem-' + idx}
            >
              {renderNumber(idx)}
            </PaginationItem>
          );
      }
    };

    const addFirst = () => {
      pageItems &&
        pageItems.push(
          <PaginationItem
            onClick={() => (1 === current ? null : onChange && onChange(1))}
            key={1}
            selected={1 === current}
            className={'paginationitem paginationitem-1'}
          >
            {renderNumber(1)}
          </PaginationItem>
        );
      pageItems &&
        pageItems.push(
          <PaginationItem disabled key="dots-1" className={'paginationitem paginationitem-dots-1'}>
            ...
          </PaginationItem>
        );
    };

    const addLast = () => {
      pageItems &&
        pageItems.push(
          <PaginationItem disabled key="dots-2" className={'paginationitem paginationitem-dots-2'}>
            ...
          </PaginationItem>
        );
      pageItems &&
        pageItems.push(
          <PaginationItem
            onClick={() => (total === current ? null : onChange && onChange(total))}
            key={total}
            selected={total === current}
            className={'paginationitem paginationitem-' + total}
          >
            {renderNumber(total)}
          </PaginationItem>
        );
    };

    if (total - 1 < step * 2 + 3) {
      addPagination(1, total);
    } else if (current < step * 2 + 1) {
      addPagination(1, step * 2 + 3);
      addLast();
    } else if (current > total - step * 2) {
      addFirst();
      addPagination(total - step * 2 - 2, total);
    } else {
      addFirst();
      addPagination(current - step + 1, current + step + 1);
      addLast();
    }
  }

  const renderArrowLeft = () => {
    const prev = current && current - 1;
    const hasPrev = current && current > 1;

    return (
      hasPrev &&
      renderNumber(
        prev,
        <Arrow
          left
          onClick={() => current && current > 1 && onChange && onChange(current - 1)}
          src={current === 1 ? ArrowGrey : ArrowBlack}
        />
      )
    );
  };

  const renderArrowRight = useCallback(() => {
    const next = current ? current + 1 : 0;
    const hasNext = total && current && current < total;

    return (
      hasNext &&
      renderNumber(
        next,
        <Arrow
          onClick={() => onChange && onChange(next)}
          right
          src={current === total ? ArrowGrey : ArrowBlack}
        />
      )
    );
  }, [current, total]);

  return (
    <Container>
      {renderArrowLeft()}
      <Numbers className="pagination">{pageItems}</Numbers>
      {renderArrowRight()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

interface ArrowProps {
  left?: boolean;
  right?: boolean;
}

const Arrow = styled.img<ArrowProps>`
  height: 7px;
  width: 14px;
  ${props => props.left && `transform: rotate(90deg);`}
  ${props => props.right && `transform: rotate(270deg);`}
  cursor: pointer;
`;

const Numbers = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px 20px 0px 20px;
`;

interface PaginationItemProps {
  selected?: boolean;
  disabled?: boolean;
}

const PaginationItem = styled.li<PaginationItemProps>`
  color: ${props => props.theme.colors.gray2};
  border: ${props => props.selected && `1px solid black`};
  border-radius: 2px;
  font-size: 13px;
  padding: 3px 6px;
  margin: 5px 5px 5px 0;
  &:last-child {
    margin-right: 0px;
  }
  cursor: ${props => (props.disabled || props.selected ? 'default' : 'pointer')};
`;
