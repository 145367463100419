import styled from 'styled-components';

import { Heading } from '../../content';

import {
  backgroundVariant,
  ContainerProps,
  ContentProps,
  DescriptionProps,
} from './Accordion.model';

export const Container = styled.div<ContainerProps>`
  margin-bottom: 10px;
  padding: 20px;
  background: ${props => props.theme.colors[backgroundVariant[props.background]]};
  border-radius: 8px;
`;

export const IconHolder = styled.div`
  padding: 0 10px 0 0;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const HeadingWrapper = styled.div`
  width: 80%;
`;

export const AnimatedContent = styled.div<ContentProps>`
  display: grid;
  transition: grid-template-rows 0.7s ease;
  grid-template-rows: ${props => (props.show ? '1fr' : '0fr')};
`;

export const Description = styled.div<DescriptionProps>`
  padding-top: 20px;
  overflow: hidden;

  ${props =>
    props.addParagraphMargin &&
    `
    p {
      margin-bottom: 10px;
    }
  `}
`;

export const HeadingIcon = styled.img`
  margin-right: 16px;
`;

export const AccordionHeading = styled(Heading)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
