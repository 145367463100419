export enum InvestorEntityActionCode {
  SET_KYC_1 = 'SET_KYC_1',
  SET_KYC_2 = 'SET_KYC_2',
  VERIFY_ACCREDITATION = 'VERIFY_ACCREDITATION',
  INFORM_MAIN_BENEFICIAL_OWNER_DATA = 'INFORM_MAIN_BENEFICIAL_OWNER_DATA',
  UPLOAD_MAIN_BENEFICIAL_OWNERS_DOCUMENT = 'UPLOAD_MAIN_BENEFICIAL_OWNERS_DOCUMENT',
  INVITE_BENEFICIAL_OWNERS = 'INVITE_BENEFICIAL_OWNERS',
  LINK_BANK_ACCOUNT = 'LINK_BANK_ACCOUNT',
  VERIFY_MICRO_DEPOSITS = 'VERIFY_MICRO_DEPOSITS',
  EDIT_ACCREDITATION = 'EDIT_ACCREDITATION',
  UPDATE_ACCREDITATION = 'UPDATE_ACCREDITATION',
  UPDATE_ACCREDITATION_DOCUMENTATION = 'UPDATE_ACCREDITATION_DOCUMENTATION',

  // IRA Actions
  VERIFY_BENEFICIARIES = 'VERIFY_BENEFICIARIES',
  SET_IRA_FEE = 'SET_IRA_FEE',
  SIGN_IRA_DOC = 'SIGN_IRA_DOC',
  FUND_IRA = 'FUND_IRA',
  ITS_ON_US = 'ITS_ON_US',
}
