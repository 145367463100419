import { DropDownType } from '@yieldstreet/platform-kit';
import { media } from '@yieldstreet/ui-kit';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

interface FieldLayoutGroupProps {
  inline: boolean;
}

interface FieldMainProps {
  fullWidth: boolean;
  maxWidth?: number;
}

interface FieldProps extends FieldMainProps {
  withBorder: boolean;
  active: boolean;
  responsive: boolean;
  type: any;
}

export const FieldLayoutGroup = styled.div<FieldLayoutGroupProps>`
  ${props =>
    props.inline
      ? `
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: baseline;
    gap: 4px;
  `
      : `
    display: block;
  `}
`;

export const FieldLabel = styled.div`
  font-size: 14px;
`;

export const FieldMain = styled(motion.div)<FieldMainProps>`
  margin-right: ${props => props.theme.spacing.s};
  font-style: normal;
  font-weight: 400;
  font-size: 100%;
  color: ${props => props.theme.colors.text_link};
  ${props =>
    props.fullWidth
      ? `
    max-width: auto;
  `
      : `
    max-width: ${props?.maxWidth ? props.maxWidth + 'px' : '240px'};
  `}
`;

export const FieldValue = styled(motion.div)`
  width: 100%;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FieldBase = css<FieldProps>`
  color: ${props => props.theme.colors.border_strong_alt};
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  height: 60px;
  padding: 0 ${props => props.theme.spacing.s};
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
`;

export const FieldDefault = css<FieldProps>`
  ${FieldBase};
  ${props => {
    if (props.withBorder) {
      return `
      border: 1px solid ${props.theme.colors.input_button_basic_inverse_background_active};
border-bottom-color: ${
        props.active ? props.theme.colors.border_strong_alt : props.theme.colors.border_default
      };
`;
    }
  }}

  ${props => {
    if (props.responsive) {
      return media.tablet`
        height: 60px;
        padding: 0 ${props => props.theme.spacing.xs};

        ${FieldMain} {
          font-size: 75%;
          ${props =>
            props.fullWidth
              ? `
            max-width: auto;
          `
              : `
            max-width: 90px;
          `}
        }
       `;
    }
  }}

  ${props => {
    if (props.responsive) {
      return media.desktop`
          height: 60px;
          padding: 0 ${props => props.theme.spacing.s};

          ${FieldMain} {
            font-size: 100%;
            ${props =>
              props.fullWidth
                ? `
              max-width: auto;
            `
                : `
              max-width: ${props?.maxWidth ? props.maxWidth + 'px' : '240px'};
            `}
          }
        `;
    }
  }}
`;

export const FieldPill = css`
  ${FieldBase};
  border-radius: 8px;
  padding: ${props => `6px ${props.theme.spacing.s}`};
  background: ${props => props.theme.colors.input_button_basic_inverse_background_active};
  height: auto;
`;

export const Field = styled.div<FieldProps>`
  ${props => (props.type === DropDownType.PILL ? FieldPill : FieldDefault)};
`;

export const FieldIcon = styled(motion.img)`
  pointer-events: none;
`;

export const fieldValueAnimation = (inline: boolean) => ({
  exit: 'empty',
  initial: 'empty',
  animate: 'filled',
  variants: {
    filled: {
      opacity: 1,
      transform: `translateY(0px)`,
    },
    empty: {
      opacity: 0,
      transform: `translateY(${inline ? 0 : 12}px)`,
    },
  },
});

export const fieldIconAnimation = (active: boolean) => ({
  animate: {
    transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
  },
});
