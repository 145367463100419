import React, { useState, useEffect } from 'react';
import { Paragraph, ExternalLink } from '@yieldstreet/ui-kit';

import PrivacyFrameModal from './PrivacyFrameModal';
import { FundFooterProps } from './FundFooter.model';
import {
  LegalParagraph,
  Footer,
  SSectionContainer,
  AddressContainer,
  Telephone,
  Email,
  LegalContainer,
  DisclosureToggle,
  DisclosureToggleText,
  ChevronDown,
  ChevronUp,
  Copyright,
  CopyrightLine,
  SExternalLink,
} from './FundFooter.style';

export const FundFooter = ({ link }: FundFooterProps) => {
  const [isPrivacyFrameModalVisible, setIsPrivacyFrameModalVisible] = useState(false);
  const [showFullDisclosure, setShowFullDisclosure] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('privacy-policy')) {
      setIsPrivacyFrameModalVisible(true);
    }
  }, []);

  return (
    <Footer id="fund-footnotes">
      <SSectionContainer>
        <LegalParagraph size="small" marginBottom="m">
          Investors should carefully consider the investment objectives, risks, charges and expenses
          of the Yieldstreet Alternative Income Fund before investing. The prospectus for the
          Yieldstreet Alternative Income Fund contains this and other information about the Fund and
          can be obtained by emailing investments@yieldstreet.com or by referring to
          yieldstreetalternativeincomefund.com. The prospectus should be read carefully before
          investing in the Fund.
        </LegalParagraph>

        <LegalParagraph size="small" marginBottom="m">
          Investments in the Fund are not bank deposits (and thus not insured by the FDIC or by any
          other federal governmental agency) and are not guaranteed by Yieldstreet or any other
          party.
        </LegalParagraph>

        <LegalParagraph size="small" marginBottom="m">
          The securities described in the prospectus are not offered for sale in the states of
          Nebraska or North Dakota or to persons residing or located in such states. No subscription
          for the sale of Fund shares will be accepted from any person residing or located in
          Nebraska or North Dakota.
        </LegalParagraph>

        <LegalParagraph size="small" marginBottom="m">
          An investment in the Fund is not suitable for investors that require short-term liquidity.
          The Fund's shares have no history of public trading, are not publicly traded, and you
          should not expect to be able to sell your shares regardless of how the Fund performs. The
          Fund's shares are currently not listed on any securities exchange and there is no
          expectation that a secondary market for the Fund's shares will develop in the future.
          Pursuant to its Share Repurchase Program, the Fund intends to periodically repurchase
          shares from the Fund's investors, subject to the discretion of the Fund's Board of
          Directors, but only a limited number of shares will be eligible for repurchase by the
          Fund.
        </LegalParagraph>

        {isPrivacyFrameModalVisible && (
          <PrivacyFrameModal hideModal={() => setIsPrivacyFrameModalVisible(false)} />
        )}

        <AddressContainer>
          <Paragraph inverse>300 Park Ave, 15th Floor, New York, NY 10022</Paragraph>
          <Telephone inverse>
            <SExternalLink href="tel:844-943-5378">844-943-5378</SExternalLink>
          </Telephone>
          <Email inverse>
            <ExternalLink target="_blank" href="mailto:investments@yieldstreet.com">
              investments@yieldstreet.com
            </ExternalLink>
          </Email>
        </AddressContainer>

        <LegalContainer show={showFullDisclosure}>
          <LegalParagraph size="small" marginBottom="m">
            No communication by YieldStreet Alternative Income Fund Inc. (the “Fund”) or YieldStreet
            Management, LLC (the “Adviser”, and together with the Fund, “Yieldstreet™”), through
            this website or any other medium, should be construed or is intended to be a
            recommendation to purchase, sell or hold any security or otherwise to be investment,
            tax, financial, accounting, legal, regulatory or compliance advice. This website does
            not constitute an offer, or the solicitation of any offer, to sell or purchase any
            securities, which may only be made by delivery of a valid prospectus.
          </LegalParagraph>
          <LegalParagraph size="small" marginBottom="m">
            Any financial projections or returns shown on this website are estimated predictions of
            performance only, are hypothetical, are not based on actual investment results and are
            not guarantees of future results. Estimated projections do not represent or guarantee
            the actual results of any transaction, and no representation is made that any
            transaction will, or is likely to, achieve results or profits similar to those shown.
            Any investment information contained herein has been secured from sources that
            Yieldstreet believes are reliable, but we make no representations or warranties as to
            the accuracy of such information and accept no liability therefor.
          </LegalParagraph>

          <LegalParagraph size="small" marginBottom="m">
            Investments in securities offered by the Fund are not bank deposits (and thus not
            insured by the FDIC or by any other federal governmental agency), are not guaranteed by
            Yieldstreet or any other party, and may lose value. Neither the Securities and Exchange
            Commission nor any federal or state securities commission or regulatory authority has
            recommended or approved any investment or the accuracy or completeness of any of the
            information or materials provided by or through this website. Investors must be able to
            afford the loss of their entire investment.
          </LegalParagraph>
          <LegalParagraph size="small" marginBottom="m">
            Investments in securities offered by the Fund are speculative and involve a high degree
            of risk and those investors who cannot afford to lose their entire investment should not
            invest. Such investments are highly illiquid and those investors who cannot hold an
            investment for an indefinite period of time should not invest.
          </LegalParagraph>
          <LegalParagraph size="small" marginBottom="m">
            Articles or information from third-party media outside of this domain may discuss
            Yieldstreet or relate to information contained herein, but Yieldstreet does not approve
            and is not responsible for such content.
          </LegalParagraph>
          <LegalParagraph size="small" marginBottom="m">
            This website provides preliminary and general information about the securities offered
            by the Fund and is intended for initial reference purposes only, and is qualified by and
            subject to more detailed information in the applicable offering materials. This website
            does not constitute an offer to sell or buy any securities. No offer or sale of any
            securities will occur without the delivery of offering materials and related documents.{' '}
            <SExternalLink href={link} target="_blank" rel="noopener noreferrer">
              View Prospectus
            </SExternalLink>
          </LegalParagraph>
          <LegalParagraph size="small" marginBottom="m">
            The Fund's adviser is YieldStreet Management, LLC, an investment advisor registered with
            the Securities and Exchange Commission.
          </LegalParagraph>

          <DisclosureToggle show={showFullDisclosure}>
            {!showFullDisclosure && (
              <DisclosureToggleText onClick={() => setShowFullDisclosure(true)}>
                <Paragraph inverse>Read full disclosure</Paragraph>
                <ChevronDown />
              </DisclosureToggleText>
            )}
            {showFullDisclosure && (
              <DisclosureToggleText onClick={() => setShowFullDisclosure(false)}>
                <ChevronUp />
                <br />
                <Paragraph inverse>Collapse disclosure</Paragraph>
              </DisclosureToggleText>
            )}
          </DisclosureToggle>
        </LegalContainer>

        <Copyright>
          <CopyrightLine inverse>
            Copyright © {new Date().getFullYear()} Yieldstreet Alternative Income Fund Inc.
          </CopyrightLine>
          <SExternalLink href="https://www.yieldstreet.com/privacy/" target="_blank">
            <Paragraph size="small" inverse>
              Privacy Policy
            </Paragraph>
          </SExternalLink>
        </Copyright>
      </SSectionContainer>
    </Footer>
  );
};
