import { useState, useCallback, useEffect } from 'react';
import { fooBar } from './fooBar';

export const useFooBar = (barCallback: (bar: boolean) => void) => {
  const [bar, setBar] = useState(false);

  useEffect(() => {
    if (bar) {
      barCallback(bar);
    }
  }, [bar, barCallback]);

  const triggerBar = useCallback(() => {
    const bar = fooBar(true);
    setBar(bar);
  }, [setBar]);

  return { bar, triggerBar };
};
