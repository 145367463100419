import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';

export const enum InvestmentsListSortTypes {
  CurrentValue = 'currentValue',
  InvestmentAmount = 'investmentAmount',
  Irr = 'irr',
  LastCommentary = 'lastCommentary',
  NoteTitle = 'noteTitle',
  PerformanceStatus = 'performanceStatus',
  TermRemaining = 'termRemaining',
  RequiresUserAction = 'requiresUserAction',
}

export const enum InvestmentsListOrderTypes {
  Ascending = 'asc',
  Descending = 'desc',
}

export const enum InvestmentsListStatusTypes {
  Current = 'current',
  Past = 'past',
}

export const enum InvestmentsListOriginTypes {
  Active = 'active',
  Matured = 'matured',
  Pending = 'pending',
}

export const enum InvestmentsListLabelTypes {
  CurrentBalance = 'Current balance',
  FinalTerm = 'Final term',
  GainLoss = 'Gain/loss',
  Invested = 'Invested',
  Investment = 'Investment',
  Investments = 'Investments',
  LastCommentary = 'Last commentary',
  NetReturn = 'Net annualized return',
  Status = 'Status',
  TargetTerm = 'Target term',
}

export const enum PerformanceStatusTypes {
  Active = 'Active',
  Default = 'Default',
  Pending = 'Pending',
  Watchlist = 'Watchlist',
}

export const enum InvestmentsListQueryParams {
  ActionableItemsOnly = 'actionableItemsOnly',
  Order = 'orderBy',
  Sort = 'sortBy',
  Status = 'status',
}

export interface InvestmentsListQueryParamsValues {
  actionableItemsOnly?: boolean;
  orderBy: InvestmentsListOrderTypes;
  sortBy: InvestmentsListSortTypes;
  status: InvestmentsListStatusTypes;
}

interface UseInvestmentsListConfig {
  refetchOnMountOrArgChange?: boolean;
}

export interface UseInvestmentsListProps {
  investorId?: string;
  managementStrategy: PortfolioStrategyValues;
  params: InvestmentsListQueryParamsValues;
  userId?: number | string;
  config?: UseInvestmentsListConfig;
}

export const INVESTMENTS_LIST_INIT_FILTER_OPTIONS = {
  [InvestmentsListQueryParams.Status]: InvestmentsListStatusTypes.Current,
};

export const INVESTMENTS_LIST_INIT_SORT_OPTIONS = {
  [InvestmentsListQueryParams.Order]: InvestmentsListOrderTypes.Ascending,
  [InvestmentsListQueryParams.Sort]: InvestmentsListSortTypes.NoteTitle,
};

export const INVESTMENTS_LIST_VALID_FILTER_VALUES = {
  [InvestmentsListQueryParams.Status]: [
    InvestmentsListStatusTypes.Current,
    InvestmentsListStatusTypes.Past,
  ],
};

export const INVESTMENTS_LIST_VALID_SORT_VALUES = {
  [InvestmentsListQueryParams.Order]: [
    InvestmentsListOrderTypes.Ascending,
    InvestmentsListOrderTypes.Descending,
  ],
  [InvestmentsListQueryParams.Sort]: [
    InvestmentsListSortTypes.CurrentValue,
    InvestmentsListSortTypes.InvestmentAmount,
    InvestmentsListSortTypes.Irr,
    InvestmentsListSortTypes.LastCommentary,
    InvestmentsListSortTypes.NoteTitle,
    InvestmentsListSortTypes.PerformanceStatus,
    InvestmentsListSortTypes.TermRemaining,
  ],
};
