import { AccreditationType } from '../..';
import { InvestorAccountAiqDocument } from '../../types';

export const aiqDocument: InvestorAccountAiqDocument = {
  id: 13488,
  type: 'Tax',
  title: 'John Doe Tax Return',
  url: 'https://wwww.yieldstreet.com/tax_return.pdf',
  lastReviewDate: '10/12/2019',
  accreditationAmount: 120000,
  accreditationType: AccreditationType.INCOME,
  actionNeeded: false,
  actionNeededReminderTag: '',
  actionNeededTag: '',
  active: true,
  createDate: 2093029309230,
  expired: false,
  investorAccountId: 93933,
  investorAccountName: 'Doe',
  investorFirstName: 'John',
  userDisplayName: 'John Doe',
  userId: 339393,
  verified: false,
};
