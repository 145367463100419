import styled from 'styled-components';

import { media } from '../../themes/media';
import { SliderInput } from '../../form';
import Arrow from './assets/Vector.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.background_default};

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  ${media.large`
    width: 380px;
  `}
`;

export const SSliderInput = styled(SliderInput)`
  margin-bottom: 0 !important;

  ${media.desktop`
    margin-top: auto !important;
  `}

  > span {
    display: none;
  }

  p {
    background-color: ${props => props.theme.colors.background_page};
    border-radius: 4px;
    padding: 6px 10px;
    ${media.desktop`
      display: block;
    `}
  }

  span {
    color: ${props => props.theme.colors.chart_10};
  }

  .MuiSlider-root {
    margin-bottom: 0;

    .MuiSlider-track {
      color: #02c9a5;
      transition: unset;
    }
    .MuiSlider-thumb {
      background: #02c9a5;
      border-radius: 4px;
      height: 18px;
      position: relative;
      transition-property: height, width, box-shadow;
      transition-duration: 0.3s;

      width: 34px;

      &:hover {
        height: 23px;
        width: 39px;
      }

      &:before,
      &:after {
        background-image: url('${Arrow}');
        background-size: cover;
        content: '';
        height: 8px;
        position: absolute;
        bottom: auto;
        width: 5px;
      }

      &:before {
        left: 5px;
        transform: scaleX(-1);
      }

      &:after {
        left: auto;
        right: 5px;
      }
    }
  }
`;

export const BoxContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  column-gap: ${({ theme }) => theme.spacing.m};
`;
