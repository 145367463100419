import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../themes/media';
import { BaseInputProps } from '@yieldstreet/tool-kit';
import { OptionDescription } from './optionStyles';
import { H5 } from '../../content/text/Heading';
import arrow from '../../assets/graphics/icons/arrow.svg';
import { FormErrorContainer } from '../../form/common/FormError';
interface QuestionInputWrapperProps {
  questionTheme: 'primary' | 'secondary' | 'tertiary' | 'tags';
}

export const withQuestion = <Value, CustomInputProps>(
  inputComponentClass: React.ComponentType<BaseInputProps<Value> & CustomInputProps>,
  questionTheme: 'primary' | 'secondary' | 'tertiary' | 'tags'
): FunctionComponent<BaseInputProps<Value> & CustomInputProps> => {
  return props => {
    const InputComponent = inputComponentClass as React.ComponentType<BaseInputProps<Value>>;

    return (
      <QuestionInputWrapper className={props?.className} questionTheme={questionTheme}>
        <InputComponent {...props} />
      </QuestionInputWrapper>
    );
  };
};

const Chevron = css`
  &::after {
    display: block;
    animation: fadeIn ease 1s;
    background: url(${arrow}) no-repeat;
    transform: rotate(-90deg);
    content: '';
    position: absolute;
    width: 11px;
    height: 7px;
    z-index: 1;
    top: calc(50% - (11 / 2));
    right: 20px;
    ${media.desktop`
      right: 30px;
    `}
  }
`;

const Active = css`
  &::after {
    filter: invert(100%);
  }
  ${media.desktop`
    &::after {
      display: none;
    }
  `};

  background-color: ${props => props.theme.colors.background_inverse};
  div,
  p {
    color: ${props => props.theme.colors.text_secondary_paragraph_inverse};
  }
`;

const Disabled = css`
  background-color: ${props => props.theme.colors.background_card};
  opacity: 0.3;
  div,
  p {
    color: ${props => props.theme.colors.text_header};
  }
`;

const ChevronTheme = css`
  div {
    ${H5}
  }
  .MuiFormControlLabel-root {
    transition: background-color 0.25s linear;
    position: relative;
    box-shadow: ${props => props.theme.shadows.shadow_default};
    border-radius: 4px;

    .MuiButtonBase-root {
      display: none;
    }
  }

  [data-radio='true'] {
    .MuiFormControlLabel-root {
      ${Chevron};
      ${media.desktop`
      &::after {
        display: none;
      }
  `};
      &:hover {
        ${Chevron};
      }
      &:active {
        ${Chevron};
      }
    }
  }

  .MuiFormControlLabel-root {
    transition: 0.15s all ease;
    &:active {
      ${Active};
    }
  }

  [data-checked='true'][data-radio='true'] {
    .MuiFormControlLabel-root {
      ${Active};
      ${Chevron};
    }
  }

  [data-disabled='true'] {
    .MuiFormControlLabel-root {
      ${Disabled};
    }
  }

  [data-checked='true'][data-checkbox='true'] {
    .MuiFormControlLabel-root {
      ${Active};
      position: relative;
      padding: 10px 30px 10px 40px;
      &::before {
        animation: fadeIn ease 1s;
        top: calc(50% - 7px);
        left: 21px;
        content: '';
        position: absolute;
        transform: rotate(45deg);
        height: 10px;
        width: 5px;
        border-bottom: 2px solid ${props => props.theme.colors.text_paragraph_inverse};
        border-right: 2px solid ${props => props.theme.colors.text_paragraph_inverse};
      }
      ${media.desktop`
        padding: 20px 30px 20px 40px;
      `};
    }
  }
`;

export const PrimaryTheme = css`
  .MuiButtonBase-root {
    margin-right: 5px;
    margin-left: -5px;
  }

  .MuiFormControlLabel-root {
    background-color: ${props => props.theme.colors.background_card};
    margin: 0;
    margin-bottom: 10px;
    padding: 18px;
    ${media.phoneLandscape`
      margin-bottom: 20px;
    `};
  }
`;

export const SecondaryTheme = css`
  ${ChevronTheme}

  .MuiButtonBase-root {
    margin-right: 5px;
    margin-left: -5px;
  }

  .MuiFormControlLabel-root {
    background-color: ${props => props.theme.colors.background_card};
    margin: 0;
    margin-bottom: 10px;
    padding: 10px 30px 10px 10px;

    ${media.phoneLandscape`
      margin-bottom: 20px;
      padding: 20px 40px 20px 20px;
    `};
  }

  .option-control-label:hover {
    ${media.desktop`
      background-color: ${props => props.theme.colors.text_paragraph};
      .option-label,
      p {
        color: ${props => props.theme.colors.text_paragraph_inverse}!important;
      }
      &:after {
        filter: invert(1);
      }
    `}
  }
`;

export const TertiaryTheme = css`
  ${ChevronTheme}

  .MuiFormControlLabel-root {
    background-color: ${props => props.theme.colors.background_card};
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
  }

  .MuiButtonBase-root {
    padding: 0;
    margin: 0;
  }

  width: 100%;
`;

export const TagsTheme = css`
  ${ChevronTheme}

  & ${FormErrorContainer} {
    all: unset;
  }

  [data-checkbox-group-options='true'] {
    flex-flow: row wrap;
    gap: 16px;
  }

  .MuiFormControlLabel-root {
    padding: 8px 16px 8px 16px;
    margin-left: 0;
    margin-right: 0;
    border: 1px solid ${({ theme }) => theme.colors.border_strong};
    background-color: ${({ theme }) => theme.colors.background_default};

    [data-checkbox-label='true'] {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.text_label};
    }
  }

  [data-checkbox='true'] {
    width: auto;

    &[data-checked='true'] .MuiFormControlLabel-root {
      padding: 8px 16px 8px 28px;
      ::before {
        left: 10px;
      }
    }

    :not([data-checked='true']):not([data-disabled='true']) .MuiFormControlLabel-root:hover {
      ${media.desktop`
          background-color: ${({ theme }) => theme.colors.text_secondary_paragraph_inverse};
          border-color: ${({ theme }) => theme.colors.border_extra_strong};

          [data-checkbox-label='true'] {
            color: ${({ theme }) => theme.colors.text_secondary_paragraph};
          }
        `}
    }
  }
`;

const QuestionInputWrapper = styled.div<QuestionInputWrapperProps>`
  .MuiFormControl-root {
    width: 100%;
    padding: 0;

    > .MuiFormLabel-root {
      margin-bottom: 10px;
      font-size: 20px;
      color: ${props => props.theme.colors.text_paragraph};
    }
  }

  ${OptionDescription} {
    margin-top: 5px;
    margin-bottom: 0;
  }

  ${props => themeSelector(props.questionTheme)}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const themeSelector = (themeName: string) => {
  switch (themeName) {
    case 'tags':
      return TagsTheme;
    case 'tertiary':
      return TertiaryTheme;
    case 'secondary':
      return SecondaryTheme;
    case 'primary':
    default:
      return PrimaryTheme;
  }
};
