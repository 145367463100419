import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { ExternalLink } from '../../content/link';
import { Heading, Paragraph } from '../../content/text';
import { Modal } from '../../elements/modal';
import { PageContainer } from '../../layout/page-container';
import { PageSection } from '../../layout';
import { media } from '../../themes/media';

import LinkedInIcon from '../../assets/graphics/icons/linkedin.svg';

interface BioItem {
  name: string;
  title: string | React.ReactNode;
  image: React.ReactNode;
  bio: React.ReactNode;
  linkedIn?: string;
}

interface BioContainerProps {
  quote: string;
  quoteHeadingType?: 1 | 2 | 3 | 4 | 5;
  quoteByHeadingType?: 1 | 2 | 3 | 4 | 5;
  quoteBy: string;
  bios: BioItem[];
}

export const BioModalContainer: FunctionComponent<BioContainerProps> = ({
  quote,
  quoteHeadingType = 3,
  quoteBy,
  quoteByHeadingType = 4,
  bios,
}) => {
  const [showBio, setShowBio] = useState<number | undefined>();

  return (
    <Background>
      <PageSection>
        <PageContainer>
          <OuterContainer>
            <Container>
              <ContentHolder>
                <SHeading type={quoteHeadingType}>“{quote}”</SHeading>
                <SHeading type={quoteByHeadingType}>{quoteBy}</SHeading>
              </ContentHolder>
              <RightContent>
                {bios.map((bio, index) => (
                  <>
                    <BioHolder key={index}>
                      <ImageHolder>
                        {bio.image}
                        {bio.linkedIn ? (
                          <ExternalLink href={bio.linkedIn} target="_blank">
                            <LinkedIn>
                              <img src={LinkedInIcon} />
                            </LinkedIn>
                          </ExternalLink>
                        ) : (
                          ''
                        )}
                      </ImageHolder>
                      <Paragraph small semiBold>
                        {bio.name}
                      </Paragraph>
                      <Paragraph small>{bio.title}</Paragraph>
                      <Link small>
                        <ExternalLink onClick={() => setShowBio(index)}>Read bio</ExternalLink>
                      </Link>
                    </BioHolder>
                    {showBio === index && <MobileContent>{bio.bio}</MobileContent>}
                  </>
                ))}
              </RightContent>
            </Container>
            {bios.map(
              (bio, index) =>
                showBio === index && (
                  <Modal clickClose={() => setShowBio(undefined)} className="modal" key={index}>
                    <ModalHolder>
                      <ModalLeftContainer>
                        <BioHolder>
                          <ImageHolder>{bio.image}</ImageHolder>
                          <Paragraph small semiBold>
                            {bio.name}
                          </Paragraph>
                          <Paragraph small>{bio.title}</Paragraph>
                        </BioHolder>
                      </ModalLeftContainer>
                      <ModalRightContainer>{bio.bio}</ModalRightContainer>
                    </ModalHolder>
                  </Modal>
                )
            )}
          </OuterContainer>
        </PageContainer>
      </PageSection>
    </Background>
  );
};

const Background = styled.div`
  padding: 40px 0 0 0;
`;

const OuterContainer = styled.div`
  .modal {
    display: none;
    ${media.desktop`
      display: -webkit-box;
    `}
    > div {
      border: none;
      max-width: 900px;
    }
  }
`;

const ModalHolder = styled.div`
  padding: 20px 20px 20px 0;
  display: flex;
`;

const ModalLeftContainer = styled.div`
  width: 25%;
`;

const ModalRightContainer = styled.div`
  width: 75%;
  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`;

const ContentHolder = styled.div`
  width: 100%;
  ${media.desktop`
    width: 40%;
  `}
`;
const RightContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
    width: 60%;
  `}
  justify-content: center;
`;

const SHeading = styled(Heading)`
  margin-bottom: 20px;
`;

const Link = styled(Paragraph)`
  margin-top: 10px;
`;

const MobileContent = styled.div`
  display: block;
  text-align: left;
  margin-top: 40px;
  p:not(:last-child) {
    margin-bottom: 20px;
  }

  ${media.desktop`
    display: none;
  `}
`;

const BioHolder = styled.div`
  width: 100%;
  margin: 20px 0;

  ${media.desktop`
    width: 170px;
    margin: 0 20px;
  `}
  text-align: center;
`;

const ImageHolder = styled.div`
  position: relative;
  width: 175px;
  margin: 0 auto;
  img {
    max-width: 175px;
  }
  ${media.desktop`
    margin: 0 0 20px 0;
  `}
  text-align: center;
`;

const LinkedIn = styled.div`
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 5px 5px 4px 5px;
  background: ${props => props.theme.colors.background_card};
  border-bottom: 1px solid ${props => props.theme.colors.accent_strong};
`;
