import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APP_PERSIST_SLICE_INITIAL_STATE } from './appPersist.model';

export const updateMarketplacePersistance = createAsyncThunk<any, object>(
  'appPersist/marketplace',
  (args, { fulfillWithValue }) => {
    return fulfillWithValue(args);
  }
);

export const appPersist = createSlice({
  name: 'appPersist',
  initialState: APP_PERSIST_SLICE_INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateMarketplacePersistance.fulfilled, (state, action) => {
      state.marketplace = { ...state.marketplace, ...action.payload };
    });
  },
});

export const selectMarketplacePersist = (state: any) => {
  return state.appPersist?.marketplace || APP_PERSIST_SLICE_INITIAL_STATE.marketplace;
};
