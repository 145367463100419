import React from 'react';
import { Investment } from '@yieldstreet/tool-kit';

import { theme2 as legacyTheme } from '../../../../themes/theme2';

import EarningIcon from '../../assets/earning-icon.svg';
import EarningPaymentsIcon from '../../assets/earning-payments-icon.svg';
import PaymentsIcon from '../../assets/payments-icon.svg';

export interface PortfolioSimulatorProps {
  amounts: number[];
  investments: Investment[];
  showTotalInvestment: boolean;
}

export const enum ChartDisplay {
  PAYMENTS,
  EARNINGS,
  BOTH,
}

export const TabsMobileDesc = {
  [ChartDisplay.EARNINGS]: 'Earnings',
  [ChartDisplay.BOTH]: 'Earnings and Payments',
  [ChartDisplay.PAYMENTS]: 'Payments',
};

export const TabsMobile = [
  {
    key: ChartDisplay.EARNINGS,
    icon: <img alt="Earning Icon" src={EarningIcon} />,
  },
  {
    key: ChartDisplay.BOTH,
    icon: <img alt="Earning / Payments Icon" src={EarningPaymentsIcon} />,
  },
  {
    key: ChartDisplay.PAYMENTS,
    icon: <img alt="Payments Icon" src={PaymentsIcon} />,
  },
];

export const TabsDesktop = [
  {
    title: 'Earnings',
    key: ChartDisplay.EARNINGS,
  },
  {
    title: 'Both',
    key: ChartDisplay.BOTH,
  },
  {
    title: 'Payments',
    key: ChartDisplay.PAYMENTS,
  },
];

// FIX ME - replace with new chart colors when ready
export const ColumnColors = [
  legacyTheme.colors.chart_3,
  legacyTheme.colors.chart_5,
  legacyTheme.colors.chart_10,
];
export const ChartColors = [legacyTheme.colors.chart_1, legacyTheme.colors.chart_14];
