import { BeneficialOwner } from '../../types';
import { BeneficialOwnerStatus } from '../../enums';

export const beneficialOwner: BeneficialOwner = {
  id: 90729,
  email: 'john.doe@yieldstreet.com',
  firstName: 'John',
  lastName: 'Doe',
  status: BeneficialOwnerStatus.PENDING,
  createDate: '2020-11-19 08:22:38',
  updateDate: '2020-11-19 08:22:38',
  expireDate: '2020-11-26 08:22:38',
};
