import React, { FunctionComponent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { BaseInputProps, withFormik } from '@yieldstreet/tool-kit';

import {
  withQuestion,
  SFormHelperText,
  FormError,
  OptionControl,
  OptionLabel,
  OptionDescription,
  OptionControlLabel,
  useLabelId,
} from '../common';

export interface CheckboxInputProps extends BaseInputProps<boolean> {
  description?: string;
  descriptionCheckedOnly?: boolean;
  indeterminate?: boolean;
}

export const CheckboxInput: FunctionComponent<CheckboxInputProps> = props => {
  const {
    testId,
    label,
    value,
    error,
    onChange,
    name,
    caption,
    className,
    disabled,
    description,
    descriptionCheckedOnly,
    ...otherProps
  } = props;

  const labelId = useLabelId(name);

  return (
    <OptionControl
      error={!!error}
      className={className}
      data-checkbox
      data-checked={value}
      data-disabled={disabled}
      id={labelId}
    >
      <OptionControlLabel
        disabled={disabled}
        label={
          <>
            <OptionLabel data-checkbox-label>{label}</OptionLabel>
            {(!descriptionCheckedOnly || value) && description && (
              <OptionDescription small data-checkbox-description>
                {description}
              </OptionDescription>
            )}
          </>
        }
        control={
          <Checkbox
            data-checkbox-input
            data-cy={testId || 'checkbox'}
            {...otherProps}
            name={name}
            checked={value}
            onChange={onChange && ((_e, checked) => onChange(checked))}
          />
        }
      />

      {error || caption ? (
        <SFormHelperText margin="dense">
          {error ? (
            <FormError
              testId="error"
              type="checkbox-input"
              name={props?.name || 'checkbox'}
              error={error}
            />
          ) : (
            caption
          )}
        </SFormHelperText>
      ) : null}
    </OptionControl>
  );
};

export const FormCheckboxInput = withFormik(CheckboxInput);

export const QuestionCheckboxInput = withQuestion(CheckboxInput, 'primary');
export const QuestionSecondaryCheckboxInput = withQuestion(CheckboxInput, 'secondary');

export const FormQuestionSecondaryCheckboxInput = withFormik(QuestionSecondaryCheckboxInput);
