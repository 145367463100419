import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { media } from '../../../../themes/media';
import { Paragraph, Label } from '../../../../content/text';
import { Button } from '../../../../content/button';

import { RowProps, MobileStyleProps, ContentProps } from './ProgressTableRow.model';

export const Row = styled.div<RowProps>`
  border-top: 1px solid ${props => props.theme.colors.border_default};
  margin: 0 12px;
  padding: 20px 8px;
  ${media.tablet`
    margin: 0;
    padding: 20px 35px;
  `};
`;

export const Desktop = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `};
`;

export const Mobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.tablet`
    display: none;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
`;

export const LeftElement = styled.div`
  min-width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UpdatedDate = styled(Paragraph)`
  color: ${props => props.theme.colors.text_label};
`;

export const ErrorIcon = styled.img`
  height: 18px;
  margin-right: 15px;
  margin-top: 0;
  ${media.tablet`
    height: 24px;
    margin-right: 19px;
    margin-top: 4px;
  `};
`;

export const RightElement = styled.div``;

export const RowTitle = styled(Paragraph)<MobileStyleProps>`
  color: ${props => (!props.light || props.error ? `unset` : props.theme.colors.text_label)};
`;

export const Description = styled(Paragraph)`
  color: ${props => props.theme.colors.text_label};
  max-width: 660px;
  margin-top: 21px;
`;

export const MobileDate = styled(Label)`
  margin-top: 8px;
`;

export const DesktopSubTitle = styled(Description)`
  margin-top: 0;
`;

export const MobileSubTitle = styled(Description)`
  margin-top: 23px;
`;

export const MobileButton = styled(Button)`
  margin-top: 24px;
`;

export const IconHolder = styled.div<MobileStyleProps>`
  min-width: ${props => props.error && '47px'};
  display: flex;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }
`;

export const AnimatedContent = styled.div<ContentProps>`
  overflow: hidden;
  height: auto;
  max-height: ${props => (props.show ? '1000px' : '0px')};
  transition: max-height 0.7s ease;
`;

export const SFontAwesomeIcon = styled(FontAwesomeIcon)<MobileStyleProps>`
  color: ${props => (props.light ? props.theme.colors.text_label : `unset`)};
`;

export const ButtonContainer = styled.div`
  margin-left: 20px;
`;
