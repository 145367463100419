import { HttpMethods } from '../../enums/http';

import { restApi, RTKQAll, Tags } from '../common';
import {
  BulkInvestmentEligibilityApiResponse,
  BulkInvestmentEligibilityRequest,
} from './deprecated';
import {
  Investment,
  InvestmentGetApiResponse,
  ManagedPortfolioEligibilityResponse,
} from './investment.model';

const BASE_URL = '/a/api/investment';

export const investmentApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.ManagedPortfolioEligibility, Tags.InvestmentEligibility, Tags.Investments],
  })
  .injectEndpoints({
    endpoints: builder => {
      const managedPortfolioEligibility = builder.query<
        ManagedPortfolioEligibilityResponse,
        RTKQAll
      >({
        query: () => ({
          url: `${BASE_URL}/managed-portfolio-eligibility`,
          method: HttpMethods.Get,
        }),
        keepUnusedDataFor: 0,
        providesTags: [Tags.ManagedPortfolioEligibility],
      });

      const bulkInvestmentEligibility = builder.mutation<
        BulkInvestmentEligibilityApiResponse,
        BulkInvestmentEligibilityRequest
      >({
        query: data => ({
          url: `${BASE_URL}/bulk-investment-eligibility`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.InvestmentEligibility],
      });

      const getAllInvestments = builder.query<Investment[], RTKQAll>({
        query: () => ({
          url: `${BASE_URL}/all`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.Investments],
        transformResponse: (response: InvestmentGetApiResponse) => response.investments,
      });

      return {
        managedPortfolioEligibility,
        bulkInvestmentEligibility,
        getAllInvestments,
      };
    },
  });
