import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';

import { transformAllInvestorEntityStatusResponse } from './investor-entity-status.utils';
import {
  InvestorEntityAllStatus,
  InvestorEntityStatus,
  InvestorEntityStatusApiRequest,
  InvestorEntityStatusApiResponse,
} from './investor-entity-status.model';

export const investorEntityStatusApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.InvestorEntityStatus],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getAllInvestorEntityStatus = builder.query<InvestorEntityStatusApiResponse, {}>({
        query: () => {
          return {
            url: '/a/api/investor-entity/all/status',
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.InvestorEntityStatus],
        transformResponse: transformAllInvestorEntityStatusResponse,
      });

      const getInvestorEntityStatus = builder.query<
        InvestorEntityStatus,
        InvestorEntityStatusApiRequest
      >({
        query: ({ investorEntityId }) => ({
          url: `/a/api/investor-entity/${investorEntityId}/status`,
          method: HttpMethods.Get,
        }),
        transformResponse: (response: InvestorEntityAllStatus) => {
          return response.status;
        },
        providesTags: [Tags.InvestorEntityStatus],
      });

      return {
        getAllInvestorEntityStatus,
        getInvestorEntityStatus,
      };
    },
  });
