import { HeadingType } from './../../content/text/Heading';
import { css, FlattenInterpolation, ThemeProps } from 'styled-components';

import { media } from '../../themes/media';
import InverseCloseIcon from '../../assets/graphics/icons/close-icon-inverse.svg';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import CryptoBg from '../../assets/graphics/backgrounds/crypto-bg.svg';
import FreeSharesBg from '../../assets/graphics/backgrounds/free-shares.png';

type CustomThemeProps = {
  [key: number]: {
    container?: FlattenInterpolation<ThemeProps<any>>;
    buttonWrapper?: FlattenInterpolation<ThemeProps<any>>;
    iconStyle?: FlattenInterpolation<ThemeProps<any>>;
    textWrapper?: FlattenInterpolation<ThemeProps<any>>;
    titleType?: HeadingType;
    closeIcon?: string;
    buttonType?: 'primary' | 'secondary' | 'link';
    isInverse?: boolean;
    image?: string;
  };
};

const cryptoBg = css`
  background: url(${CryptoBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const customThemeObj: CustomThemeProps = {
  1: {
    container: css`
      flex-direction: column;
      padding: ${({ theme }) => theme.spacing.l};
      ${cryptoBg}
    `,
    buttonWrapper: css`
      margin: ${({ theme }) => theme.spacing.m} 0 0;
    `,
    titleType: 3,
    closeIcon: InverseCloseIcon,
    buttonType: 'primary',
    isInverse: true,
  },
  2: {
    container: css`
      flex-direction: row;
      padding: ${({ theme }) => theme.spacing.sm};
      justify-content: space-between;
      ${cryptoBg}
    `,
    buttonWrapper: css`
      margin: 0 40px 0;
      align-self: center;
    `,
    titleType: 4,
    closeIcon: InverseCloseIcon,
    buttonType: 'primary',
    isInverse: true,
  },
  3: {
    container: css`
      flex-direction: column;
      padding: ${({ theme }) => theme.spacing.sm};
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.active_alt};
      ${media.tablet`
        flex-direction: row;
      `}
    `,
    buttonWrapper: css`
      align-self: center;
      ${media.tablet`
        margin-left: ${({ theme }) => theme.spacing.xs};
      `}
    `,
    iconStyle: css`
      top: 0;
      bottom: 0;
      margin: auto;
      right: 10px;
      ${media.tablet`
        right: 15px;
      `}
    `,
    textWrapper: css`
      text-align: center;
    `,
    closeIcon: CloseIcon,
    buttonType: 'link',
    isInverse: false,
  },
  4: {
    image: FreeSharesBg,
    titleType: 4,
    closeIcon: InverseCloseIcon,
    buttonType: 'primary',
  },
};
