import { genTestIds } from '@yieldstreet/tool-kit';

import SuccessIcon from '../../assets/graphics/status/success.svg';
import FailureIcon from '../../assets/graphics/status/failure.svg';
import PendingIcon from '../../assets/graphics/status/pending.svg';
import WarningIcon from '../../assets/graphics/status/warning.svg';

export const { getTestIds, useTestIds } = genTestIds([]);

export enum ProcessStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  WARNING = 'WARNING',
  PENDING = 'PENDING',
  // INFO = 'INFO',
}

export const statusIcons = {
  [ProcessStatus.SUCCESS]: SuccessIcon,
  [ProcessStatus.FAILURE]: FailureIcon,
  [ProcessStatus.WARNING]: WarningIcon,
  [ProcessStatus.PENDING]: PendingIcon,
};
