import React, { FC } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material';

import { withFormikWeb } from '../common';
import { TextInput, TextInputProps } from './TextInput';

export interface MaskFormatOwnProps
  extends Omit<PatternFormatProps, 'onClick' | 'onChange' | 'onBlur' | 'suffix' | 'prefix'> {
  useFormattedValue?: boolean;
}

const MaskFormatInput: FC<MaskFormatOwnProps & InputBaseComponentProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, defaultValue, format, useFormattedValue, allowLeadingZeros, ...otherProps } =
    props;

  return (
    <PatternFormat
      {...otherProps}
      // allowLeadingZeros={allowLeadingZeros}
      onValueChange={({ value, formattedValue }) => {
        onChange &&
          onChange({
            target: { value: useFormattedValue ? formattedValue : value },
          } as unknown as React.FormEvent<HTMLInputElement>);
      }}
      format={format}
    />
  );
};

export const MaskTextInput: FC<MaskFormatOwnProps & TextInputProps> = props => {
  return (
    <TextInput
      {...props}
      maskClassName={
        props.value ? 'MuiInputLabel-shrink MuiInputLabel-filled Mui-focused Mui-focused' : ''
      }
      inputComponent={MaskFormatInput as React.ElementType<InputBaseComponentProps>}
    />
  );
};

export const FormMaskTextInput = withFormikWeb(MaskTextInput);
