import invert from 'lodash/invert';
import { Audiences, TierLevels, AudiencePresetTypes } from '../../enums/audiences/audiences';

export const AudienceKeyMap = invert(Audiences);

export type AudienceKey = keyof typeof Audiences;
export type AudiencePreset =
  | AudiencePresetTypes.ANALYTICS
  | AudiencePresetTypes.DASHBOARD
  | AudiencePresetTypes.PORTFOLIO
  | AudiencePresetTypes.LOGIN
  | AudiencePresetTypes.ACCOUNT_SETUP;

export const AudiencePresets: { [key in AudiencePreset]: AudienceKey[] } = {
  login: [
    'repeatInvestor',
    'tier',
    'hasWallet',
    'hasPortfolio',
    'adviceEligible',
    'adviceReady',
    'adviceClient',
  ],
  analytics: [
    'altExperienced',
    'firstTimeInvestor',
    'repeatInvestor',
    'tier',
    'hasWallet',
    'hasPortfolio',
    'adviceEligible',
    'adviceReady',
    'adviceClient',
  ],
  dashboard: ['altExperienced', 'tier', 'hasWallet', 'hasPortfolio'],
  portfolio: [
    'altExperienced',
    'firstTimeInvestors',
    'allNAI',
    'noInvestmentPreferences',
    'tier',
    'hasWallet',
    'hasPortfolio',
    'portfolioSizeAbove1M',
    'adviceEligible',
    'adviceReady',
    'adviceClient',
  ],
  accountSetup: ['portfolioSizeAbove1M', 'tier', 'hasWallet'],
};

export type AudienceRequest = {
  [key in Audiences]?: Object;
};

export type AudienceResponse = {
  [key in Audiences]?: boolean;
};

export type AudienceResolution<K extends AudienceKey = AudienceKey> = {
  [key in K]?: boolean;
};

export type TierLevelKey = keyof typeof TierLevels;

export type TierAudienceMetaData = {
  level: TierLevelKey;
  expiry: string | null;
  rangeStart: number;
  rangeEnd: number;
  isOptedIn: boolean;
  startDate: string;
  portfolioValue: number;
  percentageInTier: number;
  leftForNextTier: number;
};

export type AudienceResponseMetaData = {
  [key in Audiences]?: {
    active: boolean;
    meta: null | TierAudienceMetaData;
  };
};

export type AudienceResolutionMetaData<K extends AudienceKey = AudienceKey> = {
  [key in K]?: {
    active: boolean;
    meta: TierAudienceMetaData;
  };
};
