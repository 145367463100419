import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReducerPaths } from '../common';
import { ApplicationConfig } from './config.model';

// FIXME
export const configApi: any = createSlice({
  name: ReducerPaths.Config,
  initialState: {},
  reducers: {
    addConfig: (_, action: PayloadAction<ApplicationConfig>) => action.payload,
  },
});
