import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '5%',
  left: '50%',
  width: '360px',
  transform: 'translate(-50%, 0)',
  zIndex: 4,

  [theme.breakpoints.up('sm')]: {
    bottom: '10%',
    width: '700px',
  },
}));

export const STypography = styled(Typography)<{ inverse?: boolean }>(({ inverse, theme }) => ({
  color: inverse
    ? theme.palette.text.paragraphPrimary.inverse
    : theme.palette.text.paragraphPrimary.default,
}));

export const Container = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  position: 'relative',
  padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
  borderRadius: '50px',
  backgroundColor: theme.palette.background.dark,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const CloseImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(2)}`,
  right: theme.spacing(2),
  padding: theme.spacing(2),
  height: '28px',
  borderRadius: '50px',
  border: `1px solid ${theme.palette.border.default}`,
  background: theme.palette.background.default,
  cursor: 'pointer',
}));
