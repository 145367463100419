import React, { useState, useEffect, useMemo, useContext } from 'react';
import { sizeProps, Size, SizeMap, FriendlySize } from '../../themes/media';
import { isArray } from 'lodash';

const hasWindow = typeof window !== 'undefined' && window.document && window.document.createElement;

export const isResponsiveMatch = (size: Size, defaultSize: Size = 'mobile', forceSize?: Size) => {
  const props = sizeProps[size as Size];
  if (forceSize) {
    return size === forceSize;
  } else if (hasWindow) {
    return props[0] === 'min' && window.innerWidth >= +props[1];
  } else {
    return size === defaultSize;
  }
};

export const responsiveLoop = (
  callback: (size: Size, match: boolean) => void,
  defaultSize?: Size,
  onMatchOnly?: boolean,
  forceSize?: Size
) =>
  Object.keys(sizeProps)
    .reverse()
    .filter(size => isArray(sizeProps[size as Size]))
    .every(size => {
      const isMatch = isResponsiveMatch(size as Size, defaultSize, forceSize);
      if (onMatchOnly) {
        if (isMatch) {
          callback(size as Size, isMatch);
          return false;
        }
      } else {
        callback(size as Size, isMatch);
      }
      return true;
    });

export const responsive = (
  callback: (size: Size) => void,
  defaultSize: Size = 'mobile',
  forceSize?: Size
) => {
  const checkViewPort = (): Size => {
    let viewPortSize: Size = defaultSize;
    responsiveLoop(
      size => {
        viewPortSize = size as Size;
      },
      defaultSize,
      true,
      forceSize
    );
    return viewPortSize;
  };

  let viewPort = checkViewPort();
  callback(viewPort);

  hasWindow &&
    window.addEventListener(
      'resize',
      () => {
        const currentViewPort = checkViewPort();
        callback && callback(currentViewPort);
      },
      false
    );
};

export const isResponsiveMinSize = (minSize: Size, defaultSize?: Size, forceSize?: Size) => {
  let minSizeMatch = false;
  let curSizeMatch = false;
  responsiveLoop(
    (size, match) => {
      curSizeMatch = match;
      if (curSizeMatch) {
        if (minSize === size) {
          minSizeMatch = true;
        }
      }
    },
    defaultSize,
    false,
    forceSize
  );
  return minSizeMatch;
};

export const isResponsiveMaxSize = (maxSize: Size, defaultSize?: Size, forceSize?: Size) => {
  let maxSizeMatch = false;
  let curSizeMatch = false;
  responsiveLoop(
    (size, match) => {
      if (!curSizeMatch) {
        if (maxSize === size) {
          maxSizeMatch = true;
        }
        curSizeMatch = match;
      }
    },
    defaultSize,
    false,
    forceSize
  );
  return maxSizeMatch;
};

export const ResponsiveContext = React.createContext<Size | undefined>(undefined);

// Returns the current viewport size (e.g. mobile, tablet, desktop, large)
// e.g. const viewPort = useResponsive();
export const useResponsive = (defaultSize?: Size) => {
  const forceSize = useContext(ResponsiveContext);

  const [viewPort, setViewPort] = useState<Size | undefined>(() => {
    let initSize;
    responsive(
      size => {
        initSize = size;
      },
      defaultSize,
      forceSize
    );
    return initSize;
  });

  useEffect(() => {
    responsive(setViewPort, defaultSize, forceSize);
  }, [setViewPort, defaultSize, forceSize]);

  return viewPort;
};

export const useResponsiveFriendly = (defaultSize?: FriendlySize) => {
  const isMobile = useResponsiveMaxSize('phoneLandscape', defaultSize);
  const isTablet = useResponsiveMaxSize('desktop', defaultSize);

  const friendlyViewPort: FriendlySize | undefined = useMemo(() => {
    switch (true) {
      case isMobile:
        return 'mobile';
      case isTablet:
        return 'tablet';
      default:
        return 'desktop';
    }
  }, [isMobile, isTablet]);

  return friendlyViewPort;
};

// Returns true if the current viewport size either equal or larger than the minimum size passed as the first parameter, else returns false.
// e.g. const isTabletOrBigger = useResponsiveMinSize('tablet');
export const useResponsiveMinSize = (minSize: Size, defaultSize?: Size) => {
  const forceSize = useContext(ResponsiveContext);
  const viewPort = useResponsive(defaultSize);

  const isMinSize = useMemo(() => {
    return viewPort ? isResponsiveMinSize(minSize, defaultSize, forceSize) : false;
  }, [defaultSize, forceSize, minSize, viewPort]);

  return isMinSize;
};

// Returns true if the current viewport size either equal or smaller than the maximum size passed as the first parameter, else returns false.
// e.g. const isDesktopOrSmaller = useResponsiveMaxSize('desktop');
export const useResponsiveMaxSize = (minSize: Size, defaultSize?: Size) => {
  const forceSize = useContext(ResponsiveContext);
  const viewPort = useResponsive(defaultSize);

  const isMinSize = useMemo(() => {
    return viewPort ? isResponsiveMaxSize(minSize, defaultSize, forceSize) : false;
  }, [defaultSize, forceSize, minSize, viewPort]);

  return isMinSize;
};

export interface ResponsiveProps<P extends Object> {
  responsiveProps?: Partial<SizeMap<Size, Partial<P>>>;
}

export interface ResponsiveComponentState<P> {
  currentViewPort: Size;
  currentPropsSize?: Size | null;
  currentProps?: Partial<P> | null;
}
