import { HttpMethods } from '../../../enums/http';
import { restApi, Tags } from '../../common';
import { StateList, USStateListResponse } from './address.model';

export const addressApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.Address],
  })
  .injectEndpoints({
    endpoints: build => {
      const getUSStateList = build.query<StateList, void>({
        query: () => ({
          url: `/a/api/dic/address/states`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.Address],
        transformResponse: (response: USStateListResponse) => response.states,
      });

      return {
        getUSStateList,
      };
    },
  });
