import * as api from '@opentelemetry/api';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { Exception } from '@opentelemetry/api';

type SpanCallback = (span: api.Span) => void;
type SpanCallbackConfig = {
  onStart?: SpanCallback;
  onEnd?: SpanCallback;
  onSuccess?: SpanCallback;
  onError?: SpanCallback;
};

export class Tracer {
  private _tracer: api.Tracer;

  private static _globalSpanCallbacks: SpanCallbackConfig = {};
  private static _globalAttributes: api.Attributes = {};

  constructor(tracerName: string, private _spanCallbacks: SpanCallbackConfig = {}) {
    this._tracer = api.trace.getTracer(tracerName);
  }

  static setGlobalAttributes(attributes: api.Attributes) {
    this._globalAttributes = omitBy(
      {
        ...this._globalAttributes,
        ...attributes,
      },
      isUndefined
    );
  }

  static setGlobalCallbacks(callbacks: SpanCallbackConfig) {
    this._globalSpanCallbacks = omitBy(
      {
        ...this._globalSpanCallbacks,
        ...callbacks,
      },
      isUndefined
    );
  }

  executeCallback(key: keyof SpanCallbackConfig, span: api.Span) {
    const globalCallback = Tracer._globalSpanCallbacks[key];
    if (globalCallback) {
      globalCallback(span);
    }

    const tracerCallback = this._spanCallbacks[key];
    if (tracerCallback) {
      tracerCallback(span);
    }
  }

  startSpan<F extends (span: api.Span) => ReturnType<F>>(
    spanName: string,
    attributes: api.Attributes = {},
    parent: api.Span | undefined,
    worker: F
  ): ReturnType<F> {
    const result = this._tracer.startActiveSpan(
      spanName,
      {
        attributes: {
          ...Tracer._globalAttributes,
          ...omitBy(attributes, isUndefined),
        },
      },
      parent ? api.trace.setSpan(api.context.active(), parent) : api.context.active(),
      span => {
        this.executeCallback('onStart', span);
        return worker(span);
      }
    );

    return result;
  }

  handleSpanSuccess(span: api.Span) {
    span.setStatus({ code: api.SpanStatusCode.OK });
    this.executeCallback('onSuccess', span);
  }

  handleSpanError(span: api.Span, error?: Exception) {
    if (error && span.isRecording()) {
      const errorString = isString(error) ? error : error.stack;
      span.recordException(error);
      span.setStatus({ code: api.SpanStatusCode.ERROR, message: errorString });
      this.executeCallback('onError', span);
    }
  }

  endSpan(span: api.Span) {
    if (span.isRecording()) {
      this.executeCallback('onEnd', span);
      span.end();
    }
  }

  trace<F extends (span: api.Span) => ReturnType<F>>(
    worker: (span: api.Span) => any,
    spanName: string,
    attributes?: api.Attributes,
    parent?: api.Span
  ) {
    return this.startSpan(spanName, attributes, parent, span => {
      try {
        const result = worker(span);
        this.handleSpanSuccess(span);
        return result;
      } catch (error) {
        this.handleSpanError(span, error as Exception);
        throw error;
      } finally {
        this.endSpan(span);
      }
    });
  }

  traceAsync<F extends (span: api.Span) => ReturnType<F>>(
    worker: (span: api.Span) => Promise<any>,
    spanName: string,
    attributes?: api.Attributes,
    parent?: api.Span
  ) {
    return this.startSpan(spanName, attributes, parent, async span => {
      try {
        const result = await worker(span);
        this.handleSpanSuccess(span);
        return result;
      } catch (error) {
        this.handleSpanError(span, error as Exception);
        throw error;
      } finally {
        this.endSpan(span);
      }
    });
  }
}
