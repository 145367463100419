import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Paragraph } from '../../content/text';

export const NotificationBanner: FunctionComponent = ({ children }) => {
  return (
    <Slogan>
      <SloganText inverse>{children}</SloganText>
    </Slogan>
  );
};

const Slogan = styled.div`
  background: ${props => props.theme.colors.chart_3};
  text-align: center;
  width: 100%;
  padding: 10px 20px;
`;

const SloganText = styled(Paragraph)`
  line-height: 1.5;
  vertical-align: middle;
  display: inline-block;
`;
