import { ActivityEvent, ActivityEventType, ActivityMessageTemplateId } from '../../apis';

export const MOCK_ACTIVITY_ITEM_LINK_DATA = {
  userId: 1,
  messageId: 191828,
};

export const MOCK_MESSAGE_DEFAULT = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
  },
};

export const MOCK_MESSAGE_CUSTOM_UPDATE_QPS = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.CustomUpdate,
    activityEventType: ActivityEventType.QuarterlySnapshot,
  },
};

export const MOCK_MESSAGE_CUSTOM_ACTIVITY_WALLET_BALANCE_PROMO = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.CustomActivity,
    activityEventType: ActivityEventType.WalletBalancePromo,
  },
};

export const MOCK_MESSAGE_MOMENTS_OF_JOY_REGISTRATION = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  messageTemplateId: ActivityMessageTemplateId.MomentsOfJoyRegistration,
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.MomentsOfJoy,
  },
};

export const MOCK_MESSAGE_MOMENTS_OF_JOY_FIRST_TIME_INVESTMENT = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  messageTemplateId: ActivityMessageTemplateId.MomentsOfJoyFirstTimeInvestment,
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.MomentsOfJoy,
  },
};

export const MOCK_MESSAGE_INVESTMENT_CANCELLED = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.InvestmentCancelled,
  },
};

export const MOCK_MESSAGE_DISTRIBUTION_PAYMENT = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.DistributionPayment,
  },
};

export const MOCK_MESSAGE_INVESTMENT_UPDATE = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.InvestmentUpdate,
  },
};

export const MOCK_MESSAGE_RECURRING_PAYMENT_WARNING = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.RecurringPaymentWarning,
  },
};

export const MOCK_MESSAGE_DEAL_MATURED = {
  startAt: '2022-02-01T17:25:02.965Z',
  sortDate: '2022-02-01T17:25:02.965Z',
  configuration: {
    title: 'abc123',
    text: 'qwerty',
    activityEvent: ActivityEvent.DealMatured,
  },
};
