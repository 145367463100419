import { Tags, restApi } from '../common';
import { HttpMethods } from '../../enums/http';
import {
  AdviceServiceResponse,
  AssignStrategyRequest,
  GetManagedPortfolioRequest,
  GetManagedPortfolioResponse,
  GetSuggestionsParams,
  GetSuggestionsResponse,
} from './adviceEngine.model';

export const adviceEngineApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.AdviceSuggestions,
      Tags.AdviceManagedPortfolio,
      Tags.ManagedPortfolioEligibility,
    ],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => {
      const assignStrategy = builder.mutation<{}, AssignStrategyRequest>({
        query: data => ({
          url: `/api/advice-engine/strategy/assign`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: [Tags.AdviceSuggestions, Tags.ManagedPortfolioEligibility],
      });

      const getSuggestions = builder.query<GetSuggestionsResponse, GetSuggestionsParams>({
        query: ({ investorEntityId }) => ({
          url: `/api/advice-engine/strategy/${investorEntityId}/suggested`,
          method: HttpMethods.Get,
        }),
        transformResponse: (res: AdviceServiceResponse<GetSuggestionsResponse>) => res.response,
        providesTags: [Tags.AdviceSuggestions],
      });

      const getManagedPortfolio = builder.query<
        GetManagedPortfolioResponse,
        GetManagedPortfolioRequest
      >({
        query: ({ investorEntityId }) => ({
          url: `/api/advice-engine/managed-portfolio/${investorEntityId}`,
          method: HttpMethods.Get,
        }),
        transformResponse: (res: AdviceServiceResponse<GetManagedPortfolioResponse>) => {
          return res.response;
        },
        providesTags: [Tags.AdviceManagedPortfolio],
      });

      return {
        assignStrategy,
        getManagedPortfolio,
        getSuggestions,
      };
    },
  });
