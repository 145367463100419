import {
  GetUserAddressParams,
  GetUserAddressResponse,
  UpdateUserAddressParams,
  UpdateUserAddressResponse,
} from './user-address.model';
import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums/http';

export const userAddressApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserProfileAddress, Tags.UserContact],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getUserAddress = builder.query<GetUserAddressResponse, GetUserAddressParams>({
        query: ({ userId }) => ({
          url: `/a/api/user/${userId}/address`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.UserProfileAddress],
      });

      const updateUserAddress = builder.mutation<
        UpdateUserAddressResponse,
        UpdateUserAddressParams
      >({
        query: ({ userId, address }) => ({
          url: `/a/api/user/${userId}/address`,
          method: HttpMethods.Post,
          data: address,
        }),
        invalidatesTags: [Tags.UserProfileAddress, Tags.UserContact],
      });

      return {
        getUserAddress,
        updateUserAddress,
      };
    },
  });
