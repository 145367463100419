import styled from 'styled-components';
import { Paragraph } from '../../content/text';
import { SFormControl, SFormControlLabel } from './formStyles';

export const OptionControl = styled(SFormControl)`
  &.MuiFormControl-root {
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const OptionControlLabel = styled(SFormControlLabel)`
  & .MuiButtonBase-root {
    align-self: start;
    padding-top: 0;
    padding-bottom: 0;
    padding: 5px;
    margin: -4px 4px;
    color: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.input_disabled_text
        : theme.colors.input_background_selected} !important;
  }

  & .MuiTypography-root {
    color: ${props => props.theme.colors.text_paragraph};
  }
`;

export const OptionLabel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row,
  margin-top: 4px;
`;

export const OptionDescription = styled(Paragraph)`
  color: ${props => props.theme.colors.text_label};
`;
