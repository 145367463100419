import React from 'react';
import { CSSProperties } from 'styled-components';

export const TabTestIds = {
  container: 'tab-container',
  icon: 'tab-icon',
};

export interface TabComponentProps extends TabContainerProps {
  title?: string;
  icon?: React.ReactNode;
  onClick?: (event: React.SyntheticEvent) => void;
  testId?: string;
  numericBadge?: number;
  vertical?: boolean;
  textWrap?: CSSProperties['whiteSpace'];
}

export interface TabContainerProps {
  isActive?: boolean;
  disableIconInverse?: boolean;
  vertical?: boolean;
}

export interface TabBadgeProps extends TabContainerProps {
  isLarge?: boolean;
}
