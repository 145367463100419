import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 998;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
`;

export const Container = styled.div`
  background: ${props => props.theme.colors.background_inverse};
  height: 68px;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  transition: background 0.5s;

  &.transparent {
    background: rgba(255, 255, 255, 0);
    border-bottom: none;
  }
  box-sizing: border-box;
  ${media.large`
    height: 74px;
    padding: 0;
  `};
  ${media.print`
    display: none;
  `}
`;

export const Flex = styled.div`
  display: flex;
`;

export const LogoWrap = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.spacing.m};

  a {
    display: flex;
  }

  img {
    width: 145px;
    min-height: 39px;
    ${media.large`
      width: 185px;
      height: 100%;
      min-height: 50px;
    `};
  }
`;

export const RightButtonWrapper = styled.div`
  margin: auto 0;
  padding-right: ${({ theme }) => theme.spacing.m};
`;
