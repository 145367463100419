import { PortfolioOverview } from '../models';
import { PerformanceStatus } from '../../../../enums';
import {
  InvestmentEngine,
  InvestmentStatusTypes,
  InvestmentTypes,
  TenderStatus,
} from '../../../../types';

export const portfolioOverviewDebtDefault: PortfolioOverview = {
  userId: 109,
  accountSummary: {
    outstandingPrincipal: -0.01,
    outstandingPrincipalPreDefault: 0.0,
    outstandingPrincipalPostDefault: -0.01,
    accruedInvestmentInterest: 0.0,
    accruedInvestmentInterestPreDefault: 0.0,
    accruedInvestmentInterestPostDefault: 0.0,
    balanceInDefault: -0.01,
    balanceByPerformanceStatus: {
      PERFORMING: 0.0,
      MARINE_DEFAULT: 0.0,
      MODIFIED_OUTLOOK: 0.0,
      PARTIAL_DEFAULT: 0.0,
      DEFAULT: 0.0,
    },
    investmentBalance: -0.01,
    pendingInvestments: 0.0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    reserveBalance: 0,
    total: -0.01,
    weightedInvestmentRate: 0,
    irr: 6.65,
    interestEarnedLast30: 0.0,
    fundsTotal: 0,
    funds: [],
  },
  capital: null,
  investorEntities: [],
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 5021.62,
    compoundInterestEarned: 0.0,
    interestPaid: 5021.62,
    fexpPaid: 0.0,
    fexpBalance: 0,
    netPaid: 5021.62,
    walletInterestPaid: 0.0,
    compoundInterestPaid: 0.0,
    endingAccruedInterest: 0.0,
    interestEarnedPreDefault: 5183.97,
    interestEarnedPostDefault: -162.35,
    earningsTimeline: [
      {
        date: '2016-04-30',
        accruedPreDefault: 0,
        accruedPostDefault: 0,
        totalPaid: 0,
        netPaid: 0,
        fexpPaid: 0,
        walletInterestPaid: 0,
        value: 0,
      },
      {
        date: '2016-05-31',
        accruedPreDefault: 0.0,
        accruedPostDefault: 0.0,
        totalPaid: 391.79,
        netPaid: 391.79,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 391.79,
      },
      {
        date: '2016-06-30',
        accruedPreDefault: 0.0,
        accruedPostDefault: 0.0,
        totalPaid: 911.85,
        netPaid: 911.85,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 911.85,
      },
      {
        date: '2016-07-31',
        accruedPreDefault: 0.0,
        accruedPostDefault: 0.0,
        totalPaid: 1430.94,
        netPaid: 1430.94,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 1430.94,
      },
      {
        date: '2016-08-31',
        accruedPreDefault: 0.0,
        accruedPostDefault: 0.0,
        totalPaid: 1932.96,
        netPaid: 1932.96,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 1932.96,
      },
      {
        date: '2016-09-30',
        accruedPreDefault: 341.89,
        accruedPostDefault: 0.0,
        totalPaid: 2055.27,
        netPaid: 2055.27,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 2397.16,
      },
      {
        date: '2016-10-31',
        accruedPreDefault: 239.86,
        accruedPostDefault: 0.0,
        totalPaid: 2626.26,
        netPaid: 2626.26,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 2866.12,
      },
      {
        date: '2016-11-30',
        accruedPreDefault: 216.39,
        accruedPostDefault: 0.0,
        totalPaid: 3086.8,
        netPaid: 3086.8,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 3303.19,
      },
      {
        date: '2016-12-31',
        accruedPreDefault: 221.53,
        accruedPostDefault: 0.0,
        totalPaid: 3515.24,
        netPaid: 3515.24,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 3736.77,
      },
      {
        date: '2017-01-31',
        accruedPreDefault: 169.72,
        accruedPostDefault: 0.0,
        totalPaid: 3979.94,
        netPaid: 3979.94,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 4149.66,
      },
      {
        date: '2017-02-28',
        accruedPreDefault: 131.95,
        accruedPostDefault: 0.0,
        totalPaid: 4365.23,
        netPaid: 4365.23,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 4497.18,
      },
      {
        date: '2017-03-31',
        accruedPreDefault: 182.4,
        accruedPostDefault: 0.0,
        totalPaid: 4672.5,
        netPaid: 4672.5,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 4854.9,
      },
      {
        date: '2017-04-30',
        accruedPreDefault: 329.07,
        accruedPostDefault: 0.0,
        totalPaid: 4854.9,
        netPaid: 4854.9,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 5183.97,
      },
      {
        date: '2017-05-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 335.51,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 5519.48,
      },
      {
        date: '2017-06-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 660.2,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 5844.17,
      },
      {
        date: '2017-07-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 995.71,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 6179.68,
      },
      {
        date: '2017-08-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 1331.23,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 6515.2,
      },
      {
        date: '2017-09-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 1655.92,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 6839.89,
      },
      {
        date: '2017-10-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 1991.43,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 7175.4,
      },
      {
        date: '2017-11-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 2316.12,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 7500.09,
      },
      {
        date: '2017-12-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 2651.63,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 7835.6,
      },
      {
        date: '2018-01-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 2987.14,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 8171.11,
      },
      {
        date: '2018-02-28',
        accruedPreDefault: 162.35,
        accruedPostDefault: 3290.19,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 8474.16,
      },
      {
        date: '2018-03-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 3625.7,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 8809.67,
      },
      {
        date: '2018-04-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 3950.39,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 9134.36,
      },
      {
        date: '2018-05-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 4285.9,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 9469.87,
      },
      {
        date: '2018-06-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 4610.59,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 9794.56,
      },
      {
        date: '2018-07-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 4946.1,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 10130.07,
      },
      {
        date: '2018-08-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 5281.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 10465.59,
      },
      {
        date: '2018-09-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 5606.31,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 10790.28,
      },
      {
        date: '2018-10-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 5941.82,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 11125.79,
      },
      {
        date: '2018-11-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 6266.51,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 11450.48,
      },
      {
        date: '2018-12-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 6602.02,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 11785.99,
      },
      {
        date: '2019-01-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 6937.53,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 12121.5,
      },
      {
        date: '2019-02-28',
        accruedPreDefault: 162.35,
        accruedPostDefault: 7240.58,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 12424.55,
      },
      {
        date: '2019-03-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 7576.09,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 12760.06,
      },
      {
        date: '2019-04-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 7900.78,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 13084.75,
      },
      {
        date: '2019-05-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 8236.29,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 13420.26,
      },
      {
        date: '2019-06-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 8560.98,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 13744.95,
      },
      {
        date: '2019-07-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 8896.49,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 14080.46,
      },
      {
        date: '2019-08-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 9232.01,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 14415.98,
      },
      {
        date: '2019-09-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 9556.7,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 14740.67,
      },
      {
        date: '2019-10-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 9892.21,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 15076.18,
      },
      {
        date: '2019-11-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 10216.9,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 15400.87,
      },
      {
        date: '2019-12-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 10552.41,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 15736.38,
      },
      {
        date: '2020-01-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 10887.92,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 16071.89,
      },
      {
        date: '2020-02-29',
        accruedPreDefault: 162.35,
        accruedPostDefault: 11201.79,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 16385.76,
      },
      {
        date: '2020-03-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 11537.3,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 16721.27,
      },
      {
        date: '2020-04-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 11861.99,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 17045.96,
      },
      {
        date: '2020-05-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 12197.5,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 17381.47,
      },
      {
        date: '2020-06-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 12522.19,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 17706.16,
      },
      {
        date: '2020-07-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 12857.71,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 18041.68,
      },
      {
        date: '2020-08-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 13193.22,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 18377.19,
      },
      {
        date: '2020-09-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 13517.91,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 18701.88,
      },
      {
        date: '2020-10-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 13853.42,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19037.39,
      },
      {
        date: '2020-11-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14178.11,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19362.08,
      },
      {
        date: '2020-12-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-01-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-02-28',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-03-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-04-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-05-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-06-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-07-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-08-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-09-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-10-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-11-30',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2021-12-31',
        accruedPreDefault: 162.35,
        accruedPostDefault: 14513.62,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 19697.59,
      },
      {
        date: '2022-01-02',
        accruedPreDefault: 0.0,
        accruedPostDefault: 0.0,
        totalPaid: 5021.62,
        netPaid: 5021.62,
        fexpPaid: 0.0,
        walletInterestPaid: 0.0,
        value: 5021.62,
      },
    ],
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: -0.01,
    repaidPrincipal: 0.0,
    endingOutstandingPrincipal: -0.01,
    endingOutstandingPrincipalPreDefault: 0.0,
    endingOutstandingPrincipalPostDefault: -0.01,
    liquidationPaid: 0.0,
    liquidationDiscount: 0.0,
    isInDefault: true,
    principalTimeline: [
      {
        date: '2016-04-30',
        paid: 0,
        outstandingPreDefault: 0,
        outstandingPostDefault: 0,
        principalWriteOff: 0,
        liquidationPaid: 0,
        liquidationDiscount: 0,
        value: 0,
      },
      {
        date: '2016-05-31',
        paid: 0.0,
        outstandingPreDefault: 48824.85,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 48824.85,
      },
      {
        date: '2016-06-30',
        paid: 0.0,
        outstandingPreDefault: 47307.85,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 47307.85,
      },
      {
        date: '2016-07-31',
        paid: 0.0,
        outstandingPreDefault: 45794.63,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 45794.63,
      },
      {
        date: '2016-08-31',
        paid: 0.0,
        outstandingPreDefault: 44470.81,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 44470.81,
      },
      {
        date: '2016-09-30',
        paid: 0.0,
        outstandingPreDefault: 43531.5,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 43531.5,
      },
      {
        date: '2016-10-31',
        paid: 0.0,
        outstandingPreDefault: 42089.31,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 42089.31,
      },
      {
        date: '2016-11-30',
        paid: 0.0,
        outstandingPreDefault: 40502.67,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 40502.67,
      },
      {
        date: '2016-12-31',
        paid: 0.0,
        outstandingPreDefault: 38874.06,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 38874.06,
      },
      {
        date: '2017-01-31',
        paid: 0.0,
        outstandingPreDefault: 36653.64,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 36653.64,
      },
      {
        date: '2017-02-28',
        paid: 0.0,
        outstandingPreDefault: 33681.22,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 33681.22,
      },
      {
        date: '2017-03-31',
        paid: 0.0,
        outstandingPreDefault: 32007.05,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 32007.05,
      },
      {
        date: '2017-04-30',
        paid: 0.0,
        outstandingPreDefault: 31207.57,
        outstandingPostDefault: 0.0,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 31207.57,
      },
      {
        date: '2017-05-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2017-06-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2017-07-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2017-08-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2017-09-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2017-10-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2017-11-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2017-12-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-01-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-02-28',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-03-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-04-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-05-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-06-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-07-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-08-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-09-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-10-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-11-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2018-12-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-01-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-02-28',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-03-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-04-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-05-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-06-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-07-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-08-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-09-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-10-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-11-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2019-12-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-01-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-02-29',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-03-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-04-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-05-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-06-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-07-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-08-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-09-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-10-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-11-30',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2020-12-31',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: 30387.6,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: 30387.6,
      },
      {
        date: '2022-01-02',
        paid: 0.0,
        outstandingPreDefault: 0.0,
        outstandingPostDefault: -0.01,
        principalWriteOff: 0.0,
        liquidationPaid: 0.0,
        liquidationDiscount: 0.0,
        value: -0.01,
      },
    ],
  },
  investmentDetail: {
    fundId: null,
    fundType: null,
    noteUrlHash: '901',
    title: 'Ridesharing Fleet Expansion',
    distributionRate: 0,
    assetClass: 'PRCR',
    assetClassDescription: 'Private Credit',
    paymentType: 'Bi-Weekly',
    termPhrase: 'N/A',
    advisor: null,
    subAdvisor: null,
    interestRate: 13.0,
    taxDocument: 'K1',
    firstYearExpense: 0,
    annualFlatExpense: 0,
    irr: -71.7,
    investedDate: '2016-05-04',
    activeDate: '2016-05-06',
    launchedDate: '2016-04-29',
    terminationDate: '2018-08-31',
    interestDefaultedDate: '2017-05-01',
    principalDefaultedDate: '2017-05-01',
    dateOfFirstDefault: '2017-05-01',
    originator: 'Soli Capital',
    dividendDistributionOption: null,
    canToggleDistributionOption: null,
    performanceStatus: PerformanceStatus.DEFAULT,
    performanceStatusNote:
      'As previously disclosed, the Guarantor of the underlying loan facility filed for Chapter 7 bankruptcy, which has further complicated the recovery process. Of the $904K total principal investment, 64% remains outstanding. Although YS SPV remains contractually entitled to receive interest from the borrower, given the bankruptcy and our inability to predict the outcome of the pending litigation, we have determined that interest will cease to accrue on portfolios as of December 31, 2020.',
    spvType: '3C1',
    offeringData: {},
    investments: [
      {
        type: InvestmentTypes.Investment,
        dateInvested: '2016-05-04',
        dateActive: '2016-05-06',
        shareCount: 0,
        sharePrice: 0,
        status: InvestmentStatusTypes.Confirmed,
        amount: 50000.0,
      },
    ],
    tenderOfferStatus: TenderStatus.UNAVAILABLE,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 50000.0,
    totalRepaid: 5021.62,
    netTotalRepaid: 5021.62,
    fundProvider: null,
    engine: InvestmentEngine.Pflex,
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: [],
  },
  wallets: [],
  investmentStrategies: [],
};
