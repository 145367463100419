import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    textAlign: 'center',
  },
}));

export const IconWrapper = styled('div')<{ centered?: boolean }>(({ theme, centered }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(10),
  ...(centered && {
    textAlign: 'center',
  }),
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    textAlign: 'center',
  },
}));

export const Icon = styled('img')``;

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(10),
  gap: theme.spacing(3),
  flexDirection: 'column-reverse',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    justifyContent: 'center',
    width: 'auto',
  },
}));
