import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { Markdown } from '../../../../utils/markdown';

import { Button } from '../../../../content/button';
import { BaseProps } from '../../../../types/components/base';

import checkboxError from '../assets/checkbox_error.svg';

import { RowData, useTestIds, getTestIds } from './ProgressTableRow.model';
import {
  Row,
  Desktop,
  Mobile,
  TextWrapper,
  LeftElement,
  UpdatedDate,
  ErrorIcon,
  RightElement,
  RowTitle,
  Description,
  MobileDate,
  DesktopSubTitle,
  MobileSubTitle,
  MobileButton,
  IconHolder,
  AnimatedContent,
  SFontAwesomeIcon,
  ButtonContainer,
} from './ProgressTableRow.style';

export const ProgressTableRow = ({
  date,
  title,
  description,
  button = false,
  buttonCTA,
  onCTAClick,
  expandable,
  buttonLoading,
  buttonDisabled = false,
  startOpen,
  children,
  error = false,
  lastRow,
  forcedOpen,
  ...props
}: RowData & BaseProps) => {
  const [show, setShow] = useState<boolean>(startOpen || false);
  const testIds = useTestIds(props);

  const handleShow = () => {
    if (forcedOpen || show) return undefined;

    setShow(true);
  };

  const handleArrowClick = () => {
    if (forcedOpen) return undefined;

    setShow(!show);
  };

  return (
    <Row lastRow={lastRow} onClick={handleShow} {...testIds.base.attr}>
      <Desktop {...testIds.desktopContainer.attr}>
        <TextWrapper>
          <LeftElement>
            {/* dates shouldn't show while the BE sends them wrong
              leaving it there so its simple to revert once fixed */}
            {false && date && <UpdatedDate>{date}</UpdatedDate>}
            {error && <ErrorIcon src={checkboxError} {...testIds.errorIcon.attr} />}
          </LeftElement>
          <RightElement>
            <RowTitle semiBold light={false} {...testIds.title.attr}>
              {title}
            </RowTitle>
            {description && (
              <DesktopSubTitle {...testIds.subtitle.attr}>
                <Markdown source={description} />
              </DesktopSubTitle>
            )}
            {expandable && children && show && (
              <AnimatedContent show={show}>
                <Description {...testIds.content.attr}>{children}</Description>
              </AnimatedContent>
            )}
            {forcedOpen && (
              <AnimatedContent show>
                <Description {...testIds.content.attr}>{children}</Description>
              </AnimatedContent>
            )}
          </RightElement>
        </TextWrapper>
        {button && (
          <ButtonContainer {...testIds.cta.attr}>
            <Button onClick={onCTAClick} loading={buttonLoading} disabled={buttonDisabled}>
              {buttonCTA}
            </Button>
          </ButtonContainer>
        )}
        {expandable && (
          <IconHolder onClick={handleArrowClick}>
            {show && <FontAwesomeIcon icon={faChevronUp} {...testIds.expandableIconUp.attr} />}
            {!show && (
              <SFontAwesomeIcon
                light={!show}
                icon={faChevronDown}
                {...testIds.expandableIconDown.attr}
              />
            )}
          </IconHolder>
        )}
      </Desktop>
      <Mobile {...testIds.mobileContainer.attr}>
        <TextWrapper>
          <RightElement>
            <RowTitle semiBold light={!show} error={error} {...testIds.title.attr}>
              {title}
            </RowTitle>
            {show && (
              <AnimatedContent show={show}>
                {date && <MobileDate>{`Updated on ${date}`}</MobileDate>}
                {description && (
                  <MobileSubTitle {...testIds.subtitle.attr}>
                    <Markdown source={description} />
                  </MobileSubTitle>
                )}
                {children && <Description {...testIds.content.attr}>{children}</Description>}
                {button && (
                  <div {...testIds.cta.attr}>
                    <MobileButton onClick={onCTAClick}>{buttonCTA}</MobileButton>
                  </div>
                )}
              </AnimatedContent>
            )}
          </RightElement>
        </TextWrapper>
        <IconHolder error={error} onClick={handleArrowClick}>
          {error && <ErrorIcon src={checkboxError} {...testIds.errorIcon.attr} />}
          {show && <FontAwesomeIcon icon={faChevronUp} {...testIds.expandableIconUp.attr} />}
          {!show && (
            <SFontAwesomeIcon
              light={!show}
              icon={faChevronDown}
              {...testIds.expandableIconDown.attr}
            />
          )}
        </IconHolder>
      </Mobile>
    </Row>
  );
};

ProgressTableRow.getTestIds = getTestIds;
