import { GetInvestorEntityDocumentsResponse } from '../../apis';
import { PortfolioDocumentType } from '../../enums';

export const investorEntityDocuments: GetInvestorEntityDocumentsResponse = {
  documents: [
    {
      id: 963867,
      name: 'DiscretionaryAdviceClientAgreement_1711357639477.pdf',
      notes: 'Discretionary Advice Client Agreement',
      docType: PortfolioDocumentType.DACA,
      docTypeDescription: 'Discretionary Advice Client Agreement',
      urlHash: null,
      url: 'url',
      createDate: 1711339200000,
      createDateString: '2024-03-25',
    },
  ],
};
