import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Label, Paragraph } from '../..';
import QuoteImg from '../../assets/graphics/icons/quotes-testimonial.svg';

interface TestimonialProps {
  date: string;
  content: string;
  investorName: string;
  memmberSince: string;
}

interface TestimonialCardProps {
  testimonial: TestimonialProps;
  className?: string;
}

export const TestimonialCard: FunctionComponent<TestimonialCardProps> = ({
  testimonial,
  className,
}) => (
  <Card className={className}>
    <IconWrapper>
      <Circle className="quote-icon" />
    </IconWrapper>
    <Content>
      <Testimonial>{testimonial.content}</Testimonial>
      <InvestorName>{testimonial.investorName}</InvestorName>
      <MemberSince small>Investor since {testimonial.memmberSince}</MemberSince>
    </Content>
  </Card>
);

const Card = styled.div`
  background-color: ${props => props.theme.colors.background_card};
  border: 1px solid ${props => props.theme.colors.border_default};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.shadow_default};
  padding: 40px;
  display: flex;
  flex-direction: column;
  /* margin: 0 auto; */
  max-width: 70vw;
  min-height: 418px;
  width: 326px;
`;

const IconWrapper = styled.div`
  margin-right: 15px;
  position: relative;
`;

const Circle = styled.div`
  background-image: url(${QuoteImg});
  background-size: cover;
  content: '';
  display: block;
  margin-bottom: 15px;
  left: -15px;
  height: 22px;
  width: 28px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
`;

const Testimonial = styled(Paragraph)`
  margin: 8px 0 15px;
  margin-bottom: 40px;
`;
const InvestorName = styled.div`
  font-family: ${props => props.theme.typography.primary};
  font-size: 16px;
  margin-bottom: 3px;
  margin-top: auto;
`;
const MemberSince = styled(Label)``;
