import styled from 'styled-components';

export const OfferingThumbSkeleton = styled.div`
  border-radius: 6px;
  min-height: 140px;
  width: 100%;
`;

export const MainContainerSkeleton = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 115px;
`;

export const TitleContainerSkeleton = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.xxs};
  width: 100%;
`;

export const AssetClassWrapperSkeleton = styled.div`
  gap: ${props => props.theme.spacing.xxs};
  width: 50%;
`;

export const ContentContainerSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${props => props.theme.spacing.xs};
`;

export const BadgeContainerSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing.xxs};
`;

export const BadgeSkeleton = styled.div`
  height: 30px;
  width: 30%;
`;
