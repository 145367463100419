export enum UserGateCode {
  // The offering status does not allow the investor to invest
  OFFERING_STATUS = 'OFFERING_STATUS',
  // User is not in the offer's audience segment
  SEGMENTATION = 'SEGMENTATION',
  // User fails the self accreditation requirement of the offering
  SELF_ACCREDITATION = 'SELF_ACCREDITATION',
  // User fails the QP accreditation requirement of the offering
  SELF_QP = 'SELF_QP',
  // User fails the QC accreditation requirement of the offering
  SELF_QC = 'SELF_QC',
  // User still has to add an Investor Entity in order to be able to invest
  NO_INVESTOR_ENTITY = 'NO_INVESTOR_ENTITY',
  // All the user's Investor Entities require some action to complete setup
  INVESTOR_ENTITY_GATE = 'INVESTOR_ENTITY_GATE',
  // The user should have never ended up with an investment intent on this offering
  NEVER = 'NEVER',
  // Something went wrong
  ERROR = 'ERROR',
}

export enum InvestorEntityGateCode {
  // Investor blocked from investing due to its entity type (only Individual accepted)
  INVESTOR_ENTITY_TYPE = 'INVESTOR_ENTITY_TYPE',
  // The offering is not allowed for entity provider - used only for investment flow
  INVESTOR_ENTITY_PROVIDER = 'INVESTOR_ENTITY_PROVIDER',
  // Entity is not ready, it has missing third party setup - used only in investment flow if provider is allowed for the offering
  INVESTOR_ENTITY_PROVIDER_ACTION = 'INVESTOR_ENTITY_PROVIDER_ACTION',
  // Entity is not ready to invest, and requires further action from the user
  INVESTOR_ENTITY_ACTION = 'INVESTOR_ENTITY_ACTION',
  // Entity is ineligible for managed investing (citizenship and residence need to be US)
  KYC_INELIGIBLE = 'KYC_INELIGIBLE',
  // Entity is not ready to invest, it is missing extra kyc info and requires further action from the user
  MISSING_EXTRA_KYC_INFO = 'MISSING_EXTRA_KYC_INFO',
  // Entity is not ready to invest, it is missing managed investor account and requires further action from the user
  MISSING_MANAGED_INVESTOR_ACCOUNT = 'MISSING_MANAGED_INVESTOR_ACCOUNT',
  // Entity is not ready to invest, it is missing self-directed investor account and requires further action from the user
  MISSING_SELF_DIRECTED_INVESTOR_ACCOUNT = 'MISSING_SELF_DIRECTED_INVESTOR_ACCOUNT',
  // Discretionary Advice quiz not fully complete and requires further action from the user
  MISSING_MANAGED_PORTFOLIO_ANSWERS = 'MISSING_MANAGED_PORTFOLIO_ANSWERS',
  // Entity is missing strategy and requires further action from the user
  MISSING_MANAGED_PORTFOLIO_STRATEGY = 'MISSING_MANAGED_PORTFOLIO_STRATEGY',
  // Entity has pending third party actions, and requires further action from the user
  THIRD_PARTY_ACTION = 'THIRD_PARTY_ACTION',
  // All investor accounts have a blocking gate
  INVESTOR_ACCOUNT_GATE = 'INVESTOR_ACCOUNT_GATE',
  // Entity does not have any linked bank accounts,
  MISSING_BANK_ACCOUNTS = 'MISSING_BANK_ACCOUNTS',
  // Entity has a linked micro deposit account without verifying it
  PENDING_MICRO_DEPOSITS = 'PENDING_MICRO_DEPOSITS',
  // There are connected bank accounts, but they are still pending verification from the bank account linking provider.
  PENDING_BANK_ACCOUNT_VERIFICATION = 'PENDING_BANK_ACCOUNT_VERIFICATION',
  // Entity has a linked micro deposit account which will be automatically verified
  // Investor should have never got to this point
  NEVER = 'NEVER',
  // Something went wrong
  ERROR = 'ERROR',
}

export enum InvestorAccountGateCode {
  // A wallet is required for an investor to invest in this type of offering
  NO_WALLET = 'NO_WALLET',
  // Wallet balance needs to cover the entire investment amount in certain scenarios
  INSUFFICIENT_WALLET_BALANCE = 'INSUFFICIENT_WALLET_BALANCE',
  // Account is not ready to invest, it is missing initial deposit and requires further action from the user
  DEPOSIT_REQUIRED = 'DEPOSIT_REQUIRED',
  // Account is not ready to invest, initial deposit is still processing
  DEPOSIT_PROCESSING = 'DEPOSIT_PROCESSING',
  // Investor should have never got to this point
  NEVER = 'NEVER',
  // Something went wrong
  ERROR = 'ERROR',
}

export enum KYCIneligibleReasons {
  // Country of citizenship is invalid
  CITIZENSHIP = 'CITIZENSHIP',
  // User indicated that they are politically exposed
  POLITICALLY_EXPOSED = 'POLITICALLY_EXPOSED',
  // User indicated that their family is politically exposed
  FAMILY_POLITICALLY_EXPOSED = 'FAMILY_POLITICALLY_EXPOSED',
  // User indicated that they are FINRA associated
  FINRA_AFFILIATED = 'FINRA_AFFILIATED',
  // User indicated that they have a controlling stake in any company
  CONTROL_CORPORATION = 'CONTROL_CORPORATION',
  // User indicated that they have any exchange affiliation
  EXCHANGE_AFFILIATED = 'EXCHANGE_AFFILIATED',
}

export enum ThirdPartyActionProvider {
  ATOMIC = 'ATOMIC',
}

export enum ThirdPartyActionType {
  COMPLETION_LINK = 'COMPLETION_LINK',
}
