import styled from 'styled-components';
import Switch from '@mui/material/Switch';

export const SSwitch = styled(Switch)`
  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.border_default} !important;
    width: 40px;
    opacity: 1 !important;
  }
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${({ theme }) => theme.colors.active};
  }
  & .MuiSwitch-switchBase.Mui-disabled {
    color: ${({ theme }) => theme.colors.border_default};
  }
  & .MuiSwitch-switchBase {
    color: ${({ theme }) => theme.colors.border_strong};
  }
`;
