import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Label } from '../../content/text';
interface TimelineItem {
  label: string;
  content: React.ReactNode;
}

interface TimelineProps {
  items: TimelineItem[];
}

export const Timeline: FunctionComponent<TimelineProps> = ({ items }) => {
  return (
    <TimelineWrapper>
      <CardsWrapper>
        {items.map((item, index) => (
          <Card>
            <Indicator lastItem={index + 1 === items.length}>
              <IndicatorWrapper>
                <IndicatorIndex>
                  <IndicatorIndexLabel>{index + 1}</IndicatorIndexLabel>
                </IndicatorIndex>
              </IndicatorWrapper>
            </Indicator>

            <ContentWrapper>{item.content}</ContentWrapper>
          </Card>
        ))}
      </CardsWrapper>
    </TimelineWrapper>
  );
};

const TimelineWrapper = styled.div`
  display: flex;
`;

const CardsWrapper = styled.div``;

const Card = styled.div`
  display: flex;
`;

const Indicator = styled.div<{ lastItem: boolean }>`
  display: flex;
  position: relative;
  ${props =>
    !props.lastItem &&
    css`
      &:after {
        content: '';
        border: 1px solid ${props => props.theme.colors.border_outline};
        height: 100%;
        position: absolute;
        left: 11px;
        z-index: 1;
        top: 2px;
      }
    `};
`;

const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IndicatorIndex = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.background_inverse};
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 15px;
  width: 24px;
  position: relative;
  z-index: 10;
`;

const IndicatorIndexLabel = styled(Label)`
  color: ${props => props.theme.colors.text_header_inverse} !important;
`;

const ContentWrapper = styled.div``;
