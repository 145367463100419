import styled from 'styled-components';

import { media } from '../../themes/media';
import { Heading } from '../../content/text';

export const Container = styled.div`
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing.l};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  width: 100%;

  ${media.desktop`
    padding-left: 110px;
    padding-right: 110px;
  `}
`;

export const ContentHolder = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const TraitItemWrapper = styled.div<{ blocks?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.xl};
  ${props => (props.blocks ? null : `column-gap: ${props.theme.spacing.m};`)}

  ${media.desktop<{ blocks?: boolean }>`
    justify-content: initial;
    row-gap: ${props =>
      props.blocks ? `${props.theme.spacing.xl}` : `${props.theme.spacing['3xl']}`};
  `}
`;

export const TextWrapper = styled.div`
  max-width: 700px;
`;

export const SHeading = styled(Heading)`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const TraitItem = styled.div<{ blocks?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  ${props =>
    props.blocks
      ? `
      background-color: ${props.theme.colors.background_light};
      border: 1px solid ${props.theme.colors.border_default};
      border-radius: 4px;
      padding: ${props.theme.spacing.ml};
       `
      : null}

  ${media.desktop`
    width: auto;
    flex-grow: 1;
    flex-basis: 0;
    flex: 30%;
  `}
`;

export const TraitIcon = styled.img`
  width: 48px;
  height: 48px;
`;

export const Bubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(284.31deg, #85f1b7 10.16%, #02c9a5 90.64%);
  border-radius: 48px;
  width: 48px;
  height: 48px;
`;

export const ItemHeading = styled(Heading)`
  margin: ${({ theme }) => `${theme.spacing.m} 0 ${theme.spacing.xs}`};
`;

export const TraitLine = styled.div`
  width: 1px;
  height: 156px;
  background-color: #d9d9d9;
  align-self: center;

  &:last-child {
    display: none;
  }
`;

export const BlocksLine = styled.div`
  width: 1px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border_default};
  align-self: center;

  &:last-child {
    display: none;
  }

  ${media.desktop`
    width: 20px;
    height: 0px;
  `}
`;
