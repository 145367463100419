import { restApi, Tags } from '../../common';
import { HttpMethods } from '../../../enums/http';
import { NavChartResponse, NavChartRequest } from './nav-chart.model';

export const navChartApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioNavChart],
  })
  .injectEndpoints({
    endpoints: build => {
      const getNavChart = build.query<NavChartResponse, NavChartRequest>({
        query: ({ userId, ...params }: NavChartRequest) => ({
          url: `/api/portfolio/${userId}/nav-chart`,
          method: HttpMethods.Get,
          params,
        }),
        providesTags: [Tags.PortfolioNavChart],
      });

      return {
        getNavChart,
      };
    },
  });
