import { HttpMethods } from '../../../enums/http';
import { SocketManagerReconnectionStrategy, websocketManager } from '../../../websocket';
import { Tags, restApi } from '../../common';
import { selectConfig } from '../../config';
import {
  InvestmentCreateBulkApiResponse,
  InvestmentCreateApiResponse,
  InvestmentCreateRequest,
  InvestmentGetRequestApiRequest,
  InvestmentGetRequestApiResponse,
  InvestmentPreCreateBulkRequest,
  InvestmentPreCreateBulkRequestApiResponse,
  InvestmentPreCreateApiResponse,
  InvestmentPreCreateRequest,
  InvestmentSignBulkRequest,
  InvestmentSignBulkResponse,
  InvestmentSignRequest,
  InvestmentSignResponse,
  InvestmentUpdatesSubscriptionRequest,
  InvestmentUpdatesSubscriptionResponse,
  TrackingInvestmentSubscribeUpdatesRequest,
  TrackingInvestmentSubscribeUpdatesResponse,
  InvestmentCreateBulkRequest,
  InvestmentPositionGetApiRequest,
  InvestmentPositionGetApiResponse,
  LiquidationsRequest,
  LiquidationsResponse,
  TrackingInvestmentSubscribeDetailedUpdatesResponse,
  SubmitTransferRequest,
  SubmitTransferResponse,
} from './offerings.model';

const BASE_URL = '/a/api/investment-request/offerings';
const SOCKET_URL = '/a/api/investment-request/ws/offerings/investments';

export const investmentRequestOfferingsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.InvestmentRequest,
      Tags.InvestmentRequestBulk,
      Tags.InvestmentSign,
      Tags.InvestmentSignBulk,
      Tags.InvestmentUpdatesSubscription,
      Tags.InvestmentTrackingUpdatesSubscription,
      Tags.InvestmentTrackingDetailedUpdatesSubscription,
      Tags.InvestmentLiquidation,
      Tags.InvestmentTrackingSubmitTransfer,
    ],
  })
  .injectEndpoints({
    endpoints: builder => {
      /**
       * POST a single pre-investment requests
       */
      const preCreate = builder.mutation<
        InvestmentPreCreateApiResponse, // TODO: revisit response after testing
        InvestmentPreCreateRequest
      >({
        query: ({ noteUrlHash, ...data }) => ({
          url: `${BASE_URL}/${noteUrlHash}/investments/pre`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentRequest }],
      });

      /**
       * Get Investment Request
       * (POST due to BE restrictions)
       */
      const getInvestmentRequest = builder.mutation<
        InvestmentGetRequestApiResponse['investments'],
        InvestmentGetRequestApiRequest
      >({
        query: data => ({
          url: `${BASE_URL}/investments`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentRequest }],
        transformResponse: (response: InvestmentGetRequestApiResponse) => response.investments,
      });

      /**
       * Bulk pre-create
       */
      const bulkPreCreate = builder.mutation<
        InvestmentPreCreateBulkRequestApiResponse[], // TODO: revisit response after testing
        InvestmentPreCreateBulkRequest
      >({
        query: data => ({
          url: `${BASE_URL}/investments/pre/bulk`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentRequestBulk }],
      });

      /**
       * Create
       */
      const create = builder.mutation<
        InvestmentCreateApiResponse, // TODO: revisit response after testing
        InvestmentCreateRequest
      >({
        query: ({ noteUrlHash, investmentId, ...data }) => {
          const investmentIdUrl = investmentId ? `/${investmentId}` : '';

          return {
            url: `${BASE_URL}/${noteUrlHash}/investments${investmentIdUrl}`,
            method: HttpMethods.Post,
            data,
          };
        },
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentRequest }],
      });

      /**
       * Bulk create
       */
      const bulkCreate = builder.mutation<
        InvestmentCreateBulkApiResponse, // TODO: revisit response after testing
        InvestmentCreateBulkRequest
      >({
        query: data => ({
          url: `${BASE_URL}/investments/bulk`,
          method: HttpMethods.Post,
          data,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentRequestBulk }],
      });

      /**
       * Sign
       */
      const sign = builder.mutation<InvestmentSignResponse, InvestmentSignRequest>({
        query: ({ noteUrlHash, investmentId, ...data }) => ({
          url: `${BASE_URL}/${noteUrlHash}/investments/${investmentId}`,
          method: HttpMethods.Put,
          data,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentSign }],
      });

      /**
       * Bulk sign
       */
      const bulkSign = builder.mutation<InvestmentSignBulkResponse, InvestmentSignBulkRequest>({
        query: data => ({
          url: `${BASE_URL}/investments/bulk`,
          method: HttpMethods.Put,
          data,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentSignBulk }],
      });

      /**
       * Subscribe to status updates
       */
      const subscribeInvestmentUpdates = builder.query<
        InvestmentUpdatesSubscriptionResponse,
        InvestmentUpdatesSubscriptionRequest
      >({
        queryFn: () => ({ data: {} as InvestmentUpdatesSubscriptionResponse }),
        providesTags: [Tags.InvestmentUpdatesSubscription],
        async onCacheEntryAdded(
          { groupingByInvestmentId, ...payload },
          { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }
        ) {
          const { wssHost } = selectConfig(getState() as any);

          const onMessage = (_: WebSocket, ev: MessageEvent) => {
            const data = JSON.parse(ev.data);
            updateCachedData(draft =>
              groupingByInvestmentId
                ? Object.assign(draft, { [data?.investment?.id]: data })
                : Object.assign(draft, {
                    [data?.investment?.noteUrlHash]: data,
                  })
            );
          };

          const onOpen = () => {
            websocketManager.send(Tags.InvestmentUpdatesSubscription, JSON.stringify(payload));
          };

          try {
            await cacheDataLoaded;

            websocketManager.remove(Tags.InvestmentUpdatesSubscription);

            websocketManager.add(
              Tags.InvestmentUpdatesSubscription,
              {
                url: `${wssHost}${SOCKET_URL}`,
                reconnectionStrategy: SocketManagerReconnectionStrategy.retry,
              },
              { onMessage, onOpen }
            );

            await cacheEntryRemoved;
          } finally {
            websocketManager.unsubscribe(Tags.InvestmentUpdatesSubscription);
          }
        },
      });

      const subscribeTrackingInvestmentUpdates = builder.query<
        TrackingInvestmentSubscribeUpdatesResponse,
        TrackingInvestmentSubscribeUpdatesRequest
      >({
        queryFn: () => ({
          data: {} as TrackingInvestmentSubscribeUpdatesResponse,
        }),
        providesTags: [Tags.InvestmentTrackingUpdatesSubscription],
        async onCacheEntryAdded(
          { noteUrlHash, investmentRequestId, ...payload },
          { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }
        ) {
          const { wssHost } = selectConfig(getState() as any);

          const onMessage = (_: WebSocket, ev: MessageEvent) => {
            const data = JSON.parse(ev.data);

            updateCachedData(draft =>
              Object.assign(draft, {
                [investmentRequestId]: data,
              })
            );
          };

          const onOpen = () => {
            websocketManager.send(
              Tags.InvestmentTrackingUpdatesSubscription,
              JSON.stringify(payload)
            );
          };

          try {
            await cacheDataLoaded;

            websocketManager.remove(Tags.InvestmentTrackingUpdatesSubscription);

            let path = `/a/api/investment-request/ws/offerings/${noteUrlHash}/investments/${investmentRequestId}`;

            websocketManager.add(
              Tags.InvestmentTrackingUpdatesSubscription,
              {
                url: `${wssHost}${path}`,
                reconnectionStrategy: SocketManagerReconnectionStrategy.retry,
              },
              { onMessage, onOpen }
            );

            await cacheEntryRemoved;
          } finally {
            websocketManager.unsubscribe(Tags.InvestmentTrackingUpdatesSubscription);
          }
        },
      });

      const subscribeTrackingInvestmentDetailedUpdates = builder.query<
        TrackingInvestmentSubscribeDetailedUpdatesResponse,
        TrackingInvestmentSubscribeUpdatesRequest
      >({
        queryFn: () => ({
          data: {} as TrackingInvestmentSubscribeUpdatesResponse,
        }),
        providesTags: [Tags.InvestmentTrackingDetailedUpdatesSubscription],
        async onCacheEntryAdded(
          { noteUrlHash, investmentRequestId, ...payload },
          { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }
        ) {
          const { wssHost } = selectConfig(getState() as any);

          const onMessage = (_: WebSocket, ev: MessageEvent) => {
            const data = JSON.parse(ev.data);

            updateCachedData(draft =>
              Object.assign(draft, {
                [investmentRequestId]: data,
              })
            );
          };

          const onOpen = () => {
            websocketManager.send(
              Tags.InvestmentTrackingUpdatesSubscription,
              JSON.stringify(payload)
            );
          };

          try {
            await cacheDataLoaded;

            websocketManager.remove(Tags.InvestmentTrackingDetailedUpdatesSubscription);

            let path = `/a/api/investment-request/ws/offerings/${noteUrlHash}/investments/${investmentRequestId}/detailed`;

            websocketManager.add(
              Tags.InvestmentTrackingDetailedUpdatesSubscription,
              {
                url: `${wssHost}${path}`,
                reconnectionStrategy: SocketManagerReconnectionStrategy.retry,
              },
              { onMessage, onOpen }
            );

            await cacheEntryRemoved;
          } finally {
            websocketManager.unsubscribe(Tags.InvestmentTrackingDetailedUpdatesSubscription);
          }
        },
      });

      const getInvestorPosition = builder.query<
        InvestmentPositionGetApiResponse['investorPosition'],
        InvestmentPositionGetApiRequest
      >({
        query: ({ noteUrlHash, investorAccountId }) => ({
          url: `${BASE_URL}/${noteUrlHash}/investoraccount/${investorAccountId}/current-position`,
          method: HttpMethods.Get,
        }),
        transformResponse: (response: InvestmentPositionGetApiResponse) =>
          response.investorPosition,
      });

      const liquidation = builder.mutation<LiquidationsResponse, LiquidationsRequest>({
        query: ({ urlHash, ...payload }) => ({
          url: `${BASE_URL}/${urlHash}/liquidations`,
          method: HttpMethods.Post,
          data: payload,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentLiquidation }],
      });

      const submitTransfer = builder.mutation<SubmitTransferResponse, SubmitTransferRequest>({
        query: ({ urlHash, investmentRequestId, ...payload }) => ({
          url: `${BASE_URL}/${urlHash}/investments/${investmentRequestId}/post`,
          method: HttpMethods.Post,
          data: payload,
        }),
        invalidatesTags: (_result, _error) => [{ type: Tags.InvestmentTrackingSubmitTransfer }],
      });

      return {
        liquidation,
        preCreate,
        bulkPreCreate,
        create,
        bulkCreate,
        sign,
        bulkSign,
        subscribeInvestmentUpdates,
        subscribeTrackingInvestmentUpdates,
        subscribeTrackingInvestmentDetailedUpdates,
        getInvestmentRequest,
        getInvestorPosition,
        submitTransfer,
      };
    },
  });
