import { AssetClassType } from '@yieldstreet/platform-kit';

export const lightAssetColors = {
  [AssetClassType.RealEstate]: 'rgb(179, 239, 228)',
  [AssetClassType.RealAssets]: 'rgb(179, 239, 228)',
  [AssetClassType.PrivateCredit]: 'rgb(188, 227, 244)',
  [AssetClassType.PrivateEquity]: 'rgb(183, 206, 202)',
  [AssetClassType.Cash]: `rgb(239, 219, 38)`,
};

export const assetColorsRGBValues: { [key: string]: string } = {
  [AssetClassType.RealEstate]: '133, 241, 183',
  [AssetClassType.RealAssets]: '30, 161, 217',
  [AssetClassType.PrivateCredit]: '15, 92, 78',
  [AssetClassType.PrivateEquity]: '2, 201, 165',
  [AssetClassType.Cash]: '239, 219, 38',
};

export const strokeAssetColors = {
  [AssetClassType.RealEstate]: `rgb(${assetColorsRGBValues[AssetClassType.RealAssets]})`,
  [AssetClassType.RealAssets]: `rgb(${assetColorsRGBValues[AssetClassType.RealAssets]})`,
  [AssetClassType.PrivateCredit]: `rgb(${assetColorsRGBValues[AssetClassType.PrivateCredit]})`,
  [AssetClassType.PrivateEquity]: `rgb(${assetColorsRGBValues[AssetClassType.PrivateEquity]})`,
  [AssetClassType.Cash]: `rgb(${assetColorsRGBValues[AssetClassType.Cash]})`,
};

const GRADIENT_START = 'GRADIENT_START';
const GRADIENT_END = 'GRADIENT_END';

const assetColorsOpacityValues = {
  GRADIENT_START: '0.52',
  GRADIENT_END: '0.21',
};

const buildRGBA = (rgb: string, opacity: number | string) => `rgba(${rgb}, ${opacity})`;

const buildColorKey = (assetClass: AssetClassType, suffix: string) => `${assetClass}_${suffix}`;

const getColors = (assetClass: AssetClassType, opacity = 1) => ({
  [assetClass]: buildRGBA(assetColorsRGBValues[assetClass], opacity),
  [buildColorKey(assetClass, GRADIENT_START)]: buildRGBA(
    assetColorsRGBValues[assetClass],
    assetColorsOpacityValues.GRADIENT_START
  ),
  [buildColorKey(assetClass, GRADIENT_END)]: buildRGBA(
    assetColorsRGBValues[assetClass],
    assetColorsOpacityValues.GRADIENT_END
  ),
});

export const getAssetColors = (opacity = 1) => ({
  ...getColors(AssetClassType.RealEstate, opacity),
  ...getColors(AssetClassType.RealAssets, opacity),
  ...getColors(AssetClassType.PrivateCredit, opacity),
  ...getColors(AssetClassType.PrivateEquity, opacity),
  ...getColors(AssetClassType.Cash, opacity),
});
