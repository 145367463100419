import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Paragraph } from '../../content/text';

/* ============================
 *    AUM statistics section
 * ============================ */

interface AUMStatsSectionProps {
  listItems: string[];
}

export const AUMStatsSection: FunctionComponent<AUMStatsSectionProps> = ({ listItems }) => {
  // const statsSmallDescriptionCopy = '(2018 vs 2019)';
  return (
    <StatsContainer>
      {/* AUM stats list items */}
      <StatsList>
        {listItems.map((item: string, index) => {
          /* first split the phrase at ':' to separate its title & description */
          const phraseList = item.split(':');
          return (
            <StatsListItem key={index} inverse>
              {/* Give the title its own presentation component */}
              {phraseList[1] && (
                <>
                  <StatsListItemTitle>{phraseList[0] + ':'}</StatsListItemTitle>
                  <span>{phraseList[1]}</span>
                </>
              )}
              {/* ...and give the description a regular html span tag */}
              {!phraseList[1] && <span>{phraseList[0]}</span>}
            </StatsListItem>
          );
        })}
      </StatsList>
      {/* title and description below stats */}
      {/* <StatsDescriptionContainer>
        <StatsGraphTitle semiBold>Avatar AUM</StatsGraphTitle>
        <StatsSmallDescription inverse regular>
          {statsSmallDescriptionCopy}
        </StatsSmallDescription>
      </StatsDescriptionContainer> */}
      {/* Line graphic */}
      {/* <StatsGraphic /> */}
    </StatsContainer>
  );
};

/* ===== Layout UI elements ===== */

const StatsContainer = styled.section`
  display: block;
  box-sizing: border-box;
  ${media.desktop`
    flex-basis:50%;
    width:50%;
    padding-left:50px;
    padding-right:90px;
  `}
`;

/* ===== Component UI elements ===== */

const StatsList = styled.ul`
  padding: 0;
  margin-top: 0;
  margin-bottom: 20px;
  ${media.desktop`
    padding: 0 40px 0 0;
    margin-bottom:40px;
  `}
`;

// const StatsDescriptionContainer = styled.div`
//   margin-bottom: 30px;
// `;

// const StatsGraphic = styled.div`
//   width: 100%;
//   height: 2px;
//   background-color: ${props => props.theme.colors.background_card};
//   position: relative;
//   &:before,
//   &:after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     height: 20px;
//     width: 20px;
//     background-color: ${props => props.theme.colors.background_card};
//   }
//   &:before {
//     left: 0;
//   }
//   &:after {
//     right: 0;
//   }
// `;

/* ===== Type styles ===== */

// const StatsGraphTitle = styled(Paragraph)``;

// const StatsSmallDescription = styled(Paragraph)`
//   ${LabelStyle}
// `;

const StatsListItemTitle = styled.span`
  font-weight: bold;
`;

const StatsListItem = styled(Paragraph)`
  max-width: 335px;
  margin-bottom: 2px;
  ${media.desktop`
    max-width:none;
  `}
`;
