import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
// import { Heading } from '../../content/text';
import { PageSection } from '../../layout/page-section';
import { Heading } from '../../content/text/Heading';
import { Paragraph } from '../../content';

export interface SliderSidePhotoItems {
  title?: string;
  content?: string;
  image?: React.ReactNode;
}

interface ListContentProps {
  active?: boolean;
}

interface SliderSidePhotoContentProps {
  heading?: string;
  items: SliderSidePhotoItems[];
}

export const SliderSidePhotoContent: FunctionComponent<SliderSidePhotoContentProps> = props => {
  const { heading, items } = props;

  const [section, setSection] = useState<number | undefined>(0);

  const sectionChange = (newSelection: number, isMobile = false) => {
    if (isMobile && section === newSelection) {
      setSection(undefined);
      return;
    }
    setSection(newSelection);
  };

  const list = items.map((item, key) => (
    <ListItem key={key}>
      <Title onClick={() => sectionChange(key)}>
        <Paragraph semiBold>{item.title}</Paragraph>
      </Title>
      <ListContent small active={section === key}>
        {item.content}
      </ListContent>
    </ListItem>
  ));

  const image = items.map((item, key) => (
    <ImageHolder key={key} active={section === key}>
      {item.image}
    </ImageHolder>
  ));

  return (
    <PageSection>
      <Container items={items}>
        <LeftContainer>
          {heading && <Heading type={3}>{heading}</Heading>}
          <List>{list}</List>
        </LeftContainer>
        <RightContainer>{image}</RightContainer>
      </Container>
    </PageSection>
  );
};

const Container = styled.div<SliderSidePhotoContentProps>`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  ${media.tablet<SliderSidePhotoContentProps>`
      margin: 40px 0;
      flex-direction: row;
  `}
  ${media.desktop`
    padding: 0 65px;
  `}
`;

const LeftContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  ${media.tablet`
    margin: 20px 0 0 0;
    width: 50%; 
    padding: 0 20px;
  `}
  ${media.desktop`
    padding: 0 60px;
  `}
`;

const RightContainer = styled.div`
  width: 100%;
  ${media.tablet`
    margin: 20px 0 0 0;
    width: 50%;
    padding: 0 20px;
  `}
  ${media.desktop`
    padding: 0 60px;
  `}
`;

const List = styled.ul`
  margin-top: 20px;
`;

const ListItem = styled.li`
  padding: 20px 0;
  border-top: 1px solid ${props => props.theme.colors.border_default};
  border-bottom: 1px solid ${props => props.theme.colors.border_default};
  ${media.tablet`
    max-width: 375px;
  `}
`;

const Title = styled.div`
  ${media.tablet`
    cursor: pointer;
  `}
`;

const ListContent = styled(Paragraph)<ListContentProps>`
  display: block;
  margin-top: 10px;
  ${media.tablet<ListContentProps>`
    display: ${props => (props.active ? 'block' : 'none')};
  `}
`;

const ImageHolder = styled.div<ListContentProps>`
  display: none;
  ${media.tablet<ListContentProps>`
    margin: 0 auto;
    text-align: center;
    display: ${props => (props.active ? 'block' : 'none')};
  `};
`;
