import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import { AssetClassesApiResponse } from './asset-classes.model';

const API_ENDPOINT = '/a/api/dic/note/assetClasses';

export const assetClassesApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.DictionaryAssetClasses],
  })
  .injectEndpoints({
    endpoints: build => {
      const getAssetClasses = build.query<AssetClassesApiResponse, void>({
        query: () => ({
          url: API_ENDPOINT,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.DictionaryAssetClasses],
      });

      return {
        getAssetClasses,
      };
    },
  });
