export enum EntityType {
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  C_CORPORATION = 'C_CORPORATION',
  S_CORPORATION = 'S_CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUST = 'TRUST',
  ESTATE = 'ESTATE',
  LLC = 'LLC',
  IRA = 'IRA',
}
