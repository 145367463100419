import styled from 'styled-components';
import { media, Button, Heading, SectionContainer } from '@yieldstreet/ui-kit';

export const DocumentsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.l};
  ${media.tablet`
    width: 65%;
    margin-top: 0;
  `}
`;

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}
`;

export const DocumentHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: ${({ theme }) => theme.shadows.elevation_3};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const CardWrapper = styled.a`
  width: 100%;

  &:first-of-type,
  &:first-of-type div {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-of-type,
  &:last-of-type div {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & > div {
    margin: 0;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border_default};
  }
`;

export const SSectionContainer = styled(SectionContainer)`
  ${media.desktop`
    padding: 0 ${({ theme }) => theme.spacing.m};
    margin-top: ${({ theme }) => theme.spacing['3xl']}
  `}
`;

export const SubTitle = styled(Heading)`
  margin: 0 0 ${({ theme }) => theme.spacing.m};
`;

export const SButton = styled(Button)`
  display: block;
  margin: ${({ theme }) => theme.spacing.m} auto;
`;
