import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  MarketplaceSliceState,
  allToken,
  selectMarketplacePersist,
  updateMarketplacePersistance,
  userApi,
} from '@yieldstreet/platform-kit';

export const useMarketplaceState = <T>(initialState: T, updateKey: keyof MarketplaceSliceState) => {
  const dispatch = useDispatch();

  const { data } = userApi.useGetUserStateQuery(allToken);
  const currentAuthToken = data?.authToken;

  const { [updateKey]: persistedData, authToken: persistedAuthToken } = useSelector(state =>
    selectMarketplacePersist(state)
  );

  const isSameToken = currentAuthToken && isEqual(currentAuthToken, persistedAuthToken);

  const initialData = useMemo(() => {
    return !persistedData || !isSameToken ? initialState : (persistedData as T);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeState, setActiveState] = useState<T>(initialData);

  const update = (activeData: T) => {
    if (!currentAuthToken || isEqual(activeData, persistedData)) {
      return;
    }

    dispatch(
      updateMarketplacePersistance({
        [updateKey]: activeData,
        authToken: currentAuthToken,
      }) as any
    );
  };

  return { initialState: initialData, update, activeState, setActiveState };
};
