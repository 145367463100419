import {
  configureRTKMockStore,
  getMockEndpoint,
  getRTKMockStoreExtraMiddlewares,
  investmentsApi,
  userApi,
  userState,
  portfolioInvestmentsByAssetClass,
  investorEntityApi,
  investorEntities,
  investmentApi,
  managedPortfolioEligibility,
  getMockErrorEndpoint,
  getMockLoadingEndpoint,
} from '@yieldstreet/platform-kit';

const assetList = [
  {
    assetClass: 'CASH',
    title: 'Cash',
    value: 100,
    percentage: 10,
    name: 'test',
  },
  {
    assetClass: 'REAL',
    title: 'Real estate',
    value: 100,
    percentage: 10,
    name: 'test',
  },
  {
    assetClass: 'SHTN',
    title: 'Short term notes',
    value: 100,
    percentage: 10,
    name: 'test',
  },
];

const investmentStrategyMock = {
  userId: '1',
  investorAccountId: '2',
  investmentStrategyDetails: {
    investmentStrategyId: '7bbd3172-de00-4a71-9f3e-7ea63fcc00ba',
    title: 'Test Title',
    description: 'Test Description',
    assetClassAllocations: [
      {
        allocationPercentage: 0.2,
        assetClass: 'PRIVATE_EQUITY',
      },
      {
        allocationPercentage: 0.25,
        assetClass: 'REAL_ESTATE',
      },
      {
        allocationPercentage: 0.25,
        assetClass: 'PRIVATE_CREDIT',
      },
      {
        allocationPercentage: 0.3,
        assetClass: 'CASH',
      },
    ],
    eligibleOfferings: [
      {
        id: '1',
        noteUrlHash: 'note_test',
        assetClass: 'Asset',
        priority: 1,
        engine: 'Fund',
        fundType: 'Fund',
        minAmount: 10.0,
        interval: 10.0,
        maxAmount: 10.0,
      },
    ],
    investmentStrategyType: 'BALANCED',
    estimatedIncomeReturn: 10.0,
    estimatedAppreciation: 10.0,
    estimatedTotalReturn: 10.0,
    liquidityRisk: 'MEDIUM',
    risk: 1,
    reserve: 0.1,
    operatorUserId: '100',
    operatorName: 'Test operator',
  },
};

const assetMock = {
  ...portfolioInvestmentsByAssetClass,
  currentValueBreakdown: assetList,
};

const getMockDataViewStore = (props = {}) => {
  return configureRTKMockStore(
    [
      getMockEndpoint(userApi, 'getUserState', userState),
      getMockEndpoint(investmentsApi, 'getInvestmentsByAssetClass', assetMock),
      getMockEndpoint(investmentsApi, 'getInvestmentStrategy', investmentStrategyMock),
      getMockEndpoint(investorEntityApi, 'getInvestorEntity', investorEntities),
      getMockEndpoint(investmentApi, 'managedPortfolioEligibility', managedPortfolioEligibility),
    ],
    {
      ...props,
      session: () => ({
        privacyOverlayVisible: false,
      }),
    },
    getRTKMockStoreExtraMiddlewares()
  );
};

const getMockErrorViewStore = (props = {}) => {
  return configureRTKMockStore(
    [
      getMockEndpoint(userApi, 'getUserState', userState),
      getMockEndpoint(investorEntityApi, 'getInvestorEntity', investorEntities),
      getMockEndpoint(investmentApi, 'managedPortfolioEligibility', managedPortfolioEligibility),
      getMockErrorEndpoint(investmentsApi, 'getInvestmentsByAssetClass', 'error'),
      getMockErrorEndpoint(investmentsApi, 'getInvestmentStrategy', 'error'),
    ],
    {
      ...props,
      session: () => ({
        privacyOverlayVisible: false,
      }),
    },
    getRTKMockStoreExtraMiddlewares()
  );
};

const getMockLoadingViewStore = (props = {}) => {
  return configureRTKMockStore(
    [
      getMockEndpoint(userApi, 'getUserState', userState),
      getMockEndpoint(investorEntityApi, 'getInvestorEntity', investorEntities),
      getMockEndpoint(investmentApi, 'managedPortfolioEligibility', managedPortfolioEligibility),
      getMockLoadingEndpoint(investmentsApi, 'getInvestmentsByAssetClass'),
      getMockLoadingEndpoint(investmentsApi, 'getInvestmentStrategy'),
    ],
    {
      ...props,
      session: () => ({
        privacyOverlayVisible: false,
      }),
    },
    getRTKMockStoreExtraMiddlewares()
  );
};

export const TargetAllocationMock = {
  getMockDataViewStore,
  getMockErrorViewStore,
  getMockLoadingViewStore,
};

const useTargetAllocationResponseMock = {
  allocations: {
    Current: [
      { assetClass: 'CASH', percentage: 10, title: 'Cash' },
      { assetClass: 'Real Estate', percentage: 0, title: 'Real Estate' },
      { assetClass: 'Private Credit', percentage: 0, title: 'Private Credit' },
      { assetClass: 'Private Equity', percentage: 0, title: 'Private Equity' },
    ],
    Diff: [
      { assetClass: 'CASH', percentage: -20, title: 'Cash' },
      { assetClass: 'Real Estate', percentage: -25, title: 'Real Estate' },
      { assetClass: 'Private Credit', percentage: -25, title: 'Private Credit' },
      { assetClass: 'Private Equity', percentage: -20, title: 'Private Equity' },
    ],
    Target: [
      { assetClass: 'CASH', percentage: 30, title: 'Cash' },
      { assetClass: 'Real Estate', percentage: 25, title: 'Real Estate' },
      { assetClass: 'Private Credit', percentage: 25, title: 'Private Credit' },
      { assetClass: 'Private Equity', percentage: 20, title: 'Private Equity' },
    ],
  },
  assetList: ['Cash', 'Real Estate', 'Private Credit', 'Private Equity'],
  currentAllocations: [
    { assetClass: 'CASH', name: 'test', percentage: 10, title: 'Cash', value: 100 },
    { assetClass: 'CASH', name: 'test', percentage: 10, title: 'Cash', value: 100 },
  ],
  isError: false,
  isLoading: false,
  targetAllocations: [
    { allocationPercentage: 30, assetClass: 'Cash' },
    { allocationPercentage: 25, assetClass: 'Real Estate' },
    { allocationPercentage: 25, assetClass: 'Private Credit' },
    { allocationPercentage: 20, assetClass: 'Private Equity' },
    { allocationPercentage: 10, assetClass: 'Cash' },
  ],
};

export const TargetAllocationResponseMock = {
  useTargetAllocationResponseMock,
};
