import { InvestorAccount } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeInvestorAccount: ObjectAnonymizer<InvestorAccount> = value =>
  anonymizeObject(value, {
    name: 'string',
    firstName: 'string',
    lastName: 'string',
    addressLine1: 'string',
    addressLine2: 'string',
    city: 'string',
    state: 'string',
    postalCode: 'string',
    aiq: ['array', 'investorAccountAiq'],
  });
