import { BaseProps } from '../../types';

import blastIcon from './icons/blast.svg';
import currencyIcon from './icons/currency.svg';
import filterIcon from './icons/filter.svg';
import flameIcon from './icons/flame.svg';
import refreshIcon from './icons/refresh.svg';
import calendarIcon from './icons/calendar.svg';
import timeIcon from './icons/time.svg';
import updatedIcon from './icons/updated.svg';
import alertIcon from './icons/alert.svg';
import circleIcon from './icons/circle.svg';
import circleCheckIcon from './icons/circle-check.svg';
import circleAlertIcon from './icons/circle-alert.svg';
import devicesIcon from './icons/devices.svg';
import mailIcon from './icons/mail.svg';

export interface IconProps extends Partial<BaseProps> {
  iconType: keyof typeof IconType;
  color?: string;
}

export interface IconWrapperProps extends Partial<BaseProps> {
  icon: string;
}

export enum IconType {
  BLAST = 'BLAST',
  CURRENCY = 'CURRENCY',
  FILTER = 'FILTER',
  FLAME = 'FLAME',
  REFRESH = 'REFRESH',
  CALENDAR = 'CALENDAR',
  TIME = 'TIME',
  UPDATED = 'UPDATED',
  ALERT = 'ALERT',
  CIRCLE = 'CIRCLE',
  CIRCLE_CHECK = 'CIRCLE_CHECK',
  CIRCLE_ALERT = 'CIRCLE_ALERT',
  DEVICES = 'DEVICES',
  MAIL = 'MAIL',
}

export const Icons = {
  [IconType.BLAST]: blastIcon,
  [IconType.CURRENCY]: currencyIcon,
  [IconType.FILTER]: filterIcon,
  [IconType.FLAME]: flameIcon,
  [IconType.REFRESH]: refreshIcon,
  [IconType.CALENDAR]: calendarIcon,
  [IconType.TIME]: timeIcon,
  [IconType.UPDATED]: updatedIcon,
  [IconType.ALERT]: alertIcon,
  [IconType.CIRCLE]: circleIcon,
  [IconType.CIRCLE_CHECK]: circleCheckIcon,
  [IconType.CIRCLE_ALERT]: circleAlertIcon,
  [IconType.DEVICES]: devicesIcon,
  [IconType.MAIL]: mailIcon,
};
