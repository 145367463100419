import { genTestIds } from '@yieldstreet/tool-kit';
import { ThirdPartyProviders } from '@yieldstreet/platform-kit';

import { theme2, themeCadre } from '../../themes';

export const ButtonThemes = {
  ys: theme2,
  cadre: themeCadre,
};

export type ThemedButtonProps = {
  theme: ThirdPartyProviders;
};

const { getTestIds } = genTestIds();

export const testIds = getTestIds('themed-button');
