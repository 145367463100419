import React, { useCallback, useRef, useState } from 'react';

import { Paragraph } from '../../content';
import { useOutsideClick } from '../../hooks/use-outside-click';
import DotsVert from '../../assets/graphics/icons/dots-vertical.svg';

import { DropdownMenuProps } from './DropdownMenu.model';
import {
  DropdownMenuHeader,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuWrapper,
} from './DropdownMenu.style';

export const DropdownMenu = ({ label, icon = DotsVert, options = [] }: DropdownMenuProps) => {
  const [open, setOpen] = useState(false);
  const dropdownListRef = useRef(null);

  useOutsideClick(dropdownListRef, setOpen);

  const handleHeaderClick = useCallback(() => {
    setOpen(state => !state);
  }, []);

  const handleItemClick = useCallback(
    (itemAction: () => void) => {
      itemAction();
      handleHeaderClick();
    },
    [handleHeaderClick]
  );

  return (
    <DropdownMenuWrapper>
      <DropdownMenuHeader onClick={handleHeaderClick}>
        {label && <Paragraph>{label}</Paragraph>}
        <DropdownMenuIcon alt="Menu dots" src={icon} />
      </DropdownMenuHeader>

      <DropdownMenuList ref={dropdownListRef} open={open}>
        {options.map(action => (
          <DropdownMenuItem
            key={action.label}
            onClick={() => {
              handleItemClick(action.onClick);
            }}
          >
            {action.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuList>
    </DropdownMenuWrapper>
  );
};
