import { HttpMethods } from '../../enums/http';
import { restApi, RTKQAll, Tags } from '../common';
import { RepresentativeCurrentApiResponse } from '../../types';

export const getRepresentativeApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.Representative],
  })
  .injectEndpoints({
    endpoints: build => {
      const getRepresentative = build.query<RepresentativeCurrentApiResponse, RTKQAll>({
        query: () => {
          return {
            url: `/a/api/representative/current`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.Representative],
      });

      return {
        getRepresentative,
      };
    },
  });
