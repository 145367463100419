import type { SpacingStyleProps } from '../../base-styles';
import { Spacing as Size } from '../../themes';

export type SkeletonCommonProps = {
  filled?: boolean;
  active?: boolean;
};

export type SkeletonContainerProps = SpacingStyleProps &
  SkeletonCommonProps & {
    spread?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  };

export type SkeletonThumbnailProps = SpacingStyleProps &
  SkeletonCommonProps & {
    ratioX?: number;
    ratioY?: number;
    rounded?: boolean;
    size?: Size;
  };

export type SkeletonLineProps = SkeletonCommonProps & {
  spread?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

export enum SkeletonTextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
}

export type SkeletonTextAlignType = `${SkeletonTextAlign}`;

export type SkeletonTextProps = SkeletonCommonProps & {
  lines?: number;
  align?: SkeletonTextAlignType;
};
