import {
  configureRTKMockStore,
  getMockEndpoint,
  getMockErrorEndpoint,
  getMockLoadingEndpoint,
  investmentApi,
  investorEntities,
  investorEntityApi,
  managedPortfolioEligibility,
  overviewApi,
  portfolioOverview,
  restApi,
  userApi,
  userState,
} from '@yieldstreet/platform-kit';

const errorMockResponse = 'error mock response';

const mockBaseApis = [
  getMockEndpoint(userApi, 'getUserState', userState),
  getMockEndpoint(investorEntityApi, 'getInvestorEntity', investorEntities),
  getMockEndpoint(investmentApi, 'managedPortfolioEligibility', managedPortfolioEligibility),
];

export const getMockStorePortfolioStrategyData = () =>
  configureRTKMockStore(
    [...mockBaseApis, getMockEndpoint(overviewApi, 'getPortfolioOverview', portfolioOverview)],
    {},
    restApi.middleware
  );

export const getMockStorePortfolioStrategyError = () =>
  configureRTKMockStore(
    [...mockBaseApis, getMockErrorEndpoint(overviewApi, 'getPortfolioOverview', errorMockResponse)],
    {},
    restApi.middleware
  );

export const getMockStorePortfolioStrategyLoading = () =>
  configureRTKMockStore(
    [...mockBaseApis, getMockLoadingEndpoint(overviewApi, 'getPortfolioOverview')],
    {},
    restApi.middleware
  );

export const getMockStorePortfolioStrategyEmpty = () =>
  configureRTKMockStore(
    [
      ...mockBaseApis,
      getMockEndpoint(overviewApi, 'getPortfolioOverview', {
        investmentStrategies: [],
      }),
    ],
    {},
    restApi.middleware
  );
