import React from 'react';
import {
  CommunicationMessage,
  CommunicationMessageCategory,
  CommunicationMessageModified,
} from '@yieldstreet/platform-kit';

export const ACTIVITY_STACK_LIMIT_DEFAULT = 10;
export const ACTIVITY_MESSAGES_LIMIT_DEFAULT = 20;
export const ACTIVITY_SNACKBAR_THROTTLE_DEFAULT = 3000;
export const ACTIVITY_MESSAGE_DEBOUNCE_DEFAULT = 2000;
export const ACTIVITY_STACKABLE_CATEGORIES_DEFAULT = [
  CommunicationMessageCategory.InvestmentProgress,
  CommunicationMessageCategory.InvestmentUpdates,
  CommunicationMessageCategory.Payments,
];

export interface UseActivityMessagesProps<Config = ActivityMessageBaseConfig> {
  messages?: CommunicationMessageModified[];
  config?: UseActivityMessagesConfig;
  activityMapperFn: (message: CommunicationMessage) => ActivityMessageCard<Config>;
}

export interface UseActivityMessagesResult<ActivityMessageListType> {
  activityMessages: Record<string, ActivityMessageListDictionary<ActivityMessageListType>>;
}

export interface UseActivityMessagesConfig {
  stackLimit: number;
  stackableCategories: CommunicationMessageCategory[];
}
export const UseActivityMessagesConfigDefault: UseActivityMessagesConfig = {
  stackLimit: ACTIVITY_STACK_LIMIT_DEFAULT,
  stackableCategories: ACTIVITY_STACKABLE_CATEGORIES_DEFAULT,
};

export interface ActivityMessageItem {
  date: string;
  title?: string;
  category?: string;
  content?: string;
  isUnread: boolean;
  messageId?: string;
}

export interface ActivityMessageListDictionary<Config = unknown> {
  date: string;
  groupedMessages: ActivityMessageGroup<Config>[];
}

export interface ActivityMessageGroup<Config = unknown> {
  cards: ActivityMessageCard<Config>[];
  stackConfig?: ActivityMessageStackConfig;
}

export interface ActivityMessageStackConfig {
  hasUnreadMessages?: boolean;
}

export interface ActivityMessageBaseConfig {
  iconType?: CommunicationMessageCategory | null;
  isFullWidth?: boolean;
  borderColour?: string;
}

export interface ActivityMessageCard<
  Config = unknown,
  Result = ActivityMessageBaseConfig & Config
> {
  message: ActivityMessageItem;
  children?: React.ReactNode;
  config?: Result;
}
