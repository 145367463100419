interface CampaignProps {
  fundId: string;
  userId: string | number;
}

export interface Campaign {
  id: string;
  title: string;
  startDate?: number[];
  endDate?: number[];
}

export interface UseCampaignProps extends CampaignProps {
  investorAccountId?: string | number;
}

export interface CampaignEligibilityApiGetRequest extends CampaignProps {
  investorAccountId?: string | number;
  campaignId: string;
}

export interface CampaignEligibilityApiGetAllRequest extends Pick<CampaignProps, 'userId'> {
  investorAccountId?: string | number;
}

export interface CampaignApiPutRequest extends CampaignProps {
  investorAccountId?: string | number;
  votes: SubmitVote[];
}
export interface CampaignApiPutAllRequest extends Pick<CampaignProps, 'userId'> {
  investorAccountId?: string | number;
  votes: SubmitVote[];
}

export interface CampaignEligibilityApiGetResponse {
  success: boolean;
  eligible: boolean;
  message: string;
  votes?: Vote[];
}

export interface CampaignsApiGetRequest extends CampaignProps {}
export interface CampaignsApiGetResponse {
  success: boolean;
  campaigns: Campaign[];
}

export interface CampaignApiPutResponse extends CampaignProps {
  votes: Vote[];
}

export enum VoteValue {
  Yes = 'YES',
  No = 'NO',
  Abstain = 'ABSTAIN',
}

export interface SubmitVote {
  value: VoteValue;
  campaignId: string;
}

export interface SubmitTrackingVote {
  value: VoteValue;
  noteUrlHash: string;
}

export interface Vote {
  id: string;
  userId?: string | number;
  investorAccountId?: string | number;
  accountId?: string;
  value: VoteValue | null;
  status: CampaignStatus;
  campaignId: string;
  noteTitle: string;
  description: string;
  noteUrlHash: string;
  campaignAggregateId: string;
}

export enum CampaignStatus {
  Pending = 'PENDING',
  Computed = 'COMPUTED',
  Canceled = 'CANCELED',
}
