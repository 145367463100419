import { InvestorAccountAiqDocument } from '../../types';
import { anonymizeObject } from '../helpers';
import { ObjectAnonymizer } from '../types';

export const anonymizeInvestorAccountAiqDocument: ObjectAnonymizer<
  InvestorAccountAiqDocument
> = value =>
  anonymizeObject(value, {
    url: 'string',
  });
