import { isNumber, isString } from 'lodash';

import { INTERCOM_TARGET_ATTRIBUTE } from './intercom.model';

export const intercomTargetAttr = (target: string | null) => {
  const isValidTarget = isString(target) || isNumber(target);

  return isValidTarget
    ? {
        [INTERCOM_TARGET_ATTRIBUTE]: target,
      }
    : {};
};
