import merge from 'lodash/merge';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import filter from 'lodash/filter';

import {
  SocketManagerAddConnection,
  SocketManagerConnection,
  SocketManagerEvents,
  SocketManagerReconnectionStrategy,
} from './socket-manager.model';
import { socketHeartbeat } from './socket-manager.utils';

class WebsocketManager {
  connections: Record<string, SocketManagerConnection | undefined> = {};
  timeoutId: number | undefined | any;

  get() {
    return this.connections;
  }

  send(key: string, data: Parameters<WebSocket['send']>[0], params?: object | string) {
    const connection = this.connections[key];
    if (params) {
      const key = typeof params === 'string' ? params : this.getComplexKey(params);
      connection?.sockets[key]?.send(data);
    } else {
      forEach(connection?.sockets, x => x.send(data));
    }
  }

  add(key: string, config: SocketManagerAddConnection, events: Partial<SocketManagerEvents>) {
    const sockets: Record<string, WebSocket> = {};
    const params = config.params || { 1: null };

    const foundConnection = this.connections[key];

    if (foundConnection && !config.params) {
      this.subscribe(key, events);
      return;
    }

    const connection: SocketManagerConnection = foundConnection || {
      config,
      events: [],
      sockets: {},
    };

    connection.events?.push(events);
    forEach(params, param => {
      const socketKey = this.getComplexKey(param);

      const url = this.sanitizeUrl(config.url, param);

      const socket = this.connect(connection, key, socketKey, url, config);
      Object.assign(sockets, { [socketKey]: socket });
    });

    this.connections = merge(this.connections, {
      [key]: { ...connection, sockets },
    });

    this.heartbeat();
  }

  subscribe(key: string, events: Partial<SocketManagerEvents>) {
    const connection = this.connections[key];
    if (!connection) {
      return;
    }

    connection.events.push(events);
  }

  unsubscribe(key: string, events?: Partial<SocketManagerEvents>) {
    const connection = () => this.connections[key];

    if (!events && connection()) {
      this.remove(key);
      return;
    }

    const updatedEvents = filter(connection()?.events, e => e !== events);
    if (!updatedEvents.length) {
      this.remove(key);
      return;
    }

    if (connection()?.events) {
      connection()!.events = updatedEvents;
    }
  }

  remove(key: string) {
    const connectedSockets = this.connections[key]?.sockets;
    const keys = Object.keys(connectedSockets || {});

    for (const selectedKey of keys) {
      try {
        const socket = connectedSockets?.[selectedKey];
        if (socket && socket.readyState === socket.OPEN) {
          socket.close();
        }
      } catch {}
    }

    this.connections = omit(this.connections, key);
  }

  removeAll() {
    forEach(this.connections, (_connection, key) => {
      this.remove(key);
    });
    this.connections = {};
    clearTimeout(this.timeoutId);
  }

  private getComplexKey(object: Object | null) {
    if (!object) return '*';

    return Object.values(object).join('-');
  }

  private connect(
    connection: SocketManagerConnection,
    connectionKey: string,
    socketKey: string,
    url: string,
    config: SocketManagerAddConnection
  ) {
    const socket = new WebSocket(url);

    socket.onopen = ev => {
      forEach(this.connections[connectionKey]?.events, e => {
        e.onOpen?.call(this, socket, ev);
      });
    };

    socket.onmessage = ev => {
      forEach(this.connections[connectionKey]?.events, e => {
        e.onMessage?.call(this, socket, ev);
      });
    };

    socket.onerror = ev => {
      forEach(this.connections[connectionKey]?.events, e => {
        e.onError?.call(this, socket, ev);
      });
    };

    socket.onclose = ev => {
      //Before retry, we need to check WHY close was called.
      if (this.connections[connectionKey]) {
        // an error has occcured //  call handle retry;
        this.handleRetryMechanism(connection, connectionKey, socketKey, url, config);
      } else {
        forEach(connection.events, e => {
          e.onClose?.call(this, socket, ev);
        });
      }
    };

    return socket;
  }

  private handleRetryMechanism(
    connection: SocketManagerConnection,
    connectionKey: string,
    socketKey: string,
    url: string,
    config: SocketManagerAddConnection
  ) {
    const SMART_RETRY_TIMEOUT_INTERVAL = 10000;

    if (
      !config.reconnectionStrategy ||
      config.reconnectionStrategy === SocketManagerReconnectionStrategy.ignore
    ) {
      this.remove(connectionKey);
    } else if (config.reconnectionStrategy === SocketManagerReconnectionStrategy.retry) {
      setTimeout(
        args => {
          const socket = this.connect.bind(args);
          if (this.connections?.[connectionKey]?.sockets[socketKey]) {
            Object.assign(this.connections?.[connectionKey]?.sockets || {}, {
              [socketKey]: socket,
            });
          }
        },
        SMART_RETRY_TIMEOUT_INTERVAL,
        connection,
        connectionKey,
        socketKey,
        url,
        config
      );
    }
  }

  private heartbeat() {
    const TIMEOUT_INTERVAL = 30000;
    if (this.timeoutId) {
      return;
    }
    const handleHeartbeat = (connections: typeof this.connections) => {
      forEach(connections, connection => {
        if (!Object.values(connection?.sockets || {}).length) {
          this.timeoutId = 0;
          return;
        }

        forEach(connection?.sockets, socket => {
          try {
            if (socket.readyState === socket.OPEN) {
              socket.send(socketHeartbeat);
            }
          } catch {}
        });
      });

      this.timeoutId = setTimeout(handleHeartbeat, TIMEOUT_INTERVAL, this.connections);
    };

    this.timeoutId = setTimeout(handleHeartbeat, TIMEOUT_INTERVAL, this.connections);
  }

  private sanitizeUrl(url: string, params?: object) {
    forEach(params, (set, key) => (url = url.replace(`:${key}`, set)));
    return url;
  }
}

export const websocketManager = new WebsocketManager();
