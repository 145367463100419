import { genTestIds } from '@yieldstreet/tool-kit';

import { CardBaseContainerProps } from '../card/Card.model';
import { ReactNode } from 'react';

export const { getTestIds, useTestIds } = genTestIds([
  'thumb',
  'title',
  'assetClass',
  'badge',
  'content',
]);

export interface OfferingCardMetric {
  label?: string;
  value?: string | ReactNode;
}

export interface OfferingCardProps extends CardBaseContainerProps {
  thumbSrc: string;
  thumbAlt?: string;
  assetClass: string;
  offeringTitle: string;
  badges?: React.ReactNode[];
  metrics?: OfferingCardMetric[];
}
