import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { MuiTheme } from '../../../theme';

import { Image } from './SidePhotoContent.model';
import { ImagePosition } from './SidePhotoContent.model';
import { SidePhotoContentProps } from './SidePhotoContent.model';

const getTextContainerPadding = ({
  imagePosition,
  removePaddingSide,
  small,
  theme,
}: {
  imagePosition?: string;
  removePaddingSide?: boolean;
  small?: boolean;
  theme: MuiTheme;
}) => {
  if (removePaddingSide || small) {
    return '0';
  }

  return imagePosition === 'left'
    ? `0 ${theme.spacing(28)} 0 ${theme.spacing(10)}`
    : `0 0 0 ${theme.spacing(28)}`;
};

export const Close = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  height: '16px',
  cursor: 'pointer',
}));

export const Container = styled('div')<SidePhotoContentProps>(
  ({
    backgroundImage,
    imagePosition,
    removeFooterSpacing,
    removeHeaderSpacing,
    removePaddingSide,
    reverseMobile,
    theme,
  }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: reverseMobile ? 'column-reverse' : 'column',
    flexWrap: 'wrap',
    margin: `${theme.spacing(5)} 0`,

    ...(removeFooterSpacing && {
      marginBottom: 0,
    }),

    ...(removeHeaderSpacing && {
      marginTop: 0,
    }),

    ...(backgroundImage && {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight: '300px',
    }),

    [theme.breakpoints.up('md')]: {
      flexDirection: imagePosition === 'left' ? 'row-reverse' : 'row',
      margin: `${theme.spacing(10)} auto`,

      ...(removePaddingSide && {
        padding: `0 ${theme.spacing(5)}`,
      }),
    },

    [theme.breakpoints.up('lg')]: {
      ...(removePaddingSide && {
        maxWidth: '1180px',
      }),
    },
  })
);

export const Content = styled('div')(({ theme }) => ({
  margin: theme.spacing(5),
  marginTop: 0,

  'p:not(:last-child)': {
    marginBottom: theme.spacing(5),
  },
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: `0 ${theme.spacing(5)}`,

  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}));

export const MainImage = styled('div')<Image>(({ imageBorder, theme }) => ({
  img: {
    width: '100%',
    ...(imageBorder && {
      borderBottom: `10px solid ${theme.palette.brand.tertiary.main}`,
    }),
  },
  video: {
    width: '100%',
  },
}));

export const RightContainer = styled('div')<ImagePosition>(
  ({ removeImageMargin, small, theme }) => ({
    width: '100%',
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      margin: removeImageMargin ? `0` : `${theme.spacing(5)} 0 0`,
      padding: `0 ${theme.spacing(15)}`,
      width: '50%',
      ...(small && {
        display: 'flex',
        alignItems: 'flex-end',
        padding: `0 ${theme.spacing(5)}`,
      }),
    },
  })
);

export const SplashImage = styled('div')<ImagePosition>(({ imagePosition, theme }) => ({
  marginTop: theme.spacing(5),
  width: '100%',

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    bottom: '-30px',
    width: 'auto',
    ...(imagePosition === 'left' ? { right: '-60px' } : { left: '-60px' }),
  },
}));

export const TextContainer = styled('div')<ImagePosition>(
  ({ imagePosition, removePaddingSide, small, theme }) => ({
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '50%',
      padding: getTextContainerPadding({ imagePosition, removePaddingSide, small, theme }),
    },
  })
);

export const Title = styled(Typography)<{ inverse?: boolean }>(({ inverse, theme }) => ({
  color: inverse ? theme.palette.text.header.inverse : theme.palette.text.header.default,
}));
