import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';

/* Layout elements */
import { PageSection } from '../../layout/page-section';

/* UI elemenets */
import { IndustrySectorsDesktop, IndustrySectorsMobile } from './industrySectors';
import { AUMStatsSection } from './AUMStats';
import { CopyBlock } from './copyBlock';
import { Callout } from './callouts';

/* ====================================
 *    'Grow you AUM with us' section
 * ==================================== */

interface GrowYourAUMProps {
  title: string;
  subtitle: string;
  description: string;
  AUMStats: string[];
  callout: string;
}

export const GrowYourAUM: FunctionComponent<GrowYourAUMProps> = ({
  title,
  subtitle,
  description,
  AUMStats,
  callout,
}) => {
  return (
    <PageSection>
      <ContentContainer>
        <CopyBlock title={title} subtitle={subtitle} description={description} />
        <IndustrySectorsMobile />
        <IndustrySectorsDesktop />
        <AUMStatsSection listItems={AUMStats} />
        <Callout copy={callout} />
      </ContentContainer>
    </PageSection>
  );
};

/* ===== Layout UI elements ===== */

const ContentContainer = styled.div`
  display: block;
  padding-top: 20px;
  position: relative;
  padding: 20px;
  &:before,
  &:after {
    display: none;
    content: '';
    position: absolute;
    width: 100%;
    background-color: ${props => props.theme.colors.background_card};
    height: 20px;
    ${media.desktop`
      display:block;
      height:40px;
    `}
    & * {
      box-sizing: border-box !important;
    }
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  ${media.desktop`
    padding:0px;
    max-width:1250px;
    margin: 0 auto;
    padding-top:88px;
    padding-bottom:480px;
    display: flex;
    background: linear-gradient(
      to right,
      ${props => props.theme.colors.background_card} 1%,
      ${props => props.theme.colors.background_card} 50%,
      ${props => props.theme.colors.background_inverse} 50%,
      ${props => props.theme.colors.background_inverse} 100%
      );
  `}
  background: linear-gradient(
    to bottom,
    ${props => props.theme.colors.background_card} 1%,
    ${props => props.theme.colors.background_card} 47%,
    ${props => props.theme.colors.background_inverse} 47%,
    ${props => props.theme.colors.background_inverse} 100%
    );
`;
