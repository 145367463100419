import { HttpMethods } from '../../enums/http';

import { restApi, Tags } from '../common';
import { ETCPurchaseRequest } from './etc.model';

export const etcApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.EtcPurchaseRequest],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getInvestmentPurchaseRequests = builder.query<ETCPurchaseRequest[], string>({
        query: investmentId => ({
          url: `/a/api/etc/purchase-requests?investmentId=${investmentId}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.EtcPurchaseRequest],
        transformResponse: response => response?.investments?.[0]?.requests,
      });

      return {
        getInvestmentPurchaseRequests,
      };
    },
  });
