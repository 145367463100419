import styled from 'styled-components';

import { Surface } from '../surface/Surface';

export const SurfaceImageWrapper = styled(Surface)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: inherit;
  }
`;
