import { HttpMethods } from '../../../enums';
import { restApi } from '../../common';

import {
  ChangeUserPasswordApiRequest,
  SetUserPasswordRequest,
  UserResetPasswordApiRequest,
  UserResetPasswordApiResponse,
} from './password.model';

export const userPasswordApi = restApi.injectEndpoints({
  endpoints: builder => {
    const setPassword = builder.mutation<{}, SetUserPasswordRequest>({
      query: data => {
        return {
          url: '/api/auth/set-password',
          method: HttpMethods.Post,
          data,
        };
      },
    });

    const changePassword = builder.mutation<{}, ChangeUserPasswordApiRequest>({
      query: data => {
        return {
          url: '/a/api/user/password',
          method: HttpMethods.Put,
          data,
        };
      },
    });

    const resetPassword = builder.mutation<
      UserResetPasswordApiResponse,
      UserResetPasswordApiRequest
    >({
      query: ({ email }) => {
        return {
          url: '/a/api/user/resetPassword',
          method: HttpMethods.Post,
          data: { email },
        };
      },
      transformResponse: (response: UserResetPasswordApiResponse) => {
        return response;
      },
    });

    return {
      setPassword,
      changePassword,
      resetPassword,
    };
  },
});

export const resetPasswordApi = userPasswordApi;
