import { Audiences, PaymentMethodType } from '../../enums';
import {
  OfferingPromotion,
  OfferingPromotionGate,
  OfferingPromotionGateCode,
  OfferingPromotionStatus,
  OfferingPromotionEligibilityResult,
  Promotion,
  PromotionType,
  BulkOfferingPromotionEligibilityResult,
} from './offering-promotion.model';

export const promoKeyMock = '100-free-investment';
export const urlHashMock = 'dhrswg';

export const freeInvestmentPromoMock: Promotion<PromotionType.FREE_INVESTMENT> = {
  id: '30f10630-4320-11ed-8b8f-092402ffe4c2',
  key: promoKeyMock,
  type: PromotionType.FREE_INVESTMENT,
  config: {
    investmentAmountValue: 100,
    paymentMethod: PaymentMethodType.ACH,
    sourceBankAccountId: '5678',
  },
};

export const offeringPromoMock: OfferingPromotion = {
  id: '30f10630-4320-11ed-8b8f-092402ffe4c2',
  promotionId: '30f10630-4320-11ed-8b8f-092402ffe4c2',
  noteUrlHash: urlHashMock,
  status: OfferingPromotionStatus.ACTIVE,
  maximumCountLimit: 1,
  maximumInvestmentCount: 100,
  audiences: {
    [Audiences.repeatInvestor]: {
      NOTE_URL_HASH: urlHashMock,
    },
    [Audiences.firstTimeInvestor]: {},
  },
};

export const offeringPromoGateMock: OfferingPromotionGate = {
  code: OfferingPromotionGateCode.LIMIT_REACHED,
};

export const offeringPromoGateInvalidMock: OfferingPromotionGate = {
  code: OfferingPromotionGateCode.PROMOTION_INVALID,
};

export const offeringPromoElgibilityMock: OfferingPromotionEligibilityResult = {
  gate: null,
  promotion: freeInvestmentPromoMock,
  offeringPromotion: offeringPromoMock,
};

export const offeringPromoElgibilityGatedMock: OfferingPromotionEligibilityResult = {
  gate: offeringPromoGateMock,
  promotion: freeInvestmentPromoMock,
  offeringPromotion: offeringPromoMock,
};

export const offeringPromoElgibilityGatedInvalidMock: OfferingPromotionEligibilityResult = {
  gate: offeringPromoGateInvalidMock,
  promotion: null,
  offeringPromotion: null,
};

export const bulkPromoElgibilityMock: BulkOfferingPromotionEligibilityResult = {
  [urlHashMock]: offeringPromoElgibilityMock,
};

export const bulkPromoElgibilityGatedMock: BulkOfferingPromotionEligibilityResult = {
  [urlHashMock]: offeringPromoElgibilityGatedMock,
};

export const bulkPromoElgibilityGatedInvalidMock: BulkOfferingPromotionEligibilityResult = {
  [urlHashMock]: offeringPromoElgibilityGatedInvalidMock,
};
