import React, { useMemo } from 'react';

import { Label, Paragraph } from '../../content';

import { OfferingCardBase } from './base-layout/OfferingCardBase';
import { OfferingCardProps, useTestIds } from './OfferingCardV2.model';
import {
  OfferingThumb,
  TitleContainer,
  AssetClass,
  OfferingTitle,
  BadgesContainer,
  ContentContainer,
  MetricWrapper,
  OfferingTitleWrapper,
  MainContainer,
} from './OfferingCardV2.style';

export const OfferingCardV2: React.FC<OfferingCardProps> = props => {
  const { thumbSrc, assetClass, offeringTitle, metrics, thumbAlt, badges } = props;

  const testIds = useTestIds(props);

  const ThumbComponent = useMemo(() => {
    return <OfferingThumb alt={thumbAlt} src={thumbSrc} {...testIds.thumb.attr}></OfferingThumb>;
  }, [thumbAlt, thumbSrc, testIds.thumb.attr]);

  const MainComponent = useMemo(
    () => (
      <MainContainer>
        <TitleContainer>
          <AssetClass {...testIds.assetClass.attr}>{assetClass}</AssetClass>
          <OfferingTitleWrapper>
            <OfferingTitle {...testIds.title.attr} type={4}>
              {offeringTitle}
            </OfferingTitle>
          </OfferingTitleWrapper>
        </TitleContainer>

        <BadgesContainer {...testIds.badge.attr}>{badges ? badges : null}</BadgesContainer>
      </MainContainer>
    ),
    [assetClass, offeringTitle, badges, testIds]
  );

  const ContentComponent = useMemo(() => {
    if (!!metrics && metrics?.length > 0) {
      return (
        <ContentContainer {...testIds.content.attr}>
          {metrics?.map(metric => {
            return (
              <MetricWrapper>
                <Label>{metric.label}</Label>
                <Paragraph semiBold size="small">
                  {metric.value}
                </Paragraph>
              </MetricWrapper>
            );
          })}
        </ContentContainer>
      );
    }

    return null;
  }, [metrics, testIds.content.attr]);

  return (
    <OfferingCardBase
      topComponent={ThumbComponent}
      mainComponent={MainComponent}
      contentComponent={ContentComponent}
      {...props}
    ></OfferingCardBase>
  );
};
