import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import { anonymizerRegister, ObjectAnonymizeRegister } from './register';

import {
  Value,
  ValueArray,
  ValueObject,
  InputObject,
  AnonymizeType,
  AnonymizeTypeMap,
  AnonymizeKey,
  AnonymizeValue,
  ObjectAnonymizeKey,
  ObjectAnonymizer,
  ObjectAnonymizeValue,
  Structure,
  StructureType,
  StructureKey,
  StructureValue,
  ArrayStructure,
} from './types';

export const anonymizeString = (value: string) => (value ? value.replace(/[0-9a-z]/gi, '*') : '');

const isAnonymizeTypeMap = (type: AnonymizeType<any>): type is AnonymizeTypeMap<any> =>
  isObject(type);

const isAnonymizeKey = (type: AnonymizeType<any>): type is AnonymizeKey => isString(type);

const isObjectAnonymizeKey = (type: AnonymizeKey): type is ObjectAnonymizeKey =>
  type in anonymizerRegister;

const isStructure = (type: AnonymizeType<any>): type is Structure<StructureType, StructureKey> =>
  isArray(type);

const isArrayStructure = (
  type: Structure<StructureType, StructureKey>
): type is ArrayStructure<StructureType> => type[0] === 'array';

const getObjectAnonymizer = <K extends ObjectAnonymizeKey>(type: K) => {
  return anonymizerRegister[type] as unknown as ObjectAnonymizer<ObjectAnonymizeRegister[K]>;
};

const anonymizeValue = (value: AnonymizeValue, type: AnonymizeType<any>) => {
  if (isNil(value)) {
    return value;
  } else if (isStructure(type)) {
    return anonymizeStructure(value as StructureValue, type);
  } else if (isAnonymizeTypeMap(type)) {
    return anonymizeObject(value, type);
  } else if (isAnonymizeKey(type)) {
    if (isObjectAnonymizeKey(type)) {
      const anonymizeType = getObjectAnonymizer(type);
      return anonymizeType(value as ObjectAnonymizeValue);
    } else {
      return anonymizeString(value as Value);
    }
  }
};

const anonymizeStructure = (
  value: StructureValue,
  structure: Structure<StructureType, StructureKey>
): StructureValue => {
  if (isArrayStructure(structure)) {
    return (value as ValueArray<any>).map(value => anonymizeValue(value, structure[1]));
  } else {
    return mapValues(value as ValueObject<any>, value => anonymizeValue(value, structure[1]));
  }
};

export const anonymizeObject = <V extends InputObject>(
  object: V,
  types: AnonymizeTypeMap<V>
): V => {
  if (!isObject(object)) {
    return object;
  }
  const result: V = { ...object };
  Object.keys(types).forEach((key: keyof V) => {
    const value = object[key];
    const type = types[key];
    if (!isNil(value)) {
      result[key] = anonymizeValue(value, type);
    }
  });
  return result;
};
