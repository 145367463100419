import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';

export const queryParser = (params?: Record<string, any>): string => {
  if (!params) {
    return '';
  }

  const combinedParams: string[] = [];

  forEach(params, (value, key) => {
    if (isEmpty(value) && !isBoolean(value) && !isNumber(value)) {
      return;
    }

    if (isArray(value)) {
      combinedParams.push(...map(value, item => `${key}[]=${item}`));
    } else {
      combinedParams.push(`${key}=${value}`);
    }
  });
  return combinedParams.join('&');
};
