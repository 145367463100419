import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../themes';
import { Heading, Paragraph } from '../../content/text';
import { PageSection } from '../../layout/page-section';

interface BioContainerProps {
  quote?: string;
  quoteBy?: string;
}

export const BioContainer: FunctionComponent<BioContainerProps> = ({
  quote,
  quoteBy,
  children,
}) => {
  return (
    <PageSection>
      <Container>
        <ContentHolder>{children}</ContentHolder>
        <QuoteHolder>
          <Quote type={4}>{quote}</Quote>
          <QuoteBy semiBold>{quoteBy}</QuoteBy>
        </QuoteHolder>
      </Container>
    </PageSection>
  );
};

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    padding: 40px 130px 100px 130px;
  `}
`;

const QuoteHolder = styled.div`
  width: 100%;
  ${media.desktop`
    margin: 20px 0 0 0;
    width: 40%;
  `}
`;

const ContentHolder = styled.div`
  width: 100%;
  margin: 20px 0 0 0;
  ${media.desktop`
    width: 60%;
  `}
`;

const Quote = styled(Heading)`
  width: 100%;
`;

const QuoteBy = styled(Paragraph)`
  margin: 40px 0 0 0;
`;
