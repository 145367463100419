import {
  FundBalanceCalculationType,
  FundCapitalBalanceReductionType,
  FundProvider,
} from '@yieldstreet/platform-kit';

export const ALL = 'ALL';
export const FUND_NOTE_URL_HASH_PRISM = 'LJouqg';
export const FUND_NOTE_URL_HASH_REIT = 'e5jyfw';

export interface UseInvestmentSummaryProps {
  investorId?: string;
  noteUrlHash?: string;
  userId?: number;
}

export const FundBalanceCalculationTypes = [FundBalanceCalculationType.InvestedMinusCapital];

export const FundCapitalBalanceReductionTypes = [
  FundCapitalBalanceReductionType.CapitalDistributed,
  FundCapitalBalanceReductionType.IncomeDistributed,
];

export const YSFundProviders = [FundProvider.Ys, FundProvider.Geneva];
