import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import {
  DocumentUploadResponse,
  DocumentUploadRequest,
  SubmitAutoAiqParams,
  AiqAutoVerificationResponse,
  GetAutoAiqParams,
  AutoAiqResponse,
} from '../../types';

export const accreditationApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.OfferingEligibility],
  })
  .injectEndpoints({
    endpoints: builder => {
      const uploadDocument = builder.mutation<DocumentUploadResponse, DocumentUploadRequest>({
        query: ({ file, title, type }) => {
          const data = new FormData();
          data.append('file', file as Blob);
          data.append('title', title);
          data.append('type', type);
          return {
            url: '/a/api/document/temp/upload',
            method: HttpMethods.Post,
            headers: { 'content-type': 'multipart/form-data' },
            data,
          };
        },
      });

      const submitAutoAiq = builder.mutation<AutoAiqResponse, SubmitAutoAiqParams>({
        query: ({ request, investorAccountId }) => ({
          url: `/a/api/investor/account/${investorAccountId}/auto-aiq`,
          method: 'POST',
          data: request,
        }),
      });

      const getAutoAiq = builder.query<AiqAutoVerificationResponse, GetAutoAiqParams>({
        query: ({ aiqId, investorAccountId }) => ({
          url: `/a/api/investor/account/${investorAccountId}/autoaiq/${aiqId}`,
          method: 'GET',
        }),
      });

      return {
        uploadDocument,
        submitAutoAiq,
        getAutoAiq,
      };
    },
  });
