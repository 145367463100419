import React from 'react';
import { globalHistory } from '@reach/router';
import {
  theme2,
  MarketingContainer,
  ModalProvider,
  SpacingTokens as GlobalStyles,
} from '@yieldstreet/ui-kit';
import styled, { ThemeProvider } from 'styled-components';

const Wrapper = styled.div``;

export interface BaseLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const BaseLayout = ({ className, children }: BaseLayoutProps) => {
  return (
    <ThemeProvider theme={theme2}>
      <MarketingContainer>
        <ModalProvider history={globalHistory}>
          <GlobalStyles />
          <Wrapper className={className}>{children}</Wrapper>
        </ModalProvider>
      </MarketingContainer>
    </ThemeProvider>
  );
};

export default BaseLayout;
