import { OfferingV5 } from './offering-v5';

export type NoteResponse = {
  data: {
    success: boolean;
    note: OfferingV5;
  };
};

export enum FundType {
  FortyActFund = 'FORTY_ACT_FUND',
  InternalFund = 'INTERNAL_FUND',
  ThirdPartyFund = 'THIRD_PARTY_FUND',
  FeederFund = 'FEEDER_FUND',
  RegAPlus = 'REG_A_PLUS',
}

// reference https://yieldstreet.slack.com/archives/C05BDTJ20F3/p1686775315978689
export enum TenderOfferType {
  ENHANCED_CRYPTO = 'ENHANCED_CRYPTO',
  PRISM = 'PRISM',
  REIT = 'REIT',
  PRIVATE = 'PRIVATE',
}

export enum OfferingDocuments {
  OTHR = 'OTHR', // 'Other',
  OPER = 'OPER', // 'Operating Agreement',
  PRPM = 'PRPM', // 'Private Placement Memorandum',
  OFTP = 'OFTP', // 'Offer to Purchase',
  LOTL = 'LOTL', // 'Letter of Transmittal',
  TFAQ = 'TFAQ', // 'Tender Offer FAQ',
  AOFI = 'AOFI', // 'Articles of Incorporation',
  CLNG = 'CLNG', // 'Closing Binder',
  NOTE = 'NOTE', // 'Exemplar Note',
  EIND = 'EIND', // 'Form 147C (EIN document)',
  IFON = 'IFON', // 'Indenture & Form of Note',
  OFHR = 'OFHR', // 'Offering Hero Image',
  OFTB = 'OFTB', // 'Offering Thumbnail',
  PRST = 'PRST', // 'Portfolio Status',
  PRSP = 'PRSP', // 'Prospectus',
  SUBS = 'SUBS', // 'Subscription Agreement',
  SUBA = 'SUBA', // 'Subscription Agreement Appendix',
  SUPP = 'SUPP', // 'Supporting Docs',
  BPDN = 'BPDN', // 'BPDN Document',
  RISK = 'RISK', // 'Risk Factors',
}

export enum NoteAttachmentType {
  AOFI = 'Articles of Incorporation',
  CLNG = 'Closing Binder',
  NOTE = 'Exemplar Note',
  EIND = 'Form 147C (EIN document)',
  IFON = 'Indenture & Form of Note',
  OFHR = 'Offering Hero Image',
  OFTB = 'Offering Thumbnail',
  OPER = 'Operating Agreement',
  PRST = 'Portfolio Status',
  PRPM = 'Private Placement Memorandum',
  PRSP = 'Prospectus',
  SUBS = 'Subscription Agreement',
  SUBA = 'Subscription Agreement Appendix',
  SUPP = 'Supporting Docs',
  BPDN = 'BPDN Document',
  RISK = 'Risk Factors',
  OTHR = 'Other',
  OFTP = 'Offer to Purchase',
  LOTL = 'Letter of Transmittal',
  TFAQ = 'Tender Offer FAQ',
}
