import styled, { css } from 'styled-components';
import { InvestmentPerformanceStatus } from '@yieldstreet/platform-kit';

import { StatusTagInfo, TagLabelProps } from './StatusTag.model';

export const Tag = styled.div<{ status: InvestmentPerformanceStatus }>`
  background: ${props => StatusTagInfo(props.status).color};
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;
`;

const LabelStyleSmall = css`
  font-size: 10px;
`;

const LabelStyle = css`
  font-size: 12px;
`;

export const TagLabel = styled.label<TagLabelProps>`
  color: ${props => props.theme.colors.text_secondary_paragraph} !important;
  font-weight: 500;
  line-height: 14px;
  ${props => (props?.small ? LabelStyleSmall : LabelStyle)}
`;

export const TagContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.xs};
`;

export const Icon = styled.img`
  height: 20px;
  width: 20px;
`;
