import React, { FC } from 'react';
import { Box } from '@mui/material';

import { ContentHolder } from './PageSection.style';

export const PageSection: FC = ({ children }) => {
  return (
    <Box>
      <ContentHolder>{children}</ContentHolder>
    </Box>
  );
};
