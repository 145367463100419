export enum SignupQuizSteps {
  EXPERIENCE = 'experience',
  EXPERIENCE_ALTS = 'experience_alts',
  YOUR_EXPERIENCE = 'your-experience',
  PORTFOLIO = 'portfolio',
  ACCREDITATION = 'accreditation',
  REVIEW = 'review',
  SIGNUP = 'signup',
  ACCREDITED = 'accredited',
  INVESTING_REASONS = 'investing-reasons',
  USUAL_INVESTING_WAY = 'usual-investing-way',
  INTERESTED_ASSET_CLASSES = 'interested-asset-classes',
}

export enum SignupExperienceAltsValues {
  YES = 'YES',
  NO = 'NO',
}

export enum SignupInterestedValues {
  ART = 'ART',
  CRYPTO = 'CRYPTO',
  LEGAL = 'LEGAL',
  MULTI_ASSET_CLASS_FUND = 'MULTI_ASSET_CLASS_FUND',
  PRIVATE_CREDIT = 'PRIVATE_CREDIT',
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
  REAL_ESTATE = 'REAL_ESTATE',
  SHORT_TERM_NOTES = 'SHORT_TERM_NOTES',
  TRANSPORTATION = 'TRANSPORTATION',
  VENTURE_CAPITAL = 'VENTURE_CAPITAL',
}

export enum SignupInvestingValues {
  DIVERSIFY_MY_PORTFOLIO = 'DIVERSIFY_MY_PORTFOLIO',
  GENERATE_INCOME = 'GENERATE_INCOME',
  GROW_LONG_TERM_WEALTH = 'GROW_LONG_TERM_WEALTH',
  REDUCE_PORTFOLIO_VOLATILITY = 'REDUCE_PORTFOLIO_VOLATILITY',
}

export enum SignupPortfolioValues {
  UNDER_50K = 'UNDER_50K',
  BETWEEN_50_250 = 'BETWEEN_50_250',
  BETWEEN_250_1M = 'BETWEEN_250_1M',
  BETWEEN_1M_5M = 'BETWEEN_1M_5M',
  MORE_5M = 'MORE_5M',
}

export enum SignupUsualInvestingWayValues {
  ACTIVELY_MANAGE_MY_PORTFOLIO = 'ACTIVELY_MANAGE_MY_PORTFOLIO',
  PASSIVELY_MANAGE_MY_PORTFOLIO = 'PASSIVELY_MANAGE_MY_PORTFOLIO',
  PARTNER_WITH_A_FINANCIAL_ADVISOR = 'PARTNER_WITH_A_FINANCIAL_ADVISOR',
}

export enum SignupAccreditationMethods {
  INDIVIDUAL_JOINT = 'INDIVIDUAL_JOINT',
  NETWORTH = 'NETWORTH',
  LICENSED_BROKER = 'LICENSED_BROKER',
  ENTITY = 'ENTITY',
  NONE = 'NONE',
}

export enum GoalQuizSteps {
  PRIMARY_FINANCIAL_GOAL = 'primary-financial-goal',
  MINIMUM_TARGET = 'minimum-target-return',
  MAXIMUM_TARGET = 'maximum-target-term',
  AMOUNT_90_DAYS = 'invest-next-90-days',
  MAX_AMOUNT_PER_OFFERING = 'invest-offering-maximum',
}

export enum GoalQuizPrimaryFinancialValues {
  DIVERSIFY_STOCK_MARKET = 'DIVERSIFY_STOCK_MARKET',
  DIVERSIFY_FIXED_INCOME = 'DIVERSIFY_FIXED_INCOME',
  INCREASE_YIELD = 'INCREASE_YIELD',
  SUPPLEMENT_INCOME = 'SUPPLEMENT_INCOME',
}
