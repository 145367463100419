import { PortfolioPrincipal } from '../models';

export const portfolioPrincipal: PortfolioPrincipal = {
  beginningOutstandingPrincipal: 0,
  endingOutstandingPrincipal: 54408.04,
  endingOutstandingPrincipalPostDefault: 31699.97,
  endingOutstandingPrincipalPreDefault: 22708.07,
  isInDefault: true,
  liquidationDiscount: 0,
  liquidationPaid: 0,
  repaidPrincipal: 0,
  totalInvested: 54408.04,
  principalTimeline: [
    {
      date: '2016-06-30',
      liquidationDiscount: 0,
      liquidationPaid: 0,
      outstandingPostDefault: 0,
      outstandingPreDefault: 0,
      paid: 0,
      principalWriteOff: 0,
      value: 0,
    },
    {
      date: '2016-07-31',
      liquidationDiscount: 0,
      liquidationPaid: 0,
      outstandingPostDefault: 0,
      outstandingPreDefault: 15000,
      paid: 0,
      principalWriteOff: 0,
      value: 15000,
    },
    {
      date: '2016-08-31',
      liquidationDiscount: 0,
      liquidationPaid: 0,
      outstandingPostDefault: 0,
      outstandingPreDefault: 14928.69,
      paid: 0,
      principalWriteOff: 0,
      value: 14928.69,
    },
  ],
};
