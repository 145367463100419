import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums/http';
import {
  SocketManagerReconnectionStrategy,
  SocketManagerEvents,
  websocketManager,
} from '../../websocket';
import { selectConfig } from '../config';
import {
  COMMUNICATION_BASE_URL,
  CommunicationMessageModified,
  FetchMessagesApiResponse,
  FetchMessagesApiRequestParams,
  FetchMessagesUpdateStrategy,
  ReadMessageApiResponse,
  ReadMessageApiRequestParams,
  ReadMessagesApiResponse,
  ReadMessagesApiRequestParams,
  DismissMessageApiResponse,
  DismissMessageApiRequestParams,
  DismissMessagesApiResponse,
  DismissMessagesApiRequestParams,
} from './communication.model';
import {
  updateMessage,
  updateMessages,
  updateMessageArgs,
  updateCache,
  getQueryParams,
  getCacheParams,
  constructTagId,
  onSocketMessage,
  onSocketOpen,
} from './communication.util';

export const communicationApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.Communication],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getMessages = builder.query<
        CommunicationMessageModified[],
        FetchMessagesApiRequestParams
      >({
        queryFn: () => ({
          data: [],
        }),
        providesTags: [Tags.Communication],
        async onCacheEntryAdded(
          params,
          { cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
        ) {
          if (params?.authToken && params?.updates !== FetchMessagesUpdateStrategy.INITIAL_ONLY) {
            const state = getState();
            const { wssHost } = selectConfig(state as any);

            const webSocketEvents: Partial<SocketManagerEvents> = {
              onOpen: () => onSocketOpen(Tags.Communication, params.authToken as string),
              onMessage: (_, ev) => onSocketMessage(ev, dispatch, params),
            };

            try {
              await cacheDataLoaded;
              websocketManager.add(
                Tags.Communication,
                {
                  url: `${wssHost}/api/communication/ws/messages`,
                  reconnectionStrategy: SocketManagerReconnectionStrategy.retry,
                },
                webSocketEvents
              );
              await cacheEntryRemoved;
            } finally {
              websocketManager.unsubscribe(Tags.Communication, webSocketEvents);
            }
          }
        },
      });

      const fetchMessages = builder.query<FetchMessagesApiResponse, FetchMessagesApiRequestParams>({
        query: params => {
          return params.updates !== FetchMessagesUpdateStrategy.LIVE_ONLY
            ? {
                url: `${COMMUNICATION_BASE_URL}`,
                method: HttpMethods.Get,
                params: getQueryParams(params),
              }
            : {};
        },
        providesTags: (result, _error, args) =>
          result
            ? [{ type: Tags.Communication, id: constructTagId(args) }, Tags.Communication]
            : [Tags.Communication],
        transformResponse: (
          response: FetchMessagesApiResponse,
          _meta,
          args: FetchMessagesApiRequestParams
        ) => ({
          ...response,
          items: updateMessageArgs(response?.items, args),
        }),
        async onQueryStarted(params, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(
              communicationApi.util.updateQueryData('getMessages', getCacheParams(params), draft =>
                params.page && params.page > 1
                  ? updateCache(draft, data?.items || [])
                  : [...(data?.items || [])]
              )
            );
          } catch {}
        },
      });

      const readMessage = builder.mutation<ReadMessageApiResponse, ReadMessageApiRequestParams>({
        query: ({ messageSpecId, read }) => ({
          url: `${COMMUNICATION_BASE_URL}/${messageSpecId}/read`,
          method: HttpMethods.Post,
          data: {
            read,
          },
        }),
        async onQueryStarted({ messageSpecId, messageArgs, read }, { dispatch }) {
          dispatch(
            communicationApi.util.updateQueryData(
              'getMessages',
              getCacheParams(messageArgs),
              draft => updateMessage(draft, messageSpecId, { seen: read })
            )
          );
        },
      });

      const readMessages = builder.mutation<ReadMessagesApiResponse, ReadMessagesApiRequestParams>({
        query: ({ messageSpecIds, read }) => ({
          url: `${COMMUNICATION_BASE_URL}/read`,
          method: HttpMethods.Post,
          data: {
            read,
            messageSpecIds,
          },
        }),
        async onQueryStarted({ messageSpecIds, messageArgs, read }, { dispatch }) {
          dispatch(
            communicationApi.util.updateQueryData(
              'getMessages',
              getCacheParams(messageArgs),
              draft => updateMessages(draft, messageSpecIds, { seen: read })
            )
          );
        },
      });

      const dismissMessage = builder.mutation<
        DismissMessageApiResponse,
        DismissMessageApiRequestParams
      >({
        query: ({ messageSpecId, dismissed }) => ({
          url: `${COMMUNICATION_BASE_URL}/${messageSpecId}/dismiss`,
          method: HttpMethods.Post,
          data: {
            dismiss: dismissed,
          },
        }),
        async onQueryStarted({ messageSpecId, messageArgs, dismissed }, { dispatch }) {
          dispatch(
            communicationApi.util.updateQueryData(
              'getMessages',
              getCacheParams(messageArgs),
              draft =>
                updateMessage(draft, messageSpecId, {
                  dismissed,
                })
            )
          );
        },
      });

      const dismissMessages = builder.mutation<
        DismissMessagesApiResponse,
        DismissMessagesApiRequestParams
      >({
        query: ({ messageSpecIds, dismissed }) => ({
          url: `${COMMUNICATION_BASE_URL}/dismiss`,
          method: HttpMethods.Post,
          data: {
            dismiss: dismissed,
            messageSpecIds,
          },
        }),
        async onQueryStarted({ messageSpecIds, messageArgs, dismissed }, { dispatch }) {
          dispatch(
            communicationApi.util.updateQueryData(
              'getMessages',
              getCacheParams(messageArgs),
              draft =>
                updateMessages(draft, messageSpecIds, {
                  dismissed,
                })
            )
          );
        },
      });

      return {
        getMessages,
        fetchMessages,
        readMessage,
        readMessages,
        dismissMessage,
        dismissMessages,
      };
    },
  });
