import React from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds, useTestIds } = genTestIds([
  'top-bar',
  'content',
  'content-main',
  'content-subtitle',
  'content-secondary',
  'content-secondary-item',
  'content-tool-tip',
]);

export type OfferingBannerSecondaryItem = {
  title: string;
  tooltip?: string;
  content: string;
};

export type OfferingBannerIcon = {
  src: string;
};

export type OfferingBannerProps = {
  topBar?: React.ReactNode;
  title?: string;
  subtitle?: string;
  icon?: OfferingBannerIcon;
  loading?: boolean;
  secondary?: OfferingBannerSecondaryItem[];
};
