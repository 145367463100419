import React, { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content/text/Paragraph';
import { media } from '../../themes/media';

interface EllipsisProps {
  bulletColor?: 'primary' | 'secondary';
}

interface BulletStripProps {
  bulletItems: string[];
  bulletColor?: 'primary' | 'secondary';
  inverse?: boolean;
  semiBold?: boolean;
}

export const BulletStrip: FunctionComponent<BulletStripProps> = ({
  bulletItems,
  bulletColor = 'primary',
  inverse = true,
  semiBold = true,
}) => {
  return (
    <ItemsContainer>
      {bulletItems.map((item, index) => {
        const isLastItem = index === bulletItems.length - 1;
        return (
          <Fragment key={index}>
            <Text inverse={inverse} semiBold={semiBold}>
              {item}
            </Text>
            {!isLastItem && <Ellipsis bulletColor={bulletColor} />}
          </Fragment>
        );
      })}
    </ItemsContainer>
  );
};

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  ${media.desktop`
    flex-direction: row;
  `}
`;

const Text = styled(Paragraph)`
  margin-bottom: 10px;
  ${media.desktop`
    margin-bottom: 0;
  `}
`;

const Ellipsis = styled.div`
  ${media.desktop<EllipsisProps>`
    margin: 0 20px;
    align-self: center;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${props =>
      props.bulletColor === 'primary'
        ? props.theme.colors.accent_medium
        : props.theme.colors.accent_light};
  `}
`;
