import { TaxDocumentId } from '@yieldstreet/platform-kit';

export const portfolioTaxInitialState = {
  addOrRemoveTaxDocumentId: (_id: TaxDocumentId) => {},
  downloadTaxDocuments: () => {},
  clearTaxDocumentIds: () => {},
  isDocumentSelected: (_id: TaxDocumentId) => false,
  setTaxDocumentIds: (_ids: TaxDocumentId[]) => {},
  taxDocumentIds: [] as TaxDocumentId[],
  hasDocumentSelected: false,
  isDownloadingTaxDocuments: false,
};

export interface PortfolioTaxResponse {
  addOrRemoveTaxDocumentId: (_id: TaxDocumentId) => void;
  downloadTaxDocuments: () => void;
  clearTaxDocumentIds: () => void;
  isDocumentSelected: (_id: TaxDocumentId) => boolean;
  setTaxDocumentIds: (_ids: TaxDocumentId[]) => void;
  taxDocumentIds: TaxDocumentId[];
  hasDocumentSelected: boolean;
  isDownloadingTaxDocuments: boolean;
}
