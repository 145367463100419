import React, { FunctionComponent } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';
import { media } from '../../themes/media';
import { OfferingCard } from '../../elements/offering-card';
import { Heading, HeadingType } from '../../content/text';
import 'swiper/css';
import 'swiper/css/pagination';

interface Alignment {
  desktopAlignment?: 'left' | 'center';
}

interface Props extends Alignment {
  title?: string;
  titleType?: HeadingType;
  className?: string;
  children: React.ReactChild[];
  ViewAllWrapper?: any;
  slidesPerView?: number;
  inverse?: boolean;
}

export const OfferingCardSection: FunctionComponent<Props> = ({
  title,
  titleType = 3,
  className,
  children,
  ViewAllWrapper,
  slidesPerView = 3,
  desktopAlignment = 'center',
  inverse,
}) => {
  return (
    <Wrapper className={className}>
      {title && (
        <Title type={titleType} desktopAlignment={desktopAlignment} inverse={inverse}>
          {title}
        </Title>
      )}
      <SSwiper
        spaceBetween={20}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: slidesPerView,
          },
        }}
        pagination={{
          clickable: true,
        }}
        centerInsufficientSlides
        modules={[Pagination]}
      >
        {React.Children.map(children, child => {
          return <SwiperSlide>{child}</SwiperSlide>;
        })}
        {ViewAllWrapper && (
          <SwiperSlide>
            <ViewAllWrapper>
              <OfferingCard viewAllCard />
            </ViewAllWrapper>
          </SwiperSlide>
        )}
      </SSwiper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 768px;

  ${media.large<Alignment>`
    max-width: 1159px;
  `}
`;

const Title = styled(Heading)<Alignment>`
  padding: 20px;
  width: 100%;
  text-align: center;
  ${media.desktop<Alignment>`
    text-align: ${props => props.desktopAlignment};
    padding: ${props => (props.desktopAlignment === 'center' ? '20px' : '20px 0')};
    margin: ${props => (props.desktopAlignment === 'center' ? '0 auto 40px' : '0 0 40px 0')};
  `}
`;

const SSwiper = styled(Swiper)`
  margin: -8px -8px 0 !important;
  padding: 8px 8px 0 !important;

  .swiper-wrapper {
    padding-bottom: 55px;
  }

  .swiper-slide {
    height: auto;
    padding-bottom: 12px;
  }

  .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.colors.active};
  }
`;
