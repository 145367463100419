import { genTestIds } from '@yieldstreet/tool-kit';

export const { getTestIds, useTestIds } = genTestIds(['container', 'label', 'description', 'icon']);

export enum ProgressBarV2Status {
  ACTIVE = 'ACTIVE',
  CLEARED = 'CLEARED',
  FAILED = 'FAILED',
  IDLE = 'IDLE',
}

export type StepItem = {
  label: string;
  description: string;
  status: ProgressBarV2Status;
};

export type StepList = StepItem[];

export type ProgressBarV2Props = {
  orientation: 'horizontal' | 'vertical';
  steps: StepList;
  testId?: string;
};
