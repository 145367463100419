import React from 'react';

import { Button } from '../../button';

import { ButtonLinkProps } from './ButtonLink.model';
import { Link } from './ButtonLink.style';

export const ButtonLink = ({ buttonProps, children, linkProps }: ButtonLinkProps) => {
  return (
    <Link {...linkProps}>
      <Button {...buttonProps}>{children}</Button>
    </Link>
  );
};
