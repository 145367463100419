import styled from 'styled-components';

import { media } from '../../themes/media';
import { Heading } from '../../content';

export const BannerContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.m};

  ${media.desktop`
    padding-left: 110px;
    padding-right: 110px;
  `};
`;

export const ContentHolder = styled.div`
  display: flex;
  background: ${({ theme }) => theme.gradients.radial_primary};
  text-align: center;
  padding: ${({ theme }) => `${theme.spacing['2xl']} ${theme.spacing.m}`};

  ${media.desktop`
    text-align: initial;
    padding: ${({ theme }) => theme.spacing['2xl']};
    column-gap: ${({ theme }) => theme.spacing['2xl']};
  `}
`;

export const SHeading = styled(Heading)`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.ml};
`;

export const ImageContainer = styled.div`
  display: none;

  ${media.large`
    display: initial;
  `}
`;
