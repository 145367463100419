import dayjs from 'dayjs';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';

export interface PortfolioCompositionProps {
  investmentsQuery?: InvestmentsQueryConfig;
  offeringsQuery?: OfferingsQueryConfig;
  currentInvestorQuery?: CurrentInvestorConfig;
  userId?: number;
  managementStrategy?: PortfolioStrategyValues;
}

export interface InvestmentsQueryConfig {
  percentageDecimalPoints?: number;
  asOfDate?: string;
}

export const investmentsDefaultQuery = {
  percentageDecimalPoints: 1,
  asOfDate: dayjs().format('YYYY-MM-DD'),
};

export interface OfferingsQueryConfig extends InvestmentsQueryConfig {
  showFirst?: number;
}

export const offeringDefaultQuery = {
  ...investmentsDefaultQuery,
  showFirst: 200,
};

export interface CurrentInvestorConfig {
  investorAccountId?: string;
}

export const currentInvestorDefaultQuery = {};
