import styled from 'styled-components';

import { Heading } from '../../../content';

export const PageSheetTitleHeading = styled(Heading)`
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
