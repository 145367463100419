import styled from 'styled-components';
import { media } from '../../themes';

export const BeanBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.m};

  margin: ${props => props.theme.spacing.l} auto;

  ${media.desktop`
    flex-direction: row;
    gap: ${props => props.theme.spacing.l};
    justify-content: center;
  `}
`;
