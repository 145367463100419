// import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import {
  BulkOfferingPromotionEligibilityRequest,
  BulkOfferingPromotionEligibilityResult,
  BulkOfferingPromotionEligibilityResponse,
} from './offering-promotion.model';
import { HttpMethods } from '../../enums';

export const offeringPromotionApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.OfferingPromo],
  })
  .injectEndpoints({
    endpoints: builder => {
      const promotionEligibility = builder.query<
        BulkOfferingPromotionEligibilityResult,
        BulkOfferingPromotionEligibilityRequest
      >({
        query: data => ({
          url: `/api/offering/offering-promotions/eligibility`,
          method: HttpMethods.Post,
          data,
        }),
        transformResponse: (result: BulkOfferingPromotionEligibilityResponse) => result.response,
      });

      return {
        promotionEligibility,
      };
    },
  });
