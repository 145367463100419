export enum AssetClassType {
  PrivateBusinessCredit = 'PVBC',
  MultiAssetClassFund = 'FUND',
  Transportation = 'TRPT',
  ShortTermNotes = 'SHTN',
  Other = 'OTHR',
  PrivateEquity = 'PREQ',
  Marine = 'MRIN',
  VentureCapital = 'VCAP',
  PrivateCredit = 'PRCR',
  Commercial = 'COML',
  Corporates = 'CORP',
  Aviation = 'AVAT',
  RealEstate = 'REAL',
  Crypto = 'CRYP',
  Art = 'ARTF',
  Legal = 'LEGL',
  Consumer = 'COND',
  Cash = 'CASH',
  RealAssets = 'REAS',
}
