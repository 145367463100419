import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Heading, HeadingType, Paragraph } from '../../../content/text';

interface BulletTitleContentProps {
  title?: string;
  headingType?: HeadingType;
  tagType?: HeadingType;
  inverse?: boolean;
}

export const BulletTitleContent: FunctionComponent<BulletTitleContentProps> = ({
  title,
  headingType = 5,
  tagType = 5,
  children,
  inverse,
}) => {
  return (
    <OuterContainer>
      <ContentHolder>
        <Title type={headingType} tagType={tagType} inverse={inverse}>
          {title}
        </Title>
        <Paragraph secondary inverse={inverse}>
          {children}
        </Paragraph>
      </ContentHolder>
    </OuterContainer>
  );
};

const OuterContainer = styled.ul`
  display: flex;
  margin-bottom: 30px;
`;

const ContentHolder = styled.li``;

const Title = styled(Heading)`
  margin-bottom: 10px;
`;
