import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Heading, Label } from '../../../content/text';
import { ButtonContainer } from '../../../content/button/ButtonContainer';
import { media } from '../../../themes/media';

interface DealCardProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  image?: React.ReactNode;
  className?: string;
  cta?: React.ReactNode;
}

export const DealCard: FunctionComponent<DealCardProps> = props => {
  const { title, subtitle, image, children, cta } = props;
  return (
    <OuterContainer>
      <TopContainer>
        <ImageHolder>{image && <ImageWrapper>{image}</ImageWrapper>}</ImageHolder>
        <ContentHolder>
          <TitleHolder>
            {subtitle ? <SLabel>{subtitle}</SLabel> : ''}
            {title && <Headline type={5}>{title}</Headline>}
          </TitleHolder>
        </ContentHolder>
      </TopContainer>
      <ContentHolder>
        <TextHolder>
          {children}
          {cta && <CTAHolder>{cta}</CTAHolder>}
        </TextHolder>
      </ContentHolder>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background: ${props => props.theme.colors.background_card};
  box-shadow: ${props => props.theme.shadows.shadow_default};
  padding: 20px;
  flex: none;
  flex: 1 0;
  text-align: left;
  margin: 0 0 20px 0;
  ${media.desktop`
    margin: 0 0 0 20px;
  `}
`;

const TopContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex: 0 0;
`;

const TitleHolder = styled.div``;

const Headline = styled(Heading)`
  padding: 0;
  margin-bottom: 10px;
`;

const ImageHolder = styled.div`
  img {
    width: 100%;
  }
  margin-right: 20px;
  overflow: hidden;
  ${media.desktop`
    width: 30%;
  `}
`;

const ContentHolder = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const CTAHolder = styled(ButtonContainer)``;

const ImageWrapper = styled.div``;

const SLabel = styled(Label)`
  margin-bottom: 10px;
  display: inline-block;
  padding: 5px;
  background: ${props => props.theme.colors.background_page};
`;

const TextHolder = styled.div`
  margin-top: 20px;
  dl {
    display: flex;
    flex-wrap: wrap;
    dt {
      flex: 1 0 50%;
      margin-bottom: 5px;
    }
    dd {
      flex: 1 1;
      text-align: right;
      margin-bottom: 5px;
    }
  }
`;
