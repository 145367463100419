import {
  offeringTagSection,
  metricCardSection,
  metricCardSection2,
  noteBannerSection1,
  noteBannerSection2,
  paragraphBoxSection,
  paragraphBoxSection2,
  paragraphBoxSection3,
  paragraphBoxSection4,
  mediaCardsSection,
  wordPressCardsSection,
  disclaimerSection,
} from '../offering-v5-sections';

export const tagsContainer = {
  key: 'SK6j-V4L9',
  title: 'Page Offering Description',
  subtitle: 'This is the subtitle',
  hideTitle: false,
  backgroundColor: '#F7F8F9',
  headingType: 4,
  navigation: {
    slug: 'details',
    title: 'Details',
    icon: 'details',
    directNavigation: true,
  },
  sections: [offeringTagSection, metricCardSection],
};

export const overviewContainer = {
  key: '2Xn43a7Ppp',
  title: 'Overview',
  subtitle: '',
  hideTitle: false,
  navigation: {
    slug: 'overview',
    title: 'Overview',
    icon: 'details',
    directNavigation: true,
  },
  sections: [noteBannerSection1, paragraphBoxSection],
};

export const highlightsContainer = {
  key: 'crIPPtlbJS',
  title: 'Highlights',
  subtitle: '',
  hideTitle: true,
  navigation: {
    slug: 'highlights',
    title: 'Highlights',
    icon: 'promise',
    directNavigation: true,
  },
  sections: [noteBannerSection2],
};

export const essentialsContainer = {
  key: 'gNn2hZqsiB',
  title: 'Essentials',
  subtitle:
    'Please refer the Series Note Supplement in the Resources section for more details about this offering.',
  hideTitle: false,
  navigation: {
    slug: 'essentials',
    title: 'Essentials',
    icon: 'essentials',
    directNavigation: true,
  },
  sections: [paragraphBoxSection2, paragraphBoxSection3, paragraphBoxSection4, metricCardSection2],
};

export const docsContainer = {
  key: 'mX1izs2ZjW',
  title: 'Docs',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  hideTitle: false,
  navigation: {
    slug: 'docs',
    title: 'Docs',
    icon: 'docs',
    directNavigation: true,
  },
  sections: [mediaCardsSection],
};

export const contentContainer = {
  key: 'HwP7445Z_N',
  title: 'Content (test)',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  hideTitle: false,
  navigation: {
    slug: 'content',
    title: 'Content',
    icon: 'content',
    directNavigation: true,
  },
  sections: [wordPressCardsSection, disclaimerSection],
};

export const offeringV5Containers = [
  tagsContainer,
  overviewContainer,
  highlightsContainer,
  essentialsContainer,
  docsContainer,
  contentContainer,
];
