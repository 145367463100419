export enum DocumentType {
  incorporation = 'AOFI',
  borrow = 'BPDN',
  closingBinder = 'CLNG',
  exemplarNote = 'NOTE',
  ein = 'EIND',
  indenture = 'IFON',
  offeringHero = 'OFHR',
  offeringThumb = 'OFTB',
  operating = 'OPER',
  portfolioStatus = 'PRST',
  privatePlacement = 'PRPM',
  prospectus = 'PRSP',
  subscription = 'SUBS',
  subsAppendix = 'SUBA',
  supporting = 'SUPP',
  subscriptionAgreement = 'ISUB',
  borrowerPaymentDependentNote = 'IBPD',
  contersignedSubscriptionAgreement = 'ICSA',
  other = 'OTHR',
}
