import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
import {
  CreateCommitmentRequest,
  GetCommitmentRequest,
  GetCommitmentResponse,
  CommitmentResponse,
  InvestmentsRequestAPIResponse,
  UpdateCommitmentRequest,
} from './investment-request.model';

const BASE_URL = '/api/investment-request';

export const investmentRequestApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.InvestmentRequest],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getInvestmentRequests = builder.query<InvestmentsRequestAPIResponse, void>({
        query: () => ({
          url: `${BASE_URL}/investments`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.InvestmentRequest],
      });

      const createCommitment = builder.mutation<CommitmentResponse, CreateCommitmentRequest>({
        query: ({ noteUrlHash, committedAmount }) => {
          return {
            url: `${BASE_URL}/commitments`,
            method: HttpMethods.Post,
            data: {
              noteUrlHash,
              committedAmount,
            },
          };
        },
        invalidatesTags: [Tags.InvestmentRequest],
      });

      const getCommitment = builder.query<GetCommitmentResponse, GetCommitmentRequest>({
        query: ({ commitmentId }) => {
          return {
            url: `${BASE_URL}/commitments/${commitmentId}`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.InvestmentRequest],
      });

      const signCommitment = builder.mutation<CommitmentResponse, UpdateCommitmentRequest>({
        query: ({ commitmentId, ...data }) => {
          return {
            url: `${BASE_URL}/commitments/${commitmentId}`,
            method: HttpMethods.Put,
            data,
          };
        },
        invalidatesTags: [Tags.InvestmentRequest],
      });

      return {
        getInvestmentRequests,
        createCommitment,
        getCommitment,
        signCommitment,
      };
    },
  });
