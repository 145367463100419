import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { media } from '../../themes/media';
import Background from '../../assets/graphics/backgrounds/info-hero-background.svg';
import { Heading, Paragraph } from '../../content/text';
import { PageSection } from '../../layout/page-section';
import { ButtonContainer } from '../../content/button';

interface Image {
  imageBorder?: boolean;
}

interface InfoHeroBannerProps extends Image {
  title?: string;
  boldTitle?: string;
  subtitle?: string;
  bottomTitle?: string;
  bottomContentLeft?: React.ReactNode;
  bottomContentRight?: React.ReactNode;
  inverse?: boolean;
  mainImage?: React.ReactNode;
  splashImage?: React.ReactNode;
  cta?: React.ReactNode;
  removeFooterSpacing?: boolean;
  className?: string;
}

export const InfoHeroBanner: FunctionComponent<InfoHeroBannerProps> = props => {
  const {
    title,
    boldTitle,
    subtitle,
    bottomTitle,
    inverse,
    bottomContentLeft,
    bottomContentRight,
    mainImage,
    splashImage,
    imageBorder,
    cta,
    className,
  } = props;

  return (
    <BannerHolder className={className}>
      <PageSection>
        <Container>
          <TextContainer className="text-container">
            <Headline type={1} inverse={inverse}>
              {title} {boldTitle && <strong>{boldTitle}</strong>}
            </Headline>
            <Content>
              {subtitle}
              <ButtonContainer>{cta}</ButtonContainer>
            </Content>
          </TextContainer>
          <RightContainer>
            <ImageContainer>
              <MainImage imageBorder={imageBorder}>
                <PlusBackground />
                {mainImage}
              </MainImage>
            </ImageContainer>
          </RightContainer>
        </Container>
      </PageSection>
      <BottomContent className="bottom-content-container">
        <PageSection>
          <BottomContainer>
            <SplashImage>{splashImage}</SplashImage>
            <SubHeadline type={4} inverse>
              {bottomTitle}
            </SubHeadline>
            <BottomTextContainer>
              <BottomText>{bottomContentLeft}</BottomText>
              <BottomText>{bottomContentRight}</BottomText>
            </BottomTextContainer>
          </BottomContainer>
        </PageSection>
      </BottomContent>
    </BannerHolder>
  );
};

const BannerHolder = styled.div`
  position: relative;
`;

const PlusBackground = styled.div`
  position: absolute;
  width: 200px;
  height: 100%;
  left: -150px;
  z-index: 10;
  display: none;
  ${media.desktop`
    display: block;
    background: url('${Background}') no-repeat 50% 73%;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 468px;
  position: relative;
  ${media.desktop`
    min-height: 200px;
  `}
  padding: 0 0 40px 0;
`;

const TextContainer = styled.div`
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  ${media.desktop`
    position: unset;
    transform: unset;
    top: auto;
    width: 60%;
    padding: 0 40px 0 110px;
  `}
`;

const Headline = styled(Heading)`
  margin: 20px 0 20px 20px;
`;

const Content = styled(Paragraph)`
  margin: 0 20px 20px 20px;
`;

const RightContainer = styled.div`
  text-align: center;
  width: 100%;
  ${media.desktop`
    margin: 20px 0 0 0;
    width: 40%; 
    padding: 0 60px;
  `}
`;

const ImageContainer = styled.div`
  ${media.desktop`
    position: relative;
  `}
  margin: 0 20px;
  ${media.desktop`
    margin: 0;
  `}
`;

const MainImage = styled.div<Image>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  ${media.desktop`
    right: 0;
    z-index: 1;
    height: auto;
  `}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${media.desktop<Image>`
      maxWidth: 467
      width: auto;
      ${props =>
        props.imageBorder ? `border-bottom: 10px solid ${props.theme.colors.accent_strong};` : ''};
    `}
  }
`;

const BottomContent = styled.div`
  background: ${props => props.theme.colors.background_inverse};
  min-height: 338px;
  padding: 0;
  ${media.desktop`
    padding: 80px 0 66px 0;
  `}
`;

const SplashImage = styled.div`
  width: 114px;
  height: 114px;
  img {
    width: 114px;
    height: 114px;
    padding: 20px;
    vertical-align: middle;
    text-align: center;
  }
  position: absolute;
  background: ${props => props.theme.colors.background_inverse};
  right: 20px;
  top: -57px;
  ${media.desktop`
    top: -124px;
    right: auto;
  `}
`;

const BottomContainer = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  ${media.desktop`
    padding: 0 40px 0 130px;
    width: calc(100% - 540px);
  `}
`;

const SubHeadline = styled(Heading)`
  margin: 20px 0;
`;

const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
  `}
`;

const BottomText = styled.div`
  width: 100%;
  p {
    padding-bottom: 10px;
  }
  ${media.desktop`
    width: 50%;
    &:not(:last-child) {
      padding-right: 50px;
    }
  `}
`;
