import { HttpMethods } from '../../../enums/http';
import { restApi, Tags } from '../../common';
import { PortfolioTiersRequest } from './tiers.model';

export const tiersApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioTiers, Tags.PortfolioTiersOptIn],
  })
  .injectEndpoints({
    endpoints: builder => {
      const tierOptIn = builder.mutation<{}, PortfolioTiersRequest>({
        query: ({ userId, tierLevel }) => ({
          url: `/api/portfolio/${userId}/tiers/${tierLevel}/optIn`,
          method: HttpMethods.Post,
        }),
        invalidatesTags: [Tags.PortfolioTiers, Tags.PortfolioTiersOptIn],
      });

      return {
        tierOptIn,
      };
    },
  });
