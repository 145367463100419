import {
  configureRTKMockStore,
  getMockEndpoint,
  getRTKMockStoreExtraMiddlewares,
  MAX_ROLLOVER_AMOUNT_MOCK,
  offeringApi,
  offeringV5,
  portfolioGraphQLApi,
} from '@yieldstreet/platform-kit';

export const MockProps = {
  urlHash: 'test',
  investorId: '123',
};

const MOCK_OFFERING = {
  ...offeringV5,
  urlHash: MockProps.urlHash,
};

export const getMockEndpoints = () => [
  getMockEndpoint(portfolioGraphQLApi, 'getRolloverAmount', {
    ...MAX_ROLLOVER_AMOUNT_MOCK,
  }),
  getMockEndpoint(offeringApi, 'details', {
    [MOCK_OFFERING.urlHash]: {
      note: MOCK_OFFERING,
    },
  }),
];

export const getMockStore = () =>
  configureRTKMockStore(
    getMockEndpoints(),
    {
      user: () => ({
        userState: {
          loggedIn: true,
        },
      }),
      investorAccounts: () => ({}),
    },
    getRTKMockStoreExtraMiddlewares(),
    []
  );
