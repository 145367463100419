import styled from 'styled-components';
import { media, MarketingContainer } from '@yieldstreet/ui-kit';

export const LeadershipSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.l};

  ${media.desktop`
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing['3xl']};
  `}
`;

export const PhotoCardsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  width: 100%;
  white-space: pre-line;

  ${media.desktop`
    width: 50%;
  `}
`;

export const CopyWraper = styled.div`
  max-width: 380px;
`;

export const ModalBackground = styled(MarketingContainer)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.l};
  background-color: ${({ theme }) => theme.colors.background_page};
  box-shadow: ${({ theme }) => theme.shadows.elevation_1};
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: 8px;
  max-width: 1000px;
  min-height: 100vh;
  white-space: pre-line;

  ${media.desktop`
    min-height: unset;
    flex-direction: row;
  `}
`;

export const ModalLeadWrapper = styled.div`
  text-align: center;
  & > img {
    width: 178px;
    border-radius: 4px;
  }
`;
