import { orderBy } from 'lodash';

import { HttpMethods } from '../../../enums';
import {
  PortfolioInvestmentsByAssetClassApiResponse,
  PortfolioInvestmentsByAssetClass,
  PortfolioInvestmentsByAssetClassRequest,
  PortfolioInvestmentsByOffering,
  PortfolioInvestmentsByOfferingRequest,
  PortfolioInvestmentsByStatus,
  PortfolioInvestmentsByStatusRequest,
  PortfolioInvestmentStrategyRequest,
  PortfolioInvestmentStrategyResponse,
} from '../../../types';
import { Tags, RTKQAll, restApi } from '../../common';
import {
  FundReinvestmentRequest,
  FundReinvestmentResponse,
  PortfolioInvestmentsYearsApiResponse,
  PortfolioInvestmentsYearsRequest,
  PortfolioInvestmentsYearsResponse,
} from './investments.model';

export const investmentsApi = restApi
  .enhanceEndpoints({
    addTagTypes: [
      Tags.PortfolioInvestments,
      Tags.PortfolioInvestmentsActivityYears,
      Tags.PortfolioInvestmentsByAssetClass,
      Tags.PortfolioInvestmentsByOffering,
      Tags.PortfolioInvestmentsByStatus,
      Tags.PortfolioInvestmentStrategy,
    ],
  })
  .injectEndpoints({
    endpoints: build => {
      const getInvestmentsActivityYears = build.query<
        PortfolioInvestmentsYearsResponse,
        PortfolioInvestmentsYearsRequest
      >({
        query: ({ investorId, userId, managementStrategy }: PortfolioInvestmentsYearsRequest) => {
          return {
            url: `/api/portfolio/${userId}/investments/activity-years`,
            method: HttpMethods.Get,
            params: {
              investorAccountId: investorId,
              managementStrategy,
            },
          };
        },
        providesTags: [Tags.PortfolioInvestmentsActivityYears],
        transformResponse: ({ availableYears }: PortfolioInvestmentsYearsApiResponse) => ({
          years: availableYears,
        }),
      });

      const getInvestmentsByAssetClass = build.query<
        PortfolioInvestmentsByAssetClass,
        PortfolioInvestmentsByAssetClassRequest | RTKQAll
      >({
        query: ({
          asOfDate,
          investorAccountId,
          percentageDecimalPoints,
          showFirst,
          status,
          userId,
          managementStrategy,
        }: PortfolioInvestmentsByAssetClassRequest) => {
          return {
            url: `/api/portfolio/${userId}/investments/by-asset-class`,
            method: HttpMethods.Get,
            params: {
              asOfDate,
              investorAccountId,
              percentageDecimalPoints,
              showFirst,
              status,
              managementStrategy,
            },
          };
        },
        providesTags: [Tags.PortfolioInvestmentsByAssetClass],
        transformResponse: (response: PortfolioInvestmentsByAssetClassApiResponse) => {
          // TODO: Remove codeblock once sorting/ordering is provided by backend - https://app.shortcut.com/yieldstreet/story/102060
          const currentValueBreakdown = orderBy(
            response?.currentValueBreakdown,
            item => item?.percentage,
            ['desc']
          );

          return {
            ...response,
            currentValueBreakdown,
          };
        },
      });

      const getInvestmentsByOffering = build.query<
        PortfolioInvestmentsByOffering,
        PortfolioInvestmentsByOfferingRequest | RTKQAll
      >({
        query: ({ userId, ...params }: PortfolioInvestmentsByOfferingRequest) => {
          return {
            url: `/api/portfolio/${userId}/investments/by-offering`,
            method: HttpMethods.Get,
            params,
          };
        },
        providesTags: [Tags.PortfolioInvestmentsByOffering],
      });

      const getInvestmentsByStatus = build.query<
        PortfolioInvestmentsByStatus,
        PortfolioInvestmentsByStatusRequest | RTKQAll
      >({
        query: ({ userId, ...params }: PortfolioInvestmentsByStatusRequest) => ({
          url: `/api/portfolio/${userId}/investments/by-status`,
          method: HttpMethods.Get,
          params,
        }),
        providesTags: [Tags.PortfolioInvestmentsByStatus],
      });

      const updateFundReinvestmentStatus = build.mutation<
        FundReinvestmentResponse,
        FundReinvestmentRequest
      >({
        query: ({ fundId, investorAccountId, reinvestmentChoice }: FundReinvestmentRequest) => ({
          url: `/api/fund/funds/${fundId}/accounts/${investorAccountId}`,
          method: HttpMethods.Patch,
          data: reinvestmentChoice,
        }),
      });

      const getInvestmentStrategy = build.query<
        PortfolioInvestmentStrategyResponse,
        PortfolioInvestmentStrategyRequest | RTKQAll
      >({
        query: ({ investorAccountId, userId }: PortfolioInvestmentStrategyRequest) => {
          return {
            url: `/api/portfolio/${userId}/investment-strategy/${investorAccountId}`,
            method: HttpMethods.Get,
          };
        },
        providesTags: [Tags.PortfolioInvestmentStrategy],
      });

      return {
        getInvestmentsActivityYears,
        getInvestmentsByAssetClass,
        getInvestmentsByOffering,
        getInvestmentsByStatus,
        updateFundReinvestmentStatus,
        getInvestmentStrategy,
      };
    },
  });
