import { PortfolioStrategyValues, EntityInvestorAccount } from '@yieldstreet/platform-kit';
import { ALL } from '../../investment-details';

interface ManagementStrategyProps {
  managementStrategy: PortfolioStrategyValues;
}

export interface SelectInvestorAccountsProps extends ManagementStrategyProps {
  investorId?: string;
}

export interface FilterInvestorAccountsProps extends ManagementStrategyProps {
  investorAccounts: EntityInvestorAccount[];
}

export const ALL_ACCOUNTS = {
  id: ALL,
  name: 'All accounts',
  investorEntityId: null,
};
