import React, { ReactNode, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import { Button, Heading, Paragraph } from '../../content';
import { FormTextInput } from '../../form';
import { Spinner } from '../../layout/spinner';
import { media } from '../../themes';
import { ModalTrigger, ModalTriggerProps } from '../../utils/modal-trigger/ModalTrigger';

import EmailCaptureSuccess from '../../assets/graphics/case-specific/email-capture-success.png';

type EmailCaptureRewardProps = {
  buttonText: string;
  onSubmit?: ({ email }: { email: string }) => void;
  rewardFile: string;
  rewardImg?: ReactNode;
  subtitle?: string | ReactNode;
  title: string | ReactNode;
};

type FormikEmail = {
  email: string;
};

type EmailComponentProps = EmailCaptureRewardProps & ModalTriggerProps;

export const EmailCaptureReward = ({
  buttonText,
  className,
  modalTimeoutInSeconds,
  offsetSize = 0,
  onSubmit,
  rewardFile,
  rewardImg,
  storageId,
  subtitle,
  timeout = 0,
  title,
  triggerType,
}: EmailComponentProps) => {
  const [showModal, setShowModal] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: FormikEmail) => {
    setIsSubmitting(true);
    onSubmit && (await onSubmit({ email: values.email }));
    setIsSubmitting(false);
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      window.setTimeout(() => {
        const a = document.createElement('a');
        a.href = rewardFile;
        a.target = '_blank';
        a.download = '';
        document.body.appendChild(a);
        a.click();
        a.remove();
        setShowModal(false);
      }, 2000);
    }
  }, [rewardFile, submitted]);

  if (!showModal) {
    return null;
  }

  return (
    <SModalTrigger
      className={className}
      modalTimeoutInSeconds={modalTimeoutInSeconds}
      offsetSize={offsetSize}
      storageId={storageId}
      timeout={timeout}
      triggerType={triggerType}
      width="1040px"
    >
      {submitted ? (
        <WrapperSuccess>
          <ImgWrapper>
            <img src={EmailCaptureSuccess} className="img-success" />
          </ImgWrapper>
          <Heading type={3} tagType={4} marginTop>
            Thank you! Your download is starting.
          </Heading>
          <Paragraph marginTop marginBottom>
            This free guide includes important definitions of
            <br /> alternative investments, steps to vet investment
            <br /> opportunities, and some real life examples.
          </Paragraph>
          <CloseButton
            type="button"
            reducedPadding
            buttonType="primary"
            onClick={() => setShowModal(false)}
          >
            Close
          </CloseButton>
        </WrapperSuccess>
      ) : (
        <Wrapper>
          <ImgWrapper>{rewardImg}</ImgWrapper>
          <WrapperForm>
            <Heading type={3} tagType={4}>
              {title}
            </Heading>
            <Subtitle>{subtitle}</Subtitle>

            <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
              {formikProps => (
                <SForm>
                  <FormTextInput
                    name="email"
                    type="email"
                    required
                    formikProps={formikProps}
                    label="Email address"
                    placeholder="Email address"
                    fullWidth
                  />
                  <SubmitButton type="submit" reducedPadding buttonType="primary">
                    {isSubmitting ? <Spinner small /> : buttonText}
                  </SubmitButton>
                </SForm>
              )}
            </Formik>
            <Cancel onClick={() => setShowModal(false)}>
              <CloseText secondary marginTop>
                No thanks, my portfolio is fine as is.
              </CloseText>
            </Cancel>
          </WrapperForm>
        </Wrapper>
      )}
    </SModalTrigger>
  );
};

const SModalTrigger = styled(ModalTrigger)``;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  ${media.desktop`
    justify-content: space-between;
    flex-direction: row-reverse;
    max-width: none;
  `}
`;

const WrapperForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${media.desktop`
    min-width: 430px;
  `}
`;

const Cancel = styled(Button)`
  &,
  &:hover {
    background: none;
    border: none;
    display: flex;
    padding: 0;
    text-align: left;
  }
`;

const CloseText = styled(Paragraph)`
  cursor: pointer;
  text-decoration: underline;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  ${media.desktop`
    margin-left: 15px;
    min-width: 153px;
    padding: 14px;
    width: unset;
  `}
`;

const Subtitle = styled(Paragraph)`
  margin: 25px 0 40px;
`;

const ImgWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
  ${media.desktop`
    margin-bottom: 0;
  `}
  img {
    max-width: 100%;
    ${media.desktop`
      max-height: 440px;
    `}
  }
  .img-success {
    animation: rotation 2s infinite linear;
    height: 170px;
    ${media.desktop`
      height: 114px;
    `}
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;

const WrapperSuccess = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
  ${media.desktop`
    text-align: center;
  `}
`;

const CloseButton = styled(Button)`
  margin-top: auto;
  width: 100%;
  ${media.desktop`
    width: auto;
  `}
`;

const SForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  ${media.desktop`
    flex-direction: row;
    height: 60px;
    justify-content: center;
  `}
`;
