import { alpha, styled } from '@mui/material/styles';

import { MuiTheme } from '../../../../../theme';

const getBackgroundColor = (theme: MuiTheme) => alpha(theme.palette.background.dark, 0.7);

export const Wrapper = styled('div')<{ hideOnMobile?: boolean }>(({ hideOnMobile, theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  background: hideOnMobile ? 'none' : getBackgroundColor(theme),

  [theme.breakpoints.up('sm')]: {
    background: getBackgroundColor(theme),
  },
}));
