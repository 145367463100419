import styled from 'styled-components';

import { Heading, Paragraph } from '../../../../content/text';
import { media } from '../../../../themes';

export const OuterContainer = styled.div`
  background: ${({ theme }) => theme.colors.background_default};
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  min-height: 392px;
  margin: 20px 0 0 0;
  padding: 0 20px;
  flex-grow: 1;
  flex-basis: 0;

  ${media.desktop`
    margin: 0;
    min-height: 466px;
    max-width: 221px;

    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
  `}
`;

export const Headline = styled(Heading)`
  padding: 0 0 20px 0;
  margin: 30px 0 20px 0;
  min-height: 68px;
  box-sizing: content-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border_default};
`;

export const Title = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text_label};
`;

export const Number = styled(Paragraph)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ImageHolder = styled.div`
  margin-top: auto;
  margin-left: auto;
`;

export const Image = styled.img`
  width: 80px;
`;
