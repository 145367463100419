import React, { FC } from 'react';
import styled from 'styled-components';
import { Spinner } from '../spinner';
import { BaseProps } from '../../types';
import { useTestIds } from './PageLoader.model';

type PageLoaderProps = LoaderContainerProps & BaseProps;

export const PageLoader: FC<PageLoaderProps> = ({ center, ...props }) => {
  const testIds = useTestIds(props);
  return (
    <LoaderContainer center={center} data-js-empty-loader role="progressbar" {...testIds.base.attr}>
      <Spinner />
    </LoaderContainer>
  );
};

type LoaderContainerProps = {
  center?: boolean;
};

export const LoaderContainer = styled.div<LoaderContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;

  ${props =>
    props.center
      ? `
      align-items: center;
      display: flex;
      justify-content: center;
    `
      : `
      padding-top: 25vh;
      text-align: center;
    `}

  opacity: 0;
  animation: fade-in ease 0.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
