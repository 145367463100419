import { HttpMethods } from '../../../enums/http';
import { Tags, restApi } from '../../common';
import { PromotionIRAAPIResponse, PromotionIRAAPIRequest } from './promotion.model';

export const BASE_URL = '/a/api/portfolio/promotion';

export const promotionApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioPromotion],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getPromotionIRA = builder.query<PromotionIRAAPIResponse, PromotionIRAAPIRequest>({
        query: ({ userId }) => ({
          url: `${BASE_URL}/ira/${userId}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.PortfolioPromotion],
      });

      return {
        getPromotionIRA,
      };
    },
  });
