import { ApiStateResult, ApiError } from '@yieldstreet/platform-kit';

import { BusinessEvent, eventManager, EventProps } from '../events';
import { useEffect } from 'react';
import { usePrevious } from '../utils';
import { getApiErrorMessage } from '../errors';

export const useApiEventTracking = <Event extends BusinessEvent>(
  apiState: ApiStateResult<unknown, unknown>,
  event: Event,
  properties: EventProps<Event> | Partial<EventProps<Event>>,
  errorMessage?: string
) => {
  const { data, error } = apiState;

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (data && data !== prevData) {
      eventManager.track(event, properties as EventProps<Event>);
    }
  }, [data, prevData, event, properties]);

  useEffect(() => {
    if (error && error !== prevError) {
      eventManager.trackFailure(event, {
        error: errorMessage || getApiErrorMessage(error as ApiError),
        ...properties,
      });
    }
  }, [error, prevError, event, properties, errorMessage]);
};
