export interface MarketplaceSliceState {
  filters: any;
  authToken: string;
  marketplaceView: MarketplaceView;
}

export type MarketplaceView = 'list' | 'grid';

export interface AppPersistSliceState {
  marketplace: Partial<MarketplaceSliceState>;
}

export const APP_PERSIST_SLICE_INITIAL_STATE: AppPersistSliceState = {
  marketplace: {
    marketplaceView: 'grid',
  },
};
