import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Label } from './Label';
import { TextBaseStyleProps } from './TextBase';

export type TitleTagProps = TextBaseStyleProps & {
  small?: boolean;
  htmlFor?: string;
  inverse?: boolean;
};

export const TitleTag: FunctionComponent<TitleTagProps> = ({
  children,
  small,
  htmlFor,
  inverse,
  ...rest
}) => {
  return (
    <SLabel {...rest} small={small} htmlFor={htmlFor} inverse={inverse}>
      {children}
    </SLabel>
  );
};

const SLabel = styled(Label)`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 4px;
`;
