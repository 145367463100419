import { styled } from '@mui/system';
import { Snackbar } from '@mui/material';
import { SnackbarTypes } from '@yieldstreet/tool-kit';

import { MuiTheme } from '../../../theme';
import { SnackbarStylesProps, SnackbarColorPickerProps } from './Snackbar.model';

const snackbarColorPicker = ({ theme, type }: SnackbarColorPickerProps) => {
  switch (type) {
    case SnackbarTypes.Success:
      return theme.palette.success.main;
    case SnackbarTypes.Error:
      return theme.palette.error.main;
    case SnackbarTypes.Warning:
      return theme.palette.warning.main;
    case SnackbarTypes.Info:
    default:
      return theme.palette.info.main;
  }
};

export const SnackbarItem = styled(Snackbar)<SnackbarStylesProps>(({ theme, type }) => ({
  position: 'relative',
  animation: 'fadeIn ease 0.5s',
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },

  '&:not(:first-child)': {
    marginTop: theme.spacing(2),
  },

  '.MuiSnackbar-root': {
    position: 'relative',
  },

  '.MuiSnackbarContent-message': {
    padding: 0,
  },

  '.MuiPaper-elevation6': {
    boxShadow: 'none',
  },

  '.MuiSnackbarContent-root': {
    width: '100%',
    padding: theme.spacing(5),
    background: snackbarColorPicker({ theme: theme as MuiTheme, type }),
    borderRadius: 0,

    [theme.breakpoints.up('sm')]: {
      width: '375px',
    },
  },
}));

export const SnackbarItemWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: theme.spacing(5),
  zIndex: 100,

  [theme.breakpoints.up('sm')]: {
    top: '115px',
    bottom: 'unset',
    left: 'unset',
    right: '34px !important',
  },
}));
