import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import LegacyStyled from 'styled-components';

import { media } from '../../themes';
import { ExternalLink } from '../../content';
import { ContainerProps } from './TopNotification.model';

// The places using TopNotification can pass a background prop. This can be
// passed from soy using background colors from the old theme. Ideally we
// have a dedicated ticket for this change.
export const Container = LegacyStyled.div<ContainerProps>`
  background: ${({ backgroundColor, theme }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.background_default};
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;

  ${media.tablet`
    flex-direction: row;
    justify-content: center;
  `}
`;

export const TextWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    marginRight: theme.spacing(12),
  },
}));

export const Icon = styled('img')(({ theme }) => ({
  height: '23px',
  marginRight: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
}));

export const Description = styled(Typography)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,

  [theme.breakpoints.up('sm')]: {
    margin: `0 ${theme.spacing(1)}`,
  },
}));

export const Close = styled('img')(({ theme }) => ({
  height: '16px',
  cursor: 'pointer',

  [theme.breakpoints.up('sm')]: {
    alignSelf: 'center',
  },
}));

export const SExternalLink = styled(ExternalLink)(() => ({
  width: 'fit-content',
}));
