import { HttpMethods } from '../../enums/http';
import { restApi, BASE_REST_CONFIG, Tags } from '../common';
import {
  UsersRecurringPaymentsRequest,
  UsersRecurringPaymentsApiResponse,
  UsersRecurringPaymentsQueryResponse,
} from './users.model';

export const usersApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UsersRecurringPayments],
  })
  .injectEndpoints({
    ...BASE_REST_CONFIG,
    endpoints: builder => {
      const getUsersRecurringPayments = builder.query<
        UsersRecurringPaymentsQueryResponse,
        UsersRecurringPaymentsRequest
      >({
        query: ({ userId }) => ({
          url: `/a/api/users/${userId}/recurring-payments`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.UsersRecurringPayments],
        transformResponse: (response: UsersRecurringPaymentsApiResponse) =>
          response.recurringPayments,
      });

      return { getUsersRecurringPayments };
    },
  });
