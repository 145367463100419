import { useMemo } from 'react';
import {
  FundType,
  LegalEntityType,
  offeringApi,
  OfferingDocuments,
  OfferingV5,
  TenderOfferType,
} from '@yieldstreet/platform-kit';

import { TenderOfferPages, TenderOfferPagesCopy } from './tenderOfferPageCopy.model';
import { isRegAPlus } from '../../offering';

const { useDetailsQuery } = offeringApi;

type useTenderOfferPageCopyProps = {
  urlHash: string;
  screen: `${TenderOfferPages}`;
};

export const useTenderOfferPageCopy = ({ urlHash, screen }: useTenderOfferPageCopyProps) => {
  const PageCopy = TenderOfferPagesCopy[screen];

  const { offering, isOfferingLoading } = useDetailsQuery([urlHash], {
    selectFromResult: ({ data, isLoading, isUninitialized }) => ({
      offering: data?.[urlHash]?.note as OfferingV5,
      isOfferingLoading: isLoading || isUninitialized,
    }),
  });

  const docs = useMemo(() => {
    let docs = [OfferingDocuments.OFTP, OfferingDocuments.TFAQ, OfferingDocuments.LOTL];

    if (isRegAPlus(offering?.fundType)) {
      docs = [OfferingDocuments.OFTP, OfferingDocuments.TFAQ];
    }

    if (
      offering?.fundType === FundType.ThirdPartyFund &&
      offering?.legalEntityType === LegalEntityType.FUND
    ) {
      docs = [OfferingDocuments.TFAQ, OfferingDocuments.PRPM, OfferingDocuments.OPER];
    }

    return docs;
  }, [offering]);

  const { type, copy } = useMemo(() => {
    if (isOfferingLoading || !offering) {
      return {
        type: 'default',
        copy: PageCopy.default,
      };
    }

    if (
      offering?.fundType === FundType.ThirdPartyFund &&
      offering?.legalEntityType === LegalEntityType.FUND
    ) {
      return {
        type: TenderOfferType.ENHANCED_CRYPTO,
        copy: PageCopy[TenderOfferType.ENHANCED_CRYPTO],
      };
    }

    return {
      type: offering?.fundType || 'default',
      copy: PageCopy[offering?.fundType || 'default'] || PageCopy.default,
    };
  }, [PageCopy, offering, isOfferingLoading]);

  return {
    type,
    offering,
    isOfferingLoading,
    copy,
    docs,
  };
};
