import { ThemeColor } from './palette.model';

type brand = 'midnightGreen' | 'parkGreen' | 'patinaGreen' | 'aquaMarine';

export const BRAND: Record<brand, ThemeColor> = {
  midnightGreen: {
    50: '#e6eaea',
    100: '#b0bebf',
    200: '#8a9fa0',
    300: '#547374',
    400: '#335859',
    500: '#002e30',
    600: '#002a2c',
    700: '#002122',
    800: '#00191a',
    900: '#001314',
  },
  parkGreen: {
    '50': '#e6eded',
    '100': '#b0c7c8',
    '200': '#8aacad',
    '300': '#558687',
    '400': '#346e70',
    '500': '#014a4c',
    '600': '#014345',
    '700': '#013536',
    '800': '#01292a',
    '900': '#001f20',
  },
  patinaGreen: {
    '50': '#eef9f7',
    '100': '#ccede6',
    '200': '#b3e5d9',
    '300': '#90d9c8',
    '400': '#7ad1bd',
    '500': '#59c6ad',
    '600': '#51b49d',
    '700': '#3f8d7b',
    '800': '#316d5f',
    '900': '#255349',
  },
  aquaMarine: {
    '50': '#fafffe',
    '100': '#effffb',
    '200': '#e7fff9',
    '300': '#dcfff7',
    '400': '#d5fff5',
    '500': '#cbfff3',
    '600': '#b9e8dd',
    '700': '#90b5ad',
    '800': '#708c86',
    '900': '#556b66',
  },
};
