import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

import type { TypographyTypeMap } from '@mui/material/Typography';

import { MuiTheme } from '../../../theme';
import { SectionContainer } from '../../layout/section-container';

import { Alignment, HeroBannerProps } from './HeroBanner.model';

const getBannerHeight = (bannerSize: string = '', theme: MuiTheme) => {
  switch (bannerSize) {
    case 'extralarge':
      return {
        minHeight: '640px',

        [theme.breakpoints.up('md')]: {
          minHeight: '560px',
        },
      };

    case 'large':
      return {
        minHeight: '480px',

        [theme.breakpoints.up('md')]: {
          minHeight: '400px',
        },
      };

    case 'small':
      return {
        minHeight: '320px',
      };

    default:
      return {
        minHeight: '360px',
      };
  }
};

export const Container = styled(Stack)<HeroBannerProps>(({ contentAlignment, reverse, theme }) => ({
  flexDirection: reverse ? 'column-reverse' : 'column',
  margin: '0 auto',
  padding: 0,
  width: '100%',
  height: '100%',

  [theme.breakpoints.up('md')]: {
    flexDirection: contentAlignment === 'center' ? 'column' : 'row',
    justifyContent: 'space-between',
  },

  b: {
    display: 'inline',
    fontWeight: 'inherit',
    textDecoration: 'none',
    backgroundImage: `linear-gradient(to top, transparent 20%, rgba(${theme.palette.accent.strong}, 0.15) 21%)`,
    backgroundPosition: '0 0.5em',
    backgroundRepeat: 'no-repeat',
    backgroundSize: `100% ${theme.spacing(5)}`,
  },
}));

export const Content = styled('div')(({ theme }) => ({
  margin: `0 0 ${theme.spacing(5)} 0`,

  'h1, h2, h3, h4, h5': {
    marginBottom: theme.spacing(5),
  },

  '.search': {
    display: 'flex',
    marginTop: theme.spacing(5),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
}));

export const ContentHolder = styled('div')<Alignment>(({ contentAlignment, theme }) => ({
  margin: 'auto 0',
  padding: `${theme.spacing(5)} 0`,
  width: '100%',
  color: theme.palette.text.paragraphPrimary.inverse,
  zIndex: 1,

  [theme.breakpoints.up('md')]: {
    ...(contentAlignment && {
      margin: 'auto',
    }),
  },
}));

export const Headline = styled(Typography)<
  Alignment & {
    inverse?: boolean;
    variant?: TypographyTypeMap['props']['variant'];
  }
>(({ contentAlignment, inverse, theme }) => ({
  margin: contentAlignment
    ? `0 0 ${theme.spacing(5)} 0`
    : `0 ${theme.spacing(5)} ${theme.spacing(5)} 0`,

  color: inverse ? theme.palette.text.header.inverse : theme.palette.text.header.default,

  [theme.breakpoints.up('md')]: {
    margin: `0 0 ${theme.spacing(5)} 0`,
  },
}));

export const InnerContainer = styled('div')<Alignment>(({ contentAlignment, theme }) => ({
  paddingTop: theme.spacing(1),
  boxSizing: 'border-box',
  textAlign: contentAlignment || 'inherit',
}));

export const Image = styled('div')<HeroBannerProps>(({ hideMediaOnMobile, theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(10)}`,

  ...(hideMediaOnMobile && {
    display: 'none',
  }),

  [theme.breakpoints.up('md')]: {
    display: 'block',
    padding: `${theme.spacing(10)} 0`,
  },
}));

export const Media = styled('div')<Partial<HeroBannerProps>>(({ hideMediaOnMobile, theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(10)}`,

  ...(hideMediaOnMobile && {
    display: 'none',
  }),

  [theme.breakpoints.up('md')]: {
    display: 'block',
    padding: `${theme.spacing(10)} 0`,
  },

  video: {
    width: '100%',
  },
}));

export const Middle = styled('div')<Alignment & HeroBannerProps>(
  ({ bannerSize, contentAlignment, theme }) => ({
    display: 'flex',
    width: '100%',
    ...getBannerHeight(bannerSize, theme),

    [theme.breakpoints.up('md')]: {
      width: '90%',
      ...(contentAlignment === 'center' ? { margin: 'auto' } : {}),
    },

    [theme.breakpoints.up('lg')]: {
      width: '55%',
    },
  })
);

export const OuterContainer = styled('div')<HeroBannerProps>(({ slogan, theme }) => ({
  margin: slogan ? `0 0 ${theme.spacing(17)} 0` : '0',

  [theme.breakpoints.up('md')]: {
    margin: slogan ? `0 0 ${theme.spacing(12)} 0` : '0',
  },
}));

export const Paragraph = styled(Typography)<{ inverse?: boolean }>(({ inverse, theme }) => ({
  color: inverse
    ? theme.palette.text.paragraphPrimary.inverse
    : theme.palette.text.paragraphPrimary.default,
}));

export const SideContent = styled('div')<HeroBannerProps>(({ contentAlignment, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(5),
  width: '100%',

  [theme.breakpoints.up('md')]: {
    ...(contentAlignment === 'center' ? { margin: 'auto' } : {}),
  },

  [theme.breakpoints.up('lg')]: {
    width: '45%',
  },
}));

export const Slogan = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  textAlign: 'center',
}));

export const SloganText = styled(Typography)(() => ({
  display: 'inline-block',
  lineHeight: '1.5',
  verticalAlign: 'middle',
}));

export const SSectionContainer = styled(SectionContainer)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,

  [theme.breakpoints.up('md')]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
