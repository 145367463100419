import { genTestIds } from '@yieldstreet/tool-kit';

import { HeadingType } from '../../content/text/Heading';

const { getTestIds } = genTestIds(['label', 'title', 'logo', 'back-navigation']);
export const testIds = getTestIds('page-split');

export interface BackgroundWrapperProps {
  backgroundImage?: string | ((content: React.ReactNode) => React.ReactNode);
}

export interface LogoHolderProps {
  center?: boolean;
}

export interface PageSplitProps {
  backgroundColor?: string;
  backgroundImage?: string | ((content: React.ReactNode) => React.ReactNode);
  backNavigation?: boolean;
  backNavigationCallback?: () => void;
  backNavigationText?: string;
  className?: string;
  footerContent?: React.ReactNode;
  headerHeight?: number;
  hideBackgroundImageMobile?: boolean;
  label?: string;
  middleSplit?: boolean;
  secondLogo?: React.ReactNode;
  showLogo?: boolean;
  sideContent?: React.ReactNode;
  title?: string | React.ReactNode;
  titleTagType?: HeadingType;
}
