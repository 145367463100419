import { genTestIds } from '@yieldstreet/tool-kit';

const { getTestIds } = genTestIds(['arrows', 'to-icon', 'from-icon']);

export const externalProviderTestIds = getTestIds('external-provider-visual');

export interface ExternalProviderVisualProps {
  fromIcon?: ExternalProviderVisualIcon;
  toIcon?: ExternalProviderVisualIcon;
  showArrow?: boolean;
}

export interface ExternalProviderVisualIcon {
  icon: string;
  alt: string;
  backgroundColor?: string;
}
