import React from 'react';
import styled from 'styled-components';
import { UserState } from '@yieldstreet/platform-kit';

import { IconPicker } from '../../utils/icon-picker';
import { Label, Paragraph } from '../../content/text';
import { media } from '../../themes';

import { Documents } from './MediaCardsSection.model';

interface MediaCardProps {
  title?: string;
  url?: string;
  description?: string;
  iconName?: string;
  type?: string;
  documents?: Documents[];
  userState: UserState;
  isPastOffering: boolean;
}

interface StyledComponentProps {
  className?: string;
}

interface TitleProps extends StyledComponentProps {
  icon?: string;
}

export const MediaCard = ({
  title,
  url,
  description,
  iconName,
  type,
  documents,
  userState,
  isPastOffering,
}: MediaCardProps) => {
  const getUrl = (type: string | undefined) => {
    switch (type) {
      case 'custom':
      case 'main':
      case 'other':
        return url;
      case 'mainDocument':
        return documents?.find(doc => doc.type === 'main')?.url;
      case 'mainDocumentPreview':
        return documents?.find(doc => doc.type === 'preview')?.url;
      case 'standAloneDoc':
        return documents?.find(doc => doc.name === title)?.url;
      default:
        return;
    }
  };

  if (type === 'main' || type === 'other' || type === 'standAloneDoc') {
    iconName = 'download';
  }

  if (
    (userState.loggedIn && !isPastOffering) ||
    ((!userState.loggedIn || isPastOffering) && type === 'custom')
  ) {
    return (
      <Wrapper>
        <a
          className="media-card-link"
          data-cy="media-card-link"
          href={getUrl(type)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextWrapper>
            <TitleContainer>
              {iconName && (
                <IconPicker
                  className="media-card-icon"
                  data-cy="media-card-icon"
                  iconName={iconName}
                />
              )}
              <Title className="media-card-title" data-cy="media-card-title" icon={iconName}>
                {title}
              </Title>
            </TitleContainer>
            {description && (
              <Description className="media-card-description" data-cy="media-card-description">
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </Description>
            )}
          </TextWrapper>
        </a>
      </Wrapper>
    );
  } else return null;
};

const Wrapper = styled.div`
  cursor: pointer;
  overflow-wrap: break-word;
  border-bottom: 2px solid ${props => props.theme.colors.chart_1};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 20px;
  max-height: 144px;
  flex-wrap: wrap;
  background-color: ${props => props.theme.colors.background_card};

  ${media.tablet`
    flex: 1;
    margin: 20px 10px;
  `};

  ${media.large`
    margin: 20px 10px;
    flex-basis: 33.33%;
    max-width: 380px;
  `};
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-wrap: anywhere;
  .iconImage {
    margin: auto 0;
  }
  cursor: pointer;
`;

const Title = styled(Paragraph)<TitleProps>`
  padding-bottom: 10px;
  margin-left: ${props => props.icon && `20px`};
`;

const Description = styled(Label)<StyledComponentProps>`
  cursor: pointer;
`;
