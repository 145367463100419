import { alpha, styled } from '@mui/material/styles';

export const SubMenuSection = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${alpha(theme.palette.border.strong, 0.5)}`,
    },
  },
}));

export const SubMenuSectionItems = styled('ul')<{ grid: boolean }>(({ grid, theme }) => ({
  marginTop: 10,
  [theme.breakpoints.up('lg')]: {
    columnCount: grid ? 2 : 1,
    marginLeft: -15,
    marginTop: 0,
  },
}));
