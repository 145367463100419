import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TextBaseStyle, TextBaseStyleProps } from './TextBase';

import { getHeaderStyle, HeadingStyleProps, HeadingType } from './Heading';

export type TagType = 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export interface TitleHeadingProps extends TextBaseStyleProps, HeadingStyleProps {
  type: HeadingType;
  tagType?: TagType;
  className?: string;
}

export const TitleHeading: FunctionComponent<TitleHeadingProps> = ({
  type,
  children,
  tagType = 'span',
  ...otherProps
}) => {
  return (
    <TitleHeadingElement type={type} as={tagType} {...otherProps}>
      {children}
    </TitleHeadingElement>
  );
};

const TitleHeadingElement = styled.span<TitleHeadingProps>`
  ${TextBaseStyle};
  ${props => getHeaderStyle(props.type)};
  display: block;
`;
