import { css, styled } from '@mui/material/styles';

import DiagonalLines from '../../assets/graphics/backgrounds/diagonal-lines-pattern.svg';
import { LabelStyleSmall } from '../../content/text/Label';
import { ContainerPadding } from '../container/Container';

import { LogoHolderProps, PageSplitProps } from './PageSplit.model';

const maxWidth = 1250;
const ratioSideSplit = 5 / 12;
const ratioMiddleSplit = 6 / 12;

export const SPageSplit = styled('div')<Partial<PageSplitProps>>(({ theme, backgroundColor }) => ({
  display: 'flex',
  backgroundColor: backgroundColor,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export const LeftPageContent = styled('div')<Partial<PageSplitProps>>(({ theme, middleSplit }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(5)} 0 ${theme.spacing(7)} 0`,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    background: `${theme.palette.background.default} url(${DiagonalLines}) bottom left no-repeat`,
    padding: `${theme.spacing(10)} ${theme.spacing(25)} ${theme.spacing(
      25
    )} calc((100vw - 1250px) / 2)`,
    width: `calc(((100vw - ${maxWidth}px) / 2) + (${maxWidth}px * ${
      middleSplit ? ratioMiddleSplit : ratioSideSplit
    }))`,
  },
}));

export const ContentHolder = styled('div')({
  width: '100%',
});

export const RightPageContent = styled('div')<Partial<PageSplitProps>>(
  ({ theme, hideBackgroundImageMobile, headerHeight, middleSplit, backgroundImage }) => ({
    padding: `${theme.spacing(7)} 0`,
    width: '100%',
    ...(hideBackgroundImageMobile || !backgroundImage
      ? {}
      : {
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'right top',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }),
    [theme.breakpoints.up('md')]: {
      // Reminder that you cannot add "theme.spacing" with normal values (Interesting solution: https://stackoverflow.com/questions/69319992/spacing-math-with-theme-spacing-in-mui-v5)
      padding: `calc(60px + ${headerHeight}px) calc((100vw - ${maxWidth}px) / 2) ${theme.spacing(
        25
      )} ${theme.spacing(25)}`,
      width: `calc(((100vw - ${maxWidth}px) / 2) + (${maxWidth}px * ${
        middleSplit ? 1 - ratioMiddleSplit : 1 - ratioSideSplit
      }))`,
      ...(!backgroundImage
        ? {}
        : {
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: 'right top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }),
    },
  })
);

export const ContentHeader = styled('div')(
  {
    width: '100%',
    height: 'fit-content',
  },
  ContainerPadding
);

export const ContentLeft = styled('div')(
  ({ theme }) => ({
    width: '100%',
    paddingTop: theme.spacing(2),
  }),
  ContainerPadding
);

export const ContentRight = styled('div')(
  {
    width: '100%',
    animation: 'slideIn ease 0.25s',
    '@keyframes slideIn': {
      '0%': {
        marginLeft: 50,
        opacity: 0,
      },
      '100%': {
        opacity: 1,
        marginLeft: 0,
      },
    },
  },
  ContainerPadding
);

export const FooterContentLeft = styled('div')(
  ({ theme }) => ({
    display: 'none',
    marginTop: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  }),
  css`
    ${ContainerPadding}${LabelStyleSmall.toString()}
  `
);

export const FooterContentRight = styled(FooterContentLeft)(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(4),
  margin: 0,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const LogosHolder = styled('div')<LogoHolderProps>(({ theme, center }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingBottom: theme.spacing(8),
  justifyContent: center ? 'center' : 'left',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'left',
  },
}));

export const Logo = styled('div')({
  img: {
    maxWidth: '100%',
    maxHeight: '47px',
  },
});

export const LogoSecondary = styled(Logo)({
  flexShrink: 1,
  flexGrow: 0,
});

export const BackNavigation = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} 0 ${theme.spacing(5)} 0`,
  },
}));
