import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import ExitIntent from './ExitIntent';
import { NewModal, useModalContext } from '../../elements/new-modal';
import { ModalContent } from '../../elements/modal/Modal.style';

/**
 * The different types of triggers
 */
export enum Triggers {
  SCROLL = 'scroll',
  SCROLL_PERCENTAGE = 'scrollPercentage',
  EXIT = 'exit',
  LOAD = 'load',
  TIME = 'time',
  TIMED_SCROLL = 'timedScroll',
}

type Styled = {
  height?: string;
  width?: string;
};

type ModalLocalStorageProps = {
  [key: string]: {
    timeout: number;
  };
};

type OwnProps = {
  storageId: string;
  className?: string;
  closeSubmit?: boolean;
  disableCloseOnClickOutside?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  // How long (in seconds) before the modal can be shown again
  modalTimeoutInSeconds?: number;
  submitId?: string;
  dataCy?: string;
};

type TriggerTypes =
  | {
      triggerType: Triggers.SCROLL;
      offsetSize: number;
      timeout?: number;
    }
  | {
      triggerType: Triggers.SCROLL_PERCENTAGE;
      offsetSize: number;
      timeout?: number;
    }
  | {
      triggerType: Triggers.EXIT | Triggers.LOAD;
      offsetSize?: number;
      timeout?: number;
    }
  | {
      triggerType: Triggers.TIME;
      offsetSize?: number;
      timeout: number;
    }
  | {
      triggerType: Triggers.TIMED_SCROLL;
      offsetSize: number;
      timeout: number;
    };

export type ModalTriggerProps = OwnProps & TriggerTypes & Styled;

export const ModalTrigger: FunctionComponent<ModalTriggerProps> = ({
  children,
  closeSubmit,
  dataCy,
  modalTimeoutInSeconds = 0,
  offsetSize = 0,
  onClose,
  onOpen,
  storageId = '',
  submitId = '',
  timeout = 0,
  triggerType,
}) => {
  const [shown, setShown] = useState(true);

  const { showModal, hideModal } = useModalContext();

  if (storageId.length === 0) {
    throw new Error('Storage ID is required');
  }

  if (!triggerType) {
    throw new Error('Trigger type is required');
  }

  /**
   * @returns true if the modal can be shown
   */
  const canShowModal = useCallback(() => {
    const idList = getModalLocalStorage();
    if (Object.keys(idList).includes(storageId)) {
      return Date.now() >= idList[storageId].timeout;
    }

    return true;
  }, [storageId]);

  /**
   * @returns Get object related to Modal Triggers from local storage
   */
  const getModalLocalStorage = (): ModalLocalStorageProps => {
    const ls = localStorage.getItem('shownModals');
    return ls !== null ? JSON.parse(ls) : {};
  };

  /**
   * Set a timestamp for when the modal can be shown again in local storage
   * @param expiryInSeconds How much time in seconds until the modal can be shown again
   */
  const setModalLocalStorage = useCallback(
    (expiryInSeconds: number) => {
      const idList = getModalLocalStorage();
      const nextTimestamp = expiryInSeconds > 0 ? Date.now() + expiryInSeconds * 1000 : 0;

      idList[storageId] = { timeout: nextTimestamp };
      localStorage.setItem('shownModals', JSON.stringify(idList));
    },
    [storageId]
  );

  /**
   * Logic for closing the modal
   */
  const closeModal = useCallback(() => {
    setModalLocalStorage(modalTimeoutInSeconds);
    hideModal();
    setShown(true);
    onClose && onClose();
  }, [hideModal, modalTimeoutInSeconds, onClose, setModalLocalStorage]);

  /**
   * Logic for opening the modal
   */
  const openModal = useCallback(() => {
    if (!canShowModal()) {
      return;
    }

    showModal(storageId);
    onOpen && onOpen();
  }, [canShowModal, onOpen, showModal, storageId]);

  /**
   * If a modal was "submitted" or shown in Local Storage, ensure modal is set as shown
   */
  useEffect(() => {
    const idList = getModalLocalStorage();
    const isShown = Object.keys(idList).includes(storageId);
    const isSubmitted = !!(submitId && localStorage.getItem(submitId));

    // If not shown, don't do anything
    if (!isShown) {
      setShown(false);
      return;
    }

    // If shown and not submitted, check if timeout has passed to reveal modal again
    if (!isSubmitted && idList[storageId].timeout && Date.now() >= idList[storageId].timeout) {
      localStorage.removeItem(storageId);
      setShown(false);
      return;
    }

    // Otherwise, set as previously shown
    setShown(true);
  }, [storageId, submitId]);

  /**
   * Assign event based off trigger type
   */
  useEffect(() => {
    const checkModal = () => {
      let calculatedOffsetSize = offsetSize;

      if (calculatedOffsetSize && Triggers.SCROLL_PERCENTAGE) {
        calculatedOffsetSize =
          (calculatedOffsetSize / 100) * (document.body.scrollHeight - window.innerHeight);
      }

      if (calculatedOffsetSize && window.scrollY >= calculatedOffsetSize) {
        openModal();
        window.removeEventListener('scroll', checkModal);
      }
    };

    if (!shown) {
      switch (triggerType) {
        case Triggers.SCROLL:
        case Triggers.SCROLL_PERCENTAGE:
          window.addEventListener('scroll', checkModal, { passive: true });
          break;
        case Triggers.TIMED_SCROLL:
          const to = setTimeout(() => {
            window.addEventListener('scroll', checkModal, { passive: true });
            clearTimeout(to);
          }, timeout);
          break;
      }
    }

    return () => window.removeEventListener('scroll', checkModal);
  }, [triggerType, offsetSize, timeout, shown, openModal]);

  /**
   * Trigger the modal when the user exits the page
   */
  useEffect(() => {
    if (triggerType === Triggers.EXIT && !shown) {
      const removeExitIntent = ExitIntent({
        threshold: 80,
        eventThrottle: 100,
        onExitIntent: () => {
          openModal();
          removeExitIntent();
        },
      });
      return () => {
        removeExitIntent();
      };
    }
  }, [triggerType, shown, openModal]);

  /**
   * Trigger the modal when the page loads or after a set time
   */
  useEffect(() => {
    if (triggerType === Triggers.LOAD && !shown) {
      openModal();
    }

    if (triggerType === Triggers.TIME && !shown) {
      const to = setTimeout(() => {
        openModal();
        clearTimeout(to);
      }, timeout);
    }
  }, [triggerType, timeout, shown, openModal]);

  /**
   * Effect when the modal is closed
   */
  useEffect(() => {
    if (closeSubmit) {
      submitId && localStorage.setItem(submitId, 'submitted');
      closeModal();
    }
  }, [closeModal, closeSubmit, submitId]);

  return (
    <NewModal modalId={storageId} onClose={closeModal} dataCy={dataCy}>
      <ModalContent>{children}</ModalContent>
    </NewModal>
  );
};
