import React, { FunctionComponent, useState, useEffect } from 'react';
import get from 'lodash/get';
import { BaseInputProps, BaseFormikInputProps } from './types';

export type FormikCallbacks<Value, CustomInputProps> = {
  onSubmit?: (props: BaseFormikInputProps<Value> & CustomInputProps) => void;
  onSubmitError?: (props: BaseFormikInputProps<Value>) => void;
};

export type BaseInputComponentProps<Value, CustomInputProps> = BaseInputProps<Value> &
  CustomInputProps;

export type BaseInputComponentType<Value, CustomInputProps> = React.ComponentType<
  BaseInputComponentProps<Value, CustomInputProps>
>;

export type FormikInputComponent<Value, CustomInputProps> = FunctionComponent<
  BaseFormikInputProps<Value> & CustomInputProps
>;

export const withFormik = <Value, CustomInputProps>(
  inputComponent: BaseInputComponentType<Value, CustomInputProps>,
  callbacks: FormikCallbacks<Value, CustomInputProps> = {}
): FormikInputComponent<Value, CustomInputProps> => {
  return props => {
    const InputComponent = inputComponent as React.ComponentType<BaseInputProps<Value>>;

    const { name, formikProps, error, onChange, onBlur, onClick, ...otherProps } = props;

    const { errors, touched, values, submitCount, setFieldValue, setFieldTouched, setFieldError } =
      formikProps;

    const [counter, setCounter] = useState(0);

    useEffect(() => {
      if (error) {
        setFieldError(name, error);
      }
    }, [error, name, setFieldError]);

    useEffect(() => {
      const errorList = Object.keys(errors);
      const { onSubmit, onSubmitError } = callbacks;

      if (counter !== submitCount) {
        setCounter(submitCount);
        onSubmit && onSubmit(props);

        if (errorList && errorList.length && errorList[0] === name) {
          onSubmitError && onSubmitError(props);
          // scrollToQuery(`[data-cy='${name}']`, 'center');
        }
      }
    }, [errors, callbacks, formikProps]);

    const fieldValue: Value = get(values, name, '');
    const fieldTouched: boolean = !!get(touched, name, false);
    const fieldError = get(errors, name, '') as unknown as string | undefined;

    return React.createElement(InputComponent, {
      ...otherProps,
      name,
      value: fieldValue,
      onBlur: () => {
        onBlur && onBlur();
        setFieldTouched(name, true);
      },
      onChange: (value, ...args) => {
        onChange && onChange(value, ...args);
        setFieldValue(name, value);
      },
      onClick: e => {
        onClick && onClick(e);
      },
      error: fieldTouched && fieldError ? fieldError : undefined,
    });
  };
};
