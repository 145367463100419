import { HttpMethods } from '../../enums';
import { Tags, restApi } from '../common';
import { UserStateAPIResponse } from '../../types';
import { UpdateUserNameParams } from './user-name.model';

export const userNameApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.UserState, Tags.UserContact],
  })
  .injectEndpoints({
    endpoints: builder => {
      const updateUserName = builder.mutation<UserStateAPIResponse, UpdateUserNameParams>({
        query: ({ userId, data }) => ({
          url: `/a/api/user/name/${userId}`,
          method: HttpMethods.Patch,
          data,
        }),
        invalidatesTags: [Tags.UserState, Tags.UserContact],
        transformResponse: res => res.data,
      });

      return {
        updateUserName,
      };
    },
  });
