export enum AdviceEmploymentStatus {
  Employed = 'EMPLOYED',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED',
}

export enum AdviceCurrentIncome {
  _0K_200K = '0K_200K',
  _200K_500K = '200K_500K',
  _500K_1M = '500K_1M',
  _1M_PLUS = '1M+',
}

export enum AdviceNetWorth {
  _0K_500K = '0K_500K',
  _500K_1M = '500K_1M',
  _1M_5M = '1M_5M',
  _5M_PLUS = '5M+',
}

export enum AdviceInvestmentStyle {
  Income = 'INCOME',
  Growth = 'GROWTH',
  Balanced = 'BALANCED',
}

export enum AdviceRiskTolerance {
  Conservative = 'CONSERVATIVE',
  Moderate = 'MODERATE',
  Aggressive = 'AGGRESSIVE',
  AggressivePlus = 'AGGRESSIVE_PLUS',
}

export enum AdvicePreferredTimeHorizon {
  _0_3Y = '0_3Y',
  _3_5Y = '3_5Y',
  _5Y = '5Y+',
}

export enum AdviceExpectedYield {
  _3_5 = '3_5',
  _6_8 = '6_8',
  _9_11 = '9_11',
  _12_PLUS = '12+',
}

export enum AdviceInterestedAssetClasses {
  Art = 'ART',
  Crypto = 'CRYPTO',
  Legal = 'LEGAL',
  MultiAssetClassFun = 'MULTI_ASSET_CLASS_FUND',
  PrivateCredit = 'PRIVATE_CREDIT',
  PrivateEquity = 'PRIVATE_EQUITY',
  RealEstate = 'REAL_ESTATE',
  ShortTermNotes = 'SHORT_TERM_NOTES',
  Transportation = 'TRANSPORTATION',
  VentureCapital = 'VENTURE_CAPITAL',
}

export enum AdviceIndustries {
  AccommodationAndFoodServices = 'ACCOMMODATION_AND_FOOD_SERVICES',
  AdminSupportWasteManagementAndRemediation = 'ADMIN_SUPPORT_WASTE_MANAGEMENT_AND_REMEDIATION',
  AgricultureForestFishingHunting = 'AGRICULTURE_FOREST_FISHING_HUNTING',
  ArtsEntertainmentAndRecreation = 'ARTS_ENTERTAINMENT_AND_RECREATION',
  Construction = 'CONSTRUCTION',
  Educational = 'EDUCATIONAL',
  FinanceAndInsurance = 'FINANCE_AND_INSURANCE',
  HealthCareAndSocialAssistance = 'HEALTH_CARE_AND_SOCIAL_ASSISTANCE',
  Information = 'INFORMATION',
  ManagementOfCompaniesAndEnterprises = 'MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES',
  Manufacturing = 'MANUFACTURING',
  Mining = 'MINING_OIL_AND_GAS',
  OtherServicesExcludingPublicAdmin = 'OTHER_SERVICES_EXCL_PUBLIC_ADMIN',
  ProfessionalScientificAndTechnical = 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL',
  PublicAdmin = 'PUBLIC_ADMIN',
  RealEstateRentalAndLeasing = 'REAL_ESTATE_RENTAL_AND_LEASING',
  RetailTrade = 'RETAIL_TRADE',
  TransportationAndWarehousing = 'TRANSPORTATION_AND_WAREHOUSING',
  Utilities = 'UTILITIES',
  WholesaleTrade = 'WHOLESALE_TRADE',
}

export enum AdviceQualifiedPurchaser {
  True = 'TRUE',
  False = 'FALSE',
}

export enum AdviceQualifiedClient {
  True = 'TRUE',
  False = 'FALSE',
}

export enum AdviceInvestmentAmount {
  _50K_100K = '50K_100K',
  _100K_250K = '100K_250K',
  _250K_Plus = '250K+',
}
