import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import {
  CampaignApiPutRequest,
  CampaignApiPutResponse,
  CampaignEligibilityApiGetResponse,
  CampaignEligibilityApiGetRequest,
  CampaignEligibilityApiGetAllRequest,
  CampaignsApiGetResponse,
  CampaignsApiGetRequest,
  CampaignApiPutAllRequest,
} from './campaign.model';

export const campaignApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.CampaignStatus, Tags.Campaigns],
  })
  .injectEndpoints({
    endpoints: build => {
      const getCampaignEligibility = build.query<
        CampaignEligibilityApiGetResponse,
        CampaignEligibilityApiGetRequest
      >({
        query: ({ fundId, campaignId, userId, investorAccountId }) => ({
          url: `/api/fund/funds/${fundId}/campaigns/${campaignId}/eligibilities/${userId}`,
          method: HttpMethods.Get,
          params: {
            investorAccountId,
          },
        }),
        providesTags: [Tags.CampaignStatus],
      });

      const getCampaigns = build.query<CampaignsApiGetResponse, CampaignsApiGetRequest>({
        query: ({ fundId, userId }) => ({
          url: `/api/fund/funds/${fundId}/campaigns/${userId}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.Campaigns],
      });

      const submitCampaignVotes = build.mutation<CampaignApiPutResponse, CampaignApiPutRequest>({
        query: ({ fundId, userId, ...data }) => ({
          url: `/api/fund/funds/${fundId}/campaigns/votes/${userId}`,
          method: HttpMethods.Put,
          data,
        }),
        invalidatesTags: [Tags.CampaignStatus],
      });

      const getAllCampaignEligibility = build.query<
        CampaignEligibilityApiGetResponse,
        CampaignEligibilityApiGetAllRequest
      >({
        query: ({ userId, investorAccountId }) => ({
          url: `/api/fund/funds/campaigns/eligibilities/${userId}`,
          method: HttpMethods.Get,
          params: {
            investorAccountId,
          },
        }),
      });

      const submitAllCampaignVotes = build.mutation<
        CampaignApiPutResponse,
        CampaignApiPutAllRequest
      >({
        query: ({ userId, ...data }) => ({
          url: `/api/fund/funds/campaigns/votes/${userId}`,
          method: HttpMethods.Put,
          data,
        }),
      });

      return {
        getCampaigns,
        getCampaignEligibility,
        submitCampaignVotes,
        getAllCampaignEligibility,
        submitAllCampaignVotes,
      };
    },
  });
