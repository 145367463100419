import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { theme2 } from '../../themes';

export interface LinearProgressBarProps {
  percentCompleted: number;
}

export interface LinearProgressBarStyleProps {
  percentCompleted: number;
}

export const LinearProgressBar: FunctionComponent<LinearProgressBarProps> = ({
  percentCompleted = 0,
}) => (
  <LinearProgressBarBackground>
    <LinearProgressBarFilling percentCompleted={percentCompleted} />
  </LinearProgressBarBackground>
);

export const LinearProgressBarBackground = styled.div`
  height: 10px;
  width: 100%;
  background-color: ${theme2.colors.background_progress_bar};
  border-radius: 10px;
`;

export const LinearProgressBarFilling = styled.div<LinearProgressBarStyleProps>`
  height: 100%;
  width: ${props => `${props.percentCompleted}%`};
  background-color: ${theme2.colors.active};
  border-radius: inherit;
`;
