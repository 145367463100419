import { genTestIds } from '@yieldstreet/tool-kit';

import { theme2 } from '../../themes';

const { getTestIds } = genTestIds(['content', 'tooltip']);

export const testIds = getTestIds('tag');

export interface TagProps {
  content: string;
  tooltip?: string;
  type?: keyof typeof TagType;
}

export enum TagType {
  DEFAULT = 'DEFAULT',
}

export const TagConfiguration = {
  [TagType.DEFAULT]: {
    backgroundColor: theme2.colors.tag_default_background_color,
    textColor: theme2.colors.tag_default_text_color,
    borderColor: theme2.colors.tag_default_border_color,
  },
};

export interface TagContainerProps {
  backgroundColor?: string;
  borderColor?: string;
}

export interface TagContentProps {
  textColor?: string;
}
