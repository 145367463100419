import styled, { css, CSSProperties } from 'styled-components';
import { ParagraphBase } from '../../content';
import { TabBadgeProps, TabContainerProps } from './Tab.model';
import { media } from '../../themes';

export const TabContainer = styled.div<TabContainerProps>`
  ${ParagraphBase};
  display: flex;

  ${media.desktop<Partial<TabContainerProps>>`
    ${props =>
      props.vertical &&
      `
      &:hover {
        border-left: 2px solid ${props.theme.colors.border_outline};
      }

      ${
        props.isActive
          ? `border-left: 2px solid ${props.theme.colors.border_outline};`
          : 'border-left: 2px solid transparent;'
      };
    `}
  `}

  ${media.large<Partial<TabContainerProps>>`
    ${props =>
      props.vertical &&
      `
      padding: ${props.theme.spacing.xs};
      color: ${props.isActive ? props.theme.colors.text_paragraph : props.theme.colors.text_label};

    `}
  `}

  ${props =>
    props.vertical
      ? `
        color: ${props.theme.colors.text_paragraph};
        padding: ${props.theme.spacing.sm} 0;
      `
      : `
      padding: ${props.theme.spacing.xs} ${props.theme.spacing.sm};
      border: 1px solid ${props.theme.colors.border_default};
      background: ${
        props.isActive ? props.theme.colors.background_inverse : props.theme.colors.background_card
      };
      color: ${
        props.isActive ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_label
      };
      transition: all 0.2s ease-in-out;
      border-radius: 15rem;

      img {
        filter: ${
          props.isActive && !props?.disableIconInverse
            ? css`
                -webkit-filter: invert(1);
                filter: invert(1);
              `
            : css`
                -webkit-filter: invert(0);
                filter: invert(0);
              `
        };
        opacity: ${props.isActive && props?.disableIconInverse ? 1 : 0.6};
      }

      &:hover {
        background: ${
          props.isActive
            ? props.theme.colors.background_inverse
            : props.theme.colors.input_button_basic_inverse_background_hover
        };

        color: ${
          props.isActive ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_label
        };
      }
  `}

  cursor: ${props => (props.isActive ? 'default' : 'pointer')};
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;

  ${media.large`
    width: fit-content;
  `}
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;

  img {
    display: flex;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
`;

export const TabTitle = styled.div<{
  vertical?: boolean;
  textWrap?: CSSProperties['whiteSpace'];
}>`
  font-weight: ${props => (props.vertical ? 400 : 500)};
  ${props => props.textWrap && `white-space: ${props.textWrap};`}
`;

export const TabNumericBadge = styled.div<TabBadgeProps>`
  width: ${props => (props?.isLarge ? '34px' : '20px')};
  height: 20px;
  border-radius: 10px;
  padding: 4px 0;
  background-color: ${props =>
    props?.isActive ? props.theme.colors.text_header_inverse : props.theme.colors.text_header};
  color: ${props =>
    props?.isActive ? props.theme.colors.text_header : props.theme.colors.text_header_inverse};
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  margin-left: 8px;
`;

export const Icon = styled.img`
  transform: rotate(180deg);
`;
