export const offeringTagSection = {
  key: 'Vd7z3oAu7',
  type: 'offeringTags',
};

export const metricCardSection = {
  key: 'lhgHL3BnmC',
  type: 'metricCards',
  content: [
    {
      title: 'Returns & Management fees',
      fields: [
        {
          label: 'Annual management fee',
          description: '0.0%',
          tooltipMessage: 'This is a message for the tooltip.',
          tooltipPlace: 'bottom',
        },
        {
          label: 'Target annual net return',
          description: '0.0%',
          tooltipMessage: 'This is a message for the tooltip.',
          tooltipPlace: 'bottom',
        },
        {
          label: 'Target annual cash yield',
          description: '0.0%',
          tooltipMessage: 'This is a message for the tooltip.',
          tooltipPlace: 'bottom',
        },
      ],
    },
    {
      title: 'Schedule',
      fields: [
        {
          label: 'Current income schedule',
          description: 'Monthly',
          color: 'dark',
          tooltipMessage: 'This is a message for the tooltip.',
          tooltipPlace: 'bottom',
        },
        {
          label: 'First expected payment date',
          description: '1Q 2023',
          color: 'dark',
        },
        {
          label: 'Exit Strategy',
          description: 'Event-based',
          color: 'dark',
          tooltipMessage: 'This is a message for the tooltip.',
          tooltipPlace: 'bottom',
        },
        {
          label: 'Target term',
          description: '3-4 years',
          color: 'dark',
        },
      ],
    },
    {
      title: 'Structure',
      fields: [
        {
          label: 'Tax document',
          description: 'K-1',
        },
        {
          label: 'Offering structure',
          description: 'SPV',
        },
        {
          label: 'Annual flat expense',
          description: '025%',
        },
      ],
    },
  ],
};

export const metricCardSection2 = {
  key: 'jke7OC4-Ai',
  type: 'metricCards',
  content: [
    {
      title: 'Yield',
      fields: [
        {
          label: 'Gross yield',
          description: '0.0%',
        },
        {
          label: 'Yieldstreet fee',
          description: '0.0%',
        },
        {
          label: 'Servicing fee',
          description: '0.0%',
        },
        {
          label: 'Target yield',
          description: '0.0%',
          extraSpace: true,
          loginRequired: true,
        },
        {
          label: 'Interest Type',
          description: '_______',
          tooltipMessage: 'This is a message for the tooltip.',
        },
      ],
    },
    {
      title: 'Schedule',
      fields: [
        {
          label: 'Payment schedule',
          description: '________',
          color: 'dark',
          tooltipMessage: 'This is a message for the tooltip.',
          tooltipPlace: 'bottom',
        },
        {
          label: 'Prefunded',
          description: '___',
          color: 'dark',
        },
        {
          label: 'Maturity',
          description: 'Date',
          color: 'light',
          size: 'small',
          extraSpace: true,
        },
        {
          label: 'Loan maturity',
          description: 'MMM DD, YYYY',
          color: 'dark',
        },
      ],
    },
    {
      title: 'Structure',
      fields: [
        {
          label: 'Tax document',
          description: '_____',
        },
        {
          label: 'Offering structure',
          description: '____',
        },
        {
          label: 'Expenses',
          color: 'light',
          size: 'small',
          extraSpace: true,
        },
        {
          label: 'First year expense',
          description: '$___',
        },
        {
          label: 'Annual flat expense',
          description: '$__',
        },
      ],
    },
  ],
};

export const noteBannerSection1 = {
  key: 'LfeYU9zWUk',
  type: 'noteBanner',
  title: 'Important Notes - and featured!',
  featuredBanner: true,
  note: 'this is a note to the banner section',
  content: [
    'This is a sentence with **bold** and a [link](http://www.google.com)  - inside a featured note banner',
  ],
};

export const noteBannerSection2 = {
  key: 'wYzss-2KSZ',
  type: 'noteBanner',
  title: 'This is the second banner',
  featuredBanner: false,
  note: 'this is a **note**',
  content: [
    'This is a sentence with **bold** and a [link](http://www.google.com)',
    'another content item',
  ],
};

export const noteBannerSection3 = {
  key: 'LKkafwj-5',
  type: 'noteBanner',
  title: '',
  featuredBanner: false,
  note: '',
  content: [],
};

export const paragraphBoxSection = {
  key: 'TT75ToDAIi',
  type: 'paragraphBox',
  title: '',
  video: 'https://player.vimeo.com/video/836576673',
  image: '',
  imageCaption: '',
  mediaTitle: 'media title test',
  interactiveMap: '',
  charLimit: 1500,
  content: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v',
  ],
};

export const paragraphBoxSection2 = {
  key: 'SxwnDdjUvB',
  type: 'paragraphBox',
  title: 'Lorem ipsum',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  iconName: 'structure',
  video: '',
  image: 'http://via.placeholder.com/380x250',
  imageCaption: 'Image title',
  mediaTitle: 'media title test',
  interactiveMap: '',
  charLimit: 1500,
  content: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v',
  ],
};

export const paragraphBoxSection3 = {
  key: 'C49HD55ZYl',
  type: 'paragraphBox',
  title: 'Lorem ipsum',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  iconName: 'structure',
  video: '',
  image: 'http://via.placeholder.com/380x250',
  imageCaption: 'Image title',
  mediaTitle: 'media title test',
  interactiveMap: '',
  charLimit: 1500,
  content: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v',
  ],
};

export const paragraphBoxSection4 = {
  key: 'OqQJkaQLH2',
  type: 'paragraphBox',
  title: 'Lorem ipsum',
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  iconName: 'structure',
  video: '',
  image: 'http://via.placeholder.com/380x250',
  imageCaption: 'Image title',
  mediaTitle: 'media title test',
  interactiveMap: '',
  charLimit: 1500,
  content: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v',
  ],
};

export const mediaCardsSection = {
  key: 'KZ01CoVYxV',
  type: 'mediaCards',
  resources: ['all'],
  content: [
    {
      type: 'custom',
      title: 'Lorem ipsum',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      iconName: 'note',
      customUrl: 'www.yieldstreet.com',
      previewPdfPage: '',
    },
  ],
};

export const wordPressCardsSection = {
  key: 'DipGaBaNAoZ',
  type: 'wordPressCards',
  content: [
    {
      articleUrl: 'https://www.yieldstreet.com/blog/article/yieldstreet-meets-kpop',
    },
    {
      articleUrl:
        'https://www.yieldstreet.com/blog/article/understanding-your-fund-investment-what-is-the-j-curve',
    },
    {
      articleUrl:
        'https://www.yieldstreet.com/blog/article/commercial-real-estate-cre-can-appeal-to-different-risk-return-profiles',
    },
  ],
};

export const disclaimerSection = {
  key: 'Hni4GkyjdqV',
  type: 'disclaimer',
  useLegalEntityDisclaimer: true,
  content: [],
};

export const offeringV5Sections = [
  offeringTagSection,
  metricCardSection,
  metricCardSection2,
  noteBannerSection1,
  noteBannerSection2,
  paragraphBoxSection,
  paragraphBoxSection2,
  paragraphBoxSection3,
  paragraphBoxSection4,
  mediaCardsSection,
  wordPressCardsSection,
  disclaimerSection,
];
